import styled from "styled-components";
import React from "react";
import { Footer } from "../layouts/BaseLayout/Footer";
import { Header, LogoHeader } from "../components/header";
import {
  HomeHeroBackground as HeroBackground,
  //   CloudBackground,
} from "../components/home/hero/HomeHeroBackground";

import useDocumentTitle from "../helpers/useDocumentTitle";
import { Hero } from "../components/rainbowRumble/Hero";
import { device } from "../theme/mediaQuery";
// import { GameBanner } from "../components/rainbowRumble/GameBanner";
// import { BannerTitle } from "../components/rainbowRumble/BannerTitle";
// import { InfoCard } from "../components/rainbowRumble/InfoCard";

// import {
//   GAMES,
//   RBW as INFO_CARD,
//   RR_CARD as GAME_BANNER,
// } from "../components/rainbowRumble/GameBannerCopy";

const PurpleGradientWrapper = styled.div`
  background: linear-gradient(
    180deg,
    #150079 0%,
    #5118a4 43.52%,
    #872cc6 64.87%,
    #3976eb 100%
  );
  overflow: hidden;
  padding-bottom: 32px;

  @media (${device.lg}) {
    padding-bottom: 0;
    height: 100vh;
  }
`;

const HeroBackgroundWrap = styled(HeroBackground)`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  &::before {
    background-size: contain;
    background-repeat: repeat-x;
  }
`;

// const CardWrap = styled.section`
//   padding: 0 32px;
//   max-width: 1500px;
//   margin: 0 auto;
//   display: flex;
//   flex-direction: column;
//   row-gap: 32px;
//   @media (${device.md}) {
//     padding: 0 40px 30px;
//   }
//   @media (${device.lg}) {
//     row-gap: 126px;
//     padding: 0 40px 35px;
//   }

//   @media (${device.xxl}) {
//     padding: 0 53px 40px;
//   }
// `;

// const HighlightWrap = styled(CardWrap)`
//   padding-top: 45px;
//   @media (${device.md}) {
//     padding-top: 60px;
//   }
//   @media (${device.lg}) {
//     padding-top: 90px;
//   }
//   @media (${device.xxl}) {
//     padding-top: 126px;
//   }
// `;

// const Divider = styled.div`
//   height: 1px;
//   background: rgba(255, 255, 255, 0.3);

//   margin: ${({ $margin }) => ($margin ? "32px 32px" : 0)};
//   @media (${device.md}) {
//     margin: ${({ $margin }) => ($margin ? "32px 40px" : 0)};
//   }
//   @media (${device.lg}) {
//     display: none;
//   }
// `;

export function RainbowRumbleWillReturn() {
  useDocumentTitle("Rainbow Rumble Will Return");

  return (
    <>
      <PurpleGradientWrapper>
        <HeroBackgroundWrap>
          <Header />
          <Hero showBanner={false} />
        </HeroBackgroundWrap>
        {/* <HighlightWrap>
          <GameBanner {...GAME_BANNER} />
        </HighlightWrap>
        <CloudBackground>
          <Divider $margin={true} />
          <BannerTitle title="Rainbow token basics" />
          <HighlightWrap className="py-lg-0">
            <InfoCard {...INFO_CARD} reverse />
          </HighlightWrap>
        </CloudBackground>
        <Divider $margin={true} />
        <BannerTitle
          title={
            <>
              Other Ways <span className="break-after">To </span>
              Play & Collect $CU
            </>
          }
        />
        <Divider $margin={true} />
        <CardWrap className="mb-5">
          {GAMES.map((game, i) => (
            <React.Fragment key={`${game.title}-game-banner`}>
              <GameBanner {...game} reverse={i % 2} />
              {i !== GAMES.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </CardWrap>*/}
      </PurpleGradientWrapper>{" "}
      <Footer />{" "}
    </>
  );
}
