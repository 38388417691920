import { useWeb3Query } from "./utils/useWeb3Query";
import { alchemy } from "../services/alchemy";
import { getLandContract } from "../contracts";
import { getUnicorns } from "./utils/unicornHelper";

export const GET_ALL_UNICORNS_QUERY_KEY = "GET_ALL_UNICORNS_QUERY_KEY";
export const GET_ALL_SHADOWCORN_QUERY_KEY = "GET_ALL_SHADOWCORN_QUERY_KEY";
export const GET_ALL_UNICORN_ITEMS_QUERY_KEY = "GET_ALL_UNICORN_ITEMS_QUERY_KEY";
export const GET_ALL_SHADOWCORN_ITEMS_QUERY_KEY = "GET_ALL_SHADOWCORN_ITEMS_QUERY_KEY";
export const GET_ALL_LANDS_QUERY_KEY = "GET_ALL_LANDS_QUERY_KEY";

export const useGetInventoryUnicorns = () => {
  return useWeb3Query({
    queryKey: [GET_ALL_UNICORNS_QUERY_KEY],
    queryFn: async ({ wallet, address }) => {
      const unicornIds = await alchemy.getUnicornIdsByWallet(address);
      const balance = unicornIds.length;

      if (balance === 0) {
        return [];
      }

      return getUnicorns(wallet, unicornIds, balance);
    }
  });
};

export const useGetInventoryShadowcorns = () => {
  return useWeb3Query({
    queryKey: [GET_ALL_SHADOWCORN_QUERY_KEY],
    queryFn: async ({ address }) => {
      return alchemy.getShadowcornsByWallet(address);
    }
  });
};

export const useGetInventoryItems = () => {
  return useWeb3Query({
    queryKey: [GET_ALL_UNICORN_ITEMS_QUERY_KEY],
    queryFn: async ({ address }) => {
      return alchemy.getUnicornItemsByWallet(address);
    }
  });
};

export const useGetInventoryShadowcornItems = () => {
  return useWeb3Query({
    queryKey: [GET_ALL_SHADOWCORN_ITEMS_QUERY_KEY],
    queryFn: async ({ address }) => {
      return alchemy.getShadowcornItemsByWallet(address);
    }
  });
};

export const useGetInventoryLands = () => {
  return useWeb3Query({
    queryKey: [GET_ALL_LANDS_QUERY_KEY],
    queryFn: async ({ wallet, address }) => {
      const ids = await alchemy.getLandIdsByWallet(address);
      const result = [];
      const contract = getLandContract(wallet, address);

      for (const key in ids) {
        const id = ids[key];
        try {
          const uri = await contract.tokenURI(id);
          const metadata = await fetch(uri).then((res) => res.json());
          const landData = await contract.getMetadata(id);

          metadata.tokenID = id;
          metadata.rarity = landData.rarity;
          metadata.class = landData.class;
          metadata.landType = landData.landType;
          metadata.name = landData[9];

          result.push(metadata);
        } catch (error) {
          console.warn("Error fetching metadata for land", id, error);
        }
      }

      return result;
    }
  });
};
