import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";

export const RewardWrapper = styled.div`
  display: flex;

  &.center {
    justify-content: center;
  }

  .reward-group {
    position: relative;
    margin: 0 7px;

    img {
      width: 35px;
      height: 35px;

      @media (${device.lg}) {
        width: 45px;
        height: 45px;
      }

      border-radius: 5px;
    }

    img:nth-of-type(1) {
      position: relative;
      z-index: 10;
    }

    img:nth-of-type(2) {
      position: absolute;
      left: 8px;
      top: -6px;
      transform: scale(0.9);
      z-index: 9;
    }

    img:nth-of-type(3) {
      position: absolute;
      left: 14px;
      top: -12px;
      transform: scale(0.8);
      z-index: 8;
    }

    img:nth-of-type(1n + 4) {
      display: none;
    }
  }
`;
