import React from "react";
import { Image } from "react-bootstrap";

import { calculateMilestone, milestoneConfig } from "../LeaderboardHelper";
import styled from "styled-components";

import milestone_ribbon_1 from "../../../img/leaderboard/milestone_ribbon_1.png";
import milestone_ribbon_2 from "../../../img/leaderboard/milestone_ribbon_2.png";
import milestone_ribbon_3 from "../../../img/leaderboard/milestone_ribbon_3.png";
import milestone_ribbon_4 from "../../../img/leaderboard/milestone_ribbon_4.png";
import milestone_ribbon_5 from "../../../img/leaderboard/milestone_ribbon_5.png";
import milestone_rpg_1 from "../../../img/leaderboard/milestone_rpg_1.png";
import milestone_rpg_3 from "../../../img/leaderboard/milestone_rpg_3.png";
import milestone_rpg_4 from "../../../img/leaderboard/milestone_rpg_4.png";
import milestone_rpg_5 from "../../../img/leaderboard/milestone_rpg_5.png";
import milestone_unicorn_1 from "../../../img/leaderboard/milestone_unicorn_1.png";
import milestone_unicorn_2 from "../../../img/leaderboard/milestone_unicorn_2.png";
import milestone_unicorn_3 from "../../../img/leaderboard/milestone_unicorn_3.png";
import milestone_unicorn_4 from "../../../img/leaderboard/milestone_unicorn_4.png";
import milestone_unicorn_5 from "../../../img/leaderboard/milestone_unicorn_5.png";
import { Tooltip } from "../../base/Tooltip";

const Shape = styled.div`
  position: absolute;
  display: flex;
  visibility: ${({ $hide }) => $hide && "hidden"};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  margin: auto 0;
  margin-left: -30px;
  top: 0px;
  bottom: 0;
  // padding-bottom: 12px;
  // padding-right: 2px;
  font-family: "Insanibc";
  font-size: 17px;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  transition: transform 0.36s ease;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
  z-index: 9;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: -1;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    background: none !important;
    height: auto;
    font-size: 20px;
    color: #221454;
    text-shadow: none;
    top: calc(100% + 15px);

    &:nth-child(even) {
      top: auto;
      bottom: calc(100% + 15px);
    }
  }

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }

  @media screen and (max-width: 425px) {
    font-size: 14px;
  }

  // Desktop only
  @media screen and (min-width: 769px) {
    &.reached {
      transform: scale(1);
    }
  }

  span.subtitle {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -28px;
    // text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02);

    @media screen and (max-width: 1200px) {
      font-family: "Quicksand", sans-serif;
      font-size: 14px;
      margin-bottom: -32px;
      bottom: auto;
    }

    img {
      // margin-right: -1px;
      margin-left: -10px;
      width: 25px;
      height: 25px;

      @media screen and (max-width: 1200px) {
        width: 18px;
        height: 18px;
        margin-left: -7px;
      }
    }
  }

  &:nth-child(1) {
    &.ribbon {
      background: url(${milestone_ribbon_1}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }

    &.rpg {
      background: url(${milestone_rpg_1}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }

    &.unicorn {
      background: url(${milestone_unicorn_1}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }
  }

  &:nth-child(2) {
    &.ribbon {
      background: url(${milestone_ribbon_2}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }

    &.rpg {
      background: url(${milestone_rpg_5}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
      left: 100%;
    }
    &.unicorn {
      background: url(${milestone_unicorn_2}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }
  }

  &:nth-child(3) {
    &.ribbon {
      background: url(${milestone_ribbon_3}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }

    &.rpg {
      background: url(${milestone_rpg_3}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }

    &.unicorn {
      background: url(${milestone_unicorn_3}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }
  }

  &:nth-child(4) {
    &.ribbon {
      background: url(${milestone_ribbon_4}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }

    &.rpg {
      background: url(${milestone_rpg_4}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }

    &.unicorn {
      background: url(${milestone_unicorn_4}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    left: ${({ $position }) => $position}%;
  }

  &:nth-child(5) {
    left: 98%;
    background-color: aqua;

    &.ribbon {
      background: url(${milestone_ribbon_5}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }

    &.rpg {
      background: url(${milestone_rpg_5}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }

    &.unicorn {
      background: url(${milestone_unicorn_5}) no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }

    @media screen and (max-width: 1400px) {
      left: 100%;
    }
    @media screen and (max-width: 1200px) {
      left: 94%;
    }
  }
`;

const RewardWrap = styled.span`
  font-family: "Insanibc";
  font-size: 16px;
  text-shadow: none;
  padding-left: 2px;
  color: ${({ $color }) => ($color ? $color : "#221454")};
`;

const ImageWrap = styled(Image)`
  cursor: pointer;

  & hover {
    filter: brightness(1.1);
  }
`;

const Milestone = (props) => {
  const percentage = (props.value / props.maxValue) * 100;
  return (
    <Shape
      className={`shape ${props.image}  ${
        props.score >= props.value ? "reached" : ""
      } ${
        props.specific
          ? `milestone-specific milestone-${calculateMilestone(
              props.score,
              milestoneConfig
            )} milestone-specific-${props.milestone}`
          : ""
      }`}
      $hide={props.value === null}
      $position={percentage}
      style={{ color: props.color || "" }}
    >
      {props.value && Number(props.value).toLocaleString("en")}
      <span className="subtitle">
        <div style={{ marginLeft: props.lootbox_2 ? "5px" : "" }}>
          {props.lootbox &&
            (props.tooltip ? (
              <Tooltip overlay={props.tooltip} position="bottom">
                <ImageWrap src={props.lootbox} />
              </Tooltip>
            ) : (
              <Image src={props.lootbox} />
            ))}
          {props.reward && (
            <RewardWrap $color={props.color}>{props.reward}</RewardWrap>
          )}
          {props.lootbox_2 &&
            (props.tooltip_2 ? (
              <Tooltip overlay={props.tooltip_2} position="bottom">
                <ImageWrap
                  src={props.lootbox_2}
                  style={{ marginLeft: "-3px" }}
                />
              </Tooltip>
            ) : (
              <Image src={props.lootbox_2} style={{ marginLeft: "-3px" }} />
            ))}
          {props.reward_2 && (
            <RewardWrap $color={props.color}>{props.reward_2}</RewardWrap>
          )}
        </div>
        {Number(props.value) === props.maxValue && props.completionBadge && (
          <Image
            src={props.completionBadge}
            style={{ marginLeft: 3, width: 20, height: 20, borderRadius: 100 }}
          />
        )}
      </span>
    </Shape>
  );
};

export default Milestone;
