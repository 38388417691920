import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "../../components/base/Container";
import { NavBar } from "../../components/base/NavBar";
import { Box } from "../../components/base/Box";
import { InventoryUnicorns } from "../../components/profile/InventoryUnicorns";
import { InventoryLands } from "../../components/profile/InventoryLands";
import { InventoryItems } from "../../components/profile/InventoryItems";
import { InventoryShadowcorns } from "../../components/profile/shadowcorn/InventoryShadowcorns";
import { InventoryIngredients } from "../../components/profile/shadowcorn/InventoryIngredients";
import { InventoryMinions } from "../../components/profile/shadowcorn/InventoryMinions";
import { InventoryLootboxes } from "../../components/profile/shadowcorn/InventoryLootboxes";
import { InventoryRituals } from "../../components/profile/shadowcorn/InventoryRituals";
import useDocumentTitle from "../../helpers/useDocumentTitle";
import { useAtomValue } from "jotai/index";
import { walletConnectedAtom } from "../../store";
import { Typography } from "../../components/base/Typography";
import { Button } from "../../components/base/Button";
import { useConnectWallet } from "@web3-onboard/react";
import { googleAnalyticsEvent } from "../../helpers/googleAnalytics";
import { EVENT_KEYS } from "../../consts/googleEvents";
import { useLocation } from "react-router-dom";

const InventoryWrap = styled.div`
  display: flex;
  align-items: stretch;
  position: relative;
`;

const SubTabWrap = styled(NavBar)`
  padding-top: 0;
`;

const TokenType = {
  UNICORN: "unicorns",
  LAND: "lands",
  ITEM: "items",
  SHADOWCORN: "shadowcorns",
};

const SCTokenType = {
  SHADOWCORN: "shadowcorns",
  MINION: "minions",
  LOOTBOX: "lootboxes",
  INGREDIENT: "ingredients",
  RITUALS: "rituals",
};

const TABS = [
  { title: "Unicorns", type: TokenType.UNICORN },
  { title: "Lands", type: TokenType.LAND },
  { title: "Items", type: TokenType.ITEM },
  { title: "Shadowcorns", type: TokenType.SHADOWCORN },
];

const SC_TABS = [
  { title: "Shadowcorns", type: SCTokenType.SHADOWCORN },
  { title: "Minions", type: SCTokenType.MINION },
  { title: "Ingredients", type: SCTokenType.INGREDIENT },
  { title: "Lootboxes", type: SCTokenType.LOOTBOX },
  { title: "Rituals", type: SCTokenType.RITUALS },
];

const PlaceholderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px;
  min-height: 300px;
`;

export function Inventory() {
  useDocumentTitle("Inventory");
  const location = useLocation();
  const walletConnected = useAtomValue(walletConnectedAtom);
  const [, connect] = useConnectWallet();
  const [tab, setTab] = useState(TABS[0].type);
  const [subTab, setSubTab] = useState(SC_TABS[0].type);
  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get("tab");

  const connectWallet = () => {
    connect();
    googleAnalyticsEvent(EVENT_KEYS.CONNECT_CLICKED);
  };

  useEffect(() => {
    const tabArray = tabParam?.split("-");
    const tabKeys = TABS.map((tabObject) => tabObject.type);
    const tabExists = tabArray && tabKeys.includes(tabArray[0]);

    const subTabKeys = SC_TABS.map((tabObject) => tabObject.type);
    const subTabExists = tabArray && subTabKeys.includes(tabArray[1]);

    if (tabExists) {
      setTab(tabArray[0]);
    }

    if (subTabExists) {
      setSubTab(tabArray[1]);
    }
  }, [tabParam]);

  return (
    <InventoryWrap>
      <Container className="mb-3">
        {/* <div style={{ background: "" }}>
          <NavBar />
        </div> */}
        <Box
          variant="flat"
          rounded="md"
          color={"white"}
          //color="blue"
        >
          {!walletConnected ? (
            <PlaceholderWrap>
              <Typography
                tag="h5"
                color="gray300"
                variant="displaySm"
                className="d-sm-block d-md-none"
              >
                Please connect your wallet on desktop
              </Typography>
              <Typography
                tag="h5"
                color="gray300"
                variant="displayMd"
                className="d-none d-md-block"
              >
                Please connect your wallet
              </Typography>
              <Button
                variant="primary"
                onClick={connectWallet}
                className="d-none d-md-block"
              >
                Connect
              </Button>
            </PlaceholderWrap>
          ) : (
            <>
              <NavBar type={tab} setType={setTab} TABS={TABS} />
              {tab === TokenType.UNICORN && <InventoryUnicorns />}
              {tab === TokenType.LAND && <InventoryLands />}
              {tab === TokenType.ITEM && <InventoryItems />}
              {tab === TokenType.SHADOWCORN && (
                <>
                  <SubTabWrap
                    type={subTab}
                    setType={setSubTab}
                    TABS={SC_TABS}
                    background="#4B4FE4"
                    button={true}
                    pattern={false}
                  />
                  {subTab === SCTokenType.SHADOWCORN && (
                    <InventoryShadowcorns />
                  )}
                  {subTab === SCTokenType.MINION && <InventoryMinions />}
                  {subTab === SCTokenType.INGREDIENT && (
                    <InventoryIngredients />
                  )}
                  {subTab === SCTokenType.LOOTBOX && <InventoryLootboxes />}
                  {subTab === SCTokenType.RITUALS && <InventoryRituals />}
                </>
              )}
            </>
          )}
        </Box>
      </Container>
    </InventoryWrap>
  );
}