export class CurrencyToken {
    constructor(
      name,
      value,
      decimals = 18
    ) {
      this.name = name;
      this.value = value;
      this.decimals = decimals;
    }
  
    get parsed() {
      return parseFloat((this.value / Math.pow(10, this.decimals)).toFixed(2));
    }
  
    static fromJSON(json) {
      return new CurrencyToken(
        json.name,
        json.value,
        json.decimals || 18
      );
    }
  }