import { Row as BSRow } from "react-bootstrap";
import styled from "styled-components";

const RowWrap = styled(BSRow)`
  ${({ height }) => height && `height: ${height};`}
  ${({ $gap }) => $gap && `gap: ${$gap};`}
`;

export function Row({ gap, ...rest }) {
  return (
    <RowWrap $gap={gap} {...rest} />
  );
}