import styled from "styled-components";
import { Button } from "../../base/Button";
import { Icon } from "../../base/Icon";
import { useSetAtom } from "jotai/index";
import { stakeModalOpenedAtom } from "../../../store/staking";
import { device } from "../../../theme/mediaQuery";
import { TOKEN_NAMES } from "../Modals/AssetSelector";

const Wrap = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-direction: column;
  border: 1px dashed #5338C2;
  border-radius: 16px;
  background: #462170;
  padding: 30px;
  @media (${device.md}) {
    padding: 40px;
  }
`;

const PlaceholderTextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: auto;
  padding-bottom: 30px;
`;

const PlaceholderText = styled.div`
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.palette.white};
  text-align: center;
`;

const PlaceholderIcon = styled.div`
  width: 63px;
  height: 63px;
  color: #5338C2;

  & svg {
    fill: transparent;
  }
`;

const PlaceholderButtonWrap = styled.div`
  max-width: 520px;
  width: 100%;
  margin: 0 auto;
`;

export function StakingRewardsPlaceholder({
  title,
  ctaText,
  setStakeInitialTab,
  initialTab = TOKEN_NAMES.CU,
}) {
  const setShowStakeModal = useSetAtom(stakeModalOpenedAtom);

  const handleStakeClick = () => {
    setStakeInitialTab(initialTab);
    setShowStakeModal(true);
  };

  return (
    <Wrap>
      <PlaceholderTextSection>
        <PlaceholderIcon>
          <Icon name="placeholder-plus" size="100%" />
        </PlaceholderIcon>
        <PlaceholderText>
          {title}
        </PlaceholderText>
      </PlaceholderTextSection>
      <PlaceholderButtonWrap>
        <Button
          variant="secondary"
          size="lg"
          block
          onClick={handleStakeClick}
        >
          {ctaText}
        </Button>
      </PlaceholderButtonWrap>
    </Wrap>
  );
}
