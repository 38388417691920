import styled from "styled-components";
import { Button } from "../base/Button";
import { useConnectModal } from "thirdweb/react";
import { connectWallet } from "../ConnectWallet/connectWallet";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  //background: #3C1568;
  background: rgba(255, 255, 255, 0.1);
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 22px;
  color: ${({ theme }) => theme.palette.white};
  border-radius: 16px;
  padding: 20px 40px;
  text-align: center;
  min-height: 250px;
  max-width: 600px;
  margin: 0 auto;
`;

const Description = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

export function StakingConnectWalletPlaceholder() {
  const { connect } = useConnectModal();

  return (
    <Wrap>
      <h3>Please connect your wallet to begin staking</h3>
      <Description>
        You can connect your wallet to start staking and earn rewards
      </Description>
      <Button variant="primary" onClick={() => connectWallet(connect)}>
        Connect Wallet
      </Button>
    </Wrap>
  );
}
