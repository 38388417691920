import { useAtomValue } from "jotai/index";
import { trimmedCurrentWalletAddress, walletConnectedAtom } from "../../store";
import React from "react";
import { Button } from "../base/Button";
import { DropDown, DropDownItem, DropDownMenu } from "../base/Dropdown";
import { Link, useMatch } from "react-router-dom";
import { NavItem } from "./NavItem";
import { Icon } from "../base/Icon";
import rbw from "../../img/currencies/rbw.png";
import matic from "../../img/currencies/matic.png";
import unim from "../../img/currencies/unim.png";
import weth from "../../img/currencies/weth.png";
import cu from "../../img/currencies/rbw.png";
import xai from "../../img/currencies/xai.png";
import styled from "styled-components";
import { device } from "../../theme/mediaQuery";
import { googleAnalyticsEvent } from "../../helpers/googleAnalytics";
import { EVENT_KEYS } from "../../consts/googleEvents";
import { BridgePendingTransactionButton } from "../bridge/BridgePendingTransactionButton";
import { useGetWalletTokens } from "../../query/tokens";
import {
  useActiveWallet,
  useConnectModal,
  useDisconnect,
} from "thirdweb/react";
import { connectWallet } from "../ConnectWallet/connectWallet";
import { useWalletDetailsModal } from "thirdweb/react";
import { createThirdwebClient } from "thirdweb";
import { NetworkConfiguration } from "../Network";
import { POLYGON_NETWORK } from "../ConnectWallet/WalletHelper";
import { CRYPTO } from "../../consts/externalLinks";

const BalanceWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: ${({ theme }) => theme.palette.slate50};
  border-radius: 4px;
  padding: 8px;

  & li {
    font-size: 14px;

    &:hover {
      color: ${({ theme }) => theme.palette.gray300};
    }
  }
`;

const DropDownWrap = styled(DropDown)`
  width: 100%;
  @media (${device.xxl}) {
    width: auto;
  }
`;

const ImageWrap = styled.img`
  width: 24px;
  height: auto;
`;

const BalanceTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.display};
  color: #283a58;
  font-size: 14px;
  padding: 0 0.25rem 10px 0.25rem;
  border-bottom: 1px solid #dce7f2;
`;

const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

// const Backdrop = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 9;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5);
// `;

export function NavActions() {
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const walletConnected = useAtomValue(walletConnectedAtom);
  const shortWalletAddress = useAtomValue(trimmedCurrentWalletAddress);
  const { data } = useGetWalletTokens();
  const {
    UNIM = 0,
    RBW = 0,
    ETH = 0,
    MATIC = 0,
    CU = 0,
    UNIMXAI = 0,
    XAI = 0,
  } = data || {};
  const [showProfileMenu, setShowProfileMenu] = React.useState(false);
  const { connect } = useConnectModal();
  const { disconnect } = useDisconnect();
  const isProfilePage = useMatch("/profile/:subpage");
  const isClaimPage = useMatch("/claim");
  const isBridgePage = useMatch("/bridge");
  const wallet = useActiveWallet();
  const connectUser = () => {
    connectWallet(connect);
    googleAnalyticsEvent(EVENT_KEYS.CONNECT_CLICKED);
  };
  const handleDisconnect = () => {
    disconnect(wallet);
  };

  const client = createThirdwebClient({
    clientId: netconfig.clientID,
  });

  const { open } = useWalletDetailsModal();
  function handleClick() {
    open({ client, theme: "dark" });
  }

  // const wcSession = () => {
  //   localStorage.removeItem("wc@2:client:0.3//session");
  // };

  if (walletConnected) {
    return (
      <ActionsWrap>
        {isBridgePage && <BridgePendingTransactionButton />}
        <DropDownWrap>
          <NavItem
            title={shortWalletAddress}
            onClick={() => setShowProfileMenu(!showProfileMenu)}
            appendIcon={"arrow-down"}
            prependIcon={"user"}
            active={isClaimPage || isProfilePage}
          />

          <DropDownMenu
            show={showProfileMenu}
            onClose={() => setShowProfileMenu(false)}
          >
            <a
              href={CRYPTO.MARKETPLACE_PROFILE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DropDownItem
                // as={Link}
                // to={CRYPTO.MARKETPLACE_PROFILE}
                prepend={<Icon name="user" width={24} />}
                className="p-1"
              >
                Profile
              </DropDownItem>
            </a>
            <DropDownItem
              as={Link}
              to={"/claim"}
              prepend={<Icon name="reward" width={24} />}
              className="p-1"
            >
              Rewards
            </DropDownItem>
            <DropDownItem
              onClick={handleClick}
              prepend={<Icon name="wallet" width={24} />}
              className="p-1">
              View Wallet
            </DropDownItem>
            <BalanceWrap>
              <BalanceTitle>Account balance</BalanceTitle>
              <DropDownItem
                prepend={<ImageWrap src={rbw} alt="RBW Logo" />}
                append={RBW.toLocaleString()}
                className="p-1"
              >
                RBW
              </DropDownItem>
              <DropDownItem
                prepend={<ImageWrap src={unim} alt="UNIM Logo" />}
                append={Number(UNIM).toFixed(2).toLocaleString()}
                className="p-1"
              >
                UNIM
              </DropDownItem>
              <DropDownItem
                prepend={<ImageWrap src={matic} alt="Matic Logo" />}
                append={MATIC.toLocaleString()}
                className="p-1"
              >
                Matic
              </DropDownItem>
              <DropDownItem
                prepend={<ImageWrap src={cu} alt="CU Logo" />}
                append={CU.toLocaleString()}
                className="p-1"
              >
                CU
              </DropDownItem>
              <DropDownItem
                prepend={<ImageWrap src={weth} alt="ETH Logo" />}
                append={ETH.toLocaleString()}
                className="p-1"
              >
                ETH (Arbitrum)
              </DropDownItem>
              <DropDownItem
                prepend={<ImageWrap src={xai} alt="ETH Logo" />}
                append={XAI.toLocaleString()}
                className="p-1"
              >
                XAI
              </DropDownItem>
              <DropDownItem
                prepend={<ImageWrap src={unim} alt="ETH Logo" />}
                append={UNIMXAI.toLocaleString()}
                className="p-1"
              >
                UNIM (XAI)
              </DropDownItem>
            </BalanceWrap>

            <DropDownItem
              onClick={() => {
                // wcSession();
                handleDisconnect();
              }}
              prepend={<Icon name="logout" width={24} />}
              className="p-1"
            >
              Disconnect Wallet
            </DropDownItem>
          </DropDownMenu>
        </DropDownWrap>
      </ActionsWrap>
    );
  } else {
    return (
      <Button
        xxl={{ block: false }}
        variant="primary"
        block={true}
        onClick={() => connectUser()}
        size="sm"
      >
        Connect Wallet
      </Button>
    );
  }
}
