import styled from "styled-components";
import { LeaderboardStakingTable } from "../../components/leaderboard/LeaderboardStaking";

const LeaderboardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export function StakingLeaderboard() {
  return (
    <LeaderboardWrap>
      <LeaderboardStakingTable isStakingPage includeSummary />
    </LeaderboardWrap>
  );
}
