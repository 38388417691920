import { Container } from "react-bootstrap";
import { SectionCard } from "../../common/SectionCard";
import styled from "styled-components";
import { Box } from "../../base/Box";
import { Button } from "../../base/Button";
import { Link } from "react-router-dom";
import { Typography } from "../../base/Typography";
import { LeaderboardUnicornBattleTable } from "../../leaderboard/LeaderboardUnicornBattle";

const LeaderboardTableWrap = styled.div`
  padding: 35px 0;
`;

const SectionWrap = styled(Box)`
  padding: 100px 0;
`;

const SubtitleWrap = styled.div`
  text-align: center;
`;

const ActionsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 48px;
`;

export function LeaderboardSection() {
  return (
    <SectionWrap
      tag="section"
      gradient="linear-gradient(180deg, #5118A4 0%, #180468 100%);"
      pattern="labyrinth"
      patternSize="30%"
    >
      <Container>
        <SectionCard title="Leaderboards">
          <SubtitleWrap>
            <Typography color="white" variant="displayMd" tag="h3">
              Prepare For War
            </Typography>
          </SubtitleWrap>
          <LeaderboardTableWrap>
            <LeaderboardUnicornBattleTable />
          </LeaderboardTableWrap>
          <ActionsWrap>
            <Button as={Link} variant="secondary" to="/leaderboard">
              view all leaderboards
            </Button>
          </ActionsWrap>
        </SectionCard>
      </Container>
    </SectionWrap>
  );
}
