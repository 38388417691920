import styled from "styled-components";
import { device } from "../../theme/mediaQuery";

export const Chip = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.palette.indigo300};
  padding: 4px 16px;
  border-radius: 56px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.white};
  white-space: break-spaces;
  text-align: center;
  @media (${device.xl}) {
    font-size: ${({ $size }) => ($size === "sm" ? "11px" : "18px")};
    padding: ${({ $size }) => ($size === "sm" ? "8px 16px" : "8px 24px")};
  }

  & * {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
  }
`;
