import rbw_img from "../../img/currencies/rbw-tilted.png";
import grail_img from "../../img/currencies/grail.png";
import arb_img from "../../img/currencies/arbitrum.png";
import xai_img from "../../img/currencies/xai-red.png";
import culp_img from "../../img/currencies/culp.png";
import { StakingTokenTypes } from "./constants";

export const TokenImagesMap = {
  [StakingTokenTypes.cu]: rbw_img,
  [StakingTokenTypes.culp]: culp_img,
  [StakingTokenTypes.grail]: grail_img,
  [StakingTokenTypes.arb]: arb_img,
  [StakingTokenTypes.xai]: xai_img,
  [StakingTokenTypes.rbw]: rbw_img,
};

export function StakingTokenImage({ token }) {
  return (
    <img src={TokenImagesMap[token]} alt={token} />
  );
}