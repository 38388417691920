import styled from "styled-components";
import { device } from "../../theme/mediaQuery";

const NavMenuWrap = styled.div`
  background: ${({ theme }) => theme.palette.white};
  opacity: ${({ $active }) => ($active ? "1" : "0")};
  max-height: ${({ $active }) => ($active ? "2000px" : "0")};
  transition: all 0.3s ease-in-out;
  margin-top: 8px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

  @media (${device.xxl}) {
    // show float menu on large screen
    position: absolute;
    top: 100%;
    left: ${({ $isFullSizeMenu }) => ($isFullSizeMenu ? "3%" : "-100%")};
    right: ${({ $isFullSizeMenu }) => ($isFullSizeMenu ? "3%" : "auto")};
    ${({ $isFullSizeMenu }) => !$isFullSizeMenu && "min-width: 450px;"}
    transform: ${({ $active }) =>
      $active ? "translateY(0)" : "translateY(-10px)"};
    visibility: ${({ $active }) => ($active ? "visible" : "hidden")};
    margin-top: initial;
    max-height: initial;
  }
`;

export function NavMenu({ active, menuRef, isFullSizeMenu, children }) {
  return (
    <NavMenuWrap
      ref={menuRef}
      $active={active}
      $isFullSizeMenu={isFullSizeMenu}
    >
      {children}
    </NavMenuWrap>
  );
}
