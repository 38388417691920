import styled from "styled-components";
import { StakingBadgesDelegatable } from "../../components/staking/badges/StakingBadgesDelegatable";
import { StakingBadgesEscrowed } from "../../components/staking/badges/StakingBadgesEscrowed";
import { StakingBadgesCumulativeCard } from "../../components/staking/badges/StakingBadgesCumulativeCard";
import { StakingBadgesNotification } from "../../components/staking/badges/StakingBadgesNotification";
import { useGetVaultBadges } from "../../query/vault";
import { device } from "../../theme/mediaQuery";
import { formatStringWithFixedFraction } from "../../components/Helpers";
import { NetworkConfiguration } from "../../components/Network";
import { POLYGON_NETWORK } from "../../components/ConnectWallet/WalletHelper";
import { defineChain } from "thirdweb";
import { useSwitchActiveWalletChain } from "thirdweb/react";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const CumulativeWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media (${device.lg}) {
    align-items: center;
    flex-direction: row;
  }
`;

export function StakingBadges() {
  const { data = {}, isLoading } = useGetVaultBadges();
  if (!isLoading) {
    console.log("badges data", data);
  }
  const totalBoostFactor = formatStringWithFixedFraction(
    data?.totalBoostFactor || 0,
    0,
    3
  );
  const totalBoostFactorLP = formatStringWithFixedFraction(
    data?.totalBoostFactorLP || 0,
    0,
    3
  );
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const arbChain = defineChain({
    id: netconfig.networkIdArbitrum,
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
  });
  const switchChain = useSwitchActiveWalletChain();
  return (
    <Wrap>
      <CumulativeWrap>
        <StakingBadgesCumulativeCard
          title="CU Badge Boost"
          value={totalBoostFactor}
        />
        <StakingBadgesCumulativeCard
          title="CULP Badge Boost"
          value={totalBoostFactorLP}
        />
      </CumulativeWrap>
      <StakingBadgesDelegatable switchChain={switchChain} chain={arbChain} />
      <StakingBadgesEscrowed />
      <StakingBadgesNotification />
    </Wrap>
  );
}
