import Web3 from "web3";
import CU_Rituals_ABI from "../CU_Rituals_ABI.json";
// import wretch from "wretch";
import { LOOTBOX_ITEM_POOL_IDS } from "../consts/tokenPools";
import { NetworkConfiguration } from "../components/Network";
import { POLYGON_NETWORK } from "../components/ConnectWallet/WalletHelper";

export class NotAnOwnerError extends Error {
  constructor(message) {
    super(message);
    this.name = "NonAnOwnerError";
  }
}

export function getRitualContract(wallet, walletAddress) {
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];

  const web3 = new Web3(wallet.provider);

  const contract = new web3.eth.Contract(
    CU_Rituals_ABI,
    netconfig.ritualsAddress
  );

  return {
    getRitualDetails(tokenId) {
      return contract.methods.getRitualDetails(tokenId).call({
        from: walletAddress,
      });
    },
    ownerOf(tokenId) {
      return contract.methods.ownerOf(tokenId).call({
        from: walletAddress,
      });
    },
    tokenURI(tokenId) {
      return contract.methods.tokenURI(tokenId).call({
        from: walletAddress,
      });
    },
    getRitualsByOwner(address) {
      return contract.methods.getRitualsByOwner(address).call();
    },
    getRitualsByOwnerPaginated(address, page) {
      return contract.methods.getRitualsByOwnerPaginated(address, page).call();
    },
  };
}

export async function getRitualByTokenId(wallet, walletAddress, tokenId) {
  const contract = getRitualContract(wallet, walletAddress);

  const tokenURI = await contract.tokenURI(tokenId);
  const base64 = tokenURI.replace("data:application/json;base64,", "");
  const tokenData = JSON.parse(atob(base64));
  const ritualDetails = await contract.getRitualDetails(tokenId);

  let constraintsArr = [];
  // eslint-disable-next-line array-callback-return
  ritualDetails.constraints.map((constraint) => {
    constraintsArr.push(constraint);
  });

  let costsArr = [];
  // eslint-disable-next-line array-callback-return
  ritualDetails.costs.map((cost) => {
    costsArr.push({
      amount: cost.component[0],
      assetType: cost.component[1],
      poolId: cost.component[2],
      asset: cost.component[3],
    });
  });

  let productsArr = [];
  // eslint-disable-next-line array-callback-return
  ritualDetails.products.map((product) => {
    productsArr.push({
      amount: product.component[0],
      assetType: product.component[1],
      poolId: product.component[2],
      asset: product.component[3],
    });
  });

  return {
    ...ritualDetails,
    tokenId: tokenId,
    image: tokenData.image,
    attributes: [
      {
        traitType: "Charges",
        value: ritualDetails.charges,
        displayType: null,
        maxValue: null,
      },
      {
        traitType: "Costs",
        value: costsArr,
        displayType: null,
        maxValue: null,
      },
      {
        traitType: "Products",
        value: productsArr,
        displayType: null,
        maxValue: null,
      },
      {
        traitType: "Constraints",
        value: constraintsArr,
        displayType: null,
        maxValue: null,
      },
      {
        traitType: "Rarity",
        value: "",
        displayType: null,
        maxValue: null,
      },
    ],
  };
}

function isLootboxRitual(ritual) {
  // we can tell if a ritual is a lootbox by looking at the costs attribute
  const costAttribute = ritual.costs;
  const costsPoolIds = costAttribute.map((cost) => cost.poolId);
  const isLootbox = costsPoolIds.some((poolId) =>
    LOOTBOX_ITEM_POOL_IDS.includes(parseInt(poolId))
  );

  return isLootbox;
}

// we need to serialize the ritual object to JSON because the contract returns a lot of tuples
function serializeRitualToJson(ritual, tokenId) {
  return {
    tokenId,
    name: ritual[0],
    rarity: ritual[1],
    costs: ritual[2].map((cost) => cost.component),
    products: ritual[3].map((product) => product.component),
    constraints: ritual[4].map((constraint) => constraint),
    charges: ritual[5],
  };
}

async function getRitualsForOwner(contract, address) {
  const ritualsIds = []; // the first array contains the tokenIds
  const ritualsArray = []; // the second array contains the rituals objects

  let page = 0;
  let morePages = true;
  // we need to paginate the results because the contract only returns 50 rituals at a time
  while (morePages) {
    const ritualsPaginated = await contract.getRitualsByOwnerPaginated(
      address,
      page
    );

    const ritualsIdsArray = ritualsPaginated[0];
    const ritualsInfoArray = ritualsPaginated[1];
    const moreEntriesExists = ritualsPaginated[2];

    ritualsIds.push(...ritualsIdsArray);
    ritualsArray.push(...ritualsInfoArray);

    page++;
    morePages = moreEntriesExists;
  }

  return ritualsArray.map((ritual, index) =>
    serializeRitualToJson(ritual, ritualsIds[index])
  );
}

async function getAllRitualsForUser(contract, walletAddress) {
  // the final array of rituals contains both the user's rituals and the generic ones
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const userRituals = await getRitualsForOwner(contract, walletAddress);
  const genericRituals = await getRitualsForOwner(
    contract,
    netconfig.ritualsGenericAddress
  );

  return [...userRituals, ...genericRituals];
}

export async function getRituals({ wallet, address }) {
  const contract = getRitualContract(wallet, address);
  const result = await getAllRitualsForUser(contract, address);
  const filtered = result.filter((ritual) => !isLootboxRitual(ritual));
  const sortedArray = filtered.sort(
    (a, b) => parseInt(b.tokenId) - parseInt(a.tokenId)
  );

  const promises = sortedArray.map(async (item) => {
    const tokenURI = await contract.tokenURI(item.tokenId);
    const base64 = tokenURI.replace("data:application/json;base64,", "");
    const tokenData = JSON.parse(atob(base64));
    return {
      ...item,
      image: tokenData.image,
    };
  });

  const filteredWithImages = await Promise.all(promises);
  return filteredWithImages;
}

export async function getLootboxRituals(wallet, walletAddress) {
  const contract = getRitualContract(wallet, walletAddress);
  const result = await getAllRitualsForUser(contract, walletAddress);

  return result.filter((ritual) => isLootboxRitual(ritual));
}
