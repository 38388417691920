import { useState } from "react";
import styled from "styled-components";
import { TokenCard } from "../TokenCard";
import { getAttribute } from "../../Helpers";
import { InventoryTokens } from "../InventoryTokens";
import Spinner from "../../base/Spinner";
import { Typography } from "../../base/Typography";
import { Button } from "../../base/Button";
import { ItemsModal } from "../ItemsModal";
import { BUY } from "../../../consts/externalLinks";
import { useGetInventoryShadowcornItems } from "../../../query/inventory";
import { MINION_ITEM_POOL_IDS } from "../../../consts/tokenPools";

const pluralize = require("pluralize");

const PlaceholderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px;
  min-height: 528px;
  padding: 0 10px;
`;

const filters = [
  {
    title: "Class",
    options: [
      { value: null, title: "All", icon: "all" },
      { value: "Fire", title: "Fire", icon: "fire" },
      { value: "Nebula", title: "Nebula", icon: "nebula" },
      { value: "Slime", title: "Slime", icon: "slime" },
      { value: "Soul", title: "Soul", icon: "soul" },
      { value: "Volt", title: "Volt", icon: "volt" }
    ],
    id: "class",
    getter: (item) => getAttribute("Class", item)
  }
];

const filterGetters = {
  class: (item) => getAttribute("Class", item)
};

export function InventoryIngredients() {
  const { data = [], isLoading } = useGetInventoryShadowcornItems();
  const [showModal, setShowModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);

  const items = data?.filter((item) => !MINION_ITEM_POOL_IDS.includes(item.poolId));

  const handleSelectIngredient = (item) => {
    setSelectedToken(item);
    setShowModal(true);
  };

  // let _toolbarTitle = `${data.length} ingredients owned`;
  let _toolbarTitle = `${pluralize(
    "ingredient type",
    items.length,
    true
  )} owned`;

  if (isLoading) {
    return (
      <PlaceholderWrap>
        <Spinner color="black" />
      </PlaceholderWrap>
    );
  }

  return (
    <>
      {items && items.length > 0 && (
        <>
          <InventoryTokens
            title={_toolbarTitle}
            data={items}
            filters={filters}
            filterGetters={filterGetters}
            renderCard={(item) => (
              <TokenCard
                onClick={() => {
                  handleSelectIngredient(item);
                }}
                image={item.image}
                name={item.name}
                countTag={item.count}
                setShowModal={setShowModal}
                rightAttribute={{
                  title: "Class",
                  value: getAttribute("Class", item)
                }}
              />
            )}
          />

          <ItemsModal
            showModal={showModal}
            setShowModal={setShowModal}
            item={selectedToken}
            classIcon={
              selectedToken &&
              getAttribute("Class", selectedToken)?.toLowerCase()
            }
          />
        </>
      )}
      {items && items.length === 0 && !isLoading && (
        <PlaceholderWrap>
          <Typography tag={"h3"} variant={"textLg"} color="gray300">
            🎁 Missing magical items? 🌟
          </Typography>
          <Typography tag={"h3"} variant={"textMd"} color="gray300">
            Elevate your Crypto Unicorns experience! Find lootboxes, tickets,
            potions and nft in-game items to boost your game play experience.
          </Typography>
          <a
            href={BUY.MARKETPLACE.UNICORN_ITEMS}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button block={true} prependIcon="cart">
              Visit Marketplace
            </Button>
          </a>
        </PlaceholderWrap>
      )}
    </>
  );
}
