import labyrinth from "../../img/pattern/labyrinth.png";
import sprinkles from "../../img/pattern/sprinkles.png";
import petroglyph from "../../img/pattern/petroglyph.png";
import dots from "../../img/pattern/dots.png";
import dots_reverse from "../../img/pattern/dots-reverse.png";
import swirly from "../../img/pattern/swirly.png";
import hawaii from "../../img/pattern/hawaii.png";
import zebra from "../../img/pattern/zebra.png";
import tribal from "../../img/pattern/tribal.png";
import soil from "../../img/pattern/soil.png";
import ripple from "../../img/pattern/ripple.png";
import landGreen from "../../img/pattern/land-green.png";
import landPurple from "../../img/pattern/land-purple.png";
import waves from "../../img/pattern/waves.png";
import lines from "../../img/pattern/lines.png";

export const commonPatterns = {
  labyrinth: labyrinth,
  sprinkles: sprinkles,
  petroglyph: petroglyph,
  dots: dots,
  "dots-reverse": dots_reverse,
  swirly: swirly,
  hawaii: hawaii,
  zebra: zebra,
  tribal: tribal,
  soil: soil,
  ripple: ripple,
  landGreen: landGreen,
  landPurple: landPurple,
  waves: waves,
  lines: lines,
};
