import styled from "styled-components";
import { Typography } from "../../base/Typography";
import { device } from "../../../theme/mediaQuery";

const Card = styled.div`
  background: #f0f1ff;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1;
  justify-content: center;

  & > * {
    line-height: normal;
  }

  & > h3 {
    letter-spacing: 0.85px;
  }
`;

const IconWrap = styled.img`
  width: 24px;
  height: 24px;
  @media (${device.md}) {
    width: 32px;
    height: 32px;
  }
`;

export const StatCard = ({ value, label, img, ...rest }) => {
  return (
    <Card {...rest}>
      {img && <IconWrap src={img} alt="CU logo" />}
      <Typography
        as="h3"
        color="#5338C2"
        variant="textXl"
        md={{ variant: "displayXs" }}
      >
        {value}
      </Typography>
      <Typography
        as="span"
        color="black"
        family="body"
        variant="textSm"
        md={{ variant: "textMd" }}
      >
        {label}
      </Typography>
    </Card>
  );
};
