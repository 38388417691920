// Because attributes from JSON might not always be in a specific order,
// this helper function ensures we're getting the proper values
// Example: getAttribute("attack", jsonData);

// Returns: string
export function getAttribute(name, obj) {
  let _attributes = obj?.attributes;
  if (_attributes) {
    for (let i = 0; i < _attributes.length; i++) {
      if (_attributes[i].trait_type.toLowerCase() === name.toLowerCase()) {
        return _attributes[i].value;
      }
    }
  }

  return null;
}

// Used for Land tokens only
// The JSON has the class located in different locations
// This function returns based on the type ("Mythic", "Rare", or "Common")
// Returns: string
export function getClass(item) {
  let type = getAttribute("Rarity", item);

  switch (type?.toLowerCase()) {
    case "mythic":
      return "Mythic";
    case "rare":
      return getAttribute("class group", item);
    case "common":
      return getAttribute("class", item);
    default:
      return null;
  }
}

// Used for Land tokens only
// The JSON has the rarity located in potentially different locations in the JSON
// This function returns based on the type ("Mythic", "Rare", or "Common")
// Returns: string
export function getRarity(item) {
  let type = getAttribute("Rarity", item);

  if (type) return type.toLowerCase();

  return null;
}

// Used for Minions Only
// This function returns based on the tier ("Tier One", "Tier Two", etc.)
// Returns: string
export function getTier(item) {
  let type = getAttribute("Tier", item);

  switch (type) {
    case 1:
      return "Tier One";
    case 2:
      return "Tier Two";
    case 3:
      return "Tier Three";
    default:
      return null;
  }
}

// Used for Minions only
// This function returns based on the name and level of minion ("fire", "soul", and "1", "2")
// Returns: string
export function getMinionVideo(name, level) {
  return `/videos/minion/level-${level}/${name}.webm`;
}

// This function transforms pool id to the associated class
// poolIdMapping is an obj where the key is the pool id and the value is the class (i.e { [FIRE_POOL_ID]: 'Fire'})
// Returns: string or null
export function getMinionClass(poolId, poolIdMapping) {
  return poolIdMapping[poolId] || null;
}

// Get current wallet balance
// Returns: integer
export async function getContractBalance(contract, wallet, walletAddress) {
  let _balance = 0;
  _balance = await contract.methods
    .balanceOf(wallet?.accounts[0]?.address)
    .call();

  return Number(_balance);
}

// Used to get tokenURI JSON per contract
// Returns: JSON array

export async function getContractJSON(contract, wallet, setLoadCount) {
  const balance = await getContractBalance(contract, wallet);
  let jsonArr = [];

  for (let index = 0; index < Number(balance); index++) {
    // Timeout to avoid rate limiting
    await new Promise((resolve) => setTimeout(resolve, 100 * index));

    const tokenID = await contract.methods
      .tokenOfOwnerByIndex(wallet?.accounts[0]?.address, index)
      .call();

    let tokenURI = await contract.methods.tokenURI(Number(tokenID)).call();

    if (!tokenURI || tokenURI?.includes("undefined")) {
      console.log(`Missing tokenURI. TokenID: ${tokenID}`);
    } else {
      const res = await fetch(tokenURI);
      let itemJson = await res.json();

      if (contract.methods?.getLandMetaData) {
        const landData = await contract.methods.getLandMetaData(tokenID).call();

        itemJson.tokenID = tokenID;
        itemJson.rarity = landData.rarity;
        itemJson.class = landData.class;
        itemJson.landType = landData.landType;
        itemJson.name = landData[9];
      }

      setLoadCount((c) => c + 1);
      jsonArr.push(itemJson);
    }
  }

  return jsonArr;
}

// export async function getContractJSON(contract, wallet, setLoadCount) {
//   // Get current wallet balance
//   const _balance = await getContractBalance(contract, wallet);

//   let _jsonArr = [];
//   let promises = [];

//   for (let index = 0; index < Number(_balance); index++) {
//     promises.push(
//       new Promise(async function (resolve) {
//         // Timeout to avoid error 429: Rate limit of 40 calls per 1 second
//         //  Since we can only make 40 calls/1 second, and each loop makes 2 calls, minimum is
//         //  ~50 milliseconds in delay
//         setTimeout(async () => {
//           // Get the token ID from balance
//           const tokenID = await contract.methods
//             .tokenOfOwnerByIndex(wallet?.accounts[0]?.address, index)
//             .call();

//           // Get tokenURI
//           let tokenURI = await contract.methods
//             .tokenURI(Number(tokenID))
//             .call();

//           // Debugging shim for Land missing tokenURIs
//           // if (!tokenURI) {
//           //   tokenURI =
//           //     "https://arweave.net/FpIWpx6vfqWmHzgjRKC9Gc4UnlzGYD6x7sCnMtkgO5I"; // Rare
//           // }

//           if (!tokenURI || tokenURI?.includes("undefined")) {
//             console.log(`Missing tokenURI. TokenID: ${tokenID}`);
//           } else {
//             //console.log(landData, "land");
//             const res = await fetch(tokenURI);
//             let itemJson = await res.json();

//             // Shim for getting Land metadata
//             if (contract.methods?.getLandMetaData) {
//               const landData = await contract.methods
//                 .getLandMetaData(tokenID)
//                 .call();

//               itemJson.tokenID = tokenID;
//               itemJson.rarity = landData.rarity;
//               itemJson.class = landData.class;
//               itemJson.landType = landData.landType;
//               itemJson.name = landData[9];

//               // console.log("landData: ", itemJson);
//             }

//             // Used for "Loading X of BALANCE"
//             setLoadCount((c) => c + 1);
//             _jsonArr.push(itemJson);
//           }

//           resolve(tokenID);
//         }, 250 * index);
//       })
//     );
//   }

//   await Promise.allSettled(promises);
//   return _jsonArr;
// }

function inRange(x, min, max) {
  return min <= x && x <= max;
}

// A shim to temporarily fix missing metadata
export function getLandClassByTokenID(id) {
  let _id = Number(id);
  switch (true) {
    case inRange(_id, 1, 1000) ||
      inRange(_id, 10001, 11000) ||
      inRange(_id, 20001, 21000):
      return "Mythic";
    case inRange(_id, 1001, 2000) ||
      inRange(_id, 11001, 12000) ||
      inRange(_id, 21001, 22000):
      return "Light";
    case inRange(_id, 2001, 3000) ||
      inRange(_id, 12001, 13000) ||
      inRange(_id, 22001, 23000):
      return "Wonder";
    case inRange(_id, 3001, 4000) ||
      inRange(_id, 13001, 14000) ||
      inRange(_id, 23001, 24000):
      return "Mystery";
    case inRange(_id, 4001, 5000) ||
      inRange(_id, 14001, 15000) ||
      inRange(_id, 24001, 25000):
      return "Heart";
    case inRange(_id, 5001, 6000) ||
      inRange(_id, 15001, 16000) ||
      inRange(_id, 25001, 26000):
      return "Cloud";
    case inRange(_id, 6001, 7000) ||
      inRange(_id, 16001, 17000) ||
      inRange(_id, 26001, 27000):
      return "Flower";
    case inRange(_id, 7001, 8000) ||
      inRange(_id, 17001, 18000) ||
      inRange(_id, 27001, 28000):
      return "Candy";
    case inRange(_id, 8001, 9000) ||
      inRange(_id, 18001, 19000) ||
      inRange(_id, 28001, 29000):
      return "Crystal";
    case inRange(_id, 9001, 10000) ||
      inRange(_id, 19001, 20000) ||
      inRange(_id, 29001, 30000):
      return "Moon";
    default:
      return null;
  }
}

export function getLandRarityByTokenID(id) {
  let _id = Number(id);
  switch (true) {
    case inRange(_id, 1, 1000) ||
      inRange(_id, 10001, 11000) ||
      inRange(_id, 20001, 21000):
      return "Mythic";
    case inRange(_id, 1001, 2000) ||
      inRange(_id, 11001, 12000) ||
      inRange(_id, 21001, 22000):
      return "Rare";
    case inRange(_id, 2001, 3000) ||
      inRange(_id, 12001, 13000) ||
      inRange(_id, 22001, 23000):
      return "Rare";
    case inRange(_id, 3001, 4000) ||
      inRange(_id, 13001, 14000) ||
      inRange(_id, 23001, 24000):
      return "Rare";
    case inRange(_id, 4001, 5000) ||
      inRange(_id, 14001, 15000) ||
      inRange(_id, 24001, 25000):
      return "Common";
    case inRange(_id, 5001, 6000) ||
      inRange(_id, 15001, 16000) ||
      inRange(_id, 25001, 26000):
      return "Common";
    case inRange(_id, 6001, 7000) ||
      inRange(_id, 16001, 17000) ||
      inRange(_id, 26001, 27000):
      return "Common";
    case inRange(_id, 7001, 8000) ||
      inRange(_id, 17001, 18000) ||
      inRange(_id, 27001, 28000):
      return "Common";
    case inRange(_id, 8001, 9000) ||
      inRange(_id, 18001, 19000) ||
      inRange(_id, 28001, 29000):
      return "Common";
    case inRange(_id, 9001, 10000) ||
      inRange(_id, 19001, 20000) ||
      inRange(_id, 29001, 30000):
      return "Common";
    default:
      return null;
  }
}

export const rarity = {
  0: "Unset",
  1: "Mythic",
  2: "Rare",
  3: "Common",
};

export const landType = {
  0: "Unset",
  1: "Mythic",
  2: "Light",
  3: "Wonder",
  4: "Mystery",
  5: "Heart",
  6: "Cloud",
  7: "Flower",
  8: "Candy",
  9: "Crystal",
  10: "Moon",
  11: "Rainbow",
  12: "OmNom",
  13: "Star",
};

export const getGasPrice = async (wallet, tx) => {
  if (wallet.label === "Sequence") {
    return {
      maxFeePerGas: null,
      maxPriorityFee: null,
    };
  }

  let gasLimit = await wallet.estimateGas(tx);

  return {
    gasLimit: gasLimit,
  };
};

export const convertNum = (price) => {
  let convertedPrice = price.toPrecision(2);
  if (convertedPrice.includes("e")) {
    return price.toFixed(Number(convertedPrice.slice(-1)) + 1);
  } else {
    return convertedPrice;
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const formatWalletAddress = (address) => {
  if (!address || address.length < 6) {
    return address;
  }
  const prefix = address.substring(0, 6);
  const suffix = address.substring(address.length - 6);
  return `${prefix}...${suffix}`;
};

export async function checkSmartWallet(address, wallet) {
  let walletCode = await wallet.provider.getCode(address);
  if (walletCode === "0x") {
    return false;
  } else {
    return true;
  }
}

export function timeAgo(date) {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);

  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

export function formatStringWithFixedFraction(
  str,
  fractionDigits = 0,
  maxFractionDigits
) {
  if (!str) return "-";
  let num = str;
  // if str is string, convert to number
  if (typeof str === "string") {
    num = Number(str);
  }

  if (isNaN(num)) {
    return "-";
  }

  return num.toLocaleString("en-US", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: maxFractionDigits,
  });
}
