import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import styled from "styled-components";
import { HomeVideoCarouselItem } from "./HomeVideoCarouselItem";
import jousting_top from "../../../img/home/carousel/jousting-top.png";
import jousting_bottom from "../../../img/home/carousel/jousting-bottom.png";
import jousting_foreground from "../../../img/home/carousel/jousting-foreground.png";
import jousting_video from "../../../img/home/carousel/jousting.mp4";
import land_top from "../../../img/home/carousel/land-top.png";
import land_bottom from "../../../img/home/carousel/land-bottom.png";
import land_foreground from "../../../img/home/carousel/land-foreground.png";
import land_video from "../../../img/home/carousel/farming.mp4";
import shadowcorn_foreground from "../../../img/home/carousel/shadowcorn-foreground.png";
import shadowcorn_main from "../../../img/home/carousel/shadowcorn-main.png";
import shadowcorn_top from "../../../img/home/carousel/shadowcorn-top.png";
import shadowcorn_bottom from "../../../img/home/carousel/shadowcorn-bottom.png";
import tribes_main from "../../../img/home/carousel/tribes-main.png";
import tribes_foreground from "../../../img/home/carousel/tribes-foreground.png";
import tribes_top from "../../../img/home/carousel/tribes-top.png";
import tribes_bottom from "../../../img/home/carousel/tribes-bottom.png";
import { device } from "../../../theme/mediaQuery";
import { useState } from "react";

const ITEMS = [
  // {
  //   title: "Tribes",
  //   activePage: 0,
  //   topImage: tribes_top,
  //   bottomImage: tribes_bottom,
  //   image: tribes_main,
  //   foregroundImage: tribes_foreground,
  // },
  // {
  //   title: "Unicorn Party",
  //   activePage: 1,
  //   topImage: unicornparty_top,
  //   bottomImage: unicornparty_bottom,
  //   image: unicornparty_main,
  //   foregroundImage: unicornparty_foreground,
  // },
  // {
  //   title: "Jousting",
  //   activePage: 2,
  //   topImage: jousting_top,
  //   bottomImage: jousting_bottom,
  //   video: jousting_video,
  //   foregroundImage: jousting_foreground,
  // },
  // {
  //   title: "Land Gameplay",
  //   activePage: 0,
  //   topImage: land_top,
  //   bottomImage: land_bottom,
  //   video: land_video,
  //   foregroundImage: land_foreground,
  // },
  // {
  //   title: "Bumperconrs",
  //   activePage: 0,
  //   topImage: land_bottom,
  //   bottomImage: land_top,
  //   image: bumper_main,
  //   foregroundImage: bumper_foreground,
  // },
  {
    title: "Shadowcorns",
    activePage: 4,
    topImage: shadowcorn_top,
    bottomImage: shadowcorn_bottom,
    image: shadowcorn_main,
    foregroundImage: shadowcorn_foreground,
  },
];

const CarouselWrap = styled(Swiper)`
  overflow: visible;

  & .swiper-pagination {
    padding-top: 5px;
    position: static;

    @media (${device.lg}) {
      padding-top: 50px;
    }
  }

  & .swiper-pagination-bullet {
    background: rgba(217, 217, 217, 0.4);
    border-radius: 32px;
    width: 26px;
    height: 6px;
    transition: width 0.3s ease-in-out;

    &-active {
      background: #ffa9d2;
      width: 82px;
    }
  }
`;

const CarouselSlide = styled(SwiperSlide)`
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.swiper-slide-active {
    opacity: 1;
  }

  @media (${device.lg}) {
    padding: 60px;
  }

  @media (${device.xl}) {
    padding: 40px;
  }
`;

export function HeroVideoCarousel({ items = ITEMS, portrait = false }) {
  const [currentVideoRef, setCurrentVideoRef] = useState(null);

  const handleSlideChange = () => {
    // if user change slide, pause current video
    if (currentVideoRef !== null) {
      currentVideoRef.current?.pause();
    }
  };

  return (
    <CarouselWrap
      spaceBetween={0}
      slidesPerView={1}
      modules={[Pagination]}
      pagination={{ clickable: true }}
      onSlideChange={handleSlideChange}
      touchStartPreventDefault={false}>
      {items.map((item, index) => (
        <CarouselSlide index={item.title} key={item.title}>
          <HomeVideoCarouselItem
            {...item}
            onPlayVideo={setCurrentVideoRef}
            portrait={portrait}
          />
        </CarouselSlide>
      ))}
    </CarouselWrap>
  );
}
