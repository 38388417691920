import styled from "styled-components";
import { Box } from "../base/Box";
import { Typography } from "../base/Typography";
import { ClassIcon } from "../common/ClassIcon";
import { unicornsMap } from "../common/CharacterCard";
import fire from "../../img/shadowcorns/fire.png";
import soul from "../../img/shadowcorns/soul.png";
import slime from "../../img/shadowcorns/slime.png";
import nebula from "../../img/shadowcorns/nebula.png";
import volt from "../../img/shadowcorns/volt.png";

export const shadowcornMap = {
  fire: {
    image: fire,
    background: "radialFire",
    footerBackground: "linear-gradient(180deg, #FAC43D 0%, #B92E0F 100%)",
  },
  soul: {
    image: soul,
    background: "radialSoul",
    footerBackground: "linear-gradient(0deg, #581CF8 0%, #B852FB 100%)",
  },
  slime: {
    image: slime,
    background: "radialSlime",
    footerBackground: "linear-gradient(0deg, #569E0A 0%, #9AA51C 100%)",
  },
  nebula: {
    image: nebula,
    background: "radialNebula",
    footerBackground: "linear-gradient(0deg, #59103F 0%, #FE6CCF 100%)",
  },
  volt: {
    image: volt,
    background: "radialVolt",
    footerBackground: "linear-gradient(180deg, #2FA1FF 0%, #002635 100%)",
  },
};

export const CHARACTER = {
  UNICORN: 0,
  SHADOWCORN: 1,
};

const CardWrap = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;

  transform: translateY(0px);
  transition: all 0.24s ease;
`;

const HeaderImg = styled.img`
  object-fit: contain;
  height: 256px;
  width: 100%;
  margin: 14px 0 7px;
  margin: ${({ $isUnicorn }) => ($isUnicorn ? "14px 0 7px" : "24px 0 12px")};
`;

const ClassImg = styled(ClassIcon)`
  width: 50px;
`;

const TextBox = styled(Box)`
  user-select: none;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  gap: 21px;
  padding: 21px 20px;
  min-height: 85px;
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Separator = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.11);
  width: 100%;
`;

export function CharacterCard({
  name,
  description,
  characterType = CHARACTER.UNICORN,
}) {
  const character =
    characterType === CHARACTER.UNICORN ? unicornsMap : shadowcornMap;
  const image = character[name].image;
  const background = character[name].background;

  return (
    <CardWrap>
      <Box
        variant="gradient"
        gradient={background}
        rounded="sm"
        pattern="none"
        patternSize="lg"
        patternOpacity="0.05"
      >
        <HeaderImg src={image} alt="" />

        <Separator />
        <TextBox
          variant="gradient"
          gradient="linearBlack"
          pattern="sprinkles"
          patternSize="lg"
        >
          <ClassImg name={name.toLowerCase()} />
          <TextWrap>
            <Typography
              tag={"h3"}
              variant={"textXl"}
              xl={{ variant: "displayXs" }}
              color="white"
            >
              {name}
            </Typography>
            <Typography
              tag={"h3"}
              variant={"textSm"}
              xl={{ variant: "textMd" }}
              color="white"
            >
              {description}
            </Typography>
          </TextWrap>
        </TextBox>
      </Box>
    </CardWrap>
  );
}
