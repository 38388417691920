import { atom } from "jotai";

export const claimedAtom = atom({
  claimedBalance: null,
  claimed: null,
});

export const claimedV2Atom = atom({
  claimedBalanceV2: null,
  claimedV2: null,
});

export const dropperAtom = atom({
  dropperBalance: null,
  dropper: null,
});

export const dropperV2Atom = atom({
  dropperBalanceV2: null,
  dropperV2: null,
});

export const dropperContractAtom = atom(null);

export const dropperContractV2Atom = atom(null);
