import { Container as BSContainer } from "react-bootstrap";
import styled from "styled-components";

const LargeBootstrapContainer = styled(BSContainer)`
  @media (min-width: 1600px) {
    max-width: 1440px;
  }
`;

export function Container({ large = false, ...rest }) {
  if (large) {
    return <LargeBootstrapContainer {...rest} />;
  }
  return <BSContainer {...rest} />;
}