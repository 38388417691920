import React from "react";
import { ReactComponent as Wallet } from "../../img/icons/wallet.svg";
import { ReactComponent as User } from "../../img/icons/user.svg";
import { ReactComponent as ArrowDown } from "../../img/icons/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../img/icons/arrow-up.svg";
import { ReactComponent as ArrowLeft } from "../../img/icons/arrow-left.svg";
import { ReactComponent as FullArrowRight } from "../../img/icons/full-arrow-right.svg";
import { ReactComponent as ArrowRight } from "../../img/icons/arrow-right.svg";
import { ReactComponent as Book } from "../../img/icons/book.svg";
import { ReactComponent as Dashboard } from "../../img/icons/dashboard.svg";
import { ReactComponent as Discord } from "../../img/icons/discord.svg";
import { ReactComponent as Edit } from "../../img/icons/edit.svg";
import { ReactComponent as Exit } from "../../img/icons/exit.svg";
import { ReactComponent as Games } from "../../img/icons/games.svg";
import { ReactComponent as Substack } from "../../img/icons/substack.svg";
import { ReactComponent as Reward } from "../../img/icons/reward.svg";
import { ReactComponent as Settings } from "../../img/icons/settings.svg";
import { ReactComponent as Twitter } from "../../img/icons/twitter.svg";
import { ReactComponent as Youtube } from "../../img/icons/youtube.svg";
import { ReactComponent as Cog } from "../../img/icons/cog.svg";
import { ReactComponent as Jousting } from "../../img/icons/jousting.svg";
import { ReactComponent as Tribes } from "../../img/icons/tribes.svg";
import { ReactComponent as Leaves } from "../../img/icons/leaves.svg";
import { ReactComponent as Tree } from "../../img/icons/tree.svg";
import { ReactComponent as Stars } from "../../img/icons/stars.svg";
import { ReactComponent as Unicorn } from "../../img/icons/unicorn.svg";
import { ReactComponent as Burger } from "../../img/icons/burger.svg";
import { ReactComponent as Play } from "../../img/icons/play.svg";
import { ReactComponent as Pause } from "../../img/icons/pause.svg";
import { ReactComponent as Logout } from "../../img/icons/logout.svg";
import { ReactComponent as Eyes } from "../../img/icons/eyes.svg";
import { ReactComponent as TeamRpg } from "../../img/icons/team-rpg.svg";
import { ReactComponent as RainbowRumble } from "../../img/icons/rainbow-rumble.svg";
import { ReactComponent as Stamps } from "../../img/icons/stamps.svg";
import { ReactComponent as Download } from "../../img/icons/download.svg";
import { ReactComponent as Breederdao } from "../../img/icons/breederdao.svg";
import { ReactComponent as Fullscreen } from "../../img/icons/fullscreen.svg";
import { ReactComponent as FullscreenExit } from "../../img/icons/fullscreen-exit.svg";
import { ReactComponent as Bumpercorn } from "../../img/icons/bumpercorn.svg";
import { ReactComponent as InflatableUnicorn } from "../../img/icons/inflatable-unicorn.svg";
import { ReactComponent as Check } from "../../img/icons/check.svg";
import { ReactComponent as CheckSquare } from "../../img/icons/check-square.svg";
import { ReactComponent as Info } from "../../img/icons/info.svg";
import { ReactComponent as InfoSquare } from "../../img/icons/info-square.svg";
import { ReactComponent as Desktop } from "../../img/icons/desktop.svg";
import { ReactComponent as Android } from "../../img/icons/android.svg";
import { ReactComponent as IOS } from "../../img/icons/ios.svg";
import { ReactComponent as Close } from "../../img/icons/close.svg";
import { ReactComponent as RoundPlus } from "../../img/icons/round-plus.svg";
import { ReactComponent as RoundInfo } from "../../img/icons/round-info.svg";
import { ReactComponent as RoundInfoAlt } from "../../img/icons/round-info-alt.svg";
import { IconWrapper } from "../../theme/components";
import { ReactComponent as Pending } from "../../img/icons/pending.svg";
import { ReactComponent as LineArrowRight } from "../../img/icons/line-arrow-right.svg";
import { ReactComponent as BoldArrowRight } from "../../img/icons/bold-arrow-right.svg";
import { ReactComponent as WalletDense } from "../../img/icons/wallet-dense.svg";
import { ReactComponent as Triangle } from "../../img/icons/triangle.svg";
import { ReactComponent as PlaceholderPlus } from "../../img/icons/placeholder-plus.svg";
import { ReactComponent as DollarSign } from "../../img/icons/dollar-sign.svg";
import { ReactComponent as Time } from "../../img/icons/time.svg";
import { ReactComponent as Arrows } from "../../img/icons/arrows.svg";
import { ReactComponent as Community } from "../../img/icons/community.svg";
import { ReactComponent as Social } from "../../img/icons/social.svg";
import { ReactComponent as Globe } from "../../img/icons/globe.svg";
import { ReactComponent as Pin } from "../../img/icons/pin.svg";
import { ReactComponent as Email } from "../../img/icons/email.svg";
import { ReactComponent as Cart } from "../../img/icons/cart.svg";
import { ReactComponent as Lock } from "../../img/icons/lock.svg";

const iconsMap = {
  wallet: Wallet,
  user: User,
  "arrow-down": ArrowDown,
  "arrow-up": ArrowUp,
  "arrow-right": ArrowRight,
  "arrow-left": ArrowLeft,
  "full-arrow-right": FullArrowRight,
  cart: Cart,
  book: Book,
  dashboard: Dashboard,
  discord: Discord,
  edit: Edit,
  exit: Exit,
  games: Games,
  substack: Substack,
  reward: Reward,
  settings: Settings,
  twitter: Twitter,
  youtube: Youtube,
  cog: Cog,
  jousting: Jousting,
  tribes: Tribes,
  leaves: Leaves,
  tree: Tree,
  stars: Stars,
  unicorn: Unicorn,
  burger: Burger,
  play: Play,
  pause: Pause,
  logout: Logout,
  eyes: Eyes,
  "team-rpg": TeamRpg,
  "rainbow-rumble": RainbowRumble,
  stamps: Stamps,
  download: Download,
  breederdao: Breederdao,
  fullscreen: Fullscreen,
  "fullscreen-exit": FullscreenExit,
  bumpercorns: Bumpercorn,
  "inflatable-unicorn": InflatableUnicorn,
  check: Check,
  "check-square": CheckSquare,
  info: Info,
  "info-square": InfoSquare,
  desktop: Desktop,
  ios: IOS,
  android: Android,
  close: Close,
  pending: Pending,
  "line-arrow-right": LineArrowRight,
  "bold-arrow-right": BoldArrowRight,
  "wallet-dense": WalletDense,
  triangle: Triangle,
  "placeholder-plus": PlaceholderPlus,
  "round-plus": RoundPlus,
  "round-info": RoundInfo,
  "round-info-alt": RoundInfoAlt,
  "dollar-sign": DollarSign,
  time: Time,
  arrows: Arrows,
  community: Community,
  social: Social,
  pin: Pin,
  globe: Globe,
  email: Email,
  lock: Lock,
};

export function Icon({ name, ...rest }) {
  let Icon = iconsMap[name];

  if (!Icon) {
    console.warn(`Icon ${name} not found`);
    return <></>;
  }

  return (
    <IconWrapper {...rest}>
      <Icon />
    </IconWrapper>
  );
}
