import React from "react";
import styled, { useTheme } from "styled-components";

const generateUniqueId = () => `spinnerGradient-${Date.now()}-${Math.random()}`;

const Spinner = ({
  color = "white",
  size = "50px",
  gradientColor1,
  gradientColor2,
}) => {
  const theme = useTheme();
  const themeColor = theme.palette[color] || color;

  // unique gradient ID for each spinner instance to prevent gradient from fading
  const gradientId = React.useMemo(generateUniqueId, []);

  return (
    <StyledSpinner viewBox="0 0 50 50" size={size}>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop
            offset="0%"
            style={{ stopColor: gradientColor1, stopOpacity: 1 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: gradientColor2, stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
        stroke={gradientColor1 ? `url(#${gradientId})` : themeColor}
      />
    </StyledSpinner>
  );
};

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  // margin: -25px 0 0 -25px;
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  & .path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default Spinner;
