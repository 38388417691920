import styled from "styled-components";
import { Icon } from "../base/Icon";
import { Typography } from "../base/Typography";
import { device } from "../../theme/mediaQuery";
import { isTestnet } from "../Network";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #1f8e0b;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  @media (${device.md}) {
    white-space: nowrap;
  }
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  background: #1f8e0b;
  color: ${({ theme }) => theme.palette.white};
  border-radius: 50%;
`;

const LinkWrap = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1f8e0b;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  border-bottom: 1px solid #1f8e0b;
  align-self: flex-end;
  line-height: 1;
  font-size: 14px;

  &:hover {
    color: #49b236 !important;
    border-bottom: 1px solid #49b236;
  }
`;

export function BridgeConfirmNotification({
  amount,
  chain,
  convertedToken,
  txHash,
  dstTxHash,
  links,
  isInflight = true,
}) {
  // generate transaction link based on chain and txHash and dstTxHash
  const scanLink = isTestnet
    ? `${links.TESTNET}/${txHash}`
    : `${links.MAINNET}/${txHash}`;

  const linkConfirmation = isTestnet
    ? `${links?.CONFIRMATION_TESTNET}/${dstTxHash}`
    : `${links?.CONFIRMATION_MAINNET}/${dstTxHash}`;

  const status = isInflight ? "is being processed" : "has been deposited";
  return (
    <Wrap>
      <TitleWrap>
        <IconWrap>
          <Icon name="check-square" size="60%" />
        </IconWrap>
        <Typography variant="textMd" color="#1F8E0B" family="display">
          {amount} {convertedToken} {status} on {chain}
        </Typography>
      </TitleWrap>
      <LinkWrap href={scanLink} target="_blank" rel="noreferrer">
        View Transaction <Icon name="line-arrow-right" size="13px" />
      </LinkWrap>
      {dstTxHash && (
        <LinkWrap
          href={linkConfirmation}
          target="_blank"
          rel="noreferrer"
          className="pt-2"
        >
          View Transaction Confirmation
          <Icon name="line-arrow-right" size="13px" />
        </LinkWrap>
      )}
    </Wrap>
  );
}
