import React from "react";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import { Typography } from "../../base/Typography";

const Shape = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  background: #fff;
  height: 55px;
  width: 45px;
  border-radius: 16px;
  padding: 8px;

  font-family: "Insanibc";
  text-shadow: none;
  text-align: center;
  font-size: 7px;
  color: #c82ce7;

  transition: transform 0.36s ease;
  z-index: 9;

  @media (min-width: 480px) {
    font-size: 10px;
    height: 70px;
    width: 75px;
  }

  @media (${device.md}) {
    font-size: 12px;
    width: 125px;
    height: 110px;
  }
  @media (${device.xl}) {
    height: 120px;
    width: 160px;
    font-size: 14px;
  }

  // positioning
  position: absolute;
  &:nth-child(even) {
    top: auto;
    bottom: calc(100% + 16px);
  }
  &:nth-child(1) {
    left: 0;
  }
  &:nth-child(3) {
    left: 23%;
  }
  &:nth-child(5) {
    left: 49%;
  }
  &:nth-child(7) {
    left: 73%;
  }

  &:nth-child(2) {
    right: 73%;
  }
  &:nth-child(4) {
    right: 49%;
  }
  &:nth-child(6) {
    right: 23%;
  }
  &:nth-child(8) {
    right: 0;
  }
`;

const EliminationWrap = styled.span`
  font-family: "Insanibc";
  font-size: 10px;
  color: #55a2c4;
  display: none;

  @media (${device.md}) {
    display: inline;
    font-size: 12px;
  }
  @media (${device.xl}) {
    font-size: 16px;
  }
`;

const DateWrap = styled.span`
  font-family: "Insanibc";
  font-size: 8px;
  color: ${({ theme }) => theme.palette.darkBlue300};

  @media (min-width: 480px) {
    font-size: 12px;
  }
  @media (${device.md}) {
    font-size: 14px;
  }
  @media (${device.xl}) {
    font-size: 18px;
  }
`;
const YearWrap = styled.span`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  display: none;

  @media (${device.md}) {
    display: inline;
  }
`;

const RewardWrap = styled(Typography)`
  display: none;
  @media (min-width: 480px) {
    display: inline;
  }
`;

const ImgWrap = styled.img`
  display: none;
  @media (${device.md}) {
    display: inline;
  }
`;

const RBWWrap = styled.span`
  font-family: inherit;
  font-size: 10px;
  color: inherit;
  display: inline;
  @media (${device.md}) {
    display: none;
  }
`;

const DateMilestone = (props) => {
  const percentage = (props.value / props.maxValue) * 100;
  const year = props.year ? props.year : "2023";
  return (
    <Shape $position={percentage}>
      {props.title && props.title}
      <EliminationWrap>Elimination Date</EliminationWrap>
      <DateWrap $color={props.color}>
        {props.date}
        <YearWrap>, {year}</YearWrap>
      </DateWrap>
      {props.subtitle && (
        <RewardWrap
          variant="text2xs"
          xl={{
            variant: "textMd",
          }}
          lg={{
            variant: "textSm",
          }}
          md={{
            variant: "textXs",
          }}
          color="darkBlue300"
          family="display"
        >
          {props.subtitle}
          {props.img && (
            <ImgWrap
              src={props.img}
              alt="icon"
              className="ms-1"
              style={{ width: "100%", maxWidth: "23px" }}
            />
          )}
          <RBWWrap> RBW</RBWWrap>
        </RewardWrap>
      )}
    </Shape>
  );
};

export default DateMilestone;
