import React from "react";
import styled, { keyframes } from "styled-components";
import { Typography } from "../../base/Typography";
import { Box } from "../../base/Box";
import { device } from "../../../theme/mediaQuery";
import { Modal } from "../../leaderboard/components/Modal";
import { ModalTitle } from "./ModalTitle";

const modalAnimation = keyframes`
  from {
    transform: translateY(-5%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ModalWrap = styled(Modal)`
  max-width: 820px;
  min-height: 300px;
  padding: 0;
  border-radius: 40px;
  z-index: 15;

  animation: ${modalAnimation} 0.3s ease-in-out;
`;

const BoxWrap = styled(Box)`
  position: relative;
  border-radius: 30px;
  padding: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  @media (${device.md}) {
    padding: 32px;
  }
  @media (${device.lg}) {
    padding: 55px;
  }
`;

const SubtitleWrap = styled.div`
  z-index: 13;
`;

export function AlertModal({
  toggleModal,
  showModal,
  title,
  subtitle,
  message,
  children,
  variant = "primary",
}) {
  const gradient =
    variant === "secondary"
      ? "linear-gradient(180deg, #5252F2 0%, #3131B0 100%)"
      : "linear-gradient(180deg, #6F19B8 0%, #2D0D78 100%)";

  return (
    <ModalWrap
      toggleModal={toggleModal}
      showModal={showModal}
      opacity="md"
      fullscreen
    >
      <BoxWrap gradient={gradient} pattern="sprinkles" patternSize="60%">
        <ModalTitle variant={variant}>{title}</ModalTitle>
        <SubtitleWrap>
          <Typography
            tag="p"
            variant="textSm"
            md={{ variant: "displayXs" }}
            color="white"
            family="display"
            className="text-center"
          >
            {subtitle}
          </Typography>
          {message && (
            <Typography
              tag="p"
              variant="textSm"
              md={{ variant: "textXl" }}
              color="white"
              className="text-center pb-md-2"
            >
              {message}
            </Typography>
          )}
        </SubtitleWrap>
        {children}
      </BoxWrap>
    </ModalWrap>
  );
}
