import { EVENTS } from "../consts/googleEvents";

// Events without custom parameters beyond the event category
// eventCategory is an optional parameter that can override the default
// eventCategory in each event coming from the EVENTS const
export const googleAnalyticsEvent = (eventName, eventCategory) => {
  if (EVENTS[eventName].NAME) {
    window.gtag("event", EVENTS[eventName].NAME, {
      event_category: eventCategory
        ? eventCategory
        : EVENTS[eventName].CATEGORY,
    });
  }
};
// Events with custom parameters
export const googleAnalyticsEventWallet = (address, walletType) => {
  window.gtag("event", EVENTS.WALLET_CONNECTED.NAME, {
    event_category: EVENTS.WALLET_CONNECTED.CATEGORY,
    event_wallet_address: address.replace("0x", ""),
    event_wallet_type: walletType,
  });
};

