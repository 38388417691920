export const API = {
  LEADERBOARD: "https://engineapi.moonstream.to/leaderboard",
};

export const BLOG = {
  GAME_GUIDE:
    "https://cryptounicorns.notion.site/Crypto-Unicorns-Game-Guide-4f3df83096fc4e57b58197b328442b71",
  PROGRAM:
    "https://cryptounicorns.substack.com/p/security-bug-bounty-program-8d1?utm_source=publication-search",
  SECURITY:
    "https://cryptounicorns.substack.com/p/security-bug-bounty-program-a23?utm_source=publication-search",
  TRIBES_RULES: "https://cryptounicorns.substack.com/p/tribes-leaderboard-s1",
  NEWS: "https://cryptounicorns.substack.com/",
  STAKING:
    "https://cryptounicorns.substack.com/p/cu-liquidity-bootstrapping-event",
};

export const SCAN = {
  LAYER_ZERO: {
    TESTNET: "https://testnet.layerzeroscan.com/tx",
    MAINNET: "https://layerzeroscan.com/tx",
    CONFIRMATION_TESTNET: "https://sepolia.arbiscan.io/tx",
    CONFIRMATION_MAINNET: "https://arbiscan.io/tx",
  },
  EXPLORER: {
    TESTNET: "https://testnet.layerzeroscan.com/tx",
    MAINNET: "https://layerzeroscan.com/tx",
    CONFIRMATION_TESTNET: "https://testnet-explorer-v2.xai-chain.net/tx",
    CONFIRMATION_MAINNET: "https://explorer.xai-chain.net/tx",
  },
};

export const BUG_BOUNTY = {
  POLY_SCAN: {
    BANK: "https://polygonscan.com/address/0x94f557dDdb245b11d031F57BA7F2C4f28C4A203e#code",
    DROPPER:
      "https://polygonscan.com/address/0x6bc613A25aFe159b70610b64783cA51C9258b92e#codee",
    ITEM_MARKETPLACE:
      "https://polygonscan.com/address/0x99A558BDBdE247C2B2716f0D4cFb0E246DFB697D#code",
    LANDS:
      "https://polygonscan.com/address/0xA2a13cE1824F3916fC84C65e559391fc6674e6e8#code",
    CU: "https://arbiscan.io/token/0x89c49a3fa372920ac23ce757a029e6936c0b8e02",
    CULP: "https://arbiscan.io/token/0x75e1a0dda0f7d3ca5d8960875e306533e7259609",
    SATELLITE_BANK:
      "https://polygonscan.com/address/0x28597eA60030fBae79088d89d803f25143c7a6B5#code",
    SHADOWCORNS:
      "https://polygonscan.com/address/0xa7D50EE3D7485288107664cf758E877a0D351725#code",
    STAKING_V2:
      "https://arbiscan.io/token/0x29b9fd9941caa27c1d778abb5fddafe85821283e",
    STAKING_V2_CULP:
      "https://arbiscan.io/token/0x942bE15783585A542F77289f0723A7ee29E66c2e",
    UNICORNS:
      "https://polygonscan.com/address/0xdC0479CC5BbA033B3e7De9F178607150B3AbCe1f#code",
    UNIM: "https://polygonscan.com/token/0x64060ab139feaae7f06ca4e63189d86adeb51691#code",
  },
  ZENDESK:
    "https://lagunagames.zendesk.com/hc/en-us/requests/new?ticket_form_id=5571375677467",
};

export const BUY = {
  UNIM: "https://app.camelot.exchange/?token1=0x3fB787101DC6Be47cfe18aeEe15404dcC842e6AF&token2=0x64060ab139feaae7f06ca4e63189d86adeb51691&chainId=660279",
  CU: "https://app.camelot.exchange/?token2=0x89C49A3fA372920aC23ce757A029e6936c0b8e02&chainId=42161",
  MARKETPLACE: {
    HOME: "https://marketplace.cryptounicorns.fun",
    SHADOWCORN: "https://marketplace.cryptounicorns.fun/collection/shadowcorns",
    UNICORN: "https://marketplace.cryptounicorns.fun/collection/unicorns",
    GEMS: "https://marketplace.cryptounicorns.fun/collection/gems",
    UNICORN_ITEMS:
      "https://marketplace.cryptounicorns.fun/collection/unicorn-items",
    UNICORN_LAND: "https://marketplace.cryptounicorns.fun/collection/lands",
    RITUALS: "https://marketplace.cryptounicorns.fun/collection/rituals",
    UNICORN_UNLOCKED:
      "https://marketplace.cryptounicorns.fun/collection/unicorns?search_game_lock=Unlocked",
    UNICORN_ADULT__UNLOCKED:
      "https://marketplace.cryptounicorns.fun/collection/unicorns?search_lifecycle=Adult&search_game_lock=Unlocked",
    UNICORN_ADULT__UNLOCKED_ELO:
      "https://marketplace.cryptounicorns.fun/collection/unicorns?search_lifecycle=Adult&search_game_lock=Unlocked&search_elo_rating=1200,2500",
    UNICORN_ADULT__UNLOCKED_READY_TO_BUY: "https://marketplace.cryptounicorns.fun/collection/unicorns?search_lifecycle=Adult&search_game_lock=Unlocked&search_status=LISTED",
      UNICORN_LISTINGS:
      "https://marketplace.cryptounicorns.fun/collection/unicorns",
    UNICORN_LAND_UNLOCKED:
      "https://marketplace.cryptounicorns.fun/collection/lands?search_game_lock=Unlocked",
  },
};

export const CHAIN = {
  MATIC: "https://matic-mumbai.chainstacklabs.com",
  POLYGON: "https://polygon-rpc.com",
  ARBITRUM: "https://arb1.arbitrum.io/rpc",
};

export const COMMUNITY = {
  BREEDERDAO: "https://playcore.io/games/cryptounicorns",
  DISCORD: "https://discord.com/invite/xSAAjcEWRU",
  FORMUS: "https://forum.cryptounicorns.fun/",
  GOVERNANCE: "https://snapshot.org/#/lgcryptounicorns.eth",
  SUBSTACK: "https://cryptounicorns.substack.com",
  TWITTER: "https://twitter.com/crypto_unicorns",
  YOUTUBE: "https://www.youtube.com/@CryptoUnicorns",
  ZENDESK: "https://lagunagames.zendesk.com/hc/en-us",
  UNIWU: "https://uniwu.me/",
};

export const CRYPTO = {
  AIRDROP: "https://airdrop.cryptounicorns.fun/",
  JOUSTING: "https://game.cryptounicorns.fun/",
  LAND_GAME: "https://game.cryptounicorns.fun/",
  TRIBES: "https://game.cryptounicorns.fun/",
  MARKETPLACE: "https://marketplace.cryptounicorns.fun/",
  MARKETPLACE_PROFILE:
    "https://marketplace.cryptounicorns.fun/profile/collections/unicorns",
  MOB_RUN: {
    WEBGL: "https://mobrun.cryptounicorns.fun",
    APK: "https://mobrun.cryptounicorns.fun/apk/mobrun-latest.apk",
    IOS: "https://apps.apple.com/us/app/mob-run/id6472675898",
    ANDROID: "https://play.google.com/store/apps/details?id=fun.mobrun",
  },
  BUMPERCORNS: {
    WEBGL: "https://bumpercorns.unicornparty.com",
    APK: "https://bumpercorns.unicornparty.com/apk/bumpercorns-latest.apk",
    IOS: "https://apps.apple.com/us/app/bumpercorns/id6471430341",
    ANDROID: "https://play.google.com/store/apps/details?id=fun.bumpercorns",
  },
  BINGO: {
    APK_WEBSITE: "https://www.unicornparty.com",
    IOS: "https://apps.apple.com/us/app/unicorn-bingo/id6474942118",
    ANDROID: "https://play.google.com/store/apps/details?id=fun.unicornbingo",
  },
  SHADOWCORNS: "https://shadowforge.cryptounicorns.fun/",
  TEAM_RPG: "https://teamrpg.cryptounicorns.fun/",
  RAINBOW_RUMBLE: "https://rainbowrumble.cryptounicorns.fun/",
  UNICORNS: "https://www.cryptounicorns.fun/",
  VAULT: "https://cryptounicorns.vault.inc/#/",
  VENDING: "https://vending.cryptounicorns.fun/",
  EARN_CU: "https://game.cryptounicorns.fun/",
  SHOP: "https://shop.cryptounicorns.fun/",
};

export const VIDEOS = {
  JOUSTING_TRAILER: "https://www.youtube.com/embed/vVtQftC6VmA",
  RAINBOW_RUMBLE_TRAILER: "https://www.youtube.com/watch?v=cW8agoABp6M",
};

export const WALLET = {
  COINBASE: "https://wallet.coinbase.com/",
  METAMASK: "https://metamask.io",
};

export const WHITE_PAPER = {
  GETTING_STARTED:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/getting-started",
  AIRDROP:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/play-to-airdrop",
  PLAY_NO_WALLET:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/unicorn-party/ways-to-play/play-without-a-wallet",
  PLAY_POLYGON:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/unicorn-party/ways-to-play/play-with-a-polygon-wallet",
  PLAY_POLYGON_UNICORN:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/unicorn-party/ways-to-play/play-with-polygon-wallet-and-your-unicorns",
  LEARN:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/getting-started",
  STEP_1:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/getting-started/step-1-setup-your-wallet",
  STEP_2:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/getting-started/step-2-get-currency-on-polygon",
  TOKENOMICS:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-whitepaper-v2/tokenomics",
  WELCOME:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/",
  PLAY_AND_EARN:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/supplemental/tokenomics/allocation-and-unlock-schedule/play-and-earn-allocation",
  LAND_WELCOME:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/land-gameplay",
  JOUSTING_WELCOME:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/jousting",
  SHADOWCORN__WELCOME:
    "https://whitepaper.cryptounicorns.fun/crypto-unicorns-welcome-guide/shadowcorns",
};

export const FORM = {
  LICENSING:
    "https://docs.google.com/forms/d/e/1FAIpQLSf5QGg4rDr176utVI4sj1ArBWGy3Yzhy53tzJKfCe8hYs3FLQ/viewform?embedded=true",
};
