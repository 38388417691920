import { Link } from "react-router-dom";
import styled from "styled-components";
import { Row } from "../../base/Row";
import { Col } from "../../base/Col";
import { Icon } from "../../base/Icon";
import { Typography } from "../../base/Typography";
import { Button } from "../../base/Button";
import { googleAnalyticsEvent } from "../../../helpers/googleAnalytics";
import { EVENT_KEYS } from "../../../consts/googleEvents";

const GameApkButtonWrap = styled(Link)`
  background: ${({ theme, $background }) =>
    $background ? $background : theme.palette.indigo300};
  text-shadow: ${({ theme }) =>
    `0px 1px 0px ${theme.components.button.primary.default.textShadowColor}`};
  border-radius: 16px;
  padding: ${({ $small }) => ($small ? "12px" : "8px 16px")};
  display: block;
  text-decoration: none;
  text-align: left;
  color: ${({ theme }) => theme.palette.white};
  cursor: default;

  &:hover {
    color: ${({ theme }) => theme.palette.white};
  }
`;

const IconWrap = styled(Icon)`
  & svg {
    overflow: visible;
  }
`;
const QrWrap = styled.img`
  max-width: 85px;
  width: 100%;
  padding-left: 0;
`;

const DescriptionCol = styled(Col)`
  border-left: 1px solid #2c76db;
`;

const DescriptionSecondaryCol = styled(DescriptionCol)`
  border-left: 1px solid #e87e06;
`;

export function GameApkButton({
  qr,
  link,
  event,
  background,
  small = false,
  mobileHidden = false,
}) {
  const handleAnalytics = () => {
    if (event) {
      googleAnalyticsEvent(EVENT_KEYS.PLAY_GAME, event);
    }
  };
  return (
    <>
      {/* Desktop only */}
      <GameApkButtonWrap
        to={link}
        $small={small}
        $background={background}
        target="_blank"
        onClick={handleAnalytics}
        className="d-none d-sm-block"
      >
        <Row className="align-items-center">
          <Col xs={10}>
            <Typography
              variant="textSm"
              weight={700}
              color="white"
              family="display"
              tag="h5"
              xl={{
                variant: small ? "textXs" : "textLg",
              }}
            >
              Scan to DOWNLOAD APK FOR ANDROID TO PLAY!
            </Typography>
            <Typography
              variant="textXs"
              family={"body"}
              weight={700}
              color="white"
              tag="p"
              xl={{
                variant: small ? "text2xs" : "textSm",
              }}
              style={{ textTransform: "none", textShadow: "none" }}
            >
              Scan here to download the game and start playing, you need to
              download APK in order to play on your android!
            </Typography>
          </Col>
          <Col xs={2}>
            <QrWrap src={qr} />
          </Col>
        </Row>
      </GameApkButtonWrap>
      {/* Mobile only */}
      {!mobileHidden && (
        <Button
          variant="secondary"
          to={link}
          target="_blank"
          onClick={handleAnalytics}
          textStyle={{
            padding: small ? "12px" : "16px",
            textShadow: "0px 1px 0px #B33E05",
          }}
          className="d-block d-sm-none"
          as={Link}
        >
          <Row className="align-items-center">
            <Col xs={2}>
              <IconWrap color="white" name="download" size="95%" />
            </Col>
            <DescriptionSecondaryCol xs={10}>
              <Typography
                variant="textSm"
                weight={700}
                color="white"
                family="display"
                tag="h5"
                xl={{
                  variant: small ? "textXs" : "textMd",
                }}
              >
                Download and Play Now!
              </Typography>
              <Typography
                variant="textXs"
                weight={700}
                color="white"
                tag="p"
                xl={{
                  variant: small ? "text2xs" : "textSm",
                }}
                style={{ textTransform: "none", textShadow: "none" }}
              >
                Android Users, click here to download the APK and play now
              </Typography>
            </DescriptionSecondaryCol>
          </Row>
        </Button>
      )}
    </>
  );
}
