import { Row } from "../../components/base/Row";
import { Col } from "../../components/base/Col";
import styled from "styled-components";
import { StakingOverviewLinkCard } from "../../components/staking/overview/StakingOverviewLinkCard";
import { StakingOverviewLeaderboardBanner } from "../../components/staking/overview/StakingOverviewLeaderboardBanner";
import { StakingOverviewCUStats } from "../../components/staking/overview/StakingOverviewCUStats";
import { StakingOverviewCULPStats } from "../../components/staking/overview/StakingOverviewCULPStats";
import { StakingOverviewPrice } from "../../components/staking/overview/StakingOverviewPrice";
import { useState } from "react";
import { StakeModal } from "../../components/staking/Modals/StakeModal";
import { StakingSuccessModal } from "../../components/staking/Modals/StakingSuccessModal";
import { TOKEN_NAMES } from "../../components/staking/Modals/AssetSelector";
import { stakeModalOpenedAtom } from "../../store/staking";
import { useAtomValue, useSetAtom } from "jotai";
import { RewardModal } from "../../components/staking/Modals/RewardModal";
import { device } from "../../theme/mediaQuery";
import { BUY } from "../../consts/externalLinks";
import { currentWalletAddressAtom } from "../../store";
import { useConnectWallet } from "@web3-onboard/react";
import { googleAnalyticsEvent } from "../../helpers/googleAnalytics";
import { EVENT_KEYS } from "../../consts/googleEvents";
import { defineChain } from "thirdweb";
import { NetworkConfiguration } from "../../components/Network";
import { POLYGON_NETWORK } from "../../components/ConnectWallet/WalletHelper";
import { useSwitchActiveWalletChain } from "thirdweb/react";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (${device.lg}) {
    gap: 40px;
  }
`;

export function StakingOverview() {
  const setShowStakeModal = useSetAtom(stakeModalOpenedAtom);
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  //const [, connect] = useConnectWallet();

  const [stakeInitialTab, setStakeInitialTab] = useState(TOKEN_NAMES.CU);

  // we need to pass the initial tab to the stake modal depending on the button clicked
  const handleOpenStakeModal = (initialTab) => {
    setStakeInitialTab(initialTab);
    setShowStakeModal(true);
  };

  const connectWallet = () => {
    //connect();
    googleAnalyticsEvent(EVENT_KEYS.CONNECT_CLICKED);
  };
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const arbChain = defineChain({
    id: netconfig.networkIdArbitrum,
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
  });
  const switchChain = useSwitchActiveWalletChain();

  return (
    <Wrap>
      <StakingOverviewLeaderboardBanner />
      <Row>
        <Col xl={6} className="mb-4 mb-xl-0">
          <StakingOverviewCUStats
            chain={arbChain}
            switchChain={switchChain}
            onStake={() => handleOpenStakeModal(TOKEN_NAMES.CU)}
          />
        </Col>
        <Col xl={6}>
          <StakingOverviewCULPStats
            chain={arbChain}
            switchChain={switchChain}
            onStake={() => handleOpenStakeModal(TOKEN_NAMES.CULP)}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4 mb-xl-0">
          <StakingOverviewPrice />
        </Col>
        <Col xl={6}>
          {walletAddress && (
            <StakingOverviewLinkCard
              title="Purchase assets"
              description="Running low on CU or CULP tokens? Click below to purchase more!"
              buttonLabel="Purchase More"
              buttonLink={BUY.CU}
            />
          )}
          {!walletAddress && (
            <StakingOverviewLinkCard
              title="Want to see more?"
              description="Connect your wallet to view even more!"
              buttonLabel="Connect Wallet"
              // onClick={connectWallet}
            />
          )}
        </Col>
      </Row>
      {walletAddress && (
        <>
          <Row>
            <Col lg={6} xl={4} className="mb-4 mb-xl-0">
              <StakingOverviewLinkCard
                title="Deposits"
                description="View your current deposits and their associated withdraw dates. Reward claims will be prevented if there is a withdrawable deposit."
                buttonLabel="Go To My Deposits"
                buttonLink="/staking/deposits"
              />
            </Col>
            <Col lg={6} xl={4} className="mb-4 mb-xl-0">
              <StakingOverviewLinkCard
                title="Rewards"
                description="Earn rewards by staking CU or CULP. Rewards are distributed daily at 4:45 UTC. After a claim has been initiated, the rewards will be subject to a 7-day vesting period before the claim can be completed."
                buttonLabel="Claim Rewards"
                buttonLink="/staking/rewards"
              />
            </Col>
            <Col lg={6} xl={4}>
              <StakingOverviewLinkCard
                title="Badges"
                description="Delegate Badges to increase your Badge Boost. The higher the badge boost, the higher the sAsset amount you will receive on deposit. sAsset amounts determine the amount of rewards your deposits generate and, for CU, your voting power. sAssets (sCU & sCULP) are non-transferrable."
                buttonLabel="delegate badges"
                buttonLink="/staking/badges"
              />
            </Col>
          </Row>
          <StakeModal initialTab={stakeInitialTab} />
          <StakingSuccessModal />
        </>
      )}
      <RewardModal />
    </Wrap>
  );
}
