import { Row } from "../base/Row";
import { Col } from "../base/Col";
import styled from "styled-components";
import { Typography } from "../base/Typography";
import { Chip } from "../base/Chip";
import { device } from "../../theme/mediaQuery";
import { Button } from "../base/Button";
import { Icon } from "../base/Icon";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../helpers/useIsMobile";
import { GameApkButton } from "../home/games/GameApkButton";

const CardWrap = styled(Row)`
  align-items: center;
  flex-direction: ${({ $reverse }) => ($reverse ? "row-reverse" : "row")};
  row-gap: 16px;
  @media (${device.sm}) {
    row-gap: 32px;
  }
`;
const ImageWrap = styled.div`
  width: 100%;
  position: relative;
  align-items: center;
  padding: 0;
  aspect-ratio: 1 / 1;
  max-width: 500px;
  @media (${device.md}) {
    max-width: 625px;
  }
`;

const Image = styled.img`
  width: 100%;
  position: relative;
  z-index: 1;
`;

const ComingSoonWrap = styled.div`
  position: absolute;

  top: 45%;
  left: -10px;
  right: -10px;

  z-index: 1;
  background: #f16a3d;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (${device.xl}) {
    height: 80px;
  }

  & p {
    font-size: 26px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.display};
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);

    @media (${device.xl}) {
      font-size: 40px;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 45px;

    top: 35%;
    z-index: -1;
    background: #c45632;
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 28% 51%, 0% 0%);

    @media (${device.xl}) {
      height: "55px";
    }
  }

  &::before {
    left: 0;
    transform: translateX(-99%);
  }

  &::after {
    right: 0;
    transform: rotate(180deg) translateX(-99%);
  }
`;

const ImageCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: ${({ $size }) => ($size === "sm" ? "auto" : 0)};
`;

const DetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  height: 100%;
  gap: 24px;

  @media (${device.lg}) {
    padding-left: ${({ $reverse }) => ($reverse ? 0 : "36px")};
    padding-right: ${({ $reverse }) => ($reverse ? "36px" : 0)};
  }
  @media (${device.xxl}) {
    gap: 32px;
    padding-left: ${({ $reverse }) => ($reverse ? 0 : "52px")};
    padding-right: ${({ $reverse }) => ($reverse ? "52px" : 0)};
  }
`;

const TitleWrap = styled(Typography)`
  font-size: 32px;
  @media (${device.md}) {
    font-size: 60px;
  }
  @media (${device.xxl}) {
    font-size: 65px;
  }
`;

export const TagsWrap = styled.div`
  display: flex;
  gap: 30px 16px;
  flex-direction: ${({ $size }) => ($size === "sm" ? "column" : "row")};

  @media (${device.md}) {
    column-gap: 21px;
  }
  @media (${device.xl}) {
    column-gap: 32px;
  }
  @media (${device.xxl}) {
    column-gap: 45px;
  }

  & > div {
    min-width: 118px;
    @media (${device.lg}) {
      min-width: 186px;
    }
    @media (${device.xl}) {
      min-width: 236px;
    }
  }
`;

const ListWrap = styled.ul`
  padding: 0 0 0 24px;
  @media (${device.md}) {
    padding: 0 0 0 32px;
  }
  @media (${device.xl}) {
    padding: 0 0 0 40px;
  }

  & li {
    list-style-type: disc;
    list-style-position: outside;
    line-height: normal;

    @media (${device.lg}) {
      line-height: 32px;
    }
    @media (${device.xl}) {
      line-height: 45px;
    }
  }
`;

const ChipList = styled.ul`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 12px;

  @media (${device.lg}) {
    gap: 16px;
  }

  @media (${device.xxl}) {
    padding-top: 24px;
  }
`;

const ChipWrap = styled(Chip)`
  background-color: ${({ $color }) => $color};

  font-size: 10px;

  @media (${device.md}) {
    font-size: 14px;
  }
  @media (${device.lg}) {
    font-size: 18px;
  }
  @media (${device.xxl}) {
    font-size: 21px;
  }
`;

const FooterButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @media (min-width: 466px) {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
  }

  @media (${device.md}) {
    flex-wrap: ${({ $hasAppButtons }) => $hasAppButtons && "wrap"};
  }

  @media (${device.xxl}) {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
  }

  & > a,
  & > button {
    max-width: 458px;
    min-width: 200px;
    flex-grow: 1;
    @media (${device.sm}) {
      flex-grow: unset;
    }

    & * {
      font-size: 20px;
      @media (${device.lg}) {
        font-size: 24px;
      }
      @media (${device.xl}) {
        font-size: 26px;
      }
    }
  }
`;

const MobileLinksWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 16px;
  @media (${device.md}) {
    flex-wrap: nowrap;
  }
  @media (${device.xxl}) {
    flex-grow: 1;
  }
`;

const AppButtonWrap = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  background: ${({ theme, $background }) =>
    $background ? $background : theme.palette.indigo300};
  padding: 12px 16px;
  border-radius: 16px;
  white-space: break-spaces;
  text-decoration: none;
  width: 100%;

  @media (${device.sm}) {
    max-width: 225px;
  }

  & h5 {
    line-height: 9px;
    font-size: 10px;
    text-transform: uppercase;
    @media (${device.xl}) {
      font-size: 12px;
    }
  }
  & h6 {
    font-size: 22px;
    text-transform: none;
    margin-bottom: 0;
    @media (${device.xl}) {
      font-size: 24px;
    }
  }
`;

const AppButtonText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > * {
    white-space: nowrap;
  }
`;

export function GameBanner({
  image,
  title,
  primary,
  secondary,
  mobileLinks,
  description = [],
  tags1 = [],
  tags2 = [],
  title1,
  title2,
  subtitle1 = "Required NFTS",
  subtitle2 = "Playable On",
  unreleased = false,
  reverse = false,
  titleColor = "magenta100",
  tagsColor = "#7379E2",
  size = "lg",
}) {
  const isMobile = useIsMobile();

  const handleClick = (button) => {
    if (isMobile && button.analyticsMobile) {
      button.analyticsMobile();
    } else if (button.analytics) {
      button.analytics();
    }
  };

  return (
    <CardWrap $reverse={reverse}>
      <ImageCol xs={12} lg={5} $size={size}>
        <ImageWrap>
          <Image src={image} alt="Game Banner" />
          {unreleased && (
            <ComingSoonWrap>
              <p>Coming soon</p>
            </ComingSoonWrap>
          )}
        </ImageWrap>
      </ImageCol>
      <Col xs={12} lg={7} className="p-0">
        <DetailWrap $reverse={reverse}>
          <TitleWrap
            variant="displaySm"
            weight={700}
            color={titleColor}
            tag="h3"
            lg={{
              variant: "displayXl",
            }}
          >
            {title}
          </TitleWrap>
          <ListWrap>
            {description.map((text, i) => (
              <Typography
                variant="textSm"
                weight={700}
                color="white"
                tag="li"
                key={`${title}-desc-${i}`}
                md={{
                  variant: "textXl",
                }}
                xxl={{
                  variant: "displayXs",
                }}
              >
                {text}
              </Typography>
            ))}
          </ListWrap>
          <TagsWrap $size={size}>
            {tags1.length > 0 && (
              <TagsCard
                title={title1}
                subtitle={subtitle1}
                list={tags1}
                color={tagsColor}
              />
            )}
            {tags2.length > 0 && (
              <TagsCard
                title={title2}
                subtitle={subtitle2}
                list={tags2}
                color={tagsColor}
              />
            )}
          </TagsWrap>
          {mobileLinks && (
            <GameApkButton
              small={false}
              link={mobileLinks.apk.link}
              qr={mobileLinks.apk.qr}
              event={mobileLinks.apk.event}
              mobileHidden
            />
          )}
          <FooterButtonWrap $hasAppButtons={mobileLinks}>
            {secondary && (
              <Button
                as={secondary?.disabled ? "button" : Link}
                size="lg"
                variant="secondary"
                block={false}
                target="_blank"
                rel="noopener noreferrer"
                to={
                  isMobile && secondary.linkMobile
                    ? secondary.linkMobile
                    : secondary.link
                }
                onClick={() => handleClick(secondary)}
                disabled={secondary?.disabled ? true : false}
              >
                {isMobile && secondary.titleMobile
                  ? secondary.titleMobile
                  : secondary.title}
              </Button>
            )}
            {primary && (
              <Button
                as={Link}
                size="lg"
                variant="primary"
                block={false}
                target="_blank"
                rel="noopener noreferrer"
                to={
                  isMobile && primary.linkMobile
                    ? primary.linkMobile
                    : primary.link
                }
                onClick={() => handleClick(primary)}
                disabled={primary?.disabled ? true : false}
              >
                {isMobile && primary.titleMobile
                  ? primary.titleMobile
                  : primary.title}
              </Button>
            )}
            {mobileLinks && (
              <MobileLinksWrap>
                <AppButton
                  platform="android"
                  link={mobileLinks.android.link}
                  event={mobileLinks.android.analytics}
                />
                <AppButton
                  platform="ios"
                  link={mobileLinks.ios.link}
                  event={mobileLinks.ios.analytics}
                />
              </MobileLinksWrap>
            )}
          </FooterButtonWrap>
        </DetailWrap>
      </Col>
    </CardWrap>
  );
}

export function TagsCard({ list, title, subtitle, color }) {
  const DesktopIcon = (token) =>
    token.toLowerCase().includes("desktop") && (
      <Icon className="d-none d-lg-flex" name="desktop" />
    );
  const subtitleColor = title ? "magenta50" : "white";
  return (
    <div>
      {title && (
        <Typography
          variant="textSm"
          weight={700}
          color="white"
          tag="h5"
          xxl={{
            variant: "displayMd",
          }}
          md={{
            variant: "displaySm",
          }}
          sm={{
            variant: "displayXs",
          }}
          className="mb-3"
        >
          {title}
        </Typography>
      )}
      <Typography
        variant="textXs"
        weight={700}
        color={subtitleColor}
        tag="h5"
        lg={{
          variant: "displayXs",
        }}
        md={{
          variant: "displaySm",
        }}
        sm={{
          variant: "textLg",
        }}
      >
        {subtitle}
      </Typography>
      <ChipList>
        {list.map((token, i) => (
          <ChipWrap $size="md" key={`${subtitle}-${token}-${i}`} $color={color}>
            {DesktopIcon(token)} {token}
          </ChipWrap>
        ))}
      </ChipList>
    </div>
  );
}

export function AppButton({ platform, link, event, background }) {
  const title = platform === "ios" ? "App Store" : "Google Play";
  const topTitle = platform === "ios" ? "Download on the" : "Get it on";
  return (
    <AppButtonWrap
      to={link}
      onClick={() => event()}
      target="_blank"
      rel="noreferrer"
      $background={background}
    >
      <Icon size="35px" name={platform} />
      <AppButtonText>
        <Typography
          variant="textXs"
          family={"body"}
          weight={400}
          color="white"
          tag="h5"
        >
          {topTitle}
        </Typography>
        <Typography
          variant="textXl"
          family={"body"}
          weight={700}
          color="white"
          tag="h6"
        >
          {title}
        </Typography>
      </AppButtonText>
    </AppButtonWrap>
  );
}
