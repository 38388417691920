import styled from "styled-components";
import { Typography } from "../base/Typography";
import { useEffect, useState } from "react";
import pluralize from "pluralize";
import { capitalizeFirstLetter } from "../Helpers";
import { device } from "../../theme/mediaQuery";

const CountdownCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
  padding: 8px 12px 12px;
  background: linear-gradient(180deg, #fc6cb2 0%, #780139 100%);
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 0 auto;
  max-width: 283px;

  @media (${device.lg}) {
    padding: 8px 24px 12px;
  }
`;

const Title = styled(Typography)`
  color: white;
  font-weight: bold;
`;

const Divider = styled.hr`
  border: none;
  margin: 0;
  height: 1px;
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  filter: drop-shadow(1px 0px 0px rgba(0, 0, 0, 0.25));
`;

const TimeWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
  flex-wrap: wrap;
`;

const TimeBox = styled.div`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(153, 153, 153, 0.1) 100%
  );
  padding: 4px 6px;
  width: 35px;
  border-radius: 8px;
  @media (min-width: 370px) {
    width: 52px;
  }
`;

const TimeWrap = styled(Typography)`
  line-height: normal;
  text-align: center;
`;

const calculateTimeLeft = (date) => {
  const target = new Date(date * 1000);
  const current = new Date();
  const difference = target.getTime() - current.getTime();
  let timeLeft = { days: 0, hrs: 0, mins: 0, secs: 0 };

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
      mins: Math.floor((difference / 1000 / 60) % 60),
      secs: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const CountdownCard = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <CountdownCardWrapper>
      <Title as="h3">Join Now!</Title>
      <Divider />
      <TimeWrapper>
        {Object.keys(timeLeft).map((interval) => (
          <TimeBox key={interval}>
            <TimeWrap
              family="display"
              variant="textLg"
              md={{ variant: "textXl" }}
              color="#F9CF81"
            >
              {timeLeft[interval] || "0"}
            </TimeWrap>
            <TimeWrap
              variant="text2xs"
              md={{ variant: "textXs" }}
              color="white"
            >
              {capitalizeFirstLetter(pluralize(interval, timeLeft[interval]))}
            </TimeWrap>
          </TimeBox>
        ))}
      </TimeWrapper>
    </CountdownCardWrapper>
  );
};
