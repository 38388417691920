import React from "react";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import Milestone from "./LeaderboardMilestone";
import DateMilestone from "./LeaderboardDateMilestone";
import { ProgressBar } from "../../base/ProgressBar";

export const ProgressBarWrapper = styled.div`
  position: relative;

  @media (${device.lg}) {
    margin: 50px 0px;
  }

  .milestone {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 100%;
    margin: auto 0;
    margin-left: -30px;
    top: 0;
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 1px 4px -1px;
    font-family: "Insanibc";
    font-size: 26px;

    &.reached {
      color: #fff;
      background: linear-gradient(#e9c100, #c79428);
      border: 3px solid #fadd40;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    }

    &:nth-child(1) {
      left: 3%;
    }

    &:nth-child(2) {
      left: 10%;
    }

    &:nth-child(3) {
      left: 25%;
    }

    &:nth-child(4) {
      left: 50%;
    }

    &:nth-child(5) {
      left: 100%;
    }
  }
`;

const MilestonesWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  margin-top: 16px;
`;

export const DateProgressBarWrapper = styled(ProgressBarWrapper)`
  position: relative;
  margin: 50px 0px;
  @media (${device.md}) {
    margin: 110px 0 140px 0;
  }
`;
const LeaderboardProgressBar = ({
  milestones,
  progressBarColor,
  progress,
  date = false,
}) => {
  if (date) {
    return (
      <DateProgressBarWrapper>
        <ProgressBar color={progressBarColor} now={progress} />
        <MilestonesWrapper>
          {milestones.map((milestone, i) => (
            <DateMilestone
              {...milestone}
              key={`date-milestone-${milestone.value}-${i}`}
            />
          ))}
        </MilestonesWrapper>
      </DateProgressBarWrapper>
    );
  }
  return (
    <ProgressBarWrapper>
      {milestones.map((milestone, i) => (
        <Milestone {...milestone} key={`milestone-${milestone.value}-${i}`} />
      ))}
      <ProgressBar color={progressBarColor} now={progress} />
    </ProgressBarWrapper>
  );
};

export default LeaderboardProgressBar;
