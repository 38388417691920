import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography } from "../../base/Typography";
import { Button } from "../../base/Button";
import { device } from "../../../theme/mediaQuery";
import { InfoModal } from "./InfoModal";
import { RecipientInput } from "./RecipientInput";
import { currentWalletAddressAtom } from "../../../store";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { badgeModalAtom, badgeSuccessModalAtom } from "../../../store/staking";
import { useDelegateBadge } from "../../../query/vault";

const InfoModalWrap = styled(InfoModal)`
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 24px 32px;
  @media (${device.md}) {
    gap: 40px;
    padding: 24px 40px;
  }
`;
const BadgeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 6px;
  @media (${device.md}) {
    gap: 10px;
  }
`;

export function DelegateModal() {
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  // const delegateMutation = useDelegateBadge(badge, token, recipientAddress);
  const [modal, setModal] = useAtom(badgeModalAtom);
  const setSuccessModal = useSetAtom(badgeSuccessModalAtom);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [smartWalletError, setSmartWalletError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const disable = addressError || smartWalletError || recipientAddress === "";

  const showModal = modal.show;
  const badge = modal.badge;
  const token = modal.token;

  const delegateMutation = useDelegateBadge(badge, token, recipientAddress);

  const isLoading = delegateMutation.isPending;

  const handleClose = () => {
    setModal({ show: false, badge: null, token: null });
  };

  const handleRecipientChange = (value) => {
    setRecipientAddress(value); // reset smart wallet error
    setSmartWalletError(false);
  };

  const handleConfirm = () => {
    delegateMutation.mutate(null, {
      onSuccess: (result) => {
        const transactionHash = result?.transactionHash;
        setSuccessModal({
          show: true,
          badge,
          token,
          hash: transactionHash,
          recipientAddress,
        });
        handleClose();
      },
    });
  };

  // reset state when modal is closed
  useEffect(() => {
    if (!showModal) {
      setRecipientAddress("");
      setSmartWalletError(false);
      setAddressError(false);
    }
  }, [showModal, walletAddress]);

  return (
    <InfoModalWrap
      toggleModal={handleClose}
      showModal={showModal}
      headerTitle={`Delegate Badge - ${token}`}>
      <DelegateInfoScreen
        badge={badge}
        handleRecipientChange={handleRecipientChange}
        setAddressError={setAddressError}
        handleConfirm={handleConfirm}
        disable={disable}
        isLoading={isLoading}
      />
    </InfoModalWrap>
  );
}

const BadgeWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ $large }) => ($large ? "12px" : "0")};
  gap: 12px;
  align-items: center;
  align-self: center;
  z-index: 13;
`;
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const Image = styled.img`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  @media (${device.md}) {
    width: 90px;
    height: 90px;
  }
`;

const ImageWrap = styled.div`
  padding: 10px;
  border: 6px solid #e7b610;
  background: #231852;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

  @media (${device.md}) {
    padding: 15px;
    border: 6px solid #e7b610;
  }
`;

const ImageLarge = styled.img`
  width: 85px;
  height: 85px;
  border-radius: 50%;
  @media (${device.md}) {
    width: 130px;
    height: 130px;
  }
`;

function Badge({ name, description, img, large = false }) {
  const nameArray = name.split(" - ");
  const title = nameArray[0];
  const tier = nameArray.length > 1 ? nameArray[1] : null;
  if (large) {
    return (
      <BadgeWrap $large={large}>
        <ImageWrap>
          <ImageLarge src={img} alt={`${img} logo`} />
        </ImageWrap>
        <TextWrap>
          <Typography
            as="h2"
            variant="textXl"
            md={{ variant: "displaySm" }}
            color="#9441E7">
            {title}
          </Typography>
          {tier && (
            <Typography
              as="p"
              variant="textMd"
              md={{ variant: "textLg" }}
              color="#6A6A6A">
              {tier}
            </Typography>
          )}
          {description && (
            <Typography
              as="p"
              variant="textSm"
              md={{ variant: "textMd" }}
              color="#6A6A6A"
              className="text-center"
            >
              {description}
            </Typography>
          )}
        </TextWrap>
      </BadgeWrap>
    );
  }
  return (
    <BadgeWrap>
      <Image src={img} alt={`${img} logo`} />
      <TextWrap>
        <Typography
          as="h2"
          variant="textLg"
          sm={{ variant: "textXl" }}
          md={{ variant: "displaySm" }}
          color="#9441E7">
          {title}
        </Typography>
        <Typography
          as="p"
          variant="textMd"
          md={{ variant: "textLg" }}
          color="#6A6A6A"
        >
          {tier && tier}
          {tier && description && " - "}
          {description && `Pool ID: ${description}`}
        </Typography>
      </TextWrap>
    </BadgeWrap>
  );
}

const ButtonSection = styled.div`
  text-align: center;
`;

const ButtonWrap = styled(Button)`
  align-self: center;
  min-width: 152px;
`;

const SpinnerWrap = styled.div`
  padding: 8px 2px;
  height: 36px;

  & svg {
    filter: none;
    height: 20px;
    vertical-align: top;
  }
`;

function DelegateInfoScreen({
  badge,
  handleRecipientChange,
  setAddressError,
  disable,
  isLoading,
  handleConfirm,
}) {
  return (
    <>
      <Badge
        name={badge?.name}
        description={badge?.poolId}
        img={badge?.image}
      />
      <BadgeSection>
        <RecipientInput
          onChange={handleRecipientChange}
          onError={setAddressError}
          label="Enter the address you would like to delegate this badge to"
          placeholder="Enter address"
        />
      </BadgeSection>
      <ButtonSection>
        <ButtonWrap
          size="lg"
          variant="secondary"
          onClick={handleConfirm}
          disabled={disable || isLoading}
          isLoading={isLoading}
        >
          Confirm
        </ButtonWrap>
        <Typography
          as="p"
          family="body"
          variant="textSm"
          md={{ variant: "textMd" }}
          color="#252525"
          className="mt-2"
        >
          Badge delegation is permanent and cannot be revoked!
        </Typography>
      </ButtonSection>
    </>
  );
}
