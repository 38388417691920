import React from "react";
import styled, { css } from "styled-components";
import { device } from "../../theme/mediaQuery";
import {
  breakpointProps,
  responsiveBreakpoints,
} from "../../theme/utils/responsive-props";
import { Link } from "react-router-dom";
import { Icon } from "./Icon";
import Spinner from "./Spinner";

const ButtonVariant = {
  primary: "primary",
  secondary: "secondary",
  destructive: "destructive",
  rainbow: "destructive",
};

const ButtonSize = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
};

const ButtonHeight = {
  default: {
    xs: "30px",
    sm: "30px",
    md: "40px",
    lg: "50px",
  },
  md: {
    xs: "30px",
    sm: "43px",
    md: "46px",
    lg: "67px",
  },
};

const ButtonPadding = {
  default: {
    xs: "0 10px",
    sm: "0 20px",
    md: "0 20px",
    lg: "0 20px",
  },
  md: {
    xs: "0 10px",
    sm: "0 20px",
    md: "0 20px",
    lg: "0 20px",
  },
};

const ButtonFontSize = {
  default: {
    xs: "16px",
    sm: "16px",
    md: "20px",
    lg: "24px",
  },
  md: {
    xs: "16px",
    sm: "24px",
    md: "24px",
    lg: "28px",
  },
};

const pickButtonStyles = (state) => {
  return css`
    background-image: ${(args) =>
      pickThemeButtonBackgroundColor({ ...args, state })};
    color: ${(args) => pickThemeButtonTextColor({ ...args, state })};
    text-shadow: 0px 2px 0px
      ${(args) => pickTextShadowColor({ ...args, state })};
  `;
};

const pickButtonBorderStyles = (state) => {
  return css`
    background: ${(args) => pickThemeButtonBorderColor({ ...args, state })};
  `;
};

function getButtonThemeProperty(theme, variant = ButtonVariant.primary) {
  return theme.components.button[variant];
}

function pickThemeButtonBackgroundColor({
  theme,
  $variant,
  state = "default",
}) {
  const themeConfig = getButtonThemeProperty(theme, $variant);

  return themeConfig[state]?.background || themeConfig.default.background;
}

function pickThemeButtonTextColor({ theme, $variant, state = "default" }) {
  const themeConfig = getButtonThemeProperty(theme, $variant);

  return themeConfig[state]?.textColor || themeConfig.default.textColor;
}

function pickThemeButtonBorderColor({ theme, $variant, state = "default" }) {
  const themeConfig = getButtonThemeProperty(theme, $variant);

  return themeConfig[state]?.borderColor || themeConfig.default.borderColor;
}

function pickTextShadowColor({ theme, $variant, state = "default" }) {
  const themeConfig = getButtonThemeProperty(theme, $variant);

  return (
    themeConfig[state]?.textShadowColor || themeConfig.default.textShadowColor
  );
}

function pickThemeButtonFontSize({
  $size = ButtonSize.md,
  $breakpoint = "default",
}) {
  return ButtonFontSize[$breakpoint][$size];
}

function pickThemeButtonPadding({
  $size = ButtonSize.md,
  $breakpoint = "default",
}) {
  return ButtonPadding[$breakpoint][$size];
}

function pickThemeButtonHeight({
  $size = ButtonSize.md,
  $breakpoint = "default",
}) {
  return ButtonHeight[$breakpoint][$size];
}

const BaseThemeButton = styled.div`
  align-items: center;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${pickThemeButtonPadding};
  min-height: ${pickThemeButtonHeight};
  border-radius: 12px;
  background-clip: padding-box;
  border: solid 2px transparent;
  white-space: nowrap;

  @media (${device.md}) {
    padding: ${(props) =>
      pickThemeButtonPadding({ ...props, $breakpoint: "md" })};
    min-height: ${(props) =>
      pickThemeButtonHeight({ ...props, $breakpoint: "md" })};
  }

  ::after {
    //pattern
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    background-image: ${({ theme, $pattern }) =>
      $pattern
        ? theme.pattern[$pattern]
          ? `url(${theme.pattern[$pattern]})`
          : $pattern
        : `url(${theme.pattern.swirly})`};
    background-size: 150%;
    visibility: ${({ $variant }) =>
      $variant === ButtonVariant.destructive ? "hidden" : "visible"};
  }

  ::before {
    // border gradient
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${pickThemeButtonBorderColor};
    z-index: -1;
    border-radius: inherit;
    margin: -2px;
  }
`;

const ButtonContent = styled.div`
  position: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: break-spaces;
  text-align: center;
  gap: 10px;
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.display};
  text-transform: uppercase;
  font-weight: 800;
  font-size: ${pickThemeButtonFontSize};
  opacity: ${({ $transparent }) => ($transparent ? 0 : 1)};
  transition: opacity 0.3s ease;

  white-space: ${({ $nowrap }) => ($nowrap ? "nowrap" : "")};
  word-break: ${({ $nowrap }) => ($nowrap ? "keep-all" : "")};

  & svg {
    filter: drop-shadow(
      0px 2px 0px
        ${(props) => (props.$transparent ? "none" : pickTextShadowColor(props))}
    );
  }

  @media (${device.md}) {
    font-size: ${(props) =>
      pickThemeButtonFontSize({ ...props, $breakpoint: "md" })};
  }
`;

const ButtonWrap = styled.button`
  position: relative;
  z-index: 1;
  border: none;
  background: none;
  width: ${({ $block }) => ($block ? "100%" : "auto")};
  text-decoration: none;

  ${(args) =>
    breakpointProps(
      args,
      (props, query) => query`
                      width: ${props.block ? "100%" : "auto"};
                  `
    )};

  ${BaseThemeButton} {
    ${pickButtonStyles("default")};
  }

  :hover {
    ${BaseThemeButton} {
      ${pickButtonStyles("hover")};
    }
  }

  :focus-visible {
    ${BaseThemeButton} {
      &:before {
        // change border color
        ${pickButtonBorderStyles("focus")};
      }
    }
  }

  :disabled {
    :hover {
      ${BaseThemeButton} {
        ${pickButtonStyles("disabled")};

        &:before {
          // change border color
          ${pickButtonBorderStyles("disabled")};
        }
      }
    }

    ${BaseThemeButton} {
      ${pickButtonStyles("disabled")};
      cursor: not-allowed;

      &:before {
        // change border color
        ${pickButtonBorderStyles("disabled")};
      }
    }
    ${ButtonContent} {
      & svg {
        filter: drop-shadow(
          0px 2px 0px
            ${(props) => pickTextShadowColor({ ...props, state: "disabled" })}
        );
      }
    }
  }

  :active {
    ${BaseThemeButton} {
      ${pickButtonStyles("active")};

      &:before {
        // change border color
        ${pickButtonBorderStyles("active")};
      }
    }
  }
`;

export const LinkButton = styled(Link)`
  font-weight: 400;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.white};
  text-decoration: none;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.display};
  border-bottom: 2px solid ${({ theme }) => theme.palette.white};

  :hover {
    color: ${({ theme }) => theme.palette.white};
  }
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Button = ({
  variant,
  size,
  block,
  children,
  md,
  prependIcon,
  textStyle,
  isLoading = false,
  pattern,
  nowrap = false,
  ...rest
}) => {
  const breakpointsProps = responsiveBreakpoints(rest);

  return (
    <ButtonWrap
      $size={size}
      $variant={variant}
      $block={block}
      $breakpointProps={breakpointsProps}
      {...rest}
    >
      <BaseThemeButton
        $size={size}
        $variant={variant}
        style={textStyle}
        $pattern={pattern}
      >
        <ButtonContent
          $size={size}
          $variant={variant}
          $transparent={isLoading}
          $nowrap={nowrap}
        >
          {prependIcon && <Icon name={prependIcon} />}
          {children}
        </ButtonContent>
        {isLoading && (
          <LoadingWrap>
            <Spinner size="30px" />
          </LoadingWrap>
        )}
      </BaseThemeButton>
    </ButtonWrap>
  );
};