import React, { useEffect } from "react";
import { AlertModal } from "./AlertModal";
import { Button } from "../../base/Button";
import { Portal } from "../../common/Portal";
import { rewardErrorModalAtom } from "../../../store/staking";
import { useAtom } from "jotai";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Typography } from "../../base/Typography";
import { device } from "../../../theme/mediaQuery";

const ButtonWrap = styled.div`
  width: 100%;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  & a * {
    font-size: 16px;

    @media (${device.md}) {
      font-size: 25px;
    }
  }

  @media (${device.md}) {
    gap: 60px;
  }
`;

export function ErrorModal({ variant = "primary" }) {
  const [modal, setModal] = useAtom(rewardErrorModalAtom);
  const location = useLocation();
  const showModal = modal.show;

  const handleClose = () => {
    setModal({
      show: false,
    });
  };

  // close modal if location changes
  useEffect(() => {
    handleClose();
  }, [location.pathname]);

  return (
    <Portal>
      <AlertModal
        toggleModal={handleClose}
        showModal={showModal}
        title="Uh oh!"
        variant={variant}
      >
        <ButtonWrap>
          <Typography
            tag="p"
            variant="textMd"
            md={{ variant: "displayXs" }}
            color="white"
            family="display"
            className="text-center"
          >
            You cannot claim this reward due to an expired deposit. Please
            withdraw the expired deposit to enable reward claims.
          </Typography>
          <Button
            as={Link}
            to="/staking/deposits"
            size="lg"
            variant="secondary"
            block={true}
          >
            Withdraw expired deposit
          </Button>
        </ButtonWrap>
      </AlertModal>
    </Portal>
  );
}
