import { Container } from "../components/base/Container";
import { SectionCard } from "../components/common/SectionCard";
import styled from "styled-components";
import { device } from "../theme/mediaQuery";
import useDocumentTitle from "../helpers/useDocumentTitle";

const ContentWrap = styled.div`
  padding: 0 20px;
  margin: 0 auto;
  color: ${({ theme }) => theme.palette.white};
  padding-bottom: 50px;

  & a {
    color: ${({ theme }) => theme.palette.white};
  }

  p,
  li {
    font-size: 16px;
    padding: 10px 0;
    font-weight: 600;
  }

  & .c2 {
    font-size: 23px;
    font-weight: 700;
  }

  & ul {
    font-size: 16px;
    padding: 10px 15px;
    font-weight: 600;
  }

  & li {
    list-style-type: disc;
  }

  @media (${device.md}) {
    p,
    li {
      font-size: 21px;
    }

    & .c2 {
      font-size: 32px;
    }
  }
`;

export function PrivacyPage() {
  useDocumentTitle("Privacy Policy");

  return (
    <Container className="pb-5">
      <SectionCard title="Privacy Policy">
        <ContentWrap>
          <p class="c0">
            <span class="c2">1. Introduction and Overview.</span>
          </p>
          <p class="c0">
            <span class="c1">
              This Privacy Policy (the "Privacy Policy") outlines the practices
              of The Crypto Unicorns DAO, a Nevada Unincorporated Nonprofit
              Association,(“The Crypto Unicorns UNA”, “the UNA”, “we", “us", or
              “our") in relation to the collection, use, and sharing of
              information about you in connection with our digital platforms,
              products, services, and any other interactions you may have with
              us, such as the purchase of digital assets. These terms apply to
              our website located at www.cryptounicorns.fun, any other online
              locations, applications, services, or products linked to this
              Privacy Policy, as well as any other interactions or engagements
              you may have with us (collectively referred to as the "Services").
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              By accessing or using the Services, you agree to our{" "}
              <a href="/terms-of-service">Terms of Service</a> (the “Terms”) and
              understand that the Terms represent a binding agreement between
              you and us. By using the Services, you also agree to our
              collection, use, and disclosure practices, as well as any other
              activities described in this Privacy Policy. If you do not agree
              with the terms of this Privacy Policy, you should immediately
              discontinue the use of the Services. If you have any questions or
              wish to exercise your rights and choices, please contact us at the
              email or portal address set forth in the “Contact Us” section
              below.
            </span>
          </p>
          <p class="c0">
            <span class="c2">2. Changes to Privacy Policy.</span>
          </p>
          <p class="c0">
            <span class="c1">
              We reserve the right to revise this Privacy Policy at any time.
              Any changes will be effective immediately upon our posting of the
              revised Privacy Policy. Your continued use of the Services
              indicates your consent to the Privacy Policy then in effect.
            </span>
          </p>
          <p class="c0">
            <span class="c2">3. Information Collection.</span>
          </p>
          <p class="c0">
            <span class="c1">A. Personal Data</span>
          </p>
          <p class="c0">
            <span class="c1">
              We may ask you to provide us with personally identifiable
              information that can be used to contact or identify you ("Personal
              Data"). This may include, but is not limited to, your email
              address.
            </span>
          </p>
          <p class="c0">
            <span class="c1">B. Usage Data</span>
          </p>
          <p class="c0">
            <span class="c1">
              We collect information that your browser sends when you visit our
              Service or when you access the Service by or through a mobile
              device ("Usage Data"). This may include information such as your
              Internet Protocol address (IP address), browser type, browser
              version, the pages of our Service that you visit, the time and
              date of your visit, and other diagnostic data.
            </span>
          </p>
          <p class="c0">
            <span class="c1">C. Cookies Data</span>
          </p>
          <p class="c0">
            <span class="c1">
              We use cookies and similar technologies to track activity on our
              Service and hold certain information. Cookies are files with a
              small amount of data that may include an anonymous unique
              identifier. You can instruct your browser to refuse all cookies or
              to indicate when a cookie is being sent. However, if you do not
              accept cookies, you may not be able to use some portions of our
              Service.
            </span>
          </p>
          <p class="c0">
            <span class="c1">D. Publicly-available blockchain data.</span>
          </p>
          <p class="c0">
            <span class="c1">
              When you connect your non-custodial blockchain wallet to the
              Services, we collect and log your publicly-available blockchain
              address. Blockchain addresses are publicly-available data that are
              not created or assigned by us or any central party, and are not
              personally identifying.
            </span>
          </p>
          <p class="c0">
            <span class="c2">4. Use of Information.</span>
          </p>
          <p class="c0">
            <span class="c1">
              We use information for necessary business operations as outlined
              in this Privacy Policy. Our reasons for collecting and using
              information encompass:
            </span>
          </p>
          <ul>
            <li>The operation and management of the Services;</li>
            <li>
              Responding to your comments, questions, and requests, and
              providing necessary support;
            </li>
            <li>
              Sending you technical notices, updates, security alerts, and
              messages regarding changes to our policies;
            </li>
            <li>
              Detecting and addressing issues of fraud, breach of terms, or
              potential threats;
            </li>
            <li>Compliance with legal and regulatory requirements.</li>
          </ul>
          <p class="c0">
            <span class="c1">
              We utilize information that does not identify you (including
              aggregated or de-identified information) for general purposes,
              except as prohibited by law. For details on your rights and
              choices concerning our use of your information, refer to the "Your
              Rights and Choices" section below.
            </span>
          </p>
          <p class="c0">
            <span class="c2">5. Sharing and Disclosure of Information.</span>
          </p>
          <p class="c0">
            <span class="c1">
              We may share the information we collect in accordance with this
              Privacy Policy. The categories of parties we may share information
              with include:
            </span>
          </p>
          <ul class="c3 lst-kix_rgm0gb4oo8rx-0 start">
            <li class="c4 li-bullet-0">
              <span class="c1">
                Affiliates. We may share information with our affiliates and
                related entities when they act as our service providers.
              </span>
            </li>
            <li class="c4 li-bullet-0">
              <span class="c1">
                Service Providers. We may share information with third-party
                service providers for business purposes, such as fraud detection
                and prevention, payment processing, customer support, and data
                analytics. All service providers are restricted to using the
                information in accordance with our instructions.
              </span>
            </li>
            <li class="c4 li-bullet-0">
              <span class="c1">
                Professional Advisors. We may share information with our
                professional advisors for audit purposes and compliance with our
                legal obligations.
              </span>
            </li>
            <li class="c4 li-bullet-0">
              <span class="c1">
                Mergers or Acquisitions. In the event of a proposed or actual
                merger, sale, or any other type of acquisition or business
                combination of our assets, or transfer of our business to
                another business, we may share information.
              </span>
            </li>
            <li class="c0 c5 li-bullet-0">
              <span class="c1">
                Legal Compliance. We may share information to comply with legal
                requirements, including responding to lawful requests by public
                authorities.
              </span>
            </li>
          </ul>
          <p class="c0">
            <span class="c1">
              We may also share information that does not identify you
              (including information that has been de-identified or aggregated),
              except as prohibited by law. For information on your rights and
              choices regarding how we share your information, refer to the
              "Your Rights and Choices" section below.
            </span>
          </p>
          <p class="c0">
            <span class="c2">6. Other Parties.</span>
          </p>
          <p class="c0">
            <span class="c1">
              Our Services may incorporate technologies controlled by third
              parties. This may include links that redirect you to websites,
              platforms, and services not controlled by us.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              Please be aware that when you interact with these third parties,
              including when you leave our site, they may independently collect
              and request information from you. The data they collect is subject
              to their own policies and practices, which may include sharing
              information with us. These third parties may store information
              within or outside of the U.S. We recommend that you review their
              privacy policies and terms of use.
            </span>
          </p>
          <p class="c0">
            <span class="c2">7. Your Rights and Choices.</span>
          </p>
          <p class="c0">
            <span class="c1">
              A. Access to Personal Information: You have the right to request
              access to your personal information that we collect and maintain.
              Once we receive and confirm your verifiable request, we will
              disclose to you the personal information we have about you, its
              sources, the business purpose for collecting it, and third parties
              with whom it has been shared.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              B. Deletion of Personal Information: You have the right to request
              that we delete any of your personal information that we have
              collected from you and retained, subject to certain exceptions.
              Once we receive and confirm your verifiable request, we will
              delete your personal information from our records, unless an
              exception applies.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              C. Opt-Out of the Sale of Personal Information and Unsubscribe: If
              we sell personal information, you have the right to opt-out. If
              applicable, you can do this by following the instructions provided
              in our Privacy Policy. Additionally, if you have opted-in to our
              marketing communications, you can unsubscribe at any time by
              clicking the "unsubscribe" link at the bottom of each email we
              send.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              D. Cookies and Tracking: You can manage cookie preferences and
              opt-out of having cookies and other similar collection
              technologies used by adjusting the settings on your browser or
              interface, commonly known as "Do Not Track" settings.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              E. Non-Discrimination: We will not discriminate against you for
              exercising any of your CCPA rights. Specifically, we will not deny
              you goods or services, charge different prices or rates for goods
              or services, or provide you a different level of quality of goods
              or services.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              Please note that we may only respond to two access or deletion
              requests per user within a 12-month period. Also, disabling or
              removing tracking technologies may affect the functionality of the
              Services.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              For more information on &quot;Do Not Track,&quot; visit{" "}
            </span>
            <a href=" http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>
            <span class="c1">.</span>
          </p>
          <p class="c0">
            <span class="c2">8. Data Security.</span>
          </p>
          <p class="c0">
            <span class="c1">
              We take the security of your information seriously. We implement
              and maintain reasonable administrative, physical, and technical
              security safeguards designed to protect your personal information
              from loss, theft, misuse, unauthorized access, disclosure,
              alteration, and destruction.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              These security measures consider the nature of the personal
              information and the risks involved in processing that information.
              However, please be aware that no security measures are completely
              infallible when it comes to transmission via the internet, and we
              cannot guarantee the absolute security of your personal
              information.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              If we become aware of a breach involving your personal
              information, we will take appropriate steps to notify affected
              individuals and regulatory authorities as required by law.
            </span>
          </p>
          <p class="c0">
            <span class="c2">9. International Transfer.</span>
          </p>
          <p class="c0">
            <span class="c1">
              Please note that your personal information may be transferred to,
              processed, and stored in various countries outside your country of
              residence, including but not limited to the European Economic Area
              and the United Kingdom, for the purposes described in this Privacy
              Policy. These countries may have different data protection laws
              than your country of residence.
            </span>
          </p>
          <p class="c0 c7">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              By using our Services, you understand and consent to the
              collection, storage, processing, and transfer of your information
              to our facilities in the United States and those third parties
              with whom we share it as described in this Privacy Policy.
            </span>
          </p>
          <p class="c0">
            <span class="c2">10. Children.</span>
          </p>
          <p class="c0">
            <span class="c1">
              The Services are intended for general audiences and are not
              directed at children. To use the Services, you must legally be
              able to enter into the Agreement. We do not knowingly collect
              personal information (as defined by the U.S. Children’s Privacy
              Protection Act, or “COPPA”) from children. If you are a parent or
              guardian and believe we have collected personal information in
              violation of COPPA, please contact us at{" "}
              <a href="mailto: legal@cryptounicorns.fun">
                legal@cryptounicorns.fun
              </a>{" "}
              and we will remove the personal information in accordance with
              COPPA.
            </span>
          </p>
          <p class="c0">
            <span class="c2">11. Contact Us.</span>
          </p>
          <p class="c0">
            <span class="c1">
              If you have any questions or comments about this Privacy Policy,
              our data practices, or our compliance with applicable law, please
              contact us:
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              By email:{" "}
              <a href="mailto: legal@cryptounicorns.fun">
                legal@cryptounicorns.fun
              </a>{" "}
            </span>
          </p>
          <p class="c0">
            <span>By mail: </span>
            <span class="c8">
              548 Market Street STE 53174, San Francisco CA 94104
            </span>
          </p>
        </ContentWrap>
      </SectionCard>
    </Container>
  );
}
