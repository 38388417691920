import React from "react";
import styled from "styled-components";
import { Box } from "./Box";
import { Typography } from "./Typography";
import { device } from "../../theme/mediaQuery";

const CtaWrap = styled(Box)`
  position: relative;
  height: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  min-height: 140px;
  @media (${device.sm}) {
    padding: 32px;
  }

  @media (${device.lg}) {
    min-height: 200px;
  }

  @media (${device.xl}) {
    padding: 32px 40px;
  }
`;

const Img = styled.img`
  position: absolute;
  height: 140px;

  right: ${({ $reverse }) => ($reverse ? "-40px" : "")};
  left: ${({ $reverse }) => ($reverse ? "" : "-88px")};

  @media (min-width: 407px) {
    height: 100%;

    right: ${({ $reverse }) => ($reverse ? "-25px" : "")};
    left: ${({ $reverse }) => ($reverse ? "" : "-50px")};
  }

  @media (${device.sm}) {
    right: ${({ $reverse }) => ($reverse ? "-35px" : "")};
    left: ${({ $reverse }) => ($reverse ? "" : "-55px")};
  }
  @media (${device.lg}) {
    right: ${({ $reverse }) => ($reverse ? "-55px" : "")};
    left: ${({ $reverse }) => ($reverse ? "" : "-88px")};
  }

  @media (${device.xl}) {
    left: ${({ $reverse }) => ($reverse ? "" : "-50px")};
  }

  @media (${device.xxl}) {
    height: 120%;
    top: -10%;
    left: ${({ $reverse }) => ($reverse ? "" : "-60px")};
  }
`;

const TextWrap = styled.div`
  padding-right: ${({ $reverse }) => ($reverse ? "45px" : 0)};
  padding-left: ${({ $reverse }) => ($reverse ? 0 : "40px")};

  @media (min-width: 407px) {
    padding-right: ${({ $reverse }) => ($reverse ? "80px" : 0)};
    padding-left: ${({ $reverse }) => ($reverse ? 0 : "80px")};
  }

  @media (${device.sm}) {
    padding-right: ${({ $reverse }) => ($reverse ? "20%" : 0)};
    padding-left: ${({ $reverse }) => ($reverse ? 0 : "15%")};
  }
  @media (${device.lg}) {
    padding-right: ${({ $reverse }) => ($reverse ? "15%" : 0)};
    padding-left: ${({ $reverse }) => ($reverse ? 0 : "25%")};
  }
  @media (${device.xl}) {
    padding-right: ${({ $reverse }) => ($reverse ? "30%" : 0)};
    padding-left: ${({ $reverse }) => ($reverse ? 0 : "30%")};
  }
`;

export function Card({
  title,
  subtitle,
  img,
  color = "redishOrange300",
  reverse = false,
  ...rest
}) {
  return (
    <CtaWrap
      variant="gradient"
      rounded="md"
      color={color}
      pattern="labyrinth"
      patternSize="50%"
      {...rest}
    >
      <Img src={img} alt="" $reverse={reverse} />
      <TextWrap $reverse={reverse}>
        <Typography
          tag={"h3"}
          variant={"textXl"}
          md={{
            variant: "displaySm",
          }}
          textshadow="soft"
          className="d-flex flex-column justify-content-center"
        >
          {title}
        </Typography>
        <Typography
          tag={"p"}
          variant={"textSm"}
          weight={600}
          md={{
            variant: "textMd",
          }}
          textshadow="soft"
        >
          {subtitle}
        </Typography>
      </TextWrap>
    </CtaWrap>
  );
}
