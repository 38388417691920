import { useEffect } from "react";

import { NetworkConfiguration } from "../Network";
import { POLYGON_NETWORK } from "./WalletHelper";
import { createThirdwebClient, defineChain } from "thirdweb";
import { createWallet, inAppWallet, walletConnect } from "thirdweb/wallets";
import { ethers5Adapter } from "thirdweb/adapters/ethers5";
import {
  useActiveAccount,
  useActiveWalletChain,
  useAutoConnect,
  useSwitchActiveWalletChain,
} from "thirdweb/react";
import { useSetAtom } from "jotai";
import {
  currentWalletAddressAtom,
  signerAtom,
  walletConnectedAtom,
} from "../../store";
import { useLocation } from "react-router-dom";
export function WalletProvider() {
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];

  const location = useLocation();
  const address = useActiveAccount();
  const switchChain = useSwitchActiveWalletChain();
  let chain = useActiveWalletChain();
  const maticChain = defineChain({
    id: netconfig.networkId,
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
  });

  const arbChain = defineChain({
    id: netconfig.networkIdArbitrum,
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    // rpc: "https://arb1.arbitrum.io/rpc	",
  });

  const xaiChain = defineChain({
    id: netconfig.networkIdXai,
    nativeCurrency: {
      name: "Xai",
      symbol: "XAI",
      decimals: 18,
    },
  });

  const amoyChain = defineChain({
    id: 80002,
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
    rpc: "https://rpc-amoy.polygon.technology",
  });

  const setWalletConnected = useSetAtom(walletConnectedAtom);
  const setCurrentWalletAddress = useSetAtom(currentWalletAddressAtom);
  const setSignerAtom = useSetAtom(signerAtom);
  const client = createThirdwebClient({
    clientId: netconfig.clientID,
  });

  const wallets = [
    // inAppWallet(),
    createWallet("io.metamask"),
    createWallet("com.coinbase.wallet"),
    createWallet("io.rabby"),
    walletConnect(),
    inAppWallet({
      auth: {
        options: ["email", "google", "facebook", "apple"],
      },
    }),
  ];
  const { data: autoConnected, isLoading } = useAutoConnect({
    client,
    wallets,
  });

  // useEffect(() => {
  //   console.log("data:", autoConnected, isLoading);
  // }, [autoConnected, isLoading]);

  async function getSigner() {
    if (!address) return null;

    const ethersSigner = await ethers5Adapter.signer.toEthers({
      client,
      chain,
      account: address,
    });

    return ethersSigner;
  }

  useEffect(() => {
    if (!address || !location) return;
    if (location.pathname.includes("staking")) {
      switchChain(arbChain);
    }
    if (location.pathname.includes("bridge")) {
      switchChain(maticChain);
    }
  }, [address?.address, location]);

  useEffect(() => {
    if (address) {
      setCurrentWalletAddress(address.address);
      setWalletConnected(true);
    } else {
      setWalletConnected(false);
      setCurrentWalletAddress("");
    }
  }, [address]);

  useEffect(() => {
    if (!chain || !client) return;
    if (!address) {
      setSignerAtom(null);
    } else {
      let signer = getSigner();
      setSignerAtom(signer);
    }
  }, [address, chain]);

  // return children
}
