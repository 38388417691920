import { useWeb3Mutation, useWeb3QueryWrongChain } from "./utils/useWeb3Query";
import { NetworkConfiguration } from "../components/Network";
import CU_Hatchery_ABI from "../CU_MinionHatchery_ABI.json";
import CU_SatelliteBank_ABI from "../CU_SatelliteBank_ABI.json";
import { ethers } from "ethers";
import { queryClient } from ".";
import { useAtomValue } from "jotai";
import { currentWalletAddressAtom } from "../store";

export const GET_CLAIMS_QUERY_KEY = "GET_CLAIMS_QUERY_KEY";
export const MUTATE_CLAIM_REWARDS = "MUTATE_CLAIM_REWARDS";
export const GET_SC_CLAIMS_QUERY_KEY = "GET_SC_CLAIMS_QUERY_KEY";
export const MUTATE_SC_REWARDS = "MUTATE_SC_REWARDS";
export const GET_CLAIMS_ARB_QUERY_KEY = "GET_CLAIMS_ARB_QUERY_KEY";
export const MUTATE_CLAIM_REWARDS_ARB = "MUTATE_CLAIM_REWARDS_ARB";

const parseAddress = (address) => {
  let str = address.slice(0, 2);
  if (str === "0x") {
    return address;
  } else {
    return "0x" + address;
  }
};

const setJsonV2 = (json, e, contract) => {
  json.block_deadline = e.parameters.blockDeadline;
  json.amount = e.parameters.amount;
  json.claimed = e.claimed;
  json.display_amount = e.parameters.amount;
  json.contract = contract;
  json.claimInput = [
    e.parameters.dropId,
    e.request_id,
    e.parameters.blockDeadline,
    e.parameters.amount,
    parseAddress(e.parameters.signer),
    parseAddress(e.parameters.signature),
  ];

  return json;
};

const getMetaData = async (drop, contract, walletAddr) => {
  let getUrl = await contract.dropUri(drop.parameters.dropId);
  let metaData = await fetch(getUrl)
    .then((res) => res.json())
    .then((json) => {
      setJsonV2(json, drop, contract);
      return json;
    })
    .catch(console.error);

  return metaData;
};

const abi = [
  {
    type: "function",
    name: "claimStatus",
    // constant: true,
    inputs: [
      {
        name: "dropId",
        type: "uint256",
        baseType: "uint256",
        //  _isParamType: true,
      },
      {
        name: "requestId",
        type: "uint256",
        baseType: "uint256",
        // _isParamType: true,
      },
    ],
    outputs: [
      {
        name: null,
        type: "bool",
        baseType: "bool",
        // _isParamType: true,
      },
    ],
    payable: false,
    stateMutability: "view",
    // _isFragment: true,
  },
  {
    type: "function",
    name: "dropUri",
    // constant: true,
    inputs: [
      {
        name: "dropId",
        type: "uint256",
        baseType: "uint256",
        //  _isParamType: true,
      },
    ],
    outputs: [
      {
        name: null,
        type: "string",
        baseType: "string",
        // _isParamType: true,
      },
    ],
    payable: false,
    stateMutability: "view",
    //_isFragment: true,
  },
  {
    type: "function",
    name: "claim",
    // constant: false,
    inputs: [
      {
        name: "dropId",
        type: "uint256",
        baseType: "uint256",
        // _isParamType: true,
      },
      {
        name: "requestID",
        type: "uint256",
        baseType: "uint256",
        // _isParamType: true,
      },
      {
        name: "blockDeadline",
        type: "uint256",
        baseType: "uint256",
        // _isParamType: true,
      },
      {
        name: "amount",
        type: "uint256",
        baseType: "uint256",
        // _isParamType: true,
      },
      {
        name: "signer",
        type: "address",
        baseType: "address",
        // _isParamType: true,
      },
      {
        name: "signature",
        type: "bytes",
        baseType: "bytes",
        // _isParamType: true,
      },
    ],
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    // _isFragment: true,
  },
  {
    inputs: [
      { internalType: "uint256[]", name: "dropIDList", type: "uint256[]" },
      { internalType: "uint256[]", name: "requestIDList", type: "uint256[]" },
      {
        internalType: "uint256[]",
        name: "blockDeadlineList",
        type: "uint256[]",
      },
      { internalType: "uint256[]", name: "amountList", type: "uint256[]" },
      { internalType: "address[]", name: "signerList", type: "address[]" },
      { internalType: "bytes[]", name: "signatureList", type: "bytes[]" },
    ],
    name: "batchClaim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

// export const useGetClaims = () => {
//   return useWeb3QueryWrongChain({
//     queryKey: [GET_CLAIMS_QUERY_KEY],
//     queryFn: async ({ address }) => {
//       const netconfig =
//         NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];

//       const wallet = new ethers.providers.JsonRpcProvider(netconfig.XaiRPC);

//       let blockNum = await wallet.getBlockNumber();
//       const claimData = [
//         // {
//         //   contractAddr: netconfig.CUDropperV1Address,
//         //   abi: CU_Dropper_ABI,
//         //   json: setJsonV1,
//         //   api: `${netconfig.DROPPER_BASE_URL}/drops/batch?blockchain=${netconfig.DROPPER_BLOCKCHAIN_NAME}&address=${wallet?.accounts[0].address}&limit=100&current_block_number=${blockNum}`
//         // },
//         // {
//         //   contractAddr: netconfig.SatelliteBankAddress,
//         //   abi: CU_SatelliteBank_ABI,
//         //   json: setJsonV2,
//         //   api: `${netconfig.DROPPER_BASE_URL}/metatx/requests?contract_id=${netconfig.DROPPER_CONTRACT_ID_V2_2P}&caller=${address}`
//         // },
//         {
//           contractAddr: netconfig.CUDropperV2Address,
//           abi: CU_SatelliteBank_ABI,
//           json: setJsonV2,
//           api: `${netconfig.DROPPER_BASE_URL}/metatx/requests?contract_id=${netconfig.DROPPER_CONTRACT_ID_V2_LEADERBOARD}&caller=${address}`,
//         },
//       ];

//       let dropperArr = [];
//       let dropperArrSC = [];
//       let claimedArr = [];

//       for (let i = 0; i < claimData.length; i++) {
//         let contract = new ethers.Contract(
//           claimData[i].contractAddr,
//           abi,
//           wallet
//         );
//         let drops = await fetch(claimData[i].api)
//           .then((res) => res.json())
//           .then((json) => json);
//         // await drops.map(async (drop) => {
//         for (let i = 0; i < drops.length; i++) {
//           let claimStatus;
//           let drop = drops[i];
//           claimStatus = await contract.claimStatus(
//             drop.parameters.dropId,
//             drop.request_id
//           );

//           drop.claimed = Number(claimStatus);

//           drop.display_amount = drop.parameters.amount;

//           if (drop.display_amount.length >= 18) {
//             drop.display_amount = ethers.utils.formatEther(
//               `${drop.display_amount}`
//             );
//           }
//           let dropId = drop.parameters.dropId;
//           let metaData = await getMetaData(drop, contract, address);
//           drop = metaData;
//           drop.dropId = dropId;
//           let expired = [];
//           if (drop.claimed === 0 || !drop.claimed) {
//             if (blockNum > drop.block_deadline) {
//               expired.push(drop);
//             } else {
//               //seperate out SC drops
//               if (drop.dropId === "6" || drop.dropId === "9") {
//                 dropperArrSC.push(drop);
//               } else {
//                 dropperArr.push(drop);
//               }
//             }
//           }

//           if (drop.claimed === 1 || drop.claimed) {
//             claimedArr.push(drop);
//           }
//         }
//       }
//       //create batch arrays for claiming all drops

//       const batchInput = {
//         dropIDList: [],
//         requestIDList: [],
//         blockDeadlineList: [],
//         amountList: [],
//         signerList: [],
//         signatureList: [],
//       };
//       const batchInputSC = {
//         dropIDList: [],
//         requestIDList: [],
//         blockDeadlineList: [],
//         amountList: [],
//         signerList: [],
//         signatureList: [],
//       };
//       const cuTotal =
//         dropperArrSC.reduce((acc, drop) => {
//           if (drop.dropId === "6") {
//             return acc.add(ethers.BigNumber.from(drop.amount));
//           } else {
//             return acc;
//           }
//         }, ethers.BigNumber.from(0)) || 0;

//       const unimTotal =
//         dropperArrSC.reduce((acc, drop) => {
//           if (drop.dropId === "9") {
//             // return acc + Number(drop.amount);
//             return acc.add(ethers.BigNumber.from(drop.amount));
//           } else {
//             return acc;
//           }
//         }, ethers.BigNumber.from(0)) || 0;

//       dropperArrSC.map((drop) => {
//         batchInputSC.dropIDList.push(drop.claimInput[0]);
//         batchInputSC.requestIDList.push(drop.claimInput[1]);
//         batchInputSC.blockDeadlineList.push(drop.claimInput[2]);
//         batchInputSC.amountList.push(drop.claimInput[3]);
//         batchInputSC.signerList.push(parseAddress(drop.claimInput[4]));
//         batchInputSC.signatureList.push(parseAddress(drop.claimInput[5]));
//       });
//       dropperArr.map((drop) => {
//         batchInput.dropIDList.push(drop.claimInput[0]);
//         batchInput.requestIDList.push(drop.claimInput[1]);
//         batchInput.blockDeadlineList.push(drop.claimInput[2]);
//         batchInput.amountList.push(drop.claimInput[3]);
//         batchInput.signerList.push(parseAddress(drop.claimInput[4]));
//         batchInput.signatureList.push(parseAddress(drop.claimInput[5]));
//       });
//       return {
//         dropper: {
//           dropperBalance: dropperArr.length,
//           dropper: dropperArr,
//           batchClaim: batchInput,
//           dropperSC: dropperArrSC,
//           batchClaimSC: batchInputSC,
//           cuTotal,
//           unimTotal,
//         },
//         claimed: {
//           claimedBalance: claimedArr.length,
//           claimed: claimedArr,
//         },
//       };
//     },
//   });
// };

export const useGetClaims = () => {
  return useWeb3QueryWrongChain({
    queryKey: [GET_CLAIMS_QUERY_KEY],
    queryFn: async ({ address }) => {
      const startTime = Date.now();
      const netconfig =
        NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];
      const wallet = new ethers.providers.JsonRpcProvider(netconfig.XaiRPC);
      const blockNum = await wallet.getBlockNumber();
      const claimData = [
        {
          contractAddr: netconfig.CUDropperV2Address,
          abi: CU_SatelliteBank_ABI,
          json: setJsonV2,
          api: `${netconfig.DROPPER_BASE_URL}/metatx/requests?contract_id=${netconfig.DROPPER_CONTRACT_ID_V2_LEADERBOARD}&caller=${address}`,
        },
      ];

      let dropperArr = [];
      let dropperArrSC = [];
      let claimedArr = [];

      const processDrop = async (drop, contract) => {
        let claimStatus = await contract.claimStatus(
          drop.parameters.dropId,
          drop.request_id
        );
        drop.claimed = Number(claimStatus);
        drop.display_amount = drop.parameters.amount;

        if (drop.display_amount.length >= 18) {
          drop.display_amount = ethers.utils.formatEther(
            `${drop.display_amount}`
          );
        }

        let metaData = await getMetaData(drop, contract, address);
        // drop = metaData;
        metaData.dropId = drop.parameters.dropId;

        if (metaData.claimed === 0 || !metaData.claimed) {
          if (blockNum > metaData.block_deadline) {
            // expired drops can be handled if needed
          } else {
            if (["6", "9"].includes(metaData.dropId)) {
              dropperArrSC.push(metaData);
            } else {
              dropperArr.push(metaData);
            }
          }
        } else {
          claimedArr.push(metaData);
        }
      };

      for (const claim of claimData) {
        const contract = new ethers.Contract(claim.contractAddr, abi, wallet);
        const drops = await fetch(claim.api).then((res) => res.json());

        if (drops.length > 0) {
          await Promise.all(drops.map((drop) => processDrop(drop, contract)));
        }
      }

      const batchInput = {
        dropIDList: [],
        requestIDList: [],
        blockDeadlineList: [],
        amountList: [],
        signerList: [],
        signatureList: [],
      };

      const batchInputSC = {
        dropIDList: [],
        requestIDList: [],
        blockDeadlineList: [],
        amountList: [],
        signerList: [],
        signatureList: [],
      };

      const cuTotal = dropperArrSC.reduce((acc, drop) => {
        if (drop?.dropId === "6") {
          return acc.add(ethers.BigNumber.from(drop.amount));
        }
        return acc;
      }, ethers.BigNumber.from(0));

      const unimTotal = dropperArrSC.reduce((acc, drop) => {
        if (drop?.dropId === "9") {
          return acc.add(ethers.BigNumber.from(drop.amount));
        }
        return acc;
      }, ethers.BigNumber.from(0));

      dropperArrSC.forEach((drop) => {
        batchInputSC.dropIDList.push(drop.claimInput[0]);
        batchInputSC.requestIDList.push(drop.claimInput[1]);
        batchInputSC.blockDeadlineList.push(drop.claimInput[2]);
        batchInputSC.amountList.push(drop.claimInput[3]);
        batchInputSC.signerList.push(parseAddress(drop.claimInput[4]));
        batchInputSC.signatureList.push(parseAddress(drop.claimInput[5]));
      });

      dropperArr.forEach((drop) => {
        batchInput.dropIDList.push(drop.claimInput[0]);
        batchInput.requestIDList.push(drop.claimInput[1]);
        batchInput.blockDeadlineList.push(drop.claimInput[2]);
        batchInput.amountList.push(drop.claimInput[3]);
        batchInput.signerList.push(parseAddress(drop.claimInput[4]));
        batchInput.signatureList.push(parseAddress(drop.claimInput[5]));
      });
      const endTime = Date.now();
      console.log("Claims XAI fetch time: ", endTime - startTime, "ms");

      return {
        dropper: {
          dropperBalance: dropperArr.length,
          dropper: dropperArr,
          batchClaim: batchInput,
          dropperSC: dropperArrSC,
          batchClaimSC: batchInputSC,
          cuTotal,
          unimTotal,
        },
        claimed: {
          claimedBalance: claimedArr.length,
          claimed: claimedArr,
        },
      };
    },
  });
};

export const useGetClaimsArb = () => {
  return useWeb3QueryWrongChain({
    queryKey: [GET_CLAIMS_ARB_QUERY_KEY],
    queryFn: async ({ address }) => {
      const startTime = Date.now();
      const netconfig =
        NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];
      const wallet = new ethers.providers.JsonRpcProvider(
        netconfig.ArbitrumRPC
      );

      const blockNum = await wallet.getBlockNumber();
      const claimData = [
        // {
        //   contractAddr: netconfig.CUDropperV1Address,
        //   abi: CU_Dropper_ABI,
        //   json: setJsonV1,
        //   api: `${netconfig.DROPPER_BASE_URL}/drops/batch?blockchain=${netconfig.DROPPER_BLOCKCHAIN_NAME}&address=${wallet?.accounts[0].address}&limit=100&current_block_number=${blockNum}`
        // },
        // {
        //   contractAddr: netconfig.SatelliteBankAddress,
        //   abi: CU_SatelliteBank_ABI,
        //   json: setJsonV2,
        //   api: `${netconfig.DROPPER_BASE_URL}/metatx/requests?contract_id=${netconfig.DROPPER_CONTRACT_ID_V2_2P}&caller=${address}`
        // },
        {
          contractAddr: netconfig.Arbitrum.DropperV2Address,
          abi: CU_SatelliteBank_ABI,
          json: setJsonV2,
          api: `${netconfig.DROPPER_BASE_URL}/metatx/requests?contract_id=${netconfig.Arbitrum.DropperID}&caller=${address}`,
        },
      ];

      let p2aArr = [];
      let eventsArr = [];

      const processDrop = async (drop, contract) => {
        let claimStatus = await contract.claimStatus(
          drop.parameters.dropId,
          drop.request_id
        );
        drop.claimed = Number(claimStatus);
        drop.display_amount = drop.parameters.amount;

        if (drop.display_amount.length >= 18) {
          drop.display_amount = ethers.utils.formatEther(
            `${drop.display_amount}`
          );
        }

        let metaData = await getMetaData(drop, contract, address);
        //drop = metaData;
        metaData.dropId = drop.parameters.dropId;

        if (metaData.claimed === 0 || !metaData.claimed) {
          if (blockNum <= metaData.block_deadline) {
            if (["1", "2", "3", "4"].includes(metaData.dropId)) {
              p2aArr.push(metaData);
            } else {
              eventsArr.push(metaData);
            }
          }
        }
      };

      for (const claim of claimData) {
        const contract = new ethers.Contract(claim.contractAddr, abi, wallet);
        const result = await fetch(claim.api);
        const drops = await result.json();

        if (drops.length > 0) {
          await Promise.all(drops.map((drop) => processDrop(drop, contract)));
        }
      }

      const batchInputP2a = {
        dropIDList: [],
        requestIDList: [],
        blockDeadlineList: [],
        amountList: [],
        signerList: [],
        signatureList: [],
      };

      const batchInputEvents = {
        dropIDList: [],
        requestIDList: [],
        blockDeadlineList: [],
        amountList: [],
        signerList: [],
        signatureList: [],
      };

      const calculateTotal = (arr, id) =>
        arr
          .reduce((acc, drop) => {
            if (drop?.dropId === id) {
              return acc.add(ethers.BigNumber.from(drop.amount));
            }
            return acc;
          }, ethers.BigNumber.from(0))
          .toString() || 0;

      const joustingTotal = calculateTotal(p2aArr, "1");
      const questingTotal = calculateTotal(p2aArr, "2");
      const dailyTotal = calculateTotal(p2aArr, "3");
      const shadowcornTotal = calculateTotal(p2aArr, "4");

      eventsArr.forEach((drop) => {
        batchInputEvents.dropIDList.push(drop.claimInput[0]);
        batchInputEvents.requestIDList.push(drop.claimInput[1]);
        batchInputEvents.blockDeadlineList.push(drop.claimInput[2]);
        batchInputEvents.amountList.push(drop.claimInput[3]);
        batchInputEvents.signerList.push(parseAddress(drop.claimInput[4]));
        batchInputEvents.signatureList.push(parseAddress(drop.claimInput[5]));
      });

      p2aArr.forEach((drop) => {
        batchInputP2a.dropIDList.push(drop.claimInput[0]);
        batchInputP2a.requestIDList.push(drop.claimInput[1]);
        batchInputP2a.blockDeadlineList.push(drop.claimInput[2]);
        batchInputP2a.amountList.push(drop.claimInput[3]);
        batchInputP2a.signerList.push(parseAddress(drop.claimInput[4]));
        batchInputP2a.signatureList.push(parseAddress(drop.claimInput[5]));
      });
      const endTime = Date.now();
      console.log("Claims Arb fetch time: ", endTime - startTime, "ms");
      return {
        eventsArr,
        p2aArr,
        batchInputEvents,
        batchInputP2a,
        joustingTotal: ethers.utils.formatEther(joustingTotal),
        questingTotal: ethers.utils.formatEther(questingTotal),
        dailyTotal: ethers.utils.formatEther(dailyTotal),
        shadowcornTotal: ethers.utils.formatEther(shadowcornTotal),
      };
    },
  });
};

// export const useGetClaimsArb = () => {
//   return useWeb3QueryWrongChain({
//     queryKey: [GET_CLAIMS_ARB_QUERY_KEY],
//     queryFn: async ({ address }) => {
//       const netconfig =
//         NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];

//       const wallet = new ethers.providers.JsonRpcProvider(
//         netconfig.ArbitrumRPC
//       );

//       let blockNum = await wallet.getBlockNumber();
//       const claimData = [
//         // {
//         //   contractAddr: netconfig.CUDropperV1Address,
//         //   abi: CU_Dropper_ABI,
//         //   json: setJsonV1,
//         //   api: `${netconfig.DROPPER_BASE_URL}/drops/batch?blockchain=${netconfig.DROPPER_BLOCKCHAIN_NAME}&address=${wallet?.accounts[0].address}&limit=100&current_block_number=${blockNum}`
//         // },
//         // {
//         //   contractAddr: netconfig.SatelliteBankAddress,
//         //   abi: CU_SatelliteBank_ABI,
//         //   json: setJsonV2,
//         //   api: `${netconfig.DROPPER_BASE_URL}/metatx/requests?contract_id=${netconfig.DROPPER_CONTRACT_ID_V2_2P}&caller=${address}`
//         // },
//         {
//           contractAddr: netconfig.Arbitrum.DropperV2Address,
//           abi: CU_SatelliteBank_ABI,
//           json: setJsonV2,
//           api: `${netconfig.DROPPER_BASE_URL}/metatx/requests?contract_id=${netconfig.Arbitrum.DropperID}&caller=${address}`,
//         },
//       ];

//       let p2aArr = [];
//       let eventsArr = [];
//       // let claimedArr = [];

//       for (let i = 0; i < claimData.length; i++) {
//         let contract = new ethers.Contract(
//           claimData[i].contractAddr,
//           abi,
//           wallet
//         );
//         let drops = await fetch(claimData[i].api)
//           .then((res) => res.json())
//           .then((json) => json);

//         // await drops.map(async (drop) => {
//         for (let i = 0; i < drops.length; i++) {
//           let claimStatus;
//           let drop = drops[i];
//           claimStatus = await contract.claimStatus(
//             drop.parameters.dropId,
//             drop.request_id
//           );
//           drop.claimed = Number(claimStatus);

//           drop.display_amount = drop.parameters.amount;

//           if (drop.display_amount.length >= 18) {
//             drop.display_amount = ethers.utils.formatEther(
//               `${drop.display_amount}`
//             );
//           }
//           let dropId = drop.parameters.dropId;
//           let metaData = await getMetaData(drop, contract, address);
//           drop = metaData;
//           drop.dropId = dropId;
//           if (drop.claimed === 0 || !drop.claimed) {
//             if (blockNum > drop.block_deadline) {
//             } else {
//               //seperate out SC drops
//               if (
//                 drop.dropId === "1" ||
//                 drop.dropId === "2" ||
//                 drop.dropId === "3" ||
//                 drop.dropId === "4"
//               ) {
//                 p2aArr.push(drop);
//               } else {
//                 eventsArr.push(drop);
//               }
//             }
//           }
//         }
//       }
//       //create batch arrays for claiming all drops

//       const batchInputP2a = {
//         dropIDList: [],
//         requestIDList: [],
//         blockDeadlineList: [],
//         amountList: [],
//         signerList: [],
//         signatureList: [],
//       };
//       const batchInputEvents = {
//         dropIDList: [],
//         requestIDList: [],
//         blockDeadlineList: [],
//         amountList: [],
//         signerList: [],
//         signatureList: [],
//       };
//       const joustingTotal =
//         p2aArr
//           .reduce((acc, drop) => {
//             if (drop.dropId === "1") {
//               return acc.add(ethers.BigNumber.from(drop.amount));
//             } else {
//               return acc;
//             }
//           }, ethers.BigNumber.from(0))
//           .toString() || 0;
//       const questingTotal =
//         p2aArr
//           .reduce((acc, drop) => {
//             if (drop.dropId === "2") {
//               return acc.add(ethers.BigNumber.from(drop.amount));
//             } else {
//               return acc;
//             }
//           }, ethers.BigNumber.from(0))
//           .toString() || 0;
//       const dailyTotal =
//         p2aArr
//           .reduce((acc, drop) => {
//             if (drop.dropId === "3") {
//               return acc.add(ethers.BigNumber.from(drop.amount));
//             } else {
//               return acc;
//             }
//           }, ethers.BigNumber.from(0))
//           .toString() || 0;
//       const shadowcornTotal =
//         p2aArr
//           .reduce((acc, drop) => {
//             if (drop.dropId === "4") {
//               return acc.add(ethers.BigNumber.from(drop.amount));
//             } else {
//               return acc;
//             }
//           }, ethers.BigNumber.from(0))
//           .toString() || 0;

//       eventsArr.map((drop) => {
//         batchInputEvents.dropIDList.push(drop.claimInput[0]);
//         batchInputEvents.requestIDList.push(drop.claimInput[1]);
//         batchInputEvents.blockDeadlineList.push(drop.claimInput[2]);
//         batchInputEvents.amountList.push(drop.claimInput[3]);
//         batchInputEvents.signerList.push(parseAddress(drop.claimInput[4]));
//         batchInputEvents.signatureList.push(parseAddress(drop.claimInput[5]));
//       });
//       p2aArr.map((drop, index) => {
//         // if (index === 1) {
//         batchInputP2a.dropIDList.push(drop.claimInput[0]);
//         batchInputP2a.requestIDList.push(drop.claimInput[1]);
//         batchInputP2a.blockDeadlineList.push(drop.claimInput[2]);
//         batchInputP2a.amountList.push(drop.claimInput[3]);
//         batchInputP2a.signerList.push(parseAddress(drop.claimInput[4]));
//         batchInputP2a.signatureList.push(parseAddress(drop.claimInput[5]));
//         // }
//       });
//       return {
//         eventsArr, // claims to display in the events card
//         p2aArr,
//         batchInputEvents, // input for the events claim mutation
//         batchInputP2a, // input for the p2a claim mutation
//         joustingTotal: ethers.utils.formatEther(joustingTotal), //xai total to display
//         questingTotal: ethers.utils.formatEther(questingTotal), //xai total to display
//         dailyTotal: ethers.utils.formatEther(dailyTotal), //xai total to display
//         shadowcornTotal: ethers.utils.formatEther(shadowcornTotal), //xai total to display
//       };
//     },
//   });
// };

export const useClaimRewardsArb = (payload) => {
  const walletAddress = useAtomValue(currentWalletAddressAtom);

  return useWeb3Mutation({
    mutationKey: [MUTATE_CLAIM_REWARDS_ARB],
    mutationFn: async ({ wallet, address }) => {
      const netconfig =
        NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];
      const contract = new ethers.Contract(
        netconfig.Arbitrum.DropperV2Address,
        abi,
        wallet
      );

      const gasLimit = await contract.estimateGas.batchClaim(
        payload.dropIDList,
        payload.requestIDList,
        payload.blockDeadlineList,
        payload.amountList,
        payload.signerList,
        payload.signatureList
      );

      const tx = await contract.batchClaim(
        payload.dropIDList,
        payload.requestIDList,
        payload.blockDeadlineList,
        payload.amountList,
        payload.signerList,
        payload.signatureList,
        {
          gasLimit: gasLimit,
        }
      );

      await tx.wait();
      if (tx.status === 0) {
        throw new Error("Transaction failed");
      } else {
        return tx;
      }
    },
    onSuccess: async () => {
      console.log("Success");

      queryClient.invalidateQueries({
        queryKey: [GET_CLAIMS_ARB_QUERY_KEY, walletAddress],
      });
    },
  });
};

export const useClaimRewards = (payload) => {
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  return useWeb3Mutation({
    mutationKey: [MUTATE_CLAIM_REWARDS],
    mutationFn: async ({ wallet, address }) => {
      const netconfig =
        NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];
      const contract = new ethers.Contract(
        netconfig.CUDropperV2Address,
        abi,
        wallet
      );

      const gasLimit = await contract.estimateGas.batchClaim(
        payload.dropIDList,
        payload.requestIDList,
        payload.blockDeadlineList,
        payload.amountList,
        payload.signerList,
        payload.signatureList
      );

      const tx = await contract.batchClaim(
        payload.dropIDList,
        payload.requestIDList,
        payload.blockDeadlineList,
        payload.amountList,
        payload.signerList,
        payload.signatureList,
        {
          gasLimit: gasLimit,
        }
      );

      await tx.wait();
      if (tx.status === 0) {
        throw new Error("Transaction failed");
      } else {
        return tx;
      }
    },
    onSuccess: async () => {
      console.log("Success");

      queryClient.invalidateQueries({
        queryKey: [GET_CLAIMS_QUERY_KEY, walletAddress],
      });
    },
  });
};

export const useGetSCClaims = () => {
  return useWeb3QueryWrongChain({
    queryKey: [GET_SC_CLAIMS_QUERY_KEY],
    queryFn: async ({ address }) => {
      const netconfig =
        NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];

      const wallet = new ethers.providers.JsonRpcProvider(netconfig.XaiRPC);
      const contract = new ethers.Contract(
        netconfig.hatcheryAddress,
        CU_Hatchery_ABI,
        wallet
      );

      const playerRewards = await contract.getPlayerRewards(address);

      //console.log("playerRewards: ", playerRewards);

      return playerRewards;
    },
  });
};

export const useClaimSCRewards = () => {
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  return useWeb3Mutation({
    mutationKey: [MUTATE_SC_REWARDS],
    mutationFn: async ({ wallet }) => {
      const netconfig =
        NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];
      const contract = new ethers.Contract(
        netconfig.hatcheryAddress,
        CU_Hatchery_ABI,
        wallet
      );

      const tx = await contract.claimRewards();

      await tx.wait();
      if (tx.status === 0) {
        throw new Error("Transaction failed");
      } else {
        return tx;
      }
    },
    onSuccess: async () => {
      console.log("Success");

      queryClient.invalidateQueries({
        queryKey: [GET_SC_CLAIMS_QUERY_KEY, walletAddress],
      });
    },
  });
};
