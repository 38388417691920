import React, { useState } from "react";
import logo from "../../img/logo.png";
import laguna from "../../img/logos/laguna.png";
import { NavActions } from "./NavActions";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container } from "../base/Container";
import { NavItem } from "./NavItem";
import { NavGamesMenu } from "./NavGamesMenu";
import { NavGameHubMenu } from "./NavGameHubMenu";
import { NavCommunityMenu } from "./NavCommunityMenu";
import { NavHelpMenu } from "./NavHelpMenu";
import { Icon } from "../base/Icon";
import { device } from "../../theme/mediaQuery";
import { BridgePendingTransactionButton } from "../bridge/BridgePendingTransactionButton";
import { NavMPMenu } from "./NavMPMenu";

const HeaderWrap = styled.header`
  padding: 45px 0;
`;

const NavbarWrap = styled.nav`
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  z-index: 10;
  flex-wrap: wrap;
  padding: 0 12px;
  @media (${device.xxl}) {
    gap: 75px;
    flex-wrap: nowrap;
    padding: 0;
  }
`;

const NavbarBrandWrap = styled(Link)`
  width: 80px;

  @media (${device.md}) {
    width: 160px;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-basis: 100%;
  max-height: ${({ $active }) => ($active ? "2000px" : "0")};
  opacity: ${({ $active }) => ($active ? "1" : "0")};
  transition: all 0.3s ease-in-out;
  overflow: visible;
  visibility: ${({ $active }) => ($active ? "visible" : "hidden")};

  @media (${device.xxl}) {
    flex-direction: row;
    gap: 5px;
    flex-basis: auto;
    max-height: initial;
    overflow: initial;
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 134px;
`;

const NavActionsWrap = styled.div`
  @media (${device.xxl}) {
    margin-left: auto;
  }
`;

const NavMobileActionsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  @media (${device.xxl}) {
    display: none;
  }
`;

const NavBurgerButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.white};
`;

export function Header({ scrollToGame, large = false, isLagunaGames = false }) {
  const [activeNav, setActiveNav] = useState(false);
  const toggleNav = () => {
    setActiveNav((prevState) => !prevState);
  };

  return (
    <HeaderWrap>
      <Container large={large}>
        <NavbarWrap>
          <NavbarBrandWrap to="/">
            {isLagunaGames ? (
              <Logo className="logo" src={laguna} alt="Laguna Games" />
            ) : (
              <Logo className="logo" src={logo} alt="Crypto Unicorns" />
            )}
          </NavbarBrandWrap>
          <NavMobileActionsWrap>
            <BridgePendingTransactionButton />
            <NavBurgerButton onClick={toggleNav}>
              <Icon name="burger" size="34px" />
            </NavBurgerButton>
          </NavMobileActionsWrap>
          <Nav $active={activeNav}>
            <NavItem
              renderMenu={() => <NavGamesMenu scrollToGame={scrollToGame} />}
              isFullSizeMenu={true}
              title="Our Games"
              appendIcon="arrow-down"
            />
            <NavItem
              renderMenu={() => <NavMPMenu />}
              isFullSizeMenu={true}
              title="Marketplace"
              appendIcon="arrow-down"
            />
            <NavItem
              renderMenu={() => <NavGameHubMenu scrollToGame={scrollToGame} />}
              isFullSizeMenu={true}
              title="Ecosystem"
              appendIcon="arrow-down"
            />
            <NavItem
              renderMenu={() => <NavCommunityMenu />}
              isFullSizeMenu={true}
              title="Community"
              appendIcon="arrow-down"
            />
            <NavItem
              renderMenu={() => <NavHelpMenu />}
              title="Help"
              appendIcon="arrow-down"
            />
            <NavActionsWrap>
              <NavActions />
            </NavActionsWrap>
          </Nav>
        </NavbarWrap>
      </Container>
    </HeaderWrap>
  );
}

export function LogoHeader() {
  return (
    <HeaderWrap>
      <Container>
        <NavbarWrap>
          <NavbarBrandWrap to="/">
            <Logo className="logo" src={logo} alt="Crypto Unicorns" />
          </NavbarBrandWrap>
        </NavbarWrap>
      </Container>
    </HeaderWrap>
  );
}
