import React from "react";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import { Typography } from "../../base/Typography";
import { Link } from "react-router-dom";
import { Icon } from "../../base/Icon";

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: #f3f3ff;
  border-radius: 10px;
  gap: 8px;
  padding: 12px 24px;
  @media (${device.sm}) {
    flex-direction: row;
  }
  @media (${device.md}) {
    gap: 11px 40px;
    padding: 30px;
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  @media (${device.sm}) {
    flex-basis: calc(50% - 6px);
  }
  @media (${device.md}) {
    flex-basis: calc(50% - 20px);
  }
`;

const IconWrap = styled.img`
  margin-right: 6px;
  width: 16px;
  height: 16px;
  @media (${device.md}) {
    width: 24px;
    height: 24px;
  }
`;

const IncreaseButton = styled.button`
  background-color: rgba(0, 158, 246, 0.1);
  color: #009ef6;
  padding: 0 6px 0 10px;
  border: 1px solid #009ef6;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: ${({ $space }) => ($space ? "none" : "flex")};
  
  @media (${device.sm}) {
    display: flex;
    opacity: ${({ $space }) => ($space ? 0 : 1)};
  }

  &:hover {
    background-color: #009ef6;
    color: ${({ theme }) => theme.palette.white};

    svg {
      fill: ${({ theme }) => theme.palette.white};
    }
  }

  svg {
    margin-left: 4px;
    fill: #009ef6;
  }
`;

export const InfoCard = ({ data, ...rest }) => {
  return (
    <CardWrapper {...rest}>
      {data.map((item, index) => (
        <Section key={index}>
          <Typography
            tag="span"
            color="#252525"
            variant="text2xs"
            md={{ variant: "textSm" }}
          >
            {item.label}
          </Typography>
          <Typography
            tag="span"
            color="#5338C2"
            variant="text2xs"
            md={{ variant: "textSm" }}
            family="display"
            className="ms-auto"
          >
            {item.img && <IconWrap src={item.img} alt={`${item.label} logo`} />}
            {item.value}
          </Typography>
          {(item.button || item.buttonSpace) && (
            <IncreaseButton as={Link} to="/staking/badges" $space={item?.buttonSpace}>
              <Typography
                tag="span"
                color="inherit"
                variant="text2xs"
                md={{ variant: "textSm" }}
                family="display"
              >
                Increase
              </Typography>
              <Icon name="full-arrow-right" color="inherit" size="20" />
            </IncreaseButton>
          )}
        </Section>
      ))}
    </CardWrapper>
  );
};
