import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "../../components/header";
import styled from "styled-components";

const PurpleGradientWrapper = styled.div`
  background: linear-gradient(180deg, #150079 0%, #5118a4 100%);
`;

// base layout for all pages
export function BaseLayout() {

  return (
    <main>
      <PurpleGradientWrapper>
        <Header />
        <Outlet />
        <Footer />
      </PurpleGradientWrapper>
    </main>
  );
}