import styled from "styled-components";
import React from "react";
import { device } from "../../theme/mediaQuery";
import { TIERS } from "./Tier";
import { Typography } from "../base/Typography";
import pluralize from "pluralize";

const RewardsBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px 24px;
  align-self: stretch;
  justify-content: space-between;
  @media (${device.lg}) {
    gap: 56px 24px;
  }
  @media (${device.xl}) {
    flex-direction: row;
  }
`;
export const RewardsBox = ({ tier }) => {
  const hasCompleted = tier === TIERS.length;
  const currentTier = TIERS[tier];
  const nextTier = hasCompleted ? null : TIERS[tier + 1];
  return (
    <RewardsBoxWrap>
      <RewardsContainer tier={currentTier} current />
      <RewardsContainer tier={nextTier} />
    </RewardsBoxWrap>
  );
};

const RewardsContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 18px 20px 18px;
  gap: 20px;
  border-radius: 12px;
  background: #3947a4;

  @media (${device.md}) {
    padding: 0px 24px 20px 24px;
    flex: 1;
  }
  @media (${device.lg}) {
    padding: 0px 45px 20px 45px;
    flex: 1;
  }
`;

const RewardsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  gap: 20px;
  width: 100%;
`;

const TierTitleWrap = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0px 0px 12px 12px;
  background: ${({ $background, theme }) =>
    theme.gradients[$background] && theme.gradients[$background]};
  padding: 8px 12px;
  gap: 8px;
  @media (${device.md}) {
    padding: 8px 30px;
    gap: 10px;
  }

  & p {
    @media (min-width: 350px) {
      white-space: nowrap;
    }
  }
`;

const TierIcon = styled.img`
  width: 24px;
  height: 24px;

  @media (${device.md}) {
    width: 32px;
    height: 32px;
  }
  @media (${device.lg}) {
    width: 40px;
    height: 40px;
  }
`;

const RewardsContainer = ({ tier, current = false }) => {
  const text = current ? "Your current rewards" : "Next tier rewards";
  if (tier?.name === undefined) {
    return null;
  }
  return (
    <RewardsContainerWrap>
      <TierTitleWrap $background={tier.gradient}>
        <TierIcon src={tier.img} alt={tier.name} />
        <Typography
          tag={"p"}
          variant={"text2xs"}
          sm={{ variant: "textXs" }}
          md={{ variant: "textSm" }}
          xl={{ variant: "textMd" }}
          weight={700}
        >
          {text}
        </Typography>
      </TierTitleWrap>
      <RewardsWrap>
        {tier.rewards.map((reward, i) => (
          <Reward
            key={`${i}-${reward.name}-${reward.count}`}
            img={reward.img}
            name={reward.name}
            count={reward.count}
          />
        ))}
      </RewardsWrap>
    </RewardsContainerWrap>
  );
};

const RewardWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(50% - 20px);
  gap: 8px;
  @media (${device.md}) {
    flex: 1;
  }
`;
const ImgWrap = styled.div`
  position: relative;
`;

const Img = styled.img`
  width: 45px;
  height: 45px;
  @media (${device.lg}) {
    width: 60px;
    height: 60px;
  }
`;
const Count = styled(Typography)`
  background: #b80042;
  border: 1px solid #ff2472;
  position: absolute;
  transform: translateX(50%);
  right: 0px;
  bottom: -6px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 60px;

  line-height: normal;
  min-width: 23px;
  height: 23px;
  @media (${device.lg}) {
    padding: 4px;
  }
`;

const Reward = ({ img, name, count }) => {
  const title = count ? pluralize(name, count) : name;
  return (
    <RewardWrap>
      <ImgWrap>
        <Img src={img} alt={name} />
        {count !== 0 && (
          <Count
            tag={"p"}
            variant={"text2xs"}
            lg={{ variant: "textXs" }}
            weight={700}
            color="white"
          >
            {count}
          </Count>
        )}
      </ImgWrap>
      <Typography
        tag={"p"}
        variant={"textXs"}
        md={{ variant: "textSm" }}
        weight={700}
        className="text-center"
      >
        {title}
      </Typography>
    </RewardWrap>
  );
};
