import { useWeb3Query } from "./utils/useWeb3Query";

import { getRituals } from "../contracts/ritual";
import { RitualModel } from "../models/ritual";

export const BASE_STALE_TIME = 1000 * 60 * 60 * 1; // 24 hours

export const GET_RITUALS_QUERY_KEY = "rituals";

export const useGetRituals = () => {
  return useWeb3Query({
    queryKey: [GET_RITUALS_QUERY_KEY],
    queryFn: getRituals,
    staleTime: BASE_STALE_TIME,
    select: (data) =>
      data?.map((ritual) => RitualModel.fromBlockchainObject(ritual)),
  });
};
