import styled from "styled-components";
import React from "react";
import { device } from "../../theme/mediaQuery";
import { Typography } from "../base/Typography";
import { Container } from "../base/Container";
import arb from "../../img/logos/arbitrum.png";
import xai from "../../img/logos/xai.png";

const SectionWrap = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  @media (${device.md}) {
    padding: 18px 0;
  }
  @media (${device.lg}) {
    padding: 21px 0;
    gap: 8px;
  }
  @media (${device.xl}) {
    gap: 10px;
    padding: 26px 0;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 700;
  color: ${({ theme }) => theme.palette.white};

  text-align: center;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  z-index: 1;
  letter-spacing: 0.05em;

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-text-stroke: 5px #8221e3;
    font-size: 1em;
    filter: drop-shadow(0px 4px 0px #3d007a);
    @media (${device.md}) {
      -webkit-text-stroke: 8px #8221e3;
    }
    @media (${device.xl}) {
      -webkit-text-stroke: 15px #8221e3;
    }
  }

  @media (${device.md}) {
    font-size: 2rem;
  }
  @media (${device.xl}) {
    font-size: 3.875rem;
  }
`;

export const Hero = ({ ...rest }) => {
  return (
    <Container>
      <SectionWrap className="pt-0" {...rest}>
        <TitleWrap>
          <Title data-text="Play to airdrop">Play to airdrop</Title>
        </TitleWrap>
        <Typography
          tag={"p"}
          variant={"textSm"}
          md={{ variant: "textMd" }}
          xl={{ variant: "displayXs" }}
          className="text-center"
        >
          Earn XAI Daily and Rank on the Airdrop Leaderboard!
        </Typography>
        <PoweredBy />
      </SectionWrap>
    </Container>
  );
};

const PoweredTitle = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 12px;

  line-height: 150%;
  @media (${device.lg}) {
    gap: 16px;
  }
`;

const LogoWrap = styled.img`
  height: 1.5em; // to match 150% line height
  max-height: 36px;
`;

export const PoweredBy = () => {
  return (
    <PoweredTitle
      tag={"p"}
      variant={"textSm"}
      md={{ variant: "textMd" }}
      xl={{ variant: "displayXs" }}
      className="text-center"
    >
      Powered by
      <LogoWrap src={xai} alt="XAI Logo" />
      and
      <LogoWrap src={arb} alt="Arbitrum Logo" />
    </PoweredTitle>
  );
};
