import { Container } from "../../base/Container";
import styled, { keyframes } from "styled-components";
import { SectionCard } from "../../common/SectionCard";
import { Row } from "../../base/Row";
import { Col } from "../../base/Col";
import { Typography } from "../../base/Typography";
import egg from "../../../img/evolution/egg.png";
import baby from "../../../img/evolution/baby.png";
import adult from "../../../img/evolution/adult.png";
import { HomeEvolutionFooterCard } from "./HomeEvolutionFooterCard";
import { HomeEvolutionStep } from "./HomeEvolutionStep";
import arrow from "../../../img/evolution/arrow.png";
import cloud from "../../../img/evolution/cloud.png";
import rainbow from "../../../img/home/hero-rainbow.png";
import egg_icon from "../../../img/evolution/egg-icon.png";
import cog_icon from "../../../img/evolution/cog-icon.png";
import heart_icon from "../../../img/evolution/heart-icon.png";
import jousting_icon from "../../../img/evolution/jousting-icon.png";
import plant_icon from "../../../img/evolution/plant-icon.png";
import poop_icon from "../../../img/evolution/poop-icon.png";
import { device } from "../../../theme/mediaQuery";
import React from "react";
import { Button } from "../../base/Button";
import { BUY, COMMUNITY } from "../../../consts/externalLinks";

const ADULT_UNICORN_ATTRIBUTES = [
  {
    title: "plant",
    image: plant_icon,
  },
  {
    title: "craft",
    image: cog_icon,
  },
  {
    title: "breed",
    image: heart_icon,
  },
  {
    title: "joust",
    image: jousting_icon,
  },
];

const BABY_UNICORN_ATTRIBUTES = [
  {
    title: "plant",
    image: plant_icon,
  },
  {
    title: "poop",
    image: poop_icon,
  },
];

const HomeEvolutionBackground = styled.div`
  background: linear-gradient(180deg, rgb(146, 205, 249) 0%, #ecf7ff 100%);
  position: relative;
`;

const HomeEvolutionContainer = styled(Container)`
  position: relative;
  z-index: 2;
`;

const keyframeFloat = keyframes`
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-20px);
  }
`;

const HomeEvolutionCloud = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${cloud});
  background-size: contain;
  background-repeat: repeat-x;
  background-position: bottom;
  z-index: 3;
  pointer-events: none;
  animation: ${keyframeFloat} 10s ease-in-out infinite alternate;
`;

const HomeEvolutionRainbow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${rainbow});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
`;

const HomeEvolutionWrap = styled.section`
  padding-top: 50px;
  padding-bottom: 175px;
`;

const HomeEvolutionBody = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;

  @media (${device.lg}) {
    padding-top: 50px;
    padding-bottom: 40px;
  }
`;

const StepCol = styled(Col)`
  position: relative;
  margin-bottom: 20px;

  @media (${device.md}) {
    &:nth-of-type(1) {
      margin-bottom: 0px;
    }
    &:nth-of-type(2) {
      margin-bottom: 35px;
    }
  }

  @media (${device.lg}) {
    &:nth-of-type(1) {
      margin-bottom: 65px;
    }
    &:nth-of-type(2) {
      margin-bottom: 100px;
    }
  }

  @media (${device.xxl}) {
    margin-bottom: 0;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-bottom: 0;
    }
  }
`;

const StepArrow = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 60%) rotate(90deg);
  width: 60px;
  height: 60px;
  background: url(${arrow});
  background-size: contain;
  z-index: 2;

  @media (${device.md}) {
    transform: translate(-50%, 50%) rotate(90deg);
  }

  @media (${device.xxl}) {
    transform: translate(50%, -50%);
    top: 50%;
    right: 0;
    bottom: initial;
    left: initial;
  }
`;

const CircleImageWrap = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  flex-basis: 60px;
  display: block;
  margin: auto;
`;

const AttributeWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  @media (${device.sm}) {
    flex-wrap: no-wrap;
    gap: 10px;
  }
`;

const AttributesContainer = styled.div`
  padding: 20px 0;
  width: 100%;

  @media (${device.xl}) {
    padding: 35px 0;
  }
`;

const EggDescription = styled.div`
  margin: auto;
`;

export function HomeEvolution() {
  return (
    <HomeEvolutionBackground>
      <HomeEvolutionCloud />
      <HomeEvolutionRainbow />
      <HomeEvolutionContainer>
        <HomeEvolutionWrap>
          <SectionCard title="evolution of a great ‘Corn-panion">
            <HomeEvolutionBody>
              <Row>
                <StepCol xs={12} xxl={4}>
                  <StepArrow />
                  <HomeEvolutionStep title="‘Corn egg" image={egg} type="egg">
                    <EggDescription className="my-xl-5">
                      <Row>
                        <Col xs={12} xl={3} className="mb-3 mb-xl-0">
                          <CircleImageWrap src={egg_icon} />
                        </Col>
                        <Col xs={12} xl={9} className="pl-1">
                          <Typography family="display" tag="p" color="white">
                            Hatch a ‘Corn
                          </Typography>
                          <Typography
                            tag="p"
                            color="white"
                            weight={600}
                            variant="textSm"
                          >
                            Hatching a ‘Corn has a chance to get a mythic ‘Corn
                          </Typography>
                        </Col>
                      </Row>
                    </EggDescription>
                  </HomeEvolutionStep>
                </StepCol>
                <StepCol xs={12} xxl={4}>
                  <StepArrow />
                  <HomeEvolutionStep
                    title="BABY ‘CORN"
                    image={baby}
                    type="baby"
                  >
                    <Typography
                      family="display"
                      tag="p"
                      color="white"
                      variant="textXl"
                    >
                      What can they help you with?
                    </Typography>
                    <AttributesContainer>
                      <Row className="justify-content-center">
                        {BABY_UNICORN_ATTRIBUTES.map((attribute) => (
                          <Col xs={6} lg={3} key={attribute.title}>
                            <AttributeWrap>
                              <CircleImageWrap src={attribute.image} />
                              <Typography
                                family="display"
                                tag="p"
                                color="white"
                                variant="textXs"
                              >
                                {attribute.title}
                              </Typography>
                            </AttributeWrap>
                          </Col>
                        ))}
                      </Row>
                    </AttributesContainer>
                  </HomeEvolutionStep>
                </StepCol>
                <StepCol xs={12} xxl={4}>
                  <HomeEvolutionStep
                    title="Adult ‘CORN"
                    image={adult}
                    type="adult"
                  >
                    <Typography
                      family="display"
                      tag="p"
                      color="white"
                      variant="textXl"
                    >
                      What can they help you with?
                    </Typography>
                    <AttributesContainer>
                      <Row>
                        {ADULT_UNICORN_ATTRIBUTES.map((attribute) => (
                          <Col
                            xs={6}
                            md={3}
                            className="mb-3 mb-lg-0"
                            key={attribute.title}
                          >
                            <AttributeWrap>
                              <CircleImageWrap src={attribute.image} />
                              <Typography
                                family="display"
                                tag="p"
                                color="white"
                                variant="textXs"
                              >
                                {attribute.title}
                              </Typography>
                            </AttributeWrap>
                          </Col>
                        ))}
                      </Row>
                    </AttributesContainer>
                    <Typography
                      family="display"
                      tag="p"
                      color="white"
                      variant="textMd"
                    >
                      And many more
                    </Typography>
                  </HomeEvolutionStep>
                </StepCol>
              </Row>
              <Row className="pt-3 pt-lg-5">
                <Col xs={12} xl={6} className="mb-3 mb-xl-0">
                  <MarketplacesCard />
                </Col>
                <Col xs={12} xl={6}>
                  <BreederCard />
                </Col>
              </Row>
            </HomeEvolutionBody>
          </SectionCard>
        </HomeEvolutionWrap>
      </HomeEvolutionContainer>
    </HomeEvolutionBackground>
  );
}

function MarketplacesCard() {
  return (
    <HomeEvolutionFooterCard
      background="linearOrangeLight"
      iconBackground="#E1533F"
      title="adopt your first unicorn"
      icon="unicorn"
      subtitle="Find your perfect pet in our official online marketplace. Each Unicorn is unique, serving as your gateway to a whole world of excitement and adventure."
      footer={
        <a
          href={BUY.MARKETPLACE.UNICORN_LISTINGS}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button block={true} prependIcon="cart">
            buy on MarketPlace
          </Button>
        </a>
      }
    />
  );
}

function BreederCard() {
  return (
    <HomeEvolutionFooterCard
      background="linearMintDark"
      iconBackground="#0CA28E"
      title="Breed Legendary Unicorns"
      icon="stars"
      subtitle="Harness the power of Playcore’s Unicorn Breeding tool to take the guesswork out of breeding top 'Corns. Hint: Genes matter!"
      footer={
        <a
          href={COMMUNITY.BREEDERDAO}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button block={true} prependIcon="breederdao">
            use breederdao’s tool
          </Button>
        </a>
      }
    />
  );
}
