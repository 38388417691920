import { Swiper, SwiperSlide } from "swiper/react";
import React, { Children, useEffect, useState } from "react";
import styled from "styled-components";

const SwiperWrap = styled(Swiper)`
  overflow: visible;
`;

export function GameCarousel({
  active,
  onSlideChange,
  children
}) {
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(active, 600);
    }
  }, [active, swiper]);

  if (!children) return null;

  const arrayChildren = Children.toArray(children);
  const renderChild = Children.map(arrayChildren, (child, index) => (
    <SwiperSlide key={index}>
      {child}
    </SwiperSlide>
  ));

  return (
    <SwiperWrap
      autoHeight={true}
      onSwiper={setSwiper}
      slidesPerView={1}
      spaceBetween={300}
      onSlideChange={(swiper) => onSlideChange(swiper.realIndex)}

    >
      {renderChild}
    </SwiperWrap>
  );
}