import styled from "styled-components";
import comic from "../../img/licensing/comic.png";
import banner from "../../img/licensing/banner.png";
import { device } from "../../theme/mediaQuery";

export const ComicBackground = styled.div`
  position: relative;
  z-index: 1;

  &::after {
    // comic
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-image: url(${comic});
    background-repeat: repeat-x;
    background-position: bottom;
    z-index: -1;
    opacity: 0.1;
    background-size: 197px 676px;
  }
`;

export const BannerBackground = styled.div`
  position: relative;
  z-index: 1;

  &::after {
    // banner
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-image: url(${banner});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    z-index: -1;
    opacity: 1;

    display: none;

    @media (${device.lg}) {
      display: unset;
      left: 0;
      right: 0;
    }
    @media (${device.xxxl}) {
      top: -3%;
    }
    @media (${device["4xl"]}) {
      display: none;
    }
  }
`;
