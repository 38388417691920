import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const VideoStateButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  //bottom: 8px;
  //right: 8px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: rgb(255, 255, 255);
  background-color: rgba(159, 159, 159, 0.4);
  backdrop-filter: blur(10px);
  cursor: pointer;

  ${({ $isPlaying }) => $isPlaying && `opacity: 0.001;`}
  & svg {
    width: 30%;
    fill: white;
  }

  &:hover {
    opacity: 1;
  }
`;

// const LoadingWrap = styled.div`
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
//   & svg {
//     color: rgba(0, 0, 0, 0.3) !important;
//   }
// `;

const PLAYING_DEBOUNCE_TIME = 50;
const WAITING_DEBOUNCE_TIME = 200;

export function CardHeaderVideo(props) {
  const { src } = props;
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [/*isWaiting*/, setIsWaiting] = useState(false);
  //   const [canPlay, setCanPlay] = useState(false);

  const isWaitingTimeout = useRef(null);
  const isPlayingTimeout = useRef(null);

  const handleToggleVideState = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (videoRef?.current.paused) {
      videoRef?.current.play();
    } else {
      videoRef?.current.pause();
    }
  };

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const waitingHandler = () => {
      clearTimeout(isWaitingTimeout.current);

      isWaitingTimeout.current = setTimeout(() => {
        setIsWaiting(true);
      }, WAITING_DEBOUNCE_TIME);
    };

    const playHandler = () => {
      clearTimeout(isWaitingTimeout.current);
      clearTimeout(isPlayingTimeout.current);

      isPlayingTimeout.current = setTimeout(() => {
        setIsPlaying(true);
        setIsWaiting(false);
      }, PLAYING_DEBOUNCE_TIME);
    };

    const pauseHandler = () => {
      clearTimeout(isWaitingTimeout.current);
      clearTimeout(isPlayingTimeout.current);

      isPlayingTimeout.current = setTimeout(() => {
        setIsPlaying(false);
        setIsWaiting(false);
      }, PLAYING_DEBOUNCE_TIME);
    };

    const errorHandler = (e) => {
      console.log("Error loading source: " + src);
    };

    const element = videoRef.current;

    element.addEventListener("waiting", waitingHandler);
    element.addEventListener("play", playHandler);
    element.addEventListener("playing", playHandler);
    element.addEventListener("pause", pauseHandler);
    element.addEventListener("error", errorHandler);

    return () => {
      clearTimeout(isWaitingTimeout.current);
      clearTimeout(isPlayingTimeout.current);
      element.removeEventListener("waiting", waitingHandler);
      element.removeEventListener("play", playHandler);
      element.removeEventListener("playing", playHandler);
      element.removeEventListener("pause", pauseHandler);
      element.removeEventListener("error", errorHandler);
    };
  }, [videoRef, src]);

  return (
    <>
      <video
        ref={videoRef}
        src={src}
        muted
        loop
        preload="metadata"
        style={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}>
        Sorry, your browser doesn't support embedded videos.
      </video>
      <VideoStateButton onClick={handleToggleVideState} $isPlaying={isPlaying} className="playing-button">
        {!isPlaying ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path
              d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path
              d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z" />
          </svg>
        )}
      </VideoStateButton>
      {/* {isWaiting ? (
        <LoadingWrap>
          <CircularProgress color='primary' />
        </LoadingWrap>
      ) : (
        <></>
      )} */}
    </>
  );
}
