import styled, { useTheme } from "styled-components";
import React from "react";
import { device } from "../../theme/mediaQuery";
import { ProgressBar as Progress } from "../base/ProgressBar";
import { Typography } from "../base/Typography";

export const ProgressBarWrap = styled.div`
  position: relative;
  width: 100%;
  margin-top: 8px;
  @media (${device.lg}) {
    margin-top: 12px;
  }
`;

const TierIcon = styled.img`
  position: absolute;
  left: ${({ $score }) => `calc(${$score}% - 1.5%)`};
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 72px;
  height: 72px;
`;

const TierInfo = styled.div`
  display: flex;
  background: ${({ $background }) => $background};
  box-shadow: 0px 4px 4px 0px rgba(246, 246, 246, 0.2) inset;
  border-radius: 50px;
  min-height: 32px;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  text-align: center;

  background-color: red;
  @media (${device.sm}) {
    background-color: unset;
    width: max-content;
  }

  & p {
    font-weight: 600;
  }
  & p > span {
    white-space: nowrap;
  }
`;

const NextImage = styled.img`
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  width: 45px;
  height: 45px;
  z-index: 1;
`;

export const ProgressBar = ({
  nextIcon,
  nextTier,
  progressIcon,
  gradient,
  pointsRemaining,
  score,
}) => {
  const ratio = (score / (score + pointsRemaining)) * 100;
  const percentage = ratio === 0 ? 2 : ratio >= 100 ? 100 : ratio;
  const theme = useTheme();
  const $gradient = theme.gradients[gradient] || gradient;

  return (
    <>
      {nextTier && (
        <TierInfo $background={$gradient} $score={percentage}>
          {" "}
          <Typography
            tag={"p"}
            variant={"textXs"}
            xl={{ variant: "textSm" }}
            color="white"
          >
            You’re {pointsRemaining.toLocaleString("en")} points away from{" "}
            <span>{nextTier}</span>!
          </Typography>
        </TierInfo>
      )}
      <ProgressBarWrap>
        <Progress
          color={
            // $gradient ||
            "linear-gradient(270deg, #F6CE7E 0%, #F0A045 100%)"
          }
          background="#1B3296"
          now={percentage}
          animated={false}
        />

        <TierIcon src={progressIcon} alt="Tier Icon" $score={percentage} />
        {nextIcon && <NextImage src={nextIcon} alt={nextTier} />}
      </ProgressBarWrap>
    </>
  );
};
