import React from "react";
import styled from "styled-components";
import { Box } from "../base/Box";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { CardHeaderVideo } from "../base/CardHeaderVideo";
import { Typography } from "../base/Typography";
import { getAttribute } from "../Helpers";
import { device } from "../../theme/mediaQuery";
import { Modal } from "../base/Modal";

const VideoWrap = styled.div`
  border-radius: 8px;
  object-fit: cover;
  max-height: 700px;
  min-width: 50%;
  position: relative;

  @media (${device.xl}) {
    max-width: 50%;
    max-height: fit-content;
  }

  & div {
    top: 50%;
  }
`;

const StatsWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledStatHighlights = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 1rem;
  margin: 0.5rem 0;

  @media (${device.lg}) {
    flex-direction: row;
    justify-content: center;
    column-gap: 1.275rem;
  }
`;

const StyledStatBox = styled(Box)`
  width: 100%;
`;

const StatRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.darkBlue300};
  column-gap: 0.5rem;
  padding: 0.3rem;

  @media (${device.lg}) {
    padding: 1rem;
    column-gap: 1.25rem;
  }
`;

// const StatIcon = styled(FilterIcon)`
//   width: 24px;
//   height: 24px;
// `;

const StatsHighlightWrap = styled(Box)`
  width: 100%;

  @media (${device.lg}) {
    width: 50%;
  }
`;

export function ShadowcornModal({ showModal, setShowModal, shadowcorn }) {
  const shadowcornClass =
    shadowcorn && getAttribute("Class", shadowcorn).toLowerCase();
  const rarity = shadowcorn && getAttribute("Rarity", shadowcorn);

  const stats = shadowcorn && [
    {
      label: "Might",
      score: getAttribute("Might", shadowcorn),
    },
    {
      label: "Wickedness",
      score: getAttribute("Wickedness", shadowcorn),
    },
    {
      label: "Tenacity",
      score: getAttribute("Tenacity", shadowcorn),
    },
    {
      label: "Cunning",
      score: getAttribute("Cunning", shadowcorn),
    },
    {
      label: "Arcana",
      score: getAttribute("Arcana", shadowcorn),
    },
  ];

  return (
    <>
      {shadowcorn && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          title={shadowcorn?.name}
          subtitle={`Unicorn ID: ${shadowcorn.token_id}`}
          classIcon={shadowcornClass}
        >
          <>
            <VideoWrap>
              <CardHeaderVideo src={shadowcorn.image} />
            </VideoWrap>
            <Box
              variant="flat"
              rounded="md"
              color="darkBlue"
              className="col ms-xl-4"
              pattern="sprinkles"
            >
              <StatsWrap>
                <Box
                  variant="gradient"
                  rounded="sm"
                  gradient="linearBlack"
                  pattern="sprinkles"
                  patternSize="sm"
                  className="py-3 px-4"
                >
                  <Typography tag={"h3"} variant={"textXl"} color="white">
                    Stats Summary
                  </Typography>
                </Box>
                <div
                  style={{
                    background: "#e4f3fb",
                    borderBottomRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    height: "100%",
                  }}
                  className="p-2 px-3"
                >
                  <Row>
                    <StyledStatHighlights>
                      <StatsHighlight
                        label="Class"
                        value={shadowcornClass}
                        gradient="linear-gradient(143.22deg, #F46652 7.24%, #FD6041 94.73%)"
                      />
                      {rarity && (
                        <StatsHighlight
                          value={rarity}
                          gradient="linear-gradient(143.22deg, #14DBBD 7.24%, #00B8A8 94.73%);)"
                        />
                      )}
                    </StyledStatHighlights>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-wrap justify-content-between">
                      <StatsCard
                        data={stats}
                        id={shadowcorn.token_id}
                        key={`stat-card-shadowcorn.token_i-`}
                      />
                    </Col>
                  </Row>
                </div>
              </StatsWrap>
            </Box>
          </>
        </Modal>
      )}
    </>
  );
}

const StatsHighlight = ({ label, value, gradient }) => {
  return (
    <StatsHighlightWrap
      variant="gradient"
      rounded="sm"
      gradient={gradient}
      pattern="sprinkles"
      patternSize="lg"
      className={`p-3 col d-flex ${
        label ? "justify-content-between" : "justify-content-center"
      }`}
    >
      {label && (
        <Typography
          tag={"h4"}
          variant={"textLg"}
          color="white"
          className="d-inline"
        >
          {label}
        </Typography>
      )}
      <Typography
        tag={"h4"}
        variant={"textLg"}
        color="white"
        className="d-inline"
      >
        {value}
      </Typography>
    </StatsHighlightWrap>
  );
};

const StatsCard = ({ data, id }) => {
  return (
    <StyledStatBox
      variant="flat"
      rounded="sm"
      color="white"
      className="my-2 p-2"
    >
      {data.map((stat, i) => (
        <StatRow key={`${id}-${stat.label}`}>
          {/* <StatIcon name={stat.icon} /> */}
          <Typography
            tag={"h3"}
            variant={"textLg"}
            color="darkBlue300"
            className="me-auto"
          >
            {stat.label}
          </Typography>
          <Typography tag={"h3"} variant={"textLg"} color="darkBlue300">
            {stat.score}
          </Typography>
        </StatRow>
      ))}
    </StyledStatBox>
  );
};
