import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { googleAnalyticsEvent } from "../helpers/googleAnalytics";
import useDocumentTitle from "../helpers/useDocumentTitle";

export function Proxy({ url, event, to }) {
  const navigate = useNavigate();
  const [useEffectMounted, setUseEffectMounted] = useState(false);
  useDocumentTitle("Redirect");

  useEffect(() => {
    if (!useEffectMounted) {
      setUseEffectMounted(true);
      if (url) {
        window.open(url);
      } else {
        navigate(to);
      }
      googleAnalyticsEvent(event.name, event?.category);
    } else {
      navigate("/");
    }
  }, [url, useEffectMounted, event, navigate, to]);

  return null;
}
