import { QueryCache, QueryClient } from "@tanstack/react-query";

// set the base stale time for all queries for 1 hour
export const BASE_STALE_TIME = 1000 * 60 * 60; // 1 hour

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
      staleTime: BASE_STALE_TIME,
    },
  },
  queryCache: new QueryCache({
    onError: (err, query) => {
      console.log("There was an error with query: ", query.queryKey);
      console.log(err.message);
    },
  }),
});
