import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Header } from "../components/header";
import { HomeHeroBackground } from "../components/home/hero/HomeHeroBackground";
import { Typography } from "../components/base/Typography";
import { Container } from "../components/base/Container";
import { Row } from "../components/base/Row";
import { Col } from "../components/base/Col";
import { Footer } from "../layouts/BaseLayout/Footer";
import { device } from "../theme/mediaQuery";

import shadowcorn from "../img/home/carousel/shadowcorn-foreground.png";
import useDocumentTitle from "../helpers/useDocumentTitle";

const PurpleGradientWrapper = styled.div`
  background: linear-gradient(180deg, #150079 0%, #5118a4 43.52%);
  overflow-x: hidden;
  overflow-y: hidden;
`;

const ImgWrap = styled.img`
  width: 100%;
  max-width: 300px;

  @media (${device.lg}) {
    max-width: 411px;
  }
`;

const OopsWrap = styled.section`
  padding: 8px 12px 30px;
  min-height: 82vh;
`;

const HeroTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-bottom: 40px;
  text-align: center;

  @media (${device.xl}) {
    text-align: left;
    margin-bottom: 0;
  }
`;

const LinkWrap = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.magenta100};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.palette.violet100};
  }
`;

export function Oops() {
  useDocumentTitle("Not Found");
  return (
    <>
      <PurpleGradientWrapper>
        <HomeHeroBackground>
          <Header />
          <Container>
            <OopsWrap>
              <Row>
                <Col>
                  <HeroTextWrap>
                    <ImgWrap src={shadowcorn} />
                    <Typography
                      tag={"h1"}
                      variant={"displayMd"}
                      md={{ variant: "display2xl" }}
                    >
                      OOPS!
                    </Typography>
                    <Typography
                      tag={"h2"}
                      variant={"displayXs"}
                      md={{ variant: "displayXs" }}
                      className="mt-1 mb-3"
                    >
                      You’ve landed in an abandoned multiverse
                    </Typography>
                    <Typography
                      tag={"p"}
                      variant={"textSm"}
                      weight={600}
                      md={{ variant: "textLg" }}
                    >
                      Visit the <LinkWrap to="/">home page</LinkWrap> to
                      continue your ‘Corn adventure!
                    </Typography>
                  </HeroTextWrap>
                </Col>
              </Row>
            </OopsWrap>
          </Container>
        </HomeHeroBackground>
      </PurpleGradientWrapper>
      <Footer />
    </>
  );
}
