import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useAtomValue } from "jotai";
import { Link } from "react-router-dom";
import { COMMUNITY, WHITE_PAPER } from "../../consts/externalLinks";
import { device } from "../../theme/mediaQuery";
import { Typography } from "../base/Typography";
import { Container } from "../base/Container";
import { Button } from "../base/Button";
import { Tier } from "./Tier";
import { SectionWrap } from "../../views/Airdrop";
import { SectionCardAlt } from "../common/SectionCardAlt";
import { Box } from "../base/Box";
import Spinner from "../base/Spinner";
import { walletConnectedAtom } from "../../store";
import { ConnectWallet } from "../leaderboard/LeaderboardUnicornParty";
import { SpinnerWrap } from "../leaderboard/components/LeaderboardTableWithPagination";
import { useConnectModal } from "thirdweb/react";

import banner from "../../img/airdrop/banner.png";
import hero from "../../img/airdrop/hero.png";
import gift from "../../img/airdrop/gift.png";
import { RewardsBox } from "./RewardsBox";

const SectionCard = styled(SectionCardAlt)`
  padding: 0 16px 24px;
  min-height: 835px;
  @media (${device.md}) {
    padding: 0 21px 50px;
  }
  @media (${device.lg}) {
    padding: 0 21px 62px;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media (${device.lg}) {
      gap: 56px;
    }
  }
`;

const StatsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  align-items: stretch;
  gap: 24px 32px;

  @media (${device.lg}) {
    gap: 32px;
    flex-direction: row;
    align-items: center;
  }
  @media (${device.xl}) {
    gap: 56px;
  }
`;

const StatsImg = styled.img`
  border-radius: 16px;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset; */
  width: 100%;
  @media (${device.lg}) {
    width: 50%;
    margin-bottom: auto;
  }
  @media (${device.xl}) {
    width: 52%;
  }
`;

const StatsDetails = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  gap: 18px;
  @media (${device.lg}) {
    gap: 12px;
  }
  @media (${device.xl}) {
    gap: 24px;
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 16px;
  flex-direction: column;
  align-items: stretch;
  margin-top: 12px;
  @media (${device.lg}) {
    flex-direction: row;
    align-self: stretch;
  }
  & * {
    font-size: 18px;
    @media (${device.lg}) {
      font-size: 21px;
    }
    @media (${device.xxl}) {
      font-size: 23px;
    }
  }
`;

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  @media (${device.lg}) {
    flex-direction: column;
  }
  & > p {
    text-align: center;
    @media (${device.lg}) {
      text-align: left;
    }
  }
  & > p:last-child {
    font-weight: 700;
  }
`;

const BoxWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 18px;
  gap: 8px 24px;
  align-self: stretch;
  justify-content: space-between;
  @media (${device.lg}) {
    flex-direction: row;
    padding: 20px 40px;
  }
`;

const TIMESTAMP_EVENT = 1725148800;

export const Stats = ({ leaderboard, ...rest }) => {
  const walletAddress = useAtomValue(walletConnectedAtom);

  const isLoading = walletAddress ? leaderboard?.isLoading : false;

  const self = walletAddress ? leaderboard?.self : null;
  const points = self?.score;

  const pointsData = self?.points_data ? self?.points_data : null;
  const tier = pointsData?.current_tier || 0;
  const pointsRemaining = pointsData?.next_tier_pts_need || 0;
  const { connect } = useConnectModal();

  return (
    <Container>
      <SectionWrap {...rest}>
        <SectionCard
          title="Your season 1 stats"
          background="rgba(63, 82, 162, 0.10)"
          titleBackground="linear-gradient(180deg, #4CADF1 0%, #4C73F4 100%)"
          trapezoidColor="linear-gradient(180deg, #1784DB 0%, #2953DB 100%)"
          borderColor="#3C83EB"
          titleShadow="1px 4px 0px #1453D7"
          // renderBanner={() => <Banner />}
        >
          {isLoading && (
            <SpinnerWrap>
              <Spinner color="white" />
            </SpinnerWrap>
          )}
          {!isLoading && (
            <>
              <StatsWrap>
                <StatsImg src={hero} alt="Reward bundle" />
                <StatsDetails>
                  <Score score={points?.toLocaleString("en") || 0} />
                  <Divider />
                  <Tier
                    points={points}
                    tier={tier}
                    pointsRemaining={pointsRemaining}
                  />
                  <ButtonsWrap>
                    <Button
                      as={Link}
                      size="lg"
                      to={COMMUNITY.DISCORD}
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="secondary"
                    >
                      Join the community
                    </Button>
                    <Button
                      as={Link}
                      size="lg"
                      to={WHITE_PAPER.AIRDROP}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </Button>
                  </ButtonsWrap>
                </StatsDetails>
              </StatsWrap>
              <RewardsBox tier={tier} />
              {!walletAddress && <ConnectWallet connect={connect} />}
              <BoxWrap
                variant="gradient"
                gradient="linear-gradient(180deg, #6F19B8 0%, #2D0D78 100%)"
                pattern="sprinkles"
                patternSize="sm"
                patternOpacity="0.05"
                rounded="md"
              >
                <TitleWrap>
                  {/* <Typography
                    tag={"p"}
                    variant={"textMd"}
                    md={{ variant: "textLg" }}
                    xl={{ variant: "textXl" }}
                    textshadow="hardAlt"
                    color="white"
                  >
                    Preseason ended!
                  </Typography> */}
                  <Typography
                    tag={"p"}
                    variant={"textMd"}
                    sm={{ variant: "textXl" }}
                    md={{ variant: "displayXs" }}
                    xl={{ variant: "displaySm" }}
                    family="display"
                    color="white"
                  >
                    Season 1 ends in
                  </Typography>
                </TitleWrap>
                <Countdown targetEpoch={TIMESTAMP_EVENT} />
              </BoxWrap>
              <DailyReward />
            </>
          )}
        </SectionCard>
      </SectionWrap>
    </Container>
  );
};

const ScoreWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  @media (${device.md}) {
    gap: 12px;
  }

  & > * {
    line-height: 100%;
  }
`;
const ScoreTitle = styled(Typography)`
  background: linear-gradient(180deg, #e7b610 0%, #ff9a02 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Score = ({ score }) => {
  return (
    <ScoreWrap>
      <Typography
        tag={"p"}
        variant={"textXs"}
        md={{ variant: "textSm" }}
        xl={{ variant: "textMd" }}
        className="text-center"
        family="display"
        color="white"
      >
        Your Score
      </Typography>
      <ScoreTitle
        tag={"p"}
        variant={"displayMd"}
        md={{ variant: "displayLg" }}
        xl={{ variant: "displayXl" }}
        className="text-center"
        family="display"
        color="white"
      >
        {score}
      </ScoreTitle>
    </ScoreWrap>
  );
};

const BannerWrap = styled.div`
  position: absolute;
  z-index: 3;
  right: -9px;
  top: -9px;
  user-select: none;
  background-image: url(${banner});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  justify-content: center;
  align-items: stretch;

  width: 85px;
  height: 85px;
  @media (${device.sm}) {
    width: 150px;
    height: 150px;
  }
  @media (${device.xl}) {
    width: 216px;
    height: 216px;
  }
`;

const BannerText = styled(Typography)`
  position: relative;
  transform: rotate(45deg);
  max-width: 50%;
  line-height: 10px;

  margin-top: 2px;
  margin-right: 2px;

  @media (${device.sm}) {
    margin: unset;
    line-height: inherit;
  }
`;

export const Banner = () => {
  return (
    <BannerWrap>
      <BannerText
        tag={"p"}
        variant={"text2xs"}
        sm={{ variant: "textSm" }}
        xl={{ variant: "textLg" }}
        className="text-center"
        family="display"
        color="white"
      >
        First snapshot taken
      </BannerText>
    </BannerWrap>
  );
};

const DividerWrap = styled.div`
  background: ${({ $color }) => ($color ? $color : "rgba(0, 0, 0, 0.11)")};
  height: ${({ $isVertical }) => ($isVertical ? "100%" : "1px")};
  width: ${({ $isVertical }) => ($isVertical ? "1px" : "100%")};
`;

export const Divider = ({ color, vertical = false, ...rest }) => {
  return <DividerWrap $color={color} $isVertical={vertical} {...rest} />;
};

const CountdownWrap = styled.div`
  display: flex;
  border-radius: 8px;
  gap: 8px;
  align-items: center;
  flex-direction: column;

  @media (${device.lg}) {
    padding: 8px 16px;
    gap: 26px;
    flex-direction: row;
  }
`;

const NumberBox = styled.div`
  background: rgba(63, 82, 162, 0.4);
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 4px 20px;
  @media (${device.lg}) {
    padding: 4px 12px;
  }
`;

const NumberBoxWrap = styled.div`
  display: flex;
  gap: 8px;
`;

const Countdown = ({ targetEpoch }) => {
  const [days, setDays] = useState(0);
  const dayStr = days.toString().padStart(2, "0");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const targetDate = new Date(targetEpoch * 1000);
      const distance = targetDate - now;

      const calculatedDays = Math.floor(distance / (1000 * 60 * 60 * 24));

      if (distance < 0) {
        clearInterval(interval);
        setDays(0);
      } else {
        setDays(calculatedDays);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetEpoch]);

  return (
    <CountdownWrap>
      <NumberBoxWrap>
        {dayStr.split("").map((digit, i) => (
          <NumberBox key={`countdown-${digit}-${i}`}>
            <Typography
              tag={"p"}
              variant={"displaySm"}
              md={{ variant: "displayLg" }}
              xl={{ variant: "displayXl" }}
              family="display"
            >
              {digit}
            </Typography>
          </NumberBox>
        ))}
      </NumberBoxWrap>
      <Typography
        tag={"p"}
        variant={"textXs"}
        md={{ variant: "displaySm" }}
        xl={{ variant: "displayMd" }}
        family="display"
      >
        Days
      </Typography>
    </CountdownWrap>
  );
};

const DailyBoxWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 18px;
  gap: 12px 24px;

  @media (${device.sm}) {
    align-items: center;
  }
  @media (${device.lg}) {
    align-items: center;
    flex-direction: row;
    padding: 20px 40px;
  }
  @media (${device.xl}) {
    padding: 40px;
  }

  & a * {
    font-size: 18px;
    white-space: nowrap;
    @media (${device.lg}) {
      font-size: 21px;
    }
    @media (${device.xxl}) {
      font-size: 23px;
    }
  }
`;

const Img = styled.img`
  display: none;
  height: 100px;
  left: -30px;
  @media (${device.lg}) {
    height: 120px;
    position: absolute;
    display: block;
    height: 120px;
    left: -30px;
  }
  @media (${device.xl}) {
    height: 146px;
    left: -20px;
  }
  @media (${device.xxl}) {
    height: 190px;
    left: -25px;
  }
`;

const ImgMobile = styled(Img)`
  display: block;
  @media (${device.lg}) {
    display: none;
  }
`;

const ContentWrap = styled.div`
  flex-shrink: 1;
  display: flex;
  gap: 12px;
  align-items: center;
  flex-direction: column;
  @media (${device.sm}) {
    flex-direction: row;
  }
`;

const TextWrap = styled.div`
  text-align: center;
  @media (${device.sm}) {
    text-align: unset;
  }
  @media (${device.lg}) {
    padding-left: 60px;
  }
  @media (${device.xl}) {
    padding-left: 105px;
  }
  @media (${device.xxl}) {
    padding-left: 140px;
  }
`;

const DailyReward = () => {
  return (
    <DailyBoxWrap
      variant="gradient"
      gradient="linear-gradient(180deg, #FB60C7 0%, #FF2BB0 100%)"
      pattern="labyrinth"
      patternSize="xs"
      patternOpacity="0.05"
      rounded="md"
    >
      <Img src={gift} alt="" />
      <ContentWrap>
        <ImgMobile src={gift} alt="" />
        <TextWrap>
          <Typography
            tag={"h3"}
            variant={"textXl"}
            md={{
              variant: "displaySm",
            }}
            textshadow="soft"
            className="d-flex flex-column justify-content-center"
          >
            Daily Rewards Await!
          </Typography>
          <Typography
            tag={"p"}
            variant={"textSm"}
            weight={600}
            md={{
              variant: "textMd",
            }}
            textshadow="soft"
          >
            XAI Rewards distributed daily at 00:00 UTC! Visit the rewards page
            to learn more.
          </Typography>
        </TextWrap>
      </ContentWrap>
      <Button as={Link} size="lg" to="/claim">
        My Rewards
      </Button>
    </DailyBoxWrap>
  );
};
