import { Box } from "../../base/Box";
import styled from "styled-components";
import { Row } from "../../base/Row";
import React from "react";
import { Col } from "../../base/Col";
import { Icon } from "../../base/Icon";
import { Typography } from "../../base/Typography";
import { device } from "../../../theme/mediaQuery";

const Wrap = styled(Box)`
  padding: 16px 20px;
  text-align: center;
  @media (${device.md}) {
    padding: 32px 40px;
    text-align: left;
    height: 100%;
  }
`;

const IconWrap = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $color }) => $color};
  color: ${({ theme }) => theme.palette.white};
  margin: auto;
  @media (${device.md}) {
    width: 70px;
    height: 70px;
  }
  @media (${device.xxl}) {
    width: 80px;
    height: 80px;
  }
`;

const FooterWrap = styled.div`
  padding-top: 35px;
`;

export function HomeEvolutionFooterCard({
  footer,
  title,
  subtitle,
  background,
  iconBackground,
  icon,
}) {
  return (
    <Wrap
      gradient={background}
      variant="gradient"
      rounded="md"
      pattern="labyrinth"
      patternSize="contain"
    >
      <>
        <Row style={{ height: "100%" }}>
          <Col xs={12} md={2} className="mb-3 mb-md-0">
            <IconWrap $color={iconBackground}>
              <Icon size="60%" name={icon} />
            </IconWrap>
          </Col>
          <Col xs={12} md={10} className="ps-lg-4">
            <Typography
              variant="TextLg"
              color="white"
              family="display"
              tag="h5"
              lg={{
                variant: "displaySm",
              }}
              md={{
                variant: "textXl",
              }}
              textshadow="soft"
            >
              {title}
            </Typography>
            <Typography
              variant="textXs"
              color="white"
              tag="p"
              weight={700}
              lg={{
                variant: "textSm",
              }}
              md={{
                variant: "textMd",
              }}
              textshadow="soft"
            >
              {subtitle}
            </Typography>
          </Col>
          <Col xs={12}>
            <FooterWrap>{footer}</FooterWrap>
          </Col>
        </Row>
      </>
    </Wrap>
  );
}
