import styled from "styled-components";
import React from "react";
import { device } from "../../../theme/mediaQuery";
import { Typography } from "../../base/Typography";

import checked from "../../../img/leaderboard/bingo/checked.png";
import unchecked from "../../../img/leaderboard/bingo/unchecked.png";
import { ProgressBar } from "../../base/ProgressBar";

export const VARIANTS = {
  PINK: 0,
  PURPLE: 1,
  BLUE: 2,
  EGGPLANT: 3,
  MAGENTA: 4,
  BRIGHT_PINK: 5,
};

const THEME = {
  [VARIANTS.PINK]: { name: "pink", border: "#E18CF2", background: "#D96CEF" },
  [VARIANTS.PURPLE]: {
    name: "purple",
    border: "#9989DC",
    background: "#816DD4",
  },
  [VARIANTS.EGGPLANT]: {
    name: "eggplant",
    border: "#8A2C9A",
    background: "#661574",
  },
  [VARIANTS.MAGENTA]: {
    name: "eggplant",
    border: "#E161D9",
    background: "#AA25A2",
  },
  [VARIANTS.BRIGHT_PINK]: {
    name: "bright-pink",
    border: "#FF59C2",
    background: "#DB1A94",
  },
};

const TableWrap = styled.div`
  border-radius: 16px;
  background: ${({ $background }) => $background && $background};
  border-radius: 8px;
  border: ${({ $border }) => $border && `2px solid ${$border}`};
  padding: 4px 24px;
  @media (${device.lg}) {
    padding: 16px 40px;
  }
  & h4 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 16px;
    min-width: 915px;
  }
`;

const StyledTable = styled.table`
  text-align: center;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  min-width: 915px;
`;

const TableRow = styled.tr`
  display: flex;
  width: 100%;
  align-items: stretch;
`;

const TableCell = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  flex: ${({ $flexBasis }) => $flexBasis || "1"};
  @media (${device.lg}) {
    font-size: 16px;
  }
  &:first-child {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }
`;

const CheckedCell = styled(TableCell)`
  justify-content: flex-end;
  flex: 0.2;
`;

const TitleCell = styled(TableCell)`
  flex-direction: column;
  align-items: flex-start;
  text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
  font-weight: 400;
  font-size: ${({ $small }) => ($small ? "16px" : "18px")};
  font-family: ${({ theme }) => theme.fonts.display};
  text-shadow: none;
  text-align: left;
  @media (${device.lg}) {
    font-size: ${({ $small }) => ($small ? "22px" : "24px")};
  }

  & span {
    line-height: 100%;
    display: block;
    font-size: 12px;
    @media (${device.lg}) {
      font-size: 14px;
    }
  }
`;

const PointsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  flex-wrap: wrap;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 4px 2px;
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 14px;
  border-radius: 50px;
  margin: 0 12px;
  gap: 6px;
  width: 100%;
  @media (${device.lg}) {
    font-size: 18px;
  }
  & span {
    word-break: keep-all;
    font-size: inherit;
    font-family: inherit;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const ProgressBarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
`;

const Reward = styled.img`
  height: 30px;
  margin-right: 4px;
  width: auto;
`;

const ProgressText = styled.span`
  position: absolute;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: ${({ theme }) => theme.fonts.display};
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 14px;
`;

export const LeaderboardRow = ({
  title,
  rows,
  variant = VARIANTS.PINK,
  tooltipColor = "#7931B1",
}) => {
  const theme = THEME[variant];
  const isThreeCells = Object.keys(rows[0]).length < 4 ? true : false;
  return (
    <TableWrap $background={theme.background} $border={theme.border}>
      {title && (
        <Typography
          tag={"h4"}
          variant={"textSm"}
          md={{ variant: "textMd" }}
          xl={{ variant: "displayXs" }}
          textshadow="hard"
        >
          {title}
        </Typography>
      )}
      <StyledTable>
        <tbody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TitleCell
                $small={row.subtitle}
                $flexBasis={isThreeCells ? "2" : "1.8"}
              >
                {row.label}
                {row.subtitle && <span>{row.subtitle}</span>}
              </TitleCell>
              <TableCell
                $flexBasis={
                  row.total === 1 && row.reward
                    ? "2.6"
                    : isThreeCells
                    ? "1"
                    : "1"
                }
                $small={true}
              >
                {row.detail
                  ? row.detail
                  : row.reward !== undefined && (
                      <>
                        {row.reward.img && (
                          <Reward src={row.reward.img} alt={row.reward.title} />
                        )}
                        {row.reward.title}
                      </>
                    )}
              </TableCell>

              {row.quantity && (
                <TableCell $flexBasis="0.5">{row.quantity}x</TableCell>
              )}

              {row.points !== undefined && (
                <TableCell $flexBasis={isThreeCells ? "2" : "0.8"}>
                  <PointsBox>
                    {row.points ? row.points.toLocaleString() : 0}
                    <span> Matches</span>
                  </PointsBox>
                </TableCell>
              )}
              {row.progress !== undefined && row?.total > 1 && (
                <TableCell $flexBasis={isThreeCells ? "2" : "1.5"}>
                  <ProgressBarContainer>
                    <ProgressBar
                      now={Math.min((row.progress / row.total) * 100, 100)}
                      max={row.total}
                      color="linear-gradient(270deg, #F6CE7E 0%, #F0A045 100%)"
                      background="rgba(0, 0, 0, 0.20)"
                      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"
                      animated={false}
                      tooltip={row.tooltip}
                      tooltipColor={tooltipColor}
                    />
                    <ProgressText>{`${
                      row.progress > row.total ? row.total : row.progress
                    } /${row.total}`}</ProgressText>
                  </ProgressBarContainer>
                </TableCell>
              )}
              {row.progress !== undefined && row.checked === undefined && (
                <CheckedCell>
                  {row.progress >= row.total ? (
                    <Icon src={checked} alt="checked icon" />
                  ) : (
                    <Icon src={unchecked} alt="unchecked icon" />
                  )}
                </CheckedCell>
              )}
              {row.checked !== undefined && (
                <CheckedCell>
                  {row.checked ? (
                    <Icon src={checked} alt="checked icon" />
                  ) : (
                    <Icon src={unchecked} alt="unchecked icon" />
                  )}
                </CheckedCell>
              )}
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </TableWrap>
  );
};
