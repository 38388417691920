import styled from "styled-components";
import React from "react";
import { device } from "../../theme/mediaQuery";

import BlueBox from "../../img/box/blueBox.png";
import BlueBoxMobile from "../../img/box/blueBox-mobile.png";

export const BoxBackground = {
  blueGradient: BlueBox,
  blueGradientMobile: BlueBoxMobile,
};

const ImgWrap = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 30px;

  @media (${device.md}) {
    top: auto;
    bottom: 305px;
  }
`;

const ContentWrap = styled.div`
  padding: 30px;
  top: 180px;
  width: 100%;
  position: absolute;

  @media (${device.lg}) {
    top: 120px;
  }
`;

const BoxWrap = styled.div`
  background: ${({ $background }) =>
    $background &&
    `url(${
      BoxBackground[`${$background}Mobile`] || BoxBackground.blueGradientMobile
    })`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  border-radius: 32px;
  position: relative;
  height: 500px;
  width: 100%;

  @media (${device.sm}) {
    height: 450px;
  }

  @media (${device.md}) {
    background: ${({ $background }) =>
      $background &&
      `url(${BoxBackground[`${$background}`] || BoxBackground.blueGradient})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    border-radius: 0;
    width: unset;
    min-width: 385px;
    height: 500px;
  }

  @media (${device.lg}) {
    height: 430px;
    width: unset;
    background-size: contain;
  }
`;

export function SemiCircleBox({ image, background, children, ...rest }) {
  return (
    <BoxWrap $background={background} {...rest}>
      {image && <ImgWrap>{image}</ImgWrap>}
      <ContentWrap>{children}</ContentWrap>
    </BoxWrap>
  );
}
