import styled from "styled-components";
import { Header } from "../../components/header";
import React from "react";
import { Footer } from "../../layouts/BaseLayout/Footer";
import { Container } from "../../components/base/Container";
import { Outlet, useLocation } from "react-router-dom";
import { StakingTabs } from "../../components/staking/StakingTabs";
import hero_rainbow from "../../img/home/hero-rainbow.png";
import { device } from "../../theme/mediaQuery";
import { StakingSiteCard } from "../../components/staking/overview/StakingSiteCard";
import { NetworkBanner } from "../../components/staking/overview/NetworkBanner";
import { useAtomValue } from "jotai";
import { walletConnectedAtom } from "../../store";
import { StakingConnectWalletPlaceholder } from "../../components/staking/StakingConnectWalletPlaceholder";

const RouteTitlesMap = {
  "/staking/overview": "Staking",
  "/staking/rewards": "Rewards",
  "/staking/badges": "Badges",
  "/staking/deposits": "Deposits",
  "/staking/leaderboard": "Leaderboard",
};

const GradientBackground = styled.div`
  background: linear-gradient(180deg, #5727A3 0%, #FCA1E6 100%);
  position: relative;
  overflow: hidden;
  z-index: 1;
`;

const PageTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  //margin-bottom: 19px;
`;

const PageTitle = styled.h1`
  font-size: 1.875rem;
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 700;
  color: ${({ theme }) => theme.palette.white};

  text-align: center;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  z-index: 3;
  letter-spacing: 0.05em;

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-text-stroke: 15px #8221E3;
    font-size: 1em;
    filter: drop-shadow(0px 4px 0px #3D007A);
  }

  @media (${device.lg}) {
    font-size: 3.875rem;
  }
`;

const ContentWrap = styled.div`
  width: 100%;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  padding: 55px 12px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: -22px;
  position: relative;
  z-index: 2;

  @media (${device.xxl}) {
    padding: 55px 80px;
    margin-top: -32px;
  }
`;

const StakingSiteCardWrap = styled(StakingSiteCard)`
  margin-bottom: 80px;
  @media (${device.xxl}) {
    margin: 80px;
  }
`;

const Rainbow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-image: url(${hero_rainbow});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  z-index: -1;
`;

export function Staking() {
  const location = useLocation();
  const title = RouteTitlesMap[location.pathname];
  const isOverview = title?.includes("Staking");
  const isLeaderboard = title?.includes("Leaderboard");
  const isExcluded = isLeaderboard || isOverview;

  const isWalletConnected = useAtomValue(walletConnectedAtom);
  return (
    <>
      <NetworkBanner />
      <GradientBackground>
        <Header large={true} />
        <Container large={true}>
          <PageTitleWrap>
            <PageTitle data-text={title}>{title}</PageTitle>
          </PageTitleWrap>
          <ContentWrap>
            <StakingTabs />
            {!isWalletConnected && !isExcluded && (
              <StakingConnectWalletPlaceholder />
            )}
            {(isWalletConnected || isExcluded) && <Outlet />}
          </ContentWrap>
          {isOverview && <StakingSiteCardWrap />}
        </Container>
        <Footer />
        <Rainbow />
      </GradientBackground>
    </>
  );
}
