import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAtomValue } from "jotai";
import { RainbowBackground } from "../components/home/hero/HomeHeroBackground";
import { Typography } from "../components/base/Typography";
import { Container } from "../components/base/Container";
import { Row } from "../components/base/Row";
import { Footer } from "../layouts/BaseLayout/Footer";
import { PatternBackground } from "../components/base/Box";
import { SectionCard } from "../components/common/SectionCard";
import { Button } from "../components/base/Button";
import { Icon } from "../components/base/Icon";
import { Header } from "../components/header";

import { EVENT_KEYS } from "../consts/googleEvents";

import { device } from "../theme/mediaQuery";

import useDocumentTitle from "../helpers/useDocumentTitle";
import { googleAnalyticsEvent } from "../helpers/googleAnalytics";

import rainbow from "../img/rainbow.png";
import unicorn from "../img/griffin-unicorn.png";
import polygon from "../img/chains/polygon.png";
import arbitrum from "../img/chains/arbitrum.png";
import xai from "../img/currencies/xai-red.png";
import rbw from "../img/currencies/rbw-tilted.png";
import unim from "../img/currencies/unim.png";

import {
  currentWalletAddressAtom,
  signerAtom,
  walletConnectedAtom,
} from "../store";
import { BridgeModal } from "../components/bridge/BridgeModal";
import { TransferDetails } from "../components/bridge/TransferDetails";
import { checkSmartWallet, formatWalletAddress } from "../components/Helpers";
import { getBridgeContract } from "../components/bridge/contracts";
import Spinner from "../components/base/Spinner";
import { useGetWalletTokens } from "../query/tokens";
import { RecipientInput } from "../components/bridge/RecipientInput";
import { DropDownField } from "../components/bridge/DropDownField";
import { TokenField } from "../components/bridge/TokenField";
import { useConnectModal } from "thirdweb/react";
import { connectWallet } from "../components/ConnectWallet/connectWallet";
import { ethers } from "ethers";
import { SCAN } from "../consts/externalLinks";

export const TOKEN_NAMES = {
  RBW: 0,
  UNIM: 1,
};

export const CHAIN_NAMES = {
  POLYGON: 0,
  ARBITRUM: 1,
  XAI: 2,
};

export const TOKEN = {
  [TOKEN_NAMES.RBW]: {
    name: "RBW",
    img: rbw,
    id: TOKEN_NAMES.RBW,
    chainId: CHAIN_NAMES.ARBITRUM,
    ratio: 10,
    convertedToken: "CU",
    scanLinks: SCAN.LAYER_ZERO,
  },
  [TOKEN_NAMES.UNIM]: {
    name: "UNIM",
    img: unim,
    id: TOKEN_NAMES.UNIM,
    chainId: CHAIN_NAMES.XAI,
    ratio: 1,
    convertedToken: "UNIM",
    scanLinks: SCAN.EXPLORER,
  },
};

export const CHAIN = {
  [CHAIN_NAMES.POLYGON]: {
    name: "Polygon",
    img: polygon,
    id: CHAIN_NAMES.POLYGON,
  },
  [CHAIN_NAMES.ARBITRUM]: {
    name: "Arbitrum",
    img: arbitrum,
    id: CHAIN_NAMES.ARBITRUM,
  },
  [CHAIN_NAMES.XAI]: {
    name: "XAI",
    img: xai,
    id: CHAIN_NAMES.XAI,
  },
};

const PurpleGradientWrapper = styled.div`
  background: linear-gradient(180deg, #6f19b8 0%, #2d0d78 100%);
  overflow: hidden;
`;

const ContainerWrap = styled(Container)`
  padding: 26px 0 60px;
  max-width: 712px;
  @media (${device.xxl}) {
    padding: 32px 0 100px;
  }
`;

const PatternBackgroundWrap = styled(PatternBackground)`
  background-size: 200%;
  @media (${device.md}) {
    background-size: 100%;
  }
  @media (${device.xxl}) {
    background-size: 50%;
  }
`;

const SectionCardWrap = styled(SectionCard)`
  position: relative;
  margin: 0 12px;

  @media (${device.sm}) {
    margin: 0 24px;
  }
  @media (${device.md}) {
    margin: auto;
  }

  & h2 {
    font-size: 26px;
  }

  & .title-wrap {
    padding: 17px 0;
  }
`;

const ImgWrap = styled.img`
  width: 100%;
  transform: translateX(-100%);
  position: absolute;
  display: none;
  @media (${device.lg}) {
    max-width: 428px;
    left: 0;
    bottom: -80px;
    display: block;
  }
`;

const RowWrap = styled(Row)`
  gap: 20px;
  padding: 0 12px 40px;

  @media (${device.md}) {
    padding: 0 32px 40px;
  }

  @media (${device.lg}) {
    padding: 0 40px 40px;
  }
`;

const ChainsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  position: relative;
  padding: 0;
  @media (${device.md}) {
    flex-direction: row;
  }
`;

const ErrorMessage = styled(Typography)`
  color: #fe7a75;
  margin-top: 4px;
  opacity: ${({ $error }) => ($error ? 1 : 0)};
  max-height: ${({ $error }) => ($error ? "40px" : "0")};
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease, margin 0.5s ease;
`;

const SpinnerWrap = styled.div`
  padding: 8px 2px;
  height: 36px;

  & svg {
    filter: none;
    height: 20px;
    vertical-align: top;
  }
`;

const IconContainer = styled.div`
  background-color: #3e0f94;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 64px;
  height: 24px;

  left: 50%;
  top: 44%;
  transform: translate(-50%);
  position: absolute;

  @media (${device.md}) {
    width: 24px;
    position: static;
    transform: none;
  }

  & svg {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(170deg)
      brightness(104%) contrast(103%);
  }
`;

const fromWei = (value) => {
  return ethers.utils.formatEther(value);
};

export function Bridge() {
  useDocumentTitle("Rainbow Bridge");
  //const [{ wallet }, connect] = useConnectWallet();
  const wallet = useAtomValue(signerAtom);
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const walletConnected = useAtomValue(walletConnectedAtom);
  const { connect } = useConnectModal();
  const { data } = useGetWalletTokens();
  const {
    RBW = 0,
    MATIC = 0,
    CU = 0,
    RBWWEI = 0,
    UNIM = 0,
    UNIMWEI,
  } = data || {};

  const [transferQuantity, setTransferQuantity] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [gasFees, setGasFee] = useState("0.0");
  const [sendFee, setSendFee] = useState(null);
  const [isInputVisible, setIsInputVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [smartWalletError, setSmartWalletError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [amountError, setAmountError] = useState(null);
  const [maticError, setMaticError] = useState(null);

  const [tokenId, setTokenId] = useState(TOKEN_NAMES.RBW);
  const [chainId, setChainId] = useState(CHAIN_NAMES.ARBITRUM);
  const [availableBalance, setAvailableBalance] = useState(RBW);
  const [totalConvertedToken, setTotalConvertedToken] = useState(CU);

  const [weiBalance, setWeiBalance] = useState(RBWWEI);

  const disable =
    (walletAddress && transferQuantity === "0") ||
    (isInputVisible && recipientAddress === "") ||
    transferQuantity === "" ||
    addressError ||
    smartWalletError ||
    amountError ||
    gasFees > MATIC;

  const estimatedTime = 20;

  const connectUser = () => {
    connectWallet(connect);
    googleAnalyticsEvent(EVENT_KEYS.CONNECT_CLICKED);
  };

  const handleRbwChange = (value) => {
    setTransferQuantity(value);
    setSmartWalletError(false);

    const RBWBalance = ethers.utils.formatEther(weiBalance);

    if (Number(value) > Number(RBWBalance)) {
      setAmountError("Insufficient Balance");
    } else if (!Number(value) && value !== "") {
      // added a check for empty string to avoid
      // annoying the user with an error for no reason
      setAmountError("Enter a Valid Amount");
    } else {
      setAmountError(null);
    }
  };

  const handleSetMax = () => {
    const maxInEther = fromWei(weiBalance.toString());
    handleRbwChange(maxInEther);
  };

  const handleRecipientChange = (value) => {
    setRecipientAddress(value);
    // reset smart wallet error
    setSmartWalletError(false);
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
    setIsLoading(false);
    setError(false);
  };

  const onSelect = (tokenId) => {
    setTokenId(tokenId);
    //switch chain & update available balance
    setChainId(TOKEN[tokenId].chainId);
    setAvailableBalance(tokenId === TOKEN_NAMES.RBW ? RBW : UNIM);
    setWeiBalance(tokenId === TOKEN_NAMES.RBW ? RBWWEI : UNIMWEI);
    setTotalConvertedToken(tokenId === TOKEN_NAMES.RBW ? CU : UNIM);

    //reset errors and field
    setTransferQuantity("");
    setAmountError(null);
  };

  const handleSubmit = async () => {
    toggleModal();
    if (walletConnected) {
      setIsLoading(true);
      checkSmartWallet(walletAddress, wallet)
        .then((hasError) => {
          setSmartWalletError(hasError);
          setIsLoading(false);
          if (hasError) {
            toggleModal();
          }
        })
        .catch((e) => {
          console.log(e);
          setError(true);
          setIsLoading(false);
        });
    } else {
      connectUser();
    }
  };

  useEffect(() => {
    setWeiBalance(tokenId === TOKEN_NAMES.RBW ? RBWWEI : UNIMWEI);
    setAvailableBalance(tokenId === TOKEN_NAMES.RBW ? RBW : UNIM);
    setTotalConvertedToken(tokenId === TOKEN_NAMES.RBW ? CU : UNIM);
  }, [RBWWEI, UNIMWEI, RBW, UNIM, CU, tokenId]);

  useEffect(() => {
    async function getFee() {
      if (wallet && transferQuantity) {
        const bridgeContract = getBridgeContract(
          wallet,
          tokenId === 0 ? "dstChainIdArbitrum" : "dstChainIdXai",
          tokenId === 0
            ? "RBWBridgeContractAddress"
            : "UNIMBridgeContractAddress",
          walletAddress
        );
        const fee = await bridgeContract.estimateSendFee(
          Number(transferQuantity).toFixed(0)
        );
        const matic = ethers.utils.formatEther(String(fee.nativeFee));
        setGasFee(matic);
        setSendFee(fee);
        if (Number(matic) > Number(MATIC)) {
          setMaticError("Insufficient Matic Balance");
        } else {
          setMaticError(null);
        }
      }
    }

    if (!transferQuantity) {
      setMaticError(null);
      setGasFee("0.0");
    }
    getFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferQuantity, wallet]);

  const getToAddress = () => {
    if (addressError) {
      return "...";
    }
    return recipientAddress ? recipientAddress : walletAddress;
  };

  return (
    <>
      <PurpleGradientWrapper>
        <RainbowBackground>
          <PatternBackgroundWrap
            pattern="sprinkles"
            $patternSize="50%"
            $opacity="0.05"
            as="div"
          />
          <Header large={true} />
          <ContainerWrap>
            <SectionCardWrap
              title="Rainbow Bridge"
              patternSize="0%"
              titleBackground="linear-gradient(180deg, #FF42CE 0%, #FF8BB3 100%)"
              background="#6831CC"
              trapezoidColor="linear-gradient(180deg, #EC40B3 0%, #EC759F 100%)"
              borderColor="none"
              shadow
              img={rainbow}>
              <ImgWrap src={unicorn} />
              <RowWrap>
                <DropDownField
                  title="Token"
                  token={TOKEN[tokenId]}
                  onSelect={onSelect}
                />
                <ChainsWrap>
                  <Chain
                    address={walletAddress}
                    chain={CHAIN[CHAIN_NAMES.POLYGON].name}
                    img={CHAIN[CHAIN_NAMES.POLYGON].img}
                    prefix="From"
                  />
                  <IconContainer>
                    <Icon
                      name="arrow-down"
                      size="16px"
                      className="d-flex d-md-none"
                    />
                    <Icon
                      name="arrow-right"
                      size="16px"
                      className="d-none d-md-flex"
                    />
                  </IconContainer>
                  <Chain
                    address={getToAddress()}
                    chain={CHAIN[chainId].name}
                    img={CHAIN[chainId].img}
                    prefix="To"
                  />
                </ChainsWrap>
                <TokenField
                  title="Amount"
                  value={transferQuantity}
                  onChange={handleRbwChange}
                  available={availableBalance}
                  showMaxButton
                  error={amountError}
                  handleSetMax={handleSetMax}
                  disabled={!walletConnected}
                  token={TOKEN[tokenId]}
                />

                <RecipientInput
                  onChange={handleRecipientChange}
                  onError={setAddressError}
                  placeholder="Enter recipient address"
                  setIsInputVisible={setIsInputVisible}
                />
                <TransferDetails
                  transferQuantity={transferQuantity * 10 ** 18}
                  estimatedTime={estimatedTime}
                  totalBalance={totalConvertedToken}
                  gasFees={gasFees}
                  error={maticError}
                  token={TOKEN[tokenId]}
                />
                <div className="p-0">
                  <Button
                    onClick={handleSubmit}
                    block
                    disabled={walletConnected ? disable : false}
                    className="p-0">
                    {error && "Try Again"}
                    {isLoading || error
                      ? ""
                      : walletConnected
                      ? "Send"
                      : "Connect Wallet"}
                    {isLoading && !error && (
                      <SpinnerWrap>
                        <Spinner color="white" />
                      </SpinnerWrap>
                    )}
                  </Button>
                  {smartWalletError && (
                    <ErrorMessage
                      variant="text2xs"
                      md={{ variant: "textXs" }}
                      $error={smartWalletError}
                      className="text-center">
                      Smart wallet addresses are different between chains.
                      Please specify the address you would like to send tokens
                      to before proceeding.
                    </ErrorMessage>
                  )}
                </div>
              </RowWrap>
            </SectionCardWrap>
          </ContainerWrap>
          <BridgeModal
            showModal={showModal}
            toggleModal={toggleModal}
            estimatedTime={estimatedTime}
            toAddress={recipientAddress ? recipientAddress : walletAddress}
            tokenId={tokenId}
            transferQuantity={(
              Number(transferQuantity) *
              10 ** 18
            ).toLocaleString("fullwide", {
              useGrouping: false,
            })}
            sendFee={sendFee}
            token={TOKEN[tokenId]}
          />
        </RainbowBackground>
      </PurpleGradientWrapper>
      <Footer />
    </>
  );
}

const ChainWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  gap: 12px;
  padding: 12px 15px;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background: rgba(62, 15, 148, 0.4);
  @media (${device.md}) {
    width: 50%;
  }
`;

const ChainInfo = styled.div`
  flex-grow: 1;
`;
const ChainImg = styled.img`
  width: 44px;
  height: 44px;
`;

const Chain = ({ address, chain, img, prefix }) => {
  const chainString = chain.charAt(0).toUpperCase() + chain.slice(1);

  return (
    <ChainWrap>
      <ChainImg src={img} />
      <ChainInfo>
        <Typography tag="span" color="#C9ABFF" variant="textMd">
          {prefix}
        </Typography>
        <Typography tag="h3" family="display" variant="textXl">
          {chainString}
        </Typography>
        {address && (
          <Typography tag="p" color="#C9ABFF" variant="textMd">
            {formatWalletAddress(address)}
          </Typography>
        )}
      </ChainInfo>
    </ChainWrap>
  );
};
