import styled from "styled-components";
import { Portal } from "../common/Portal";
import { useRef, useState } from "react";
import { device } from "../../theme/mediaQuery";

const TooltipOverlay = styled.div`
  visibility: ${({ $show }) => ($show ? "visible" : "hidden")};
  background-color: ${({ $background }) => $background};
  text-align: center;
  border-radius: ${({ $border }) => ($border === "sm" ? "6px" : "16px")};
  padding: ${({ $isLarge }) => ($isLarge ? "12px" : "5px 10px")};
  position: fixed;
  z-index: 1;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 14px;
  font-weight: 600;
  max-width: 310px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media (${device.sm}) {
    max-width: unset;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ $background, $position }) =>
      $position === "top"
        ? `${$background} transparent transparent transparent`
        : `transparent transparent ${$background} transparent`};
    top: ${({ $position }) => ($position === "top" ? "100%" : "auto")};
    bottom: ${({ $position }) => ($position === "top" ? "auto" : "100%")};

    opacity: 0;
    @media (${device.sm}) {
      opacity: 1;
    }
  }
`;

const TooltipWrap = styled.div`
  display: inline-block;
  cursor: pointer;
  z-index: 999;
`;

export function Tooltip({
  children,
  overlay,
  position = "top",
  background = "white",
  border = "sm",
  large = false,
  ...rest
}) {
  const overlayRef = useRef(null);
  const [coords, setCoords] = useState({ left: 0, top: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  const mouseEnter = (e) => {
    if (!overlayRef?.current) return;
    // on mouse enter, get the coordinates of the element
    const rect = e.target.getBoundingClientRect();
    // get the coordinates of the tooltip
    const overlayRect = overlayRef?.current?.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    // coordinates of the center of the element
    let left = rect.left - overlayRect.width / 2 + rect.width / 2;
    let top;

    // Adjust left position if tooltip goes beyond the left or right edge
    if (left < 0) {
      left = 5;
    } else if (left + overlayRect.width > viewportWidth) {
      left = viewportWidth - overlayRect.width - 5;
    }

    // Adding a 10px gap to add some space between the tooltip and the element
    if (position === "top") {
      top = rect.top - overlayRect.height - 10;
    } else {
      top = rect.bottom + 10;
    }

    setCoords({
      left,
      top,
    });
    setShowTooltip(true);
  };

  const mouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <TooltipWrap onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} {...rest}>
      {children}
      <Portal>
        <TooltipOverlay
          ref={overlayRef}
          style={{ left: coords.left, top: coords.top }}
          $show={showTooltip}
          $position={position}
          $background={background}
          $border={border}
          $isLarge={large}
        >
          {overlay}
        </TooltipOverlay>
      </Portal>
    </TooltipWrap>
  );
}
