import rainbow_rumble_background from "../../img/rainbowRumble/rainbow-rumble.png";
import land_background from "../../img/rainbowRumble/land.png";
import shadowcorn_background from "../../img/rainbowRumble/shadowcorn.png";
import jousting_background from "../../img/rainbowRumble/joust.png";
import tribes_background from "../../img/rainbowRumble/tribes.png";
import unicorn_background from "../../img/rainbowRumble/unicorn-party.png";
import bumpercorns_background from "../../img/games/bumper-corns-background.png";
import mobrun_background from "../../img/games/mob-run-background.png";
import mobrun_qr from "../../img/games/mob-run-qr.png";
import bumpercorns_qr from "../../img/games/bumper-corns-qr.png";

import rbw from "../../img/currencies/rbw-tilted.png";
import { BUY, CRYPTO, WHITE_PAPER } from "../../consts/externalLinks";
import {
  EVENT_KEYS,
  PLAY_GAME,
  RAINBOW_RUMBLE,
} from "../../consts/googleEvents";
import { googleAnalyticsEvent } from "../../helpers/googleAnalytics";

export const RBW = {
  image: rbw,
  info: [
    {
      title: "About CU",
      description: [
        "$CU is the primary utility and governance token for Crypto Unicorns.",
        "Wager $CU tokens to win prizes in Rainbow Rumble tournaments.",
        "Use $CU in various games within the Crypto Unicorns ecosystem.",
        "Acquire $CU on decentralized or centralized exchanges, or directly in the game.",
      ],
    },
    {
      title: "where to purchase?",
      description: [
        "Purchase on decentralized exchange (Camelot), centralized exchange or in the core game.",
      ],
    },
  ],
  tags1: ["unicorn", "land"],
  tags2: ["desktop web browser"],
  primary: {
    title: "Buy CU",
    link: BUY.CU,
    analytics: () =>
      googleAnalyticsEvent(EVENT_KEYS.RAINBOW_RUMBLE, RAINBOW_RUMBLE.BUY_CU),
  },
  secondary: {
    title: "Collect CU",
    link: CRYPTO.EARN_CU,
    titleMobile: "Learn More",
    linkMobile: WHITE_PAPER.PLAY_AND_EARN,
  },
};

export const RR_CARD = {
  image: rainbow_rumble_background,
  title: "About Rainbow Rumble",
  description: [
    "Use your skill to survive the obstacle course.",
    "Deploy power-ups & tactics to gain the upper horn.",
    "Compete against up to 20 players in Rush Mode or Showdown Mode.",
    "Wager and collect valuable $CU tokens.",
    "Qualify ESports playoffs & Win leaderboard rewards, including $XAI, $CU and Lootboxes",
  ],
  tags1: ["1 x adult unicorn"],
  tags2: ["desktop web browser"],
  primary: {
    title: "Buy Adult Unicorn",
    link: BUY.MARKETPLACE.UNICORN_ADULT__UNLOCKED_READY_TO_BUY,
    analytics: () =>
      googleAnalyticsEvent(EVENT_KEYS.RAINBOW_RUMBLE, RAINBOW_RUMBLE.BUY_ADULT),
  },
  secondary: {
    title: "Play Now",
    link: CRYPTO.RAINBOW_RUMBLE,
    analytics: () =>
      googleAnalyticsEvent(EVENT_KEYS.PLAY_GAME, PLAY_GAME.RAINBOW_RUMBLE),
  },
};

export const LAND_CARD = {
  image: land_background,
  title: "Land Gameplay",
  description: [
    "Utilize Unicorns for building, farming, breeding, and quests on digital land plots.",
    "Protect your economy from malicious Shadowcorn invaders.",
    "Collect $CU, $UNIM, and other NFTs while playing.",
  ],
  tags1: ["Unicorns", "Land"],
  tags2: ["Desktop web browser"],
  primary: {
    title: "Buy Land",
    link: BUY.MARKETPLACE.UNICORN_LAND_UNLOCKED,
  },
  secondary: {
    title: "Land Gameplay",
    link: CRYPTO.LAND_GAME,
    titleMobile: "Learn More",
    linkMobile: WHITE_PAPER.LAND_WELCOME,
  },
};

export const JOUSTING_CARD = {
  image: jousting_background,
  title: "Jousting",
  description: [
    "Send your strongest unicorns to battle in the arena.",
    "Craft special potions to enhance your unicorn's stats.",
    "Collect $CU, lootboxes, and in-game rewards.",
  ],
  tags1: ["1 x adult unicorn"],
  tags2: ["Desktop web browser"],
  primary: {
    title: "Buy Adult Unicorn",
    link: BUY.MARKETPLACE.UNICORN_ADULT__UNLOCKED_ELO,
  },
  secondary: {
    title: "Play Jousting",
    link: CRYPTO.JOUSTING,
    titleMobile: "Learn More",
    linkMobile: WHITE_PAPER.JOUSTING_WELCOME,
  },
};

export const SC_CARD = {
  image: shadowcorn_background,
  title: "Shadowcorn",
  description: [
    "Limited to 3,000 total Shadowcorns.",
    "Passively raid land & steal resources from Unicorn holders.",
    "Use the Shadowforge to create a minion army.",
    "Deploy Shadowcorns and minions to battle unicorns and win more rewards.",
  ],
  tags1: ["Shadowcorns"],
  tags2: ["Desktop web browser"],
  primary: {
    title: "Buy Shadowcorns",
    link: BUY.MARKETPLACE.SHADOWCORN,
  },
  secondary: {
    title: "Enter Dark Forest",
    link: CRYPTO.SHADOWCORNS,
    titleMobile: "Learn More",
    linkMobile: WHITE_PAPER.SHADOWCORN__WELCOME,
  },
};

export const TRIBES_CARD = {
  image: tribes_background,
  title: "Tribes",
  description: [
    "Join a Tribe to compete in Tribe vs Tribe leaderboard events.",
    "Work together to upgrade Tribe buildings in preparation for Tribe PvP.",
    "Craft Gems to increase the stats of your unicorns.",
  ],
  tags1: ["Unicorns", "Land"],
  tags2: ["Desktop web browser"],
  secondary: {
    title: "Play Now",
    link: CRYPTO.LAND_GAME,
  },
  unreleased: false,
};

export const UP_CARD = {
  image: unicorn_background,
  title: "Unicorn Party",
  description: [
    "Enjoy a variety of fun and timeless PvP mini-games on iOS and Android.",
    "Choose from games like Bingo, Trivia, Solitaire, and more!",
    "Wager and collect $CU or play for free.",
  ],
  // tags1: ["1 x adult unicorn"],
  tags2: ["IOS /android"],
  secondary: {
    title: "Enter Unicorn Party",
    link: "/unicornparty",
  },
  // mobileLinks: {
  //   ios: CRYPTO.BUMPERCORNS.IOS,
  //   android: CRYPTO.BUMPERCORNS.ANDROID,
  // },
};

export const BUMPERCORNS_CARD = {
  image: bumpercorns_background,
  title: "Bumpercorns",
  description: [
    "Bumpercorns is a fun Battle Royale game with the goal of bumping other Unicorns out of the arena and being the last one standing!",
  ],
  // title1: "Party stamp room",
  // subtitle1: "required NFTS",
  // tags1: ["1 x adult unicorn"],
  // title2: "Unishoe room",
  // subtitle2: "required NFTS",
  // tags2: ["No Requirements"],
  // secondary: {
  //   title: "Play on Web",
  //   link: CRYPTO.BUMPERCORNS.WEBGL,
  //   analytics: () =>
  //     googleAnalyticsEvent(EVENT_KEYS.PLAY_GAME, PLAY_GAME.BUMPERCORNS),
  // },
  mobileLinks: {
    ios: {
      link: CRYPTO.BUMPERCORNS.IOS,
      analytics: () =>
        googleAnalyticsEvent(EVENT_KEYS.PLAY_GAME, PLAY_GAME.BUMPERCORNS_IOS),
    },
    android: {
      link: CRYPTO.BUMPERCORNS.ANDROID,
      analytics: () =>
        googleAnalyticsEvent(
          EVENT_KEYS.PLAY_GAME,
          PLAY_GAME.BUMPERCORNS_ANDROID
        ),
    },
    apk: {
      link: CRYPTO.BUMPERCORNS.APK,
      qr: bumpercorns_qr,
      event: PLAY_GAME.BUMPERCORNS_APK,
    },
  },
};

export const MOBRUN_CARD = {
  image: mobrun_background,
  title: "Mobrun",
  description: [
    "Mob Run is a fast-paced “.io” game, victory goes to the Unicorn with the biggest group of gummy followers at the end of the round!",
  ],
  // title1: "Party stamp room",
  // subtitle1: "required NFTS",
  // tags1: ["1 x adult unicorn"],
  // title2: "Unishoe room",
  // subtitle2: "required NFTS",
  // tags2: ["No Requirements"],
  // secondary: {
  //   title: "Play on Web",
  //   link: CRYPTO.MOB_RUN.WEBGL,
  //   analytics: () =>
  //     googleAnalyticsEvent(EVENT_KEYS.PLAY_GAME, PLAY_GAME.MOB_RUN),
  // },
  mobileLinks: {
    ios: {
      link: CRYPTO.MOB_RUN.IOS,
      analytics: () =>
        googleAnalyticsEvent(EVENT_KEYS.PLAY_GAME, PLAY_GAME.MOB_RUN_IOS),
    },
    android: {
      link: CRYPTO.MOB_RUN.ANDROID,
      analytics: () =>
        googleAnalyticsEvent(EVENT_KEYS.PLAY_GAME, PLAY_GAME.MOB_RUN_ANDROID),
    },
    apk: {
      link: CRYPTO.MOB_RUN.APK,
      qr: mobrun_qr,
      event: PLAY_GAME.MOB_RUN_APK,
    },
  },
};

export const GAMES = [
  LAND_CARD,
  JOUSTING_CARD,
  SC_CARD,
  // UP_CARD,
  TRIBES_CARD,
];
