import styled from "styled-components";
import { device } from "../../theme/mediaQuery";

const Wrap = styled.div`
  background: linear-gradient(90deg,
  #ffc600 0%,
  #ff005b 26.5%,
  #0090ff 74%,
  #00ffdb 99%);
  padding: 4px;
  border-radius: 16px;
`;

const TitleWrap = styled.div`
  text-align: center;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-family: ${({ theme }) => theme.fonts.display};
  color: ${({ theme }) => theme.palette.white};
  padding: 4px 12px 0;
  line-height: 24px;
  @media (${device.sm}) {
    font-size: 14px;
  }
`;

export function StakingRainbowBorder({ headerTitle, children, footerTitle }) {
  return (
    <Wrap>
      {headerTitle && (
        <TitleWrap>
          {headerTitle}
        </TitleWrap>
      )}
      {children}
      {footerTitle && (
        <TitleWrap>
          {footerTitle}
        </TitleWrap>
      )}
    </Wrap>
  );
}
