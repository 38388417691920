import { useEffect, useRef } from "react";

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    // console.log("first useEffect: ", title);
    // document.title = "Crypto Unicorns" + title ? ` • ${title}` : "";
    // let _title = `Crypto Unicorns${title ? ` • ${title}` : ""}`;
    // console.log("_title: ", _title);
    document.title = `Crypto Unicorns${title ? ` • ${title}` : ""}`;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        // console.log("prevailOnUnmount", prevailOnUnmount);
        // document.title = defaultTitle.current;
        document.title = defaultTitle.current;
      }
    },
    [prevailOnUnmount]
  );
}

export default useDocumentTitle;