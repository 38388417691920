import React from "react";
import styled from "styled-components";
import { Typography } from "../base/Typography";
import { Container } from "../base/Container";
import { device } from "../../theme/mediaQuery";
import { SectionWrap as Section } from "./Hero";

import ribbonLeft from "../../img/licensing/ribbon-left.svg";
import unicorn from "../../img/licensing/award.png";
import clouds from "../../img/home/small-clouds.png";
import cuLogo from "../../img/logo.png";
import yahoo from "../../img/logos/yahoo.png";

const SectionWrap = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media (${device.xl}) {
    gap: 60px;
  }
`;

const RainbowBorder = styled.div`
  background: linear-gradient(
    90deg,
    #ffc600 0%,
    #ff005b 26.5%,
    #0090ff 74%,
    #00ffdb 99%
  );
  padding: 4px;
  width: 100%;
  border-radius: 16px;
  position: relative;
`;

const BoxWrap = styled.div`
  background: linear-gradient(180deg, #7dc9ea 0%, #4656f7 100%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  padding: 56px 32px 0 32px;
  @media (${device.md}) {
    padding-bottom: 20px;
  }
  @media (${device.lg}) {
    padding: 80px 60px 57px 60px;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  @media (${device.md}) {
    margin-left: auto;
    gap: 25px;
    width: 60%;
  }
  @media (${device.lg}) {
    width: 50%;
  }

  & > h3 {
    @media (${device.md}) {
      width: 454px;
    }
  }

  & > * {
    text-align: center;
  }
  & > p img {
    margin: 0 8px;
  }
`;

const AwardLogo = styled.img`
  height: 18px;
  width: auto;
  @media (${device.xl}) {
    height: 22px;
  }
`;

const Image = styled.img`
  user-select: none;
  height: auto;
  position: absolute;
`;

const LogoWrap = styled(Image)`
  z-index: 1;
  height: auto;
  transform: translateX(50%);
  width: 160px;
  top: -50px;
  right: 50%;
  @media (${device.sm}) {
    width: 200px;
    top: -70px;
    right: 50%;
  }
  @media (${device.md}) {
    right: calc(25% + 30px);
  }
  @media (${device.lg}) {
    top: -100px;
    width: 264px;
  }
`;

const UnicornAward = styled(Image)`
  display: none;
  z-index: 1;
  bottom: 0;
  left: 32px;
  @media (${device.md}) {
    display: block;
    left: 0;
    width: 250px;
  }
  @media (${device.lg}) {
    left: 30px;
    width: 350px;
  }
  @media (${device.xl}) {
    left: 60px;
    width: 400px;
  }

  @media (${device.xxl}) {
    width: 470px;
  }
`;

const UnicornMobile = styled(Image)`
  position: static;
  z-index: 1;
  max-width: 312px;
  @media (${device.md}) {
    display: none;
  }
`;

const Clouds = styled(Image)`
  width: 120%;
  bottom: -50px;
  @media (${device.sm}) {
    width: 120%;
    bottom: -72px;
  }
  @media (${device.md}) {
    width: 450px;
    left: -121px;
    bottom: -90px;
  }
  @media (${device.lg}) {
    width: 650px;
  }
  @media (${device.xl}) {
    width: 792px;
    left: -121px;
    bottom: -90px;
  }
`;

export function GameAwards() {
  return (
    <Container>
      <SectionWrap>
        <RainbowBorder>
          <UnicornAward src={unicorn} alt="unicorn award" />
          <LogoWrap src={cuLogo} alt="crypto unicorn logo" />
          <BoxWrap>
            <Clouds src={clouds} alt="clouds" />
            <ContentWrap>
              {/* <Banner /> */}
              <Typography
                tag={"h3"}
                variant={"displayXs"}
                md={{ variant: "displaySm" }}
                family="display"
              >
                {`”I’ve become increasingly obsessed with my crypto unicorns“`}
              </Typography>
              <Typography
                tag={"p"}
                variant={"textSm"}
                md={{ variant: "textMd" }}
              >
                by
                <AwardLogo src={yahoo} alt="Yahoo!" />
                January 2023
              </Typography>
              <UnicornMobile src={unicorn} alt="unicorn award" />
            </ContentWrap>
          </BoxWrap>
        </RainbowBorder>
      </SectionWrap>
    </Container>
  );
}

const BannerWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const BannerBody = styled.div`
  background: #d54344;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 6px 10px;
  position: relative;
  z-index: 2;

  @media (${device.sm}) {
    padding: 6px 27px;
  }
  & p {
    line-height: 100%;
  }
  & span {
    margin-bottom: 6px;
    line-height: 100%;
  }
`;

const RibbonSide = styled(Image)`
  z-index: 1;
  width: 50px;
  top: 51%;
  @media (${device.md}) {
    width: 60px;
    top: 48.5%;
  }
  @media (${device.lg}) {
    width: 90px;
    top: 22%;
  }
  @media (${device.xl}) {
    width: 120px;
    top: 8%;
  }
  @media (${device.xxl}) {
    top: 20%;
  }
`;

const RibbonLeftSide = styled(RibbonSide)`
  left: 15px;
  @media (${device.sm}) {
    left: -30px;
  }
  @media (${device.md}) {
    left: -1px;
  }
  @media (${device.lg}) {
    left: -5px;
  }
  @media (${device.xl}) {
    left: -3px;
  }
  @media (${device.xxl}) {
    left: 5px;
  }
`;

const RibbonRightSide = styled(RibbonSide)`
  right: 15px;
  @media (${device.sm}) {
    right: -30px;
  }
  @media (${device.md}) {
    right: -1px;
  }
  @media (${device.lg}) {
    right: -5px;
  }
  @media (${device.xl}) {
    right: -3px;
  }
  @media (${device.xxl}) {
    right: 5px;
  }
  transform: scaleX(-1);
`;

export const Banner = () => {
  return (
    <BannerWrap>
      <BannerBody>
        <Typography
          tag={"p"}
          variant={"displayXs"}
          sm={{ variant: "displaySm" }}
          xl={{ variant: "displayMd" }}
          xxl={{ variant: "displayLg" }}
          textshadow="hard"
          family="display"
        >
          game of the year
        </Typography>
        <Typography
          tag={"span"}
          weight={400}
          variant={"textXs"}
          sm={{ variant: "textSm" }}
          xl={{ variant: "textMd" }}
          xxl={{ variant: "textLg" }}
          family="display"
        >
          2022
        </Typography>
      </BannerBody>
      <RibbonLeftSide src={ribbonLeft} alt="Left Ribbon" />
      <RibbonRightSide src={ribbonLeft} alt="Right Ribbon" />
    </BannerWrap>
  );
};
