import { useState, useEffect } from "react";
import { size } from "../theme/mediaQuery";

const isLargeScreen = () => window.innerWidth > size.xxl;
export function useIsLarge() {
  const [isLarge, setIsLarge] = useState(isLargeScreen());

  useEffect(() => {
    const handleResize = () => {
      setIsLarge(isLargeScreen());
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isLarge;
}
