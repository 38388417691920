import styled from "styled-components";
import { Typography } from "../base/Typography";
import { ReactComponent as LeftBanner } from "../../img/rainbowRumble/banner.svg";
import { device } from "../../theme/mediaQuery";

const BannerTitleWrap = styled.div`
  @media (${device.lg}) {
    padding: 90px 0;
  }
  @media (${device.xxl}) {
    padding: 126px 0;
  }
`;

const BannerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 56px;
  margin: 0 auto;
  padding: 0 12px;
  max-width: 1500px;
  z-index: 1;
`;

const BannerSVG = styled(LeftBanner)`
  display: none;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  width: 100%;
  margin-top: 20px;

  &.right {
    transform: scaleX(-1); // flip the right banner
  }

  @media (${device.lg}) {
    display: block;
  }
`;

const TitleTypography = styled(Typography)`
  text-align: center;
  margin: 0 16px;
  @media (${device.lg}) {
    flex-shrink: 0;
    white-space: nowrap;
  }

  & .break-after {
    font-size: inherit;
    font-family: inherit;
    &::after {
      content: "";
      white-space: pre;
      display: block;
      @media (${device.sm}) {
        display: unset;
      }
    }
  }
`;

export function BannerTitle({ title }) {
  return (
    <BannerTitleWrap>
      <BannerWrap>
        <BannerSVG preserveAspectRatio="none" className="left" />
        <TitleTypography
          variant="displayXs"
          md={{
            variant: "displayMd",
          }}
          xxl={{
            variant: "displayXl",
          }}
          weight={700}
          color="white"
          tag="h5"
        >
          {title}
        </TitleTypography>
        <BannerSVG preserveAspectRatio="none" className="right" />
      </BannerWrap>
    </BannerTitleWrap>
  );
}
