import styled from "styled-components";
import React from "react";
import { HeroCtaCard } from "./HeroCtaCard";
import { Container } from "../../base/Container";
import { Row } from "../../base/Row";
import { Col } from "../../base/Col";
import { scrollAtom, walletConnectedAtom } from "../../../store";
import { useAtomValue } from "jotai/index";
import cloud_platform from "../../../img/home/cloud-platform.png";
import book from "../../../img/book.png";
import griffin_unicorn from "../../../img/griffin-unicorn.png";
import land_crystal from "../../../img/land-crystal.png";
import { device } from "../../../theme/mediaQuery";
import { useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { WHITE_PAPER } from "../../../consts/externalLinks";
import { EVENT_KEYS } from "../../../consts/googleEvents";
import { googleAnalyticsEvent } from "../../../helpers/googleAnalytics";
import { useConnectModal } from "thirdweb/react";
import { connectWallet } from "../../ConnectWallet/connectWallet";

const HomeHeroCtaWrap = styled.div`
  position: relative;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    height: 120px;
    bottom: 0;
    left: -4%;
    right: -4%;
    background-image: url(${cloud_platform});
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateY(60%);
    display: none;

    @media (${device.xl}) {
      display: block;
    }
  }
`;

export function HomeHeroCta({ scrollToGame }) {
  const walletConnected = useAtomValue(walletConnectedAtom);
  const { connect } = useConnectModal();

  const xlColSize = walletConnected ? 6 : 4;
  const setScroll = useSetAtom(scrollAtom);
  const navigate = useNavigate();

  const handleConnect = () => {
    connectWallet(connect).then((wallets) => {
      googleAnalyticsEvent(EVENT_KEYS.CONNECT_CLICKED);

      // check if wallet is connected
      if (wallets.length > 0) {
        //redirect to claim page
        navigate("/claim");
        // scroll to top after redirect
        window.scrollTo(0, 0);
      }
    });
  };
  return (
    <Container>
      <HomeHeroCtaWrap>
        <Row>
          {!walletConnected && (
            <Col xs={12} xl={4} className="mb-3 mb-xl-0">
              <HeroCtaCard
                title="Did you win an NFT?"
                subtitle="Connect your wallet to see if you're a lucky winner!"
                actionTitle="Connect Wallet"
                img={land_crystal}
                color="redishOrange300"
                onActionClick={handleConnect}
              />
            </Col>
          )}
          <Col xs={12} xl={xlColSize} className="mb-3 mb-xl-0">
            <HeroCtaCard
              title="Beginner's guide"
              subtitle="Discover the secrets in Crypto Unicorns: Your Guide to Magical Mastery!"
              actionTitle="learn how to play"
              img={book}
              color="mintGreen300"
              dense={walletConnected}
              link={WHITE_PAPER.WELCOME}
            />
          </Col>
          <Col xs={12} xl={xlColSize} className="mb-3 mb-xl-0">
            <HeroCtaCard
              title="play our games"
              subtitle="Start your journey filled with rainbows, unicorns and everything nice!"
              actionTitle="view all our games"
              img={griffin_unicorn}
              color="carnationPink200"
              onActionClick={() => setScroll({ scroll: true, activePage: 0 })}
              dense={walletConnected}
            />
          </Col>
        </Row>
      </HomeHeroCtaWrap>
    </Container>
  );
}
