import { useState } from "react";

export function FilterTokens({
  data,
  initialValue = {},
  getters = {}
}) {
  const [filters, setFilters] = useState(initialValue);

  // Add or update a filter
  const handleFiltersChange = (payload) => {
    const { name, value } = payload;
    setFilters((prev) => ({ ...prev, [name]: value }));
  }

  // Remove a filter
  const handleRemoveFilter = (name) => {
    setFilters((prev) => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  }

  // Remove a filter option from array
  const handleRemoveFilterOption = (name, value) => {
    setFilters((prev) => {
      const { [name]: prevValue, ...rest } = prev;
      const newValue = prevValue.filter((v) => v !== value);

      // If there are no more options, remove the filter
      if (newValue.length === 0) {
        return rest;
      } else {
        return { ...rest, [name]: newValue };
      }
    });
  }

  // Check if an item matches a filter
  const checkFilter = (item, key) => {
    let _filterValue = filters[key];
    // If the filter value is null, return the item
    if (_filterValue === null) {
      return true;
    }

    let _getter = getters[key];
    let _value = _getter ? _getter(item) : item[key];

    // If the value is an array, check if the item's attribute is in the array
    if (Array.isArray(_filterValue)) {
      return _filterValue.includes(_value);
    }

    // If the value is not an array, check if the item's attribute is equal to the value
    return _value === _filterValue;
  }

  // Filter the data
  let _filteredData = data.filter((item) => {

    // If there are no filters, return the item
    if (Object.keys(filters).length === 0) {
      return true;
    }
    // If there are filters, check if the item matches all of them
    let _filterKeys = Object.keys(filters);
    return _filterKeys.every((key) => checkFilter(item, key));
  });

  // get filter value by key
  const getFilterValue = (key) => {
    return filters[key];
  }

  return {
    data: _filteredData,
    filters,
    handleFiltersChange,
    handleRemoveFilter,
    handleRemoveFilterOption,
    getFilterValue
  }
}