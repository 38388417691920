import styled from "styled-components";
import { toast } from "react-toastify";
import { Icon } from "../base/Icon";
import { Typography } from "../base/Typography";
import { device } from "../../theme/mediaQuery";
import { formatWalletAddress, timeAgo } from "../Helpers";
import { useEffect, useState } from "react";
import { pollMessagesForDelivery } from "./bridgeListener";
import { useAtomValue, useSetAtom } from "jotai";
import { persistedPendingTransactionsAtom } from "../../store/pending";
import { BridgeConfirmNotification } from "./BridgeConfirmNotification";
import { Tooltip } from "../base/Tooltip";
import { NetworkConfiguration, isTestnet } from "../Network";
import ERC20_ABI from "../../CU_ERC20_ABI.json";
import { getCUContract } from "./contracts";
import { signerAtom, tokensAtom } from "../../store";
import { invalidateGetWalletTokens } from "../../query/tokens";
import { CHAIN_NAMES, TOKEN, CHAIN } from "../../views/Bridge";
import { useActiveAccount } from "thirdweb/react";
import { ethers } from "ethers";

const Wrap = styled.div`
  background: #672ca9;
  flex-direction: column;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  padding: 10px;

  @media (min-width: 400px) {
    flex-direction: row;
  }
  @media (${device.sm}) {
    gap: 12px;
    align-items: center;
  }
`;

const IconWrap = styled.div`
  min-width: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ff9a01;
  color: ${({ theme }) => theme.palette.white};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (${device.sm}) {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }

  & div {
    width: 12px;

    @media (${device.sm}) {
      width: 30px;
    }
  }
`;

const BodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const TopPartWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const WalletWrap = styled.a`
  color: ${({ theme }) => theme.palette.white};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #c9abff;
  }

  & div {
    &:hover {
      color: #c9abff;
    }
  }
`;

const BottomPartWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 7px;
  color: ${({ theme }) => theme.palette.white};
  flex-direction: column;
  margin-top: 5px;

  @media (${device.sm}) {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
`;

const IconImageWrap = styled.img`
  width: 20px;
  height: 20px;
`;

const AmountWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  word-break: break-all;
`;

const ChainsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ChainWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export function BridgePendingTransactionItem({
  txHash,
  timestamp,
  amount,
  tokenId,
}) {
  const netconfig = NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];
  // const [{ wallet }] = useConnectWallet();
  const wallet = useAtomValue(signerAtom);
  const account = useActiveAccount();
  const formattedHash = formatWalletAddress(txHash);
  const setPendingTransactions = useSetAtom(persistedPendingTransactionsAtom);
  const setTokens = useSetAtom(tokensAtom);
  const [transactionCompletedHash, setTransactionCompletedHash] =
    useState(null);

  const token = TOKEN[tokenId];
  const chainId = TOKEN[tokenId].chainId;
  const chain = CHAIN[chainId];

  const timeAgoString = timeAgo(timestamp);

  const scanLink = isTestnet
    ? `${token.scanLinks.TESTNET}/${txHash}`
    : `${token.scanLinks.MAINNET}/${txHash}`;

  const removeTransactionByTxHash = (txHash) => {
    setPendingTransactions((prev) => ({
      ...prev,
      transactions: prev.transactions.filter(
        (transaction) => transaction.txHash !== txHash
      ),
    }));
  };

  const contract = async (address, abi, wallet) => {
    let _contract = await new ethers.Contract(address, abi, wallet);
    return _contract;
  };

  const refreshTokens = async () => {
    const rbwAddr = netconfig.RBWContractAddress;

    const CUContract = await getCUContract();
    const RBWContract = await contract(rbwAddr, ERC20_ABI, wallet);
    const _RBWBalance = await RBWContract.balanceOf(account.address);

    const _CUBalance = await CUContract.balanceOf(account?.address);
    setTokens((prev) => ({
      ...prev,
      RBW: parseFloat(
        (_RBWBalance / Math.pow(10, 18)).toLocaleString("en", {
          maximumFractionDigits: 2,
        })
      ),
      CU: parseFloat(
        (_CUBalance / Math.pow(10, 18)).toLocaleString("en", {
          maximumFractionDigits: 2,
        })
      ),
    }));
  };

  useEffect(() => {
    toast(
      <BridgeConfirmNotification
        amount={Number(amount / token.ratio).toLocaleString("en", {
          maximumFractionDigits: 2,
        })}
        chain={chain.name}
        convertedToken={token.convertedToken}
        txHash={txHash}
        dstTxHash={transactionCompletedHash}
        links={token.scanLinks}
        isInflight
      />,
      {
        toastId: txHash,
        autoClose: false,
      }
    );
    async function checkDelivery() {
      const hash = await pollMessagesForDelivery(txHash, wallet);
      if (hash) {
        setTransactionCompletedHash(hash);
        invalidateGetWalletTokens(account.address);
      }
    }

    if (txHash && wallet) {
      checkDelivery();
      // mockCheckDelivery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txHash, wallet]);

  useEffect(() => {
    if (transactionCompletedHash) {
      // remove from pending transactions
      removeTransactionByTxHash(txHash);
      //here refresh CU and RBW
      refreshTokens();
      // show toast only if it's not already being shown
      if (!toast.isActive(txHash)) {
        toast(
          <BridgeConfirmNotification
            amount={Number(amount / token.ratio).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
            chain={chain.name}
            convertedToken={token.convertedToken}
            txHash={txHash}
            dstTxHash={transactionCompletedHash}
            links={token.scanLinks}
            isInflight={false}
          />,
          {
            toastId: txHash,
            autoClose: false,
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionCompletedHash]);

  return (
    <Wrap>
      <IconWrap>
        <Icon name="pending" />
      </IconWrap>
      <BodyWrap>
        <TopPartWrap>
          <WalletWrap href={scanLink} target="_blank" rel="noreferrer">
            <Typography variant="textSm">{formattedHash}</Typography>
          </WalletWrap>
          <Typography variant="textXs">{timeAgoString}</Typography>
        </TopPartWrap>
        <BottomPartWrap>
          <ChainsWrap>
            <Chain
              title={CHAIN[CHAIN_NAMES.POLYGON].name}
              image={CHAIN[CHAIN_NAMES.POLYGON].img}
            />
            <Icon name="line-arrow-right" size="13px" />
            <Chain title={chain.name} image={chain.img} />
          </ChainsWrap>
          <Tooltip
            overlay={`${Number(amount).toLocaleString("en", {
              maximumFractionDigits: 2,
            })} ${token.name} bridged to ${
              chain.name
            } and is converted to ${Number(amount / token.ratio).toLocaleString(
              "en",
              {
                maximumFractionDigits: 2,
              }
            )} ${token.convertedToken} `}>
            <AmountWrap>
              <IconImageWrap src={token.img} alt={token.name} />
              <Typography variant="textSm">
                {Number(amount / token.ratio).toLocaleString("en", {
                  maximumFractionDigits: 2,
                })}{" "}
                {token.convertedToken}
              </Typography>
            </AmountWrap>
          </Tooltip>
        </BottomPartWrap>
      </BodyWrap>
    </Wrap>
  );
}

function Chain({ title, image }) {
  return (
    <ChainWrap>
      <IconImageWrap src={image} alt={title} />
      <Typography variant="textXs">{title}</Typography>
    </ChainWrap>
  );
}
