import { useAtom, useSetAtom } from "jotai";
import {
  genericErrorModalAtom,
  rewardErrorModalAtom,
  rewardNotificationModalAtom,
} from "../../../store/staking";
import { useClaimRewards } from "../../../query/vault";
import { Button } from "../../base/Button";
import { REWARDS_CLAIMABLE_AFTER, StakingTokenTypes } from "../constants";

import { useActiveWalletChain } from "thirdweb/react";

export function StakingRewardClaimableButton({
  token,
  reward,
  deposits,
  disabled,
  switchNetwork,
  chain,
}) {
  const [, setRewardsNotificationModal] = useAtom(rewardNotificationModalAtom);
  const setErrorModal = useSetAtom(rewardErrorModalAtom);
  const setGenericErrorModal = useSetAtom(genericErrorModalAtom);
  const claimMutation = useClaimRewards(token);
  const chainId = useActiveWalletChain();
  const wrongChain = chainId?.id !== chain?.id;
  // check if every deposit is not expired
  const allDepositsAreActive = deposits?.every((deposit) => {
    const end = parseInt(deposit.end);
    const nowInSeconds = Date.now() / 1000;
    return end > nowInSeconds;
  });

  const handleClaim = async () => {
    // if any deposit is expired, show error modal
    if (!allDepositsAreActive) {
      setErrorModal({
        show: true,
      });
    } else {
      if (chain.id !== chainId.id) {
        await switchNetwork(chain);
      }
      claimMutation.mutate(null, {
        onSuccess: (result) => {
          const claimableAt = Date.now() + REWARDS_CLAIMABLE_AFTER;
          const transactionHash = result?.transactionHash;
          const existingRewards = reward.claimable.filter(
            (claimableToken) =>
              claimableToken.exists &&
              claimableToken.token !== StakingTokenTypes.xai
          );
          const transactions = existingRewards.map((claimableToken) => ({
            hash: transactionHash,
            amount: claimableToken.amount,
            token: claimableToken.token,
            claimableAt,
          }));
          setRewardsNotificationModal({
            show: true,
            transactions: transactions,
          });
        },
        onError: () => {
          setGenericErrorModal({ show: true });
        },
      });
    }
  };

  return (
    <Button
      size="lg"
      onClick={() => (wrongChain ? switchNetwork(chain) : handleClaim())}
      disabled={wrongChain ? false : claimMutation.isPending || disabled}
      isLoading={claimMutation.isPending}
      variant={wrongChain ? "primary" : "secondary"}>
      {wrongChain ? "Switch Network" : "Initiate Claim"}
    </Button>
  );
}
