import styled from "styled-components";
import { Button } from "../../base/Button";
import { Link } from "react-router-dom";
import { device } from "../../../theme/mediaQuery";

const Wrap = styled.div`
  height: 100%;
  background: #3c1568;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  align-items: flex-start;
`;

const WrapHorizontal = styled(Wrap)`
  gap: 30px;

  @media (${device.lg}) {
    flex-direction: row;
  }
`;

const Title = styled.h4`
  font-size: 28px;
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 700;
  color: #f5b53d;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.palette.white};
  font-size: 16px;
`;

const ButtonWrap = styled.div`
  margin-top: auto;
  @media (${device.md}) {
    & * {
      font-size: 16px;
      white-space: nowrap;
    }
  }
`;

export function StakingOverviewLinkCard({
  horizontal = false,
  title,
  description,
  buttonLabel,
  buttonLink = null,
  onClick,
}) {
  if (horizontal) {
    return (
      <WrapHorizontal>
        <div>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </div>
        <ButtonWrap>
          {buttonLink ? (
            <Button
              as={Link}
              to={buttonLink}
              target={buttonLink.includes("https") ? "_blank" : null}
              rel="noopener noreferrer"
              variant="secondary"
              size="sm"
            >
              {buttonLabel}
            </Button>
          ) : (
            <Button onClick={onClick} variant="secondary" size="sm">
              {buttonLabel}
            </Button>
          )}
        </ButtonWrap>
      </WrapHorizontal>
    );
  }

  return (
    <Wrap>
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </div>
      <ButtonWrap>
        {buttonLink ? (
          <Button
            as={Link}
            to={buttonLink}
            target={buttonLink.includes("https") ? "_blank" : null}
            rel="noopener noreferrer"
            variant="primary"
          >
            {buttonLabel}
          </Button>
        ) : (
          <Button onClick={onClick} variant="primary" size="sm">
            {buttonLabel}
          </Button>
        )}
      </ButtonWrap>
    </Wrap>
  );
}
