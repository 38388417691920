import React from "react";
import { Typography } from "../../base/Typography";
import styled from "styled-components";
import { Container } from "../../base/Container";
import { Row } from "../../base/Row";
import { Col } from "../../base/Col";
import { HeroVideoCarousel } from "./HeroVideoCarousel";
import { device } from "../../../theme/mediaQuery";

const HeroWrap = styled.section`
  padding: 30px 0;

  @media (${device.xl}) {
    padding: 70px 0;
  }
`;

const HeroTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: 100%;
  margin-bottom: 40px;
  text-align: center;

  @media (${device.xl}) {
    text-align: left;
    margin-bottom: 0;
  }
`;

export function HomeHero() {
  return (
    <Container>
      <HeroWrap>
        <Row>
          <Col
            xl={{ span: 6, order: 1 }}
            xs={{ span: 12, order: 2 }}
            className={"mt-5 mt-xl-0"}
          >
            <HeroTextWrap>
              <Typography
                tag={"h2"}
                variant={"displayXs"}
                md={{ variant: "displayXs" }}
              >
                Step into the exciting multiverse of
              </Typography>
              <Typography
                tag={"h1"}
                variant={"displayMd"}
                md={{ variant: "display2xl" }}
              >
                Crypto Unicorns
              </Typography>
              <Typography
                tag={"p"}
                variant={"textSm"}
                weight={600}
                md={{ variant: "textLg" }}
              >
                Harvest and craft magical items to level up your buildings and
                prepare for the impending darkness. Showcase the might of your
                Unicorns in thrilling PvP competitions and engaging mini-games.
                Unite with your tribe to conquer epic team quests and win
                lucrative rewards. Delight in captivating mini games, and forge
                powerful items to enrich your adventure.
              </Typography>
              <Typography
                tag={"p"}
                variant={"textSm"}
                weight={600}
                md={{ variant: "textLg" }}
              >
                Dive into a multifaceted world of strategy, creativity, and
                community - embark on your unique adventure in Crypto Unicorns
                today!
              </Typography>
            </HeroTextWrap>
          </Col>
          <Col
            xl={{ span: 6, order: 2 }}
            xs={{ span: 12, order: 1 }}
            className={"ps-lg-5"}
            style={{ overflow: "visible" }}
          >
            <HeroVideoCarousel />
          </Col>
        </Row>
      </HeroWrap>
    </Container>
  );
}
