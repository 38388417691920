import styled from "styled-components";
import { device } from "../../theme/mediaQuery";
import { forwardRef } from "react";
import { Typography } from "../base/Typography";
import dots from "../../img/pattern/dots-reverse.png";

const Wrap = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  /* overflow: hidden; */
`;

const HeaderWrap = styled.div`
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  background: linear-gradient(180deg, #5252f2 0%, #3131b0 100%), #1c037f;
  /* box-shadow: 0px 12px 32px 0px rgba(183, 208, 225, 0.3); */

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${dots});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.55;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  @media (${device.lg}) {
    height: 75px;
  }
`;

const HeaderContent = styled.div`
  position: relative;
  z-index: 3;
  height: 100%;
`;

export function CommonCard({ children, ...rest }) {
  return <Wrap {...rest}>{children}</Wrap>;
}

export const CommonCardHeader = forwardRef(({ children, ...rest }, ref) => {
  return (
    <HeaderWrap ref={ref} {...rest}>
      <HeaderContent>{children}</HeaderContent>
    </HeaderWrap>
  );
});

export function CommonCardTitle({ children, ...rest }) {
  return (
    <Typography
      tag={"h3"}
      family={"display"}
      variant="textXl"
      lg={{
        variant: "displayXs",
      }}
      xl={{
        variant: "displaySm",
      }}
      textshadow="medium"
      {...rest}
    >
      {children}
    </Typography>
  );
}
