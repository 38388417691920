import { useAtom } from "jotai";
import { rewardNotificationModalAtom } from "../../../store/staking";
import { useWithdrawEscrowedRewards } from "../../../query/vault";
import { Button } from "../../base/Button";
import { useState } from "react";
import { getTimeLeftInHumanReadable } from "../../../helpers/getTimeLeftInHumanReadable";
import { CountdownTimer } from "../../common/CountdownTimer";
import {
  useActiveWalletChain,
  useSwitchActiveWalletChain,
} from "thirdweb/react";
import { defineChain } from "thirdweb";
import { NetworkConfiguration } from "../../Network";
import { POLYGON_NETWORK } from "../../ConnectWallet/WalletHelper";

export function StakingRewardsClaimEscrowedButton({ reward, ...rest }) {
  const { index, amount, token, end } = reward;
  const endInMs = end * 1000;
  const [isClaimable, setIsClaimable] = useState(Date.now() > endInMs);

  const [rewardsNotificationModal, setRewardsNotificationModal] = useAtom(
    rewardNotificationModalAtom
  );
  const withdrawMutation = useWithdrawEscrowedRewards(index, token);

  // if time left is less than an hour, show countdown timer instead of human readable time
  const timeLeftLessThanHour = endInMs - Date.now() < 3600000;
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const chainId = useActiveWalletChain();
  const arbChain = defineChain({
    id: netconfig.networkIdArbitrum,
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
  });

  const switchNetwork = useSwitchActiveWalletChain();
  const wrongChain = chainId?.id !== arbChain?.id;
  const disabled = withdrawMutation.isPending || !isClaimable;
  const handleClaim = () => {
    withdrawMutation.mutate(null, {
      onSuccess: (result) => {
        // set claimable to 7 days from now
        const transactionHash = result?.transactionHash;
        const transactionResult = {
          hash: transactionHash,
          amount: amount,
          token,
        };

        setRewardsNotificationModal({
          show: true,
          transactions: [transactionResult],
        });
      },
    });
  };

  return (
    <Button
      size="lg"
      onClick={() => (wrongChain ? switchNetwork(arbChain) : handleClaim())}
      disabled={wrongChain ? (disabled ? disabled : null) : disabled}
      block
      isLoading={withdrawMutation.isPending}
      variant="secondary"
      {...rest}>
      {wrongChain && "Switch Network"}
      {isClaimable && !wrongChain && "Claim Reward"}
      {!isClaimable && !wrongChain && (
        <>
          Claimable in{" "}
          {timeLeftLessThanHour ? (
            <CountdownTimer
              toDate={endInMs}
              onEnd={() => setIsClaimable(true)}
            />
          ) : (
            getTimeLeftInHumanReadable(endInMs / 1000, false)
          )}
        </>
      )}
    </Button>
  );
}
