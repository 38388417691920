import { Row } from "../base/Row";
import { Col } from "../base/Col";
import styled from "styled-components";
import { Typography } from "../base/Typography";
import { device } from "../../theme/mediaQuery";
import { Button } from "../base/Button";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../helpers/useIsMobile";

const CardWrap = styled(Row)`
  align-items: center;
  flex-direction: ${({ $reverse }) => ($reverse ? "row-reverse" : "row")};
  row-gap: 16px;
  @media (${device.sm}) {
    row-gap: 32px;
  }
`;
const ImageWrap = styled.div`
  width: 100%;
  position: relative;
  align-items: center;
  padding: 0;
  aspect-ratio: 1 / 1;
  max-width: 400px;
  @media (${device.lg}) {
    max-width: 625px;
  }
`;
const Image = styled.img`
  width: 100%;
  position: relative;
  z-index: 1;
`;
const ImageCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;
const DetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  height: 100%;
  gap: 24px;
  @media (${device.lg}) {
    padding-left: ${({ $reverse }) => ($reverse ? 0 : "36px")};
    padding-right: ${({ $reverse }) => ($reverse ? "36px" : 0)};
  }
  @media (${device.xxl}) {
    gap: 32px;
    padding-left: ${({ $reverse }) => ($reverse ? 0 : "52px")};
    padding-right: ${({ $reverse }) => ($reverse ? "52px" : 0)};
  }
`;

const DetailWrap = styled.div``;

const TitleWrap = styled(Typography)`
  font-size: 32px;
  @media (${device.md}) {
    font-size: 60px;
  }
  @media (${device.xxl}) {
    font-size: 65px;
  }
`;

const ListWrap = styled.ul`
  padding: 0 0 0 24px;
  @media (${device.md}) {
    padding: 0 0 0 40px;
  }

  & li {
    list-style-type: disc;
    list-style-position: outside;
    line-height: normal;

    @media (${device.lg}) {
      line-height: 32px;
    }
    @media (${device.xl}) {
      line-height: 45px;
    }
  }
`;

const FooterButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @media (min-width: 466px) {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
  }

  & > a {
    max-width: 458px;
    flex-grow: 1;
    @media (${device.sm}) {
      flex-grow: unset;
    }

    & * {
      font-size: 20px;
      @media (${device.lg}) {
        font-size: 24px;
      }
      @media (${device.xl}) {
        font-size: 30px;
      }
    }
  }
`;

export function InfoCard({
  image,
  primary,
  secondary,
  info = [],
  requiredTokens = [],
  platform = [],
  reverse = false,
}) {
  const isMobile = useIsMobile();

  const handleClick = (button) => {
    if (isMobile && button.analyticsMobile) {
      button.analyticsMobile();
    } else if (button.analytics) {
      button.analytics();
    }
  };

  return (
    <CardWrap $reverse={reverse}>
      <ImageCol xs={12} lg={5}>
        <ImageWrap>
          <Image src={image} alt="Game Banner" />
        </ImageWrap>
      </ImageCol>
      <Col xs={12} lg={7} className="p-0">
        <DetailsWrap $reverse={reverse}>
          {info.map((item, i) => (
            <DetailWrap key={`${item.title}-info-${i}`}>
              <TitleWrap
                variant="displaySm"
                weight={700}
                color="magenta100"
                tag="h3"
                lg={{
                  variant: "displayXl",
                }}
              >
                {item.title}
              </TitleWrap>
              <ListWrap>
                {item.description.map((text, i) => (
                  <Typography
                    variant="textSm"
                    weight={700}
                    color="white"
                    tag="li"
                    md={{
                      variant: "displaySm",
                    }}
                    key={`${item.title}-desc-${i}`}
                  >
                    {text}
                  </Typography>
                ))}
              </ListWrap>
            </DetailWrap>
          ))}
          {/* <TagsWrap>
            <TagsCard title="Required NFTS" list={requiredTokens} />
            <TagsCard title="Playable On" list={platform} />
          </TagsWrap> */}
          <FooterButtonWrap>
            {secondary && (
              <Button
                as={Link}
                size="lg"
                variant="secondary"
                block={false}
                target="_blank"
                rel="noopener noreferrer"
                to={
                  isMobile && secondary.linkMobile
                    ? secondary.linkMobile
                    : secondary.link
                }
                onClick={() => handleClick(secondary)}
              >
                {isMobile && secondary.titleMobile
                  ? secondary.titleMobile
                  : secondary.title}
              </Button>
            )}
            {primary && (
              <Button
                as={Link}
                size="lg"
                variant="primary"
                block={false}
                target="_blank"
                rel="noopener noreferrer"
                to={
                  isMobile && primary.linkMobile
                    ? primary.linkMobile
                    : primary.link
                }
                onClick={() => handleClick(primary)}
              >
                {isMobile && primary.titleMobile
                  ? primary.titleMobile
                  : primary.title}
              </Button>
            )}
          </FooterButtonWrap>
        </DetailsWrap>
      </Col>
    </CardWrap>
  );
}
