import { StakingRewardsSection } from "./StakingBadgesSection";
import { DelegateModal } from "../Modals/DelegateModal";
import { BadgeSuccessModal } from "../Modals/BadgeSuccessModal";
import { useGetVaultBadges } from "../../../query/vault";
import styled from "styled-components";
import {
  StakingBadgeTable,
  StakingBadgeTableCell,
  StakingBadgeTableHeading,
  StakingBadgeTableRow,
} from "./StakingBadgeTable";
import { StakingBadgeRarity } from "./StakingBadgeRarity";
import { Button } from "../../base/Button";
import { StakingBadgeAvatar } from "./StakingBadgeAvatar";
import { useAtom } from "jotai";
import { badgeModalAtom } from "../../../store/staking";
import { NOT_DELEGATED_BADGE_ADDRESS, StakingTokenTypes } from "../constants";
import { device } from "../../../theme/mediaQuery";
import { useActiveWalletChain } from "thirdweb/react";

const Heading = styled(StakingBadgeTableHeading)`
  flex: 1;
`;

const BadgeHeading = styled(Heading)`
  flex: 2;
`;

const RarityCell = styled(StakingBadgeTableCell)`
  @media (${device.xl}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ActionsCell = styled(StakingBadgeTableCell)`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & button {
    margin: 0;
    padding: 0;
  }

  @media (${device.lg}) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export function StakingBadgesDelegatable({ switchChain, chain }) {
  const { data = {}, isLoading } = useGetVaultBadges();
  const badges = data?.allBadges || [];

  // filter out badges that are not delegated yet
  const delegatableBadges = badges.filter(
    (badge) =>
      badge.delegated === NOT_DELEGATED_BADGE_ADDRESS ||
      badge.delegatedLP === NOT_DELEGATED_BADGE_ADDRESS
  );
  const chainId = useActiveWalletChain();

  return (
    <>
      <StakingRewardsSection title="delegatable badges">
        <StakingBadgeTable
          isLoading={isLoading}
          headingMobileTitle="Delegatable Badges"
          headings={
            <>
              <BadgeHeading>Badge</BadgeHeading>
              <Heading>Rarity</Heading>
              <Heading>Boost</Heading>
              <Heading>Delegate to (POOL)</Heading>
            </>
          }
          renderRow={(badge) => (
            <BadgeRow
              badge={badge}
              key={badge.poolId}
              switchChain={switchChain}
              chain={chain}
              chainId={chainId}
            />
          )}
          items={delegatableBadges}
        />
      </StakingRewardsSection>
      <DelegateModal />
      <BadgeSuccessModal />
    </>
  );
}

function BadgeRow({ badge, switchChain, chain, chainId }) {
  const [modal, setModal] = useAtom(badgeModalAtom);
  const { boostFactor, delegated, delegatedLP, attributes } = badge;
  //console.log("badge", badge);
  // badge is delegated to a pool if the address is not 0x
  const isDelegateLP = delegatedLP !== NOT_DELEGATED_BADGE_ADDRESS;
  const isDelegate = delegated !== NOT_DELEGATED_BADGE_ADDRESS;

  const handleOpenModal = (token) => {
    setModal({ show: true, badge, token });
  };

  // get rarity from attributes by trait_type
  const rarity = attributes?.find(
    (attr) => attr.trait_type?.toLowerCase() === "rarity"
  );

  const wrongChain = chainId?.id !== chain?.id;

  return (
    <StakingBadgeTableRow>
      <StakingBadgeTableCell $flex={2}>
        <StakingBadgeAvatar badge={badge} />
      </StakingBadgeTableCell>
      <RarityCell title="Rarity">
        {rarity?.value && (
          <StakingBadgeRarity $variant={rarity.value.toLowerCase()}>
            {rarity.value}
          </StakingBadgeRarity>
        )}
        {!rarity?.value && <>-</>}
      </RarityCell>
      <StakingBadgeTableCell title="Boost">{boostFactor}</StakingBadgeTableCell>
      <ActionsCell>
        {!wrongChain && (
          <Button
            disabled={isDelegate}
            onClick={() => handleOpenModal(StakingTokenTypes.cu)}
            block={true}
            lg={{
              block: false,
            }}>
            CU
          </Button>
        )}
        <Button
          disabled={
            wrongChain ? (isDelegateLP ? isDelegateLP : null) : isDelegateLP
          }
          onClick={() =>
            wrongChain
              ? switchChain(chain)
              : handleOpenModal(StakingTokenTypes.culp)
          }
          block={true}
          lg={{
            block: false,
          }}>
          {wrongChain ? "Switch Network" : "CULP"}
        </Button>
      </ActionsCell>
    </StakingBadgeTableRow>
  );
}
