import { Container } from "../components/base/Container";
import { SectionCard } from "../components/common/SectionCard";
import styled from "styled-components";
import { device } from "../theme/mediaQuery";
import useDocumentTitle from "../helpers/useDocumentTitle";
// import { CRYPTO } from "../consts/externalLinks";

const ContentWrap = styled.div`
  padding: 0 20px;
  margin: 0 auto;
  color: ${({ theme }) => theme.palette.white};
  padding-bottom: 50px;

  & a {
    color: ${({ theme }) => theme.palette.white};
  }

  p,
  li {
    font-size: 16px;
    padding: 10px 0;
    font-weight: 600;
    list-style: normal;
    list-style-type: lower-alpha;
  }

  li.roman {
    list-style-type: lower-roman;
  }

  & .c2 {
    font-size: 23px;
    font-weight: 700;
  }

  @media (${device.md}) {
    p,
    li {
      font-size: 21px;
    }

    & .c2 {
      font-size: 32px;
    }
  }
`;

export function CUIPTermsPage() {
  useDocumentTitle("Crypto Unicorns IP Terms");

  return (
    <Container className="pb-5">
      <SectionCard title="Crypto Unicorns IP Terms">
        <ContentWrap>
          <p className="c1">
            <span className="c0">Last updated: March 26, 2024 </span>
          </p>
          <p className="c1">
            <span className="c2">1. Ownership</span>
          </p>
          <p className="c1">
            <span className="c0">
              <ol>
                <li>
                  You Own Your Crypto Unicorn NFT. When you own a digital wallet
                  that holds a Crypto Unicorn NFT you hold the exclusive right
                  to hold, sell, transfer, and execute blockchain transactions
                  involving that Crypto Unicorn NFT ("Your Crypto Unicorn NFT").
                  Except for the Crypto Unicorn NFTs we own, Crypto Unicorns DAO
                  has no right or ability to seize, freeze, or otherwise modify
                  the ownership of any Crypto Unicorn NFT.
                </li>
                <li>
                  We Own (but License to You) The IP in Your Crypto Unicorn Art.
                  Crypto Unicorns DAO owns all rights, title, and interest in
                  and to the Crypto Unicorn Art including any and all
                  copyrights, trademarks, and other intellectual property rights
                  therein ("IP"). However, we grant you the License (defined
                  below) to use the Crypto Unicorn Art associated with Your
                  Crypto Unicorn for as long as you hold Your Crypto Unicorn
                  NFT.
                </li>
                <li>
                  You Own the IP in Your Derivative Crypto Unicorn Works. As
                  between you and Crypto Unicorns DAO, you own all right, title
                  and interest in and to any "derivative work," as defined by
                  the United States Copyright Act, based upon Your Crypto
                  Unicorn Art created during the License Term (defined below)
                  ("Derivative Crypto Unicorn Work"); provided, however, that
                  (i) we retain the copyright in the Crypto Unicorn Art
                  underlying any Derivative Crypto Unicorn Work; (ii) your use
                  of any Derivative Crypto Unicorn Work during and after the
                  License Term is subject to these Terms; and (iii) your use of
                  any Derivative Crypto Unicorn Work after the License Term may
                  require a license from the current owner of the Crypto Unicorn
                  NFT.
                </li>
              </ol>
            </span>
          </p>
          <p className="c1">
            <span className="c2">2. License</span>
          </p>
          <p className="c1">
            <span className="c0">
              <ol>
                <li>
                  Grant. Subject to your acceptance of, and compliance with,
                  these Terms, upon lawfully acquiring Your Crypto Unicorn NFT
                  and, for so long as you hold Your Crypto Unicorn (the "License
                  Term"), Crypto Unicorns DAO grants to you an exclusive,
                  universe-wide, royalty-free, sublicensable license to
                  reproduce, distribute, prepare derivative works based upon,
                  publicly display, publicly perform, transmit, and otherwise
                  use and exploit, Your Crypto Unicorn Art ("License").
                </li>
                <li>
                  Restrictions and Reservations
                  <ol>
                    <li class="roman">
                      The License extends only to Your Crypto Unicorn
                      Art—meaning, the complete selection and arrangement of all
                      base layers, features, attributes, and other elements that
                      comprise Your Crypto Unicorn Art. Thus, while the License
                      allows you to create and exploit Derivative Crypto Unicorn
                      Works, the License does not grant you rights in any
                      individual element of Your Crypto Unicorn Art, or a
                      license to exploit any individual element separate and
                      apart from Your Crypto Unicorn Art.
                    </li>
                    <li class="roman">
                      The License does not grant you any rights in or to Crypto
                      Unicorns DAO's (or any other) trade names, brands, trade
                      dress, or trademarks (e.g., "Crypto Unicorn" or "Crypto
                      Unicorns DAO"), all of which are expressly reserved to
                      Crypto Unicorns DAO (collectively, "DAO TM Rights"). You
                      hereby agree that any DAO TM Rights you purport to
                      acquire, together with any associated goodwill, shall
                      automatically, immediately, and at your expense be
                      assigned to Crypto Unicorns DAO. For the sake of clarity,
                      the DAO TM Rights do not include Your Crypto Unicorn Art,
                      in which you may acquire trademark rights through the
                      exercise of your rights in accordance with, and subject
                      to, these Terms and applicable law.
                    </li>
                    <li class="roman">
                      Any application to register a trademark in Your Crypto
                      Unicorn Art must occur during the License Term and be
                      based solely upon the actual use of the Crypto Unicorn Art
                      in commerce and solely for the goods or services in
                      connection with which Your Crypto Unicorn Art has actually
                      been used in commerce in the applicable jurisdiction as of
                      the date of the application. Thus, you may not seek to
                      register a trademark in Your Crypto Unicorn Art on an
                      "intent to use" basis or where you otherwise have not used
                      Your Crypto Unicorn Art in commerce.
                    </li>
                    <li class="roman">
                      You may not use Your Crypto Unicorn Art in a manner that
                      expresses hate or encourages violence towards a person or
                      group based on membership in a protected class, such as
                      race, religion, gender, orientation, or disability.
                    </li>
                    <li class="roman">
                      You may not use Your Crypto Unicorn Art in a manner that
                      violates applicable law.
                    </li>
                    <li class="roman">
                      All rights not expressly granted herein are reserved by
                      us.
                    </li>
                  </ol>
                </li>
                <li>
                  License Back to Crypto Unicorns DAO. You grant to Crypto
                  Unicorns DAO an irrevocable, perpetual, non-exclusive,
                  universe-wide, royalty-free, sublicensable license to publicly
                  display and otherwise use Your Crypto Unicorn Art alongside
                  other Crypto Unicorn Art for the purpose of promoting or
                  exhibiting the entire Crypto Unicorns collection.
                </li>
              </ol>
            </span>
          </p>
          <p className="c1">
            <span className="c2">3. Transfers</span>
          </p>
          <p className="c1">
            <span className="c0">
              <ol>
                <li>
                  No Decoupling. Except as expressly provided herein, ownership
                  of a Crypto Unicorn NFT and the License are not separable in
                  any way. You may not engage in any transaction or activity
                  that purports to decouple the License from Your Crypto Unicorn
                  NFT.
                </li>
                <li>
                  Termination of License. Upon the transfer of Your Crypto
                  Unicorn NFT to a new Owner:
                  <ol>
                    <li class="roman">
                      Your License hereunder shall immediately and automatically
                      terminate;
                    </li>
                    <li class="roman">
                      You must discontinue any use of Your Crypto Unicorn Art as
                      a trademark or other source identifier; and
                    </li>
                    <li class="roman">
                      Any trademark and corresponding registration obtained in
                      connection with your exercise of the License shall be
                      deemed abandoned unless duly transferred to the new Owner
                      under a separately negotiated written agreement.
                    </li>
                  </ol>
                </li>
                <li>
                  Published Crypto Unicorn Works. If, during the License Term,
                  you create and make available to the public a work using Your
                  Crypto Unicorn Art (a "Published Crypto Unicorn Work"), you
                  may, except as set forth in Section 3(b)(ii), continue to use
                  and exploit that Published Crypto Unicorn Work in accordance
                  with these Terms after the License Term; provided, however,
                  that:
                  <ol>
                    <li class="roman">
                      You will be responsible for any obligations or liabilities
                      arising from your continued use of the Published Crypto
                      Unicorn Work after the License Term; and
                    </li>
                    <li class="roman">
                      This privilege does not allow you to use the Crypto
                      Unicorn Art to create any new works or materials after the
                      License Term.
                    </li>
                  </ol>
                </li>
              </ol>
            </span>
          </p>
          <p class="text-center">
            Please reach out to{" "}
            <a href="mailto: legal@cryptounicorns.fun">
              legal@cryptounicorns.fun
            </a>{" "}
            with any inquiries.
          </p>
        </ContentWrap>
      </SectionCard>
    </Container>
  );
}
