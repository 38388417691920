import { useState } from "react";
import styled from "styled-components";
import { TokenCard } from "../TokenCard";
import { getAttribute } from "../../Helpers";
import { InventoryTokens } from "../InventoryTokensPaginated";
import Spinner from "../../base/Spinner";
import { Typography } from "../../base/Typography";
import { Button } from "../../base/Button";
import { BUY } from "../../../consts/externalLinks";
import { useGetRituals } from "../../../query/rituals";
import { ItemsModal } from "../ItemsModal";

const PlaceholderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px;
  min-height: 528px;
  padding: 0 10px;
`;

const SpinnerWrap = styled.div`
  & svg {
    margin: 0;
  }
`;

const filters = [
  {
    title: "Class",
    options: [
      { value: null, title: "All", icon: "all" },
      { value: "Fire", title: "Fire", icon: "fire" },
      { value: "Nebula", title: "Nebula", icon: "nebula" },
      { value: "Slime", title: "Slime", icon: "slime" },
      { value: "Soul", title: "Soul", icon: "soul" },
      { value: "Volt", title: "Volt", icon: "volt" },
    ],
    id: "class",
    getter: (item) => getAttribute("Class", item),
  },
  {
    title: "Rarity",
    options: [
      { value: null, title: "All", icon: "all" },
      { value: "Mythic", title: "Mythic", icon: "mythicShadowcorn" },
      { value: "Rare", title: "Rare", icon: "rareShadowcorn" },
      { value: "Common", title: "Common", icon: "commonShadowcorn" },
    ],
    id: "rarity",
  },
  // {
  //   title: "Ritual Type",
  //   options: [
  //     { value: null, title: "All", icon: "all" },
  //     { value: "minion", title: "Minion", icon: "all-class" },
  //     { value: "ingredient", title: "Ingredient", icon: "ingredient" },
  //   ],
  //   id: "type",
  // },
  {
    title: "Ritual Tier",
    options: [
      { value: null, title: "All", icon: "all" },
      { value: 1, title: "Tier 1", icon: "rarityNeutralCommon" },
      { value: 2, title: "Tier 2", icon: "rarityNeutralRare" },
      { value: 3, title: "Tier 3", icon: "rarityNeutralMythic" },
    ],
    id: "tier",
  },
];

const filterGetters = {
  rarity: (item) => getAttribute("Rarity", item),
  class: (item) => getAttribute("Class", item),
  tier: (item) => getAttribute("Tier", item),
};

export function InventoryRituals() {
  const { data, isLoading } = useGetRituals();
  const [showModal, setShowModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);

  const balance = data?.length || 0;

  const handleSelectRitual = (item) => {
    setSelectedToken(item);
    setShowModal(true);
  };

  let _toolbarTitle = `${balance} rituals owned`;

  if (isLoading) {
    return (
      <PlaceholderWrap>
        <Typography
          tag={"h3"}
          variant={"textLg"}
          color="black"
          className="mb-2"
        >
          Loading rituals
        </Typography>

        <SpinnerWrap>
          <Spinner color="black" />
        </SpinnerWrap>
      </PlaceholderWrap>
    );
  }

  return (
    <>
      {balance > 0 && (
        <>
          <InventoryTokens
            title={_toolbarTitle}
            data={data}
            filters={filters}
            filterGetters={filterGetters}
            renderCard={(item) => (
              <TokenCard
                onClick={() => {
                  handleSelectRitual(item);
                }}
                image={item.image}
                name={item.name}
                countTag={item.count}
                setShowModal={setShowModal}
                rightAttribute={{
                  title: "Class",
                  value: getAttribute("Class", item),
                }}
              />
            )}
          />
          <ItemsModal
            showModal={showModal}
            setShowModal={setShowModal}
            item={selectedToken}
            classIcon={
              selectedToken &&
              getAttribute("Class", selectedToken)?.toLowerCase()
            }
          />
        </>
      )}
      {balance === 0 && (
        <PlaceholderWrap>
          <Typography tag={"h3"} variant={"textLg"} color="gray300">
            🎁 Missing magical items? 🌟
          </Typography>
          <Typography tag={"h3"} variant={"textMd"} color="gray300">
            Elevate your Crypto Unicorns experience! Find lootboxes, tickets,
            potions and nft in-game items to boost your game play experience.
          </Typography>
          <a
            href={BUY.MARKETPLACE.UNICORN_ITEMS}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button block={true} prependIcon="cart">
              Visit Marketplace
            </Button>
          </a>
        </PlaceholderWrap>
      )}
    </>
  );
}
