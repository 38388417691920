import React from "react";
import styled from "styled-components";
import { Container } from "../../base/Container";
import { Icon } from "../../base/Icon";
import { device } from "../../../theme/mediaQuery";

const WelcomeOverlayWrap = styled.div`
  position: ${({ $fullscreen }) => ($fullscreen ? "fixed" : "absolute")};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ $opacity }) =>
    $opacity === "md" ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.3)"};
  padding: 23px 12px;
  z-index: 11;
  display: flex;
  align-items: center;

  @media (${device.md}) {
    padding: 23px 18px;
  }

  @media (${device.lg}) {
    padding: 48px 62px;
  }
`;

const WelcomeOverlayContent = styled(Container)`
  z-index: 3;
  position: relative;
`;

const CloseButton = styled.div`
  border-radius: 150px;
  border-bottom: 2px solid #540312;
  background: #fa5876;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;
  width: 36px;
  height: 36px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: -10px;
  right: -10px;
  z-index: 11;

  &:hover {
    filter: brightness(1.1);
  }
`;

export function Modal({
  toggleModal,
  showModal,
  opacity,
  children,
  fullscreen = false,
  ...rest
}) {
  const handleClose = (e) => {
    e.preventDefault();
    toggleModal();
  };

  return (
    <>
      {showModal && (
        <WelcomeOverlayWrap
          onClick={handleClose}
          $opacity={opacity}
          $fullscreen={fullscreen}
        >
          <WelcomeOverlayContent onClick={(e) => e.stopPropagation()} {...rest}>
            <CloseButton
              onClick={(e) => {
                e.stopPropagation(); // Stop the click from propagating to parent elements
                toggleModal();
              }}
            >
              <Icon name="close" size="12px" />
            </CloseButton>
            {children}
          </WelcomeOverlayContent>
        </WelcomeOverlayWrap>
      )}
    </>
  );
}
