import {
  StakingOverviewStats,
  StakingOverviewStatsFullRow,
  StakingOverviewStatsGrid,
  StakingOverviewStatsSection,
} from "./StakingOverviewStats";
import { Button } from "../../base/Button";
import { StakingTokenTypes } from "../constants";
import { formatStringWithFixedFraction } from "../../Helpers";
import {
  useGetLpAPR,
  useGetTokenUSDPrice,
  useGetVaultBadges,
  useGetVaultOverview,
  useGetVaultOverviewWithoutWallet,
} from "../../../query/vault";
import { StakingOverviewEstimatedReward } from "./StakingOverviewEstimatedReward";
import { useAtomValue } from "jotai";
import { currentWalletAddressAtom } from "../../../store";
import { useActiveWalletChain } from "thirdweb/react";
import { NetworkConfiguration } from "../../Network";
import { POLYGON_NETWORK } from "../../ConnectWallet/WalletHelper";

//move this function to a helper file
function fromWei(value) {
  const convertedValue = value / 10 ** 18 || 0;
  return convertedValue === 0
    ? 0
    : formatStringWithFixedFraction(convertedValue);
}

export function StakingOverviewCUStats({ onStake, chain, switchChain }) {
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const chainId = useActiveWalletChain();

  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const badgesQuery = useGetVaultBadges();
  const { data = {}, isLoading: isOverviewLoading } = useGetVaultOverview();
  const { data: dataNoWallet = {}, isLoading: isLoadingNoWallet } =
    useGetVaultOverviewWithoutWallet();
  const { data: price = 0, isLoading: isPriceLoading } = useGetTokenUSDPrice(
    StakingTokenTypes.cu
  );

  const { data: apr = {}, isLoading: isAprLoading } = useGetLpAPR();
  const isLoading =
    isOverviewLoading || isPriceLoading || isLoadingNoWallet || isAprLoading;

  // Total Staked section
  const stakedCU = fromWei(data.CU?.userStakedCU);
  const userStakedInUsd = stakedCU * price;
  const userStakedInUsdFormatted =
    formatStringWithFixedFraction(userStakedInUsd);

  //sCU Balance section
  const sCUBalance = fromWei(data.CU?.userSCU);
  //Total CU Staked section
  const totalStaked = data.CU
    ? data.CU?.totalStakedCU
    : dataNoWallet.CU?.totalStakedCU;
  const totalStakedCU = formatStringWithFixedFraction(totalStaked);
  const totalCUStakedInUsd = totalStaked * price;
  const totalCUStakedInUsdFormatted =
    formatStringWithFixedFraction(totalCUStakedInUsd);

  //Estimated APY section
  const minAPR = apr.CU?.minAPR === "Infinity" ? 0 : apr.CU?.minAPR;
  const maxAPR = apr.CU?.maxAPR === "Infinity" ? 0 : apr.CU?.maxAPR;

  const cuBalance = fromWei(data.CU?.cuBalance);
  const disabled = cuBalance === 0;
  const wrongChain = chainId?.id !== chain?.id;
  //Badge Boost section
  const totalBoostFactor = formatStringWithFixedFraction(
    badgesQuery.data?.totalBoostFactor || 0,
    0,
    3
  );

  return (
    <StakingOverviewStats
      title="CU"
      subtitle={`Your Balance: ${cuBalance}`}
      isLoading={isLoading}
      token={StakingTokenTypes.cu}
      actions={
        <>
          <Button
            disabled={wrongChain ? (disabled ? disabled : null) : disabled}
            variant="secondary"
            size="sm"
            onClick={() => (wrongChain ? switchChain(chain) : onStake())}>
            {wrongChain ? (disabled ? "Stake" : "Switch Network") : "Stake"}
          </Button>
        </>
      }>
      <StakingOverviewStatsGrid>
        {walletAddress && (
          <>
            <StakingOverviewStatsSection
              title="My CU Staked"
              value={stakedCU}
              secondValue={`$ ${userStakedInUsdFormatted}`}
            />
            <StakingOverviewStatsSection
              title="Total CU Staked"
              value={totalStakedCU}
              secondValue={`$ ${totalCUStakedInUsdFormatted}`}
            />
            <StakingOverviewStatsSection
              title="sCU Balance"
              value={sCUBalance}
            />
            <StakingOverviewStatsSection
              title="Badge Boost"
              value={totalBoostFactor}
            />
          </>
        )}
        {!walletAddress && (
          <StakingOverviewStatsFullRow>
            <StakingOverviewStatsSection
              title="Total CU Staked"
              value={totalStakedCU}
              secondValue={`$ ${totalCUStakedInUsdFormatted}`}
            />
          </StakingOverviewStatsFullRow>
        )}
        <StakingOverviewStatsFullRow>
          <StakingOverviewStatsSection
            title="Estimated APR"
            tokens={[StakingTokenTypes.cu]}
            value={
              <StakingOverviewEstimatedReward
                min={minAPR}
                max={maxAPR}
                token={StakingTokenTypes.cu}
              />
            }
          />
        </StakingOverviewStatsFullRow>
      </StakingOverviewStatsGrid>
    </StakingOverviewStats>
  );
}
