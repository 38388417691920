import styled from "styled-components";
import { Icon } from "../../base/Icon";
import { useState } from "react";
import { device } from "../../../theme/mediaQuery";
import { useGetTokenUSDPrice, useGetVaultOverview } from "../../../query/vault";
import Spinner from "../../base/Spinner";
import { StakingTokenTypes } from "../constants";
import { StakingTokenImage } from "../StakingTokenImage";
import { formatStringWithFixedFraction } from "../../Helpers";

const Wrap = styled.div`
  background: #3c1568;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  height: 100%;
  @media (${device.lg}) {
    flex-wrap: nowrap;
    padding: 30px;
    gap: 10px;
  }
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h4`
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.display};
  color: #f5b53d;
  font-size: 20px;

  @media (${device.lg}) {
    font-size: 28px;
  }
`;

const ValueWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Value = styled.div`
  font-family: ${({ theme }) => theme.fonts.display};
  color: ${({ theme }) => theme.palette.white};
  font-size: 28px;

  @media (${device.lg}) {
    font-size: 42px;
  }
`;

const Badge = styled.div`
  background: #6bffea;
  color: #007060;
  height: 28px;
  padding: 0 8px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;


  @media (${device.sm}) {
    margin-left: auto;
  }
`;

const BadgeNegative = styled(Badge)`
  background: #ff6b6b;
  color: #7f0000;
`;

const BadgeIcon = styled(Icon)`
  ${({ $negative }) => $negative && "transform: rotate(180deg);"}
`;

const TabsWrap = styled.div`
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;

  @media (${device.lg}) {
    width: auto;
  }
`;

const TabItem = styled.div`
  background: ${({ $active }) =>
          $active ? "linear-gradient(180deg, #E7B610 0%, #D77F01 100%)" : "#5338C2"};
  height: 38px;
  min-width: 76px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;

  &:after {
    content: ${({ $active }) => ($active ? "''" : "none")};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: url(${({ theme }) => theme.pattern.sprinkles});
    opacity: 0.06;
    background-size: 600%;
  }

  &:hover {
    filter: brightness(1.1);
  }

  @media (${device.lg}) {
    width: auto;
  }
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ImageWrap = styled.div`
  width: 50px;
  height: 50px;

  & img {
    width: 100%;
    height: 100%;
  }

  @media (${device.lg}) {
    width: 80px;
    height: 80px;
    display: block;
  }
`;

// function to calculate percentage difference between two numbers
function calculatePercentageChange(lastPrice, secondLastPrice) {
  return ((lastPrice - secondLastPrice) / secondLastPrice) * 100;
}

export function StakingOverviewPrice() {
  const { data = {}, isLoading: isOverviewLoading } = useGetVaultOverview();
  const { data: price = 0, isLoading: isPriceLoading } = useGetTokenUSDPrice(StakingTokenTypes.cu);
  const [activeTab, setActiveTab] = useState("CU");

  const formattedPrice = formatStringWithFixedFraction(price, 0, 5);
  const priceHistoryCoins = data?.CU?.cuPriceHistory?.coins || {};

  // get price history from the first object key
  const priceHistory =
    priceHistoryCoins[Object.keys(priceHistoryCoins)[0]] || {};
  const prices = priceHistory?.prices || [];
  const showPercentageChange = prices.length > 1;

  // compare last two prices to get the percentage change
  const lastPrice = prices[prices.length - 1]?.price || 0;
  const secondLastPrice = prices[prices.length - 2]?.price || 0;
  const percentageChange = calculatePercentageChange(lastPrice, secondLastPrice);
  const percentageWithoutSign = Math.abs(percentageChange);

  const priceIsGreaterThanBefore = percentageChange > 0;

  const BadgeComponent = priceIsGreaterThanBefore ? Badge : BadgeNegative;

  const isLoading = isOverviewLoading || isPriceLoading;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Wrap>
      {isLoading && (
        <LoadingWrap>
          <Spinner />
        </LoadingWrap>
      )}
      {!isLoading && (
        <>
          <ImageWrap>
            <StakingTokenImage token={StakingTokenTypes.cu} />
          </ImageWrap>
          <TextWrap>
            <Title>CU Token Price</Title>
            <ValueWrap>
              <Value>${formattedPrice}</Value>
            </ValueWrap>
          </TextWrap>
          {showPercentageChange && (
            <BadgeComponent>
              <BadgeIcon
                $negative={!priceIsGreaterThanBefore}
                name="triangle"
                size="14px"
              />
              {percentageWithoutSign.toFixed(2)}%
            </BadgeComponent>
          )}
          {/*<TabsWrap>*/}
          {/*  <TabItem*/}
          {/*    $active={activeTab === "CU"}*/}
          {/*    onClick={() => handleTabClick("CU")}*/}
          {/*  >*/}
          {/*    CU*/}
          {/*  </TabItem>*/}
          {/*  <TabItem*/}
          {/*    $active={activeTab === "CULP"}*/}
          {/*    onClick={() => handleTabClick("CULP")}*/}
          {/*  >*/}
          {/*    CULP*/}
          {/*  </TabItem>*/}
          {/*</TabsWrap>*/}
        </>
      )}
    </Wrap>
  );
}
