import React from "react";
import styled from "styled-components";
import { Typography } from "../../base/Typography";
import { device } from "../../../theme/mediaQuery";
import { Icon } from "../../base/Icon";
import rbw from "../../../img/currencies/rbw.png";
import xai from "../../../img/currencies/xai-red.png";
import grail from "../../../img/currencies/grail.png";
import { InfoModal } from "./InfoModal";
import { useAtom } from "jotai";
import { rewardDetailsModalAtom } from "../../../store/staking";
import { StakingTokenTypes } from "../constants";
import { useGetLpAPR, useGetVaultOverview } from "../../../query/vault";
import { formatStringWithFixedFraction } from "../../Helpers";

const ApyTable = styled.div`
  flex: 1;
  background-color: #f0f1ff;
  border-radius: 20px;
  color: #5338c2;
`;

const TableHeading = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background: #e0e0fc;
  gap: 0px 8px;
  text-align: center;
  @media (${device.sm}) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (${device.md}) {
    padding: 20px 30px;
    align-items: stretch;
    text-align: left;
  }

  & h2 {
    text-align: left;
  }
`;

const EstimatedRewardWrap = styled.div`
  display: flex;
  height: 100%;
  column-gap: 6px;
  letter-spacing: 1px;
  justify-content: center;
  flex-wrap: wrap;

  & p {
    line-height: 1;
    text-align: center;
  }

  @media (${device.sm}) {
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: flex-end;
  }
`;

const EstimatedRewardPeriod = styled.div`
  font-size: 10px;
  color: #5338c2;
  text-align: center;
`;

const TableBody = styled.div`
  padding: 18px 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  @media (${device.md}) {
    padding: 20px 30px;
  }

  & > div,
  & > p {
    border-bottom: 1px dashed #5338c2;
  }

  & > p:first-of-type {
    border-bottom: none;
  }
`;

const ContentHeading = styled(Typography)`
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

function useGetAprForCU() {
  const { data = {} } = useGetLpAPR();
  const CUMinAPR = data.CU?.minAPR;
  const CUMaxAPR = data.CU?.maxAPR;

  const CUAPRString =
    CUMinAPR === "Infinity" || CUMaxAPR === "Infinity"
      ? "-"
      : `${CUMinAPR}-${CUMaxAPR}%`;

  return {
    breakdown: [
      {
        category: "Breakdown",
      },
      {
        category: "Base APR",
        value: CUAPRString,
        details: [{ name: "CU", value: CUAPRString, img: rbw }],
      },
    ],
    totalMinAPR: CUMinAPR,
    totalMaxAPR: CUMaxAPR,
  };
}

function useGetAprForCULP() {
  const { data } = useGetLpAPR();
  const cuMinAPR = data?.CULP.cuAPR?.minApr;
  const cuMaxAPR = data?.CULP.cuAPR?.maxApr;
  const xaiMinAPR = data?.CULP.xaiAPR?.minApr;
  const xaiMaxAPR = data?.CULP.xaiAPR?.maxApr;
  const grailMinAPR = data?.CULP.grailAPR?.minApr;
  const grailMaxAPR = data?.CULP.grailAPR?.maxApr;

  const totalMinAPR = formatStringWithFixedFraction(data?.CULP.totalMinApr);
  const totalMaxAPR = formatStringWithFixedFraction(data?.CULP.totalMaxApr);

  const baseAPRMin = formatStringWithFixedFraction(cuMinAPR + xaiMinAPR);
  const baseAPRMax = formatStringWithFixedFraction(cuMaxAPR + xaiMaxAPR);

  const variableAPRMin =
    grailMinAPR > 0 ? formatStringWithFixedFraction(grailMinAPR) : "";
  const variableAPRMax =
    grailMaxAPR > 0 ? formatStringWithFixedFraction(grailMaxAPR) : "";

  const cuAprString = `${formatStringWithFixedFraction(
    cuMinAPR
  )}-${formatStringWithFixedFraction(cuMaxAPR)}%`;
  const xaiAprString = `${formatStringWithFixedFraction(
    xaiMinAPR
  )}-${formatStringWithFixedFraction(xaiMaxAPR)}%`;
  const grailAprString = `${formatStringWithFixedFraction(
    grailMinAPR
  )}-${formatStringWithFixedFraction(grailMaxAPR)}%`;

  return {
    breakdown: [
      {
        category: "Breakdown",
      },
      {
        category: "Base APR",
        value: `${baseAPRMin}-${baseAPRMax}%`,
        details: [
          { name: "CU", value: cuAprString, img: rbw },
          { name: "XAI", value: xaiAprString, img: xai },
        ],
      },
      {
        category: "Variable APR",
        value: `${variableAPRMin}-${variableAPRMax}%`,
        details: [
          // { name: "ARB", value: "14-45%", img: arbitrum },
          { name: "GRAIL", value: grailAprString, img: grail },
        ],
      },
    ],
    totalMinAPR: totalMinAPR,
    totalMaxAPR: totalMaxAPR,
  };
}

export function RewardModal() {
  const [modal, setModal] = useAtom(rewardDetailsModalAtom);
  const cuAPR = useGetAprForCU();
  const culpAPR = useGetAprForCULP();
  const showModal = modal.show;
  const isCULPToken = modal.token === StakingTokenTypes.culp;

  const title = isCULPToken ? "cuLP Rewards" : "CU Rewards";
  const apyData = isCULPToken ? culpAPR.breakdown : cuAPR.breakdown;

  const totalMinAPR = isCULPToken ? culpAPR.totalMinAPR : cuAPR.totalMinAPR;
  const totalMaxAPR = isCULPToken ? culpAPR.totalMaxAPR : cuAPR.totalMaxAPR;

  const invalidAPR = totalMinAPR === "Infinity" || totalMaxAPR === "Infinity";

  const handleClose = () => {
    setModal({
      show: false,
      data: null,
    });
  };

  return (
    <InfoModal
      toggleModal={handleClose}
      showModal={showModal}
      headerTitle={title}>
      <ApyTable>
        <TableHeading>
          <Typography
            as="h2"
            variant="textLg"
            md={{ variant: "displayXs" }}
            color="#5338C2">
            Estimated Rewards (APR)
          </Typography>
          {!invalidAPR && (
            <EstimatedRewardWrap>
              <div>
                <Typography
                  tag="p"
                  variant="textLg"
                  md={{ variant: "textXl" }}
                  family="display"
                  color="#5338c2">
                  {totalMinAPR}
                </Typography>
                <EstimatedRewardPeriod>1 mo</EstimatedRewardPeriod>
              </div>
              <Icon name="full-arrow-right" color="#B1A4E4" size="24px" />
              <div>
                <Typography
                  tag="p"
                  variant="textLg"
                  md={{ variant: "textXl" }}
                  family="display"
                  color="#5338c2">
                  {totalMaxAPR} %
                </Typography>
                <EstimatedRewardPeriod>12 mo</EstimatedRewardPeriod>
              </div>
            </EstimatedRewardWrap>
          )}
        </TableHeading>
        <TableBody>
          {apyData.map((item, index) => (
            <React.Fragment key={index}>
              <ContentHeading
                as="p"
                variant="textSm"
                md={{ variant: "textMd" }}
                family="body">
                {item.category}
                <AmountWrap as="span" color="#5338c2">
                  {item.value}
                </AmountWrap>
              </ContentHeading>
              {item?.details &&
                item.details.map((detail, detailIndex) => (
                  <ApyDetailRow key={detailIndex} detail={detail} />
                ))}
            </React.Fragment>
          ))}
        </TableBody>
      </ApyTable>
    </InfoModal>
  );
}

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  align-items: center;
  gap: 10px;

  @media (${device.md}) {
    padding-left: 24px;
  }

  & span:first-of-type {
    margin-right: auto;
  }
`;
const AmountWrap = styled(Typography)`
  white-space: normal;
  overflow-wrap: break-word;
  text-align: right;

  @media (${device.sm}) {
    white-space: nowrap;
  }
`;
const LogoWrap = styled.img`
  width: 20px;
  height: 20px;
`;

const ApyDetailRow = ({ detail }) => (
  <TableRow>
    <LogoWrap src={detail.img} alt={`${detail.name} logo`} />
    <Typography as="span" color="#5338c2">
      {detail.name}
    </Typography>
    <AmountWrap as="span" color="#5338c2">
      {detail.value}
    </AmountWrap>
  </TableRow>
);
