import { StakingRainbowBorder } from "../StakingRainbowBorder";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Icon } from "../../base/Icon";
import { Button } from "../../base/Button";
import { CountdownCard } from "../CountdownCard";
import { device } from "../../../theme/mediaQuery";
import { useAtomValue, useSetAtom } from "jotai";
import { rewardDetailsModalAtom } from "../../../store/staking";
import { StakingTokenTypes } from "../constants";
import { BLOG } from "../../../consts/externalLinks";
import { currentWalletAddressAtom } from "../../../store";

const COUNTDOWN_DATE = 1719014400;

const Wrap = styled.div`
  background: #3c1568;
  padding: 30px 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;

  @media (${device.lg}) {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    padding: 30px 40px;
  }
`;

const CountdownWrap = styled.div`
  margin-top: -30px;
`;

const Title = styled.h4`
  font-size: 28px;
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 700;
  color: #f5b53d;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.palette.white};
  font-size: 18px;
`;

const ActionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${device.lg}) {
    flex-direction: row;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ActionLink = styled.a`
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  @media (${device.lg}) {
    justify-content: flex-end;
  }

  & * {
    fill: ${({ theme }) => theme.palette.white};
  }

  &:hover {
    color: #e0e0fc;

    & * {
      fill: #e0e0fc;
    }
  }
`;

export function StakingOverviewLeaderboardBanner() {
  const setShowRewardModal = useSetAtom(rewardDetailsModalAtom);
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const description = walletAddress
    ? "Stake CULP now to earn rewards and participate on the leaderboard."
    : "Connect your wallet and stake CULP to earn rewards and participate on the leaderboard.";

  const handleOpenRewardModal = () => {
    setShowRewardModal({
      show: true,
      token: StakingTokenTypes.culp,
    });
  };

  return (
    <StakingRainbowBorder>
      <Wrap>
        <ContentWrap>
          <div>
            <Title>Leaderboard</Title>
            <Description>{description}</Description>
          </div>
          <ActionsWrap>
            <Button variant="primary" as={Link} to="/staking/leaderboard">
              Go to leaderboard
            </Button>
            <ActionLink
              href={BLOG.STAKING}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more <Icon color="" name="full-arrow-right" size="14px" />
            </ActionLink>
          </ActionsWrap>
        </ContentWrap>
        <CountdownWrap>
          <CountdownCard targetDate={COUNTDOWN_DATE} />
        </CountdownWrap>
      </Wrap>
    </StakingRainbowBorder>
  );
}
