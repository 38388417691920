import React, { useMemo } from "react";
import { useAtomValue } from "jotai/index";
import styled from "styled-components";

import { currentWalletAddressAtom } from "../../store";
import { LeaderboardSummary } from "../leaderboard/LeaderboardSummary";
import { Container } from "../base/Container";
import { Button } from "../base/Button";
import { SectionWrap } from "../../views/Airdrop";
import { device } from "../../theme/mediaQuery";
import { Table, VARIANTS } from "./Table";

import common from "../../img/rarity/commonShadowcorn.png";
import rare from "../../img/rarity/rareShadowcorn.png";
import mythic from "../../img/rarity/mythicShadowcorn.png";

import commonAlt from "../../img/rarity/common-alt.png";
import rareAlt from "../../img/rarity/rare-alt.png";
import mythicAlt from "../../img/rarity/mythic-alt.png";

import { Divider } from "./Stats";
import { Typography } from "../base/Typography";

const HEADER_COLOR = "linear-gradient(180deg, #A04DF3 0%, #500998 100%)";
const BACKGROUND_COLOR = "rgba(106, 83, 214, 0.5)";

export const LeaderboardSummaryWrap = styled(LeaderboardSummary)`
  @media (${device.lg}) {
    min-height: 1627px;
  }
`;

export const BodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 16px 4px;
  @media (${device.md}) {
    padding: 18px 16px 60px;
  }
  @media (${device.xl}) {
    gap: 32px;
    padding: 21px 21px 80px;
  }
`;

export const ButtonWrap = styled(Button)`
  width: fit-content;
  margin-left: auto;

  @media (${device.lg}) {
    position: absolute;
    right: 20px;
    margin-left: unset;
  }
`;

export const EventTasksSeason = ({ leaderboard, ...rest }) => {
  const walletAddress = useAtomValue(currentWalletAddressAtom);

  const self = walletAddress ? leaderboard?.self : null;
  const pointsData = self?.points_data ? self?.points_data : null;
  const TABLE_DATA = useMemo(() => {
    return {
      //  Purple - Be Early
      early1: {
        label: null,
        rows: [
          {
            label: "Complete daily login",
            detail: "175 points each",
            quantity: pointsData?.s1_num_crafts
              ? parseInt(pointsData.s1_num_crafts).toLocaleString("en")
              : 0,
            points: pointsData?.s1_num_crafts_pts
              ? parseInt(pointsData.s1_num_crafts_pts).toLocaleString("en")
              : 0,
          },
          {
            label: "Complete a Daily/Weekly Quest",
            detail: "25 points each",
            quantity: pointsData?.s1_num_quests
              ? parseInt(pointsData.s1_num_quests).toLocaleString("en")
              : 0,
            points: pointsData?.s1_num_quests_pts
              ? parseInt(pointsData.s1_num_quests_pts).toLocaleString("en")
              : 0,
          },
          {
            label: "Participate in a joust",
            detail: "45 points each",
            quantity: pointsData?.s1_num_jousts
              ? parseInt(pointsData.s1_num_jousts).toLocaleString("en")
              : 0,
            points: pointsData?.s1_num_jousts_pts
              ? parseInt(pointsData.s1_num_jousts_pts).toLocaleString("en")
              : 0,
          },
        ],
      },
      // Pink - Be Engaged
      engaged1: {
        label: null,
        rows: [
          {
            label: "Breed a Unicorn",
            detail: "1,125 points each",
            quantity: pointsData?.s1_breed_unicorns
              ? parseInt(pointsData.s1_breed_unicorns).toLocaleString("en")
              : 0,
            points: pointsData?.s1_breed_unicorns_pts
              ? parseInt(pointsData.s1_breed_unicorns_pts).toLocaleString("en")
              : 0,
          },
          {
            label: "Evolve a baby",
            detail: "1,875 points each",
            quantity: pointsData?.s1_evolve_unicorns
              ? parseInt(pointsData.s1_evolve_unicorns).toLocaleString("en")
              : 0,
            points: pointsData?.s1_evolve_unicorns_pts
              ? parseInt(pointsData.s1_evolve_unicorns_pts).toLocaleString("en")
              : 0,
          },
        ],
      },
      engaged2: {
        label: "Craft a Minion",
        rows: [
          {
            iconSrc: common,
            iconAlt: "Common",
            label: "Common",
            detail: "1 points each",
            quantity: pointsData?.s1_craft_common_minions
              ? parseInt(pointsData.s1_craft_common_minions).toLocaleString(
                  "en"
                )
              : 0,
            points: pointsData?.s1_craft_common_minions_pts
              ? parseInt(pointsData.s1_craft_common_minions_pts).toLocaleString(
                  "en"
                )
              : 0,
          },
          {
            iconSrc: rare,
            iconAlt: "Rare",
            label: "Rare",
            detail: "75 points each",
            quantity: pointsData?.s1_craft_rare_minions
              ? parseInt(pointsData.s1_craft_rare_minions).toLocaleString("en")
              : 0,
            points: pointsData?.s1_craft_rare_minions_pts
              ? parseInt(pointsData.s1_craft_rare_minions_pts).toLocaleString("en")
              : 0,
          },
          {
            iconSrc: mythic,
            iconAlt: "Mythic",
            label: "Mythic",
            detail: "500 points each",
            quantity: pointsData?.s1_craft_mythic_minions
              ? parseInt(pointsData.s1_craft_mythic_minions).toLocaleString(
                  "en"
                )
              : 0,
            points: pointsData?.s1_craft_mythic_minions_pts
              ? parseInt(pointsData.s1_craft_mythic_minions_pts).toLocaleString(
                  "en"
                )
              : 0,
          },
        ],
      },

      // Blue - Be Loyal
      loyal1: {
        label: "Hold a shadowcorn",
        rows: [
          {
            iconSrc: common,
            iconAlt: "Common",
            label: "Common",
            detail: "25 points daily",
            quantity: pointsData?.s1_hold_common_SC
              ? parseInt(pointsData.s1_hold_common_SC).toLocaleString("en")
              : 0,
            points: pointsData?.s1_hold_common_SC_pts
              ? parseInt(pointsData.s1_hold_common_SC_pts).toLocaleString("en")
              : 0,
          },
          {
            iconSrc: rare,
            iconAlt: "Rare",
            label: "Rare",
            detail: "50 points daily",
            quantity: pointsData?.s1_hold_rare_SC
              ? parseInt(pointsData.s1_hold_rare_SC).toLocaleString("en")
              : 0,
            points: pointsData?.s1_hold_rare_SC_pts
              ? parseInt(pointsData.s1_hold_rare_SC_pts).toLocaleString("en")
              : 0,
          },
          {
            iconSrc: mythic,
            iconAlt: "Mythic",
            label: "Mythic",
            detail: "1,150 points daily",
            quantity: pointsData?.s1_hold_mythic_SC
              ? parseInt(pointsData.s1_hold_mythic_SC).toLocaleString("en")
              : 0,
            points: pointsData?.s1_hold_mythic_SC_pts
              ? parseInt(pointsData.s1_hold_mythic_SC_pts).toLocaleString("en")
              : 0,
          },
        ],
      },
      loyal2: {
        label: "Hold land",
        rows: [
          {
            iconSrc: commonAlt,
            iconAlt: "Common",
            label: "Common",
            detail: "5 points daily",
            quantity: pointsData?.s1_hold_common_land
              ? parseInt(pointsData.s1_hold_common_land).toLocaleString("en")
              : 0,
            points: pointsData?.s1_hold_common_land_pts
              ? parseInt(pointsData.s1_hold_common_land_pts).toLocaleString(
                  "en"
                )
              : 0,
          },
          {
            iconSrc: rareAlt,
            iconAlt: "Rare",
            label: "Rare",
            detail: "10 points daily",
            quantity: pointsData?.s1_hold_rare_land
              ? parseInt(pointsData.s1_hold_rare_land).toLocaleString("en")
              : 0,
            points: pointsData?.s1_hold_rare_land_pts
              ? parseInt(pointsData.s1_hold_rare_land_pts).toLocaleString("en")
              : 0,
          },
          {
            iconSrc: mythicAlt,
            iconAlt: "Mythic",
            label: "Mythic",
            detail: "25 points daily",
            quantity: pointsData?.s1_hold_mythic_land
              ? parseInt(pointsData.s1_hold_mythic_land).toLocaleString("en")
              : 0,
            points: pointsData?.s1_hold_mythic_land_pts
              ? parseInt(pointsData.s1_hold_mythic_land_pts).toLocaleString(
                  "en"
                )
              : 0,
          },
        ],
      },
      loyal3: {
        label: null,
        rows: [
          {
            label: "Stake CULP",
            detail: "200 points each",
            quantity: pointsData?.s1_stake_culp
              ? parseInt(pointsData.s1_stake_culp).toLocaleString("en")
              : 0,
            points: pointsData?.s1_stake_culp_pts
              ? parseInt(pointsData.s1_stake_culp_pts).toLocaleString("en")
              : 0,
          },
        ],
      },
    };
  }, [pointsData]);

  const renderBody = (leaderboard) => {
    return (
      <BodyWrap>
        {/* Purple - Be Early */}
        <TableSection title="Be Active">
          <Table variant={VARIANTS.PURPLE} rows={[TABLE_DATA.early1.rows[0]]} />
          <Table variant={VARIANTS.PURPLE} rows={[TABLE_DATA.early1.rows[1]]} />
          <Table variant={VARIANTS.PURPLE} rows={[TABLE_DATA.early1.rows[2]]} />
        </TableSection>

        {/* Pink - Be Engaged */}
        <TableSection title="Be engaged" variant={VARIANTS.PINK}>
          <Table variant={VARIANTS.PINK} rows={[TABLE_DATA.engaged1.rows[0]]} />
          <Table variant={VARIANTS.PINK} rows={[TABLE_DATA.engaged1.rows[1]]} />
          <Table
            title={TABLE_DATA.engaged2.label}
            variant={VARIANTS.PINK}
            rows={[...TABLE_DATA.engaged2.rows]}
            hasIcon={true}
          />{" "}
        </TableSection>

        {/* Blue - Be Loyal */}
        <TableSection title="Be loyal">
          <Table
            title={TABLE_DATA.loyal1.label}
            rows={[...TABLE_DATA.loyal1.rows]}
            hasIcon={true}
          />
          <Table
            title={TABLE_DATA.loyal2.label}
            rows={[...TABLE_DATA.loyal2.rows]}
            hasIcon={true}
          />
          <Table rows={[...TABLE_DATA.loyal3.rows]} />
        </TableSection>
      </BodyWrap>
    );
  };

  return (
    <Container>
      <SectionWrap className="pb-0" {...rest}>
        <LeaderboardSummaryWrap
          leaderboard={true}
          headerTitle="Season 1 event tasks"
          renderBody={renderBody}
          headerBackgroundColor={HEADER_COLOR}
          headerPattern="labyrinth"
          gradient={BACKGROUND_COLOR}
          // eventEndAt={timestampEvent}
          // share={<ButtonWrap variant="secondary">Share on Twit-X</ButtonWrap>} // TODO Fix this button
          borderColor="#5F1AA6"
          spinnerColor="white"
          condensedTimestamp
          subHeader="points updated hourly"
        />
      </SectionWrap>
    </Container>
  );
};

export const TableSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;

  @media (${device.lg}) {
    gap: 20px;
  }
`;

export const TableSection = ({ title, children }) => {
  return (
    <TableSectionWrap>
      <Typography
        tag={"h3"}
        variant={"textMd"}
        md={{ variant: "textXl" }}
        xl={{ variant: "displaySm" }}
      >
        {title}
      </Typography>
      <Divider color="rgba(255, 255, 255, 0.10)" />
      {children}
    </TableSectionWrap>
  );
};
