import { atom } from "jotai";

export * from "./lands";
export * from "./items";
export * from "./unicorns";
export * from "./claims";
export * from "./shadowcorns";
export * from "./shadowcornItems";
export * from "./minions";
export * from "./tokens";
export * from "./scroll";

export const leaderboardAtom = atom({
  top: null,
  quartiles: null,
  position: null,
  self: { score: 0 },
  addressNotFound: false,
});

export const walletConnectedAtom = atom(false);
export const currentWalletAddressAtom = atom(null);
export const trimmedCurrentWalletAddress = atom((get) => {
  let _address = get(currentWalletAddressAtom);
  return _address ? `${_address.slice(0, 6)}...${_address.slice(-4)}` : "";
});
export const signerAtom = atom(null);
