import React, { useMemo } from "react";
import { useAtomValue } from "jotai/index";
import styled from "styled-components";

import { currentWalletAddressAtom } from "../../store";
import { LeaderboardSummary } from "../leaderboard/LeaderboardSummary";
import { Container } from "../base/Container";
import { Button } from "../base/Button";
import { SectionWrap } from "../../views/Airdrop";
import { device } from "../../theme/mediaQuery";
import { Table, VARIANTS } from "./Table";

import common from "../../img/rarity/commonShadowcorn.png";
import rare from "../../img/rarity/rareShadowcorn.png";
import mythic from "../../img/rarity/mythicShadowcorn.png";

import commonAlt from "../../img/rarity/common-alt.png";
import rareAlt from "../../img/rarity/rare-alt.png";
import mythicAlt from "../../img/rarity/mythic-alt.png";

import { Divider } from "./Stats";
import { Typography } from "../base/Typography";

const HEADER_COLOR = "linear-gradient(180deg, #4BADF1 0%, #4D73F4 100%)";
const BACKGROUND_COLOR = "rgba(63, 82, 162, 0.40)";

export const LeaderboardSummaryWrap = styled(LeaderboardSummary)`
  @media (${device.lg}) {
    min-height: 1627px;
  }
`;

export const BodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 16px;
  @media (${device.md}) {
    padding: 18px 16px 60px;
  }
  @media (${device.xl}) {
    gap: 32px;
    padding: 21px 21px 80px;
  }
`;

export const ButtonWrap = styled(Button)`
  width: fit-content;
  margin-left: auto;

  @media (${device.lg}) {
    position: absolute;
    right: 20px;
    margin-left: unset;
  }
`;

export const EventTasksPreseason = ({ leaderboard, ...rest }) => {
  const walletAddress = useAtomValue(currentWalletAddressAtom);

  const self = walletAddress ? leaderboard?.self : null;
  const pointsData = self?.points_data ? self?.points_data : null;

  const TABLE_DATA = useMemo(() => {
    return {
      //  Purple - Be Early
      early1: {
        label: null,
        rows: [
          {
            label: "Hatch a genesis unicorn",
            detail: "60 points each",
            quantity: pointsData?.num_genesis_corn_hatch
              ? parseInt(pointsData.num_genesis_corn_hatch).toLocaleString("en")
              : 0,
            points: pointsData?.num_genesis_corn_hatch_points
              ? parseInt(
                  pointsData.num_genesis_corn_hatch_points
                ).toLocaleString("en")
              : 0,
          },
          {
            label: "Participate in land sale",
            detail: "20 points each",
            quantity: pointsData?.num_land_mint
              ? parseInt(pointsData.num_land_mint).toLocaleString("en")
              : 0,
            points: pointsData?.num_land_mint_points
              ? parseInt(pointsData.num_land_mint_points).toLocaleString("en")
              : 0,
          },
        ],
      },
      early2: {
        label: "Hatch a Shadowcorn Egg",
        rows: [
          {
            iconSrc: common,
            iconAlt: "Common",
            label: "Common",
            detail: "250 points each",
            quantity: pointsData?.sc_mint_common
              ? parseInt(pointsData.sc_mint_common).toLocaleString("en")
              : 0,
            points: pointsData?.sc_mint_common_points
              ? parseInt(pointsData.sc_mint_common_points).toLocaleString("en")
              : 0,
          },
          {
            iconSrc: rare,
            iconAlt: "Rare",
            label: "Rare",
            detail: "500 points each",
            quantity: pointsData?.sc_mint_rare
              ? parseInt(pointsData.sc_mint_rare).toLocaleString("en")
              : 0,
            points: pointsData?.sc_mint_rare_points
              ? parseInt(pointsData.sc_mint_rare_points).toLocaleString("en")
              : 0,
          },
          {
            iconSrc: mythic,
            iconAlt: "Mythic",
            label: "Mythic",
            detail: "1,250 points each",
            quantity: pointsData?.sc_mint_mythic
              ? parseInt(pointsData.sc_mint_mythic).toLocaleString("en")
              : 0,
            points: pointsData?.sc_mint_mythic_points
              ? parseInt(pointsData.sc_mint_mythic_points).toLocaleString("en")
              : 0,
          },
        ],
      },

      // Pink - Be Engaged
      engaged: {
        label: null,
        rows: [
          {
            label: "Breed a Unicorn",
            detail: "10 points each",
            quantity: pointsData?.num_breeds
              ? parseInt(pointsData.num_breeds).toLocaleString("en")
              : 0,
            points: pointsData?.num_breeds_points
              ? parseInt(pointsData.num_breeds_points).toLocaleString("en")
              : 0,
          },
          {
            label: "Evolve a baby",
            detail: "15 points each",
            quantity: pointsData?.num_evos
              ? parseInt(pointsData.num_evos).toLocaleString("en")
              : 0,
            points: pointsData?.num_evos_points
              ? parseInt(pointsData.num_evos_points).toLocaleString("en")
              : 0,
          },
          {
            label: "Hold a badge",
            detail: "25 points each",
            quantity: pointsData?.num_badges
              ? parseInt(pointsData.num_badges).toLocaleString("en")
              : 0,
            points: pointsData?.num_badges_points
              ? parseInt(pointsData.num_badges_points).toLocaleString("en")
              : 0,
          },
        ],
      },

      // Blue - Be Loyal
      loyal1: {
        label: "Hold a shadowcorn",
        rows: [
          {
            iconSrc: common,
            iconAlt: "Common",
            label: "Common",
            detail: "500 points each",
            quantity: pointsData?.sc_held_common
              ? parseInt(pointsData.sc_held_common).toLocaleString("en")
              : 0,
            points: pointsData?.sc_held_common_points
              ? parseInt(pointsData.sc_held_common_points).toLocaleString("en")
              : 0,
          },
          {
            iconSrc: rare,
            iconAlt: "Rare",
            label: "Rare",
            detail: "1,000 points each",
            quantity: pointsData?.sc_held_rare
              ? parseInt(pointsData.sc_held_rare).toLocaleString("en")
              : 0,
            points: pointsData?.sc_held_rare_points
              ? parseInt(pointsData.sc_held_rare_points).toLocaleString("en")
              : 0,
          },
          {
            iconSrc: mythic,
            iconAlt: "Mythic",
            label: "Mythic",
            detail: "2,500 points each",
            quantity: pointsData?.sc_held_mythic
              ? parseInt(pointsData.sc_held_mythic).toLocaleString("en")
              : 0,
            points: pointsData?.sc_held_mythic_points
              ? parseInt(pointsData.sc_held_mythic_points).toLocaleString("en")
              : 0,
          },
        ],
      },
      loyal2: {
        label: "Hold land",
        rows: [
          {
            iconSrc: commonAlt,
            iconAlt: "Common",
            label: "Common",
            detail: "25 points each",
            quantity: pointsData?.num_land_common
              ? parseInt(pointsData.num_land_common).toLocaleString("en")
              : 0,
            points: pointsData?.num_land_common_points
              ? parseInt(pointsData.num_land_common_points).toLocaleString("en")
              : 0,
          },
          {
            iconSrc: rareAlt,
            iconAlt: "Rare",
            label: "Rare",
            detail: "50 points each",
            quantity: pointsData?.num_land_rare
              ? parseInt(pointsData.num_land_rare).toLocaleString("en")
              : 0,
            points: pointsData?.num_land_rare_points
              ? parseInt(pointsData.num_land_rare_points).toLocaleString("en")
              : 0,
          },
          {
            iconSrc: mythicAlt,
            iconAlt: "Mythic",
            label: "Mythic",
            detail: "125 points each",
            quantity: pointsData?.num_land_mythic
              ? parseInt(pointsData.num_land_mythic).toLocaleString("en")
              : 0,
            points: pointsData?.num_land_mythic_points
              ? parseInt(pointsData.num_land_mythic_points).toLocaleString("en")
              : 0,
          },
        ],
      },
      loyal3: {
        label: null,
        rows: [
          {
            label: "Stake CULP",
            detail: "15 points each",
            quantity: pointsData?.num_culp_staked
              ? parseInt(pointsData.num_culp_staked).toLocaleString("en")
              : 0,
            points: pointsData?.num_culp_staked_points
              ? parseInt(pointsData.num_culp_staked_points).toLocaleString("en")
              : 0,
          },
        ],
      },
    };
  }, [pointsData]);

  const renderBody = (leaderboard) => {
    return (
      <BodyWrap>
        {/* Purple - Be Early */}
        <TableSection title="Be Early">
          <Table variant={VARIANTS.PURPLE} rows={[TABLE_DATA.early1.rows[0]]} />
          <Table
            title={TABLE_DATA.early2.label}
            variant={VARIANTS.PURPLE}
            rows={[...TABLE_DATA.early2.rows]}
            hasIcon={true}
          />
          <Table variant={VARIANTS.PURPLE} rows={[TABLE_DATA.early1.rows[1]]} />
        </TableSection>

        {/* Pink - Be Engaged */}
        <TableSection title="Be engaged" variant={VARIANTS.PINK}>
          <Table variant={VARIANTS.PINK} rows={[TABLE_DATA.engaged.rows[0]]} />
          <Table variant={VARIANTS.PINK} rows={[TABLE_DATA.engaged.rows[1]]} />
          <Table variant={VARIANTS.PINK} rows={[TABLE_DATA.engaged.rows[2]]} />
        </TableSection>

        {/* Blue - Be Loyal */}
        <TableSection title="Be loyal">
          <Table
            title={TABLE_DATA.loyal1.label}
            rows={[...TABLE_DATA.loyal1.rows]}
            hasIcon={true}
          />
          <Table
            title={TABLE_DATA.loyal2.label}
            rows={[...TABLE_DATA.loyal2.rows]}
            hasIcon={true}
          />
          <Table rows={[...TABLE_DATA.loyal3.rows]} />
        </TableSection>
      </BodyWrap>
    );
  };

  return (
    <Container>
      <SectionWrap className="pb-0" {...rest}>
        <LeaderboardSummaryWrap
          leaderboard={true}
          headerTitle="Preseason event tasks"
          renderBody={renderBody}
          headerBackgroundColor={HEADER_COLOR}
          headerPattern="labyrinth"
          gradient={BACKGROUND_COLOR}
          // share={<ButtonWrap variant="secondary">Share on Twit-X</ButtonWrap>} // TODO Fix this button
          borderColor="#3C83EB"
          spinnerColor="white"
          collapsible
          collapsed
          titleSmall
        />
      </SectionWrap>
    </Container>
  );
};

export const TableSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;

  @media (${device.lg}) {
    gap: 20px;
  }
`;

export const TableSection = ({ title, children }) => {
  return (
    <TableSectionWrap>
      <Typography
        tag={"h3"}
        variant={"textMd"}
        md={{ variant: "textXl" }}
        xl={{ variant: "displaySm" }}
      >
        {title}
      </Typography>
      <Divider color="rgba(255, 255, 255, 0.10)" />
      {children}
    </TableSectionWrap>
  );
};
