import { useState } from "react";
import styled from "styled-components";
import { TokenCard } from "../TokenCard";
import { getAttribute } from "../../Helpers";
import { InventoryTokens } from "../InventoryTokens";
import Spinner from "../../base/Spinner";
import { Typography } from "../../base/Typography";
import { Button } from "../../base/Button";
import { ShadowcornModal } from "../ShadowcornModal";
import { BUY } from "../../../consts/externalLinks";
import { useGetInventoryShadowcorns } from "../../../query/inventory";

const PlaceholderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px;
  min-height: 528px;
  padding: 0 10px;
`;

const SpinnerWrap = styled.div`
  & svg {
    margin: 0;
  }
`;

const filters = [
  {
    title: "Class",
    options: [
      { value: null, title: "All", icon: "all" },
      { value: "Fire", title: "Fire", icon: "fire" },
      { value: "Nebula", title: "Nebula", icon: "nebula" },
      { value: "Slime", title: "Slime", icon: "slime" },
      { value: "Soul", title: "Soul", icon: "soul" },
      { value: "Volt", title: "Volt", icon: "volt" },
    ],
    id: "class",
    getter: (item) => getAttribute("Class", item),
  },
  {
    title: "Rarity",
    options: [
      { value: null, title: "All", icon: "all" },
      { value: "Mythic", title: "Mythic", icon: "mythic" },
      { value: "Rare", title: "Rare", icon: "rare" },
      { value: "Common", title: "Common", icon: "common" },
    ],
    id: "rarity",
  },
];

const filterGetters = {
  rarity: (item) => getAttribute("Rarity", item),
  class: (item) => getAttribute("Class", item),
};

export function InventoryShadowcorns() {
  // const { balance, data, loading, loadCount } = useAtomValue(shadowcornsAtom);
  const { data, isLoading } = useGetInventoryShadowcorns();
  const [showModal, setShowModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);

  const balance = data?.length || 0;

  const handleSelectShadowcorn = (item) => {
    setSelectedToken(item);
    setShowModal(true);
  };

  let _toolbarTitle = `${balance} shadowcorns owned`;

  if (isLoading) {
    return (
      <PlaceholderWrap>
        <Typography
          tag={"h3"}
          variant={"textLg"}
          color="black"
          className="mb-2"
        >
          Loading shadowcorns
        </Typography>

        <SpinnerWrap>
          <Spinner color="black" />
        </SpinnerWrap>
      </PlaceholderWrap>
    );
  }

  return (
    <>
      {balance > 0 && (
        <>
          <InventoryTokens
            title={_toolbarTitle}
            subtitle={"Staked Shadowcorns won't appear in your inventory"}
            data={data}
            filters={filters}
            filterGetters={filterGetters}
            renderCard={(item) => (
              <TokenCard
                onClick={() => handleSelectShadowcorn(item)}
                image={`../../videos/shadowcorns/shadowcorn_${getAttribute(
                  "Class",
                  item
                ).toLowerCase()}_${getAttribute(
                  "Rarity",
                  item
                ).toLowerCase()}.mp4`}
                name={item.name}
                rightAttribute={{
                  title: "Class",
                  value: getAttribute("Class", item),
                }}
                leftAttribute={{
                  title: "LifeCycle",
                  value: getAttribute("Rarity", item),
                }}
                isVideo={true}
              />
            )}
          />
          <ShadowcornModal
            showModal={showModal}
            setShowModal={setShowModal}
            shadowcorn={selectedToken}
          />
        </>
      )}
      {balance === 0 && (
        <PlaceholderWrap>
          <Typography tag={"h3"} variant={"textLg"} color="gray300">
            😈 You’re not yet part of the dark side 😈
          </Typography>
          <Typography tag={"h3"} variant={"textMd"} color="gray300">
            Embrace the chaos, and unleash the power of the Shadowcorns. Get
            yours from Marketplace.
          </Typography>
          <a
            href={BUY.MARKETPLACE.SHADOWCORN}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button block={true} prependIcon="cart">
              Visit Marketplace
            </Button>
          </a>
          <Typography tag={"p"} variant={"textSm"} color="gray300">
            Staked Shadowcorns won't appear in your inventory
          </Typography>
        </PlaceholderWrap>
      )}
    </>
  );
}
