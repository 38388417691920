import styled from "styled-components";

import { Row } from "../../base/Row";
import { Col } from "../../base/Col";
import { Typography } from "../../base/Typography";
import { Box } from "../../base/Box";

function Milestone({ milestone, background, accent1, accent2 }) {
  return (
    <>
      <Col md={4} lg={3} className="d-flex justify-content-center align-items-center my-3 my-md-0">
        <Box
          variant="gradient"
          rounded="md"
          color={`${background ? background : "white"}`}
          className="d-flex flex-column justify-content-center align-items-center py-3 h-100"
        >
          <Typography
            tag={"h3"}
            variant={"textMd"}
            color={`${accent1 ? accent1 : "darkBlue300"}`}
            style={{ textAlign: "center" }}
            className="pb-2"
          >
            {milestone.title}
          </Typography>
          <Col
            xs={4}
            className="d-flex justify-content-center align-items-center py-1"
          >
            <img
              src={`/img/leaderboard/${milestone.image}.png`}
              alt={`${milestone.title} Milestone`}
              style={{ width: "100%" }}
            />
          </Col>

          <Typography
            tag={"h3"}
            variant={"textMd"}
            color={`${accent2 ? accent2 : "darkBlue300"}`}
            style={{ textAlign: "center" }}
            className="pt-2"
          >
            {milestone.score ? milestone.score.toLocaleString("en") : 0} POINTS
          </Typography>
        </Box>
      </Col>
    </>
  );
}

export const HintWrap = styled.div`
  text-align: center;
  padding-top: 35px;
`;


export function PastMilestones({
  milestones,
  hintColor,
  background,
  accent1,
  accent2,
}) {
  const milestone1 = milestones?.milestone1;
  const milestone2 = milestones?.milestone2;
  const milestone3 = milestones?.milestone3;
  return (
    <>
      <Row className="d-flex justify-content-center">
        <Typography
          tag={"h3"}
          variant={"displaySm"}
          lg={{
            variant: "displayLg",
          }}
          color="white"
          style={{ textAlign: "center" }}
          className="py-3"
        >
          PAST MILESTONES
        </Typography>
        {milestone1 && (
          <Milestone
            milestone={milestone1}
            background={background}
            accent1={accent1}
            accent2={accent2}
          />
        )}
        {milestone2 && (
          <Milestone
            milestone={milestone2}
            background={background}
            accent1={accent1}
            accent2={accent2}
          />
        )}
        {milestone3 && (
          <Milestone
            milestone={milestone3}
            background={background}
            accent1={accent1}
            accent2={accent2}
          />
        )}
      </Row>
      <Row>
        <Col>
          <HintWrap>
            <Typography
              color={hintColor ? hintColor : "darkBlue300"}
              tag="p"
              weight={600}
              family="body"
            >
              Points may take up to 24 hours to update
            </Typography>
          </HintWrap>
        </Col>
      </Row>
    </>
  );
}
