import { Box } from "../../base/Box";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import { StakingTokenImage } from "../StakingTokenImage";
import { StakingTokenTypes } from "../constants";

export const StakingRewardsCardTypes = {
  cu: "cu",
  culp: "culp",
  grail: "grail",
  arb: "arb",
  xai: "xai",
};

const TokenTitlesMap = {
  [StakingTokenTypes.cu]: "CU",
  [StakingTokenTypes.culp]: "CULP",
  [StakingTokenTypes.grail]: "Grail",
  [StakingTokenTypes.arb]: "ARB",
  [StakingTokenTypes.xai]: "Xai",
  [StakingTokenTypes.rbw]: "RBW",
};

const TokenSubtitlesMap = {
  [StakingTokenTypes.cu]: "Crypto Unicorn Token",
  [StakingTokenTypes.culp]: "CU/ETH LP Token",
  [StakingTokenTypes.grail]: "Grail",
  [StakingTokenTypes.arb]: "Arbitrum",
  [StakingTokenTypes.xai]: "Xai",
  [StakingTokenTypes.rbw]: "Crypto Unicorn",
};

const BackgroundColorsMap = {
  [StakingTokenTypes.cu]: "linear-gradient(180deg, #FC6CB2 0%, #780139 100%)",
  [StakingTokenTypes.culp]: "linear-gradient(180deg, #6F19B8 0%, #2D0D78 100%)",
  [StakingTokenTypes.grail]: "linear-gradient(180deg, #BB5A1B 0%, #953E13 100%)",
  [StakingTokenTypes.arb]: "linear-gradient(180deg, #466E87 0%, #19343D 100%)",
  [StakingTokenTypes.xai]: "linear-gradient(180deg, #A62F16 0%, #7C2011 100%)",
  [StakingTokenTypes.rbw]: "linear-gradient(180deg, #FC6CB2 0%, #780139 100%)",
};

const SectionColorsMap = {
  [StakingTokenTypes.cu]: "#940052",
  [StakingTokenTypes.culp]: "#231852",
  [StakingTokenTypes.grail]: "#662300",
  [StakingTokenTypes.arb]: "#1B3640",
  [StakingTokenTypes.xai]: "#6E2002",
  [StakingTokenTypes.rbw]: "#940052",
};

const SectionTitleColorsMap = {
  [StakingTokenTypes.cu]: "#FFC7E6",
  [StakingTokenTypes.grail]: "#FFDECC",
  [StakingTokenTypes.arb]: "#E5EBF0",
  [StakingTokenTypes.xai]: "#FED6C8",
  [StakingTokenTypes.culp]: "#FFC7E6",
  [StakingTokenTypes.rbw]: "#FFC7E6",
};

const SectionDividerColorsMap = {
  [StakingTokenTypes.cu]: "#DB007A",
  [StakingTokenTypes.culp]: "#4B00FF",
  [StakingTokenTypes.grail]: "#AD3B00",
  [StakingTokenTypes.arb]: "#415C72",
  [StakingTokenTypes.xai]: "#B33504",
  [StakingTokenTypes.rbw]: "#DB007A",
};

export const StakingRewardsCardSection = styled.div`
  border-radius: 16px;
  flex: 1;

`;

export const StakingRewardsCardSectionDivider = styled.div`
  height: 1px;
  width: 100%;
`;

const Wrap = styled(Box)`
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${StakingRewardsCardSection} {
    background: ${({ $tokenType }) => SectionColorsMap[$tokenType]};
    color: ${({ $tokenType }) => SectionTitleColorsMap[$tokenType]};
  }

  ${StakingRewardsCardSectionDivider} {
    background: ${({ $tokenType }) => SectionDividerColorsMap[$tokenType]};
  }

  @media (${device.xl}) {
    padding: 30px;
  }
`;

const HeadingWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const HeadingImage = styled.div`
  width: 40px;
  height: 40px;

  & img {
    width: 100%;
    height: 100%;
  }

  @media (${device.lg}) {
    width: 80px;
    height: 80px;
  }
`;

const HeadingTitle = styled.h3`
  font-size: 28px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};

  @media (${device.lg}) {
    font-size: 42px;
  }
`;

const HeadingSubtitle = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};

  @media (${device.lg}) {
    font-size: 20px;
  }
`;

const TokenAvatarTitle = styled.h3`
  font-size: 26px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
`;

const TokenAvatarSubtitle = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
`;

const TokenAvatarWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;

  @media (${device.sm}) {
    align-items: center;
    flex-direction: row;
  }
`;

const TokenAvatarImage = styled.div`
  width: 40px;
  height: 40px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

export function StakingRewardsCard({ token, children, action }) {
  const title = TokenTitlesMap[token];
  const subtitle = TokenSubtitlesMap[token];
  const backgroundColor = BackgroundColorsMap[token];

  return (
    <Wrap
      pattern="sprinkles"
      color={backgroundColor}
      $tokenType={token}
    >
      <HeadingWrap>
        <HeadingImage>
          <StakingTokenImage token={token} />
        </HeadingImage>
        <div>
          <HeadingTitle>
            {title}
          </HeadingTitle>
          <HeadingSubtitle>
            {subtitle}
          </HeadingSubtitle>
        </div>
      </HeadingWrap>
      {children}
      {action}
    </Wrap>
  );
}

export function StakingRewardsBox({ token, ...rest }) {
  return (
    <Box
      pattern="sprinkles"
      color={BackgroundColorsMap[token]}
      {...rest}
    />
  );
}

export function StakingRewardTokenAvatar({ token }) {
  return (
    <TokenAvatarWrap>
      <TokenAvatarImage>
        <StakingTokenImage token={token} />
      </TokenAvatarImage>
      <div>
        <TokenAvatarTitle>
          {TokenTitlesMap[token]}
        </TokenAvatarTitle>
        <TokenAvatarSubtitle>
          {TokenSubtitlesMap[token]}
        </TokenAvatarSubtitle>
      </div>
    </TokenAvatarWrap>
  );
}