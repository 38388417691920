import styled, { keyframes } from "styled-components";
import { StakingCommonCard, StakingCommonCardHeader, StakingCommonCardTitle } from "../StakingCommonCard";
import { device } from "../../../theme/mediaQuery";
import { useRef } from "react";
import Spinner from "../../base/Spinner";
import { StakingPaginatedList } from "../StakingPaginatedList";

const TableHeadingWrap = styled.div`
  height: 64px;
  display: none;
  align-items: center;
  padding: 0 20px;
  gap: 20px;

  @media (${device.xl}) {
    padding: 0 80px;
    text-align: center;
    display: flex;
  }
`;

const TableHeadingMobileWrap = styled(TableHeadingWrap)`
  display: flex;

  @media (${device.xl}) {
    display: none;
  }
`;

export const StakingBadgeTableHeading = styled(StakingCommonCardTitle)`
  font-size: 16px;
  @media (${device.xl}) {
    font-size: 20px;
  }
`;

const BodyWrap = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  @media (${device.xl}) {
    padding: 40px;
    min-height: 254px;
    gap: 40px;
  }
`;

export const StakingBadgeTableRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  background: #231852;
  border-radius: 16px;
  gap: 20px;

  @media (${device.xl}) {
    align-items: center;
    flex-direction: row;
    min-height: 86px;
  }

  @media (${device.xxl}) {
    padding: 0 40px;
  }
`;

const appearKeyframes = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

export const StakingBadgeTableCellWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  flex: ${({ $flex }) => $flex || 1};
  font-size: 20px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
  line-height: 1;
  text-align: center;
  animation: ${appearKeyframes} 0.5s ease;
  overflow: hidden;
  @media (${device.xl}) {
    display: block;
    width: auto;

  }
`;

const TableCellMobileTitle = styled(StakingCommonCardTitle)`
  font-size: 14px;

  @media (${device.xl}) {
    display: none;
  }
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
`;

export function StakingBadgeTable({
  headings,
  items,
  renderRow,
  headingMobileTitle,
  isLoading = false,
}) {
  const headingRef = useRef(null);


  const showPlaceholder = !isLoading && items.length === 0;

  const scrollToHeading = () => {
    // we need to scroll after the state is updated
    // so we use setTimeout with 0 delay
    setTimeout(() => {
      headingRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };


  return (
    <StakingCommonCard>
      <StakingCommonCardHeader ref={headingRef}>
        <TableHeadingWrap>
          {headings}
        </TableHeadingWrap>
        <TableHeadingMobileWrap>
          <StakingBadgeTableHeading>
            {headingMobileTitle}
          </StakingBadgeTableHeading>
        </TableHeadingMobileWrap>
      </StakingCommonCardHeader>
      <BodyWrap>
        {isLoading && (
          <LoadingWrap>
            <Spinner />
          </LoadingWrap>
        )}
        {!isLoading && !showPlaceholder && (
          <StakingPaginatedList onPageChange={scrollToHeading} items={items} renderRow={renderRow} />
        )}
        {showPlaceholder && <Placeholder>No badges to show</Placeholder>}
      </BodyWrap>
    </StakingCommonCard>
  );
}

export function StakingBadgeTableCell({ children, title, ...rest }) {
  return (
    <StakingBadgeTableCellWrap {...rest}>
      {title && <TableCellMobileTitle>{title}</TableCellMobileTitle>}
      {children}
    </StakingBadgeTableCellWrap>
  );
}
