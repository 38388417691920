import styled from "styled-components";
import { Box } from "../base/Box";
import { Typography } from "../base/Typography";
import { Link } from "react-router-dom";
import { Icon } from "../base/Icon";
import { device } from "../../theme/mediaQuery";
import { COMMUNITY } from "../../consts/externalLinks";

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 24px 20px;
  flex-wrap: wrap;

  @media (${device.lg}) {
    flex-wrap: nowrap;
    padding: 24px 0;
  }
`;

const IconLinkWrap = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.white};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #afb6ff;
  }
`;

const TitleWrap = styled.div`
  flex-basis: 100%;
  text-align: center;
  @media (${device.lg}) {
    flex-basis: auto;
    text-align: left;
  }
`;

export function NavMenuSocialsFooter() {
  return (
    <Box gradient="linearBlueLight" pattern="labyrinth" patternSize="50%">
      <Wrap>
        <TitleWrap>
          <Typography family="display" variant="textLg">
            Engage with the community. join us on
          </Typography>
        </TitleWrap>
        <IconLinkWrap to={COMMUNITY.DISCORD} target="_blank">
          <Icon name="discord" />
        </IconLinkWrap>
        <IconLinkWrap to={COMMUNITY.YOUTUBE} target="_blank">
          <Icon name="youtube" />
        </IconLinkWrap>
        <IconLinkWrap to={COMMUNITY.TWITTER} target="_blank">
          <Icon name="twitter" />
        </IconLinkWrap>
        <IconLinkWrap to={COMMUNITY.SUBSTACK} target="_blank">
          <Icon name="substack" />
        </IconLinkWrap>
      </Wrap>
    </Box>
  );
}
