import { Link } from "react-router-dom";
import styled from "styled-components";
import { Row } from "./Row";
import { Col } from "./Col";
import { Typography } from "./Typography";
import { Button } from "./Button";
import { EVENT_KEYS } from "../../consts/googleEvents";
import { googleAnalyticsEvent } from "../../helpers/googleAnalytics";

const GameApkButtonWrap = styled(Link)`
  background: ${({ theme, $background }) =>
    $background ? $background : theme.palette.indigo300};
  text-shadow: ${({ theme }) =>
    `0px 1px 0px ${theme.components.button.primary.default.textShadowColor}`};
  border-radius: 16px;
  padding: ${({ $small }) => ($small ? "12px" : "12px 16px")};
  display: block;
  text-decoration: none;
  text-align: left;
  color: ${({ theme }) => theme.palette.white};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.white};
  }

  & > * {
    height: 100%;
  }
`;

const GameApkButtonMobileWrap = styled.div`
  background: ${({ theme, $background }) =>
    $background ? $background : theme.palette.indigo300};
  text-shadow: ${({ theme }) =>
    `0px 1px 0px ${theme.components.button.primary.default.textShadowColor}`};
  border-radius: 16px;
  padding: ${({ $small }) => ($small ? "12px" : "12px 16px")};
  display: block;
  text-decoration: none;
  text-align: left;
  color: ${({ theme }) => theme.palette.white};
  cursor: default;
  height: 100%;

  &:hover {
    color: ${({ theme }) => theme.palette.white};
  }

  & > * {
    height: 100%;
  }
`;

const QrWrap = styled.img`
  max-width: 85px;
  width: 100%;
  padding-left: 0;
`;

export function GameApkButton({
  qr,
  link,
  event,
  background,
  small = false,
  mobileHidden = false,
}) {
  const handleAnalytics = () => {
    if (event) {
      googleAnalyticsEvent(EVENT_KEYS.PLAY_GAME, event);
    }
  };
  return (
    <>
      {/* Desktop only */}
      <GameApkButtonWrap
        to={link}
        $small={small}
        $background={background}
        target="_blank"
        onClick={handleAnalytics}
        className="d-none d-sm-block"
      >
        <Row className="align-items-center">
          <Col xs={10}>
            <Typography
              variant="textSm"
              weight={700}
              color="white"
              family="display"
              tag="h5"
              xl={{
                variant: small ? "textXs" : "textLg",
              }}
            >
              Scan to DOWNLOAD APK FOR ANDROID TO PLAY!
            </Typography>
            <Typography
              variant="textXs"
              family={"body"}
              weight={700}
              color="white"
              tag="p"
              xl={{
                variant: small ? "text2xs" : "textSm",
              }}
              style={{ textTransform: "none", textShadow: "none" }}
            >
              Scan here to download the game and start playing, you need to
              download APK in order to play on your android!
            </Typography>
          </Col>
          <Col xs={2}>
            <QrWrap src={qr} />
          </Col>
        </Row>
      </GameApkButtonWrap>

      {/* Mobile only */}
      {!mobileHidden && (
        <GameApkButtonMobileWrap
          to={link}
          $small={small}
          $background={background}
          target="_blank"
          onClick={handleAnalytics}
          className="d-block d-sm-none"
        >
          <Row className="justify-content-center">
            <Typography
              variant="textSm"
              weight={700}
              color="white"
              family="body"
              tag="h5"
              xl={{
                variant: small ? "textXs" : "textLg",
              }}
            >
              Download apk for android to play
            </Typography>
            <Typography
              variant="textXs"
              family="body"
              color="white"
              tag="p"
              xl={{
                variant: small ? "text2xs" : "textSm",
              }}
              style={{ textTransform: "none", textShadow: "none" }}
            >
              Click here to download the game and start playing, you need to
              download APK in order to play on your android
            </Typography>
            <Button
              variant="primary"
              to={link}
              target="_blank"
              onClick={handleAnalytics}
              textStyle={{
                margin: "16px 0 0",
                textShadow: "0px 1px 0px #003E8A",
              }}
              as={Link}
            >
              Download APK
            </Button>
          </Row>
        </GameApkButtonMobileWrap>
      )}
    </>
  );
}
