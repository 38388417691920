import React from "react";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 105px;
  background: #e0e0fc;
  border-radius: 20px;
  padding: 12px 24px;
  gap: 8px;

  @media (${device.sm}) {
    padding: 20px 40px;
    flex-direction: row;
    align-items: center;
  }
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  width: 90%;
  margin-right: auto;
  @media (${device.sm}) {
    margin-right: unset;
  }
`;

const InputField = styled.input`
  background: transparent;
  border: none;
  height: 100%;
  width: 100%;
  font-size: 30px;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.display};
  color: ${({ disabled }) => (disabled ? "#C9ABFF" : "#5338c2")};

  border-radius: 0;

  @media (${device.md}) {
    font-size: 41px;
  }

  ::placeholder {
    color: ${({ theme }) => theme.palette.black};
    opacity: 0.3;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
`;

const AppendWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 45%;
  white-space: break-spaces;
  text-align: right;
  margin-left: 8px;
`;

export default function Input({ value, onChange, disabled, append }) {
  const handleValueChange = (value) => {
    onChange(value);
  };

  let handleChange = (e) => {
    let value = e.target.value;
    handleValueChange(value);
  };

  return (
    <Wrap>
      <InputWrap>
        <InputField
          type="text"
          placeholder={"0"}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        />
      </InputWrap>
      {append && <AppendWrap>{append}</AppendWrap>}
    </Wrap>
  );
}
