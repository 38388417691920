import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { device } from "../../theme/mediaQuery";
import { Icon } from "../base/Icon";
import { useEffect, useState } from "react";

const TABS = [
  {
    key: "/staking/overview",
    title: "Overview",
  },
  {
    key: "/staking/rewards",
    title: "Rewards",
  },
  {
    key: "/staking/badges",
    title: "Badges",
  },
  {
    key: "/staking/deposits",
    title: "Deposits",
  },
  {
    key: "/staking/leaderboard",
    title: "Leaderboard",
  },
];

const Wrap = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: ${({ $open }) => ($open ? "500px" : "0")};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  @media (${device.lg}) {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    max-height: none;
  }
`;

const TabItem = styled(Link)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  gap: 10px;
  padding: 0 16px;
  border-radius: 16px;
  min-height: 60px;
  width: 100%;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.white};

  background: transparent;
  border: none;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
  z-index: 1;

  ${({ $active }) => $active && `background: #C186CA;`}
  &:hover {
    background: #C186CA;
    color: ${({ theme }) => theme.palette.white};
  }

  &::after {
    content: "";
    position: absolute;
    background: linear-gradient(180deg, #FCC1FD 0%, #875D9C 60%, rgba(0, 0, 0, 0) 100%) no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 1px;
    z-index: -1;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

const MobileTabToggle = styled(TabItem)`
  display: flex;
  margin-bottom: 12px;

  @media (${device.lg}) {
    display: none;
  }
`;

export function StakingTabs() {
  // get current route
  const route = useLocation();
  const currentTab = TABS.find((tab) => tab.key === route.pathname);
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  // close on route change
  useEffect(() => {
    setOpen(false);
  }, [route.pathname]);

  return (
    <div>
      <MobileTabToggle
        onClick={toggleOpen}
        $active={true}
      >
        {currentTab?.title}
        <Icon name="arrow-down" />
      </MobileTabToggle>
      <Wrap $open={open}>
        {TABS.map((tab) => (
          <li key={tab.key}>
            <TabItem
              $active={route.pathname === tab.key}
              to={tab.key}
            >
              {tab.title}
            </TabItem>
          </li>
        ))}
      </Wrap>
    </div>

  );
}