import styled from "styled-components";
import { Box } from "../../base/Box";
import Spinner from "../../base/Spinner";
import { device } from "../../../theme/mediaQuery";
import { Button } from "../../base/Button";
import { useState } from "react";

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  border-radius: 16px;
`;

const TableContainer = styled.table`
  border-radius: inherit;
  border-collapse: collapse;
  overflow: hidden;

  width: 100%;
`;

const TableHead = styled(Box)`
  color: ${({ theme }) => theme.palette.white};
`;

const TableHeading = styled.th`
  padding: 0 10px;
  white-space: nowrap;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  min-width: ${({ $first }) => ($first ? "270px" : "")};

  @media (${device.lg}) {
    min-width: ${({ $first }) => ($first ? "410px " : "")};
    padding: 0 20px;
  }
`;

const TableRow = styled(Box)`
  height: 50px;
  // box-shadow: inset 0px 7px 7px rgba(254, 253, 254, 0.25),
  //   inset 0px 0px 13px rgba(255, 255, 255, 0.25);

  @media (${device.lg}) {
    height: 70px;
  }

  & > * {
    font-family: ${({ theme }) => theme.fonts.display};
    font-size: 12px;
    text-align: left;

    @media (${device.lg}) {
      font-size: 19px;
    }
  }
`;

const TableCell = styled.td`
  color: ${({ theme }) => theme.palette.darkBlue300};
  white-space: nowrap;
  padding: 0 10px;
  background: linear-gradient(143.22deg, #ffffff 7.24%, #c7c9c9 94.73%);
  box-shadow: 0px 0px 13px 0px #ffffff40 inset, 0px 7px 7px 0px #fefdfe40 inset;

  @media (${device.lg}) {
    padding: 0 20px;
  }
`;

const PlaceholderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px;
  min-height: 300px;
`;

export function Table({
  data,
  headerColor,
  headerData,
  headerPattern = "none",
  patternSize = "50%",
  patternOpacity,
  table,
  pagination = false,
  chunkSize = 5,
  ...rest
}) {
  const [page, setPage] = useState(0);
  const count = data.length / chunkSize - 1;
  if (!data || !headerData) {
    return (
      <PlaceholderWrap>
        <Spinner />
      </PlaceholderWrap>
    );
  }

  const initRes = () => {
    return headerData.map(() => "");
  };

  const Row = ({ res }) => {
    if (res.length === 0) res = initRes();
    const rows = res.map((row, i) => (
      <TableRow key={i} tag="tr" patternTag="td">
        {Object.values(row).map((cell, j) => (
          <TableCell
            key={`${table}-${cell}-${j}`}
            className={j === 0 ? "" : "text-center"}
          >
            {cell}
          </TableCell>
        ))}
      </TableRow>
    ));
    return rows;
  };

  const TableBody = (data) => (
    <TableWrap {...rest}>
      <TableContainer responsive>
        <TableHead
          tag="thead"
          gradient={headerColor}
          variant="gradient"
          pattern={headerPattern}
          patternSize={patternSize}
          patternOpacity={patternOpacity}
          patternTag="tr"
        >
          <TableRow tag="tr">
            {headerData.map((item, i) => (
              <TableHeading
                key={`${table}-header-${i}`}
                $first={i === 0}
                className={i === 0 ? "" : "text-center"}
              >
                {item}
              </TableHeading>
            ))}
          </TableRow>
        </TableHead>
        <tbody>
          <Row res={data} />
        </tbody>
      </TableContainer>
    </TableWrap>
  );

  if (pagination) {
    const handleNext = () => {
      setPage(page + 1);
    };

    const handlePrev = () => {
      setPage(page - 1);
    };

    let chunkedData = [];

    for (let i = 0; i < data.length; i += chunkSize) {
      chunkedData.push(data.slice(i, i + chunkSize));
    }

    return (
      <>
        {TableBody(chunkedData[page])}
        <div className="text-center py-4">
          <div>
            <Button
              variant="secondary"
              onClick={handlePrev}
              disabled={page === 0 || page === null}
              className="me-4"
            >
              {"<"}
            </Button>
            <Button
              variant="secondary"
              onClick={handleNext}
              disabled={page === count}
            >
              {">"}
            </Button>
          </div>
        </div>
      </>
    );
  }
  return <>{TableBody(data)}</>;
}
