import styled from "styled-components";
import { PatternBackground } from "../base/Box";
import React from "react";
import { TrapezoidBox } from "../common/TrapezoidBox";
import { Typography } from "../base/Typography";
import { BridgePendingTransactionItem } from "./BridgePendingTransactionItem";
import { device } from "../../theme/mediaQuery";
import { BridgeCloseIcon } from "./BridgeCloseIcon";
import { useAtomValue } from "jotai";
import { persistedPendingTransactionsAtom } from "../../store/pending";

const Wrap = styled.div`
  position: fixed;
  top: 120px;
  left: 5%;
  right: 5%;

  border-radius: 12px;
  border: 2px solid #b138e6;
  z-index: 102;
  animation: slideIn 0.3s;
  background: linear-gradient(180deg, #6f19b8 0%, #2d0d78 100%);
  padding: 0 28px;
  @keyframes slideIn {
    from {
      transform: translateY(-10px);
    }
    to {
      transform: translateY(0);
    }
  }

  @media (${device.sm}) {
    max-width: 500px;
    left: auto;
    right: 0;
    position: absolute;
    top: calc(100% + 48px);
    min-width: 450px;
  }

  @media (${device.xxl}) {
    position: absolute;
    left: -35%;
    top: calc(100% + 12px);
  }
`;

const TitleWrap = styled(TrapezoidBox).attrs({ $color: "#B138E6" })`
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 36px;
  text-shadow: 0px 2px 0px #3e0f94;
`;

const ListWrap = styled.div`
  padding-top: 8px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CloseIcon = styled(BridgeCloseIcon)`
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;

  @media (${device.xxl}) {
    display: none;
  }
`;

const Placeholder = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export function BridgePendingTransactionsModal({ onClose }) {
  const { transactions } = useAtomValue(persistedPendingTransactionsAtom);

  // // Prevent scrolling on the background
  // useLockBodyScroll();

  return (
    <Wrap>
      <CloseIcon onClick={onClose} />
      <TitleWrap>
        <Typography family="display" color="white" variant="textMd">
          Pending Transactions
        </Typography>
      </TitleWrap>
      <ListWrap>
        {transactions &&
          transactions.map((item, i) => (
            <BridgePendingTransactionItem
              txHash={item.txHash}
              timestamp={item.timestamp}
              amount={item.amount}
              key={`${item.txHash}-${i}`}
              tokenId={item.tokenId}
            />
          ))}
        {transactions.length === 0 && (
          <Placeholder>
            <Typography color="white" variant="textMd" family="display">
              No pending transactions
            </Typography>
          </Placeholder>
        )}
      </ListWrap>
      <PatternBackground
        pattern="sprinkles"
        $patternSize="100%"
        $opacity="0.05"
        as="div"
        className="pattern"
      />
    </Wrap>
  );
}
