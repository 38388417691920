import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "../base/Icon";
import { Typography } from "../base/Typography";
import { FilterIcon } from "../common/FilterIcon";

const FormWrap = styled.button`
  color: ${({ theme }) => theme.palette.gray350};
  background-color: ${({ theme }) => theme.palette.white};
  position: relative;
  cursor: pointer;
  border: ${({ theme }) => `solid 1px ${theme.palette.gray150}`};
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledForm = styled.form`
  background: white;
  color: black;
  border-color: grey;
  cursor: pointer;
  user-select: none;
`;

const StyledUl = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 0.25rem);
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 999;
  overflow: hidden;
  padding: 0.5rem 0;
  border-radius: 0 0 0.5rem 0.5rem;
  row-gap: 0.125rem;

  &.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
`;

const StyledLi = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.5rem;
  width: 100%;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 0.375rem 0.875rem;
  color: ${({ theme }) => theme.palette.darkBlue300};
  background-color: #fcfcfd;

  &:hover {
    background-color: #d7edf9;
  }
`;

const StyledIcon = styled(FilterIcon)`
  width: 24px;
  height: 24px;
`;

const LabelWrap = styled.label`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  column-gap: 8px;
`;

const SelectWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export function Select(props) {
  const { value, options, onChange, label } = props;
  const [active, setActive] = useState(false);

  let _selectedOption = options.find((i) => i.value === value);

  const handleClick = () => {
    setActive((prev) => !prev);
  };

  const handleBlur = () => {
    setActive(false);
  };

  const handleSelect = (value) => {
    setActive(false);
    onChange(value);
  };

  return (
    <SelectWrap>
      <Typography tag={"h3"} variant={"textSm"} color="gray200">
        {label}
      </Typography>
      <FormWrap onClick={handleClick} onBlur={handleBlur}>
        <LabelWrap>
          {_selectedOption?.icon ? (
            <StyledIcon name={_selectedOption?.icon}></StyledIcon>
          ) : (
            <StyledIcon name="all"></StyledIcon>
          )}
          <Typography tag={"h3"} variant={"textMd"} color="darkBlue300">
            {_selectedOption?.title ? _selectedOption?.title : "Show All"}
          </Typography>
        </LabelWrap>
        <div className="select-input">
          <StyledForm type="text" readOnly />
          <Icon name={"arrow-down"} styled={{ width: "5px" }} />
          <StyledUl className={active ? "active" : null}>
            {options.map((i) => (
              <StyledLi
                key={i.value}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelect(i);
                }}
                className="select-menu-item"
              >
                {i.icon && <StyledIcon name={i.icon}></StyledIcon>}
                {i.title}
              </StyledLi>
            ))}
          </StyledUl>
        </div>
      </FormWrap>
    </SelectWrap>
  );
}
