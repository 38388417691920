import styled from "styled-components";
import { Box } from "../base/Box";
import { Typography } from "../base/Typography";
import { CardHeaderVideo } from "../base/CardHeaderVideo";
import { ClassIcon } from "../common/ClassIcon";
import pluralize from "pluralize";
import { useState, useEffect } from "react";

const CardWrap = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  transform: translateY(0px);
  transition: all 0.24s ease;

  &:hover {
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 10px;
  }
`;

const VideoWrap = styled.div`
  height: 320px;
`;

const HeaderImg = styled.img`
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  min-height: 290px;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const ClassImg = styled(ClassIcon)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -55%);
  width: 50px;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 21px 20px;
  ${({ $boxShadow }) => $boxShadow && `box-shadow: ${$boxShadow};`}
  min-height: 85px;
`;

const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Separator = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.11);
  width: 100%;
  margin: 12px 0;
`;

const CountTag = styled.div`
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.25);
  padding: 0px 8px;
  width: fit-content;
  position: absolute;
  top: 16px;
  left: 16px;
`;

const BoxShadowInset = "0px 20px 9px -15px inset"; // inset 0px 5px 9px 2px

const stylesArray = [
  {
    name: "Rainbow",
    color: "linear-gradient(180deg, #A62F16 0%, #7C2011 100%)",
    shadow: `${BoxShadowInset} #FC5F39;`,
  },
  {
    name: "Cloud",
    color: "linear-gradient(180deg, #466E87 0%, #19343D 100%)",
    shadow: `${BoxShadowInset} #6A9CBF;`,
  },
  {
    name: "Star",
    color: "linear-gradient(180deg, #E7B610 0%, #D77F01 100%)",
    shadow: `${BoxShadowInset} #FFE96A;`,
  },
  {
    name: "Heart",
    color: "linear-gradient(180deg, #FC6CB2 0%, #780139 100%)",
    shadow: `${BoxShadowInset} #FFB1F3;`,
  },
  {
    name: "Moon",
    color: "linear-gradient(180deg, #20658F 0%, #001D47 100%)",
    shadow: `${BoxShadowInset} #3CABDB;`,
  },
  {
    name: "OmNom",
    color: "linear-gradient(180deg, #BB5A1B 0%, #953E13 100%)",
    shadow: `${BoxShadowInset} #FE8428;`,
  },
  {
    name: "Crystal",
    color: "linear-gradient(180deg, #6F19B8 0%, #2D0D78 100%)",
    shadow: `${BoxShadowInset} #931EDD;`,
  },
  {
    name: "Flower",
    color: "linear-gradient(180deg, #8BAD36 0%, #00481A 100%)",
    shadow: `${BoxShadowInset} #A7C63D;`,
  },
  {
    name: "Candy",
    color: "linear-gradient(180deg, #33DBBB 0%, #003143 100%)",
    shadow: `${BoxShadowInset} #42fedc;`,
  },
  {
    name: "Mystery",
    color: "linear-gradient(180deg, #304298 0%, #280d3a 100%)",
    shadow: `${BoxShadowInset} #2c59ab;`,
  },
  {
    name: "Light",
    color: "linear-gradient(180deg, #d33854 0%, #520132 100%)",
    shadow: `${BoxShadowInset} #c85932;`,
  },
  {
    name: "Wonder",
    color: "linear-gradient(180deg, #317f56 0%, #033632 100%)",
    shadow: `${BoxShadowInset} #83a668;`,
  },
  {
    name: "Mythic",
    color: "linear-gradient(180deg, #902d79 0%, #471455 100%)",
    shadow: `${BoxShadowInset} #c03d7e;`,
  },
  {
    name: "Fire",
    color: "linear-gradient(180deg, #E86334 0%, #7B1B16 100%)",
    shadow: `${BoxShadowInset} #EFAA3C;`,
  },
  {
    name: "Nebula",
    color: "linear-gradient(180deg, #A63479 0%, #500F46 100%)",
    shadow: `${BoxShadowInset} #B930A6;`,
  },

  {
    name: "Slime",
    color: "linear-gradient(180deg, #96B339 0%, #193526 100%)",
    shadow: `${BoxShadowInset} #B1FB4F;`,
  },
  {
    name: "Soul",
    color: "linear-gradient(180deg, #AA23C3 0%, #6E1395 100%)",
    shadow: `${BoxShadowInset} #E939F3;`,
  },
  {
    name: "Volt",
    color: "linear-gradient(180deg, #3880C0 0%, #245783 100%)",
    shadow: `${BoxShadowInset} #74F9FA;`,
  },
  {
    name: "Default",
    color: "linear-gradient(180deg, #3447AC 0%, #0D153F 100%)",
    shadow: `${BoxShadowInset} #4D66E2`,
  },
];

export function TokenCard({
  image,
  name,
  leftAttribute,
  rightAttribute,
  onClick,
  isVideo,
  showClass = true,
  count,
  countTag,
}) {
  const [isLoading, setIsLoading] = useState(true);

  const cardStyle = (itemClass) => {
    let cardStyle = stylesArray.find((item) => item.name === itemClass);
    if (cardStyle) {
      return cardStyle;
    } else {
      return stylesArray.find((item) => item.name === "Default");
    }
  };

  // reset image once data changes to avoid flicker of old image
  useEffect(() => {
    setIsLoading(true);
  }, [image]);

  let color = cardStyle(rightAttribute.value).color;
  let shadow = cardStyle(rightAttribute.value).shadow;

  return (
    <CardWrap onClick={onClick}>
      <Box
        variant="gradient"
        rounded="sm"
        color={color}
        pattern="sprinkles"
        patternSize="lg"
        patternOpacity="0.05"
      >
        {countTag && (
          <CountTag>
            <Typography
              variant="textSm"
              tag={"p"}
              family={"display"}
              sm={{
                variant: "textLg",
              }}
            >
              {countTag.toLocaleString()}
            </Typography>
          </CountTag>
        )}
        {isVideo ? (
          <VideoWrap>
            <CardHeaderVideo src={image} />
          </VideoWrap>
        ) : (
          <HeaderImg
            src={image}
            alt=""
            onLoad={() => setIsLoading(false)}
            style={{ opacity: isLoading ? 0 : 1 }}
          />
        )}
        <TextBox $boxShadow={shadow}>
          {showClass && rightAttribute && (
            <ClassImg name={rightAttribute?.value?.toLowerCase()} />
          )}
          {leftAttribute && (
            <>
              <TextWrap>
                <div>
                  <Typography
                    tag={"h3"}
                    variant={"textXs"}
                    color="white"
                    style={{ textAlign: "left" }}
                  >
                    {leftAttribute?.title}
                  </Typography>
                  <Typography
                    tag={"h3"}
                    variant={"textMd"}
                    color="white"
                    style={{ textAlign: "left", lineHeight: "24px" }}
                  >
                    {leftAttribute?.value}
                  </Typography>
                </div>
                <div>
                  <Typography
                    tag={"h3"}
                    variant={"textXs"}
                    color="white"
                    style={{ textAlign: "right" }}
                  >
                    {rightAttribute?.title}
                  </Typography>
                  <Typography
                    tag={"h3"}
                    variant={"textMd"}
                    color="white"
                    style={{ textAlign: "right", lineHeight: "24px" }}
                  >
                    {rightAttribute?.value}
                  </Typography>
                </div>
              </TextWrap>
              <Separator />
            </>
          )}
          <Typography
            tag={"h3"}
            variant={"textMd"}
            color="white"
            style={{ textAlign: "center" }}
          >
            {count ? pluralize(name, count, true) : name}
          </Typography>
        </TextBox>
      </Box>
    </CardWrap>
  );
}