import Web3 from "web3";
import { isTestnet, NetworkConfiguration } from "../../components/Network";

class MetadataExceptionError extends Error {
  constructor(message) {
    super(message);
    this.name = "MetadataExceptionError";
  }
}

const abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256"
      }
    ],
    name: "getUnicornMetadata",
    outputs: [
      {
        internalType: "bool",
        name: "origin",
        type: "bool"
      },
      {
        internalType: "bool",
        name: "gameLocked",
        type: "bool"
      },
      {
        internalType: "bool",
        name: "limitedEdition",
        type: "bool"
      },
      {
        internalType: "uint256",
        name: "lifecycleStage",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "breedingPoints",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "unicornClass",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "hatchBirthday",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];

const rpcArr = [
  "https://polygon-rpc.com/",
  "https://rpc.ankr.com/polygon",
  "https://rpc-mainnet.maticvigil.com",
  "https://polygon.llamarpc.com",
  "https://polygon-bor.publicnode.com/",
  "https://1rpc.io/matic",
  "https://polygon-mainnet.public.blastapi.io"
];

async function setWeb3ProviderArray(wallet, balance, timeout = 1500) {
  let web3Arr = [];
  if (Number(balance) < 20 || isTestnet) {
    let walletProvider = new Web3(wallet.provider);
    web3Arr.push(walletProvider);
    return web3Arr;
  } else {
    let walletProvider = new Web3(wallet.provider);
    web3Arr.push(walletProvider);

    async function checkRpc(rpc) {
      return new Promise(async (resolve, reject) => {
        let web3 = new Web3(rpc);
        let timeoutId = setTimeout(() => {
          reject(new Error("RPC timeout exceeded"));
        }, timeout);

        try {
          await web3.eth.getChainId();
          clearTimeout(timeoutId);
          resolve(web3);
        } catch (err) {
          clearTimeout(timeoutId);
          reject(err);
        }
      });
    }

    const rpcPromises = rpcArr.map(async (rpc) => {
      try {
        const web3 = await checkRpc(rpc);
        return web3;
      } catch (err) {
        console.log("Bad RPC: " + rpc, err);
        return null; // or handle the error as needed
      }
    });

    const resolvedWeb3Arr = await Promise.all(rpcPromises);
    web3Arr.push(...resolvedWeb3Arr.filter((web3) => web3 !== null));
    console.log("rpc array length: ", web3Arr.length);
    return web3Arr;
  }
}

//Set an array of contracts with different rpcs
async function setContractArray(wallet, balance) {
  let providerArr = await setWeb3ProviderArray(wallet, balance);
  let contractArr = [];
  const cuAddress = NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK].CUContractAddress;

  for (let i = 0; i < providerArr.length; i++) {
    let contract = new providerArr[i].eth.Contract(
      abi,
      cuAddress
    );
    contractArr.push(contract);
  }
  return contractArr;
}


async function getData(tokenId, contract) {
  const uri = await contract.methods.tokenURI(tokenId).call();
  const metaData = await contract.methods.getUnicornMetadata(tokenId).call();
  // if uri is not valid, skip this unicorn
  if (!uri) {
    console.log(
      "Error loading unicorn. It does not have a URI. Token ID: " + tokenId
    );
    throw MetadataExceptionError("Error loading unicorn metadata.");
  }
  const metadataPromise = await fetch(uri)
    .then((response) => {
      if (!response.ok) {
        console.log("Error loading unicorn. Token ID: " + tokenId);
        console.log(response);
        throw new MetadataExceptionError("Error loading unicorn metadata.");
      }

      // if the response is not valid JSON, skip this unicorn
      try {
        return response.json();
      } catch (e) {
        console.log("Error parsing unicorn metadata: " + tokenId);
        console.log(e);
        throw new MetadataExceptionError("Error loading unicorn metadata.");
      }
    })
    .then((json) => {
      for (let i = 0; i < json.attributes.length; i++) {
        // //Remove this when we go live
        // if (json.attributes[i].trait_type === "Lifecycle") {
        //   json.attributes[i].value = "Adult";
        // }
        // //^
        if (json.attributes[i].trait_type === "Game Lock") {
          if (metaData.gameLocked === true) {
            json.attributes[i].value = "Locked";
          }
          if (metaData.gameLocked === false) {
            json.attributes[i].value = "Unlocked";
          }
        }
      }
      return {
        ...json,
        token_id: tokenId
      };
    });

  return metadataPromise;
}

export async function getUnicorns(wallet, tokenIdArr, balance) {
  const contracts = await setContractArray(wallet, balance);
  const results = [];
  const taskQueue = tokenIdArr.map((tokenId) => ({ tokenId }));

  async function processTask(task, contract) {
    const { tokenId } = task;
    try {
      const result = await getData(tokenId, contract);
      results.push(result);
    } catch (error) {
      // check if the error is NetworkExceptionError
      if (error instanceof MetadataExceptionError) {
        console.log("Metadata fetch failed. Skipping token ID: " + tokenId);
        return;
      }
      // check if error is "DNA has already been persisted"
      if (error.message.includes("DNA has already been persisted")) {
        console.log("DNA has already been persisted. Skipping token ID: " + tokenId);
        return;
      }
      taskQueue.push(task);
      console.log(`Error with tokenId ${tokenId} and contract ${contract}`, error);
    }
  }

  async function scheduleNextTaskForContract(contract) {
    const nextTask = taskQueue.shift();
    if (nextTask) {
      await processTask(nextTask, contract);
      // Schedule the next task for the same contract
      scheduleNextTaskForContract(contract);
    } else {
      // If taskQueue is empty, resolve the promise
      resolveAllTasksPromise();
    }
  }

  let allTasksPromiseResolve;
  const allTasksPromise = new Promise((resolve) => {
    allTasksPromiseResolve = resolve;
  });

  function resolveAllTasksPromise() {
    if (allTasksPromiseResolve) {
      allTasksPromiseResolve();
    }
  }

  // Kick off initial tasks for each contract
  contracts.forEach(async (contract) => {
    const task = taskQueue.shift();
    if (task) {
      await processTask(task, contract);
      // Schedule the next task for the same contract
      scheduleNextTaskForContract(contract);
    }
  });

  // Wait for all tasks for all contracts to complete
  await allTasksPromise;

  return results;
}
