import styled from "styled-components";
import { Box } from "../../base/Box";
import { Typography } from "../../base/Typography";
import { CardHeaderVideo } from "../../base/CardHeaderVideo";
import { ClassIcon } from "../../common/ClassIcon";
import pluralize from "pluralize";

const CardWrap = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  transform: translateY(0px);
  transition: all 0.24s ease;

  &:hover {
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 10px;
  }
`;

const VideoWrap = styled.div`
  height: 320px;
  & .playing-button {
    top: 50%;
  }
`;

const HeaderImg = styled.img`
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const ClassImg = styled(ClassIcon)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -55%);
  width: 50px;
  z-index: 2;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 21px 20px;
  ${({ $boxShadow }) => $boxShadow && `box-shadow: ${$boxShadow};`}
  min-height: 85px;
`;

const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Separator = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.11);
  width: 100%;
  margin: 12px 0;
`;

const CountTag = styled.div`
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.25);
  padding: 0px 8px;
  width: fit-content;
  position: absolute;
  top: 16px;
  left: 16px;
`;

const BoxShadowInset = "0px 20px 9px -15px inset"; // inset 0px 5px 9px 2px

const backgroundArray = [
  {
    name: "Fire",
    color:
      "radial-gradient(44.67% 44.67% at 50.00% 50.00%, #FDB722 0%, rgba(253, 183, 34, 0.00) 100%), radial-gradient(71.51% 50% at 50% 50%, #9E0012 0%, rgba(158, 0, 18, 0.00) 100%), #1C0107",
    shadow: `${BoxShadowInset} #EFAA3C;`,
  },
  {
    name: "Nebula",
    color:
      "radial-gradient(44.67% 44.67% at 50.00% 50.00%, #B82AA1 0%, rgba(184, 42, 161, 0.00) 100%), radial-gradient(71.51% 50% at 50% 50%, #760A63 0%, rgba(118, 10, 99, 0.00) 100%), #1C0117",
    shadow: `${BoxShadowInset} #B930A6;`,
  },
  {
    name: "Slime",
    color:
      "radial-gradient(44.67% 44.67% at 50.00% 50.00%, #93B907 0%, rgba(147, 185, 7, 0.00) 100%), radial-gradient(71.51% 50% at 50% 50%, #0F4134 0%, rgba(15, 65, 52, 0.00) 100%), #010F0C",
    shadow: `${BoxShadowInset} #B1FB4F;`,
  },
  {
    name: "Soul",
    color:
      "radial-gradient(44.67% 44.67% at 50.00% 50.00%, #B33AE9 0%, rgba(184, 42, 161, 0.00) 100%), radial-gradient(71.51% 50% at 50% 50%, #5B0DA1 0%, rgba(91, 13, 161, 0.00) 100%), #0B010F",
    shadow: `${BoxShadowInset} #E939F3;`,
  },
  {
    name: "Volt",
    color:
      "radial-gradient(44.67% 44.67% at 50.00% 50.00%, #178AB5 0%, rgba(23, 138, 181, 0.00) 100%), radial-gradient(71.51% 50% at 50% 50%, #1B4A5C 0%, rgba(8, 72, 103, 0.00) 100%), #00090B",
    shadow: `${BoxShadowInset} #74F9FA;`,
  },
  {
    name: "Default",
    color: "linear-gradient(180deg, #3447AC 0%, #0D153F 100%)",
    shadow: `${BoxShadowInset} #4D66E2`,
  },
];

const stylesArray = [
  {
    name: "Fire",
    color: "linear-gradient(180deg, #E86334 0%, #7B1B16 100%)",
    shadow: `${BoxShadowInset} #EFAA3C;`,
  },
  {
    name: "Nebula",
    color: "linear-gradient(180deg, #A63479 0%, #500F46 100%)",
    shadow: `${BoxShadowInset} #B930A6;`,
  },

  {
    name: "Slime",
    color: "linear-gradient(180deg, #96B339 0%, #193526 100%)",
    shadow: `${BoxShadowInset} #B1FB4F;`,
  },
  {
    name: "Soul",
    color: "linear-gradient(180deg, #AA23C3 0%, #6E1395 100%)",
    shadow: `${BoxShadowInset} #E939F3;`,
  },
  {
    name: "Volt",
    color: "linear-gradient(180deg, #3880C0 0%, #245783 100%)",
    shadow: `${BoxShadowInset} #74F9FA;`,
  },
  {
    name: "Default",
    color: "linear-gradient(180deg, #3447AC 0%, #0D153F 100%)",
    shadow: `${BoxShadowInset} #4D66E2`,
  },
];

export function TokenCard({
  image,
  name,
  leftAttribute,
  rightAttribute,
  onClick,
  isVideo,
  showClass = true,
  count,
  countTag,
}) {
  const cardStyle = (itemClass) => {
    let cardStyle = stylesArray.find((item) => item.name === itemClass);
    if (cardStyle) {
      return cardStyle;
    } else {
      return stylesArray.find((item) => item.name === "Default");
    }
  };

  const backgroundStyle = (itemClass) => {
    let cardStyle = backgroundArray.find((item) => item.name === itemClass);
    if (cardStyle) {
      return cardStyle;
    } else {
      return backgroundArray.find((item) => item.name === "Default");
    }
  };

  let backgroundColor = backgroundStyle(rightAttribute.value).color;
  let shadow = cardStyle(rightAttribute.value).shadow;
  const color = cardStyle(rightAttribute.value).color;

  return (
    <CardWrap onClick={onClick}>
      <Box variant="gradient" rounded="sm" color={color}>
        <Box variant="gradient" rounded="sm" color={backgroundColor}>
          {countTag && (
            <CountTag>
              <Typography
                variant="textSm"
                tag={"p"}
                family={"display"}
                sm={{
                  variant: "textLg",
                }}
              >
                {countTag.toLocaleString()}
              </Typography>
            </CountTag>
          )}
          {isVideo ? (
            <VideoWrap>
              <CardHeaderVideo src={image} />
            </VideoWrap>
          ) : (
            <HeaderImg src={image} alt="" />
          )}
        </Box>

        <TextBox $boxShadow={shadow}>
          {showClass && rightAttribute && (
            <ClassImg name={rightAttribute?.value?.toLowerCase()} />
          )}
          {leftAttribute && (
            <>
              <TextWrap>
                <div>
                  <Typography
                    tag={"h3"}
                    variant={"textXs"}
                    color="white"
                    style={{ textAlign: "left" }}
                  >
                    {leftAttribute?.title}
                  </Typography>
                  <Typography
                    tag={"h3"}
                    variant={"textMd"}
                    color="white"
                    style={{ textAlign: "left", lineHeight: "24px" }}
                  >
                    {leftAttribute?.value}
                  </Typography>
                </div>
                <div>
                  <Typography
                    tag={"h3"}
                    variant={"textXs"}
                    color="white"
                    style={{ textAlign: "right" }}
                  >
                    {rightAttribute?.title}
                  </Typography>
                  <Typography
                    tag={"h3"}
                    variant={"textMd"}
                    color="white"
                    style={{ textAlign: "right", lineHeight: "24px" }}
                  >
                    {rightAttribute?.value}
                  </Typography>
                </div>
              </TextWrap>
              <Separator />
            </>
          )}
          <Typography tag={"h3"} variant={"textMd"} color="white">
            {count ? pluralize(name, count, true) : name}
          </Typography>
        </TextBox>
      </Box>
    </CardWrap>
  );
}
