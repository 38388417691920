import { Button } from "../base/Button";
import styled from "styled-components";
import { useEffect, useState } from "react";

const DEFAULT_ITEMS_PER_PAGE = 7;

const RowsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  & path {
    fill: ${({ theme }) => theme.palette.white};
    stroke: ${({ theme }) => theme.palette.white};
  }

  & button {
    padding: 0;
    width: 40px;
    height: 40px;
  }
`;

export function StakingPaginatedList({
  items,
  renderRow,
  onPageChange,
  WrapComponent = RowsWrap,
  pageSize = DEFAULT_ITEMS_PER_PAGE,
  prependComponent,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(items.length / pageSize);
  const itemsToDisplay = items.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  const isNextDisabled = currentPage === totalPages || totalPages === 0;
  const isPrevDisabled = currentPage === 1;

  const handleNextPage = () => {
    if (currentPage === totalPages) return;
    setCurrentPage((prev) => prev + 1);
    onPageChange();
  };

  const handlePrevPage = () => {
    if (currentPage === 1) return;
    setCurrentPage((prev) => prev - 1);
    onPageChange();
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [items.length]);

  return (
    <>
      <WrapComponent>
        {prependComponent}
        {itemsToDisplay.map((item, index) => renderRow(item, index))}
      </WrapComponent>
      <PaginationWrap>
        <Button
          variant="secondary"
          prependIcon="arrow-left"
          onClick={handlePrevPage}
          disabled={isPrevDisabled}
        />
        <Button
          variant="secondary"
          prependIcon="arrow-right"
          onClick={handleNextPage}
          disabled={isNextDisabled}
        />
      </PaginationWrap>
    </>
  );
}
