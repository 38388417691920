export const colors = {
  white: "#ffffff",
  black: "#000000",

  darkBlue100: "#1C65C0",
  darkBlue200: "#144C92",
  darkBlue300: "#1E3C72",
  darkBlue500: "#2604b5",
  darkBlue700: "#052857",

  lightBlue100: "#6CBCEA",
  lightBlue200: "#59AEFF",
  lightBlue300: "#59AEFF",

  slate50: "#F2FAFF",
  slate100: "#DCE7F2",
  slate200: "#809DB4",
  slate300: "#4B6B84",

  gray100: "#C7D0E0",
  gray150: "#C9D0DF", // gray100 at 50% opacity
  gray200: "#7186A9",
  gray300: "#283A58",
  gray350: "#939CAB", // gray300 at 50% opacity
  gray500: "#667085",

  yellow100: "#FFF3C0",
  yellow200: "#FF9A01",
  yellow300: "#B33E05",

  magenta50: "#E9D4FF",
  magenta100: "#FAC1E6",
  magenta200: "#D25ABC",
  magenta300: "#B513A0",

  vermilion100: "#FFA18D",
  vermilion200: "#FF6848",
  vermilion300: "#FF484E",

  appleGreen100: "#95DF58",
  appleGreen200: "#7EC246",
  appleGreen300: "#47B424",

  red100: "#F26650",
  red200: "#E3442B",
  red300: "#DD191C",

  fuschia100: "#FF76D2",
  fuschia200: "#EF60C0",
  fuschia300: "#FF20AB",

  mintGreen100: "#53DCC7",
  mintGreen200: "#47CAB6",
  mintGreen300: "#12A69A",

  carnationPink100: "#FF76D2",
  carnationPink200: "#EF60C0",
  carnationPink300: "#FF20AB",

  shyOrange100: "#FFD77A",
  shyOrange200: "#EBBF07",
  shyOrange300: "#FF9102",

  happyYellow100: "#F9F045",
  happyYellow200: "#E9E25C",
  happyYellow300: "#F2D512",

  redishOrange100: "#FFAB5D",
  redishOrange200: "#FF8461",
  redishOrange300: "#FD6041",

  indigo100: "#9980FB",
  indigo200: "#735FF8",
  indigo300: "#492EF7",
  indigo400: "#2F1692",
  indigo500: "#230C7C",
  indigo700: "#1D0775",

  violet100: "#B984FB",
  violet200: "#8F5AF6",
  violet300: "#7533F7",

  blue100: "#6CBCEA",
  blue200: "#4F95F3",
  blue300: "#1E6CD5",

  burgundy100: "#FFB098",
  burgundy200: "#FA5E59",
  burgundy300: "#C5565A",

  wineBerry100: "#D62C7D",
  wineBerry200: "#973564",
  wineBerry300: "#4D2538",

  royalHeath100: "#FF31D5",
  royalHeath200: "#FFA5A9",
  royalHeath300: "#AA1B7A",

  spruce600: "#496AA2",
};

export const commonPalette = {
  ...colors,

  // we can also add some additional color name like "primary", "secondary" and etc
};

export const gradients = {
  //radial
  radialGray:
    "radial-gradient(113.14% 52.82% at 50.14% 47.18%, #6D9CC0 0%, #1B333D 100%)",
  radialOrange:
    "radial-gradient(113.14% 52.82% at 50.14% 47.18%, #E37B21 0%, #78220B 100%)",
  radialRed:
    "radial-gradient(113.14% 52.82% at 50.14% 47.18%, #BB3419 0%, #650B0C 100%)",
  radialYellow:
    "radial-gradient(113.14% 52.82% at 50.14% 47.18%, #F9B708 0%, #B05200 100%)",
  radialViolet:
    "radial-gradient(113.14% 52.82% at 50.14% 47.18%, #841CCF 0%, #441494 100%)",
  radialPink:
    "radial-gradient(113.14% 52.82% at 50.14% 47.18%, #FF74C0 0%, #77003A 100%)",
  radialGreen:
    "radial-gradient(113.14% 52.82% at 50.14% 47.18%, #93B337 0%, #054719 100%)",
  radialBlue:
    "radial-gradient(113.14% 52.82% at 50.14% 47.18%, #065378 0%, #001D46 100%)",
  radialMint:
    "radial-gradient(113.14% 52.82% at 50.14% 47.18%, #39C7A9 0%, #023548 100%)",
  // linear gradient
  linearPinkLight: "linear-gradient(143.22deg, #FB60C7 7.24%, #FF2BB0 94.73%)",
  linearMintLight: "linear-gradient(143.22deg, #14DBBD 7.24%, #00B8A8 94.73%)",
  linearMintDark: "linear-gradient(143.22deg, #0FC8AB 7.24%, #00B8A8 94.73%)",
  linearBlueLight: "linear-gradient(143.22deg, #4D5CFF 7.24%, #505EF3 94.73%)",
  linearBluePrimary: "linear-gradient(180deg, #4CADF1 0%, #4C73F4 100%);",
  linearOrangeLight:
    "linear-gradient(143.22deg, #F46652 7.24%, #FD6041 94.73%)",
  linearVioletLight: "linear-gradient(180deg, #B984FB 0%, #7533F7 100%)",
  linearIndigo:
    "linear-gradient(0deg, #230C7C, #230C7C), linear-gradient(0deg, #2F1692, #2F1692)",
  linearBlue: "linear-gradient(180deg, #5252F2 0%, #3131B0 100%);",
  linearBlack: "linear-gradient(180deg, #3447AC 0%, #0D153F 100%);",
  linearWhite: "linear-gradient(143.22deg, #FFFFFF 7.24%, #C7C9C9 94.73%);",

  //shadowcorns
  radialVoltLight:
    "radial-gradient(81.19% 88.24% at 100.9% 16.18%, #A3DAF0 1.04%, rgba(163, 218, 240, 0.00) 100%), linear-gradient(180deg, #3EB1E0 0%, #166A8D 100%)",
  radialGoldLight:
    "radial-gradient(22.39% 88.24% at 14.82% 16.18%, #FFD977 1.04%, rgba(157, 107, 41, 0.00) 100%), linear-gradient(180deg, #C79847 0%, #9D6B29 100%)",

  // Tiers
  tier0: "linear-gradient(0deg, #212121 0%, #E6E6E6 100%)",
  tier1: "linear-gradient(180deg, #8BAD36 0%, #00481A 100%);  ",
  tier2: "linear-gradient(180deg, #2B8BFA 0%, #2562AF 100%)",
  tier3: "linear-gradient(180deg, #884EC7 0%, #5929A2 100%)",
  tier4: "linear-gradient(180deg, #F8BB42 0%, #D28211 100%)",
  tier5: "linear-gradient(180deg, #EB882B 0%, #AF4C15 100%)",
  tier6: "linear-gradient(180deg, #DB4F37 0%, #8F2D28 100%)",
  tier7: "linear-gradient(180deg, #00DBBC 0%, #007565 100%)",
  tier8: "linear-gradient(180deg, #496AA2 0%, #1B273C 100%)",
  tier9: "linear-gradient(180deg, #FF1A99 0%, #990F5C 100%)",
  tier10: "linear-gradient(180deg, #FFC600 0%, #970 100%)",

  radialFire:
    "radial-gradient(57.95% 55.49% at 50% 50%, #F5A126 0%, #70020D 62.85%, #000 100%)",
  radialSoul:
    "radial-gradient(57.95% 55.49% at 50% 50%, #B11FEC 0%, #2F0155 50%, #000 100%)",
  radialSlime:
    "radial-gradient(57.95% 55.49% at 50% 50%, #80B615 0%, #0F2F22 62.85%, #000 100%)",
  radialNebula:
    "radial-gradient(57.95% 55.49% at 50% 50%, #A00D8F 0%, #420137 62.85%, #000 100%)",
  radialVolt:
    "radial-gradient(57.95% 55.49% at 50% 50%, #106F8B 0%, #031F2A 62.85%, #000 100%)",
};

export const textshadows = {
  sharp: "1px 2px 0 rgba(0, 0, 0, 0.65)",
  mediumAlt: "0px 4px 0px rgba(0, 0, 0, 0.35)",
  medium: "1px 2px 0px rgba(0, 0, 0, 0.25)",
  hard: "0px 5px 0px rgba(0, 0, 0, 0.20);",
  soft: "0px 2px 2px rgba(0, 0, 0, 0.15)",
  hardAlt: "0px 4px 0px rgba(0, 0, 0, 0.25)",
};
