import { NotificationsModal } from "../../common/NotificationsModal";
import { useAtom, useAtomValue } from "jotai/index";
import { badgeNotificationModelOpenedAtom, badgeNotificationTransactionsAtom } from "../../../store/staking";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import unicorn_party_badge from "../../../img/staking/badge/unicorn-party.png";
import { Button } from "../../base/Button";
import { trimWalletAddress } from "../../../helpers/trimWalletAddress";

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ListItemWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 13px 10px;
`;

const ListItemTextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItemTextTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.white};
`;

const ListItemTextId = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
`;

const ListItemTextAddress = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
`;

const ListItemIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: start;

  & img {
    width: 100%;
    height: 100%;

  }
`;

const ListItemAction = styled.div`
  margin-left: auto;

  & * {
    @media (${device.md}) {
      font-size: 16px;
    }
  }
`;

const Tip = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.white};
  margin-top: 8px;
  text-align: center;
`;

export function StakingBadgesNotification() {
  const [open, setOpen] = useAtom(badgeNotificationModelOpenedAtom);
  const transactions = useAtomValue(badgeNotificationTransactionsAtom);

  return (
    <NotificationsModal
      open={open}
      onClose={() => setOpen(false)}
      title="A badge is delegated to you!"
    >
      <ListWrap>
        {transactions.map((transaction, index) => (
          <ListItem
            key={index}
            name={transaction.name}
            id={transaction.id}
            address={transaction.address}
            hash={transaction.hash}
          />
        ))}
      </ListWrap>
      <Tip>
        Your Badge will be reflected in your account in 10 minutes
      </Tip>
    </NotificationsModal>
  );
}

function ListItem({
  name,
  id,
  address,
  hash
}) {
  return (
    <ListItemWrap>
      <ListItemIcon>
        <img src={unicorn_party_badge} alt="badge" />
      </ListItemIcon>
      <ListItemTextWrap>
        <ListItemTextTitle>
          {name}
        </ListItemTextTitle>
        <ListItemTextId>
          {id}
        </ListItemTextId>
        <ListItemTextAddress>
          From: {trimWalletAddress(address)}
        </ListItemTextAddress>
      </ListItemTextWrap>
      <ListItemAction>
        <Button>
          View Transaction
        </Button>
      </ListItemAction>
    </ListItemWrap>
  );
}