import React from "react";
import styled from "styled-components";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { Footer } from "../layouts/BaseLayout/Footer";
import { Header } from "../components/header";
import { Hero } from "../components/airdrop/Hero";
import { device } from "../theme/mediaQuery";
import { Stats } from "../components/airdrop/Stats";
import { GetFullLeaderboard } from "../hooks/GetLeaderboard";
import { AirballoonBackground } from "../components/home/hero/HomeHeroBackground";
import { EventTasksPreseason } from "../components/airdrop/EventTasksPreseason";
import { EventTasksSeason } from "../components/airdrop/EventTasksSeason";
// import { ComingSoon } from "../components/airdrop/ComingSoon";
import { MoreInfo } from "../components/airdrop/MoreInfo";

const PurpleGradientWrapper = styled.div`
  background: linear-gradient(
    179.95deg,
    #2d4ac9 0.04%,
    #15204e 60.08%,
    #e54ab6 92.63%
  );

  overflow-x: hidden;
`;

export const SectionWrap = styled.section`
  padding: ${({ $isLeaderboard }) => ($isLeaderboard ? "0" : "24px 0")};

  @media (${device.md}) {
    padding: ${({ $isLeaderboard }) => ($isLeaderboard ? "0" : "36px 0")};
  }
  @media (${device.lg}) {
    padding: ${({ $isLeaderboard }) => ($isLeaderboard ? "0" : "40px 0")};
  }
  @media (${device.xl}) {
    padding: ${({ $isLeaderboard }) => ($isLeaderboard ? "0" : "50px 0")};
  }
`;

const LEADERBOARD_ID = "00fbc1ca-0456-4cd3-ba01-012407058a75";
export const Airdrop = () => {
  useDocumentTitle("Airdrop");
  const leaderboard = GetFullLeaderboard({ id: LEADERBOARD_ID });

  return (
    <>
      <PurpleGradientWrapper>
        <AirballoonBackground>
          <Header />
          <Hero />
          <Stats leaderboard={leaderboard} />
        </AirballoonBackground>
        <EventTasksPreseason leaderboard={leaderboard} />
        <EventTasksSeason leaderboard={leaderboard} />
        {/* <ComingSoon /> */}
        <MoreInfo />
      </PurpleGradientWrapper>
      <Footer />
    </>
  );
};
