import React from "react";
import styled from "styled-components";
import { Typography } from "../base/Typography";
import { Container } from "../base/Container";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { Button } from "../base/Button";
import { device } from "../../theme/mediaQuery";

import video from "../../img/licensing/video.mp4";
import poster from "../../img/licensing/poster.jpg";

export const SectionWrap = styled.section`
  padding: 36px 0;
  position: relative;

  @media (${device.md}) {
    padding: 40px 0;
  }

  @media (${device.xl}) {
    padding: 80px 0;
  }
`;

const HeroTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
  text-align: center;

  @media (${device.xl}) {
    align-items: flex-start;
    text-align: left;
    gap: 28px;
  }

  & > h1 {
    line-height: 100%;
  }
`;

const VideoWrap = styled.div`
  & video {
    max-width: 100%;
    border-radius: 16px;
    object-fit: cover;
  }
`;

export function Hero({ licenseeRef }) {
  const scrollToLicensee = () => {
    if (licenseeRef.current) {
      licenseeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      <SectionWrap>
        <Row className="align-items-center">
          <Col
            xl={{ span: 6, order: 1 }}
            xs={{ span: 12, order: 2 }}
            className={"mt-4 mt-xl-0 pe-0"}
          >
            <HeroTextWrap>
              <Typography
                tag={"h1"}
                variant={"displaySm"}
                lg={{ variant: "displayLg" }}
                xxl={{ variant: "displayXl" }}
                textshadow="hard"
              >
                An immersive digital universe is now coming to the physical
                world
              </Typography>
              {/* <Typography
                tag={"p"}
                variant={"textXs"}
                weight={400}
                md={{ variant: "textMd" }}
              >
                Laguna games is bringing our IP characters based on the
                award-winning Web3/Blockchain game, Crypto Unicorns to market!
                We are seeking global retail distribution of Licensed Toys,
                General Merchandise, Media, Print and Brand Collection
                partnerships.
              </Typography> */}
              <Button variant="secondary" onClick={scrollToLicensee} size="lg">
                Contact Us
              </Button>
            </HeroTextWrap>
          </Col>
          <Col xl={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
            <VideoWrap>
              <video
                controls={false}
                poster={poster}
                muted
                loop
                autoPlay
                playsInline
                preload="metadata"
              >
                <source src={video} type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
            </VideoWrap>
          </Col>
        </Row>
      </SectionWrap>
    </Container>
  );
}
