import React from "react";
import { GetFullLeaderboard } from "../../hooks/GetLeaderboard";
import { Hero } from "../airdrop/Hero";
import { Stats } from "../airdrop/Stats";
import { EventTasksPreseason } from "../airdrop/EventTasksPreseason";
import { EventTasksSeason } from "../airdrop/EventTasksSeason";

const LEADERBOARD_ID = "00fbc1ca-0456-4cd3-ba01-012407058a75";

export function LeaderboardAirdropTable() {
  const leaderboard = GetFullLeaderboard({ id: LEADERBOARD_ID });

  return (
    <>
      <Hero $isLeaderboard />
      <Stats leaderboard={leaderboard} $isLeaderboard className="pb-5 px-0" />
      <EventTasksPreseason leaderboard={leaderboard} $isLeaderboard />
      <EventTasksSeason leaderboard={leaderboard} $isLeaderboard />
    </>
  );
}
