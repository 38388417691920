import { useEffect, useState } from "react";
import { RewardWrapper } from "./Reward";
import { Rank } from "./Rank";
import Spinner from "../../base/Spinner";
import { Button } from "../../base/Button";
import { GetTribeFullLeaderboard } from "../../../hooks/GetLeaderboard";
import {
  TableWrap,
  Table,
  TableHead,
  TableHeading,
  TableRow,
  TableCell,
  PlaceholderWrap,
} from "./LeaderboardTableWithPagination";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";

const initRes = [
  { address: " ", rank: "1", score: 0, points_data: { Tribe_name: "..." } },
  { address: " ", rank: "2", score: 0, points_data: { Tribe_name: "..." } },
  { address: " ", rank: "3", score: 0, points_data: { Tribe_name: "..." } },
];

const TribeName = styled(TableCell)`
  @media (${device.xl}) {
    min-width: 280px;
  }
`;

function Leaderboard({
  renderReward,
  headerBackgroundColor,
  headerPattern = "labyrinth",
  patternSize = "50%",
  patternOpacity,
  leaderboard: initleaderboard,
  LEADERBOARD_ID,
  LEADERBOARD_ID_INDIVIDUAL,
  ...rest
}) {
  const [leaderboard, setLeaderboard] = useState(null);
  const data = GetTribeFullLeaderboard({
    id: LEADERBOARD_ID,
    idIndividual: LEADERBOARD_ID_INDIVIDUAL,
    page: 0,
  });

  const count = data?.page?.count || 0;
  const page = data?.page?.current || 0;

  const handleNext = () => {
    data.getLeaderboardPage(page + 1);
  };
  const handlePrev = () => {
    data.getLeaderboardPage(page - 1);
  };

  // Initial values
  useEffect(() => {
    if (!leaderboard && initleaderboard) {
      setLeaderboard(initleaderboard);
    }
  }, [leaderboard, initleaderboard]);

  // Rehydrate the leaderboard
  useEffect(() => {
    if (data) {
      setLeaderboard(data.data);
    }
  }, [data]);

  const LeaderboardRow = ({ res }) => {
    const rows = [];
    if (res.length === 0) res = initRes;
    for (let i = 0; i < res?.length; i++) {
      const isCurrentUser = res[i].current_user;
      const tribeName = res[i].points_data?.tribe_name
        ? res[i].points_data?.approved_tribe_name
        : res[i].points_data?.tribe_id;
      const textColor = "#1E3C72";
      const bgColor = isCurrentUser
        ? "linear-gradient(143.22deg, #F8F2DF 7.24%, #EBB817 94.73%)"
        : "linear-gradient(143.22deg, #FFFFFF 7.24%, #C7C9C9 94.73%)";
      if (res[i].address) {
        rows.push(
          <TableRow
            key={i}
            tag="tr"
            gradient={bgColor}
            variant="gradient"
            pattern="labyrinth"
            patternSize="50%"
            patternOpacity={0.02}
            patternTag="td"
          >
            <TableCell $color={textColor}>
              <Rank size="sm" rank={res[i].rank} />
            </TableCell>
            <TribeName $color={textColor}>{tribeName}</TribeName>
            <TableCell className="text-center" $color={textColor}>
              {res[i].score ? parseInt(res[i].score).toLocaleString("en") : 0}
            </TableCell>
            <TableCell className="text-center" $color={textColor}>
              <RewardWrapper className="center">
                {renderReward(res[i])}
              </RewardWrapper>
            </TableCell>
          </TableRow>
        );
      }
    }
    return rows;
  };

  if (!leaderboard) {
    return (
      <PlaceholderWrap>
        <Spinner color="gray300" />
      </PlaceholderWrap>
    );
  }

  return (
    <>
      <TableWrap {...rest}>
        <Table responsive>
          <TableHead
            tag="thead"
            gradient={headerBackgroundColor}
            variant="gradient"
            pattern={headerPattern}
            patternSize="50%"
            patternTag="tr"
          >
            <TableRow tag="tr">
              <TableHeading>#</TableHeading>
              <TableHeading>Tribe</TableHeading>
              <TableHeading className="text-center">Points</TableHeading>
              <TableHeading className="text-center">Rewards</TableHeading>
            </TableRow>
          </TableHead>
          <tbody>
            <LeaderboardRow res={leaderboard} />
          </tbody>
        </Table>
      </TableWrap>

      <div className="text-center pt-4">
        <div>
          <Button
            variant="secondary"
            onClick={handlePrev}
            disabled={page === 0 || page === null}
            className="me-4"
          >
            {"<"}
          </Button>
          <Button
            variant="secondary"
            onClick={handleNext}
            disabled={page === count}
          >
            {">"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default Leaderboard;
