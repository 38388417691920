import styled from "styled-components";
import { device } from "../../theme/mediaQuery";
import { forwardRef } from "react";

const Wrap = styled.div`
  background: #3C1568;
  border-radius: 16px;
  overflow: hidden;
`;

const HeaderWrap = styled.div`
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(66, 0, 142, 0.93) 13.62%, rgba(121, 49, 177, 0.69) 57.98%, #7931B1 97.95%);

    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${({ theme }) => theme.pattern.waves}), #7B7B7B;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: luminosity;
    opacity: 0.55;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  @media (${device.lg}) {
    height: 75px;
  }
`;

const HeaderContent = styled.div`
  position: relative;
  z-index: 3;
  height: 100%;
`;

const Title = styled.h3`
  font-size: 24px;
  color: #E3A90D;
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.display};
  text-shadow: 1px 4px 0px #0000004D;

  @media (${device.lg}) {
    font-size: 32px;
  }
`;

export function StakingCommonCard({ children, ...rest }) {
  return (
    <Wrap {...rest}>
      {children}
    </Wrap>
  );
}

export const StakingCommonCardHeader = forwardRef(({ children, ...rest }, ref) => {
  return (
    <HeaderWrap ref={ref} {...rest} >
      <HeaderContent>
        {children}
      </HeaderContent>
    </HeaderWrap>
  );
});

export function StakingCommonCardTitle({ children, ...rest }) {
  return (
    <Title {...rest}>
      {children}
    </Title>
  );
}