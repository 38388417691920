import React, { useRef } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { FreeMode } from "swiper";
import { Icon } from "../base/Icon";
import { CharacterCard } from "./CharacterCard";
import { Button } from "../base/Button";
import { device } from "../../theme/mediaQuery";

const SwiperWrap = styled(Swiper)`
  overflow: visible;
`;

const SwiperSlideWrap = styled(SwiperSlide)`
  padding: 10px 0;
  height: auto;
  transition: opacity 0.3s, filter 0.3s;
  opacity: 0.4;
  filter: blur(2px);

  &.swiper-slide-active {
    opacity: 1;
    filter: blur(0);
  }

  &.swiper-slide-prev,
  &.swiper-slide-next {
    @media (${device.xl}) {
      opacity: 1;
      filter: blur(0);
    }
  }
`;

const SwiperButtonsWrap = styled.div`
  display: flex;
  justify-content: space-around;
  display: flex;
  z-index: 1;
  gap: 32px;
  max-width: 400px;
  margin: 24px auto 0;

  @media (${device.xl}) {
    margin: 0;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 109%;
    max-width: unset;
  }
  @media (${device.xxxl}) {
    width: 105%;
  }

  & svg {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(281deg)
      brightness(101%) contrast(104%);
  }
`;

export function Carousel({ characters, characterType }) {
  const swiperRef = useRef();

  return (
    <SwiperWrap
      spaceBetween={16}
      slidesPerView={1}
      breakpoints={{
        400: {
          slidesPerView: 1.25,
          spaceBetween: 24,
        },
        500: {
          slidesPerView: 1.5,
          spaceBetween: 24,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 2.5,
          spaceBetween: 24,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1400: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      }}
      slideVisibleClass="swiper-slide-visible"
      modules={[FreeMode]}
      centeredSlides={true}
      grabCursor={true}
      loop={true}
      onBeforeInit={(swiper) => {
        swiperRef.current = swiper;
      }}
    >
      <SwiperButtonsWrap>
        <Button
          variant="secondary"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <Icon name="arrow-left" />
        </Button>
        <Button
          variant="secondary"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <Icon name="arrow-right" />
        </Button>
      </SwiperButtonsWrap>
      {characters.map((unicorn) => (
        <SwiperSlideWrap key={unicorn.name}>
          <CharacterCard
            name={unicorn.name}
            description={unicorn.description}
            characterType={characterType}
          />
        </SwiperSlideWrap>
      ))}
    </SwiperWrap>
  );
}
