import React from "react";
import styled from "styled-components";
import { StakingCallout } from "./StakingCallout";
import { SuccessModal } from "./SuccessModal";
import { device } from "../../../theme/mediaQuery";
import { Typography } from "../../base/Typography";
import { useAtom } from "jotai";
import { stakeSuccessModalDataAtom, stakeSuccessModalOpenedAtom } from "../../../store/staking";
import { StakingViewTransactionButton } from "../StakingViewTransactionButton";
import { StakingTokenTypes } from "../constants";

const StakingCalloutWrap = styled(StakingCallout)`
  margin-top: 16px;

  @media (${device.md}) {
    margin-top: 24px;
  }
`;

const StakingDetailsWrap = styled.div`
  background: #6a00b2;
  color: ${({ theme }) => theme.palette.white};
  backdrop-filter: blur(6px);
  border-radius: 12px;
  padding: 12px;
  margin: 16px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 8px;
  z-index: 13;

  @media (${device.sm}) {
    width: 350px;
  }

  @media (${device.md}) {
    padding: 24px;
    margin: 24px 0;
    width: 536px;
  }
  @media (${device.md}) {
    padding: 30px;
  }
`;

const StakingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: normal;
  gap: 8px;

  & h4 {
    font-weight: normal;
  }

  & p {
    font-weight: 700;
  }
`;

const details = [
  { title: "Asset Staked", key: "token" },
  { title: "Quantity", key: "quantity" },
  { title: "Duration", key: "duration" },
  { title: "Projected APR", key: "apr" },
];

const StakingDetails = ({ transaction }) => {
  return (
    <StakingDetailsWrap>
      {transaction && details.map((item) => (
        <StakingRow key={item.key}>
          <Typography
            as="h4"
            variant="textMd"
            md={{ variant: "textLg" }}
            family="body"
          >
            {item.title}:
          </Typography>
          <Typography as="p" variant="textLg" md={{ variant: "displayXs" }}>
            {transaction[item.key]}
          </Typography>
        </StakingRow>
      ))}
    </StakingDetailsWrap>
  );
};

export function StakingSuccessModal() {
  const [successModalOpen, setSuccessModalOpen] = useAtom(stakeSuccessModalOpenedAtom);
  const [transaction, setTransaction] = useAtom(stakeSuccessModalDataAtom);

  const token = transaction?.token;
  const isCULP = token === StakingTokenTypes.culp;

  const handleClose = () => {
    setSuccessModalOpen(false);
    setTransaction(null);
  };

  return (
    <SuccessModal
      toggleModal={handleClose}
      showModal={successModalOpen}
      title="Success!"
      subtitle={`You have successfully staked ${transaction?.quantity} ${transaction?.token}!`}
    >
      <StakingDetails transaction={transaction} />
      <StakingViewTransactionButton variant="secondary" size="md" hash={transaction?.hash}>
        View Transaction
      </StakingViewTransactionButton>
      {isCULP && (
      <StakingCalloutWrap
        buttonText="View Leaderboard"
        title={`By staking ${transaction?.token}, you are eligible to be part of our leaderboard!`}
        to="/staking/leaderboard"
      />
      )}
      </SuccessModal>
  );
}
