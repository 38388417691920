import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
  GetTribeFullLeaderboard,
  GetFullLeaderboard,
  GetTribeContributionLeaderboard,
} from "../../hooks/GetLeaderboard";
import {
  LeaderboardSummary,
  LeaderboardSummaryTabs,
} from "./LeaderboardSummary";
import LeaderboardTableWithPagination from "./components/LeaderboardTableWithPagination";
import LeaderboardTablePreLoadedWithPagination from "./components/LeaderboardTablePreloadedWithPagination";
import TribesTableWithPagination from "./components/TribesTableWithPagination";
import { Table } from "./components/Table";
import { device } from "../../theme/mediaQuery";
import {
  rallyRewards,
  rallyIndividualRewards,
  getLeaderboardRewards,
} from "./LeaderboardHelper";

const LEADERBOARD_ID_INDIVIDUAL = "59125ca7-b6d8-4d42-bbe0-1786652ae9a8";
const LEADERBOARD_ID_TRIBES = "369aad51-704d-444b-8f2f-983a0efd111c";
const LEADERBOARD_ID_TRIBE_CONTRIBUTION =
  "a869bb7d-ccf2-43c6-add0-5362fa819a41";

const TIMESTAMP_EVENT = 1725148800; // Battle Event end - Sept 1, 2024

const HEADER_COLOR =
  "linear-gradient(117deg, #AE2A25 19.97%, #AB112B 83.25%)";
const HEADER_COLOR_ALT = "#800A20";
const TABS_COLOR = "#9A0520";

export function LeaderboardUnicornBattleTable({ includeSummary = false }) {
  const leaderboard = GetFullLeaderboard({ id: LEADERBOARD_ID_INDIVIDUAL });
  const tribes = GetTribeFullLeaderboard({
    id: LEADERBOARD_ID_TRIBES,
    idIndividual: LEADERBOARD_ID_TRIBE_CONTRIBUTION,
  });
  const tribe = GetTribeContributionLeaderboard({
    id: LEADERBOARD_ID_TRIBE_CONTRIBUTION,
  });

  return (
    <>
      {includeSummary && <LeaderboardPointsTable leaderboard={leaderboard} />}
      <LeaderboardBattleTables
        leaderboard={leaderboard}
        tribes={tribes}
        tribe={tribe}
      />
    </>
  );
}

// Leaderboard Table
const HEADER_DATA = [
  "Event Name",
  "Points Per",
  "Times completed",
  "Points Received",
];

export function LeaderboardPointsTable({ leaderboard }) {
  const self = leaderboard?.self?.points_data;
  const EVENT_DATA = useMemo(() => {
    return [
      {
        eventName: "Breed two unicorns together",
        pointsPer: 50,
        times: self?.uni_breed ? self.uni_breed.toLocaleString("en") : 0,
        points: self?.uni_breed_pts
          ? self.uni_breed_pts.toLocaleString("en")
          : 0,
      },
      {
        eventName: "Hatch a Unicorn Egg",
        pointsPer: 50,
        times: self?.uni_hatch ? self.uni_hatch.toLocaleString("en") : 0,
        points: self?.uni_hatch_pts
          ? self.uni_hatch_pts.toLocaleString("en")
          : 0,
      },
      {
        eventName: "Evolve a Unicorn with total stats < 1500",
        pointsPer: 150,
        times: self?.stat0_uni ? self.stat0_uni.toLocaleString("en") : 0,
        points: self?.stat0_uni_pts ? self.stat0_uni_pts.toLocaleString("en") : 0,
      },
      {
        eventName: "Evolve a Unicorn with total stats 1500 - 1699",
        pointsPer: 225,
        times: self?.stat1500_uni ? self.stat1500_uni.toLocaleString("en") : 0,
        points: self?.stat1500_uni_pts
          ? self.stat1500_uni_pts.toLocaleString("en")
          : 0,
      },
      {
        eventName: "Evolve a Unicorn with total stats 1700 - 1999",
        pointsPer: 275,
        times: self?.stat1700_uni ? self.stat1700_uni.toLocaleString("en") : 0,
        points: self?.stat1700_uni_pts
          ? self.stat1700_uni_pts.toLocaleString("en")
          : 0,
      },
      {
        eventName: "Evolve a Unicorn with total stats ≥ 2,000",
        pointsPer: 350,
        times: self?.stat2000_uni ? self.stat2000_uni.toLocaleString("en") : 0,
        points: self?.stat2000_uni_pts
          ? self.stat2000_uni_pts.toLocaleString("en")
          : 0,
      },
      {
        eventName: "Equip a TI Gem",
        pointsPer: 10,
        times: self?.tier1_gem ? self.tier1_gem.toLocaleString("en") : 0,
        points: self?.tier1_gem_pts
          ? self.tier1_gem_pts.toLocaleString("en")
          : 0,
      },
      {
        eventName: "Equip a TII Gem",
        pointsPer: 25,
        times: self?.tier2_gem ? self.tier2_gem.toLocaleString("en") : 0,
        points: self?.tier2_gem_pts
          ? self.tier2_gem_pts.toLocaleString("en")
          : 0,
      },
      {
        eventName: "Equip a TIII Gem",
        pointsPer: 50,
        times: self?.tier3_gem ? self.tier3_gem.toLocaleString("en") : 0,
        points: self?.tier3_gem_pts
          ? self.tier3_gem_pts.toLocaleString("en")
          : 0,
      },
      {
        eventName: "Equip a TIV Gem",
        pointsPer: 100,
        times: self?.tier4_gem ? self.tier4_gem.toLocaleString("en") : 0,
        points: self?.tier4_gem_pts
          ? self.tier4_gem_pts.toLocaleString("en")
          : 0,
      },
      {
        eventName: "Equip a TV Gem",
        pointsPer: 250,
        times: self?.tier5_gem ? self.tier5_gem.toLocaleString("en") : 0,
        points: self?.tier5_gem_pts
          ? self.tier5_gem_pts.toLocaleString("en")
          : 0,
      },
      {
        eventName: "Equip a TVI Gem",
        pointsPer: 500,
        times: self?.tier6_gem ? self.tier6_gem.toLocaleString("en") : 0,
        points: self?.tier6_gem_pts
          ? self.tier6_gem_pts.toLocaleString("en")
          : 0,
      },
    ];
  }, [self]);

  const renderBody = (leaderboard) => {
    return (
      <Table
        data={EVENT_DATA}
        headerData={HEADER_DATA}
        table={"title"}
        headerColor={TABS_COLOR}
        style={{ borderRadius: 0 }}
        headerPattern="none"
        chunkSize={4}
        pagination
      />
    );
  };

  return (
    <LeaderboardSummary
      leaderboard={true}
      headerTitle="Prepare for war"
      eventEndAt={TIMESTAMP_EVENT}
      renderBody={renderBody}
      headerBackgroundColor={HEADER_COLOR}
      headerPattern="labyrinth"
      gradient="white"
      patternOpacity="0.05"
    />
  );
}

// Leaderboard Tabs

const LEADERBOARD_TABS = [
  { title: "Tribes vs Tribes", key: "tribes" },
  { title: "Individual", key: "individual" },
  { title: "Tribe Contribution", key: "tribe" },
];

const LeaderboardWrap = styled.div`
  padding-bottom: 20px;
  @media (${device.lg}) {
    padding-bottom: 40px;
  }
`;

export function LeaderboardBattleTables({ leaderboard, tribes, tribe }) {
  const [tab, setTab] = useState(LEADERBOARD_TABS[0].key);

  const renderBody = (leaderboard) => {
    if (tab === "individual") {
      return (
        <LeaderboardWrap>
          <LeaderboardTableWithPagination
            renderReward={(row) =>
              getLeaderboardRewards(row.rank, rallyIndividualRewards)
            }
            headerBackgroundColor={HEADER_COLOR_ALT}
            headerPattern="none"
            leaderboard={leaderboard?.data}
            LEADERBOARD_ID={LEADERBOARD_ID_INDIVIDUAL}
            style={{ borderRadius: 0 }}
            color="#253B6E"
          />
        </LeaderboardWrap>
      );
    }
    // tribes
    if (tab === "tribes") {
      return (
        <LeaderboardWrap>
          <TribesTableWithPagination
            renderReward={(row) =>
              getLeaderboardRewards(row.rank, rallyRewards)
            }
            headerBackgroundColor={HEADER_COLOR_ALT}
            headerPattern="none"
            leaderboard={tribes?.data}
            LEADERBOARD_ID={LEADERBOARD_ID_TRIBES}
            LEADERBOARD_ID_INDIVIDUAL={LEADERBOARD_ID_TRIBE_CONTRIBUTION}
            style={{ borderRadius: 0 }}
            color="#253B6E"
          />
        </LeaderboardWrap>
      );
    }

    // tribe (table used accepts preloaded data and not fetching on next/prev click)
    return (
      <LeaderboardWrap>
        <LeaderboardTablePreLoadedWithPagination
          headerBackgroundColor={HEADER_COLOR_ALT}
          headerPattern="none"
          leaderboard={tribe}
          style={{ borderRadius: 0 }}
          totalPointsHeader="Total Tribe Contribution"
          color="#253B6E"
        />
      </LeaderboardWrap>
    );
  };

  return (
    <>
      <LeaderboardSummaryTabs
        leaderboard={
          tab === "tribe" ? tribe : tab === "tribes" ? tribes : leaderboard
        }
        headerTitle="Leaderboard"
        renderBody={renderBody}
        headerBackgroundColor={HEADER_COLOR}
        headerPattern="labyrinth"
        headerColor={TABS_COLOR}
        headerAccent="white"
        gradient="white"
        tabs={LEADERBOARD_TABS}
        tab={tab}
        setTab={setTab}
        patternOpacity="0.05"
      />
    </>
  );
}
