import styled from "styled-components";

const RarityBackground = {
  common: "linear-gradient(180deg, #28E43B 0%, #0A3307 100%)",
  rare: "linear-gradient(180deg, #2983E9 0%, #072A3F 100%)",
  mythic: "linear-gradient(180deg, #C623ED 0%, #3C0948 100%)",
  voting: "linear-gradient(180deg, #D180B4 0%, #BB047A 100%)",
  uncommon: "linear-gradient(180deg, #D6DDDF 0%, #29495B 100%)",
};

export const StakingBadgeRarity = styled.div`
  height: 28px;
  width: 110px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  background: ${({ $variant }) => RarityBackground[$variant]};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 700;
  border-radius: 8px;
`;