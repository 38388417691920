import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { Typography } from "../base/Typography";
import { device } from "../../theme/mediaQuery";
import { BUY } from "../../consts/externalLinks";
import { LinkLabel } from "./NavGamesMenu";
import gem from "../../img/marketplace/gem.jpg";
import land from "../../img/marketplace/land.jpg";
import rituals from "../../img/marketplace/rituals.jpg";
import unicorn from "../../img/marketplace/unicorn.jpg";
import shadowcorn from "../../img/marketplace/shadowcorn.jpg";
import item from "../../img/marketplace/unicorn-items.jpg";

const MP = [
  {
    title: "Unicorns",
    description: "Start your collection by purchasing a Unicorn!",
    image: unicorn,
    url: BUY.MARKETPLACE.UNICORN,
    label: null,
    target: "_blank",
  },
  {
    title: "Unicorn Items",
    description:
      "Purchase items needed to progress your realm or amass your Shadowcorn army!",
    image: item,
    url: BUY.MARKETPLACE.UNICORN_ITEMS,
    label: null,
    target: "_blank",
  },
  {
    title: "Land",
    description:
      "Enable your unicorns to generate resources by purchasing a land plot!",
    image: land,
    url: BUY.MARKETPLACE.UNICORN_LAND,
    label: null,
    target: "_blank",
  },
  {
    title: "Shadowcorn",
    description: 'Purchase a Shadowcorn to begin your "evil" empire!',
    image: shadowcorn,

    url: BUY.MARKETPLACE.SHADOWCORN,
    label: null,
    target: "_blank",
  },
  {
    title: "Gems",
    description: "Boost your Unicorn's stats by purchasing and equipping gems!",
    image: gem,

    url: BUY.MARKETPLACE.GEMS,
    label: null,
    target: "_blank",
  },
  {
    title: "Rituals",
    description:
      "Gain the upper edge in daily Shadow Forge rewards by purchasing rituals!",
    image: rituals,
    url: BUY.MARKETPLACE.RITUALS,
    target: "_blank",
  },
];

const NavMPMenuWrap = styled.nav`
  padding: 12px 12px;

  @media (${device.lg}) {
    padding: 32px 26px;
  }
`;

const ItemImage = styled.img`
  width: 100%;
  border-radius: 8px;
`;

const ItemLink = styled.div`
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 14px;
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.palette.gray350 : theme.palette.blue200};
  text-decoration: none;
  padding-top: 12px;
`;

const ItemWrap = styled(Link)`
  text-decoration: none;
  transition: background 0.2s ease-in-out;
  display: block;
  padding: 12px;
  border-radius: 8px;
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  filter: ${({ $disabled }) =>
    $disabled ? "grayscale(0.5)" : "theme.palette.blue200"};
  &:hover {
    background: ${({ theme, $disabled }) =>
      $disabled ? "none" : theme.palette.slate50};
  }

  @media (${device.xl}) {
    padding: 0 12px;
  }
`;

const ItemDetailWrap = styled.div`
  padding: 10px 0;
`;

const ItemTitleWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 8px;
`;

const MPGridRow = styled(Row)`
  row-gap: 8px;
  padding: 0 6px;
  @media (${device.lg}) {
    row-gap: 16px;
    padding: 0 16px;
  }
`;

export function NavMPMenu() {
  return (
    <NavMPMenuWrap>
      <MPGridRow>
        {MP.map((item) => (
          <Col key={item.title} xs={12} lg={6} xl={4} className="p-0">
            <ItemWrap
              to={item.url ? item.url : "#"}
              target={item.target ? item.target : null}
              $disabled={item?.disabled ? true : false}
            >
              <Row className="align-items-center mx-0">
                <Col xs={4} sm={3} xxl={3} className="p-0">
                  <ItemImage src={item.image} alt={item.title} />
                </Col>
                <Col xs={8} sm={9} xxl={9}>
                  <ItemDetailWrap>
                    <ItemTitleWrap>
                      <Typography variant="textLg" color="darkBlue300" tag="h3">
                        {item.title}
                      </Typography>
                      <LinkLabel variant={item.label} />
                    </ItemTitleWrap>
                    <Typography
                      variant="textXs"
                      color="gray500"
                      tag="p"
                      weight={600}
                    >
                      {item.description}
                    </Typography>
                    <ItemLink $disabled={item?.disabled ? true : false}>
                      {item.linkTitle || "Browse"}
                    </ItemLink>
                  </ItemDetailWrap>
                </Col>
              </Row>
            </ItemWrap>
          </Col>
        ))}
      </MPGridRow>
    </NavMPMenuWrap>
  );
}
