import styled from "styled-components";
import hero_clouds from "../../../img/home/hero-clouds.png";
import hero_rainbow from "../../../img/home/hero-rainbow.png";
import air_balloon from "../../../img/home/air-balloon.png";
import hero_rainbow_bright from "../../../img/home/hero-rainbow-bright.png";
import faded_rainbow from "../../../img/home/faded-rainbow.png";
import { device } from "../../../theme/mediaQuery";

export const HomeHeroBackground = styled.div`
  position: relative;
  z-index: 1;

  &::before {
    // clouds
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    background-image: url(${hero_clouds});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    transform: translateY(5%);
    z-index: -1;
    opacity: 0.5;
  }

  &::after {
    // rainbow
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-image: url(${hero_rainbow});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: -1;

    @media (${device.xxl}) {
      transform: translateX(10%);
    }
  }
`;

export const CloudBackground = styled.div`
  position: relative;
  z-index: 1;

  &::before {
    // clouds
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    background-image: url(${hero_clouds});
    background-size: contain;
    background-repeat: repeat-x;
    background-position: bottom;
    transform: translateY(2%);
    z-index: -1;
    opacity: 0.5;
  }
`;

export const RainbowBackground = styled.div`
  position: relative;
  z-index: 1;

  &::after {
    // rainbow
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-image: url(${hero_rainbow});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: -1;

    top: 30px;
    left: -60px;
    background-size: contain;

    @media (${device.md}) {
      left: 0;
      right: 0;
      background-size: cover;
    }
    @media (${device.xxl}) {
      transform: translateX(10%);
    }
  }
`;

export const BrightRainbowBackground = styled.div`
  position: relative;
  z-index: 1;

  &::after {
    // rainbow
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-image: url(${hero_rainbow_bright});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    z-index: -1;

    top: 13%;
    left: -60px;
    opacity: 0;

    @media (${device.sm}) {
      opacity: 1;
    }
    @media (${device.md}) {
      top: 7%;
    }
    @media (${device.xxl}) {
      top: 6%;
    }
    @media (${device["4xl"]}) {
      opacity: 0;
    }
  }
`;
export const AirballoonBackground = styled.div`
  position: relative;
  z-index: 1;
  &::after {
    // air balloon
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-image: url(${air_balloon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    z-index: -1;
    opacity: 0;
    @media (${device.md}) {
      opacity: 1;
      top: 5%;
    }
    @media (${device.lg}) {
      top: 10%;
    }
    @media (${device.xxxl}) {
      top: 0;
    }
  }
`;

export const FadedRainbowBackground = styled.div`
  position: relative;
  z-index: 1;
  &::after {
    // faded rainbow
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-image: url(${faded_rainbow});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    z-index: -1;
    opacity: 1;
    top: -65px;
    @media (${device.sm}) {
      top: -75px;
    }
    @media (${device.md}) {
      top: -80px;
    }
    @media (${device.lg}) {
      top: -100px;
      object-fit: cover;
    }
    @media (${device.xxxl}) {
      top: -120px;
    }
    @media (min-width: 2251px) {
      right: unset;
      left: -600px;
      background-size: contain;
      opacity: 0.5;
    }
    @media (min-width: 2599px) {
      opacity: 0;
    }
  }
`;
