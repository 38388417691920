import React from "react";
import styled from "styled-components";
import { RewardCard } from "./RewardCard";
import { device } from "../../theme/mediaQuery";

const RewardCardWrap = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  padding: 0 16px;
  gap: 14px;
  align-content: start;
  justify-items: center;

  @media (${device.xl}) {
    padding: 0 24px;
    gap: 16px;
  }
`;

// const rewards = Array.from({ length: 8 }, () => ({
//   name: `10 nebula shadowcorn lootboxes`,
//   img: "/",
//   id: 1234,
// }));

export function RewardCards({ data, network, ...rest }) {
  const rewards = network ? data?.eventsArr : data?.dropper?.dropper;
  return (
    <RewardCardWrap {...rest}>
      {rewards.map((reward, i) => (
        <RewardCard
          name={reward.name}
          // description={reward.description}
          img={reward.image}
          key={`${reward.name}-${i}`}
          flex
          amount={reward}
        />
      ))}
    </RewardCardWrap>
  );
}
