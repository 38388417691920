import styled from "styled-components";
import { device } from "../../theme/mediaQuery";
import { Icon } from "./Icon";
const PaginationWrap = styled.ul`
  display: none;
  justify-content: center;

  align-items: center;
  background-color: ${({ theme }) => theme.palette.purple800};
  border-radius: 8px;
  margin: 0 auto;
  overflow: hidden;
  @media (${device.md}) {
    display: flex;
  }
`;

const PaginationMobileWrap = styled(PaginationWrap)`
  display: flex;
  gap: 16px;
  @media (${device.md}) {
    display: none;
  }
`;

const PaginationItemWrap = styled.li`
  color: ${({ theme }) => theme.palette.white};
  opacity: ${({ $disabled }) => ($disabled ? 0.2 : 1)};
  background-color: ${({ theme, $active }) =>
    !$active ? "transparent" : theme.palette.purple700};
  cursor: pointer;
  padding: 0 8px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 20px;
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  user-select: none;
  transition: background-color 150ms ease-in;

  &:hover {
    background-color: ${({ theme, $divider }) =>
      !$divider && theme.palette.purple700};
  }
`;

const PageCountWrap = styled.div`
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 12px;
`;

export function Pagination({ currentPage, totalPages, onPageChange }) {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }


  const renderPageNumbers = () => {
    const pageRangeDisplayed = 2; // Number of page numbers to display before/after current page
    const minPagesOnEdges = 5; // Minimum number of pages to display on edges
    const items = [];

    // If there are less than 10 pages, display all pages
    if (totalPages <= minPagesOnEdges + 2 * pageRangeDisplayed) {
      return pageNumbers.map((number) => (
        <PaginationItem
          key={number}
          active={currentPage === number}
          onClick={() => onPageChange(number)}
        >
          {number}
        </PaginationItem>
      ));
    }

    // If there are more than 10 pages, display a subset of pages
    let start = Math.min(
      Math.max(1, currentPage - pageRangeDisplayed),
      totalPages - minPagesOnEdges + 1
    );
    let end = Math.min(start + 2 * pageRangeDisplayed, totalPages);

    // If the current page is near the start, display the first 5 pages
    if (start > 1) {
      items.push(
        <PaginationItem
          key={1}
          active={currentPage === 1}
          onClick={() => onPageChange(1)}
        >
          {1}
        </PaginationItem>
      );
      if (start > 2) {
        items.push(
          <PaginationItem key="break1" divider>
            ...
          </PaginationItem>
        );
      }
    }

    // Display the page numbers
    for (let i = start; i <= end; i++) {
      items.push(
        <PaginationItem
          key={i}
          active={currentPage === i}
          onClick={() => onPageChange(i)}
        >
          {i}
        </PaginationItem>
      );
    }

    // If the current page is near the end, display the last 5 pages
    if (end < totalPages) {
      if (end < totalPages - 1) {
        items.push(
          <PaginationItem key="break2" divider>
            ...
          </PaginationItem>
        );
      }
      items.push(
        <PaginationItem
          key={totalPages}
          active={currentPage === totalPages}
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </PaginationItem>
      );
    }

    return items;
  };

  return (
    <>
      <PaginationWrap>
        <PaginationItem
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <Icon name="arrow-left" size="20px" />
        </PaginationItem>
        {renderPageNumbers()}
        <PaginationItem
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <Icon name="arrow-right" size="20px" />
        </PaginationItem>
      </PaginationWrap>
      <PaginationMobileWrap>
        <PaginationItem
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <Icon name="arrow-left" size="20px" />
          Prev
        </PaginationItem>
        <PageCountWrap>
          Page {currentPage} of {totalPages}
        </PageCountWrap>
        <PaginationItem
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
          <Icon name="arrow-right" size="20px" />
        </PaginationItem>
      </PaginationMobileWrap>
    </>
  );
}

function PaginationItem({ active, disabled, divider, children, ...rest }) {
  return (
    <PaginationItemWrap
      $active={active}
      $disabled={disabled}
      $divider={divider}
      {...rest}
    >
      {children}
    </PaginationItemWrap>
  );
}
