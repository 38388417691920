import { StakingCommonCard, StakingCommonCardHeader, StakingCommonCardTitle } from "../StakingCommonCard";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import { StakingTokenImage } from "../StakingTokenImage";
import Spinner from "../../base/Spinner";
import { Tooltip } from "../../base/Tooltip";
import { Typography } from "../../base/Typography";
import { useAtomValue } from "jotai";
import { currentWalletAddressAtom } from "../../../store";

const CardWrap = styled(StakingCommonCard)`
  height: 100%;
`;

const HeadingWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 16px;
  height: 100%;
  @media (${device.lg}) {
    padding: 0 40px;
    flex-wrap: nowrap;

  }
`;

const CardHeaderWrap = styled(StakingCommonCardHeader)`
  height: auto;
  padding: 10px 0;
  @media (${device.lg}) {
    height: 80px;
    padding: 0;
  }
`;

const IconWrap = styled.div`
  width: 30px;
  height: 30px;

  & img {
    width: 100%;
    height: 100%;
  }

  @media (${device.lg}) {
    width: 80px;
    height: 80px;
    transform: translateY(20px);
  }
`;

const TitlesWrap = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    line-height: 1em;
  }
`;

const SubTitle = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.white};


  @media (${device.lg}) {
    font-size: 16px;
  }
`;


const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;

  & button {
    padding: 0;
  }

  @media (${device.md}) {
    & * {
      font-size: 16px;
    }
  }

`;

const StatCardWrap = styled.div`
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  border-radius: 8px;
`;

const StatCardTitle = styled.h5`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  color: #F5B53D;

  @media (${device.lg}) {
    font-size: 16px;
  }
`;

const StatCardValue = styled.div`
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.display};
  color: ${({ theme }) => theme.palette.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  & * {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
  }

  @media (${device.lg}) {
    font-size: 28px;
    flex-wrap: nowrap;
  }
`;

const StatCardSecondValue = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  color: ${({ theme }) => theme.palette.white};
`;

const BodyWrap = styled.div`
  padding: 16px;

  @media (${device.lg}) {
    padding: ${({ $hasBorder }) =>  $hasBorder  ? "36px 40px" : "40px"};
  }
`;


export const StakingOverviewStatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  //
  // @media (${device.lg}) {
  //   grid-template-columns: repeat(2, 1fr);
  // }
`;

export const StakingOverviewStatsFullRow = styled.div`
  grid-column: 1 / -1;
`;


const StatCardValueIcons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  @media (${device.lg}) {
    flex-wrap: nowrap;
  }
`;

const StatCardValueIcon = styled.div`
  width: 32px;
  height: auto;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const TipWrap = styled.div`
  background: #24004e;
  text-align: center;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: ${({ theme }) => theme.fonts.display};
  color: ${({ theme }) => theme.palette.white};
  padding: 8px 12px;
  @media (${device.lg}) {
    font-size: 18px;
  }
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${({ $isLarge }) => $isLarge ? "280px" : "197px"};
`;

const TooltipWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 7px 2px;

  & > img {
    width: 20px;
    height: 20px;
  }
`;

export function StakingOverviewStats({
  title,
  subtitle,
  actions,
  children,
  headerTip,
  footerTip,
  isLoading,
  token,
}) {
  const walletAddress = useAtomValue(currentWalletAddressAtom);

  const isCULP = token === "CULP";
  return (
    <CardWrap>
      <CardHeaderWrap>
        <HeadingWrap>
          <IconWrap>
            <StakingTokenImage token={token} />
          </IconWrap>
          <TitlesWrap>
            <StakingCommonCardTitle>{title}</StakingCommonCardTitle>
            <SubTitle>
              {isLoading && <>&nbsp;</>}
              {!isLoading && walletAddress && subtitle}
            </SubTitle>
          </TitlesWrap>
          {walletAddress && <ActionsWrap>{actions}</ActionsWrap>}
        </HeadingWrap>
      </CardHeaderWrap>
      {headerTip && <TipWrap>{headerTip}</TipWrap>}
      <BodyWrap $hasBorder={isCULP}>
        {isLoading && (
          <LoadingWrap $isLarge={walletAddress}>
            <Spinner />
          </LoadingWrap>
        )}
        {!isLoading && children}
      </BodyWrap>
      {footerTip && <TipWrap>{footerTip}</TipWrap>}
    </CardWrap>
  );
}

export function StakingOverviewStatsSection({
  title,
  value,
  secondValue,
  tokens = [],
}) {
  const secondValueError = !secondValue;
  return (
    <StatCardWrap>
      <StatCardTitle>{title}</StatCardTitle>
      <StatCardValue>
        {value}
        <StatCardValueIcons>
          {tokens.map((token, index) => (
            <Tooltip
              background="#8221E3"
              position="top"
              key={`token-${index}`}
              border="lg"
              overlay={
                <TooltipWrap>
                  <StakingTokenImage token={token} />
                  <Typography
                    variant="textSm"
                    md={{ variant: "textMd" }}
                    color="white"
                    as="p"
                    family="display"
                  >
                    {token}
                  </Typography>
                </TooltipWrap>
              }
            >
              <StatCardValueIcon>
                <StakingTokenImage token={token} />
              </StatCardValueIcon>
            </Tooltip>
          ))}
        </StatCardValueIcons>
      </StatCardValue>
      {!secondValueError && (
        <StatCardSecondValue>{secondValue}</StatCardSecondValue>
      )}
    </StatCardWrap>
  );
}
