import styled from "styled-components";
import { Typography } from "../base/Typography";
import { CHAIN } from "../../views/Bridge";

const InfoBox = styled.div`
  border-radius: 4px;
  background: rgba(62, 15, 148, 0.4);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
  column-gap: 8px;

  & span:last-of-type {
    text-align: right;
  }
`;
const ErrorMessage = styled(Typography)`
  color: #fe7a75;
  margin-top: -10px;
  opacity: ${({ $error }) => ($error ? 1 : 0)};
  max-height: ${({ $error }) => ($error ? "20px" : "0")};
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease, margin 0.5s ease;
  padding: 0;
  // outline: solid black 2px;
`;

export const TransferDetails = ({
  transferQuantity = 0,
  estimatedTime = 0,
  totalBalance,
  gasFees,
  error,
  token,
  ...rest
}) => {
  const convertedToken = token.convertedToken;
  const ratio = token.ratio;
  const chain = CHAIN[token.chainId].name;
  const formattedGasFees = Number(gasFees).toFixed(3);
  const totalReceived = Number(transferQuantity)
    ? Number(transferQuantity) / ratio
    : 0;
  const formattedTotalReceived = (totalReceived / 10 ** 18)?.toLocaleString(
    "en-US",
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    }
  );

  const formattedCurrentBalance = totalBalance?.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  });
  return (
    <>
      <InfoBox {...rest}>
        <InfoRow>
          <Typography
            tag="span"
            variant="textXs"
            md={{ variant: "textSm" }}
            lg={{ variant: "textMd" }}
          >
            Total {convertedToken} to be received on {chain}
          </Typography>
          <Typography
            tag="span"
            variant="textXs"
            md={{ variant: "textSm" }}
            lg={{ variant: "textMd" }}
            style={{ fontWeight: "bold" }}
          >
            {formattedTotalReceived} {convertedToken}
          </Typography>
        </InfoRow>
        {totalBalance !== undefined && (
          <InfoRow>
            <Typography
              tag="span"
              variant="textXs"
              md={{ variant: "textSm" }}
              lg={{ variant: "textMd" }}
            >
              Current {convertedToken} Balance
            </Typography>
            <Typography
              tag="span"
              variant="textXs"
              md={{ variant: "textSm" }}
              lg={{ variant: "textMd" }}
              style={{ fontWeight: "bold" }}
            >
              {formattedCurrentBalance} {convertedToken}
            </Typography>
          </InfoRow>
        )}
        <InfoRow>
          <Typography
            tag="span"
            variant="textXs"
            md={{ variant: "textSm" }}
            lg={{ variant: "textMd" }}
          >
            Estimated Time of Completion
          </Typography>
          <Typography
            tag="span"
            variant="textXs"
            md={{ variant: "textSm" }}
            lg={{ variant: "textMd" }}
            style={{ fontWeight: "bold" }}
          >
            {estimatedTime} minutes
          </Typography>
        </InfoRow>
        {gasFees !== undefined && (
          <InfoRow>
            <Typography
              tag="span"
              variant="textXs"
              md={{ variant: "textSm" }}
              lg={{ variant: "textMd" }}
            >
              Fees
            </Typography>
            <Typography
              tag="span"
              variant="textXs"
              md={{ variant: "textSm" }}
              lg={{ variant: "textMd" }}
              style={{ fontWeight: "bold" }}
            >
              {formattedGasFees} MATIC
            </Typography>
          </InfoRow>
        )}
      </InfoBox>
      <ErrorMessage
        variant="text2xs"
        md={{ variant: "textXs" }}
        $error={error}
        weight={600}
      >
        {error}&nbsp;
      </ErrorMessage>
    </>
  );
};
