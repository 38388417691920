import React, { useEffect, useMemo, useState } from "react";
import { useAtomValue } from "jotai/index";
import styled from "styled-components";
import {
  LeaderboardProgressBarSection,
  LeaderboardSummaryTitleSection,
  LeaderboardSummary,
  BodyRow,
} from "./LeaderboardSummary";
import {
  calculateReward,
  getLeaderboardRewards,
  bingoConfig,
  bingoRewards,
} from "./LeaderboardHelper";
import LeaderboardTableWithPagination from "./components/LeaderboardTableWithPagination";
import lootbox_unicorn from "../../img/leaderboard/lootbox_unicorn.png";
import { currentWalletAddressAtom } from "../../store";
import { device } from "../../theme/mediaQuery";
import { CRYPTO } from "../../consts/externalLinks";
import { EVENT_KEYS, PLAY_GAME } from "../../consts/googleEvents";
import { googleAnalyticsEvent } from "../../helpers/googleAnalytics";
import { GameButtons } from "../base/GameButtons";
import { Typography } from "../base/Typography";
import { LeaderboardRow, VARIANTS } from "./components/LeaderboardRow";
import { useIsMobile } from "../../helpers/useIsMobile";
import { ProgressBar } from "../base/ProgressBar";

import bingoImg from "../../img/leaderboard/bingo/bingo.png";
import unicorn from "../../img/leaderboard/bingo/unicorn.png";
import qr from "../../img/games/bingo-qr.png";
import { Button } from "../base/Button";
import { Box } from "../base/Box";
import { GetFullLeaderboard } from "../../hooks/GetLeaderboard";
import { connectWallet } from "../ConnectWallet/connectWallet";

const LEADERBOARD_ID = "a5cdf4be-f5be-40dc-8bac-3c7af25682f1"; // Unicorn party event
const LEADERBOARD_DAILY_ID = "41bdfbfb-784b-4741-98f7-dd1df893b864"; // Unicorn party event
const TIMESTAMP_EVENT = 1719014400; // Unicorn party event - June 22

const HEADER_COLOR = "linear-gradient(117deg, #6EB0ED 18.67%, #614CD7 82.97%)";
const HEADER_COLOR_ALT =
  "linear-gradient(120deg, #14DBBD 21.68%, #5463D3 61.71%, #614CD7 90.21%)";
const HEADER_COLOR_ALT_2 =
  "linear-gradient(117deg, #B365DC 18.67%, #614CD7 82.97%);";
const BACKGROUND_COLOR = "#B365DC";
const PROGRESS_BAR_COLOR =
  "linear-gradient(143.22deg, #B94EDA 7.24%, #8F25B0 94.73%)";

const gameLinks = {
  mobileLinks: {
    ios: {
      link: CRYPTO.BINGO.IOS,
      analytics: () =>
        googleAnalyticsEvent(EVENT_KEYS.PLAY_GAME, PLAY_GAME.BINGO_IOS),
    },
    android: {
      link: CRYPTO.BINGO.ANDROID,
      analytics: () =>
        googleAnalyticsEvent(EVENT_KEYS.PLAY_GAME, PLAY_GAME.BINGO_ANDROID),
    },
    apk: {
      link: CRYPTO.BINGO.APK_WEBSITE,
      qr: qr,
      event: PLAY_GAME.BINGO_APK,
    },
  },
};

export function LeaderboardUnicornPartyTable({ includeSummary = false }) {
  const leaderboard = GetFullLeaderboard({ id: LEADERBOARD_ID });
  const daily = GetFullLeaderboard({ id: LEADERBOARD_DAILY_ID });

  return (
    <>
      {includeSummary && <LeaderboardUnicornParty leaderboard={leaderboard} />}
      {includeSummary && <LeaderboardEventTasks leaderboard={daily} />}
      <LeaderboardTable leaderboard={leaderboard} />
    </>
  );
}

const ApkWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding: 12px;
  @media (${device.lg}) {
    gap: 16px;
  }
`;

export function LeaderboardUnicornParty({ leaderboard }) {
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const isMobile = useIsMobile();
  const isLoading = walletAddress ? leaderboard : true;
  const renderBody = (leaderboard) => {
    const self = leaderboard?.self;
    const title = "Unicorn bingo milestone";

    // points
    const score = self?.score ? parseInt(self.score) : 0;

    // points highlight
    const pointsHighlight = [
      {
        title: score ? parseInt(score).toLocaleString("en") : 0,
        bottomTitle: `points earned`,
        color: "#C82CE7",
        accent: "#1E2B61",
      },
      {
        title: calculateReward(score, bingoConfig).toLocaleString("en"),
        bottomTitle: "Lootboxes Earned",
        img: lootbox_unicorn,
        color: "#C82CE7",
        accent: "#1E2B61",
      },
    ];

    // progress bar
    const milestones = [
      {
        value: 250,
        maxValue: 5000,
        // reward: "0",
        score: score,
        // lootbox: lootbox_unicorn,
        image: "unicorn",
        color: "white",
      },
      {
        value: 500,
        maxValue: 5000,
        reward: "1",
        score: score,
        lootbox: lootbox_unicorn,
        image: "unicorn",
        color: "white",
      },
      {
        value: 1000,
        maxValue: 5000,
        reward: "3",
        score: score,
        lootbox: lootbox_unicorn,
        image: "unicorn",
        color: "white",
      },
      {
        value: 2500,
        maxValue: 5000,
        reward: "7",
        score: score,
        lootbox: lootbox_unicorn,
        image: "unicorn",
        color: "white",
      },
      {
        value: 5000,
        maxValue: 5000,
        reward: "15",
        score: score,
        lootbox: lootbox_unicorn,
        image: "unicorn",
        color: "white",
      },
    ];

    // past milestones
    // const pastMilestones = {
    //   milestone1: {
    //     title: "Bumpercorns Debut",
    //     score: scoreMilestone1,
    //     image: `milestone_unicorn_${calculateMilestone(
    //       scoreMilestone1,
    //       unicornMilestoneConfig
    //     )}`,
    //   },
    //   milestone2: {
    //     title: "Bumpercorns Initiation Milestone",
    //     score: scoreMilestone2,
    //     image: `milestone_unicorn_${calculateMilestone(
    //       scoreMilestone2,
    //       unicornMilestoneConfig
    //     )}`,
    //   },
    // };
    // const pastMilestonesColors = {
    //   hintColor: "white",
    // };P

    return (
      <>
        <LeaderboardSummaryTitleSection
          title={title}
          pointsHighlight={pointsHighlight}
          className="pb-4 pb-lg-5"
          img={bingoImg}
        />
        <LeaderboardProgressBarSection
          milestones={milestones}
          progress={score ? (parseInt(score) / 5000) * 100 : 0}
          progressBarColor={PROGRESS_BAR_COLOR}
          sectionBackground="#5C2875"
        />
        <BodyRow $color="#231852" $isLastSection>
          <ApkWrap>
            <GameButtons
              titleColor="white"
              tagsColor="indigo300"
              {...gameLinks}
            />
            {!walletAddress && <ConnectWallet />}
          </ApkWrap>
        </BodyRow>
        {/* <LeaderboardPastMilestonesSection
          pastMilestones={pastMilestones}
          self={!!self}
          hintColor="white"
          lastSection
          {...pastMilestonesColors}
        /> */}
      </>
    );
  };

  return (
    <LeaderboardSummary
      leaderboard={isLoading}
      headerTitle="Bingo Leaderboard"
      eventEndAt={TIMESTAMP_EVENT}
      renderBody={renderBody}
      headerBackgroundColor={HEADER_COLOR}
      headerPattern="labyrinth"
      gradient={BACKGROUND_COLOR}
      spinnerColor="white"
      pattern="petroglyph"
      patternSize={isMobile ? "sm" : "35%"}
      headerPatternSize="sm"
      patternOpacity="0.04"
      patternInvert
    />
  );
}

const LeaderboardWrap = styled.div`
  padding-bottom: 20px;
  @media (${device.lg}) {
    padding-bottom: 40px;
  }
`;

export function LeaderboardTable({ leaderboard }) {
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const isLoading = walletAddress ? leaderboard : true;
  const renderBody = (leaderboard) => {
    return (
      <LeaderboardWrap>
        <LeaderboardTableWithPagination
          renderReward={(row) => getLeaderboardRewards(row.rank, bingoRewards)}
          headerBackgroundColor="#231852"
          headerPattern="labyrinth"
          leaderboard={leaderboard?.data}
          LEADERBOARD_ID={LEADERBOARD_ID}
          style={{ borderRadius: 0 }}
        />
      </LeaderboardWrap>
    );
  };

  return (
    <>
      <LeaderboardSummary
        leaderboard={isLoading}
        headerTitle="Leaderboard"
        renderBody={renderBody}
        headerBackgroundColor={HEADER_COLOR_ALT}
        headerPattern="labyrinth"
        gradient="white"
      />
    </>
  );
}

const BodyRowWrap = styled(BodyRow)`
  display: flex;
  flex-direction: column;
  gap: 27px;
`;

const TableHeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 12px;
  @media (${device.md}) {
    flex-direction: row;
    padding-left: 40px;
  }
  @media (${device.lg}) {
    padding-left: 40px;
  }
  @media (${device.xl}) {
    padding-left: 100px;
  }
`;

const ProgressBarContainer = styled.div`
  flex: 2 2 auto;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (${device.lg}) {
    padding: 0 14px;
  }
  @media (${device.xl}) {
    padding: 0 20px;
  }
`;

const ProgressText = styled.span`
  position: absolute;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: ${({ theme }) => theme.fonts.display};
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 14px;
`;

const HeaderTitle = styled(Typography)`
  align-self: flex-end;
  width: 60%;
  text-align: right;
  font-size: 16px;
  @media (min-width: 375px) {
    font-size: 20px;
    width: 70%;
  }
  @media (${device.sm}) {
    font-size: 21px;
    width: 50%;
    align-self: unset;
    text-align: center;
  }
  @media (${device.md}) {
    font-size: 24px;
    width: unset;
  }
  @media (${device.xl}) {
    flex: 1 0 auto;
  }
`;

const HeaderImg = styled.img`
  position: absolute;
  top: -14px;
  left: 12px;
  width: 94px;
  height: 94px;
  @media (min-width: 350px) {
    top: -14px;
  }

  @media (min-width: 375px) {
    left: 15px;
  }
  @media (min-width: 507px) {
    top: -38px;
  }

  @media (${device.sm}) {
    top: -14px;
    left: 35px;
  }
  @media (${device.md}) {
    top: unset;
    bottom: 0;
    left: -11px;
  }
  @media (${device.lg}) {
    bottom: 0;
    left: 0;
    width: 120px;
    height: 120px;
  }
  @media (${device.xl}) {
    width: 160px;
    height: 160px;
  }
`;

const TOTAL_DAILY = 4;

export function LeaderboardEventTasks({ leaderboard }) {
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const isLoading = walletAddress ? leaderboard : true;
  const self = walletAddress ? leaderboard?.self : null;
  const pointsData = self?.points_data ? self?.points_data : null;

  const TABLE_DATA = useMemo(() => {
    return {
      score: {
        label: "Score 11,500 Points in a Match",
        timesTotal: 7,
        pointsPer: "10 points",
        timesCompleted: pointsData?.score_11500points
          ? parseInt(pointsData.score_11500points).toLocaleString("en")
          : 0,
        points: pointsData?.score_11500points_pts
          ? parseInt(pointsData.score_11500points_pts).toLocaleString("en")
          : 0,
      },
      bingo: {
        label: "Receive 5 Bingos in a Match",
        timesTotal: 5,
        pointsPer: "25 points",
        timesCompleted: pointsData?.receive_5bingos
          ? parseInt(pointsData.receive_5bingos).toLocaleString("en")
          : 0,
        points: pointsData?.receive_5bingos_pts
          ? parseInt(pointsData.receive_5bingos_pts).toLocaleString("en")
          : 0,
      },
      daubs: {
        label: "Complete 20 Daubs in a Match",
        timesTotal: 3,
        pointsPer: "50 points",
        timesCompleted: pointsData?.complete_20daubs
          ? parseInt(pointsData.complete_20daubs).toLocaleString("en")
          : 0,
        points: pointsData?.complete_20daubs_pts
          ? parseInt(pointsData.complete_20daubs_pts).toLocaleString("en")
          : 0,
      },
      multiBingo: {
        label: "Receive 7 Multi Bingos in a Match",
        timesTotal: 2,
        pointsPer: "100 points",
        timesCompleted: pointsData?.receive_7multibingos
          ? parseInt(pointsData.receive_7multibingos).toLocaleString("en")
          : 0,
        points: pointsData?.receive_7multibingos_pts
          ? parseInt(pointsData.receive_7multibingos_pts).toLocaleString("en")
          : 0,
      },
      match: {
        label: "Complete a Match",
        timesTotal: null,
        pointsPer: "5 points",
        timesCompleted: pointsData?.participated_matches
          ? parseInt(pointsData.participated_matches).toLocaleString("en")
          : 0,
        points: pointsData?.participated_matches_pts
          ? parseInt(pointsData.participated_matches_pts).toLocaleString("en")
          : 0,
      },
    };
  }, [pointsData]);

  const dailyProgress = useMemo(() => {
    const taskKeys = Object.keys(TABLE_DATA);
    return taskKeys.reduce((total, key) => {
      const task = TABLE_DATA[key];
      return (
        total +
        (task.timesTotal && task.timesCompleted >= task.timesTotal ? 1 : 0)
      );
    }, 0);
  }, [TABLE_DATA]);

  const renderBody = (leaderboard) => {
    return (
      <BodyRowWrap className="pb-4 pb-lg-5">
        <EventTable
          header={
            <TableHeaderWrap>
              <HeaderImg src={unicorn} alt="unicorn" />
              <HeaderTitle tag={"h3"} textshadow="mediumAlt" color="white">
                Complete all daily goals!
              </HeaderTitle>
              <ProgressBarContainer>
                <ProgressBar
                  now={(dailyProgress / TOTAL_DAILY) * 100}
                  max={TOTAL_DAILY}
                  color="linear-gradient(270deg, #F6CE7E 0%, #F0A045 100%)"
                  background="rgba(0, 0, 0, 0.20)"
                  boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"
                  animated={false}
                />
                <ProgressText>{`${dailyProgress} /${TOTAL_DAILY}`}</ProgressText>
              </ProgressBarContainer>
              <Timer />
            </TableHeaderWrap>
          }>
          <LeaderboardRow
            variant={VARIANTS.PURPLE}
            rows={[
              {
                label: TABLE_DATA.score.label,
                subtitle: TABLE_DATA.score.timesTotal + " times",
                detail: TABLE_DATA.score.pointsPer,
                progress: TABLE_DATA.score.timesCompleted,
                total: TABLE_DATA.score.timesTotal,
              },
            ]}
          />
          <LeaderboardRow
            variant={VARIANTS.PURPLE}
            rows={[
              {
                label: TABLE_DATA.bingo.label,
                subtitle: TABLE_DATA.bingo.timesTotal + " times",
                detail: TABLE_DATA.bingo.pointsPer,
                progress: TABLE_DATA.bingo.timesCompleted,
                total: TABLE_DATA.bingo.timesTotal,
              },
            ]}
          />
          <LeaderboardRow
            variant={VARIANTS.PURPLE}
            rows={[
              {
                label: TABLE_DATA.daubs.label,
                subtitle: TABLE_DATA.daubs.timesTotal + " times",
                detail: TABLE_DATA.daubs.pointsPer,
                progress: TABLE_DATA.daubs.timesCompleted,
                total: TABLE_DATA.daubs.timesTotal,
              },
            ]}
          />
          <LeaderboardRow
            variant={VARIANTS.PURPLE}
            rows={[
              {
                label: TABLE_DATA.multiBingo.label,
                subtitle: TABLE_DATA.multiBingo.timesTotal + " times",
                detail: TABLE_DATA.multiBingo.pointsPer,
                progress: TABLE_DATA.multiBingo.timesCompleted,
                total: TABLE_DATA.multiBingo.timesTotal,
              },
            ]}
          />
        </EventTable>
        <EventTable
          header={
            <Typography
              tag={"h3"}
              variant={"textXl"}
              md={{ variant: "displayXs" }}
              lg={{ variant: "displaySm" }}
              textshadow="mediumAlt"
              color="white"
              className="text-center">
              Earn points at anytime by completing a match
            </Typography>
          }>
          <LeaderboardRow
            variant={VARIANTS.PINK}
            rows={[
              {
                label: TABLE_DATA.match.label,
                detail: TABLE_DATA.match.pointsPer,
                points: TABLE_DATA.match.points,
              },
            ]}
          />
        </EventTable>
      </BodyRowWrap>
    );
  };

  return (
    <>
      <LeaderboardSummary
        leaderboard={isLoading}
        headerTitle="Event tasks"
        renderBody={renderBody}
        headerBackgroundColor={HEADER_COLOR_ALT_2}
        headerPattern="labyrinth"
        gradient="#3B288A"
        spinnerColor="white"
      />
    </>
  );
}

const EventTableWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const TableHeader = styled.div`
  padding: 20px 18px;
  border-radius: 12px 12px 0px 0px;
  background: linear-gradient(180deg, #6951cc 0%, #352966 100%);
  min-height: 50px;
  position: relative;

  @media (${device.md}) {
    padding: 20px 24px;
  }
  @media (${device.lg}) {
    padding: 20px 60px;
    min-height: 88px;
  }
`;
const TableBody = styled.div`
  background: #231852;
  display: flex;
  padding: 18px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;

  width: 100%;
  overflow-x: auto;

  @media (${device.md}) {
    padding: 24px;
  }

  @media (${device.lg}) {
    padding: 40px;
  }
`;

const EventTable = ({ header, children }) => {
  return (
    <EventTableWrap>
      <TableHeader>{header}</TableHeader>
      <TableBody>{children}</TableBody>
    </EventTableWrap>
  );
};

const TimerWrap = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
`;
const TimeLeftWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (${device.md}) {
    gap: 0;
    flex-direction: column;
  }
`;

const Timer = () => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const endOfDay = new Date();
      endOfDay.setUTCHours(23, 59, 59, 999); // UTC EOD
      const diff = endOfDay - now;

      if (diff > 0) {
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeLeft("0h 0m 0s"); // Reset
      }
    }, 1000);

    return () => clearInterval(timer); // Clean up
  }, []);

  return (
    <TimerWrap>
      <TimeLeftWrap>
        <Typography
          tag={"p"}
          variant={"text2xs"}
          md={{ variant: "text2xs" }}
          lg={{ variant: "textSm" }}
          color="white"
          className="text-center">
          REFRESHES IN
        </Typography>
        <Typography
          tag={"p"}
          variant={"textLg"}
          md={{ variant: "textXl" }}
          lg={{ variant: "displaySm" }}
          family="display"
          color="white"
          className="text-center">
          {timeLeft}
        </Typography>
      </TimeLeftWrap>
      <Typography
        tag={"p"}
        variant={"text2xs"}
        md={{ variant: "text3xs" }}
        lg={{ variant: "textXs" }}
        color="white"
        className="text-center">
        *Could take up to 1 hour to update
      </Typography>
    </TimerWrap>
  );
};

const ConnectWalletWrap = styled(Box)`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 20px 40px;

  @media (${device.lg}) {
    align-items: center;
    flex-direction: row;
    padding: 32px 24px;
  }

  & p {
    text-align: center;
    @media (${device.lg}) {
      text-align: left;
    }
  }
  // button
  & button * {
    font-size: 18px;
    white-space: nowrap;
    @media (${device.lg}) {
      font-size: 21px;
    }
    @media (${device.xxl}) {
      font-size: 25px;
    }
  }
`;

export const ConnectWallet = ({
  gradient = "linearBluePrimary",
  variant = "primary",
  connect,
}) => {
  const connectUser = () => {
    connectWallet(connect);
    googleAnalyticsEvent(EVENT_KEYS.CONNECT_CLICKED);
  };

  return (
    <ConnectWalletWrap
      variant="gradient"
      rounded="md"
      gradient={gradient}
      className="text-center">
      <Typography
        color="white"
        tag="p"
        weight={600}
        family="display"
        variant="textXl"
        md={{ variant: "displayXs" }}>
        Connect your wallet to participate in the leaderboard!
      </Typography>
      <Button variant={variant} onClick={connectUser} size="lg">
        Connect Wallet
      </Button>
    </ConnectWalletWrap>
  );
};
