import React from "react";
import styled, { useTheme } from "styled-components";
import {
  breakpointProps,
  responsiveBreakpoints,
} from "../../theme/utils/responsive-props";

export const TextVariant = {
  display3xl: "display3xl",
  display2xl: "display2xl",
  displayXl: "displayXl",
  displayLg: "displayLg",
  displayMd: "displayMd",
  displaySm: "displaySm",
  displayXs: "displayXs",
  textXl: "textXl",
  textLg: "textLg",
  textMd: "textMd",
  textSm: "textSm",
  textXs: "textXs",
  text2xs: "text2xs",
  text3xs: "text3xs",
};

function pickThemeProperties({ theme, variant }) {
  switch (variant) {
    case TextVariant.display3xl:
      return theme.typography.display3xl;
    case TextVariant.display2xl:
      return theme.typography.display2xl;
    case TextVariant.displayXl:
      return theme.typography.displayXl;
    case TextVariant.displayLg:
      return theme.typography.displayLg;
    case TextVariant.displayMd:
      return theme.typography.displayMd;
    case TextVariant.displaySm:
      return theme.typography.displaySm;
    case TextVariant.displayXs:
      return theme.typography.displayXs;
    case TextVariant.textXl:
      return theme.typography.textXl;
    case TextVariant.textLg:
      return theme.typography.textLg;
    case TextVariant.textMd:
      return theme.typography.textMd;
    case TextVariant.textSm:
      return theme.typography.textSm;
    case TextVariant.textXs:
      return theme.typography.textXs;
    case TextVariant.text2xs:
      return theme.typography.text2xs;
    case TextVariant.text3xs:
      return theme.typography.text3xs;
    default:
      return theme.typography.textMd;
  }
}

const BaseThemeTypography = styled.div`
  color: ${({ $color }) => $color};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  font-size: ${({ $fontSize }) => $fontSize};
  line-height: ${({ $lineHeight }) => $lineHeight};
  text-shadow: ${({ $textshadow }) => $textshadow};
  ${({ $family }) => $family && `font-family: ${$family};`};

  ${(args) =>
    breakpointProps(args, (props, query) => {
      const { fontSize, lineHeight } = pickThemeProperties({
        theme: args.theme,
        variant: props.variant,
      });

      return query`
      font-size: ${fontSize};
      line-height: ${lineHeight};
    `;
    })}

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const Typography = ({
  tag = "div",
  variant,
  weight = 400,
  color = "white",
  family,
  textshadow = false,
  children,
  ...rest
}) => {
  const theme = useTheme();
  const { fontSize, lineHeight } = pickThemeProperties({ theme, variant });
  const themeColor = theme.palette[color] || color;
  const $family = family && theme.fonts[family];
  const $textshadow = textshadow && theme.textshadows[textshadow];
  const breakpointsProps = responsiveBreakpoints(rest);

  return (
    <BaseThemeTypography
      $color={themeColor}
      $fontSize={fontSize}
      $fontWeight={weight}
      $lineHeight={lineHeight}
      $family={$family}
      $breakpointProps={breakpointsProps}
      $textshadow={$textshadow}
      as={tag}
      {...rest}
    >
      {children}
    </BaseThemeTypography>
  );
};
