import { Typography } from "../../base/Typography";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import { SemiCircleBox } from "../../../components/base/SemiCircleBox";

const StepBox = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const SemiCircleBoxWrap = styled(SemiCircleBox)`
  ${({ $isAdult }) => $isAdult && `height: 650px;`};

  @media (${device.sm}) {
    ${({ $isAdult }) => $isAdult && `height: 600px;`}
  };

  @media (${device.md}) {
    ${({ $isAdult }) => $isAdult && `height: 500px;`}
  };

  @media (${device.lg}) {
    ${({ $isAdult }) => $isAdult && `height: 430px;`}
  };
`;

const StepImage = styled.img`
  width: 120px;

  @media (${device.md}) {
    width: 140px;
  }
`;

const StepHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @media (${device.xl}) {
    width: 60%;
  }
`;

const StepBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto;
  text-align: center;
  padding: 16px 0;

  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

  @media (${device.xl}) {
    text-align: left;
    padding: 50px 0;
    padding: 0;
  }
`;

export function HomeEvolutionStep({ image, title, children, type }) {
  return (
    <StepBox>
      <SemiCircleBoxWrap
        image={<StepImage src={image} />}
        background="blueGradient"
        $isAdult={type === "adult"}
      >
        <StepHeader>
          <Typography
            tag="h4"
            variant="displaySm"
            color="white"
            textshadow="soft"
          >
            {title}
          </Typography>
        </StepHeader>
        <StepBody>{children}</StepBody>
      </SemiCircleBoxWrap>
    </StepBox>
  );
}
