import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container } from "../base/Container";
import { Typography } from "../base/Typography";
import { Button } from "../base/Button";
import { device } from "../../theme/mediaQuery";
import vid from "../../img/rainbowRumble/video.mp4";
import poster from "../../img/rainbowRumble/poster.jpg";

import PlayerIcon from "../../img/rainbowRumble/unicorn.png";
import ClockIcon from "../../img/rainbowRumble/clock.png";
import CUIcon from "../../img/currencies/rbw-tilted.png";
import { CRYPTO } from "../../consts/externalLinks";
import { EVENT_KEYS, PLAY_GAME } from "../../consts/googleEvents";
import { googleAnalyticsEvent } from "../../helpers/googleAnalytics";

const ContainerWrap = styled(Container)`
  @media (${device.lg}) {
    margin-top: auto;
    margin-bottom: auto;
  }
  @media (${device.xxl}) {
    margin-top: unset;
    margin-bottom: unset;
  }
`;

const HeroWrap = styled.section`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoWrap = styled.div`
  position: relative;
  & video {
    max-height: calc(100vh - 215px);
    max-width: 100%;
    border-radius: 16px;
    object-fit: cover;
    filter: brightness(0.85);
  }
`;

const HeadingWrap = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  text-align: center;
  width: 95%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
/* 
  margin-top: 35px;
  @media (${device.sm}) {
    margin-top: 50px;
  }
  @media (${device.md}) {
    margin-top: 80px;
  }
  @media (${device.lg}) {
    margin-top: 145px;
  } */

  & > p {
    font-size: 10px;
    @media (${device.lg}) {
      font-size: 16px;
    }
    @media (${device.xl}) {
      font-size: 40px;
    }
  }
  /* & > h1 {
    padding: 0 20px;
    font-size: 18px;
    @media (${device.md}) {
      font-size: 22px;
    }
    @media (${device.lg}) {
      font-size: 32px;
    }
    @media (${device.xl}) {
      font-size: 40px;
    }
  } */

  & > h1 {
    font-size: 30px;
    line-height: 19px;
    @media (${device.md}) {
      line-height: 26px;
      font-size: 40px;
    }
    @media (${device.lg}) {
      line-height: 52px;
      font-size: 70px;
    }
    @media (${device.xxxl}) {
      line-height: 90px;
      font-size: 100px;
    }
  }

  & > a {
    max-width: 285px;
    margin: auto;
  }
`;

export function Hero({ showBanner = true }) {
  const analyticsEvent = () => {
    googleAnalyticsEvent(EVENT_KEYS.PLAY_GAME, PLAY_GAME.RAINBOW_RUMBLE);
  };

  return (
    <ContainerWrap>
      <HeroWrap>
        <VideoWrap className="text-white px-3">
          <video
            controls={false}
            poster={poster}
            muted
            loop
            autoPlay
            playsInline
            preload="metadata"
          >
            <source src={vid} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
          {showBanner && <VideoBanner />}
          <HeadingWrap>
            {/* <Typography
              tag={"h1"}
              variant="text2xs"
              lg={{ variant: "displayMd" }}
              color="white"
              family={"display"}
            >
              Rainbow Rumble will return this Summer
            </Typography> */}
            <Typography
              tag={"h1"}
              variant="displaySm"
              lg={{ variant: "display2xl" }}
              color="white"
            >
              <Typography
                tag={"p"}
                variant="text2xs"
                lg={{ variant: "displayMd" }}
                color="white"
                family={"display"}
              >
                Crypto Unicorns Presents
              </Typography>
              Rainbow Rumble
            </Typography>
            <Button
              size="lg"
              as={Link}
              variant="secondary"
              block={false}
              target="_blank"
              rel="noopener"
              to={CRYPTO.RAINBOW_RUMBLE}
              onClick={analyticsEvent}
              className="d-none d-xl-block"
            >
              Play Now
            </Button>
            <Button
              size="sm"
              as={Link}
              variant="secondary"
              block={false}
              target="_blank"
              rel="noopener"
              to={CRYPTO.RAINBOW_RUMBLE}
              onClick={analyticsEvent}
              className="d-block d-xl-none"
            >
              Play Now
            </Button>
          </HeadingWrap>
        </VideoWrap>
      </HeroWrap>
    </ContainerWrap>
  );
}

const BannerPosition = styled.div`
  display: flex;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  left: -5px;
  bottom: -15px;
  height: 38px;
  width: 300px;

  @media (${device.sm}) {
    height: 50px;
    width: 394.5px;
    left: -5px;
    bottom: -15px;
  }
  @media (${device.md}) {
    left: -20px;
    bottom: -30px;
  }
  @media (${device.lg}) {
    left: -30px;
    bottom: -30px;
  }
  @media (${device.xl}) {
    height: 100px;
    width: 788px;
    left: -50px;
    bottom: -40px;
  }
  @media (min-width: 1600px) {
    left: -150px;
    bottom: -50px;
  }
`;
const BackgroundImage = styled(BannerPosition)`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) -63%,
    rgba(105, 51, 232, 0.8) 72.5%
  );

  mix-blend-mode: ${({ $isChrome }) => ($isChrome ? "overlay" : "")};
  opacity: ${({ $isChrome }) => ($isChrome ? 1 : 0.9)};
  backdrop-filter: blur(50px);
  box-shadow: 0px 0.375px 1.125px 0px rgba(255, 255, 255, 0.22) inset;
  filter: drop-shadow(0px -2.25px 9px rgba(117, 181, 183, 0.12));
  border-radius: 8px;
  width: 100%;
  @media (min-width: 380px) {
    border-radius: unset;
    clip-path: url(#banner-clip-xs);
  }

  @media (${device.sm}) {
    clip-path: url(#banner-clip-sm);
  }
  @media (${device.xl}) {
    clip-path: url(#banner-clip);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    mix-blend-mode: hard-light;
    opacity: 0.9;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  margin: 0 6px;
  @media (${device.sm}) {
    margin: 0 8px;
  }
  @media (${device.xl}) {
    margin: 0 16px;
  }
`;

const BannerWrap = styled(BannerPosition)`
  padding: 5px 8px;
  width: 100%;
  justify-content: center;
  z-index: 1;
  @media (min-width: 380px) {
    padding: 5px 25px 5px 8px;
    justify-content: space-between;
    width: unset;
  }
  @media (${device.sm}) {
    padding: 8px 25px 8px 12px;
  }
  @media (${device.xl}) {
    padding: 11px 55px 11px 21px;
  }
`;

const VideoBanner = () => {
  return (
    <>
      <ClipPathSVG />
      <BackgroundImage $isChrome={isChrome()} />
      <BannerWrap>
        <BannerSection
          title="Showdown mode"
          items={[
            {
              title: "14-20",
              subtitle: "players",
              src: PlayerIcon,
              alt: "PlayerIcon",
            },
            {
              title: "12-16",
              subtitle: "minutes",
              src: ClockIcon,
              alt: "clock",
            },
          ]}
        />
        <Divider />
        <BannerSection
          title="Rush mode"
          items={[
            {
              title: "3-5",
              subtitle: "players",
              src: PlayerIcon,
              alt: "unicorn",
            },
            {
              title: "3-5",
              subtitle: "minutes",
              src: ClockIcon,
              alt: "clock",
            },
          ]}
        />
        <Divider />
        <BannerSection
          title="Rewards"
          items={[
            {
              title: "30",
              span: "up to",
              subtitle: "CU Tokens",
              src: CUIcon,
              alt: "rbw",
            },
          ]}
        />
      </BannerWrap>
    </>
  );
};

const ClipPathSVG = () => {
  const hiddenSvgStyle = {
    position: "absolute",
    width: 0,
    height: 0,
    overflow: "hidden",
  };

  const pathStyle = {
    fill: "rgba(255, 255, 255, 0.80)",
    filter: "drop-shadow(0px -2.25px 9px rgba(117, 181, 183, 0.12))",
    mixBlendMode: "overlay",
    shapeRendering: "crispEdges",
  };

  return (
    <svg style={hiddenSvgStyle} aria-hidden="true">
      <defs>
        <clipPath id="banner-clip">
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H752.226C754.98 0 757.541 1.41683 759.004 3.75038L772.194 24.7881L786.086 45.6284C787.855 48.2822 787.879 51.7333 786.146 54.4112L759.017 96.3455C757.542 98.6241 755.014 100 752.3 100H8.00002C3.58174 100 0 96.4183 0 92V50V8Z"
            fill="white"
            fillOpacity="0.8"
            style={pathStyle}
          />
        </clipPath>
        <clipPath id="banner-clip-sm">
          <path d="M0 4C0 1.79086 1.79086 0 4 0H376.151C378.157 0 380.104 0.708547 381.169 1.87519L385.431 12.3925L392.209 22.8149C393.927 24.1411 393.943 25.867 392.239 27.2056L379.176 48.1728C378.271 49.4791 376.841 50 375.483 50H4.00001C1.79086 50 0 48.2091 0 46V25V4Z" />
        </clipPath>
        <clipPath id="banner-clip-xs">
          <path d="M0 3.04C0 1.361 1.361 0 3.04 0H285.418C287.412 0 288.885 0.538 289.522 1.425L293.273 9.42L298.933 17.339C299.585 18.347 299.594 19.679 298.955 20.676L288.586 36.611C288.026 37.597 287.086 38 286.014 38H3.04C1.361 38 0 36.718 0 34.96V19V3.04Z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const BannerSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (${device.sm}) {
    row-gap: 3px;
  }
`;

const GameDetailsWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 9px;
  @media (${device.sm}) {
    column-gap: 12px;
  }
  @media (${device.xl}) {
    column-gap: 24px;
  }
`;

const GameDetails = styled.div`
  display: flex;
  align-items: center;
  column-gap: 3px;
  @media (${device.sm}) {
    column-gap: 8px;
  }
`;

const BannerTitle = styled(Typography)`
  text-align: center;
  font-size: 11px;
  line-height: unset;

  @media (${device.sm}) {
    font-size: 12px;
  }
  @media (${device.xl}) {
    font-size: 31px;
  }
`;

const SpanWrap = styled(Typography)`
  font-size: 5px;
  line-height: unset;

  @media (${device.sm}) {
    font-size: 8px;
  }
  @media (${device.xl}) {
    font-size: 15px;
  }
`;

const BannerSubtitle = styled(Typography)`
  text-align: center;
  font-size: 5px;
  line-height: unset;

  @media (${device.sm}) {
    font-size: 7px;
  }
  @media (${device.xl}) {
    font-size: 15px;
  }
`;

const BannerIcon = styled.img`
  max-width: 15px;
  @media (${device.sm}) {
    max-width: 18px;
  }
  @media (${device.xl}) {
    max-width: 38px;
  }
`;

const BannerSection = ({ items, title }) => {
  return (
    <BannerSectionWrap>
      <BannerSubtitle tag={"h3"} color="#161F49" variant={"textXs"}>
        {title}
      </BannerSubtitle>
      <GameDetailsWrap>
        {items.map((item, i) => (
          <GameDetails key={`${item.subtitle}-${i}`}>
            <BannerIcon src={item.src} alt={item.alt} />
            <div>
              <BannerTitle tag={"h3"} color="white" variant={"textXs"}>
                {item?.span && (
                  <SpanWrap
                    tag="span"
                    variant="text2xs"
                    xl={{ variant: "textMd" }}
                    color="white"
                    family={"display"}
                  >
                    {item.span}
                  </SpanWrap>
                )}{" "}
                {item.title}
              </BannerTitle>
              <BannerSubtitle tag={"h3"} color="white" variant={"textXs"}>
                {item.subtitle}
              </BannerSubtitle>
            </div>
          </GameDetails>
        ))}
      </GameDetailsWrap>
    </BannerSectionWrap>
  );
};

function isChrome() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("chrome") && !userAgent.includes("edg");
}
