import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const Toggle = React.forwardRef(({ children, onClick, ...rest }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    {...rest}
  >
    {children}
  </div>
));

const Menu = React.forwardRef(
  (
    {
      children,
      style,
      "aria-labelledby": labeledBy,
      className,
      close, // this was causing a warning in the console
      show, // this was causing a warning in the console
      ...rest
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
        {...rest}
      >
        <ul className="list-unstyled">
          {children}
        </ul>
      </div>
    );
  }
);

export const BSDropdown = Dropdown;

export const BSDropdownToggle = (props) => <Dropdown.Toggle as={Toggle} {...props} />;
export const BSDropdownMenu = (props) => <Dropdown.Menu as={Menu} {...props} />;
export const BSDropdownItem = Dropdown.Item;
