import { useEffect, useState } from "react";
import styled from "styled-components";
import { RewardWrapper } from "./Reward";
import { Box } from "../../base/Box";
import { Rank } from "./Rank";
import Spinner from "../../base/Spinner";
import { device } from "../../../theme/mediaQuery";
import { Button } from "../../base/Button";
import { Typography } from "../../base/Typography";
import { GetFullLeaderboard } from "../../../hooks/GetLeaderboard";

export const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  border-radius: 16px;
  min-height: 600px;

  transform: ${({ $isOpen }) => ($isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease-in-out;

  @media (${device.lg}) {
    min-height: 1080px;
  }
`;

export const Table = styled.table`
  border-radius: inherit;
  border-collapse: collapse;
  overflow: hidden;

  width: 100%;
`;

export const TableHead = styled(Box)`
  color: ${({ theme }) => theme.palette.white};
`;

export const TableHeading = styled.th`
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  padding: 0 10px;

  @media (${device.lg}) {
    padding: 0 20px;
  }
`;

export const TableRow = styled(Box)`
  height: 50px;
  box-shadow: ${({ $rowShadow }) => $rowShadow};

  @media (${device.lg}) {
    height: 90px;
  }

  & > * {
    font-family: ${({ theme }) => theme.fonts.display};
    font-size: 12px;
    text-align: left;

    @media (${device.lg}) {
      font-size: 19px;
    }
  }
  // rank styling
  & > td:first-of-type {
    @media (${device.lg}) {
      width: 95px;
      & > * {
        text-align: center;
        margin: 0 auto;
      }
    }
  }
  // address styling
  & > td:nth-of-type(2) {
    @media (${device.lg}) {
      width: 500px;
    }
  }
`;

export const TableCell = styled.td`
  color: ${({ theme, $color }) =>
    $color ? $color : theme.palette.darkBlue300};
  padding: 0 10px;

  @media (${device.lg}) {
    padding: 0 20px;
  }
`;

export const PlaceholderWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 75px;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px;
  min-height: 672px;
  @media (${device.lg}) {
    min-height: 1152px;
  }
`;

export const SpinnerWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 75px;
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const LinkWrap = styled(Typography)`
  text-decoration: none;
  color: ${({ theme, $color }) => $color || theme.palette.darkBlue300};
  background: none;
  border: none;
  margin-top: 14px;

  &:hover {
    color: ${({ theme }) => theme.palette.yellow200};
  }

  &:disabled {
    color: ${({ theme }) => theme.palette.gray500};
  }

  & > svg {
    height: 22px;
    width: 22px;
  }
`;

const initRes = [
  { address: "...", rank: "1", score: 0, points_data: {} },
  { address: "...", rank: "2", score: 0, points_data: {} },
  { address: "...", rank: "3", score: 0, points_data: {} },
];

function Leaderboard({
  renderReward,
  renderReward2,
  rewardHeading = "Rewards",
  rewardHeading2 = "Rewards",
  headerBackgroundColor,
  headerPattern = "labyrinth",
  backgroundColor = "linear-gradient(143.22deg, #FFFFFF 7.24%, #C7C9C9 94.73%)",
  currentUserBackground = "linear-gradient(143.22deg, #F8F2DF 7.24%, #EBB817 94.73%)",
  color,
  buttonColor,
  currentUserColor,
  rowBoxShadow = "inset 0px 7px 7px rgba(254, 253, 254, 0.25), inset 0px 0px 13px rgba(255, 255, 255, 0.25)",
  patternSize = "50%",
  patternOpacity,
  leaderboard: initleaderboard,
  LEADERBOARD_ID,
  totalPointsHeader = "Total Points",
  spinnerColor = "darkBlue300",
  ...rest
}) {
  const [leaderboard, setLeaderboard] = useState(null);
  const data = GetFullLeaderboard({
    id: LEADERBOARD_ID,
    page: 0,
  });

  const count = data?.page?.count || 0;
  const page = data?.page?.current || 0;

  const handleNext = () => {
    data.getLeaderboardPage(page + 1);
  };
  const handlePrev = () => {
    data.getLeaderboardPage(page - 1);
  };

  // Initial values
  useEffect(() => {
    if (!leaderboard && initleaderboard) {
      setLeaderboard(initleaderboard);
    }
  }, [leaderboard, initleaderboard]);

  // Rehydrate the leaderboard
  useEffect(() => {
    if (data && !data?.isLoading) {
      setLeaderboard(data.data);
    }
  }, [data]);

  const LeaderboardRow = ({ res }) => {
    const rows = [];
    if (res.length === 0) res = initRes;
    for (let i = 0; i < res?.length; i++) {
      const isCurrentUser = res[i].current_user;
      // Use player nickname if available
      const player = res[i].points_data?.approved_nickname
        ? res[i].points_data?.approved_nickname
        : res[i].address;
      const addressText = isCurrentUser ? `${player} - (you)` : player;
      const bgColor = isCurrentUser ? currentUserBackground : backgroundColor;
      const textColor = isCurrentUser
        ? currentUserColor
          ? currentUserColor
          : color
        : color;
      if (res[i].address) {
        rows.push(
          <TableRow
            key={i}
            tag="tr"
            gradient={bgColor}
            variant="gradient"
            pattern="labyrinth"
            patternSize="50%"
            patternOpacity={0.02}
            patternTag="td"
            $rowShadow={rowBoxShadow}
          >
            <TableCell $color={textColor} className="text-center">
              <Rank size="sm" rank={res[i].rank} />
            </TableCell>
            <TableCell $color={textColor}>{addressText}</TableCell>
            <TableCell $color={textColor} className="text-center">
              {res[i].score?.toLocaleString("en") || 0}
            </TableCell>
            {renderReward && (
              <TableCell $color={textColor}>
                <RewardWrapper className="justify-content-center">
                  {renderReward(res[i])}
                </RewardWrapper>
              </TableCell>
            )}
            {renderReward2 && (
              <TableCell $color={textColor}>
                <RewardWrapper className="justify-content-center">
                  {renderReward2(res[i])}
                </RewardWrapper>
              </TableCell>
            )}
          </TableRow>
        );
      }
    }
    return rows;
  };

  if (!leaderboard) {
    return (
      <PlaceholderWrap>
        <Spinner color={spinnerColor} />
      </PlaceholderWrap>
    );
  }

  return (
    <>
      <TableWrap {...rest}>
        {data?.isLoading && (
          <SpinnerWrap>
            <Spinner color={spinnerColor} />
          </SpinnerWrap>
        )}
        {!data?.isLoading && (
          <Table responsive>
            <TableHead
              tag="thead"
              gradient={headerBackgroundColor}
              variant="gradient"
              pattern={headerPattern}
              patternSize={patternSize}
              patternOpacity={patternOpacity}
              patternTag="tr"
            >
              <TableRow tag="tr" $rowShadow={rowBoxShadow}>
                <TableHeading className="text-center">#</TableHeading>
                <TableHeading>Address</TableHeading>
                <TableHeading className="text-center">
                  {totalPointsHeader}
                </TableHeading>
                {renderReward && (
                  <TableHeading className="text-center">Rewards</TableHeading>
                )}
                {renderReward2 && (
                  <TableHeading className="text-center">
                    {rewardHeading2}
                  </TableHeading>
                )}
              </TableRow>
            </TableHead>
            <tbody>
              <LeaderboardRow res={leaderboard} />
            </tbody>
          </Table>
        )}
      </TableWrap>

      <div className="text-center pt-4">
        <div>
          <Button
            variant="secondary"
            onClick={handlePrev}
            disabled={page === 0 || page === null}
            className="me-4"
          >
            {"<"}
          </Button>
          <Button
            variant="secondary"
            onClick={handleNext}
            disabled={page === count}
          >
            {">"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default Leaderboard;
