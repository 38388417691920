import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography } from "../../base/Typography";
import { Icon } from "../../base/Icon";
import rbw from "../../../img/currencies/rbw.png";
import weth from "../../../img/currencies/weth-circle.png";
import { BSDropdown, BSDropdownItem, BSDropdownMenu, BSDropdownToggle } from "../../base/BSDropdown";
import { device } from "../../../theme/mediaQuery";

export const TOKEN_NAMES = {
  CU: 0,
  WETH: 1,
};

export const TOKENS = {
  [TOKEN_NAMES.CU]: {
    id: TOKEN_NAMES.CU,
    name: "CU",
    img: rbw,
  },
  [TOKEN_NAMES.WETH]: {
    id: TOKEN_NAMES.WETH,
    name: "ETH",
    img: weth,
  },
};

const ToggleWrap = styled(BSDropdownToggle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 130px;
  background: #f3f3ff;
  width: 160px;
  cursor: pointer;

  @media (${device.md}) {
    width: 195px;
  }

  & > * {
    font-weight: 500;
  }

  &:hover {
    filter: brightness(1.05);
  }

  &::after {
    display: none;
  }
`;

const IconWrap = styled.img`
  width: 30px;
  height: 30px;
`;

const DropDownMenuWrap = styled(BSDropdownMenu)`
  background: #f3f3ff !important;
  border: 2px solid #aa91e6;
  padding: 5px 0;
  @media (${device.md}) {
    min-width: 195px;
  }
`;

const DropDownItemWrap = styled(BSDropdownItem)`
  color: ${({ theme }) => theme.palette.black} !important;
  font-family: ${({ theme }) => theme.fonts.body} !important;
  font-weight: 500;

  &:hover {
    background: #e0e0fc !important;
  }
`;

const TokenSelect = ({ tokens = [], token, name, onChange }) => {
  const [selectedToken, setSelectedToken] = useState(TOKENS[token]);

  const handleSelect = (token) => {
    setSelectedToken(TOKENS[token.id]);
    onChange(token);
  };

  useEffect(() => {
    setSelectedToken(TOKENS[token]);
  }, [token]);

  return (
    <BSDropdown>
      <ToggleWrap>
        <IconWrap src={selectedToken.img} alt={`${selectedToken.name} logo`} />
        <Typography variant="textXl" color="black">
          {selectedToken.name}
        </Typography>
        <Icon name="arrow-down" color="#5338c2" />
      </ToggleWrap>
      <DropDownMenuWrap>
        {tokens.map((tokenKey) => {
          const token = TOKENS[tokenKey];
          return (
            <DropDownItemWrap
              key={`${name}-${token.id}-${token.name}`}
              onClick={() => handleSelect(token)}
            >
              <IconWrap src={token.img} alt={`${token.name} logo`} />{" "}
              {token.name}
            </DropDownItemWrap>
          );
        })}
      </DropDownMenuWrap>
    </BSDropdown>
  );
};

export default TokenSelect;
