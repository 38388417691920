import { useEffect, useState } from "react";

export function useIsFullscreen() {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    // we need to check if video is fullscreen
    const handleFullscreenChange = () => {
      // check if fullscreen is enabled on safari
      if (document.webkitFullscreenElement) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(!!document.fullscreenElement);
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
    };
  }, []);

  return { isFullscreen };
}