import { StakingRewardsSection } from "./StakingRewardsSection";
import { useGetEscrowedRewards } from "../../../query/vault";
import { StakingRewardsPlaceholder } from "./StakingRewardsPlaceholder";
import { StakingRewardsTable } from "./StakingRewardsTable";

export function StakingRewardsEscrowed({ setStakeInitialTab }) {
  // we need to get deposits array to retrieve the id
  // for the withdraw mutation

  const { rewards = [], deposits, isLoading } = useGetEscrowedRewards();
  const isEmpty = rewards.length === 0;

  const sortedRewards = rewards.sort((a, b) => {
    return parseInt(a.end) - parseInt(b.end);
  });

  return (
    <StakingRewardsSection
      title="escrowed rewards"
      isLoading={isLoading}
      bodyFill={true}>
      {!isEmpty && (
        <StakingRewardsTable items={sortedRewards} deposits={deposits} />
      )}
      {isEmpty && (
        <StakingRewardsPlaceholder
          title="Once a claim has been initiated, it will appear in this section until the 7-day escrow period has concluded and the reward is ready to be claimed. "
          ctaText="Stake"
          setStakeInitialTab={setStakeInitialTab}
        />
      )}
    </StakingRewardsSection>
  );
}
