import React, { useRef } from "react";
import { useCloseOnOutsideClick } from "../../hooks/UseCloseOnOutsideClick";
import styled from "styled-components";
import { device } from "../../theme/mediaQuery";

export const DropDown = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const DropDownContent = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 0;
  padding: 20px 24px;
  z-index: 50;
  background: ${({ theme }) => theme.palette.white};
  opacity: ${({ $active }) => $active ? "1" : "0"};
  transform: ${({ $active }) => $active ? "translateY(-100%)" : "translateY(-90%)"};
  visibility: ${({ $active }) => $active ? "visible" : "hidden"};
  transition: all 0.3s ease-in-out;
  right: 0;
  min-width: 230px;
  border-radius: 8px;

  @media (${device.lg}) {
    top: 100%;
    transform: ${({ $active }) =>
            $active ? "translateY(0)" : "translateY(-10px)"};
  }
`;

const DropDownItemWrap = styled.li`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.gray300};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 18px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.palette.slate300};
  }
`;

const DropDownItemAppend = styled.span`
  margin-left: auto;
  font-family: inherit;
  font-size: inherit;
  color: ${({ theme }) => theme.palette.gray300};
`;

export function DropDownItem({ prepend, append, children, ...rest }) {
  return (
    <DropDownItemWrap {...rest}>
      {prepend && prepend}
      {children}
      {append && <DropDownItemAppend>{append}</DropDownItemAppend>}
    </DropDownItemWrap>
  );
}

export function DropDownMenu({ children, show, onClose, ...rest }) {
  const dropDownRef = useRef(null);

  useCloseOnOutsideClick(
    dropDownRef,
    () => {
      if (onClose) {
        onClose();
      }
    },
    { includeParent: true }
  );

  return (
    <DropDownContent ref={dropDownRef} $active={show} {...rest}>
      {children}
    </DropDownContent>
  );
}
