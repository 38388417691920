import styled, { keyframes } from "styled-components";

const StrokeSpinnerAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Wrap = styled.div`
  color: black;
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;

  & div {
    transform-origin: 12px 12px;
    animation: ${StrokeSpinnerAnimation} 0.8s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px; /* Adjusted position */
      left: 11px; /* Adjusted position */
      width: 2px; /* Adjusted width */
      height: 5px; /* Adjusted height */
      border-radius: 20px;
      background: #fff;
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.8s;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -0.7s;
    }

    &:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -0.6s;
    }

    &:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -0.5s;
    }

    &:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -0.4s;
    }

    &:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -0.3s;
    }

    &:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }

    &:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: -0.1s;
    }

  }
`;

export function StrokeSpinner() {
  return (
    <Wrap>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />

    </Wrap>
  );
}