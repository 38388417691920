import { isTestnet } from "../components/Network";

export const FIRE_MINION_POOL_ID = 13;
export const SLIME_MINION_POOL_ID = 14;
export const VOLT_MINION_POOL_ID = 15;
export const SOUL_MINION_POOL_ID = 16;
export const NEBULA_MINION_POOL_ID = 17;


// tier 2 minions
export const FIRE_MINION_TIER2_POOL_ID = 20;
export const SLIME_MINION_TIER2_POOL_ID = 21;
export const VOLT_MINION_TIER2_POOL_ID = 22;
export const SOUL_MINION_TIER2_POOL_ID = 23;
export const NEBULA_MINION_TIER2_POOL_ID = 24;

// tier 3 minions
export const FIRE_MINION_TIER3_POOL_ID = 25;
export const SLIME_MINION_TIER3_POOL_ID = 26;
export const VOLT_MINION_TIER3_POOL_ID = 27;
export const SOUL_MINION_TIER3_POOL_ID = 28;
export const NEBULA_MINION_TIER3_POOL_ID = 29;

export const MINION_ITEM_POOL_IDS = [
  FIRE_MINION_POOL_ID,
  SLIME_MINION_POOL_ID,
  VOLT_MINION_POOL_ID,
  SOUL_MINION_POOL_ID,
  NEBULA_MINION_POOL_ID,
  FIRE_MINION_TIER2_POOL_ID,
  SLIME_MINION_TIER2_POOL_ID,
  VOLT_MINION_TIER2_POOL_ID,
  SOUL_MINION_TIER2_POOL_ID,
  NEBULA_MINION_TIER2_POOL_ID,
  FIRE_MINION_TIER3_POOL_ID,
  SLIME_MINION_TIER3_POOL_ID,
  VOLT_MINION_TIER3_POOL_ID,
  SOUL_MINION_TIER3_POOL_ID,
  NEBULA_MINION_TIER3_POOL_ID
];

export const FIRE_HUSK_POOL_ID = 2;
export const SLIME_HUSK_POOL_ID = 3;
export const VOLT_HUSK_POOL_ID = 4;
export const SOUL_HUSK_POOL_ID = 5;
export const NEBULA_HUSK_POOL_ID = 6;

export const HUSKS_ITEM_POOL_IDS = [
  FIRE_HUSK_POOL_ID,
  SLIME_HUSK_POOL_ID,
  VOLT_HUSK_POOL_ID,
  SOUL_HUSK_POOL_ID,
  NEBULA_HUSK_POOL_ID
];

export const GENERIC_MATERIAL_POOL_ID = 12;
export const HATCHERY_KEY_POOL_ID = 1;
export const RIFT_CRYSTAL_POOL_ID = 18;
export const UNICORN_SOUL_POOL_ID = 19;

export const FIRE_LOOTBOX_POOL_ID = isTestnet ? 37 : 75;
export const SLIME_LOOTBOX_POOL_ID = isTestnet ? 38 : 77;
export const VOLT_LOOTBOX_POOL_ID = isTestnet ? 39 : 79;
export const SOUL_LOOTBOX_POOL_ID = isTestnet ? 40 : 78;
export const NEBULA_LOOTBOX_POOL_ID = isTestnet ? 41 : 76;

export const LOOTBOX_ITEM_POOL_IDS = [
  FIRE_LOOTBOX_POOL_ID,
  SLIME_LOOTBOX_POOL_ID,
  VOLT_LOOTBOX_POOL_ID,
  SOUL_LOOTBOX_POOL_ID,
  NEBULA_LOOTBOX_POOL_ID
];

// Mapping of pool ids to class names
export const LOOTBOX_CLASS_MAPPING = {
  [FIRE_LOOTBOX_POOL_ID]: "Fire",
  [SLIME_LOOTBOX_POOL_ID]: "Slime",
  [VOLT_LOOTBOX_POOL_ID]: "Volt",
  [SOUL_LOOTBOX_POOL_ID]: "Soul",
  [NEBULA_LOOTBOX_POOL_ID]: "Nebula"
};

export const FIRE_MATERIAL_POOL_ID = 7;
export const SLIME_MATERIAL_POOL_ID = 8;
export const VOLT_MATERIAL_POOL_ID = 9;
export const SOUL_MATERIAL_POOL_ID = 10;
export const NEBULA_MATERIAL_POOL_ID = 11;

export const MATERIAL_ITEM_POOL_IDS = [
  FIRE_MATERIAL_POOL_ID,
  SLIME_MATERIAL_POOL_ID,
  VOLT_MATERIAL_POOL_ID,
  SOUL_MATERIAL_POOL_ID,
  NEBULA_MATERIAL_POOL_ID
];

export const SC_EGG_1_POOL_ID = isTestnet ? 3 : 1;
export const SC_EGG_2_POOL_ID = isTestnet ? 4 : 2;
export const SC_EGG_3_POOL_ID = isTestnet ? 5 : 3;

export const SC_EGG_ITEM_POOL_IDS = [
  SC_EGG_1_POOL_ID,
  SC_EGG_2_POOL_ID,
  SC_EGG_3_POOL_ID
];
