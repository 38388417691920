import Countdown from "react-countdown";
import styled from "styled-components";
import { useAtomValue } from "jotai";
import { StakingTokenTypes, ShadowcornTokenTypes } from "../staking/constants";
import { Tooltip } from "../base/Tooltip";
import { PlayerCard } from "./PlayerCard";
import { Typography } from "../base/Typography";
import { walletConnectedAtom } from "../../store";
import { device } from "../../theme/mediaQuery";
import { FormattedAmount } from "../common/FormattedAmount";
import rbw from "../../img/currencies/rbw.png";
import culp from "../../img/currencies/culp.png";
import grail from "../../img/currencies/grail.png";
import arb from "../../img/currencies/arbitrum.png";
import unim from "../../img/currencies/unim.png";
import xai from "../../img/currencies/xai-white.png";
import dark from "../../img/leaderboard/dark_marks.png";

const tokenTags = {
  [StakingTokenTypes.cu]: { label: "CU", image: rbw },
  [StakingTokenTypes.culp]: { label: "CULP", image: culp },
  [StakingTokenTypes.grail]: { label: "GRAIL", image: grail },
  [StakingTokenTypes.arb]: { label: "ARB", image: arb },
  [StakingTokenTypes.xai]: { label: "XAI", image: xai },
  [StakingTokenTypes.rbw]: { label: "RBW", image: rbw },
  [StakingTokenTypes.unim]: { label: "UNIM", image: unim },
  [ShadowcornTokenTypes.dark]: { label: "Dark Marks", image: dark },
};

const PlayerCardWrap = styled(PlayerCard)`
  height: 100%;
  padding: 20px;
  gap: 16px;

  display: flex;
  flex-direction: column;
  @media (${device.md}) {
    gap: 30px;
  }

  & button,
  a {
    padding: 0;
    margin-left: auto;

    &:last-of-type {
      margin-left: ${({ $hasButtons }) => ($hasButtons ? "unset" : "auto")};
    }

    & div {
      font-size: 16px;
      @media (${device.md}) {
        font-size: 25px;
      }
    }
  }
`;

const TokenTagWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 12px;
  background: #f3f3ff;
`;

const TokenImage = styled.img`
  width: 24px;
  height: 24px;
`;

const TokenAmount = styled.span`
  color: #343434;
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

export const TokenTag = ({ type, amount }) => {
  const token = tokenTags[type] ? tokenTags[type] : null;
  if (!token) {
    return null;
  }
  return (
    <TokenTagWrap>
      <Tooltip overlay={token.label}>
        <TokenImage src={token.image} alt={token.label} />
      </Tooltip>
      <TokenAmount>
        {amount === 0 ? 0 : <FormattedAmount wei={amount} />}
      </TokenAmount>
    </TokenTagWrap>
  );
};

const TokensContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  flex-grow: 1;
`;

const TitleWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 12px;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: 500px) {
    align-items: center;
    flex-direction: row;
  }
`;
const TokensRow = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const Separator = styled.div`
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 100%;
`;

const RowWrap = styled.div`
  display: flex;
  gap: 16px;
  align-items: stretch;
  flex-direction: column-reverse;
  justify-content: space-between;
  @media (min-width: 500px) {
    align-items: center;
    flex-direction: row;
  }
  @media (${device.xxl}) {
    gap: 20px;
  }
`;

export function TokenCard({
  title,
  subtitle,
  walletNotConnectedButton,
  secondaryButton,
  primaryButton,
  tokens,
  tooltip,
  distributionDate,
  distributionTag = null,
  isLoading,
}) {
  const walletConnected = useAtomValue(walletConnectedAtom);
  const claimRewards = (
    <>
      <Typography
        tag="h4"
        variant="textLg"
        lg={{ variant: "displayXs" }}
        color="#3334B9">
        Claimable rewards:
      </Typography>
      {secondaryButton && secondaryButton}
    </>
  );

  const possibleRewards = (
    <>
      <Typography
        tag="h4"
        variant="textLg"
        lg={{ variant: "displayXs" }}
        color="#3334B9">
        Possible Rewards:
      </Typography>
      {walletNotConnectedButton && walletNotConnectedButton}
    </>
  );

  return (
    <PlayerCardWrap
      title={title}
      subtitle={subtitle}
      isLoading={isLoading}
      tooltip={tooltip}
      $hasButtons={walletConnected}>
      <TokensContainer>
        <TitleWrap>
          {!walletConnected && possibleRewards}
          {walletConnected && claimRewards}
        </TitleWrap>
        <Separator />
        <TokensRow>
          {tokens &&
            tokens.map((token, index) => (
              <TokenTag key={index} type={token.token} amount={token.amount} />
            ))}
        </TokensRow>
      </TokensContainer>
      {walletConnected && (
        <RowWrap>
          {primaryButton && primaryButton}
          {distributionDate && (
            <CountdownTimer distributionDate={distributionDate} />
          )}
          {distributionTag && (
            <DistributionTag distributionTag={distributionTag} />
          )}
        </RowWrap>
      )}
    </PlayerCardWrap>
  );
}

const TimerWrap = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background: #e2e2ff;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(77, 102, 226, 0.25);
  justify-content: center;

  @media (min-width: 500px) {
    max-width: 220px;
  }

  @media (${device.xxl}) {
    padding: 10px 16px;
    max-width: 250px;
  }

  .timer-unit {
    display: inline-block;
    text-align: center;
    font-size: inherit;
    font-family: inherit;
    width: 16px; // to keep it from changing alignment

    @media (${device.sm}) {
      width: 18px;
    }
    @media (${device.xxl}) {
      width: 22px;
    }
  }

  .timer-unit:not(:first-of-type) {
    margin-left: 1px;
  }
`;

const CountdownTimer = ({ distributionDate }) => {
  const date =
    typeof distributionDate === "number"
      ? new Date(distributionDate * 1000)
      : new Date(distributionDate);

  return <Countdown date={date} renderer={countdownRenderer} />;
};

const DistributionTag = ({ distributionTag }) => {
  return (
    <TimerWrap>
      <Typography
        tag="span"
        variant="textXs"
        color="#3334B9"
        family="display"
        sm={{
          variant: "textSm",
        }}
        xxl={{
          variant: "textMd",
        }}>
        {distributionTag}
      </Typography>
    </TimerWrap>
  );
};

export const countdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}) => {
  const timer = (
    <TimerWrap>
      <Typography
        tag="span"
        variant="text3xs"
        color="#3334B9"
        weight={400}
        family="body"
        className="pe-1 text-center"
        sm={{
          variant: "text2xs",
        }}
        xxl={{
          variant: "textXs",
        }}>
        Rewards in:
      </Typography>
      <Typography
        tag="span"
        variant="textSm"
        color="#3334B9"
        family="display"
        sm={{
          variant: "textMd",
        }}
        xxl={{
          variant: "textXl",
        }}>
        {/* {days > 0 && (
          <>
            <span className="timer-unit">{days}</span>D
          </>
        )}{" "} */}
        <span className="timer-unit">{hours}</span>H{" "}
        <span className="timer-unit">{minutes}</span>M{" "}
        <span className="timer-unit">{seconds}</span>S
      </Typography>
    </TimerWrap>
  );

  return <>{completed ? "This claim has finished!" : timer}</>;
};
