import styled from "styled-components";
import { Icon } from "../../base/Icon";
import { Video } from "../../base/Video";
import { useRef, useState } from "react";
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse";
import { Box } from "../../base/Box";
import { Typography } from "../../base/Typography";
import { device } from "../../../theme/mediaQuery";
import { closeFullscreen, openFullscreen } from "../../../utils/fullscreen";
import { useIsFullscreen } from "../../../helpers/useIsFullscreen";
import { useSetAtom } from "jotai/index";
import { scrollAtom } from "../../../store/scroll";

const CarousePlayButton = styled.div`
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ $small }) => ($small ? "100px" : "20%")};
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(75px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
  transition: opacity 0.3s ease-in-out;
`;

const CarouseFullscreenButton = styled.div`
  position: absolute;
  z-index: 4;
  bottom: 20px;
  right: 20px;
  width: 30px;
  aspect-ratio: 1/1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;

  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
  visibility: ${({ $hide }) => ($hide ? "hidden" : "visible")};
  transition: opacity 0.3s ease-in-out;

  @media (${device.md}) {
    bottom: 20px;
    right: 20px;
  }
`;

const CarouselItemWrap = styled.div`
  width: 100%;
  position: relative;
`;

const CarouselMainContent = styled(MouseParallaxChild)`
  border-radius: 16px;
  width: 100%;
  overflow: visible;
  position: relative;
  z-index: 3;

  & img {
    width: 100%;
    margin: auto;
  }
`;

const CarouseSecondaryImage = styled(MouseParallaxChild)`
  position: absolute;
  width: 60%;
  height: 60%;
  z-index: 2;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  pointer-events: none;
  background-image: url(${({ $image }) => $image});
`;

const CarouseSecondaryImagePortrait = styled(CarouseSecondaryImage)`
  width: 130%;
  height: 130%;
`;

const CarouselTopImage = styled(CarouseSecondaryImage)`
  top: -10%;
  right: 5%;
`;

const CarouselBottomImage = styled(CarouseSecondaryImage)`
  bottom: -20%;
  left: 20%;
`;

const CarouselTopImagePortrait = styled(CarouseSecondaryImagePortrait)`
  top: -20%;
  right: 0;
`;

const CarouselBottomImagePortrait = styled(CarouseSecondaryImagePortrait)`
  top: -24%;
  left: 0%;
`;

const CarouseForegroundImage = styled(MouseParallaxChild)`
  position: absolute;
  width: 120%;
  height: 120%;
  left: -10%;
  bottom: -15%;
  z-index: 3;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  pointer-events: none;
  background-image: url(${({ $image }) => $image});
  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
  transition: opacity 0.3s ease-in-out;

  @media (${device.xxl}) {
    width: 150%;
    height: 150%;
    left: -20%;
    bottom: -35%;
  }
`;
const CarouseForegroundImagePortrait = styled(CarouseForegroundImage)`
  left: -47%;
  bottom: -27%;
  z-index: 2;
  width: 100%;
  height: 100%;
  @media (${device.sm}) {
    left: -51%;
  }
`;

const MainImage = styled.div`
  width: 100%;
  height: 100%;
  min-height: 250px;
  border-radius: 16px;
  background-image: url(${({ $image }) => $image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  // filter: blur(2px);

  @media (${device.md}) {
    min-height: 300px;
  }

  @media (${device.lg}) {
    min-height: 400px;
  }

  @media (${device.xl}) {
    min-height: 300px;
  }
`;

const VideoTransparentBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
`;

const TitleWrap = styled(Box)`
  position: absolute;
  z-index: 4;
  bottom: -5px;
  right: -10px;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 50px;
  border-radius: 32px 40px 264px 32px;
  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
  visibility: ${({ $hide }) => ($hide ? "hidden" : "visible")};
  transition: opacity 0.3s ease-in-out;

  @media (${device.xl}) {
    padding-top: 13px;
    padding-bottom: 15px;
    padding-left: 27px;
    padding-right: 100px;
    right: -7%;
    bottom: -3px;
  }
`;

const MainContentWrap = styled.div`
  background: ${({ $fullscreen }) =>
    !$fullscreen ? "transparent" : "rgba(0, 0, 0, 0.5)"};

  & video {
    ${({ $fullscreen }) => $fullscreen && "object-fit: contain !important;"}
  }
`;

const Title = styled(Typography)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.white};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.slate100};
  }
`;

export function HomeVideoCarouselItem({
  title,
  activePage,
  topImage,
  bottomImage,
  video,
  foregroundImage,
  image,
  onPlayVideo,
  portrait,
  disableParallax, 
}) {
  const [videoRef, setVideoRef] = useState(null);
  const [playing, setPlaying] = useState(false);
  const wrapRef = useRef(null);
  const { isFullscreen } = useIsFullscreen();

  const handleToggleVideo = () => {
    if (playing) {
      videoRef?.current?.pause();
    } else {
      videoRef?.current?.play();
      onPlayVideo(videoRef);
    }
  };

  const handlePauseVideo = () => {
    if (playing) {
      videoRef?.current?.pause();
    }
  };

  const handleToggleFullscreen = (event) => {
    //prevent bubbling to parent
    event.stopPropagation();

    // if fullscreen is enabled
    if (isFullscreen) {
      closeFullscreen();
    } else {
      openFullscreen(wrapRef.current);
    }
  };

  const hideFullscreenButton = !playing && !isFullscreen;
  const hideFullscreenPlayButton = playing || !isFullscreen;
  const hidePlayButton = playing;
  const setScroll = useSetAtom(scrollAtom);

  const renderParallaxChild = (Component, props) => {
    return disableParallax ? (
      <Component {...props} factorX={0} factorY={0} />
    ) : (
      <Component {...props} />
    );
  };

  if (portrait) {
    return (
      <CarouselItemWrap onClick={handlePauseVideo}>
        <MouseParallaxContainer
          containerStyle={{ overflow: "visible" }}
          globalFactorX={disableParallax ? 0 : 0.05}
          globalFactorY={disableParallax ? 0 : 0.05}
        >
          {renderParallaxChild(CarouselTopImagePortrait, { $image: topImage })}
          {renderParallaxChild(CarouselMainContent, {
            factorX: disableParallax ? 0 : 0.03,
            factorY: disableParallax ? 0 : 0.03,
            children: (
              <MainContentWrap ref={wrapRef} $fullscreen={isFullscreen}>
                {image && <MainImage $image={image} />}
                {video && (
                  <>
                    <Video
                      src={video}
                      onReady={setVideoRef}
                      onPlay={() => setPlaying(true)}
                      onPause={() => setPlaying(false)}
                      style={{
                        border: "2px solid black",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 0px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    />
                    <CarouseFullscreenButton
                      onClick={handleToggleFullscreen}
                      $hide={hideFullscreenButton}
                    >
                      <Icon
                        size="70%"
                        name={isFullscreen ? "fullscreen-exit" : "fullscreen"}
                      />
                    </CarouseFullscreenButton>
                    <CarousePlayButton
                      $small={true}
                      $hide={hideFullscreenPlayButton}
                      onClick={handleToggleVideo}
                    >
                      <Icon size="40%" name="play" />
                    </CarousePlayButton>
                    <VideoTransparentBox />
                  </>
                )}
              </MainContentWrap>
            ),
          })}
          {renderParallaxChild(CarouselBottomImagePortrait, {
            $image: bottomImage,
          })}
          {renderParallaxChild(CarouseForegroundImagePortrait, {
            $image: foregroundImage,
          })}
          {video && (
            <CarousePlayButton
              $hide={hidePlayButton}
              onClick={handleToggleVideo}
            >
              <Icon size="40%" name="play" />
            </CarousePlayButton>
          )}
        </MouseParallaxContainer>
      </CarouselItemWrap>
    );
  }

  return (
    <CarouselItemWrap onClick={handlePauseVideo}>
      <MouseParallaxContainer
        containerStyle={{ overflow: "visible" }}
        globalFactorX={disableParallax ? 0 : 0.05}
        globalFactorY={disableParallax ? 0 : 0.05}
      >
        {renderParallaxChild(CarouselTopImage, { $image: topImage })}
        {renderParallaxChild(CarouselMainContent, {
          factorX: disableParallax ? 0 : 0.03,
          factorY: disableParallax ? 0 : 0.03,
          children: (
            <MainContentWrap ref={wrapRef} $fullscreen={isFullscreen}>
              {image && <MainImage $image={image} />}
              {video && (
                <>
                  <Video
                    src={video}
                    onReady={setVideoRef}
                    onPlay={() => setPlaying(true)}
                    onPause={() => setPlaying(false)}
                  />
                  <CarouseFullscreenButton
                    onClick={handleToggleFullscreen}
                    $hide={hideFullscreenButton}
                  >
                    <Icon
                      size="70%"
                      name={isFullscreen ? "fullscreen-exit" : "fullscreen"}
                    />
                  </CarouseFullscreenButton>
                  <CarousePlayButton
                    $small={true}
                    $hide={hideFullscreenPlayButton}
                    onClick={handleToggleVideo}
                  >
                    <Icon size="40%" name="play" />
                  </CarousePlayButton>
                  <VideoTransparentBox />
                </>
              )}
            </MainContentWrap>
          ),
        })}
        {renderParallaxChild(CarouselBottomImage, { $image: bottomImage })}
        {renderParallaxChild(CarouseForegroundImage, {
          $hide: playing,
          $image: foregroundImage,
        })}
        {title && (
          <TitleWrap
            pattern="labyrinth"
            variant="gradient"
            gradient="linear-gradient(143.22deg, #F46652 7.24%, #FD6041 94.73%)"
            $hide={playing}
          >
            <Title
              variant="textMd"
              color="white"
              family="display"
              tag="a"
              onClick={(e) => {
                e.preventDefault();
                setScroll({ scroll: true, activePage: activePage });
              }}
              md={{
                variant: "textXl",
              }}
              xl={{
                variant: "displayXs",
              }}
              xxl={{
                variant: "displayMd",
              }}
            >
              {title}
            </Title>
          </TitleWrap>
        )}
        {video && (
          <CarousePlayButton $hide={hidePlayButton} onClick={handleToggleVideo}>
            <Icon size="40%" name="play" />
          </CarousePlayButton>
        )}
      </MouseParallaxContainer>
    </CarouselItemWrap>
  );
}
