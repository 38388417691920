import { NavItem } from "../header/NavItem";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BridgePendingTransactionsModal } from "./BridgePendingTransactionsModal";
import { device } from "../../theme/mediaQuery";
import { StrokeSpinner } from "../base/StrokeSpinner";
import { useAtomValue, useSetAtom } from "jotai";
import { persistedPendingTransactionsAtom } from "../../store/pending";

const Wrap = styled.div`
  position: relative;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.3s;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ButtonWrap = styled(NavItem)`
  position: relative;
  z-index: 101;
  display: none;
  @media (${device.xxl}) {
    display: inline-flex;
  }
`;

const SmallButtonWrap = styled(NavItem)`
  position: relative;
  z-index: 101;
  @media (${device.xxl}) {
    display: none;
  }
`;

export function BridgePendingTransactionButton() {
  const [active, setActive] = useState(false);
  const { showModal, transactions } = useAtomValue(
    persistedPendingTransactionsAtom
  );
  const setPendingTransactions = useSetAtom(persistedPendingTransactionsAtom);
  const pendingTransactions = useAtomValue(persistedPendingTransactionsAtom);
  const isPending = pendingTransactions?.transactions?.length > 0;

  useEffect(() => {
    if (showModal) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [showModal]);

  const closeModal = () => {
    setActive(false);
    setPendingTransactions((prev) => ({ ...prev, showModal: false }));
  };

  if (transactions.length === 0) {
    return;
  }
  return (
    <Wrap>
      {active && <Backdrop onClick={() => setActive(false)} />}
      <ButtonWrap
        title="Pending Transactions"
        onClick={() => setActive(!active)}
        active={active}
        prependComponent={isPending && <StrokeSpinner />}
      />
      <SmallButtonWrap
        onClick={() => setActive(!active)}
        active={active}
        prependComponent={isPending && <StrokeSpinner />}
      />
      {active && (
        <BridgePendingTransactionsModal active={active} onClose={closeModal} />
      )}
    </Wrap>
  );
}
