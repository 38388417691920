import { useEffect, useRef } from "react";

const PLAYING_DEBOUNCE_TIME = 50;

function formatSrc(src) {
  // if browser is Safari, add one millisecond time to the src to show preview
  // we need this because WebKit does not preload media data on iOS to avoid using unnecessary bandwidth
  if (navigator.vendor.includes("Apple")) {
    return `${src}#t=0.001`;
  }

  return src;
}

export function Video({ src, onReady, onPlay, onPause, style }) {
  const videoRef = useRef(null);

  const isWaitingTimeout = useRef(null);
  const isPlayingTimeout = useRef(null);

  const formattedSrc = formatSrc(src);

  useEffect(() => {
    const isWaitingTimeoutRef = isWaitingTimeout.current;
    const isPlayingTimeoutRef = isPlayingTimeout.current;
    if (!videoRef.current) {
      return;
    }

    onReady(videoRef);

    const playHandler = () => {
      clearTimeout(isWaitingTimeoutRef);
      clearTimeout(isPlayingTimeoutRef);

      isPlayingTimeout.current = setTimeout(() => {
        onPlay();
      }, PLAYING_DEBOUNCE_TIME);
    };

    const pauseHandler = () => {
      clearTimeout(isWaitingTimeoutRef);
      clearTimeout(isPlayingTimeoutRef);

      isPlayingTimeout.current = setTimeout(() => {
        onPause();
      }, PLAYING_DEBOUNCE_TIME);
    };

    const errorHandler = (e) => {
      console.log("Error loading source: " + src);
    };

    const element = videoRef.current;

    element.addEventListener("play", playHandler);
    element.addEventListener("playing", playHandler);
    element.addEventListener("pause", pauseHandler);
    element.addEventListener("error", errorHandler);

    return () => {
      clearTimeout(isWaitingTimeoutRef);
      clearTimeout(isPlayingTimeoutRef);

      element.removeEventListener("play", playHandler);
      element.removeEventListener("playing", playHandler);
      element.removeEventListener("pause", pauseHandler);
      element.removeEventListener("error", errorHandler);
    };
  }, [videoRef, src, onReady, onPlay, onPause]);

  return (
    <video
      ref={videoRef}
      src={formattedSrc}
      loop
      preload="metadata"
      style={{
        borderRadius: "16px",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        ...style,
      }}
    >
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
}
