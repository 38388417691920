import React from "react";
import styled from "styled-components";
import { Box } from "../base/Box";
import { Typography } from "../base/Typography";
import { Container } from "../base/Container";
import { device } from "../../theme/mediaQuery";
import { Modal } from "../base/Modal";
import { CardHeaderVideo } from "../base/CardHeaderVideo";

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  margin: 0.75rem 0;
  padding: 5px 15px;

  @media (${device.lg}) {
    flex-direction: row;
    justify-content: space-between;
    row-gap: 0.75rem;
    margin: 0.75rem 0;
    padding: 5px 15px;
  }
`;

const ImgWrap = styled.img`
  border-radius: 16px;
  object-fit: cover;

  @media (${device.lg}) {
    max-width: 35%;
    max-height: fit-content;
  }
`;

const VideoWrap = styled.div`
  border-radius: 16px;
  object-fit: cover;

  @media (${device.lg}) {
    max-width: 32%;
    max-height: fit-content;
  }
`;

const StatsWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  text-align: center;
  height: 100%;
  min-height: 100px;
`;

export function ItemsModal({
  showModal,
  setShowModal,
  classIcon,
  item,
  isVideo = false,
}) {
  const rarity = (attributes) => {
    let itemRarity = attributes.find((trait) => trait.trait_type === "Rarity");
    if (itemRarity) {
      return itemRarity.value;
    } else {
      return "";
    }
  };

  const subtitleRarity =
    item?.attributes && rarity(item.attributes) ? rarity(item.attributes) : "";
  const subtitleCount = item?.count ? `Count: ${item?.count}` : "";
  const subtitleDivider = subtitleRarity && subtitleCount ? " - " : "";
  const subtitle = `${subtitleRarity}${subtitleDivider}${subtitleCount}`;

  return (
    <>
      {item && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          size="lg"
          title={item.name}
          subtitle={subtitle}
          classIcon={classIcon}
        >
          <StyledContainer>
            {isVideo ? (
              <VideoWrap>
                <CardHeaderVideo src={item.image} />
              </VideoWrap>
            ) : (
              <ImgWrap src={item.image} />
            )}
            <Box
              variant="flat"
              rounded="md"
              color="darkBlue"
              className="col ms-lg-4"
              pattern="sprinkles"
            >
              <StatsWrap>
                <Typography
                  tag={"p"}
                  variant={isVideo ? "textMd" : "textXl"}
                  family={"display"}
                  color="white"
                >
                  {item?.description ? item?.description : `${item.name} 🎉!`}
                </Typography>
              </StatsWrap>
            </Box>
          </StyledContainer>
        </Modal>
      )}
    </>
  );
}
