import React from "react";
import styled from "styled-components";
import { Button } from "../../base/Button";
import { SuccessModal } from "./SuccessModal";
import { device } from "../../../theme/mediaQuery";
import { Typography } from "../../base/Typography";
import { useAtom } from "jotai/index";
import { badgeSuccessModalAtom } from "../../../store/staking";
import { formatWalletAddress } from "../../Helpers";
import { StakingViewTransactionButton } from "../StakingViewTransactionButton";

const ButtonWrap = styled(Button)`
  z-index: 13;

  & div {
    white-space: nowrap;
    word-break: keep-all;
  }
`;

export function BadgeSuccessModal() {
  const [modal, setModal] = useAtom(badgeSuccessModalAtom);
  const { show, badge, token, hash, recipientAddress } = modal;

  const details = {
    address: recipientAddress,
    boost: badge?.boostFactor,
    pool: token,
    hash: hash,
  };

  const handleClose = () => {
    setModal({
      show: false,
      badge: null,
      token: null,
      hash: null,
      recipientAddress: null,
    });
  };

  return (
    <SuccessModal
      toggleModal={handleClose}
      showModal={show}
      title="Success!"
      subtitle="You have successfully delegated a badge!"
    >
      <Badge
        name={badge?.name}
        description={badge?.poolId}
        img={badge?.image}
      />
      <StakingDetails details={details} />
      <StakingViewTransactionButton
        size="md"
        hash={hash}
        variant="secondary"
      >
        View Transaction
      </StakingViewTransactionButton>
    </SuccessModal>
  );
}

const BadgeWrap = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-direction: column;

  @media (${device.md}) {
    flex-direction: row;
  }
`;
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > p:last-of-type {
    font-weight: 600;
  }
`;
const ImageWrap = styled.div`
  border-radius: 150px;
  padding: 10px;
  border: 6px solid #e7b610;
  background: #231852;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  @media (${device.md}) {
    padding: 15px;
    border: 6px solid #e7b610;
  }
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  @media (${device.md}) {
    width: 55px;
    height: 55px;
  }
`;

function Badge({ name, description, img }) {
  const nameArray = name.split(" - ");
  const title = nameArray[0];
  const tier = nameArray.length > 1 ? nameArray[1] : null;
  return (
    <BadgeWrap>
      <ImageWrap>
        <Image src={img} alt={`${img} logo`} />
      </ImageWrap>
      <TextWrap>
        <Typography as="h2" variant="textXl" md={{ variant: "displaySm" }}>
          {title}
        </Typography>
        <Typography
          as="p"
          variant="textMd"
          md={{ variant: "textLg" }}
          color="white"
        >
          {tier && tier}
          {tier && description && " - "}
          {description && `Pool ID: ${description}`}
        </Typography>
      </TextWrap>
    </BadgeWrap>
  );
}

const StakingDetailsWrap = styled.div`
  background: #6a00b2;
  color: ${({ theme }) => theme.palette.white};
  backdrop-filter: blur(6px);
  border-radius: 12px;
  padding: 12px;
  margin: 16px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 8px;
  z-index: 13;
  max-width: 536px;

  @media (${device.md}) {
    padding: 24px;
    margin: 24px 0;
  }
  @media (${device.md}) {
    padding: 30px;
  }
`;

const StakingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: normal;
  gap: 8px;

  & h4 {
    font-weight: normal;
  }

  & p {
    font-weight: 700;
  }
`;

const stakingDetails = [
  { title: "Delegated to", key: "address" },
  { title: "Boost", key: "boost" },
  { title: "Pool", key: "pool" },
];

const StakingDetails = ({ details }) => {
  return (
    <StakingDetailsWrap>
      {stakingDetails.map((item) => (
        <StakingRow key={item.key}>
          <Typography
            as="h4"
            variant="textMd"
            md={{ variant: "textLg" }}
            family="body"
          >
            {item.title}:
          </Typography>
          <Typography as="p" variant="textLg" md={{ variant: "displayXs" }}>
            {item.key === "address" || item.key === "hash"
              ? formatWalletAddress(details[item.key])
              : details[item.key]}
          </Typography>
        </StakingRow>
      ))}
    </StakingDetailsWrap>
  );
};
