import styled from "styled-components";
import { Button } from "../base/Button";
import { Banner } from "../base/Banner";
import { Typography } from "../base/Typography";
import culp from "../../img/currencies/culp.png";
import { device } from "../../theme/mediaQuery";
import { Link } from "react-router-dom";

const BannerWrap = styled(Banner)``;

const Tag = styled(Typography)`
  width: fit-content;
  border-radius: 40px;
  background-color: #f5b53d;
  padding: 2px 8px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  color: #795107;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
  font-size: 10px;
  letter-spacing: 0.85px;
  @media (${device.md}) {
    font-size: 20px;
    padding: 2px 10px;
  }
`;
const ButtonWrap = styled.div`
  padding: 0;

  & div {
    padding: 4px;
    font-size: 10px;
    white-space: nowrap;
    word-break: keep-all;
    @media (${device.md}) {
      font-size: 12px;
    }
    @media (${device.xl}) {
      font-size: 16px;
    }
  }
`;
const TitleWrap = styled.div`
  display: flex;
  gap: 6px;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  @media (${device.md}) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-grow: 1;
  line-height: normal;
  flex-direction: column-reverse;
  gap: 6px;
  @media (${device.md}) {
    gap: 10px;
    flex-direction: row;
  }
`;

const LogoImage = styled.img`
  height: 45px;
  width: 45px;

  @media (${device.md}) {
    height: 60px;
    width: 60px;
    position: absolute;
    top: -30px;
    left: -70px;
  }
  @media (${device.lg}) {
    height: 80px;
    width: 80px;
    left: -86px;
  }
`;

export function StakingBanner({ apr }) {
  const minAPR = apr.CULP?.totalMinApr.toFixed(0);
  const maxAPR = apr.CULP?.totalMaxApr.toFixed(0);

  return (
    <BannerWrap
      rounded="md"
      patternSize="cover"
      gradient="linear-gradient(90deg, #9B1CC7 -4.48%, #DA2AEA 14.25%, #D128E5 37.67%, #9A1BC6 99.59%);"
      pattern="dots"
      patternOpacity="1"
      borderColor="linear-gradient(180deg, #e276e9 0%, #af59db 100%) border-box">
      <LogoImage src={culp} alt="Logo" />
      <ContentWrap>
        <TitleWrap>
          <Typography
            tag="p"
            variant="textXs"
            family="display"
            textshadow="medium"
            md={{
              variant: "textSm",
            }}
            xl={{
              variant: "textMd",
            }}>
            Stake CULP now to earn rewards and participate on the leaderboard!
          </Typography>
          <ButtonWrap>
            <Button size="xs" as={Link} to="/staking/overview">
              Visit staking website
            </Button>
          </ButtonWrap>
        </TitleWrap>
        {/* <Tag tag="p" className="d-sm-block d-md-none" family="display">
          APR 6-23 %
        </Tag> */}
      </ContentWrap>
      {apr.CULP && (
        <Tag tag="p" className="d-none d-md-block" family="display">
          APR {minAPR} - {maxAPR} %
        </Tag>
      )}
    </BannerWrap>
  );
}
