export function getTimeLeftInHumanReadable(endTimestamp, withPostfix = true) {
  const now = new Date().getTime() / 1000;
  const timeLeft = endTimestamp - now;

  if (timeLeft < 0) {
    return "Expired";
  }

  const days = Math.floor(timeLeft / 86400);
  const hours = Math.floor((timeLeft % 86400) / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const postfix = withPostfix ? "left" : "";
  if (days > 0) {
    return `${days} days ${postfix}`;
  }

  if (hours > 0) {
    return `${hours} hours ${postfix}`;
  }

  if (minutes > 0) {
    return `${minutes} minutes ${postfix}`;
  }

  return "Expired";
}