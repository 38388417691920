import React from "react";
import styled from "styled-components";
import BootstrapModal from "react-bootstrap/Modal";
import { Box } from "../base/Box";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { Typography } from "../base/Typography";
import { Icon } from "../base/Icon";
import { Container } from "../base/Container";
import { ClassIcon } from "../common/ClassIcon";
import { device } from "../../theme/mediaQuery";
import { SectionCard } from "../common/SectionCard";

const BootstrapModalWrap = styled(BootstrapModal)`
  & .modal-dialog {
    & .modal-content {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
      border-radius: 17px;
      border: none;
      overflow: ${({ $hasHeader }) => ($hasHeader ? "hidden" : "visible")};

      & > div:first-of-type {
        border-radius: ${({ $hasHeader }) => ($hasHeader ? "0" : "17px")};
      }
    }
`;

const BootstrapModalCustomWrap = styled(BootstrapModal)`
  & .modal-dialog {
    max-width: 520px;

    & .modal-content {
      background: transparent;
      border: none;
      box-shadow: none;
    }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 25px;
  right: 10px;
  transform: translate(0, -50%);
  color: ${({ theme, $color }) =>
          theme.palette[$color] ? theme.palette[$color] : theme.palette.white};

  cursor: pointer;

  @media (${device.sm}) {
    top: 25px;
    right: 15px;
  }
`;

const ModalHeader = styled(Row)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  @media (${device.sm}) {
    flex-direction: row;
    text-align: left;
  }
`;

const StyledClassIcon = styled(ClassIcon)`
  width: 45px;
  height: 45px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  margin: 0.75rem 0;
  padding: 5px 15px;

  @media (${device.sm}) {
    flex-direction: column-reverse;
  }

  @media (${device.xl}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledContainerSecondary = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  margin-bottom: 32px;
  padding: 0 15px 5px;

  @media (${device.xl}) {
    justify-content: space-between;
  }
`;

const modalColors = {
  blue: {
    backgroundGradient: "linearBlue",
    patternOpacity: 0.05,
    patternGradient: "",
    headerGradient: "linearBlack",
    closeIcon: "white"
  },
  white: {
    backgroundGradient: "linearWhite",
    patternOpacity: 0.03,
    patternGradient:
      "linear-gradient(307.87deg, rgba(217, 217, 217, 0.09) -8.79%, #FBFBFB 101.21%);",
    headerGradient: "linearWhite",
    closeIcon: "darkBlue300"
  }
};

export function Modal({
  showModal,
  setShowModal,
  title,
  subtitle,
  classIcon,
  gradient = "blue",
  pattern = "sprinkles",
  size = "xl",
  variant = "primary",
  children
}) {
  const theme = modalColors[gradient] || modalColors.blue;

  if (variant === "custom") {
    return (
      <BootstrapModalCustomWrap
        show={showModal}
        onHide={setShowModal}
        size={size}
        centered
      >
        {children}
      </BootstrapModalCustomWrap>
    );
  }

  if (variant === "secondary") {
    return (
      <BootstrapModalWrap
        show={showModal}
        onHide={setShowModal}
        size={size}
        aria-labelledby="unicorn-modal"
        centered
        $hasHeader={title}
      >
        <SectionCard title={title} border={false}>
          <StyledContainerSecondary id="sec">
            {children}
          </StyledContainerSecondary>
        </SectionCard>
      </BootstrapModalWrap>
    );
  }
  return (
    <BootstrapModalWrap
      show={showModal}
      onHide={setShowModal}
      size={size}
      aria-labelledby="unicorn-modal"
      centered
      $hasHeader={title}
    >
      <Box
        variant="gradient"
        rounded="none"
        gradient={theme.backgroundGradient}
        pattern={pattern}
        patternSize="md"
        patternOpacity={theme.patternOpacity}
        patternGradient={theme?.patternGradient}
      >
        {title && (
          <Box
            variant="gradient"
            rounded="none"
            gradient={theme.headerGradient}
            pattern="sprinkles"
            patternSize="md"
            className="p-2"
          >
            <StyledIcon
              name="exit"
              onClick={() => setShowModal(false)}
              $color={theme.closeIcon}
            />
            <ModalHeader className="mx-1 pe-sm-4">
              <Col className="col-auto p-0">
                {classIcon && <StyledClassIcon name={classIcon} />}
              </Col>
              <Col>
                <Typography tag={"h3"} variant={"displaySm"} color="white">
                  {title}
                </Typography>
                <Typography
                  tag={"h3"}
                  variant={"textLg"}
                  color="white"
                  style={{ lineHeight: "21px" }}
                >
                  {subtitle && subtitle}
                </Typography>
              </Col>
            </ModalHeader>
          </Box>
        )}
        <StyledContainer>{children}</StyledContainer>
      </Box>
    </BootstrapModalWrap>
  );
}
