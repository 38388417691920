import styled from "styled-components";
import { PatternBackground } from "../base/Box";
import React, { useEffect } from "react";
import { TrapezoidBox } from "../common/TrapezoidBox";
import { Typography } from "../base/Typography";
import { device } from "../../theme/mediaQuery";
import { Portal } from "./Portal";
import { BridgeCloseIcon } from "../bridge/BridgeCloseIcon";

const CLOSE_TIMEOUT = 20000;

const Wrap = styled.div`
  position: fixed;
  top: 120px;
  left: 5%;
  right: 5%;

  border-radius: 12px;
  border: 2px solid ${({ $accent }) => $accent};
  z-index: 102;
  animation: slideIn 0.3s;
  background: ${({ $background }) => $background};
  padding: 0 28px;
  @keyframes slideIn {
    from {
      transform: translateY(-10px);
    }
    to {
      transform: translateY(0);
    }
  }

  @media (${device.sm}) {
    max-width: 500px;
    left: auto;
    right: 30px;
    top: 60px;
    min-width: 450px;
  }
`;

const TitleWrap = styled(TrapezoidBox)`
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 36px;
  text-shadow: 0px 2px 0px ${({ $shadow }) => $shadow};
`;

const BodyWrap = styled.div`
  padding-top: 8px;
  padding-bottom: 16px;
  gap: 8px;
`;

const CloseIcon = styled(BridgeCloseIcon)`
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
`;

export function NotificationsModal({
  open,
  onClose,
  title,
  children,
  variant = "primary",
}) {
  const background =
    variant === "secondary"
      ? "linear-gradient(180deg, #5252F2 0%, #3131B0 100%)"
      : "linear-gradient(180deg, #6f19b8 0%, #2d0d78 100%)";
  const shadow = variant === "secondary" ? "#170948" : "#b138e6";
  const accent = variant === "secondary" ? "#3714C1" : "#3e0f94";

  // close after 20 seconds
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, CLOSE_TIMEOUT);
      return () => clearTimeout(timer);
    }
  }, [open, onClose]);

  return (
    <Portal>
      {open && (
        <Wrap $background={background} $accent={accent}>
          <CloseIcon onClick={onClose} />
          <TitleWrap $shadow={shadow} $color={accent}>
            <Typography family="display" color="white" variant="textMd">
              {title}
            </Typography>
          </TitleWrap>
          <BodyWrap>{children}</BodyWrap>
          <PatternBackground
            pattern="sprinkles"
            $patternSize="100%"
            $opacity="0.05"
            as="div"
            className="pattern"
          />
        </Wrap>
      )}
    </Portal>
  );
}
