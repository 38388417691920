import { Container } from "../components/base/Container";
import { SectionCard } from "../components/common/SectionCard";
import styled from "styled-components";
import { device } from "../theme/mediaQuery";
import useDocumentTitle from "../helpers/useDocumentTitle";
import { Typography } from "../components/base/Typography";
import { Box } from "../components/base/Box";
import { BUG_BOUNTY, BLOG } from "../consts/externalLinks";

const ContentWrap = styled.div`
  padding: 0 20px;
  margin: 0 auto;
  color: ${({ theme }) => theme.palette.white};
  padding-bottom: 50px;

  & a {
    color: ${({ theme }) => theme.palette.white};
  }

  & strong {
    font-weight: 900;
  }

  & p,
  & ul li {
    font-size: 16px;
    padding: 5px 0;
    font-weight: 600;
  }

  & p {
    padding: 10px 0;
  }

  & .c2 {
    font-size: 23px;
    font-weight: 700;
  }

  @media (${device.md}) {
    & p,
    & ul li {
      font-size: 21px;
    }

    & .c2 {
      font-size: 32px;
    }
  }
`;

const ListWrap = styled.ol`
  padding-left: 24px;

  font-size: 23px;
  font-weight: 700;

  & p {
    margin-left: -24px;
  }

  & ul {
    margin-left: 25px;
    li {
      list-style: disc;
    }
  }

  @media (${device.md}) {
    font-size: 32px;
    padding-left: 35px;
    & p {
      margin-left: -35px;
    }

    & ul {
      margin-left: 40px;
    }
  }
`;

const ListItemWrap = styled.li`
  list-style: upper-alpha;
  margin-top: 20px;
  word-break: break-word;
`;

const BannerWrap = styled(Box)`
  padding: 24px;
  margin-bottom: 8px;
  border-radius: 16px;
  text-align: center;
  word-break: break-word;

  & a {
    color: inherit;
    font-size: inherit;
    font-family: inherit;
  }
`;

export function BugBounty() {
  useDocumentTitle("BugBounty");

  return (
    <Container className="pb-5">
      <SectionCard title="Bug Bounty">
        <ContentWrap>
          <BannerWrap
            variant="gradient"
            rounded="md"
            gradient="linearBluePrimary"
            // color="blue200"
            pattern="labyrinth"
            patternSize="20%"
            patternOpacity="0"
          >
            <Typography tag="p" color="white" variant="textXl" family="body">
              Found a pesky bug? Email us at{" "}
              <a href="mailto:bounty@laguna.games">bounty@laguna.games</a>!
            </Typography>
          </BannerWrap>
          <ListWrap>
            <ListItemWrap className="c1">
              <span className="c2">Summary</span>
              <p>
                Crypto Unicorns is dedicated to the security and integrity of
                our systems. This program is intended to incentivize security
                researchers to identify and report vulnerabilities within the
                Crypto Unicorns ecosystem. We hope to invite ethical security
                hackers (aka White Hats) to scrutinize our contracts for
                exploits. In our commitment to digital security, we have
                meticulously analyzed the Security Bug Bounty landscape to
                develop a new, competitive reward system. Our remuneration
                system goes beyond monetary compensation, fostering a sense of
                recognition and respect for the invaluable contributions of
                these individuals. We are confident that our reward system is in
                par with market offerings, and we pledge to continually monitor
                and adjust it as necessary, ensuring it stays relevant to the
                ever-evolving security needs.
              </p>
            </ListItemWrap>
            <ListItemWrap className="c1">
              <span className="c0">Scope</span>
              <p>
                The{" "}
                <a href={BLOG.SECURITY} target="_blank" rel="noreferrer">
                  security bug bounty program
                </a>{" "}
                focuses on the following infrastructure:
              </p>
              <ul>
                <li>Vulnerabilities in smart contracts</li>
                <li>Web3 security issues</li>
                <li>Authentication and authorization flaws</li>
                <li>Infrastructure and network security issues</li>
              </ul>
              <p>
                The security bug bounty program focuses on preventing the
                following:
              </p>
              <ul>
                <li>Loss of funds (both user-end and project-end)</li>
                <li>Loss of access to assets</li>
                <li>Abnormal contract states and malfunctions</li>
                <li>Damage to the brand's and IP's reputation</li>
                <li>
                  Other losses with significant economic, market, and brand
                  implications
                </li>
              </ul>
            </ListItemWrap>
            <ListItemWrap className="c1">
              <span className="c0">Assets in Scope</span>
              <ul>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.UNIM}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Unicorn Milk (UNIM)
                  </a>
                </li>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.CU}
                    target="_blank"
                    rel="noreferrer"
                  >
                    CU
                  </a>
                </li>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.CULP}
                    target="_blank"
                    rel="noreferrer"
                  >
                    CULP
                  </a>
                </li>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.UNICORNS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Crypto Unicorns
                  </a>
                </li>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.LANDS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Crypto Unicorns Lands
                  </a>
                </li>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.SHADOWCORNS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Crypto Unicorns Shadowcorns
                  </a>
                </li>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.ITEM_MARKETPLACE}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Crypto Unicorns Item Marketplace
                  </a>
                </li>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.BANK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Game Bank
                  </a>
                </li>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.STAKING_V2}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Staking v2
                  </a>
                </li>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.STAKING_V2_CULP}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Staking CULP
                  </a>
                </li>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.DROPPER}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dropper
                  </a>
                </li>
                <li>
                  <a
                    href={BUG_BOUNTY.POLY_SCAN.SATELLITE_BANK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Satellite Game Bank
                  </a>
                </li>
              </ul>
            </ListItemWrap>
            <ListItemWrap className="c1">
              <span className="c0">Limitations</span>
              <p>
                The following issues are considered out-of-scope and will not be
                eligible for rewards:
              </p>
              <ul>
                <li>
                  Vulnerabilities, issues, and malfunctions with no impact on
                  security
                </li>
                <li>
                  Theoretical vulnerabilities without practical impact or
                  proof-of-concept
                </li>
                <li>
                  Information disclosure with no to minimal impact on security
                  (ex. directories, logs, etc)
                </li>
                <li>
                  Vulnerabilities limited to outdated or unpatched browsers
                </li>
                <li>Social engineering attacks</li>
                <li> Denial of service (ex. DoS, DDoS) attacks</li>
                <li>Content spoofing or text injection issues</li>
                <li>
                  Vulnerabilities in third-party services that are not under our
                  control
                </li>
                <li>
                  Internally-known issues, issues that have been made public,
                  issues connected to a prior issue, lesser issues that exist in
                  connection to greater issues, and/or duplicate issues
                </li>
                <li>
                  Vulnerabilities affecting assets that are not directly related
                  with Crypto Unicorns
                </li>
                <li>
                  Vulnerabilities that require physical access to a user's
                  device to exploit
                </li>
              </ul>
              <p>
                For reports that fall outside the scope of the Security Bug
                Bounty, please submit them through the Usability{" "}
                <a href={BLOG.PROGRAM} target="_blank" rel="noreferrer">
                  Bug Bounty Program
                </a>{" "}
                on{" "}
                <a href={BUG_BOUNTY.ZENDESK} target="_blank" rel="noreferrer">
                  ZenDesk
                </a>
                .
              </p>
            </ListItemWrap>
            <ListItemWrap className="c1">
              <span className="c0">Rewards</span>
              <p>
                Rewards for critical smart contract vulnerabilities is up to{" "}
                <strong>$200,000 USD</strong> depending on the severity. The
                assessment of severity takes into account the exploitability of
                the bug, the impact it causes, and the likelihood of the
                vulnerability presenting itself.
              </p>
              <p>
                Rewards are allocated to the <strong>first</strong> researcher
                (the original reporter) to submit a verifiable report and is
                based on the severity of the vulnerability. The final reward
                amount will ultimately be determined by the Crypto Unicorns team
                at their discretion.
              </p>
            </ListItemWrap>
            <ListItemWrap className="c1">
              <span className="c0">Reporting</span>
              <p>
                To submit a <strong>valid</strong> security bug bounty report,
                please send an email with the following items to{" "}
                <a href="mailto:bounty@laguna.games">bounty@laguna.games</a>:
              </p>
              <ul>
                <li>
                  Your contact details for further communication (i.e. name,
                  email address, Telegram/Discord)
                </li>
                <li>Description of the vulnerability</li>
                <li>
                  Complete proof-of-concept (PoC) and vulnerability type
                  (step-by-step exploit procedure)
                </li>
                <li>
                  The IP address where the security flaw was first discovered,
                  as well as the date and time of the discovery.
                </li>
                <li>
                  Archive of files that can help in reproducing the issue
                  (screenshots, images, logs, source code, scripts, etc.)
                </li>
                <li>
                  A description of the potential impact should the vulnerability
                  be exploited
                </li>
                <li>A list of potential corrective or mitigating measures</li>
              </ul>
              <p>
                Please ensure that each item on the list is checked off to
                ensure that your report is gets tagged as “original reporter”.
                As the checklist requires proof of concept and other
                documentations, qualitative descriptions are inadequate. The
                report must be complete and verifiable in order to qualify for
                monetary rewards.
              </p>
            </ListItemWrap>
            <ListItemWrap className="c1">
              <span className="c0">Participation</span>
              <p>By participating in the bug bounty program, you agree to:</p>
              <ul>
                <li>
                  Adhere to the program's guidelines and report findings
                  responsibly.
                </li>
                <li>
                  Maintain the confidentiality of any information regarding the
                  vulnerabilities discovered until they are officially disclosed
                  by the team
                </li>
                <li>
                  Follow responsible disclosure and give us ample time to
                  address the vulnerability before disclosing it publicly
                </li>
                <li>
                  Refrain from downloading/extracting/crawling exposed data
                  beyond what is required for proof-of-concept
                </li>
                <li>
                  Refrain from disruptive testing, such as denial-of-service
                  (DoS) attacks, social engineering attacks, and other similar
                  attacks
                </li>
                <li>
                  Refrain from attacking any vulnerability for personal gain
                </li>
                <li>
                  Refrain from causing any harm to the IP, its users, data, and
                  all associated platforms
                </li>
                <li>Comply with all applicable laws and regulations</li>
              </ul>
            </ListItemWrap>
            <ListItemWrap className="c1">
              <span className="c0">Exclusions</span>
              <p>
                This security bug bounty is open to everyone, with the exception
                of the following:
              </p>
              <ul>
                <li>
                  Anyone who discovered a security vulnerability and failed to
                  report it, thereby putting the ecosystem and its users at risk
                </li>
                <li>
                  Anyone who exploited a security vulnerability for personal
                  gain without reporting or prior to reporting
                </li>
                <li>
                  Anyone who shared information about the security vulnerability
                  with other users without reporting or prior to reporting, thus
                  encouraging its exploitation directly or indirectly
                </li>
                <li>
                  Any employee from the development teams/companies working on
                  Crypto Unicorns
                </li>
                <li>
                  Any employee and team members of third party-partners and
                  second-party developers operating in a technical capacity
                </li>
                <li>
                  Any team members from audit firms, its third-party partners,
                  and its subsidiaries that audited for Crypto Unicorns
                </li>
              </ul>
            </ListItemWrap>
          </ListWrap>
          <p className="c1">
            <span className="c0">Last updated: May 25th, 2023 </span>
          </p>
        </ContentWrap>
      </SectionCard>
    </Container>
  );
}
