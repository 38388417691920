import { StakingRewardsSection } from "./StakingBadgesSection";
import { StakingDropdown } from "../StakingDropdown";
import { useState } from "react";
import {
  StakingBadgeTable,
  StakingBadgeTableCell,
  StakingBadgeTableHeading,
  StakingBadgeTableRow,
} from "./StakingBadgeTable";
import styled from "styled-components";
import { StakingBadgeAvatar } from "./StakingBadgeAvatar";
import { StakingBadgeRarity } from "./StakingBadgeRarity";
import { useGetVaultBadges } from "../../../query/vault";
import { trimWalletAddress } from "../../../helpers/trimWalletAddress";
import { StakingTokenTypes } from "../constants";
import { device } from "../../../theme/mediaQuery";
import { useAtomValue } from "jotai/index";
import { currentWalletAddressAtom } from "../../../store";

const FilterValues = {
  ALL: null,
  DELEGATED_TO_YOU: 1,
  DELEGATED_TO_OTHERS: 2,
  DELEGATED_TO_YOU_BY_OTHERS: 3,
};

const Heading = styled(StakingBadgeTableHeading)`
  flex: 1;
`;

const BadgeHeading = styled(Heading)`
  flex: 3;
`;

const RarityCell = styled(StakingBadgeTableCell)`
  @media (${device.xl}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

function filterDelegatedBadges(badges, token) {
  const filteredBadges = badges.filter((badge) => badge.delegated !== "0x0000000000000000000000000000000000000000");

  // map and add pool type to each badge
  return filteredBadges.map((badge) => ({
    ...badge,
    pool: token,
  }));
}

export function StakingBadgesEscrowed() {
  const currentWallet = useAtomValue(currentWalletAddressAtom);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const { data = {}, isLoading } = useGetVaultBadges();

  const CUBadges = filterDelegatedBadges(data?.badgesCU || [], StakingTokenTypes.cu);
  const CULPBadges = filterDelegatedBadges(data?.badgesCULP || [], StakingTokenTypes.culp);

  const badges = [
    ...CUBadges,
    ...CULPBadges,
  ];

  const filteredBadges = badges.filter((badge) => {
    const filterValues = selectedFilter?.value || null;
    if (filterValues === null) return true;
    if (filterValues === FilterValues.DELEGATED_TO_YOU) {
      return badge.delegated?.toLowerCase() === currentWallet?.toLowerCase();
    }
    if (filterValues === FilterValues.DELEGATED_TO_OTHERS) {
      return badge.delegated?.toLowerCase() !== currentWallet?.toLowerCase();
    }
    if (filterValues === FilterValues.DELEGATED_TO_YOU_BY_OTHERS) {
      return badge.delegatedFrom?.toLowerCase() !== currentWallet?.toLowerCase() && badge.delegated?.toLowerCase() === currentWallet?.toLowerCase();
    }
  });
  return (
    <StakingRewardsSection
      title="Delegated badges"
      actions={
        <StakingDropdown
          size="sm"
          value={selectedFilter}
          onChange={setSelectedFilter}
          options={[
            { value: FilterValues.ALL, title: "All" },
            { value: FilterValues.DELEGATED_TO_YOU, title: "DELEGATED TO YOU" },
            { value: FilterValues.DELEGATED_TO_OTHERS, title: "DELEGATED TO OTHERS" },
            { value: FilterValues.DELEGATED_TO_YOU_BY_OTHERS, title: "DELEGATED TO YOU BY OTHERS" },
          ]}
        />
      }
    >
      <StakingBadgeTable
        isLoading={isLoading}
        headingMobileTitle={"Delegated Badges"}
        headings={
          <>
            <BadgeHeading>Badge</BadgeHeading>
            <Heading>Rarity</Heading>
            <Heading>Boost</Heading>
            <Heading>From</Heading>
            <Heading>To</Heading>
            <Heading>Pool</Heading>
          </>
        }
        renderRow={(badge) => <BadgeRow badge={badge} key={badge.poolId + badge.pool} />}
        items={filteredBadges}
      />
    </StakingRewardsSection>
  );
}


function BadgeRow({ badge }) {
  const { delegated, pool, boostFactor, attributes, delegatedFrom } = badge;

  // get rarity from attributes by trait_type
  const rarity = attributes.find((attr) => attr.trait_type?.toLowerCase() === "rarity");

  return (
    <StakingBadgeTableRow>
      <StakingBadgeTableCell $flex={3}>
        <StakingBadgeAvatar badge={badge} />
      </StakingBadgeTableCell>
      <RarityCell title="Rarity">
        {rarity?.value && (
          <StakingBadgeRarity
            $variant={rarity.value.toLowerCase()}
          >
            {rarity.value}
          </StakingBadgeRarity>
        )}
        {!rarity?.value && <>-</>}
      </RarityCell>
      <StakingBadgeTableCell title="Boost">
        {boostFactor}
      </StakingBadgeTableCell>
      <StakingBadgeTableCell title="from">
        {trimWalletAddress(delegatedFrom)}
      </StakingBadgeTableCell>
      <StakingBadgeTableCell title="to">
        {trimWalletAddress(delegated)}
      </StakingBadgeTableCell>
      <StakingBadgeTableCell title="Pool">
        {pool === StakingTokenTypes.cu ? "CU" : "CULP"}
      </StakingBadgeTableCell>
    </StakingBadgeTableRow>
  );
}