import styled from "styled-components";
import { Typography } from "../base/Typography";
import Spinner from "../base/Spinner";
import { CommonCard, CommonCardHeader, CommonCardTitle } from "./CommonCard";
import { device } from "../../theme/mediaQuery";
import { Icon } from "../base/Icon";
import { Tooltip } from "../base/Tooltip";

const CardWrap = styled(CommonCard)``;

const HeadingWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  padding: 12px 16px;
  height: 100%;

  & div:last-of-type {
    margin-left: ${({ $rightAlignTooltip }) =>
      $rightAlignTooltip ? "auto" : ""};
  }

  @media (${device.lg}) {
    justify-content: flex-end;
    padding: 16px 40px;
    flex-wrap: nowrap;
  }
`;

const CardHeaderWrap = styled(CommonCardHeader)`
  height: auto;
  padding: 10px 0;
  @media (${device.lg}) {
    height: 88px;
    padding: 0;
  }
`;

const CardTitleWrap = styled(CommonCardTitle)`
  display: flex;
  flex-direction: ${({ $hasSubtitle }) =>
    $hasSubtitle ? " column-reverse" : "column"};
  align-items: flex-start;

  gap: 8px;

  @media (${device.sm}) {
    align-items: center;
    flex-direction: row;
  }

  @media (${device.lg}) {
    gap: 12px;
  }
`;

const TitlesWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-right: auto;

  & > * {
    line-height: 1em;
  }
`;

const BodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: 200px;
  flex-grow: 1;
  background-color: #f3f3ff;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  @media (${device.lg}) {
    padding: 40px;
  }
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 197px;
`;

const InfoIcon = styled(Icon)`
  width: 24px;
  cursor: pointer;

  & svg * {
    fill: white;
    fill-opacity: 1;
  }

  &:hover {
    & svg * {
      fill: #f5b53d;
    }
  }
`;

export function PlayerCard({
  title,
  subtitle,
  tag,
  isLoading,
  tooltip,
  button,
  children,
  ...rest
}) {
  return (
    <CardWrap>
      <CardHeaderWrap>
        <HeadingWrap $rightAlignTooltip={button === undefined}>
          <TitlesWrap>
            <CardTitleWrap $hasSubtitle={subtitle}>
              {title}
              {tag && <Tag color="#C90707" label={tag} />}
            </CardTitleWrap>
            {subtitle && (
              <Typography
                tag={"p"}
                variant="textXs"
                xl={{
                  variant: "textSm",
                }}
                textshadow="medium"
              >
                {subtitle}
              </Typography>
            )}
          </TitlesWrap>
          {button && button}

          {tooltip && (
            <Tooltip
              overlay={
                <PlayerTooltip
                  title={tooltip.title}
                  description={tooltip.desc}
                />
              }
              border="md"
              large
            >
              <InfoIcon
                name="info"
                color="white"
                $rightAlign={button === undefined}
              />
            </Tooltip>
          )}
        </HeadingWrap>
      </CardHeaderWrap>
      <BodyWrap {...rest}>
        {isLoading && (
          <LoadingWrap>
            <Spinner color="rgba(51,52,185,0.8)" />
          </LoadingWrap>
        )}
        {!isLoading && children}
      </BodyWrap>
    </CardWrap>
  );
}

const TagWrap = styled(Typography)`
  background: ${({ theme, $color }) =>
    theme.palette[$color] ? theme.palette[$color] : $color};
  line-height: 11px;
  padding: 7px 16px;
  border-radius: 70px;
  text-shadow: none;
`;

export const Tag = ({ color, label, ...rest }) => {
  return (
    <TagWrap
      variant="textXs"
      tag={"span"}
      family={"display"}
      lg={{
        variant: "textMd",
      }}
      $color={color}
      {...rest}
    >
      {label}
    </TagWrap>
  );
};

const TooltipWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  max-width: 215px;

  & > h4 {
    line-height: 1;
  }
`;

function PlayerTooltip({ title, description }) {
  return (
    <TooltipWrap>
      <Typography
        variant="textLg"
        family="body"
        color="#2F206E"
        weight={700}
        tag="h4"
        lg={{ variant: "textXl" }}
      >
        {title}
      </Typography>
      <Typography
        variant="textMd"
        lg={{ variant: "textSm" }}
        color="#2F206E"
        weight={500}
        tag="p"
      >
        {description}
      </Typography>
    </TooltipWrap>
  );
}
