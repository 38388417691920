import { useCallback, useEffect, useState } from "react";
import { useAtomValue } from "jotai/index";
import { currentWalletAddressAtom } from "../store";
import { API } from "../consts/externalLinks";

const BASE_URL = API.LEADERBOARD;

export function GetLeaderboard({ id }) {
  const walletAddress =
    useAtomValue(currentWalletAddressAtom) ||
    "0x0000000000000000000000000000000000000000";
  // const walletAddress = "0x609F10EF05e5b068382d1a6D68409b437160d064";
  // const walletAddress = "0x05610b9Dc6Fc1515F5be854349093198cc9d561C";
  // const walletAddress = "0x3623e1ED0Dc13Eb767F1166E68c89EA66cdaE2e0";
  const LEADERBOARD_URL = `${BASE_URL}?limit=4&leaderboard_id=${id}`;
  const QUARTILES_URL = `${BASE_URL}/quartiles?leaderboard_id=${id}`;
  const POSITION_URL = `${BASE_URL}/position?leaderboard_id=${id}`;

  const [leaderboard, setLeaderboard] = useState(null);
  const [top, setTop] = useState(null);
  const [quartiles, setQuartiles] = useState(null);
  const [position, setPosition] = useState(null);
  const [self, setSelf] = useState({ score: 0 });
  const [addressNotFound, setAddressNotFound] = useState(false);

  const removeQaAddress = (_top) => {
    // let qaAddress = "0xD9eF25B896D9BFbB81306404C9b296bF3AAD4f1F";
    // let index = _top.findIndex((x) => x.address === qaAddress);
    // console.log("index :", index);
    //
    // if (index === 0) {
    //   _top.splice(0, 1);
    //   _top[0].rank = 1;
    //   _top[1].rank = 2;
    //   _top[2].rank = 3;
    // }
    // if (index === 1) {
    //   _top.splice(1, 1);
    //   _top[0].rank = 1;
    //   _top[1].rank = 2;
    //   _top[2].rank = 3;
    // }
    // if (index === 2) {
    //   _top.splice(2, 1);
    //   _top[0].rank = 1;
    //   _top[1].rank = 2;
    //   _top[2].rank = 3;
    // } else {
    //   _top.splice(3, 1);
    // }
  };

  const updateLeaderboard = useCallback(() => {
    fetch(LEADERBOARD_URL)
      .then((res) => res.json())
      .then((res) => {
        // setTop(res["leaderboard"]);
        removeQaAddress(res);
        setTop(res);
      });

    fetch(QUARTILES_URL)
      .then((res) => res.json())
      .then((res) => {
        res["percentile_75"].quartle = `Top 25`;
        res["percentile_50"].quartle = `Top 50`;
        res["percentile_25"].quartle = `Top 75`;
        setQuartiles([
          res["percentile_75"],
          res["percentile_50"],
          res["percentile_25"],
        ]);
      })
      .catch(() => {
        setQuartiles([]);
      });

    fetch(`${POSITION_URL}&address=${walletAddress}&window_size=2`)
      .then((res) => {
        // Handle if address doesn't exist
        if (res.ok) {
          return res.json();
        } else {
          setPosition({});
          setAddressNotFound(true);
        }
      })
      .then((res) => {
        setPosition(res);
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });

    fetch(`${POSITION_URL}&address=${walletAddress}&window_size=0`)
      .then((res) => {
        // Handle if address doesn't exist
        if (res.ok) {
          return res.json();
        } else {
          setSelf({ score: 0 });
        }
      })
      .then((res) => {
        if (res[0]?.score) {
          setSelf(res[0]);
        } else {
          setSelf({ score: 0 });
        }
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }, [walletAddress, LEADERBOARD_URL, QUARTILES_URL, POSITION_URL]);

  useEffect(() => {
    setAddressNotFound(false);
    if (!walletAddress || !id) return;

    updateLeaderboard();

    // Poll every 5 seconds to check if update needed
    // setInterval(() => {
    //   getTimerUpdate();
    // }, 5000);
  }, [walletAddress, updateLeaderboard, id]);

  const rankSortCallback = (arrayItemA, arrayItemB) => {
    if (arrayItemA.rank < arrayItemB.rank) {
      return -1;
    }

    if (arrayItemA.rank > arrayItemB.rank) {
      return 1;
    }

    return 0;
  };

  // Combine and sort all values
  //  then set leaderboard for displaying rows
  useEffect(() => {
    if (!top || !quartiles || !position || !self || !walletAddress) {
      return;
    }

    // Combine and sort
    let _sorted;
    if (parseInt(self.score) === 0) {
      // Current user's score is zero, so don't list positions
      _sorted = [].concat(top, quartiles).sort(rankSortCallback);
    } else {
      _sorted = [].concat(top, position, quartiles).sort(rankSortCallback);
    }

    // Remove duplicate addresses
    let _filtered = _sorted.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.address === value.address)
    );

    // Highlight user's row
    _filtered.forEach((obj, index) => {
      if (obj?.address?.toLowerCase() === walletAddress.toLowerCase()) {
        _filtered[index].current_user = true;
      }
    });

    setLeaderboard(_filtered);
  }, [top, quartiles, position, self, walletAddress]);

  if (!leaderboard) {
    return null;
  }

  return {
    top,
    quartiles,
    position,
    self,
    addressNotFound,
    data: leaderboard,
  };
}

export function GetFullLeaderboard({ id, page }) {
  const walletAddress =
    useAtomValue(currentWalletAddressAtom) ||
    "0x0000000000000000000000000000000000000000";

  const [currentPage, setCurrentPage] = useState(page ? page : 0);
  const [offset, setOffset] = useState(page ? page * 10 : 0);

  const LEADERBOARD_URL = `${BASE_URL}?leaderboard_id=${id}&offset=${offset}`;
  const COUNT_URL = `${BASE_URL}/count/addresses?leaderboard_id=${id}`;
  const POSITION_URL = `${BASE_URL}/position?leaderboard_id=${id}`;

  const [leaderboard, setLeaderboard] = useState(null);
  const [top, setTop] = useState(null);
  const [count, setCount] = useState(null);
  const [position, setPosition] = useState(null);
  const [self, setSelf] = useState({ score: 0 });
  const [addressNotFound, setAddressNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const updateLeaderboard = useCallback(async () => {
    setIsLoading(true);
    const fetchJSON = (url) =>
      fetch(url).then((res) => {
        if (!res.ok) {
          throw new Error(`Failed to fetch: ${url}`);
        }
        return res.json();
      });

    // Prepare all fetch promises
    const leaderboardPromise = fetchJSON(LEADERBOARD_URL);
    const countPromise = fetchJSON(COUNT_URL);
    const positionPromise = fetchJSON(
      `${POSITION_URL}&address=${walletAddress}&window_size=0`
    );

    Promise.all([leaderboardPromise, countPromise, positionPromise])
      .then(([leaderboardData, countData, positionData]) => {
        setTop(leaderboardData);
        setCount(countData.count ? Math.floor(countData.count / 10) : 0);

        if (positionData?.length) {
          setPosition(positionData[0]);
          setSelf(positionData[0]);
        } else {
          setPosition({});
          setSelf({ score: 0 });
          setAddressNotFound(true);
        }
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }, [walletAddress, LEADERBOARD_URL, POSITION_URL, COUNT_URL]);

  useEffect(() => {
    setAddressNotFound(false);
    if (!walletAddress || !id) return;

    updateLeaderboard();
  }, [walletAddress, updateLeaderboard, id]);

  const rankSortCallback = (arrayItemA, arrayItemB) => {
    if (arrayItemA.rank < arrayItemB.rank) {
      return -1;
    }

    if (arrayItemA.rank > arrayItemB.rank) {
      return 1;
    }

    return 0;
  };

  // Combine and sort all values
  //  then set leaderboard for displaying rows
  useEffect(() => {
    if (!top || !position || !self || !walletAddress) {
      return;
    }

    // Combine and sort
    let _sorted;
    if (parseInt(self.score) === 0) {
      // Current user's score is zero, so don't list positions
      _sorted = top;
    } else {
      _sorted = [].concat(top, position).sort(rankSortCallback);
    }

    // Remove duplicate addresses
    let _filtered = _sorted.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.address === value.address)
    );

    // Highlight user's row
    _filtered.forEach((obj, index) => {
      if (obj?.address?.toLowerCase() === walletAddress.toLowerCase()) {
        _filtered[index].current_user = true;
      }
    });

    setLeaderboard(_filtered);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [self, walletAddress, top, position]);

  // Fetch the next page
  const getLeaderboardPage = (page) => {
    setOffset(page * 10);
    setCurrentPage(page);
  };

  if (!leaderboard) {
    return null;
  }

  return {
    page: {
      current: currentPage,
      count,
    },
    top,
    position,
    self,
    addressNotFound,
    data: leaderboard,
    getLeaderboardPage,
    isLoading,
  };
}

export function GetClanFullLeaderboard({ id, idIndividual, page }) {
  const walletAddress =
    useAtomValue(currentWalletAddressAtom) ||
    "0x0000000000000000000000000000000000000000";

  const [currentPage, setCurrentPage] = useState(page ? page : 0);
  const [offset, setOffset] = useState(page ? page * 10 : 0);

  const LEADERBOARD_URL = `${BASE_URL}?leaderboard_id=${id}&offset=${offset}`;
  const COUNT_URL = `${BASE_URL}/count/addresses?leaderboard_id=${id}`;
  const POSITION_URL = `${BASE_URL}/position?leaderboard_id=${idIndividual}`;

  const [leaderboard, setLeaderboard] = useState(null);
  const [top, setTop] = useState(null);
  const [count, setCount] = useState(null);
  const [position, setPosition] = useState(null);
  const [self, setSelf] = useState({ score: 0 });
  const [addressNotFound, setAddressNotFound] = useState(false);

  const updateLeaderboard = useCallback(() => {
    fetch(LEADERBOARD_URL)
      .then((res) => res.json())
      .then((res) => {
        setTop(res);
      });

    fetch(COUNT_URL)
      .then((res) => res.json())
      .then((res) => {
        if (res.count) {
          setCount(Math.floor(res?.count / 10));
        } else {
          setCount(0);
        }
      });

    fetch(`${POSITION_URL}&address=${walletAddress}&window_size=0`)
      .then((res) => {
        // Handle if address doesn't exist
        if (res.ok) {
          return res.json();
        } else {
          setPosition({});
          setAddressNotFound(true);
        }
      })
      .then((res) => {
        setPosition(res);
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });

    fetch(`${POSITION_URL}&address=${walletAddress}&window_size=0`)
      .then((res) => {
        // Handle if address doesn't exist
        if (res.ok) {
          return res.json();
        } else {
          setSelf({ score: 0 });
        }
      })
      .then((res) => {
        if (res[0]?.score) {
          setSelf(res[0]);
        } else {
          setSelf({ score: 0 });
        }
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }, [walletAddress, LEADERBOARD_URL, POSITION_URL, COUNT_URL]);

  useEffect(() => {
    setAddressNotFound(false);
    if (!walletAddress || !id) return;

    updateLeaderboard();
  }, [walletAddress, updateLeaderboard, id]);

  // Combine and sort all values
  //  then set leaderboard for displaying rows
  useEffect(() => {
    if (!top || !position || !self || !walletAddress) {
      return;
    }

    // Combine and sort
    let _sorted = top;

    // Remove duplicate addresses
    let _filtered = _sorted.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.address === value.address)
    );

    // Highlight user's row
    _filtered.forEach((obj, index) => {
      if (obj?.points_data?.clan_name === self?.points_data?.clan_name) {
        _filtered[index].current_user = true;
      }
    });

    setLeaderboard(_filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [self, walletAddress, top, position]);

  // Fetch the next page
  const getLeaderboardPage = (page) => {
    setOffset(page * 10);
    setCurrentPage(page);
  };

  if (!leaderboard) {
    return null;
  }

  return {
    page: {
      current: currentPage,
      count,
    },
    top,
    position,
    self,
    addressNotFound,
    data: leaderboard,
    getLeaderboardPage,
  };
}

export function GetTribeContributionLeaderboard({ id, page }) {
  const walletAddress =
    useAtomValue(currentWalletAddressAtom) ||
    "0x0000000000000000000000000000000000000000";

  const LEADERBOARD_URL = `${BASE_URL}?leaderboard_id=${id}&limit=${1000000}`;
  const POSITION_URL = `${BASE_URL}/position?leaderboard_id=${id}`;

  const [leaderboard, setLeaderboard] = useState(null);
  const [top, setTop] = useState(null);
  const [position, setPosition] = useState(null);
  const [self, setSelf] = useState({ score: 0 });
  const [addressNotFound, setAddressNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const resetStates = () => {
    setTop(null);
    setPosition(null);
    setSelf({ score: 0 });
    setLeaderboard(null);
  };

  const updateLeaderboard = useCallback(async () => {
    resetStates();
    setIsLoading(true);
    const fetchJSON = (url) =>
      fetch(url).then((res) => {
        if (!res.ok) {
          throw new Error(`Failed to fetch: ${url}`);
        }
        return res.json();
      });

    // Prepare all fetch promises
    const leaderboardPromise = fetchJSON(LEADERBOARD_URL);
    const positionPromise = fetchJSON(
      `${POSITION_URL}&address=${walletAddress}&window_size=0`
    );

    Promise.all([leaderboardPromise, positionPromise])
      .then(([leaderboardData, positionData]) => {
        setTop(leaderboardData);
        if (positionData?.length) {
          setPosition(positionData[0]);
          setSelf(positionData[0]);
        } else {
          setPosition({});
          setSelf({ score: 0 });
          setAddressNotFound(true);
        }
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }, [walletAddress, LEADERBOARD_URL, POSITION_URL]);

  useEffect(() => {
    setAddressNotFound(false);
    if (!walletAddress || !id) return;

    updateLeaderboard();
  }, [walletAddress, updateLeaderboard, id]);

  const rankSortCallback = (arrayItemA, arrayItemB) => {
    if (arrayItemA.rank < arrayItemB.rank) {
      return -1;
    }

    if (arrayItemA.rank > arrayItemB.rank) {
      return 1;
    }

    return 0;
  };

  // Combine and sort all values
  //  then set leaderboard for displaying rows
  useEffect(() => {
    if (!top || !position || !self || !walletAddress) {
      return;
    }

    // Combine
    let _tribe;
    const _tribeId = self?.points_data?.tribe_id;
    if (_tribeId) {
      _tribe = top.filter((data) => data?.points_data?.tribe_id === _tribeId);
    } else {
      // User does not belong to a tribe - show all
      _tribe = top;
    }

    // Sort
    let _sorted;
    if (parseInt(self.score) === 0) {
      // Current user's score is zero, so don't list positions
      _sorted = _tribe;
    } else {
      _sorted = [].concat(_tribe, position).sort(rankSortCallback);
    }

    // Remove duplicate addresses
    let _filtered = _sorted.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.address === value.address)
    );

    // Highlight user's row
    _filtered.forEach((obj, index) => {
      if (obj?.address?.toLowerCase() === walletAddress.toLowerCase()) {
        _filtered[index].current_user = true;
      }
    });

    setLeaderboard(_filtered);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [self, walletAddress, top, position]);

  if (!leaderboard) {
    return null;
  }

  return {
    top,
    position,
    self,
    addressNotFound,
    data: leaderboard,
    isLoading,
  };
}

export function GetTribeFullLeaderboard({ id, idIndividual, page }) {
  const walletAddress =
    useAtomValue(currentWalletAddressAtom) ||
    "0x0000000000000000000000000000000000000000";

  const [currentPage, setCurrentPage] = useState(page ? page : 0);
  const [offset, setOffset] = useState(page ? page * 10 : 0);

  const LEADERBOARD_URL = `${BASE_URL}?leaderboard_id=${id}&offset=${offset}`;
  const COUNT_URL = `${BASE_URL}/count/addresses?leaderboard_id=${id}`;
  const POSITION_URL = `${BASE_URL}/position?leaderboard_id=${idIndividual}`;

  const [leaderboard, setLeaderboard] = useState(null);
  const [top, setTop] = useState(null);
  const [count, setCount] = useState(null);
  const [position, setPosition] = useState(null);
  const [self, setSelf] = useState({ score: 0 });
  const [addressNotFound, setAddressNotFound] = useState(false);

  const updateLeaderboard = useCallback(() => {
    fetch(LEADERBOARD_URL)
      .then((res) => res.json())
      .then((res) => {
        setTop(res);
      });

    fetch(COUNT_URL)
      .then((res) => res.json())
      .then((res) => {
        if (res.count) {
          setCount(Math.floor(res?.count / 10));
        } else {
          setCount(0);
        }
      });

    fetch(`${POSITION_URL}&address=${walletAddress}&window_size=0`)
      .then((res) => {
        // Handle if address doesn't exist
        if (res.ok) {
          return res.json();
        } else {
          setPosition({});
          setAddressNotFound(true);
        }
      })
      .then((res) => {
        setPosition(res);
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });

    fetch(`${POSITION_URL}&address=${walletAddress}&window_size=0`)
      .then((res) => {
        // Handle if address doesn't exist
        if (res.ok) {
          return res.json();
        } else {
          setSelf({ score: 0 });
        }
      })
      .then((res) => {
        if (res[0]?.score) {
          setSelf(res[0]);
        } else {
          setSelf({ score: 0 });
        }
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }, [walletAddress, LEADERBOARD_URL, POSITION_URL, COUNT_URL]);

  useEffect(() => {
    setAddressNotFound(false);
    if (!walletAddress || !id) return;

    updateLeaderboard();
  }, [walletAddress, updateLeaderboard, id]);

  // Combine and sort all values
  //  then set leaderboard for displaying rows
  useEffect(() => {
    if (!top || !position || !self || !walletAddress) {
      return;
    }

    // Combine and sort
    let _sorted = top;

    // Remove duplicate addresses
    let _filtered = _sorted.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.address === value.address)
    );
    // Highlight user's row
    _filtered.forEach((obj, index) => {
      if (obj?.points_data?.tribe_name === self?.points_data?.tribe_name) {
        _filtered[index].current_user = true;
      }
    });

    setLeaderboard(_filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [self, walletAddress, top, position]);

  // Fetch the next page
  const getLeaderboardPage = (page) => {
    setOffset(page * 10);
    setCurrentPage(page);
  };

  if (!leaderboard) {
    return null;
  }

  return {
    page: {
      current: currentPage,
      count,
    },
    top,
    position,
    self,
    addressNotFound,
    data: leaderboard,
    getLeaderboardPage,
  };
}

export function GetGenericLeaderboard({ id }) {
  const LEADERBOARD_URL = `${BASE_URL}?leaderboard_id=${id}`;

  const [leaderboard, setLeaderboard] = useState(null);

  const updateLeaderboard = useCallback(() => {
    fetch(LEADERBOARD_URL)
      .then((res) => res.json())
      .then((res) => {
        if (res.length > 0) {
          setLeaderboard(res[0]);
        }
      });
  }, [LEADERBOARD_URL]);

  useEffect(() => {
    updateLeaderboard();
  }, [updateLeaderboard]);

  if (!leaderboard) {
    return null;
  }

  return {
    data: leaderboard,
  };
}
