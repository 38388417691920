import React, { useRef } from "react";
import styled from "styled-components";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { Footer } from "../layouts/BaseLayout/Footer";
import { Header } from "../components/header";
import { device } from "../theme/mediaQuery";
import { Heading } from "../components/player/Heading";
import { Container } from "../components/base/Container";
import { P2A } from "../components/player/P2A";
import { EventReward } from "../components/player/EventReward";
import { Typography } from "../components/base/Typography";
import {
  StakingTokensCards,
  ShadowcornTokensCards,
} from "../components/player/TokenCards";
import { RewardAccordion } from "../components/player/RewardAccordion";
import { StakingTokenTypes } from "../components/staking/constants";
import { FadedRainbowBackground } from "../components/home/hero/HomeHeroBackground";
import arb from "../img/currencies/arbitrum.png";
import xai from "../img/currencies/xai-white.png";
import badge from "../img/player/xai-badge.png";
import { useSwitchActiveWalletChain } from "thirdweb/react";
import { defineChain } from "thirdweb";
import { NetworkConfiguration } from "../components/Network";
import { POLYGON_NETWORK } from "../components/ConnectWallet/WalletHelper";
import { Box } from "../components/base/Box";
import { Button } from "../components/base/Button";
import { Tag } from "../components/player/PlayerCard";

const PurpleGradientWrapper = styled.div`
  background: #2d0a8b;
  padding-bottom: 24px;
  @media (${device.md}) {
    padding-bottom: 32px;
  }
  @media (${device.lg}) {
    padding-bottom: 52px;
  }
`;

export const ContainerWrap = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (${device.md}) {
    gap: 65px;
  }
  @media (${device.lg}) {
    gap: 80px;
  }
`;

export const SectionWrap = styled.section`
  padding: 18px;
  @media (${device.md}) {
    padding: 24px;
  }
  @media (${device.lg}) {
    padding: 36px;
  }
  @media (${device.xl}) {
    padding: 40px;
  }
`;

export const PlayerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  border-radius: 16px;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(70px);
  border: 4px solid ${({ $color }) => $color};

  padding: 52px 12px 20px;

  @media (${device.md}) {
    gap: 40px;
    padding: 52px 28px 24px;
  }

  @media (${device.xl}) {
    padding: 52px 40px 32px;
  }
`;

const NetworkTag = styled.div`
  position: absolute;
  cursor: pointer;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  z-index: 2;

  background-color: ${({ $color }) => $color};
  max-width: 470px;
  border: none;
  border-radius: 120px;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 90%;

  @media (min-width: 470px) {
    gap: 10px;
    width: fit-content;
    white-space: nowrap;
  }

  @media (${device.xl}) {
    gap: 16px;
  }

  & .network {
    font-weight: 700;
  }

  filter: brightness(1);
  transition: filter 0.2s ease-in-out;
  &:hover {
    filter: brightness(1.1);
  }
`;

const NetworkIcon = styled.img`
  width: 25px;
  height: 25px;
  @media (${device.sm}) {
    width: 30px;
    height: 30px;
  }
`;

export const PlayerRewards = () => {
  const xaiRef = useRef(null);
  useDocumentTitle("Claims");
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const arbChain = defineChain({
    id: netconfig.networkIdArbitrum,
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
  });

  const xaiChain = defineChain({
    id: netconfig.networkIdXai,
    nativeCurrency: {
      name: "Xai",
      symbol: "XAI",
      decimals: 18,
    },
  });
  const switchChain = useSwitchActiveWalletChain();
  // switchChain(arbChain) // switch to arb chain
  // switchChain(xaiChain) // switch to xai chain

  const handleScroll = () => {
    xaiRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <PlayerContainer>
      <Heading />
      <CTABanner onClick={handleScroll} />
      {/* Arb rewards */}{" "}
      <PlayerNetwork
        color="#3040A5"
        token={StakingTokenTypes.arb}
        switchNetwork={switchChain}
        chain={arbChain}
      >
        <P2A switchNetwork={switchChain} chain={arbChain} />
        <StakingTokensCards switchNetwork={switchChain} chain={arbChain} />
        <RewardAccordion />
        <EventReward switchNetwork={switchChain} chain={arbChain} />
      </PlayerNetwork>
      {/* XAI rewards */}
      <PlayerNetwork
        color="#FF0030"
        token={StakingTokenTypes.xai}
        switchNetwork={switchChain}
        chain={xaiChain}
      >
        <ShadowcornTokensCards switchNetwork={switchChain} chain={xaiChain} />
        <EventReward
          switchNetwork={switchChain}
          chain={xaiChain}
          ref={xaiRef}
        />
      </PlayerNetwork>
    </PlayerContainer>
  );
};

const PlayerContainer = ({ children }) => {
  return (
    <>
      <PurpleGradientWrapper>
        <Header />
        <FadedRainbowBackground>
          <ContainerWrap>{children}</ContainerWrap>
        </FadedRainbowBackground>
      </PurpleGradientWrapper>
      <Footer />
    </>
  );
};

const networkMap = {
  [StakingTokenTypes.arb]: {
    name: "Arbitrum",
    img: arb,
  },
  [StakingTokenTypes.xai]: {
    name: "XAI",
    img: xai,
  },
};
const PlayerNetwork = ({ color, token, switchNetwork, chain, children }) => {
  const network = networkMap[token];
  return (
    <PlayerWrap $color={color}>
      <NetworkTag $color={color} onClick={() => switchNetwork(chain)}>
        <NetworkIcon src={network.img} alt={network.name} />
        <Typography
          as="span"
          variant="textSm"
          md={{
            variant: "textMd",
          }}
          color="white"
        >
          Switch to <span className="network">{network.name} Mainnet </span>to
          claim these rewards
        </Typography>
      </NetworkTag>
      {children}
    </PlayerWrap>
  );
};

const BannerWrap = styled(Box)`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 16px 12px;
  border-radius: 16px;

  gap: 8px 20px;
  width: 100%;

  @media (${device.md}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 21px 24px;
  }

  @media (${device.lg}) {
    padding: 24px 40px;
  }
`;

const ButtonWrap = styled(Button)`
  padding: 0;
  & div {
    padding: 4px 20px;
    white-space: nowrap;
    word-break: keep-all;
    @media (${device.lg}) {
      padding: 6px 20px;
    }
  }
`;

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  gap: 8px 20px;
  margin-bottom: 6px;
  @media (${device.md}) {
    flex-direction: row;
  }
  @media (${device.lg}) {
    align-items: flex-start;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-grow: 1;
  line-height: normal;
  flex-direction: column;
  align-items: center;
  gap: 8px 20px;
  width: 100%;
  align-items: stretch;
  justify-content: space-between;

  @media (${device.md}) {
    align-items: flex-start;
  }

  @media (${device.lg}) {
    flex-direction: row;
    align-items: center;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (${device.md}) {
    align-items: flex-start;
    text-align: left;
  }
`;

const Icon = styled.img`
  width: 81px;
  height: auto;
`;

function CTABanner({ onClick }) {
  return (
    <BannerWrap
      rounded="md"
      pattern="dots-reverse"
      patternSize="cover"
      patternOpacity="0.5"
      gradient="#f3f3ff"
    >
      <Icon src={badge} alt="xai reward" />
      <ContentWrap>
        <TitleWrap>
          <Title
            tag="p"
            variant="textXl"
            family="display"
            xl={{
              variant: "displayXs",
            }}
            color="#3334B9"
          >
            XAI Vanguard Rewards
            <Tag color="#C90707" label="claimable on xai!" />
          </Title>
          <Typography
            tag="p"
            variant="textSm"
            xl={{
              variant: "textMd",
            }}
            color="#343434"
          >
            In order to be eligible to mint the Crypto Unicorns x XAI NFT you
            will first need to complete today's "Login to Crypto Unicorns" quest
            on Galxe.
          </Typography>
        </TitleWrap>

        <ButtonWrap size="md" onClick={onClick}>
          Go to reward
        </ButtonWrap>
      </ContentWrap>
    </BannerWrap>
  );
}
