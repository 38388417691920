import styled from "styled-components";
import { Typography } from "../../base/Typography";
import { device } from "../../../theme/mediaQuery";
import { Button } from "../../base/Button";
import { Icon } from "../../base/Icon";
import { Link } from "react-router-dom";

const StakingCalloutWrap = styled.div`
  border-radius: 16px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border: 2.646px solid #e276e9;
  background: #9a1bc6;
  gap: 10px;
  z-index: 13;
  width: 100%;

  @media (${device.md}) {
    padding: 16px;
    flex-direction: row;
    justify-content: space-between;
  }

  & p {
    text-align: center;

    @media (${device.md}) {
      text-align: left;
      margin-right: auto;
    }
  }
`;

const IconWrap = styled(Icon)`
  width: 35px;
  height: 35px;
  @media (${device.md}) {
    width: 70px;
    height: 70px;
  }
`;

export const StakingCallout = ({
  title,
  buttonText,
  icon,
  onClick,
  to,
  href,
  ...rest
}) => {
  return (
    <StakingCalloutWrap {...rest}>
      {icon && <IconWrap name={icon} />}
      <Typography
        as="p"
        family="display"
        variant="textMd"
        md={{ variant: "textLg" }}
      >
        {title}
      </Typography>
      {onClick && (
        <Button size="md" onClick={onClick} nowrap>
          {buttonText}
        </Button>
      )}
      {href && (
        <Button
          as="a"
          size="md"
          href={href}
          target="_blank"
          rel="noreferrer"
          nowrap
        >
          {buttonText}
        </Button>
      )}
      {to && (
        <Button as={Link} size="md" to={to} nowrap>
          {buttonText}
        </Button>
      )}
    </StakingCalloutWrap>
  );
};
