import { StakingHistoryTable } from "../../components/staking/history/StakingHistoryTable";
import { StakingDropdown } from "../../components/staking/StakingDropdown";
import styled from "styled-components";
import { useState } from "react";
import { StakingHistoryWithdrawNotifications } from "../../components/staking/history/StakingHistoryWithdrawNotifications";
import { useGetHistory } from "../../query/vault";
import { StakingTokenTypes } from "../../components/staking/constants";
import { TokenImagesMap } from "../../components/staking/StakingTokenImage";
import { NetworkConfiguration } from "../../components/Network";
import { POLYGON_NETWORK } from "../../components/ConnectWallet/WalletHelper";
import { defineChain } from "thirdweb";
import { useSwitchActiveWalletChain } from "thirdweb/react";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Toolbar = styled.div`
  align-self: flex-end;
`;

export function StakingHistory() {
  const { data = [], isLoading } = useGetHistory();
  const [selectedOption, setSelectedOption] = useState(null);

  const filteredData = data.filter((item) => {
    if (selectedOption === null || !selectedOption?.value) {
      return true;
    }
    return item.token === selectedOption?.value;
  });

  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const arbChain = defineChain({
    id: netconfig.networkIdArbitrum,
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
  });
  const switchNetwork = useSwitchActiveWalletChain();

  return (
    <Wrap>
      <Toolbar>
        <StakingDropdown
          value={selectedOption}
          onChange={setSelectedOption}
          options={[
            { value: null, title: "All" },
            {
              value: StakingTokenTypes.cu,
              title: "CU",
              icon: TokenImagesMap[StakingTokenTypes.cu],
            },
            {
              value: StakingTokenTypes.culp,
              title: "CULP",
              icon: TokenImagesMap[StakingTokenTypes.culp],
            },
          ]}
        />
      </Toolbar>
      <StakingHistoryTable
        items={filteredData}
        isLoading={isLoading}
        switchNetwork={switchNetwork}
        chain={arbChain}
      />
      <StakingHistoryWithdrawNotifications />
    </Wrap>
  );
}
