import styled from "styled-components";
import { device } from "../../theme/mediaQuery";

const TabsWrap = styled.ul`
  display: flex;
  align-items: stretch;
  gap: 8px;
  background-color: ${({ $background, theme }) =>
    theme.palette[$background] ? theme.palette[$background] : $background};
  transition: background-color 0.3s ease;
  padding: 12px 21px 0;
  border-radius: ${({ $floating }) => ($floating ? "16px" : "0")};
  min-height: 70px;
  flex-direction: column;

  ${({ $shadow }) =>
    $shadow && `box-shadow: 0px 4px 8px rgba(72, 84, 94, 0.08);`};

  @media (${device.sm}) {
    flex-direction: row;
    padding: 0 30px;
    gap: 25px;
  }
  @media (${device.lg}) {
    padding: 0 50px;
    min-height: 90px;
  }
`;

const TabWrap = styled.li`
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
  color: ${({ theme, $color }) =>
    $color ? $color : theme.palette.darkBlue300};
  transition: color 0.3s ease;

  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 16px;
  display: flex;
  align-items: center;

  //disabled
  filter: ${({ $disabled }) => ($disabled ? "brightness(0.7)" : "none")};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};

  @media (${device.lg}) {
    font-size: 20px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    border-radius: 8px 8px 0px 0px;
    background-color: ${({ theme, $activeColor }) =>
      $activeColor ? $activeColor : theme.palette.blue300};
    transition: background-color 0.3s ease;
    opacity: ${({ $active }) => ($active ? 1 : 0)};
  }
`;

export function Tabs({
  tabs = [],
  active,
  onChange,
  withShadow = false,
  color,
  activeTextColor,
  floating = true,
  background = "white",
  ...rest
}) {
  const textColor = activeTextColor ? activeTextColor : color;
  return (
    <TabsWrap
      $floating={floating}
      $shadow={withShadow}
      {...rest}
      $background={background}
    >
      {tabs.map((tab) => (
        <TabWrap
          key={tab.key}
          $active={tab.key === active}
          $activeColor={activeTextColor}
          $color={tab.key === active ? textColor : color}
          onClick={() => !tab.disabled && onChange(tab.key)}
          $disabled={tab?.disabled}
        >
          {tab.title}
        </TabWrap>
      ))}
    </TabsWrap>
  );
}
