import { StakingRewardsSection } from "./StakingRewardsSection";
import {
  StakingRewardsCard,
  StakingRewardsCardSection,
} from "./StakingRewardsCard";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import { StakingTokenImage } from "../StakingTokenImage";
import { CountdownTimer } from "../../common/CountdownTimer";
import { useGetClaimableRewards } from "../../../query/vault";
import { FormattedAmount } from "../../common/FormattedAmount";
import Web3 from "web3";
import { StakingRewardsPlaceholder } from "./StakingRewardsPlaceholder";
import { StakingTokenTypes } from "../constants";
import { StakingRewardClaimableButton } from "./StakingRewardClaimableButton";
import { useEffect, useState } from "react";
import { TOKEN_NAMES } from "../Modals/AssetSelector";

const TokenTitlesMap = {
  [StakingTokenTypes.cu]: "CU",
  [StakingTokenTypes.culp]: "CULP",
  [StakingTokenTypes.xai]: "XAI",
  [StakingTokenTypes.arb]: "Arbitrum",
  [StakingTokenTypes.grail]: "Grail",
  [StakingTokenTypes.rbw]: "RBW",
};

const DetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  @media (${device.lg}) {
    padding: 20px;
  }
`;

const DetailsStakedGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  column-gap: 40px;

  @media (${device.xl}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const DetailsStakedItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
`;

const DetailsStakedItemSingle = styled(DetailsStakedItem)`
  grid-column: span 2;
`;

const DetailsStakedItemIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    height: 100%;
  }

  @media (${device.lg}) {
    width: 30px;
    height: 30px;
  }
`;

const DetailsStakedItemTitle = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};

  @media (${device.lg}) {
    font-size: 20px;
  }
`;

const DetailsStakedItemValue = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.body};
  margin-left: auto;

  @media (${device.lg}) {
    font-size: 20px;
  }
`;

const DetailsDivider = styled.div`
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    90deg,
    #fff,
    #fff 50%,
    transparent 50%,
    transparent 100%
  );
  background-size: 10px 1px;
`;

const DetailsTotalsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

const DetailsTotalsItem = styled.div`
  display: flex;
  gap: 20px;

  @media (${device.lg}) {
    align-items: center;
  }
`;

const DetailsTotalsItemTitle = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};

  @media (${device.lg}) {
    font-size: 20px;
  }
`;

const DetailsTotalsItemValue = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.body};

  text-align: right;
  @media (${device.md}) {
    font-size: 20px;
    min-width: 70px;
  }
`;

const DetailsXaiTip = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
  text-align: center;
`;

export function StakingRewardsClaimable({
  setStakeInitialTab,
  switchNetwork,
  chain,
}) {
  const { data = [], isLoading } = useGetClaimableRewards();
  const [initialTab, setInitialTab] = useState(TOKEN_NAMES.CU);

  const stakedRewards = data?.filter((reward) => {
    return reward.isStaked;
  });

  // we need to show placeholder based on the staked rewards
  const isCUStaked = stakedRewards.some(
    (reward) => reward.token === StakingTokenTypes.cu
  );
  const isCULPStaked = stakedRewards.some(
    (reward) => reward.token === StakingTokenTypes.culp
  );
  let placeholderTitle = "Stake CU or CULP to earn rewards!";
  let ctaText = "Stake";

  if (isCUStaked && !isCULPStaked) {
    placeholderTitle = "Stake CULP to earn CU, XAI & GRAIL rewards!";
    ctaText = "Stake CULP";
  }

  if (!isCUStaked && isCULPStaked) {
    placeholderTitle =
      "Stake CU to earn CU rewards and participate in governance votes!";
    ctaText = "Stake CU";
  }

  const isEmpty = stakedRewards.length === 0;
  const lessThanTwo = stakedRewards.length < 2;

  // get time to next day at 04:45 in UTC
  const nextDayInUTC = Date.UTC(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate() + 1,
    4,
    45,
    0,
    0
  );

  useEffect(() => {
    if (isCUStaked && !isCULPStaked) {
      setInitialTab(TOKEN_NAMES.CULP);
    } else {
      setInitialTab(TOKEN_NAMES.CU);
    }
  }, [isCUStaked, isCULPStaked]);

  return (
    <StakingRewardsSection
      isLoading={isLoading}
      title="Claimable rewards"
      bodyFill={isEmpty}
      tip={
        <>
          next rewards released in <CountdownTimer toDate={nextDayInUTC} />
        </>
      }>
      {stakedRewards.map((reward, index) => {
        const claimableRewards = reward.claimable;
        const atLeastOneRewardClaimable = claimableRewards.some(
          (reward) => reward.exists
        );

        return (
          <StakingRewardsCard
            token={reward.token}
            key={index}
            action={
              <StakingRewardClaimableButton
                token={reward.token}
                reward={reward}
                deposits={reward.deposits}
                disabled={!atLeastOneRewardClaimable}
                switchNetwork={switchNetwork}
                chain={chain}
              />
            }>
            <StakingRewardsCardSection>
              <RewardDetails
                claimable={claimableRewards}
                stakedAmount={reward.staked}
                token={reward.token}
              />
            </StakingRewardsCardSection>
          </StakingRewardsCard>
        );
      })}

      {lessThanTwo && (
        <StakingRewardsPlaceholder
          title={placeholderTitle}
          ctaText={ctaText}
          setStakeInitialTab={setStakeInitialTab}
          initialTab={initialTab}
        />
      )}
    </StakingRewardsSection>
  );
}

function RewardDetails({ claimable, stakedAmount, token }) {
  //const
  // if there is only one staked token, use a different layout
  const ItemComponent =
    claimable.length === 1 ? DetailsStakedItemSingle : DetailsStakedItem;

  // calculate total staked
  const total = claimable.reduce((acc, token) => {
    // if there is no value, return 0
    if (!token.amount) {
      return acc;
    }

    if (acc === 0) {
      return Web3.utils.toBN(token.amount);
    }

    // add value using BN from web3
    return Web3.utils.toBN(token.amount).add(acc);
  }, 0);

  const isLPToken = token === StakingTokenTypes.culp;

  return (
    <DetailsWrap>
      <DetailsStakedGrid>
        {claimable.map((stakedToken, index) => (
          <ItemComponent key={index}>
            <DetailsStakedItemIcon>
              <StakingTokenImage token={stakedToken.token} />
            </DetailsStakedItemIcon>
            <DetailsStakedItemTitle>
              {TokenTitlesMap[stakedToken.token]}
            </DetailsStakedItemTitle>
            <DetailsStakedItemValue>
              <FormattedAmount wei={stakedToken.amount} />
            </DetailsStakedItemValue>
          </ItemComponent>
        ))}
      </DetailsStakedGrid>
      <DetailsDivider />
      <DetailsTotalsWrap>
        {stakedAmount && (
          <DetailsTotalsItem>
            <DetailsTotalsItemTitle>Amount staked</DetailsTotalsItemTitle>
            <DetailsTotalsItemValue>
              <FormattedAmount wei={stakedAmount} />
            </DetailsTotalsItemValue>
          </DetailsTotalsItem>
        )}
      </DetailsTotalsWrap>
    </DetailsWrap>
  );
}
