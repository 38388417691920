import { ethers } from "ethers";
import { formatStringWithFixedFraction } from "../Helpers";

// This component will take in a wei value and return the formatted amount in ether
export function FormattedAmount({
  wei,
  formatted = true,
  eth,
  fractionDigits,
  maxFractionDigits,
}) {
  if (!wei && !eth) {
    return <></>;
  }

  const value = eth ? eth : ethers.utils.formatEther(wei);

  if (formatted) {
    return (
      <>
        {formatStringWithFixedFraction(
          value,
          fractionDigits,
          maxFractionDigits
        )}
      </>
    );
  }

  return <>{value}</>;
}
