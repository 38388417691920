import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 16px;
  gap: 12px;

  @media (${device.lg}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.white};
  @media (${device.md}) {
    font-size: 21px;
  }
`;

const Value = styled.div`
  font-size: 32px;
  font-family: ${({ theme }) => theme.fonts.display};
  color: ${({ theme }) => theme.palette.white};
  @media (${device.md}) {
    font-size: 42px;
  }
`;

export function StakingBadgesCumulativeCard({ title, value }) {
  // if value digits after decimal point is more than 2, then round it to 2
  // let valueStr = value.toString();
  // if (valueStr.includes(".")) {
  //   const decimalPart = valueStr.split(".")[1];
  //   if (decimalPart.length > 2) {
  //     valueStr = value.toFixed(2);
  //   }
  // }

  return (
    <Wrap>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Wrap>
  );
}
