export const PLAY_GAME = {
  MOB_RUN: "mob_run",
  MOB_RUN_APK: "mob_run_apk",
  MOB_RUN_IOS: "mob_run_ios",
  MOB_RUN_ANDROID: "mob_run_android",
  BUMPERCORNS: "bumpercorns",
  BUMPERCORNS_APK: "bumpercorns_apk",
  BUMPERCORNS_IOS: "bumpercorns_ios",
  BUMPERCORNS_ANDROID: "bumpercorns_android",
  MAIN: "core_game",
  RAINBOW_RUMBLE: "rainbow_rumble",
  BINGO: "bingo",
  BINGO_APK: "bingo_apk",
  BINGO_IOS: "bingo_ios",
  BINGO_ANDROID: "bingo_android",
};

export const BILLBOARD_SF = {
  BILLBOARD_SF: "billboard_sf_2023",
  MODAL_OPENED: "modal_opened",
  EMAIL_SUBMITTED: "email_submitted",
  EMAIL_FAILED: "email_failed",
};

export const RAINBOW_RUMBLE = {
  LEARN_MORE: "learn_more",
  BUY_ADULT: "buy_adult_unicorn",
  BUY_CU: "buy_cu",
  WATCH_NOW: "watch_now"
};

export const EVENTS = {
  CONNECT_CLICKED: {
    CATEGORY: "connect_clicked",
    NAME: "wallet",
  },
  WALLET_CONNECTED: {
    CATEGORY: "wallet_connected",
    NAME: "wallet",
  },
  BILLBOARD_SF: {
    CATEGORY: "billboard_2023",
    NAME: "billboard_sf",
  },
  PLAY_GAME: {
    CATEGORY: PLAY_GAME.MAIN, //default game
    NAME: "play",
  },
  RAINBOW_RUMBLE: {
    CATEGORY: RAINBOW_RUMBLE.LEARN_MORE, //default action
    NAME: "rainbow_rumble",
  },
};

export const EVENT_KEYS = {
  CONNECT_CLICKED: "CONNECT_CLICKED",
  WALLET_CONNECTED: "WALLET_CONNECTED",
  BILLBOARD_SF: "BILLBOARD_SF",
  PLAY_GAME: "PLAY_GAME",
  RAINBOW_RUMBLE: "RAINBOW_RUMBLE",
};
