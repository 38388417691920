import React from "react";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";

const TitleWrap = styled.div`
  display: flex;
  justify-content: center;
  //margin-bottom: 19px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 700;
  color: ${({ theme }) => theme.palette.white};

  text-align: center;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  z-index: 1;
  letter-spacing: 0.05em;

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-text-stroke: 15px
      ${({ $isPrimary }) => ($isPrimary ? "#8221e3" : "#3714C1")};
    font-size: 1em;
    filter: drop-shadow(
      0px 4px 0px ${({ $isPrimary }) => ($isPrimary ? "#3d007a" : "#170948")}
    );
  }

  @media (${device.md}) {
    font-size: 3.875rem;
  }
`;

export const ModalTitle = ({ variant, children }) => {
  return (
    <TitleWrap>
      <Title data-text={children} $isPrimary={variant !== "secondary"}>
        {children}
      </Title>
    </TitleWrap>
  );
};
