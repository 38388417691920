import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "../base/Icon";
import { Typography } from "../base/Typography";
import { device } from "../../theme/mediaQuery";
import { StakingRewardsTable } from "./StakingRewardsTable";
import {
  useGetEscrowedRewards,
  useGetEscrowedRewardsWrongNetwork,
} from "../../query/vault";

const AccordionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  @media (${device.sm}) {
    margin-bottom: 20px;
  }
`;

const AccordionButton = styled.button`
  position: relative;
  z-index: 2;
  background: ${({ isOpen, theme }) =>
    isOpen
      ? "linear-gradient(180deg, #5252F2 0%, #3131B0 100%), #1C037F"
      : theme.palette.white};
  max-width: 456px;
  border: none;
  border-radius: 120px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  transition: background 0.3s;

  &:hover {
    transition: color 0.3s;
    background: ${({ isOpen }) =>
      isOpen
        ? "linear-gradient(180deg, #5252F2 0%, #3131B0 100%), #1C037F"
        : "#f3f3ff"};
    filter: brightness(1.1);
  }

  & > span {
    color: ${({ isOpen, theme }) => (isOpen ? theme.palette.white : "#3334B9")};
    transition: color 0.3s;
  }
`;

const IconWrap = styled(Icon)`
  width: 18px;
  height: 18px;
  @media (${device.lg}) {
    width: 24px;
    height: 24px;
  }
  & svg {
    color: ${({ isOpen, theme }) => (isOpen ? theme.palette.white : "#3334B9")};
    transition: color 0.3s;
  }
`;

const RewardCount = styled.div`
  display: flex;
  padding: 4px 15px;
  gap: 10px;
  border-radius: 90px;
  background: ${({ isOpen, theme }) =>
    isOpen ? theme.palette.white : "#3334B9"};
  transition: background 0.3s;

  & > span {
    color: ${({ isOpen, theme }) => (isOpen ? "#3334B9" : theme.palette.white)};
    transition: color 0.3s;
  }
`;

const AccordionContent = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? "900px" : "0")};
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: #eee8f4;
  padding: ${({ isOpen }) => (isOpen ? " 0 0 20px 0" : "0")};
  margin-top: -25px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
`;

export const RewardAccordion = ({ switchNetwork, chain }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { rewards = [], deposits } = useGetEscrowedRewardsWrongNetwork();
  const isEmpty = rewards.length === 0;

  const sortedRewards = rewards.sort((a, b) => {
    return parseInt(a.end) - parseInt(b.end);
  });

  if (isEmpty) return null;

  return (
    <AccordionWrapper>
      <AccordionButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <RewardCount isOpen={isOpen}>
          <Typography
            as="span"
            family="display"
            variant="textMd"
            md={{
              variant: "textXl",
            }}
            xl={{
              variant: "displayXs",
            }}>
            {sortedRewards.length}
          </Typography>
        </RewardCount>
        <Typography
          as="span"
          family="display"
          variant="textMd"
          md={{
            variant: "textXl",
          }}
          xl={{
            variant: "displayXs",
          }}>
          View escrowed rewards
        </Typography>
        <IconWrap name={isOpen ? "arrow-up" : "arrow-down"} isOpen={isOpen} />
      </AccordionButton>
      <AccordionContent isOpen={isOpen}>
        <StakingRewardsTable items={sortedRewards} deposits={deposits} />
      </AccordionContent>
    </AccordionWrapper>
  );
};
