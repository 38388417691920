import styled from "styled-components";
import { Icon } from "../base/Icon";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $size }) => $size || "36px"};
  height: ${({ $size }) => $size || "36px"};
  background: #FA5876;
  box-shadow: 0px 4px 4px 0px #FFFFFF40 inset;
  border-bottom: 2px solid #540312;
  color: #540312;
  border-radius: 50%;
`;

export function BridgeCloseIcon(props) {
  return (
    <Wrap {...props}>
      <Icon
        name="close"
        size="40%"
      />
    </Wrap>
  );
}