import { generateFont } from "./utils";

export const commonTypography = {
  display3xl: generateFont("122px", "140px"),
  display2xl: generateFont("72px", "90px"),
  displayXl: generateFont("60px", "72px"),
  displayLg: generateFont("45px", "60px"),
  displayMd: generateFont("36px", "44px"),
  displaySm: generateFont("30px", "38px"),
  displayXs: generateFont("24px", "32px"),
  textXl: generateFont("20px", "30px"),
  textLg: generateFont("18px", "28px"),
  textMd: generateFont("16px", "24px"),
  textSm: generateFont("14px", "20px"),
  textXs: generateFont("12px", "18px"),
  text2xs: generateFont("10px", "18px"),
  text3xs: generateFont("8px", "12px"),
}