import { Link, useLocation, useMatch } from "react-router-dom";
import { Icon } from "../base/Icon";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { NavMenu } from "./NavMenu";
import { useCloseOnOutsideClick } from "../../hooks/UseCloseOnOutsideClick";
import { device } from "../../theme/mediaQuery";

const NavItemWrap = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  gap: 10px;
  padding: 0 16px;
  border-radius: 16px;
  min-height: 44px;
  width: 100%;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.white};

  // background: transparent;
  background: #1b027d;
  border: none;
  transition: background 0.2s ease-in-out;
  cursor: pointer;

  ${({ $active, theme }) =>
    $active &&
    `
    background: ${theme.palette.darkBlue500};
  `}
  &:hover {
    background: ${({ theme }) => theme.palette.darkBlue500};
    color: ${({ theme }) => theme.palette.white};
  }

  &::after {
    content: "";
    position: absolute;
    background: linear-gradient(
        180deg,
        ${({ theme }) => theme.palette.darkBlue500} 0%,
        rgba(0, 0, 0, 0) 100%
      )
      no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 1px;
    z-index: -1;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  @media (${device.xxl}) {
    width: auto;
  }
`;

const NavItemContainer = styled.div`
  position: ${({ $isFullSizeMenu }) =>
    $isFullSizeMenu ? "static" : "relative"};
`;

const BridgeNavItemWrap = styled(NavItemWrap)`
  background: transparent;

  &::after {
    background: linear-gradient(
        180deg,
        #d431fd 0%,
        #5e16a9 60%,
        rgba(0, 0, 0, 0) 100%
      )
      no-repeat;
  }

  :hover {
    background: #b138e6;
  }

  ${({ $active }) => $active && `background: #B138E6;`}
`;

const StakingNavItemWrap = styled(NavItemWrap)`
  background: transparent;

  &::after {
    background: linear-gradient(
        180deg,
        #b656fc 0%,
        #54299b 60%,
        rgba(0, 0, 0, 0) 100%
      )
      no-repeat;
  }

  :hover {
    background: #b138e6;
  }

  ${({ $active }) => $active && `background: #B138E6;`}
`;

const BlueNavItemWrap = styled(NavItemWrap)`
  background: transparent;
  &::after {
    background: linear-gradient(
        180deg,
        #5c74fe 0%,
        #2a3586 60%,
        rgba(0, 0, 0, 0) 100%
      )
      no-repeat;
  }
  :hover {
    background: #14193f;
  }
  ${({ $active }) => $active && `background: #B138E6;`}
`;

const LicensingNavItemWrap = styled(NavItemWrap)`
  background: transparent;

  &::after {
    background: linear-gradient(
        180deg,
        #5c74fe 0%,
        #2a3586 60%,
        rgba(0, 0, 0, 0) 100%
      )
      no-repeat;
  }

  :hover {
    background: #14193f;
  }
`;
const ClaimNavItemWrap = styled(NavItemWrap)`
  background: transparent;

  &::after {
    background: transparent
  }

  :hover {
    background: #14193f;
  }
`;

function NavItemLink({ path, children, ...rest }) {
  const isActive = useMatch(path);

  return (
    <Link to={path}>
      <NavItemWrap $active={isActive} {...rest}>
        {children}
      </NavItemWrap>
    </Link>
  );
}

export function NavItem({
  title,
  path,
  appendIcon,
  prependIcon,
  renderMenu = null,
  isFullSizeMenu,
  active,
  prependComponent,
  ...rest
}) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const handleClick = () => {
    if (renderMenu) {
      setShowMenu((prevState) => !prevState);
    }
  };
  const location = useLocation();
  // watch if route changes and close menu
  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);

  const isBridge = useMatch("/bridge");
  const isStaking = useMatch("/staking/*");
  const isAirdrop = useMatch("/airdrop/");
  const isClaim = useMatch("/claim/");
  const isLicensing = useMatch("/licensing/*");
  let NavItemComponent = NavItemWrap;

  if (isBridge) {
    NavItemComponent = BridgeNavItemWrap;
  } else if (isStaking) {
    NavItemComponent = StakingNavItemWrap;
  } else if (isAirdrop) {
    NavItemComponent = BlueNavItemWrap;
  } else if (isLicensing) {
    NavItemComponent = LicensingNavItemWrap;
  } else if (isClaim) {
    NavItemComponent = ClaimNavItemWrap;
  }

  const renderContent = () => {
    return (
      <>
        {prependComponent}
        {prependIcon && <Icon name={prependIcon} width={10} />}
        {title}
        {appendIcon && (
          <Icon className="ms-auto" name={appendIcon} width={10} />
        )}
      </>
    );
  };

  useCloseOnOutsideClick(menuRef, () => setShowMenu(false), {
    includeParent: true,
  });

  if (path) {
    return (
      <NavItemLink as={NavItemComponent} path={path}>
        {renderContent()}
      </NavItemLink>
    );
  }

  return (
    <NavItemContainer $isFullSizeMenu={isFullSizeMenu}>
      <NavItemComponent
        $active={showMenu || active}
        as={"button"}
        onClick={handleClick}
        {...rest}
      >
        {renderContent()}
      </NavItemComponent>
      {renderMenu && (
        <NavMenu
          isFullSizeMenu={isFullSizeMenu}
          menuRef={menuRef}
          active={showMenu}
        >
          {renderMenu()}
        </NavMenu>
      )}
    </NavItemContainer>
  );
}
