import styled from "styled-components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { device } from "../../../theme/mediaQuery";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  @media (${device.sm}) {
    align-items: center;
    flex-direction: row;
  }
`;

const ImageWrap = styled.div`
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, #E7B610 0%, #D77F01 100%);
  z-index: 1;

  & img {
    width: 90%;
    height: 90%;
    position: relative;
    z-index: 2;
    border-radius: 50%;
  }

  &:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    background: #231852;
    border-radius: 50%;
  }

  @media (${device.lg}) {
    min-width: 52px;
    height: 52px;
  }
`;

const Title = styled.h3`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};

`;

const IdWrap = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.body};
`;

const TextWrap = styled.div`
  width: 100%;
`;

export function StakingBadgeAvatar({ badge }) {
  const { image, name, poolId } = badge;

  const renderTooltip = (props) => (
    <Tooltip id="badge-tooltip" {...props}>
      {name}
    </Tooltip>
  );

  return (
    <Wrap>
      <ImageWrap>
        <img src={image} alt={name} />
      </ImageWrap>
      <TextWrap>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <Title>{name}</Title>
        </OverlayTrigger>

        <IdWrap>Pool ID: {poolId}</IdWrap>
      </TextWrap>
    </Wrap>
  );
}