import { Image } from "react-bootstrap";
import Web3 from "web3";
import shadowcornABI from "../../CU_Shadowcorn_ABI.json";
import GofpABI from "../../CU_GOFP_ABI.json";
import { POLYGON_NETWORK } from "../ConnectWallet/WalletHelper";
import { NetworkConfiguration } from "../Network";
import { Typography } from "../base/Typography";
import { Tooltip } from "../base/Tooltip";

const netconfig = NetworkConfiguration[POLYGON_NETWORK];

// This function calculates the reward given a config, which must be an array of objects corresponding
// to the rewards in order. Each object should include a threshold and a reward field.
export function calculateReward(score, config) {
  let reward = 0;

  config.forEach((data) => {
    if (score >= data.threshold) {
      reward += data.reward;
    }
  });

  return reward;
}

// This function calculates the milestone given a config, which must be an array of objects corresponding
// to the rewards in order. Each object should include a threshold and a milestone field. Unlike
// calculateReward the rewards don't accumulate and they are a string
export function calculateMilestone(score, config) {
  for (let i = config.length - 1; i >= 0; i--) {
    const state = config[i];
    if (score >= state.threshold) {
      return state.milestone;
    }
  }
  return "0";
}

// This function calculates the points remaining given a config eg. unicornPartyConfig
export function calculateRemainingPoints(score, config) {
  const nextThreshold = config.find((item) => item.threshold > score);

  // calculate the remaining points if there is a next threshold
  if (nextThreshold) {
    return nextThreshold.threshold - score;
  }

  // final threshold if there is no next threshold
  return 0;
}

// Staking Lockdrop
export const stakingLockdrop = [
  {
    threshold: 0,
    milestone: 12, // scores 0-25
  },
  {
    threshold: 26,
    milestone: 6, // scores 26-100
  },
  {
    threshold: 101,
    milestone: 3, // scores 101-200
  },
  {
    threshold: 201,
    milestone: 0, // scores 201 and above
  },
];

// UNIM
export const unimConfig = [
  {
    threshold: 70,
    reward: 500,
  },
  {
    threshold: 150,
    reward: 1000,
  },
  {
    threshold: 250,
    reward: 2500,
  },
  {
    threshold: 500,
    reward: 5000,
  },
  {
    threshold: 1000,
    reward: 10000,
  },
];

export const milestoneConfig = [
  {
    threshold: 70,
    milestone: "1",
  },
  {
    threshold: 150,
    milestone: "2",
  },
  {
    threshold: 250,
    milestone: "3",
  },
  {
    threshold: 500,
    milestone: "4",
  },
  {
    threshold: 1000,
    milestone: "5",
  },
];

// For Winter Event
export const lootboxM1Config = [
  {
    threshold: 350,
    reward: 1,
  },
  {
    threshold: 750,
    reward: 2,
  },
  {
    threshold: 1250,
    reward: 4,
  },
  {
    threshold: 2500,
    reward: 7,
  },
  {
    threshold: 5000,
    reward: 10,
  },
];

export const lootboxM2Config = [
  {
    threshold: 700,
    reward: 1,
  },
  {
    threshold: 1500,
    reward: 2,
  },
  {
    threshold: 2500,
    reward: 4,
  },
  {
    threshold: 5000,
    reward: 7,
  },
  {
    threshold: 10000,
    reward: 10,
  },
];

export const lootboxM3Config = [
  {
    threshold: 70,
    reward: 1,
  },
  {
    threshold: 150,
    reward: 2,
  },
  {
    threshold: 250,
    reward: 3,
  },
  {
    threshold: 500,
    reward: 4,
  },
  {
    threshold: 1000,
    reward: 5,
  },
];

export const commonLootboxM3Config = [
  {
    threshold: 700,
    reward: 1,
  },
];

// For Unicorn Party Bingo
export const bingoConfig = [
  {
    threshold: 0,
    reward: 0,
  },
  {
    threshold: 500,
    reward: 1,
  },
  {
    threshold: 1000,
    reward: 3,
  },
  {
    threshold: 2500,
    reward: 7,
  },
  {
    threshold: 5000,
    reward: 15,
  },
];

export const unicornMilestoneConfig = [
  {
    threshold: 1000,
    milestone: "1",
  },
  {
    threshold: 2500,
    milestone: "2",
  },
  {
    threshold: 5000,
    milestone: "3",
  },
  {
    threshold: 10000,
    milestone: "4",
  },
  {
    threshold: 25000,
    milestone: "5",
  },
];

// For Summer Event
export const summerRareLootboxConfig = [
  {
    threshold: 75,
    reward: 1,
  },
  {
    threshold: 150,
    reward: 2,
  },
];
export const summerCommonLootboxConfig = [
  {
    threshold: 250,
    reward: 1,
  },
  {
    threshold: 500,
    reward: 2,
  },
  {
    threshold: 1250,
    reward: 4,
  },
];
export const summerMilestoneConfig = [
  {
    threshold: 75,
    milestone: "1",
  },
  {
    threshold: 150,
    milestone: "2",
  },
  {
    threshold: 250,
    milestone: "3",
  },
  {
    threshold: 500,
    milestone: "4",
  },
  {
    threshold: 1250,
    milestone: "5",
  },
];

// For Airdrop
export const airdropConfig = [
  {
    threshold: 0,
    milestone: 0,
  },
  {
    threshold: 100,
    milestone: 1,
  },
  {
    threshold: 200,
    milestone: 2,
  },
  {
    threshold: 300,
    milestone: 3,
  },
  {
    threshold: 400,
    milestone: 4,
  },
  {
    threshold: 500,
    milestone: 5,
  },
  {
    threshold: 600,
    milestone: 6,
  },
  {
    threshold: 700,
    milestone: 7,
  },
  {
    threshold: 800,
    milestone: 8,
  },
  {
    threshold: 900,
    milestone: 9,
  },
  {
    threshold: 1000,
    milestone: 10,
  },
];

// For Clans Event
export const clansIndividualRewards = [
  {
    rankRange: [1, 1],
    rewards: [{ count: 3, type: "rbw", category: "", quantity: 20000 }],
  },
  {
    rankRange: [2, 2],
    rewards: [{ count: 2, type: "rbw", category: "", quantity: 18000 }],
  },
  {
    rankRange: [3, 3],
    rewards: [{ count: 2, type: "rbw", category: "", quantity: 16000 }],
  },
  {
    rankRange: [4, 5],
    rewards: [{ count: 2, type: "rbw", category: "", quantity: 14000 }],
  },
  {
    rankRange: [6, 7],
    rewards: [{ count: 2, type: "rbw", category: "", quantity: 12000 }],
  },
  {
    rankRange: [8, 10],
    rewards: [{ count: 2, type: "rbw", category: "", quantity: 10000 }],
  },
  {
    rankRange: [11, 15],
    rewards: [{ count: 1, type: "rbw", category: "", quantity: 9000 }],
  },
  {
    rankRange: [16, 20],
    rewards: [{ count: 1, type: "rbw", category: "", quantity: 8000 }],
  },
  {
    rankRange: [21, 25],
    rewards: [{ count: 1, type: "rbw", category: "", quantity: 7000 }],
  },
  {
    rankRange: [26, 30],
    rewards: [{ count: 1, type: "rbw", category: "", quantity: 6000 }],
  },
  {
    rankRange: [31, 40],
    rewards: [{ count: 1, type: "rbw", category: "", quantity: 5000 }],
  },
  {
    rankRange: [41, 50],
    rewards: [{ count: 1, type: "rbw", category: "", quantity: 4000 }],
  },
  {
    rankRange: [51, 75],
    rewards: [{ count: 1, type: "rbw", category: "", quantity: 3000 }],
  },
];

export const clansRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 1, type: "badge", category: "tier3", klass: "Clans Event" },
      { count: 150, type: "unicorn", category: "adult" },
      { count: 30, type: "land", category: "" },
      { count: 1, type: "shadowcorn", category: "common" },
      { count: 2, type: "shadowcorn", category: "rare" },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      {
        count: 1,
        type: "badge",
        category: "first_tier2",
        klass: "Clans Event",
      },
      { count: 125, type: "unicorn", category: "adult" },
      { count: 24, type: "land", category: "" },
      { count: 2, type: "shadowcorn", category: "common" },
      { count: 1, type: "shadowcorn", category: "rare" },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      {
        count: 1,
        type: "badge",
        category: "second_tier2",
        klass: "Clans Event",
      },
      { count: 100, type: "unicorn", category: "adult" },
      { count: 18, type: "land", category: "" },
      { count: 2, type: "shadowcorn", category: "common" },
    ],
  },
  {
    rankRange: [4, 4],
    rewards: [
      { count: 1, type: "badge", category: "tier1", klass: "Clans Event" },
      { count: 75, type: "unicorn", category: "adult" },
      { count: 14, type: "land", category: "" },
      { count: 1, type: "shadowcorn", category: "common" },
    ],
  },
  {
    rankRange: [5, 5],
    rewards: [
      { count: 1, type: "badge", category: "tier1", klass: "Clans Event" },
      { count: 60, type: "unicorn", category: "adult" },
      { count: 12, type: "land", category: "" },
    ],
  },
  {
    rankRange: [6, 6],
    rewards: [
      { count: 1, type: "badge", category: "tier1", klass: "Clans Event" },
      { count: 50, type: "unicorn", category: "adult" },
      { count: 10, type: "land", category: "" },
    ],
  },
  {
    rankRange: [7, 7],
    rewards: [
      { count: 1, type: "badge", category: "tier1", klass: "Clans Event" },
      { count: 40, type: "unicorn", category: "adult" },
      { count: 8, type: "land", category: "" },
    ],
  },
  {
    rankRange: [8, 8],
    rewards: [
      { count: 1, type: "badge", category: "tier1", klass: "Clans Event" },
      { count: 30, type: "unicorn", category: "adult" },
      { count: 6, type: "land", category: "" },
    ],
  },
  {
    rankRange: [9, 9],
    rewards: [
      { count: 1, type: "badge", category: "tier1", klass: "Clans Event" },
      { count: 20, type: "unicorn", category: "adult" },
      { count: 4, type: "land", category: "" },
    ],
  },
  {
    rankRange: [10, 10],
    rewards: [
      { count: 1, type: "badge", category: "tier1", klass: "Clans Event" },
      { count: 10, type: "unicorn", category: "adult" },
      { count: 2, type: "land", category: "" },
    ],
  },
];

// For Tug of War
export const TugOfWarUnicornRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 3, type: "crystals", category: "rift", quantity: 10000 },
      { count: 1, type: "questBundle", category: "III" },
      {
        count: 1,
        type: "xai",
        category: "",
        quantity: 2500,
        multiplier: 2,
      },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 2, type: "crystals", category: "rift", quantity: 8000 },
      { count: 1, type: "questBundle", category: "III" },
      { count: 1, type: "xai", category: "", quantity: 1500, multiplier: 2 },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 2, type: "crystals", category: "rift", quantity: 6000 },
      { count: 1, type: "questBundle", category: "III" },
      { count: 1, type: "xai", category: "", quantity: 1000, multiplier: 2 },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 2, type: "crystals", category: "rift", quantity: 5000 },
      { count: 1, type: "questBundle", category: "III" },
      { count: 1, type: "xai", category: "", quantity: 750, multiplier: 2 },
    ],
  },
  {
    rankRange: [6, 7],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 4500 },
      { count: 1, type: "questBundle", category: "III" },
      { count: 1, type: "xai", category: "", quantity: 500, multiplier: 2 },
    ],
  },
  {
    rankRange: [8, 10],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 4000 },
      { count: 1, type: "questBundle", category: "III" },
      { count: 1, type: "xai", category: "", quantity: 400, multiplier: 2 },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 3500 },
      { count: 1, type: "questBundle", category: "II" },
      { count: 1, type: "xai", category: "", quantity: 300, multiplier: 2 },
    ],
  },
  {
    rankRange: [16, 20],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 3000 },
      { count: 1, type: "questBundle", category: "II" },
      { count: 1, type: "xai", category: "", quantity: 250, multiplier: 2 },
    ],
  },
  {
    rankRange: [21, 30],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 2500 },
      { count: 1, type: "questBundle", category: "II" },
      { count: 1, type: "xai", category: "", quantity: 200, multiplier: 2 },
    ],
  },
  {
    rankRange: [31, 50],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 2000 },
      { count: 1, type: "questBundle", category: "II" },
      { count: 1, type: "xai", category: "", quantity: 140, multiplier: 2 },
    ],
  },
  {
    rankRange: [51, 100],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 1500 },
      { count: 1, type: "questBundle", category: "I" },
      { count: 1, type: "xai", category: "", quantity: 75, multiplier: 2 },
    ],
  },
  {
    rankRange: [101, 200],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 1000 },
      { count: 1, type: "questBundle", category: "I" },
      { count: 1, type: "xai", category: "", quantity: 50, multiplier: 2 },
    ],
  },
];

export const TugOfWarShadowcornRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 3, type: "crystals", category: "rift", quantity: 10000 },
      { count: 1, type: "unim", category: "", quantity: 3000000 },
      { count: 1, type: "xai", category: "", quantity: 3000, multiplier: 2 },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 2, type: "crystals", category: "rift", quantity: 7500 },
      { count: 1, type: "unim", category: "", quantity: 2000000 },
      { count: 1, type: "xai", category: "", quantity: 2000, multiplier: 2 },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 2, type: "crystals", category: "rift", quantity: 5000 },
      { count: 1, type: "unim", category: "", quantity: 1500000 },
      { count: 1, type: "xai", category: "", quantity: 1000, multiplier: 2 },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 2, type: "crystals", category: "rift", quantity: 4500 },
      { count: 1, type: "unim", category: "", quantity: 1000000 },
      { count: 1, type: "xai", category: "", quantity: 800, multiplier: 2 },
    ],
  },
  {
    rankRange: [6, 7],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 4000 },
      { count: 1, type: "unim", category: "", quantity: 800000 },
      { count: 1, type: "xai", category: "", quantity: 600, multiplier: 2 },
    ],
  },
  {
    rankRange: [8, 10],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 3500 },
      { count: 1, type: "unim", category: "", quantity: 600000 },
      { count: 1, type: "xai", category: "", quantity: 500, multiplier: 2 },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 3000 },
      { count: 1, type: "unim", category: "", quantity: 500000 },
      { count: 1, type: "xai", category: "", quantity: 400, multiplier: 2 },
    ],
  },
  {
    rankRange: [16, 20],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 2500 },
      { count: 1, type: "unim", category: "", quantity: 400000 },
      { count: 1, type: "xai", category: "", quantity: 320, multiplier: 2 },
    ],
  },
  {
    rankRange: [21, 30],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 2000 },
      { count: 1, type: "unim", category: "", quantity: 225000 },
      { count: 1, type: "xai", category: "", quantity: 250, multiplier: 2 },
    ],
  },
  {
    rankRange: [31, 50],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 1500 },
      { count: 1, type: "unim", category: "", quantity: 105000 },
      { count: 1, type: "xai", category: "", quantity: 180, multiplier: 2 },
    ],
  },
  {
    rankRange: [51, 100],
    rewards: [
      { count: 1, type: "crystals", category: "rift", quantity: 1000 },
      { count: 1, type: "unim", category: "", quantity: 50000 },
      { count: 1, type: "xai", category: "", quantity: 100, multiplier: 2 },
    ],
  },
];

// For Twilight Tactics
export const twilightTacticsUnicornRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 25000 },
      { count: 12, type: "lootbox", category: "mythic" },
      { count: 12, type: "lootbox", category: "spring" },
      // { count: 1, type: "badge", category: "tier3", klass: "Unicorn" },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 14500 },
      { count: 10, type: "lootbox", category: "mythic" },
      { count: 10, type: "lootbox", category: "spring" },
      // { count: 1, type: "badge", category: "tier2", klass: "Unicorn" },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 12000 },
      { count: 8, type: "lootbox", category: "mythic" },
      { count: 8, type: "lootbox", category: "spring" },
      // { count: 1, type: "badge", category: "tier2", klass: "Unicorn" },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 9000 },
      { count: 6, type: "lootbox", category: "mythic" },
      { count: 6, type: "lootbox", category: "spring" },
      // { count: 1, type: "badge", category: "tier2", klass: "Unicorn" },
    ],
  },
  {
    rankRange: [6, 7],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 7000 },
      { count: 4, type: "lootbox", category: "mythic" },
      { count: 4, type: "lootbox", category: "spring" },
      // { count: 1, type: "badge", category: "tier2", klass: "Unicorn" },
    ],
  },
  {
    rankRange: [8, 10],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 5500 },
      { count: 12, type: "lootbox", category: "rare" },
      { count: 12, type: "lootbox", category: "dark_forest_unofficial" },
      // { count: 1, type: "badge", category: "tier1", klass: "Unicorn" },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 3500 },
      { count: 10, type: "lootbox", category: "rare" },
      { count: 10, type: "lootbox", category: "dark_forest_unofficial" },
      // { count: 1, type: "badge", category: "tier1", klass: "Unicorn" },
    ],
  },
  {
    rankRange: [16, 20],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 2500 },
      { count: 5, type: "lootbox", category: "rare" },
      { count: 5, type: "lootbox", category: "dark_forest_unofficial" },
      // { count: 1, type: "badge", category: "tier1", klass: "Unicorn" },
    ],
  },
  {
    rankRange: [21, 30],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 2000 },
      { count: 15, type: "lootbox", category: "common" },
      { count: 15, type: "lootbox", category: "land" },
      // { count: 1, type: "badge", category: "tier1", klass: "Unicorn" },
    ],
  },
  {
    rankRange: [31, 50],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 1250 },
      { count: 10, type: "lootbox", category: "common" },
      { count: 10, type: "lootbox", category: "land" },
      // { count: 1, type: "badge", category: "tier1", klass: "Unicorn" },
    ],
  },
  {
    rankRange: [51, 100],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 1000 },
      { count: 5, type: "lootbox", category: "common" },
      { count: 5, type: "lootbox", category: "land" },
      // { count: 1, type: "badge", category: "tier1", klass: "Unicorn" },
    ],
  },
  {
    rankRange: [101, 200],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 750 },
      { count: 3, type: "lootbox", category: "common" },
      { count: 3, type: "lootbox", category: "land" },
    ],
  },
];
export const twilightTacticsShadowcornRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 25000 },
      { count: 600, type: "lootbox", category: "shadowcorn_fire" },
      // { count: 1, type: "badge", category: "tier3", klass: "Shadowcorn" },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 20000 },
      { count: 500, type: "lootbox", category: "shadowcorn_slime" },
      // { count: 1, type: "badge", category: "tier3", klass: "Shadowcorn" },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 14000 },
      { count: 400, type: "lootbox", category: "shadowcorn_volt" },
      // { count: 1, type: "badge", category: "tier3", klass: "Shadowcorn" },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 11000 },
      { count: 300, type: "lootbox", category: "shadowcorn_soul" },
      // { count: 1, type: "badge", category: "tier3", klass: "Shadowcorn" },
    ],
  },
  {
    rankRange: [6, 7],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 8000 },
      { count: 200, type: "lootbox", category: "shadowcorn_nebula" },
      // { count: 1, type: "badge", category: "tier3", klass: "Shadowcorn" },
    ],
  },
  {
    rankRange: [8, 10],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 6000 },
      { count: 100, type: "lootbox", category: "shadowcorn_fire" },
      // { count: 1, type: "badge", category: "tier3", klass: "Shadowcorn" },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 5000 },
      { count: 90, type: "lootbox", category: "shadowcorn_slime" },
      // { count: 1, type: "badge", category: "tier3", klass: "Shadowcorn" },
    ],
  },
  {
    rankRange: [16, 20],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 3500 },
      { count: 80, type: "lootbox", category: "shadowcorn_volt" },
      // { count: 1, type: "badge", category: "tier3", klass: "Shadowcorn" },
    ],
  },
  {
    rankRange: [21, 30],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 3000 },
      { count: 30, type: "lootbox", category: "shadowcorn_soul" },
      // { count: 1, type: "badge", category: "tier3", klass: "Shadowcorn" },
    ],
  },
  {
    rankRange: [31, 50],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 2500 },
      { count: 20, type: "lootbox", category: "shadowcorn_nebula" },
      // { count: 1, type: "badge", category: "tier3", klass: "Shadowcorn" },
    ],
  },
  {
    rankRange: [51, 100],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 1250 },
      { count: 10, type: "lootbox", category: "shadowcorn_volt" },
    ],
  },
];

// For Collections Event
export const collectionsRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 50000 },
      { count: 10, type: "lootbox", category: "winter" },
      { count: 7, type: "lootbox", category: "mythic" },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 45000 },
      { count: 10, type: "lootbox", category: "winter" },
      { count: 6, type: "lootbox", category: "mythic" },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 40000 },
      { count: 9, type: "lootbox", category: "winter" },
      { count: 5, type: "lootbox", category: "mythic" },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 35000 },
      { count: 9, type: "lootbox", category: "winter" },
      { count: 4, type: "lootbox", category: "mythic" },
    ],
  },
  {
    rankRange: [6, 7],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 30000 },
      { count: 8, type: "lootbox", category: "winter" },
      { count: 3, type: "lootbox", category: "mythic" },
    ],
  },
  {
    rankRange: [8, 10],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 25000 },
      { count: 8, type: "lootbox", category: "winter" },
      { count: 2, type: "lootbox", category: "mythic" },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 20000 },
      { count: 7, type: "lootbox", category: "winter" },
      { count: 4, type: "lootbox", category: "rare" },
    ],
  },
  {
    rankRange: [16, 20],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 15000 },
      { count: 7, type: "lootbox", category: "winter" },
      { count: 3, type: "lootbox", category: "rare" },
    ],
  },
  {
    rankRange: [21, 25],
    rewards: [
      { count: 2, type: "rbw", category: "", quantity: 10000 },
      { count: 6, type: "lootbox", category: "winter" },
      { count: 2, type: "lootbox", category: "rare" },
    ],
  },
  {
    rankRange: [26, 50],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 7500 },
      { count: 6, type: "lootbox", category: "winter" },
      { count: 4, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [51, 75],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 5000 },
      { count: 5, type: "lootbox", category: "winter" },
      { count: 4, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [76, 100],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 2500 },
      { count: 5, type: "lootbox", category: "winter" },
      { count: 3, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [101, 150],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 2000 },
      { count: 4, type: "lootbox", category: "winter" },
      { count: 3, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [151, 200],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 1500 },
      { count: 3, type: "lootbox", category: "winter" },
      { count: 2, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [201, 300],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 1000 },
      { count: 2, type: "lootbox", category: "winter" },
      { count: 2, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [301, 400],
    rewards: [
      { count: 1, type: "rbw", category: "", quantity: 500 },
      { count: 1, type: "lootbox", category: "winter" },
      { count: 1, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [401, 500],
    rewards: [
      { count: 1, type: "lootbox", category: "winter" },
      { count: 1, type: "lootbox", category: "common" },
    ],
  },
];
export const collectionsMilestoneConfig = [
  {
    threshold: 100,
    milestone: "1",
  },
  {
    threshold: 250,
    milestone: "2",
  },
  {
    threshold: 500,
    milestone: "3",
  },
  {
    threshold: 1000,
    milestone: "4",
  },
  {
    threshold: 2500,
    milestone: "5",
  },
];

// For Tribes
export const tribesIndividualRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 10, type: "lootbox", category: "mythic" },
      { count: 1, type: "lootbox", category: "rare" },
      { count: 1, type: "xai", category: "", quantity: 1500 },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 7, type: "lootbox", category: "mythic" },
      { count: 3, type: "lootbox", category: "rare" },
      { count: 1, type: "xai", category: "", quantity: 1250 },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 5, type: "lootbox", category: "mythic" },
      { count: 5, type: "lootbox", category: "rare" },
      { count: 1, type: "xai", category: "", quantity: 1000 },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 3, type: "lootbox", category: "mythic" },
      { count: 7, type: "lootbox", category: "rare" },
      { count: 1, type: "xai", category: "", quantity: 750 },
    ],
  },
  {
    rankRange: [6, 10],
    rewards: [
      { count: 1, type: "lootbox", category: "mythic" },
      { count: 10, type: "lootbox", category: "rare" },
      { count: 1, type: "xai", category: "", quantity: 500 },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 10, type: "lootbox", category: "rare" },
      { count: 1, type: "xai", category: "", quantity: 375 },
    ],
  },
  {
    rankRange: [16, 25],
    rewards: [
      { count: 7, type: "lootbox", category: "rare" },
      { count: 1, type: "xai", category: "", quantity: 250 },
    ],
  },
  {
    rankRange: [26, 50],
    rewards: [
      { count: 5, type: "lootbox", category: "rare" },
      { count: 1, type: "xai", category: "", quantity: 125 },
    ],
  },
  {
    rankRange: [51, 100],
    rewards: [
      { count: 3, type: "lootbox", category: "rare" },
      { count: 1, type: "xai", category: "", quantity: 100 },
    ],
  },
];

// For Unicorn Party Bingo
export const bingoRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 2500 },
      { count: 1, type: "xai", category: "", quantity: 5000 },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 1250 },
      { count: 1, type: "xai", category: "", quantity: 2500 },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 600 },
      { count: 1, type: "xai", category: "", quantity: 1250 },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 300 },
      { count: 1, type: "xai", category: "", quantity: 600 },
    ],
  },
  {
    rankRange: [6, 10],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 150 },
      { count: 1, type: "xai", category: "", quantity: 300 },
    ],
  },
  {
    rankRange: [11, 20],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 80 },
      { count: 1, type: "xai", category: "", quantity: 150 },
    ],
  },
  {
    rankRange: [21, 45],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 40 },
      { count: 1, type: "xai", category: "", quantity: 80 },
    ],
  },
  {
    rankRange: [46, 95],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 20 },
      { count: 1, type: "xai", category: "", quantity: 40 },
    ],
  },
  {
    rankRange: [96, 170],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 10 },
      { count: 1, type: "xai", category: "", quantity: 20 },
    ],
  },
  {
    rankRange: [171, 270],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 5 },
      { count: 1, type: "xai", category: "", quantity: 10 },
    ],
  },
  {
    rankRange: [271, 395],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 2 },
      { count: 1, type: "xai", category: "", quantity: 5 },
    ],
  },
];

// For Staking
export const stakingRewardsEnding = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 2500 },
      { count: 1, type: "xai", category: "", quantity: 5000 },
      { count: 1, type: "lockdrop", category: "12" },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 2000 },
      { count: 1, type: "xai", category: "", quantity: 4500 },
      { count: 1, type: "lockdrop", category: "12" },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 1750 },
      { count: 1, type: "xai", category: "", quantity: 4000 },
      { count: 1, type: "lockdrop", category: "12" },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 1500 },
      { count: 1, type: "xai", category: "", quantity: 3500 },
      { count: 1, type: "lockdrop", category: "12" },
    ],
  },
  {
    rankRange: [6, 7],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 1250 },
      { count: 1, type: "xai", category: "", quantity: 3000 },
      { count: 1, type: "lockdrop", category: "12" },
    ],
  },
  {
    rankRange: [8, 10],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 1000 },
      { count: 1, type: "xai", category: "", quantity: 2500 },
      { count: 1, type: "lockdrop", category: "12" },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 750 },
      { count: 1, type: "xai", category: "", quantity: 2000 },
      { count: 1, type: "lockdrop", category: "12" },
    ],
  },
  {
    rankRange: [16, 20],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 500 },
      { count: 1, type: "xai", category: "", quantity: 1500 },
      { count: 1, type: "lockdrop", category: "12" },
    ],
  },
  {
    rankRange: [21, 25],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 400 },
      { count: 1, type: "xai", category: "", quantity: 1000 },
      { count: 1, type: "lockdrop", category: "12" },
    ],
  },
  {
    rankRange: [26, 50],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 300 },
      { count: 1, type: "xai", category: "", quantity: 500 },
      { count: 1, type: "lockdrop", category: "6" },
    ],
  },
  {
    rankRange: [51, 75],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 200 },
      { count: 1, type: "xai", category: "", quantity: 250 },
      { count: 1, type: "lockdrop", category: "6" },
    ],
  },
  {
    rankRange: [76, 100],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 100 },
      { count: 1, type: "xai", category: "", quantity: 100 },
      { count: 1, type: "lockdrop", category: "6" },
    ],
  },
  {
    rankRange: [101, 150],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 50 },
      { count: 1, type: "xai", category: "", quantity: 50 },
      { count: 1, type: "lockdrop", category: "3" },
    ],
  },
  {
    rankRange: [151, 200],
    rewards: [
      { count: 1, type: "xai", category: "", quantity: 25 },
      { count: 1, type: "lockdrop", category: "3" },
    ],
  },
  {
    rankRange: [201, 300],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 5 }],
  },
];

export const stakingRewardsMay = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 1250 },
      { count: 1, type: "xai", category: "", quantity: 2500 },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 1000 },
      { count: 1, type: "xai", category: "", quantity: 2250 },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 875 },
      { count: 1, type: "xai", category: "", quantity: 2000 },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 750 },
      { count: 1, type: "xai", category: "", quantity: 1750 },
    ],
  },
  {
    rankRange: [6, 7],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 625 },
      { count: 1, type: "xai", category: "", quantity: 1500 },
    ],
  },
  {
    rankRange: [8, 10],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 500 },
      { count: 1, type: "xai", category: "", quantity: 1250 },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 375 },
      { count: 1, type: "xai", category: "", quantity: 1000 },
    ],
  },
  {
    rankRange: [16, 20],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 250 },
      { count: 1, type: "xai", category: "", quantity: 750 },
    ],
  },
  {
    rankRange: [21, 25],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 200 },
      { count: 1, type: "xai", category: "", quantity: 500 },
    ],
  },
  {
    rankRange: [26, 50],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 150 },
      { count: 1, type: "xai", category: "", quantity: 250 },
    ],
  },
  {
    rankRange: [51, 75],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 100 },
      { count: 1, type: "xai", category: "", quantity: 125 },
    ],
  },
  {
    rankRange: [76, 100],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 50 },
      { count: 1, type: "xai", category: "", quantity: 50 },
    ],
  },
  {
    rankRange: [101, 150],
    rewards: [
      { count: 1, type: "cu", category: "", quantity: 25 },
      { count: 1, type: "xai", category: "", quantity: 25 },
    ],
  },
  {
    rankRange: [151, 200],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 15 }],
  },
  {
    rankRange: [201, 300],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 5 }],
  },
];

export const tribesRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 3, type: "rolls", category: "unicorn", quantity: 3 },
      { count: 1, type: "rolls", category: "usdc", quantity: 15 },
      { count: 1, type: "xai", category: "", quantity: 15000 },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 2, type: "rolls", category: "unicorn", quantity: 2 },
      { count: 1, type: "rolls", category: "usdc", quantity: 14 },
      { count: 1, type: "xai", category: "", quantity: 12500 },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 1, type: "rolls", category: "unicorn", quantity: 1 },
      { count: 1, type: "rolls", category: "usdc", quantity: 13 },
      { count: 1, type: "xai", category: "", quantity: 10000 },
    ],
  },
  {
    rankRange: [4, 4],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 12 },
      { count: 1, type: "xai", category: "", quantity: 7500 },
    ],
  },
  {
    rankRange: [5, 5],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 11 },
      { count: 1, type: "xai", category: "", quantity: 6250 },
    ],
  },
  {
    rankRange: [6, 6],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 10 },
      { count: 1, type: "xai", category: "", quantity: 5000 },
    ],
  },
  {
    rankRange: [7, 7],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 9 },
      { count: 1, type: "xai", category: "", quantity: 4000 },
    ],
  },
  {
    rankRange: [8, 8],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 8 },
      { count: 1, type: "xai", category: "", quantity: 3000 },
    ],
  },
  {
    rankRange: [9, 9],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 7 },
      { count: 1, type: "xai", category: "", quantity: 2500 },
    ],
  },
  {
    rankRange: [10, 10],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 6 },
      { count: 1, type: "xai", category: "", quantity: 2000 },
    ],
  },
  {
    rankRange: [11, 11],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 5 },
      { count: 1, type: "xai", category: "", quantity: 1500 },
    ],
  },
  {
    rankRange: [12, 12],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 4 },
      { count: 1, type: "xai", category: "", quantity: 1000 },
    ],
  },
  {
    rankRange: [13, 13],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 3 },
      { count: 1, type: "xai", category: "", quantity: 750 },
    ],
  },
  {
    rankRange: [14, 14],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 2 },
      { count: 1, type: "xai", category: "", quantity: 500 },
    ],
  },
  {
    rankRange: [15, 15],
    rewards: [
      { count: 1, type: "rolls", category: "usdc", quantity: 1 },
      { count: 1, type: "xai", category: "", quantity: 250 },
    ],
  },
];

// For Rally
export const rallyRewards = [
  {
    rankRange: [1, 1],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 7500 }],
  },
  {
    rankRange: [2, 2],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 5500 }],
  },
  {
    rankRange: [3, 3],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 4500 }],
  },
  {
    rankRange: [4, 4],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 2250 }],
  },
  {
    rankRange: [5, 5],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 1500 }],
  },
  {
    rankRange: [6, 6],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 1200 }],
  },
  {
    rankRange: [7, 7],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 900 }],
  },
  {
    rankRange: [8, 8],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 750 }],
  },
  {
    rankRange: [9, 9],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 550 }],
  },
  {
    rankRange: [10, 10],
    rewards: [{ count: 1, type: "xai", category: "", quantity: 400 }],
  },
];

export const rallyIndividualRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 20 },
      { count: 10, type: "lootbox", category: "mythic" },
      { count: 10, type: "lootbox", category: "rare" },
      { count: 15, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 19 },
      { count: 9, type: "lootbox", category: "mythic" },
      { count: 10, type: "lootbox", category: "rare" },
      { count: 15, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 18 },
      { count: 8, type: "lootbox", category: "mythic" },
      { count: 10, type: "lootbox", category: "rare" },
      { count: 15, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [4, 4],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 17 },
      { count: 7, type: "lootbox", category: "mythic" },
      { count: 10, type: "lootbox", category: "rare" },
      { count: 15, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [5, 5],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 16 },
      { count: 6, type: "lootbox", category: "mythic" },
      { count: 9, type: "lootbox", category: "rare" },
      { count: 14, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [6, 6],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 15 },
      { count: 5, type: "lootbox", category: "mythic" },
      { count: 8, type: "lootbox", category: "rare" },
      { count: 13, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [7, 7],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 14 },
      { count: 4, type: "lootbox", category: "mythic" },
      { count: 7, type: "lootbox", category: "rare" },
      { count: 12, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [8, 8],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 13 },
      { count: 3, type: "lootbox", category: "mythic" },
      { count: 6, type: "lootbox", category: "rare" },
      { count: 11, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [9, 9],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 12 },
      { count: 2, type: "lootbox", category: "mythic" },
      { count: 5, type: "lootbox", category: "rare" },
      { count: 10, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [10, 10],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 11 },
      { count: 1, type: "lootbox", category: "mythic" },
      { count: 4, type: "lootbox", category: "rare" },
      { count: 9, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [11, 20],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 8 },
      { count: 2, type: "lootbox", category: "rare" },
      { count: 8, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [21, 50],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 6 },
      { count: 1, type: "lootbox", category: "rare" },
      { count: 6, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [51, 100],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 4 },
      { count: 5, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [101, 250],
    rewards: [
      { count: 1, type: "tribeBundle", category: "", quantity: 2 },
      { count: 3, type: "lootbox", category: "common" },
    ],
  },
  {
    rankRange: [251, 1000],
    rewards: [{ count: 2, type: "lootbox", category: "common" }],
  },
];

// For Rainbow Rumble Event v2
export const rainbowRumbleRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 1, type: "xai", category: "", quantity: 1000 },
      { count: 10, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "mythic" },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 1, type: "xai", category: "", quantity: 750 },
      { count: 8, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "wonder" },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 1, type: "xai", category: "", quantity: 600 },
      { count: 6, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "light" },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 1, type: "xai", category: "", quantity: 450 },
      { count: 5, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "mystery" },
    ],
  },
  {
    rankRange: [6, 10],
    rewards: [
      { count: 1, type: "xai", category: "", quantity: 250 },
      { count: 5, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "omnom" },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 1, type: "xai", category: "", quantity: 100 },
      { count: 5, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "rainbow" },
    ],
  },
  {
    rankRange: [16, 25],
    rewards: [
      { count: 1, type: "xai", category: "", quantity: 75 },
      { count: 5, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "star" },
    ],
  },
  {
    rankRange: [26, 50],
    rewards: [
      { count: 1, type: "xai", category: "", quantity: 40 },
      { count: 5, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "candy" },
    ],
  },
  {
    rankRange: [51, 100],
    rewards: [
      { count: 1, type: "xai", category: "", quantity: 20 },
      { count: 5, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "flower" },
    ],
  },
  {
    rankRange: [101, 200],
    rewards: [
      { count: 1, type: "xai", category: "", quantity: 15 },
      { count: 4, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "heart" },
    ],
  },
  {
    rankRange: [201, 400],
    rewards: [
      { count: 3, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "cloud" },
    ],
  },
  {
    rankRange: [401, 650],
    rewards: [
      { count: 2, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "crystal" },
    ],
  },
  {
    rankRange: [651, 1000],
    rewards: [
      { count: 1, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "moon" },
    ],
  },
];

export const rainbowCommonLootboxConfig = [
  {
    threshold: 75,
    reward: 1,
  },
];
export const rainbowRareLootboxConfig = [
  {
    threshold: 375,
    reward: 1,
  },
  {
    threshold: 1125,
    reward: 2,
  },
];
export const rainbowMythicLootboxConfig = [
  {
    threshold: 1875,
    reward: 1,
  },
];
export const rainbowMilestoneConfig = [
  {
    threshold: 75,
    milestone: "1",
  },
  {
    threshold: 375,
    milestone: "2",
  },
  {
    threshold: 1125,
    milestone: "3",
  },
  {
    threshold: 1875,
    milestone: "5",
  },
];

const Rewards = ({
  count,
  type,
  category,
  klass,
  quantity,
  multiplier,
  value,
}) => {
  const items = [];
  let imgSrc = `/img/leaderboard/${type}_${category}.png`;

  if (klass) {
    imgSrc = `/img/leaderboard/${type}_${category}_${klass}.png`;
  }

  for (let i = 0; i < count; i++) {
    items.push(<Image key={`${i}-${type}-${category}`} src={imgSrc} />);
  }

  return (
    <Tooltip
      overlay={
        value
          ? value
          : getTooltipTitle(count, type, category, klass, quantity, multiplier)
      }
    >
      <div className="reward-group">
        {value ? (
          <Typography variant={"textLg"} color="darkBlue300" family="display">
            {value}
          </Typography>
        ) : (
          items
        )}
      </div>
    </Tooltip>
  );
};

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/* TODO: Refactor this to just pass in the tooltip title to the reward component */
function getTooltipTitle(count, type, category, klass, quantity, multiplier) {
  let _capType = type.replace(/^\w/, (c) => c.toUpperCase());
  let _capCategory =
    category &&
    category.replace(/^\w/, (c) => c.toUpperCase()).replace("_unofficial", "");

  switch (type) {
    case "lootbox":
      const formattedCategory = _capCategory.replace(/_/g, " ");
      return `${toTitleCase(formattedCategory)} ${_capType} x${count}`;
    // if (klass) {
    //   return `${_capCategory} ${klass[0].toUpperCase() + klass.slice(1).toLowerCase()} ${_capType} x${count}`;
    // } else {
    //   return `${_capCategory} ${_capType} x${count}`;
    // }
    case "badge":
      return `${klass} Badge - Tier ${"I".repeat(_capCategory.slice(-1))}`;
    case "goldenTicket":
      return `Golden Ticket x${count}`;
    case "genesisEgg":
      return `${_capCategory} Genesis Egg`;
    case "evolutionBooster":
      return `Evolution Booster Tier ${_capCategory.slice(-1)} x${count}`;
    case "breedingBooster":
      return `Breeding Booster Tier ${_capCategory.slice(-1)} x${count}`;
    case "resourceBundle":
      return `Resource Bundle Tier ${_capCategory.slice(-1)} x${count}`;
    case "questScrolls":
      return `${_capCategory} Quest Scrolls x${count}`;
    case "questBundle":
      return `Quest Scroll Bundle ${category}`;
    case "tribeBundle":
      return `Tribe Material Bundle x${quantity}`;
    case "crystals":
      return `${_capCategory} ${_capType} x${quantity?.toLocaleString("en")}`;
    case "keystone":
      if (klass) {
        return `${_capCategory} ${_capType} x${count} - ${
          klass[0]?.toUpperCase() + klass?.substr(1)
        }`;
      } else {
        return `${_capCategory} ${_capType} x${count}`;
      }
    case "unim":
      return `${quantity?.toLocaleString("en")} UNIM`;
    case "rbw":
      return `${quantity?.toLocaleString("en")} RBW`;
    case "cu":
      return `${quantity?.toLocaleString("en")} CU`;
    case "xai":
      const _note = multiplier ? `(x2 if faction wins)` : "";
      return `${quantity?.toLocaleString("en")} XAI ${_note}`;
    case "usdc":
      return `${quantity?.toLocaleString("en")} USDC`;
    case "darkMarks":
      return `${quantity?.toLocaleString("en")} Dark Marks`;
    case "land":
      return `Land x ${count}`;
    case "lockdrop":
      return `${category} Month Lockdrop`;
    case "unicorn":
      let _catSplit = category.replace(/([a-z])([A-Z])/g, "$1 $2");
      let _catCap = _catSplit[0].toUpperCase() + _catSplit.substring(1);
      return `${_catCap} Unicorn x${count}`;
    case "shadowcorn":
      let _cat = category.replace(/([a-z])([A-Z])/g, "$1 $2");
      let _catCapital = _cat[0].toUpperCase() + _cat.substring(1);
      return `${_catCapital} Shadowcorn x${count}`;
    case "bag":
      const _catBag = category.replace(/([a-z])([A-Z])/g, "$1 $2");
      const _catBagCap = _catBag[0].toUpperCase() + _catBag.substring(1);
      return `${_catBagCap} Bag Rolls x${count}`;
    case "rolls":
      let _rollType = category;
      const tokens = ["usdc", "xai", "unim", "rbw"];
      if (tokens.includes(category)) {
        _rollType = category.toUpperCase();
      } else {
        _rollType = _rollType[0].toUpperCase() + _rollType.substring(1);
      }
      return `${_rollType} Rolls x${quantity}`;

    default:
      return `${type} - ${category}`;
  }
}

function inRange(x, min, max) {
  return min <= x && x <= max;
}

export const leaderboardRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 35000 },
      { count: 1, type: "unicorn", category: "doubleMythic" },
      { count: 7, type: "lootbox", category: "mythic" },
      { count: 3, type: "keystone", category: "mythic" },
      { count: 10, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 30000 },
      { count: 3, type: "unicorn", category: "singleMythic" },
      { count: 6, type: "lootbox", category: "mythic" },
      { count: 2, type: "keystone", category: "mythic" },
      { count: 10, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 25000 },
      { count: 2, type: "unicorn", category: "singleMythic" },
      { count: 5, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "mythic" },
      { count: 9, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 20000 },
      { count: 1, type: "unicorn", category: "singleMythic" },
      { count: 4, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "rare", klass: "light" },
      { count: 9, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [6, 10],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 15000 },
      { count: 3, type: "unicorn", category: "adult" },
      { count: 3, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "rare", klass: "mystery" },
      { count: 8, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 12500 },
      { count: 2, type: "unicorn", category: "adult" },
      { count: 2, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "rare", klass: "wonder" },
      { count: 8, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [16, 20],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 10000 },
      { count: 2, type: "unicorn", category: "adult" },
      { count: 2, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "common", klass: "crystal" },
      { count: 7, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [21, 30],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 9000 },
      { count: 1, type: "unicorn", category: "adult" },
      { count: 4, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "common", klass: "heart" },
      { count: 7, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [31, 40],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 8000 },
      { count: 1, type: "unicorn", category: "adult" },
      { count: 4, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "common", klass: "flower" },
      { count: 6, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [41, 50],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 7000 },
      { count: 1, type: "unicorn", category: "adult" },
      { count: 3, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "common", klass: "moon" },
      { count: 6, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [51, 75],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 6000 },
      { count: 3, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "common", klass: "cloud" },
      { count: 5, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [76, 100],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 5000 },
      { count: 2, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "common", klass: "candy" },
      { count: 5, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [101, 125],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 4500 },
      { count: 4, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "common", klass: "star" },
      { count: 4, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [126, 150],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 4000 },
      { count: 4, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "common", klass: "rainbow" },
      { count: 4, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [151, 175],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 3500 },
      { count: 3, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "common", klass: "omnom" },
      { count: 3, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [176, 200],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 3000 },
      { count: 3, type: "lootbox", category: "common" },
      { count: 3, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [201, 250],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 2500 },
      { count: 3, type: "lootbox", category: "common" },
      { count: 2, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [251, 300],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 2000 },
      { count: 3, type: "lootbox", category: "common" },
      { count: 2, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [301, 400],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 1500 },
      { count: 2, type: "lootbox", category: "common" },
      { count: 1, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier1", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [401, 500],
    rewards: [
      { count: 2, type: "lootbox", category: "common" },
      { count: 1, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier1", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [501, 750],
    rewards: [
      { count: 1, type: "lootbox", category: "common" },
      { count: 1, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier1", klass: "Spring Event" },
    ],
  },
  {
    rankRange: [751, 1000],
    rewards: [{ count: 1, type: "lootbox", category: "common" }],
  },
];

export const rpgRewards = [
  {
    rankRange: [1, 1],
    rewards: [{ value: 12500 }],
  },
  {
    rankRange: [2, 2],
    rewards: [{ value: 10000 }],
  },
  {
    rankRange: [3, 3],
    rewards: [{ value: 7500 }],
  },
  {
    rankRange: [4, 5],
    rewards: [{ value: 5000 }],
  },
  {
    rankRange: [6, 10],
    rewards: [{ value: 2500 }],
  },
  {
    rankRange: [11, 15],
    rewards: [{ value: 2000 }],
  },
  {
    rankRange: [16, 25],
    rewards: [{ value: 1500 }],
  },
  {
    rankRange: [26, 35],
    rewards: [{ value: 1000 }],
  },
  {
    rankRange: [36, 50],
    rewards: [{ value: 500 }],
  },
  {
    rankRange: [51, 75],
    rewards: [{ value: 250 }],
  },
  {
    rankRange: [76, 100],
    rewards: [{ value: 100 }],
  },
];

export const unicornPartyRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 25000 },
      { count: 1, type: "badge", category: "tier3", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 20000 },
      { count: 1, type: "badge", category: "tier3", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 15000 },
      { count: 1, type: "badge", category: "tier3", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 10000 },
      { count: 1, type: "badge", category: "tier3", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [6, 10],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 7500 },
      { count: 1, type: "badge", category: "tier3", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 5000 },
      { count: 1, type: "badge", category: "tier3", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [16, 25],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 4000 },
      { count: 1, type: "badge", category: "tier3", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [26, 35],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 3000 },
      { count: 1, type: "badge", category: "tier2", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [36, 50],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 2000 },
      { count: 1, type: "badge", category: "tier2", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [51, 75],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 1000 },
      { count: 1, type: "badge", category: "tier2", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [76, 100],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 500 },
      { count: 1, type: "badge", category: "tier2", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [101, 150],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 250 },
      { count: 1, type: "badge", category: "tier1", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [150, 200],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 100 },
      { count: 1, type: "badge", category: "tier1", klass: "Unicorn Party" },
    ],
  },
  {
    rankRange: [201, 250],
    rewards: [
      { count: 1, type: "badge", category: "tier1", klass: "Unicorn Party" },
    ],
  },
];

export const summerRewards = [
  {
    rankRange: [1, 1],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 35000 },
      { count: 1, type: "unicorn", category: "doubleMythic" },
      { count: 7, type: "lootbox", category: "mythic" },
      { count: 3, type: "keystone", category: "mythic" },
      { count: 10, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [2, 2],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 30000 },
      { count: 3, type: "unicorn", category: "singleMythic" },
      { count: 6, type: "lootbox", category: "mythic" },
      { count: 2, type: "keystone", category: "mythic" },
      { count: 10, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [3, 3],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 25000 },
      { count: 2, type: "unicorn", category: "singleMythic" },
      { count: 5, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "mythic" },
      { count: 9, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [4, 5],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 20000 },
      { count: 1, type: "unicorn", category: "singleMythic" },
      { count: 4, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "rare", klass: "light" },
      { count: 9, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [6, 10],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 15000 },
      { count: 3, type: "unicorn", category: "adult" },
      { count: 3, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "rare", klass: "mystery" },
      { count: 8, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [11, 15],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 12500 },
      { count: 2, type: "unicorn", category: "adult" },
      { count: 2, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "rare", klass: "wonder" },
      { count: 8, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [16, 20],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 10000 },
      { count: 2, type: "unicorn", category: "adult" },
      { count: 2, type: "lootbox", category: "mythic" },
      { count: 1, type: "keystone", category: "common", klass: "crystal" },
      { count: 7, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [21, 30],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 9000 },
      { count: 1, type: "unicorn", category: "adult" },
      { count: 4, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "common", klass: "heart" },
      { count: 7, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [31, 40],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 8000 },
      { count: 1, type: "unicorn", category: "adult" },
      { count: 4, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "common", klass: "flower" },
      { count: 6, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [41, 50],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 7000 },
      { count: 1, type: "unicorn", category: "adult" },
      { count: 3, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "common", klass: "moon" },
      { count: 6, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [51, 75],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 6000 },
      { count: 3, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "common", klass: "cloud" },
      { count: 5, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [76, 100],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 5000 },
      { count: 2, type: "lootbox", category: "rare" },
      { count: 1, type: "keystone", category: "common", klass: "candy" },
      { count: 5, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier3", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [101, 125],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 4500 },
      { count: 4, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "common", klass: "star" },
      { count: 4, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [126, 150],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 4000 },
      { count: 4, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "common", klass: "rainbow" },
      { count: 4, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [151, 175],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 3500 },
      { count: 3, type: "lootbox", category: "common" },
      { count: 1, type: "keystone", category: "common", klass: "omnom" },
      { count: 3, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [176, 200],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 3000 },
      { count: 3, type: "lootbox", category: "common" },
      { count: 3, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [201, 250],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 2500 },
      { count: 3, type: "lootbox", category: "common" },
      { count: 2, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [251, 300],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 2000 },
      { count: 3, type: "lootbox", category: "common" },
      { count: 2, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier2", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [301, 400],
    rewards: [
      { count: 3, type: "rbw", category: "", quantity: 1500 },
      { count: 2, type: "lootbox", category: "common" },
      { count: 1, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier1", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [401, 500],
    rewards: [
      { count: 2, type: "lootbox", category: "common" },
      { count: 1, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier1", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [501, 750],
    rewards: [
      { count: 1, type: "lootbox", category: "common" },
      { count: 1, type: "goldenTicket", category: "ticket" },
      { count: 1, type: "badge", category: "tier1", klass: "Summer Event" },
    ],
  },
  {
    rankRange: [751, 1000],
    rewards: [{ count: 1, type: "lootbox", category: "common" }],
  },
];

export const shadowForgeRewards = [
  {
    rankRange: [1, 1],
    rewards: [{ count: 3, type: "rbw", category: "", quantity: 25000 }],
  },
  {
    rankRange: [2, 2],
    rewards: [{ count: 3, type: "rbw", category: "", quantity: 20000 }],
  },
  {
    rankRange: [3, 3],
    rewards: [{ count: 3, type: "rbw", category: "", quantity: 17500 }],
  },
  {
    rankRange: [4, 5],
    rewards: [{ count: 2, type: "rbw", category: "", quantity: 15000 }],
  },
  {
    rankRange: [6, 7],
    rewards: [{ count: 2, type: "rbw", category: "", quantity: 12500 }],
  },
  {
    rankRange: [8, 10],
    rewards: [{ count: 2, type: "rbw", category: "", quantity: 10000 }],
  },
  {
    rankRange: [11, 15],
    rewards: [{ count: 1, type: "rbw", category: "", quantity: 7500 }],
  },
  {
    rankRange: [16, 20],
    rewards: [{ count: 1, type: "rbw", category: "", quantity: 5000 }],
  },
  {
    rankRange: [21, 30],
    rewards: [{ count: 1, type: "rbw", category: "", quantity: 2500 }],
  },
  {
    rankRange: [31, 50],
    rewards: [{ count: 1, type: "rbw", category: "", quantity: 1000 }],
  },
];

// Returns leaderboard rewwards based on rank, must take in a rewardsConfig
// that is an array of object with the rankRange and rewards
export function getLeaderboardRewards(rank, rewardsConfig) {
  for (const reward of rewardsConfig) {
    const [startRank, endRank] = reward.rankRange;
    if (inRange(rank, startRank, endRank)) {
      return (
        <>
          {reward.rewards.map((rewardItem, index) => (
            <Rewards key={index} {...rewardItem} />
          ))}
        </>
      );
    }
  }

  return null;
}

async function getStakedShadowcorns(GOFPContract, selectedAddress) {
  let promises = [];
  let _shadowcornArray = [];
  //Needs to be set to 6 for mainnet
  let session = 6;

  //N needs to be set to 0 for mainnet
  for (let n = 1; n <= session; n++) {
    const _shadowcornBalance = await GOFPContract.methods
      .numTokensStakedIntoSession(n, selectedAddress)
      .call();

    for (let i = 1; i <= _shadowcornBalance; i++) {
      promises.push(
        new Promise(async function (resolve) {
          setTimeout(async () => {
            let tokenId = await GOFPContract.methods
              .tokenOfStakerInSessionByIndex(n, selectedAddress, i)
              .call();

            _shadowcornArray.push(tokenId);

            resolve(true);
          }, 300 * i);
        })
      );
    }
    await Promise.allSettled(promises);
  }

  return _shadowcornArray;
}

async function getUnstakedShadowcorns(shadowcornContract, selectedAddress) {
  if (!shadowcornContract || !selectedAddress) {
    return;
  }
  const _shadowcornBalance = await shadowcornContract.methods
    .balanceOf(selectedAddress)
    .call();

  let promises = [];
  let _shadowcornArray = [];

  for (let i = 0; i < _shadowcornBalance; i++) {
    promises.push(
      new Promise(async function (resolve) {
        setTimeout(async () => {
          let _shadowcornTokenID = await shadowcornContract.methods
            .tokenOfOwnerByIndex(selectedAddress, i)
            .call();

          _shadowcornArray.push(_shadowcornTokenID);

          resolve(true);
        }, 300 * i);
      })
    );
  }

  await Promise.allSettled(promises);
  return _shadowcornArray;
}

export async function getAllShadowcorns(wallet, setUserShadowcorns) {
  let web3 = new Web3(wallet?.provider);

  let shadowcornContract = new web3.eth.Contract(
    shadowcornABI,
    netconfig.ShadowcornContractAddress
  );

  let GOFPContract = new web3.eth.Contract(
    GofpABI,
    netconfig.GOFPContractAddress
  );

  let unstakedShadowcornArr = await getUnstakedShadowcorns(
    shadowcornContract,
    wallet?.provider?.selectedAddress
  );

  let stakedShadowcornArr = await getStakedShadowcorns(
    GOFPContract,
    wallet?.provider?.selectedAddress,
    shadowcornContract
  );

  let _shadowcorns = [...stakedShadowcornArr, ...unstakedShadowcornArr];

  setUserShadowcorns(_shadowcorns);

  return _shadowcorns;
}
