import styled from "styled-components";
import React, { useRef } from "react";
import { Header } from "../components/header";
import { Footer } from "../layouts/BaseLayout/Footer";
import { BrightRainbowBackground as RainbowBackground } from "../components/home/hero/HomeHeroBackground";
import { Hero } from "../components/licensing/Hero";
import { StatsAtAGlance } from "../components/licensing/StatsAtAGlance";
import { GameAwards } from "../components/licensing/GameAwards";
import { UnicornCharacters } from "../components/licensing/UnicornCharacters";
import { ShadowcornCharacters } from "../components/licensing/ShadowcornCharacters";
import { Licensee } from "../components/licensing/Licensee";
import {
  BannerBackground,
  ComicBackground,
} from "../components/licensing/Backgrounds";
import { Merch } from "../components/licensing/Merch";
import { useDocumentTitle } from "@uidotdev/usehooks";

const BlueGradientWrapper = styled.div`
  background: linear-gradient(
    0deg,
    #26015a 0%,
    #5455ec 40.43%,
    #23248a 61.77%,
    #262992 80.5%,
    #2a2f9a 100%
  );
  overflow-x: hidden;
  overflow-y: hidden;
`;

export function Licensing() {
  useDocumentTitle("Licensing");
  const licenseeRef = useRef(null);

  return (
    <>
      <BlueGradientWrapper>
        <BannerBackground>
          <Header large={true} isLagunaGames />
          <Hero licenseeRef={licenseeRef} />
          <StatsAtAGlance />

          <RainbowBackground>
            <GameAwards />
            <UnicornCharacters />
            <ShadowcornCharacters />
            <Merch />
          </RainbowBackground>
          <div ref={licenseeRef}>
            <ComicBackground>
              <Licensee />
            </ComicBackground>
          </div>
        </BannerBackground>
      </BlueGradientWrapper>
      <Footer />
    </>
  );
}
