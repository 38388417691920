import { Box } from "../../base/Box";
import React from "react";
import { Typography } from "../../base/Typography";
import { Button } from "../../base/Button";
import styled from "styled-components";
import { Row } from "../../base/Row";
import { Col } from "../../base/Col";
import { Link } from "react-router-dom";

const CtaWrap = styled(Box)`
  padding: 16px;
  height: 100%;
`;

const ImgWrap = styled.img`
  width: 100%;
  max-width: 150px;
`;

const ActionWrap = styled.div`
  padding-top: 10px;
`;

export function HeroCtaCard({
  title,
  subtitle,
  actionTitle,
  img,
  color = "redishOrange300",
  onActionClick = () => {
  },
  dense = false,
  link = null,
  to = null,
  ...rest
}) {
  const imageColSize = dense ? 2 : 4;
  const textColSize = dense ? 10 : 8;

  return (
    <CtaWrap
      variant="gradient"
      rounded="md"
      color={color}
      pattern="labyrinth"
      patternSize="50%"
      {...rest}
    >
      <Row height={"100%"}>
        <Col xs={3} xl={imageColSize}>
          <ImgWrap src={img} alt="" />
        </Col>
        <Col
          xs={9}
          xl={textColSize}
          className="d-flex flex-column justify-content-center"
        >
          <Typography
            tag={"h3"}
            variant={"displayXs"}
            md={{
              variant: "displaySm",
            }}
            textshadow="soft"
          >
            {title}
          </Typography>
          <Typography
            tag={"p"}
            variant={"textSm"}
            weight={600}
            md={{
              variant: "textMd",
            }}
            textshadow="soft"
          >
            {subtitle}
          </Typography>
        </Col>
        <Col xs={12} className="align-self-end">
          <ActionWrap>
            {to ? (
              <Link to={to}>
                <Button block={true} onClick={onActionClick}>
                  {actionTitle}
                </Button>
              </Link>
            ) : (
              <a
                href={link ? link : null}
                target={link ? "_blank" : null}
                rel="noopener noreferrer"
              >
                <Button block={true} onClick={onActionClick}>
                  {actionTitle}
                </Button>
              </a>
            )}
          </ActionWrap>
        </Col>
      </Row>
    </CtaWrap>
  );
}
