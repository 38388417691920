import { init } from "@web3-onboard/react";

import injectedModule from "@web3-onboard/injected-wallets";
import coinbaseModule from "@web3-onboard/coinbase";

import walletConnectModule from "@web3-onboard/walletconnect";
import sequence from "./Sequence";

import { WALLET, CHAIN } from "../../consts/externalLinks";

import CULogo from "../../img/onboard-logo.svg";

//New version requires modules to be imported for each wallet
const injected = injectedModule();
const coinbase = coinbaseModule();

const walletConnect = walletConnectModule({
  projectId: "0a6e4a149e0430208ae5d10987f5b9e9",
  version: 2,
  handleUri: (uri) => console.log(uri),
  requiredChains: [137],
});

const sequenceOnboard = sequence({
  network: 80001,
  appName: "Crypto Unicorns",
});

const customTheme = {
  "--w3o-font-family": `'Quicksand', sans-serif`,
};

export const initWeb3Onboard = init({
  //Theme
  theme: customTheme,

  //Wallets display in the order of this array
  wallets: [
    coinbase,
    injected,
    walletConnect,
    sequenceOnboard,
    //torus
  ],
  connect: {
    autoConnectLastWallet: true,
    // iDontHaveAWalletLink: torusModule().link,
  },

  //New version requires each chain that the app supports
  //to be defined
  chains: [
    {
      id: "0x89",
      token: "MATIC",
      label: "Polygon",
      rpcUrl: CHAIN.POLYGON,
    },
    {
      id: "0x13882",
      token: "MATIC",
      label: "Mumbai",
      rpcUrl: CHAIN.MATIC,
    },
    {
      id: 42161,
      token: "ETH",
      label: "Arbitrum",
      rpcUrl: CHAIN.ARBITRUM,
    },
  ],
  appMetadata: {
    name: "Crypto Unicorns",
    icon: CULogo,
    logo: CULogo,
    description: "Crypto Unicorns App",
    explore: "https://www.cryptounicorns.fun",
    recommendedInjectedWallets: [
      { name: "Coinbase", url: WALLET.COINBASE },
      { name: "MetaMask", url: WALLET.METAMASK },
    ],
  },
  accountCenter: {
    desktop: {
      position: "topRight",
      enabled: false,
      minimal: false,
    },
    mobile: {
      position: "topRight",
      enabled: false,
      minimal: true,
    },
  },
  i18n: {
    en: {
      connect: {
        selectingWallet: {
          header: "🦄 Welcome to the Crypto Unicorns App",
          sidebar: {
            heading: "",
            subheading: "Select your wallet from the options to get started",
            paragraph: "Crypto Unicorns recommends the Coinbase Wallet.",
          },
        },
      },
    },
  },
});
