import { useEffect } from "react";

/**
 * Hook that closes (sets state to false) on an active element when the user clicks outside the passed ref
 */
export function useCloseOnOutsideClick(ref, setBooleanCallback, {
  includeParent = false,
}) {
  useEffect(() => {
    // Trigger callback if anywhere outside the element is clicked on
    const handleClickOutside = (event) => {
      const parent = ref.current?.parentNode;

      const node = includeParent ? parent : ref.current;

      if (node && !node.contains(event?.target)) {
        setBooleanCallback(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setBooleanCallback, includeParent]);
}
