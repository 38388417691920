import styled from "styled-components";
import React from "react";
import { Box } from "./Box";
import { device } from "../../theme/mediaQuery";

const BannerWrap = styled.div`
  padding: 0;
  border: 3px solid transparent;
  background: ${({ $borderColor }) =>
          $borderColor ? $borderColor : "transparent"};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: absolute;
  z-index: 2;
  width: 100%;

  @media (${device.md}) {
    border-radius: 18px;
    
    right: -20px;
    transform: skew(-15deg);
    max-width: 426px;
  }
  @media (${device.xl}) {
    max-width: 531px;
  }
  @media (${device.xl}) {
  }
`;

const BoxWrap = styled(Box)`
  padding: 6px 8px;
  box-shadow: 0px 4px 7px 0px rgba(255, 255, 255, 0.34) inset;
  width: 100%;
  height: 100%;
  box-shadow: none;
  border-radius: 0;
  @media (${device.md}) {
    border-radius: 16px;
    padding: 8px 26px 10px 50px;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 4px;
  @media (${device.md}) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    transform: skew(15deg); // unskew the content
  }
`;

export function Banner({
  color,
  pattern,
  patternSize,
  rounded,
  patternOpacity,
  gradient,
  borderColor,
  children,
  ...rest
}) {
  return (
    <BannerWrap $borderColor={borderColor} {...rest}>
      <BoxWrap
        color={color}
        pattern={pattern}
        patternSize={patternSize}
        rounded={rounded}
        patternOpacity={patternOpacity}
        gradient={gradient}
      >
        <ContentWrap>{children}</ContentWrap>
      </BoxWrap>
    </BannerWrap>
  );
}
