import { NotificationsModal } from "../../common/NotificationsModal";
import { withdrawNotificationModelOpenedAtom, withdrawNotificationTransactionsAtom } from "../../../store/staking";
import { useAtom } from "jotai";
import styled from "styled-components";
import rbw_img from "../../../img/currencies/rbw-tilted.png";
import { device } from "../../../theme/mediaQuery";
import { FormattedAmount } from "../../common/FormattedAmount";
import { StakingViewTransactionButton } from "../StakingViewTransactionButton";

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ListItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 13px 10px;

  @media (${device.md}) {
    align-items: center;
    flex-direction: row;
  }
`;

const ListItemPrepend = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ListItemText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.white};
`;

const ListItemIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    height: 100%;

  }
`;

const ListItemAction = styled.div`

  & * {
    @media (${device.md}) {
      font-size: 16px;
    }
  }

  @media (${device.md}) {
    margin-left: auto;

  }
`;

export function StakingHistoryWithdrawNotifications() {
  const [open, setOpen] = useAtom(withdrawNotificationModelOpenedAtom);
  const [notifications, setNotifications] = useAtom(withdrawNotificationTransactionsAtom);

  // close the modal and clear the notifications
  const handleClose = () => {
    setOpen(false);
    setNotifications([]);
  };

  return (
    <NotificationsModal
      open={open}
      onClose={handleClose}
      title="Withdrawal success!"
    >
      <ListWrap>
        {notifications.map((transaction, index) => (
          <ListItem
            key={index}
            amount={transaction.amount}
            hash={transaction.hash}
            token={transaction.token}
          />
        ))}
      </ListWrap>
    </NotificationsModal>
  );
}

function ListItem({ amount, hash, token }) {
  return (
    <ListItemWrap>
      <ListItemPrepend>
        <ListItemIcon>
          <img src={rbw_img} alt="rbw" />
        </ListItemIcon>
        <ListItemText>
          <FormattedAmount wei={amount} /> &nbsp;
          {token}
        </ListItemText>
      </ListItemPrepend>
      <ListItemAction>
        <StakingViewTransactionButton
          hash={hash}
          block
          md={{
            block: false
          }}
        >
          View Transaction
        </StakingViewTransactionButton>
      </ListItemAction>
    </ListItemWrap>
  );
}