import React from "react";
import styled from "styled-components";
import { Typography } from "../../base/Typography";
import { device } from "../../../theme/mediaQuery";
import { InfoModal } from "./InfoModal";

import tier6EvoBoosters from "../../../img/leaderboard/evolutionBooster_tier6.png";
import tier6BreedBoosters from "../../../img/leaderboard/breedingBooster_tier6.png";

import tier1Scrolls from "../../../img/leaderboard/scroll_tier1.png";
import tier2Scrolls from "../../../img/leaderboard/scroll_tier2.png";
import tier3Scrolls from "../../../img/leaderboard/scroll_tier3.png";
import tier4Scrolls from "../../../img/leaderboard/scroll_tier4.png";

import tier1Items from "../../../img/leaderboard/tier1_items.png";
import tier2Items from "../../../img/leaderboard/tier2_items.png";
import tier3Items from "../../../img/leaderboard/tier3_items.png";

import xai from "../../../img/currencies/xai-red.png";
import mythicLootbox from "../../../img/leaderboard/lootbox_mythic.png";
import progressionBundle from "../../../img/leaderboard/progression_bundle.png";

const InfoModalWrap = styled(InfoModal)`
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    margin: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e6e6fa;
    border-radius: 4px;
    padding: 20px 0;
  }

  @media (${device.md}) {
    gap: 40px;
  }
`;

const MonthlyReward = styled.div`
  width: 100%;
`;

const RewardTable = styled.div`
  width: 100%;
  background-color: #f0f1ff;
  border-radius: 20px;
  color: #5338c2;
`;

const TableHeading = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background: #e0e0fc;
  gap: 0px 8px;
  text-align: center;
  @media (${device.sm}) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (${device.md}) {
    padding: 20px 30px;
    align-items: stretch;
    text-align: left;
  }
`;

const TableBody = styled.div`
  padding: 18px 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  @media (${device.md}) {
    padding: 20px 30px;
  }

  & > div,
  & > p {
    border-bottom: 1px dashed #5338c2;
  }

  & > p:first-of-type {
    border-bottom: none;
  }
`;

const ContentHeading = styled(Typography)`
  font-weight: 700;
  display: flex;
  justify-content: space-between;

  & > * {
    font-weight: 700;
  }
`;

const monthlyRewards = {
  12: [
    { name: "XAI", value: 1000, img: xai },
    { name: "Progression Bundles", value: 5, img: progressionBundle },
    { name: "Mythic Lootbox", value: 5, img: mythicLootbox },
  ],
  6: [
    { name: "XAI", value: 500, img: xai },
    { name: "Progression Bundles", value: 3, img: progressionBundle },
    { name: "Mythic Lootbox", value: 3, img: mythicLootbox },
  ],
  3: [
    { name: "XAI", value: 100, img: xai },
    { name: "Progression Bundles", value: 1, img: progressionBundle },
    { name: "Mythic Lootbox", value: 1, img: mythicLootbox },
  ],
};

const bundleData = [
  {
    category: "Item",
    value: "Quantity",
    details: [
      {
        name: "Tier I Items (Raw Wood Plank, Cheap Nails and Frayed Rope)",
        value: 100,
        img: tier1Items,
      },
      {
        name: "Tier II (Milled Wood Plank, Tough Nails, and Fine Cord)",
        value: 25,
        img: tier2Items,
      },
      {
        name: "Tier III (Artisanal Wood Plank, Forged Nails, and Golden Thread)",
        value: 10,
        img: tier3Items,
      },
      { name: "Exotic Scroll", value: 1, img: tier4Scrolls },
      { name: "Mythic Scroll", value: 3, img: tier3Scrolls },
      { name: "Rare Scroll", value: 5, img: tier2Scrolls },
      { name: "Common Scroll", value: 10, img: tier1Scrolls },
      {
        name: "Breeding TVI",
        value: 1,
        img: tier6BreedBoosters,
      },
      { name: "Evolution TVI", value: 1, img: tier6EvoBoosters },
    ],
  },
];

export function LeaderboardModal({
  modal,
  toggleModal,
  month,
  selected = true,
}) {
  const monthReward = monthlyRewards[month];
  return (
    <InfoModalWrap
      toggleModal={toggleModal}
      showModal={modal}
      headerTitle="Monthly Rewards"
    >
      <MonthlyReward>
        {selected && (
          <Typography
            as="h2"
            variant="textLg"
            md={{ variant: "displayXs" }}
            color="#9a1bc6"
            className="mb-2 mb-md-4 text-center"
          >
            You will receive these items monthly for {month} months
          </Typography>
        )}
        <RewardTable>
          <TableHeading>
            <Typography
              as="h2"
              variant="textLg"
              md={{ variant: "displayXs" }}
              color="#5338C2"
            >
              Monthly Rewards
            </Typography>
          </TableHeading>
          <TableBody>
            {monthReward.map((detail, detailIndex) => (
              <RewardDetailRow key={detailIndex} detail={detail} />
            ))}
          </TableBody>
        </RewardTable>
      </MonthlyReward>

      <RewardTable>
        <TableHeading>
          <Typography
            as="h2"
            variant="textLg"
            md={{ variant: "displayXs" }}
            color="#5338C2"
          >
            Progression Bundle Contents
          </Typography>
        </TableHeading>
        <TableBody>
          {bundleData.map((item, index) => (
            <React.Fragment key={index}>
              <ContentHeading
                as="p"
                variant="textSm"
                md={{ variant: "textMd" }}
                family="body"
              >
                {item.category}
                <Typography as="span" color="#5338c2">
                  {item.value}
                </Typography>
              </ContentHeading>
              {item?.details &&
                item.details.map((detail, detailIndex) => (
                  <RewardDetailRow key={detailIndex} detail={detail} />
                ))}
            </React.Fragment>
          ))}
        </TableBody>
      </RewardTable>
    </InfoModalWrap>
  );
}

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  & span:first-of-type {
    margin-right: auto;
  }
`;
const LogoWrap = styled.img`
  width: 20px;
  height: 20px;
`;

const RewardDetailRow = ({ detail }) => (
  <TableRow>
    <LogoWrap src={detail.img} alt={`${detail.name} logo`} />
    <Typography as="span" color="#5338c2">
      {detail.name}
    </Typography>
    <Typography as="span" color="#5338c2">
      {detail.value.toLocaleString()}
    </Typography>
  </TableRow>
);
