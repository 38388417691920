import React, { useState } from "react";
import { useAtomValue } from "jotai/index";
import styled from "styled-components";

import { LeaderboardSummary } from "./LeaderboardSummary";
import {
  getLeaderboardRewards,
  calculateMilestone,
  stakingLockdrop,
  stakingRewardsMay,
  stakingRewardsEnding,
} from "./LeaderboardHelper";
import LeaderboardTableWithPagination from "./components/LeaderboardTableWithPagination";
import { GetFullLeaderboard } from "../../hooks/GetLeaderboard";
import { currentWalletAddressAtom } from "../../store";
import { colors } from "../../theme/colors";
import { device } from "../../theme/mediaQuery";
import { Typography } from "../base/Typography";
import Spinner from "../base/Spinner";
import { Icon } from "../base/Icon";
import { StakingCallout } from "../../components/staking/Modals/StakingCallout";
import { BLOG } from "../../consts/externalLinks";

import xai from "../../img/currencies/xai-red.png";
import culp from "../../img/currencies/culp.png";
import mythicLootbox from "../../img/leaderboard/lootbox_mythic.png";
import progressionBundle from "../../img/leaderboard/progression_bundle.png";
import lockdrop from "../../img/leaderboard/lockdrop.png";
import badge from "../../img/staking/badge.svg";
import { LeaderboardModal } from "../staking/Modals/LeaderboardModal";
import { Box } from "../base/Box";
import { Button } from "../base/Button";
import { Link } from "react-router-dom";

const LEADERBOARD_ID = "8c410836-6384-4744-b551-8e377d18dd22"; // Staking Event

const LEADERBOARD_TABS = "#9A1BC6";
const HEADER_COLOR = "linear-gradient(118deg, #7932B2 17.32%, #44028E 82.68%)";
const HEADER_COLOR_ALT =
  "linear-gradient(143deg, rgba(251, 117, 104, 0.27) 25.72%, rgba(255, 88, 52, 0.00) 94.73%), #7931B1";
const BACKGROUND_COLOR = "linear-gradient(180deg, #5727A3 0%, #8D4FB5 100%)";
const BACKGROUND_COLOR_ALT = colors.white;
const TABS_COLOR = "#131222";

export function LeaderboardStakingTable({
  includeSummary = false,
  isStakingPage = false,
}) {
  const leaderboard = GetFullLeaderboard({ id: LEADERBOARD_ID });

  return (
    <>
      {!isStakingPage && includeSummary && (
        <StakingLeaderboardSummary leaderboard={leaderboard} />
      )}
      {isStakingPage && includeSummary && (
        <StakingSummary leaderboard={leaderboard} />
      )}
      <LeaderboardStakingTables leaderboard={leaderboard} />
    </>
  );
}

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (${device.lg}) {
    gap: 35px;
  }
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (${device.md}) {
    flex-direction: row;
    gap: 20px;
  }
  > * {
    font-weight: 400;
    line-height: 28px;
    @media (${device.md}) {
      width: 50%;
    }
  }
`;

const Divider = styled.div`
  background: rgba(255, 255, 255, 0.2);
  height: 4px;
  width: 100%;
`;

const PositionWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const StakingPosition = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (${device.md}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    & > * {
      flex-basis: calc(50% - 8px) !important;
    }
  }
  @media (${device.lg}) {
    gap: 20px;
    & > * {
      flex-basis: calc(50% - 10px) !important;
    }
  }
  @media (${device.xl}) {
    flex-wrap: nowrap;
    & > * {
      flex-basis: auto;
    }
  }
`;

const stakingOptions = [
  {
    months: 12,
    title: "12 Month",
    hasRewards: true,
  },
  {
    months: 6,
    title: "6 Month",
    hasRewards: true,
  },
  {
    months: 3,
    title: "3 Month",
    hasRewards: true,
  },
  {
    months: 0,
    title: "Not Qualified",
  },
];

export function LeaderboardStakingInfo({ leaderboard, isStakingPage = false }) {
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  return (
    <>
      <InfoWrap>
        {isStakingPage && (
          <Typography
            as="h2"
            variant="displaySm"
            md={{ variant: "displayMd" }}
            className="text-center"
          >
            LBE Leaderboard event
          </Typography>
        )}
        <TextWrap>
          <Typography as="p" variant="textXl" md={{ variant: "displayXs" }}>
            To participate in the LBE event, you will need to stake CULP. By
            staking CULP, you will receive sCULP with the amount varying based
            off of badges and the selected time-lock. Each day, you will receive
            1 point per sCULP you have staked.
          </Typography>
          <Typography as="p" variant="textXl" md={{ variant: "displayXs" }}>
            Staking CULP for 12 months will provide a multiplier to the daily
            points you receive. Staking CULP, for 12 months, between 5/7 and
            5/28 (00:00 UTC) will provide a 6x multiplier to the daily points
            received from that deposit and staking CULP for 12 months between
            5/28 (00:01 UTC) and 6/21 will provide a 4x multiplier to the daily
            points received from that deposit.
          </Typography>
        </TextWrap>
      </InfoWrap>
      {!walletAddress && isStakingPage && (
        <StakingCallout
          icon="round-info"
          title="For more information on this leaderboard Check out our substack post"
          buttonText="Read more"
          href={BLOG.STAKING}
        />
      )}
      {!walletAddress && !isStakingPage && <StakingBanner />}
      <LockdropSection
        leaderboard={leaderboard}
        isStakingPage={isStakingPage}
      />
    </>
  );
}

const StakingLeaderboardWrap = styled.div`
  display: flex;
  padding: 128px 21px 21px;
  gap: 21px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (${device.sm}) {
    padding: 112px 21px 21px;
  }

  @media (${device.md}) {
    padding: 52px 32px 32px;
  }

  @media (${device.lg}) {
    padding: 52px;
    gap: 30px;
  }
`;

export function StakingLeaderboardSummary({ leaderboard }) {
  const changeDate = new Date(Date.UTC(2024, 4, 28)); // May 28, 2024
  const currentDate = new Date();

  const multiplier = currentDate < changeDate ? "6" : "4";

  const renderBody = () => {
    return (
      <StakingLeaderboardWrap>
        <LeaderboardStakingInfo leaderboard={leaderboard} />
      </StakingLeaderboardWrap>
    );
  };

  return (
    <LeaderboardSummary
      leaderboard={leaderboard}
      headerTitle="LBE Leaderboard event"
      renderBody={renderBody}
      headerBackgroundColor={HEADER_COLOR_ALT}
      headerPattern="labyrinth"
      gradient={BACKGROUND_COLOR}
      spinnerColor="white"
      badge={<Multiplier multiplier={multiplier} />}
    />
  );
}
const LockdropIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 12px;
  @media (${device.md}) {
    width: 40px;
    height: 40px;
  }
`;

const StakingWrap = styled.div`
  position: relative;
  display: flex;
  padding: 132px 21px 21px;
  gap: 21px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  @media (${device.md}) {
    padding: 90px 32px 32px;
  }

  @media (${device.lg}) {
    padding: 52px;
    gap: 30px;
  }
`;

export function StakingSummary({ leaderboard }) {
  const changeDate = new Date(Date.UTC(2024, 4, 28)); // May 28, 2024
  const currentDate = new Date();

  const multiplier = currentDate < changeDate ? "6" : "4";

  return (
    <StakingWrap>
      <Multiplier multiplier={multiplier} isStakingPage />
      <LeaderboardStakingInfo leaderboard={leaderboard} isStakingPage />
    </StakingWrap>
  );
}

const LockdropSection = ({ leaderboard, isStakingPage }) => {
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const rank = leaderboard?.self?.rank;
  const isLoading = !leaderboard || leaderboard?.isLoading;
  const monthLockdrop = parseInt(calculateMilestone(rank, stakingLockdrop));

  if (!walletAddress) {
    return null;
  }
  return (
    <>
      <Divider />
      {isLoading && <Spinner color="white" />}
      {!isLoading && (
        <>
          <PositionWrap>
            <Typography
              as="h3"
              variant="displayXs"
              md={{ variant: "displaySm" }}
              className="text-center"
            >
              <LockdropIcon src={lockdrop} alt="lockdrop" />
              Lockdrop
            </Typography>
            <Typography
              as="p"
              variant="textXl"
              md={{ variant: "displayXs" }}
              className="text-center"
            >
              Depending on your final leaderboard placement, you may qualify for
              our lockdrop program. You will receive the rewards below for each
              month the lockdrop is active:
            </Typography>
            <StakingPosition>
              {stakingOptions.map((option, index) => (
                <StakingCard
                  key={index}
                  title={option.title}
                  selected={option.months === monthLockdrop}
                  month={option.months}
                  hasRewards={option.hasRewards}
                />
              ))}
            </StakingPosition>
          </PositionWrap>
          {isStakingPage && (
            <StakingCallout
              icon="round-info"
              title="For more information on this leaderboard Check out our substack post"
              buttonText="Read more"
              href={BLOG.STAKING}
            />
          )}
          {!isStakingPage && <StakingBanner />}
        </>
      )}
    </>
  );
};

const BannerWrap = styled(Box)`
  border-radius: 16px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border: 2.646px solid #e276e9;
  background: #9a1bc6;
  gap: 10px;
  width: 100%;

  @media (${device.md}) {
    padding: 16px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ButtonWrap = styled.div`
  padding: 0;

  & div {
    padding: 4px;
    font-size: 10px;
    white-space: nowrap;
    word-break: keep-all;
    @media (${device.md}) {
      font-size: 12px;
    }
    @media (${device.xl}) {
      font-size: 16px;
    }
  }
`;
const BannerTitleWrap = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & p {
    text-align: center;
  }

  @media (${device.md}) {
    flex-direction: row;
    & p {
      text-align: left;
    }
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-grow: 1;
  line-height: normal;
  flex-direction: column-reverse;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  @media (${device.md}) {
    flex-direction: row;
  }
`;

const LogoImage = styled.img`
  height: 45px;
  width: 45px;

  @media (${device.md}) {
    height: 60px;
    width: 60px;
  }
  @media (${device.lg}) {
    height: 80px;
    width: 80px;
  }
`;

function StakingBanner() {
  return (
    <BannerWrap
      rounded="md"
      patternSize="cover"
      gradient="linear-gradient(90deg, #9B1CC7 -4.48%, #DA2AEA 14.25%, #D128E5 37.67%, #9A1BC6 99.59%);"
      pattern="dots"
      patternOpacity="1"
      borderColor="linear-gradient(180deg, #e276e9 0%, #af59db 100%) border-box"
    >
      <LogoImage src={culp} alt="Logo" />
      <ContentWrap>
        <BannerTitleWrap>
          <Typography
            tag="p"
            variant="textSm"
            family="display"
            textshadow="medium"
            md={{
              variant: "textMd",
            }}
            xl={{
              variant: "textLg",
            }}
          >
            Stake CULP now to earn rewards and participate on the leaderboard!
          </Typography>
          <ButtonWrap>
            <Button size="xs" as={Link} to="/staking/overview">
              Visit staking website
            </Button>
          </ButtonWrap>
        </BannerTitleWrap>
      </ContentWrap>
    </BannerWrap>
  );
}

const GradientBorderCard = styled.div`
  transform: rotate(12deg);
  user-select: none;
  background-image: url(${badge});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;

  width: 150px;
  height: 150px;
  right: -25px;
  top: -25px;

  z-index: 2;

  @media (${device.md}) {
    width: 180px;
    height: 180px;
    right: -90px;
    top: -30px;
  }
  @media (${device.lg}) {
    width: 224px;
    height: 224px;
    right: -100px;
    top: -100px;
  }

  & > * {
    line-height: normal;
    max-width: 80px;
    @media (${device.lg}) {
      max-width: 110px;
    }
  }
  & span {
    font-family: inherit;
    font-size: 0.75em;
  }

  & > p:first-of-type {
    line-height: 0.75em;
    @media (${device.lg}) {
    }
  }
`;

const GradientLeaderboardBorderCard = styled(GradientBorderCard)`
  right: -25px;
  top: 70px;
  @media (${device.sm}) {
    top: 45px;
  }
  @media (${device.md}) {
    right: -90px;
    top: -30px;
  }
  @media (${device.lg}) {
    right: -100px;
    top: -100px;
  }
`;

export const Multiplier = ({ multiplier, isStakingPage = false, ...props }) => {
  const badge = (
    <>
      <Typography
        as="p"
        variant="displaySm"
        md={{ variant: "displayLg" }}
        lg={{ variant: "display2xl" }}
        family="display"
        className="text-center"
        color="white"
      >
        {multiplier}
        <span>x</span>
      </Typography>
      <Typography
        as="p"
        variant="text2xs"
        md={{ variant: "textXs" }}
        lg={{ variant: "textSm" }}
        className="text-center"
        color="white"
      >
        Multiplier Active for 12 Month CULP Stakes!
      </Typography>
    </>
  );
  if (isStakingPage) {
    return <GradientBorderCard {...props}>{badge}</GradientBorderCard>;
  }
  return (
    <GradientLeaderboardBorderCard {...props}>
      {badge}
    </GradientLeaderboardBorderCard>
  );
};

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  min-height: 122px;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  background-color: ${({ $selected }) =>
    $selected ? "#B27AE0;" : "transparent"};

  filter: ${({ $selected }) => ($selected ? "" : "grayscale(40%)")};

  &::after {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    background: linear-gradient(to right, #ffc600, #ff005b, #0090ff, #00ffdb);
    z-index: -1;
    border-radius: 11px;
    display: ${({ $selected }) => ($selected ? "block" : "none")};
  }
`;

const CardImage = styled.img`
  max-width: 55px;
  height: auto;
  @media (${device.md}) {
    max-width: 65px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
`;

const TitleWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const InfoIcon = styled(Icon)`
  width: 24px;
  cursor: pointer;

  &:hover {
    & svg {
      fill-opacity: 1;
    }
  }
`;

const rewards = [
  {
    title: "XAI",
    src: xai,
  },
  {
    title: "Progression Bundle",
    src: progressionBundle,
  },
  {
    title: "Mythic Lootbox",
    src: mythicLootbox,
  },
];

export const StakingCard = ({ title, hasRewards, selected, month }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <CardContainer $selected={selected}>
      {hasRewards && (
        <ImageContainer>
          {rewards.map((reward, index) => (
            <CardImage
              src={reward.src}
              alt={reward.title}
              key={`${reward.title}-${index}`}
            />
          ))}
        </ImageContainer>
      )}
      <TitleWrap>
        <Typography
          as="p"
          variant="textXl"
          md={{ variant: "displayXs" }}
          family="display"
          className="text-center"
          color="white"
        >
          {title}
        </Typography>
        {hasRewards && (
          <>
            <InfoIcon name="info" onClick={toggleModal} />
            <LeaderboardModal
              modal={showModal}
              toggleModal={toggleModal}
              month={month}
              selected={selected}
            />
          </>
        )}
      </TitleWrap>
    </CardContainer>
  );
};

const LeaderboardWrap = styled.div`
  padding-bottom: 20px;
  @media (${device.lg}) {
    padding-bottom: 40px;
  }
`;

export function LeaderboardStakingTables({ leaderboard }) {
  const renderBody = (leaderboard) => {
    return (
      <LeaderboardWrap>
        <LeaderboardTableWithPagination
          renderReward={(row) =>
            getLeaderboardRewards(row.rank, stakingRewardsMay)
          }
          renderReward2={(row) =>
            getLeaderboardRewards(row.rank, stakingRewardsEnding)
          }
          rewardHeading="May Snapshot"
          rewardHeading2="Ending Snapshot"
          headerBackgroundColor={LEADERBOARD_TABS}
          headerPattern="labyrinth"
          leaderboard={leaderboard?.data}
          LEADERBOARD_ID={LEADERBOARD_ID}
          style={{ borderRadius: 0 }}
          currentUserColor="#570B7A"
          color="#253B6E"
        />
      </LeaderboardWrap>
    );
  };

  return (
    <>
      <LeaderboardSummary
        leaderboard={leaderboard}
        headerTitle="Leaderboard"
        renderBody={renderBody}
        headerBackgroundColor={HEADER_COLOR}
        headerPattern="labyrinth"
        headerColor={TABS_COLOR}
        gradient={BACKGROUND_COLOR_ALT}
        tabs={LEADERBOARD_TABS}
      />
    </>
  );
}
