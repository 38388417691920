import { useMemo } from "react";
import styled from "styled-components";
import { ShadowcornTokenTypes, StakingTokenTypes } from "../staking/constants";
import { TokenCard } from "./TokenCard";
import { Button } from "../base/Button";
import { Link } from "react-router-dom";
import { device } from "../../theme/mediaQuery";
import { CRYPTO } from "../../consts/externalLinks";
import { useGetClaimableRewardsWrongNetwork } from "../../query/vault";
import {
  // useAtom,
  useAtomValue,
  useSetAtom,
} from "jotai";
import { walletConnectedAtom } from "../../store";
import { StakingRewardClaimableButton } from "../staking/rewards/StakingRewardClaimableButton";
import { useActiveWalletChain } from "thirdweb/react";
import { ErrorModal } from "../staking/Modals/ErrorModal";
import { StakingBadgesNotification } from "../staking/rewards/StakingRewardsNotifications";
import {
  useClaimRewards,
  useClaimSCRewards,
  useGetClaims,
  useGetSCClaims,
} from "../../query/claim";
import { genericErrorModalAtom } from "../../store/staking";
import { GenericErrorModal } from "../staking/Modals/GenericErrorModal";

const Row = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: column;
  @media (${device.lg}) {
    flex-direction: row;
  }
  @media (${device.xl}) {
    flex-wrap: nowrap;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (${device.md}) {
    gap: 40px;
  }
  @media (${device.lg}) {
    gap: 60px;
  }
`;

const defaultCURewards = [{ token: StakingTokenTypes.cu, amount: 0 }];
const defaultCULPRewards = [
  { token: StakingTokenTypes.cu, amount: 0 },
  // { token: StakingTokenTypes.arb, amount: 0 },
  { token: StakingTokenTypes.grail, amount: 0 },
  { token: StakingTokenTypes.xai, amount: 0 },
];

export function StakingTokensCards({ switchNetwork, chain }) {
  const isWalletConnected = useAtomValue(walletConnectedAtom);
  const { data = [], isLoading } = useGetClaimableRewardsWrongNetwork();

  const stakedRewards = data?.filter((reward) => {
    return reward.isStaked;
  });

  const stakedCU = stakedRewards?.filter((reward) => {
    return reward.token === StakingTokenTypes.cu;
  })[0];

  const claimableCUExists = stakedCU?.claimable?.some(
    (reward) => reward.exists
  );

  const stakedCULP = stakedRewards?.filter((reward) => {
    return reward.token === StakingTokenTypes.culp;
  })[0];
  const claimableCULPExists = stakedCULP?.claimable?.some(
    (reward) => reward.exists
  );

  const isCUEmpty = stakedCU?.claimable
    ? stakedCU.claimable.length === 0
    : true;
  const isCULPEmpty = stakedCULP?.claimable
    ? stakedCULP.claimable.length === 0
    : true;

  // get time to next day at 04:45 in UTC
  const nextDayInUTC = Date.UTC(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate() + 1,
    4,
    45,
    0,
    0
  );

  const StakingButton = (token) => (
    <Button
      variant={isWalletConnected ? "primary" : "secondary"}
      size="lg"
      as={Link}
      to="/staking/overview"
      // target="_blank"
      // rel="noopener noreferrer"
    >
      Stake {token}
    </Button>
  );

  return (
    <Container>
      <Row>
        <TokenCard
          title={`${StakingTokenTypes.cu} Staking`}
          subtitle="Stake CU tokens to earn rewards daily!"
          isLoading={isLoading}
          walletNotConnectedButton={StakingButton(StakingTokenTypes.cu)}
          secondaryButton={
            <StakingRewardClaimableButton
              token={stakedCU?.token}
              reward={stakedCU}
              deposits={stakedCU?.deposits}
              disabled={!claimableCUExists}
              switchNetwork={switchNetwork}
              chain={chain}
            />
          }
          primaryButton={StakingButton(StakingTokenTypes.cu)}
          tokens={
            isWalletConnected && !isCUEmpty
              ? stakedCU?.claimable
              : defaultCURewards
          }
          tooltip={{
            desc: "Stake CU tokens to vote on the future direction of CU and earn rewards! The longer you stake, the higher the share of rewards you will receive.",
          }}
          distributionDate={nextDayInUTC}
        />
        <TokenCard
          title={`${StakingTokenTypes.culp} Staking`}
          subtitle="Stake CULP tokens to earn rewards daily!"
          isLoading={isLoading}
          walletNotConnectedButton={StakingButton(StakingTokenTypes.culp)}
          secondaryButton={
            <StakingRewardClaimableButton
              token={stakedCULP?.token}
              reward={stakedCULP}
              deposits={stakedCULP?.deposits}
              disabled={!claimableCULPExists}
              switchNetwork={switchNetwork}
              chain={chain}
            />
          }
          primaryButton={StakingButton(StakingTokenTypes.culp)}
          tokens={
            isWalletConnected && !isCULPEmpty
              ? stakedCULP?.claimable
              : defaultCULPRewards
          }
          tooltip={{
            desc: "Stake CULP tokens to earn rewards! The longer you stake, the higher the share of rewards you will receive.",
          }}
          distributionDate={nextDayInUTC}
        />
      </Row>
      <ErrorModal variant="secondary" />
      <GenericErrorModal variant="secondary" />
      <StakingBadgesNotification variant="secondary" />
    </Container>
  );
}

const defaultSFRewards = [
  { token: ShadowcornTokenTypes.dark, amount: 0 },
  { token: StakingTokenTypes.unim, amount: 0 },
];
const defaultSCSLPRewards = [
  { token: StakingTokenTypes.cu, amount: 0 },
  { token: StakingTokenTypes.unim, amount: 0 },
];

export function ShadowcornTokensCards({ switchNetwork, chain }) {
  const isWalletConnected = useAtomValue(walletConnectedAtom);
  const setGenericErrorModal = useSetAtom(genericErrorModalAtom);
  const chainId = useActiveWalletChain();
  const wrongChain = chainId?.id !== chain?.id;

  const { data, isLoading } = useGetClaims();
  const claimMutationSC = useClaimRewards(data?.dropper?.batchClaimSC);
  const claimMutationSF = useClaimSCRewards();

  const { data: dataHatchery, isLoading: hatcheryLoading } = useGetSCClaims();

  const loadingSF = hatcheryLoading || dataHatchery === undefined;
  const loadingSupremecy = isLoading || data === undefined;
  // Shadowforge
  const darkMarks = dataHatchery?.rewardDarkMarks;
  const formattedDarkMark = darkMarks?.toLocaleString("en");
  const unimSF = dataHatchery?.rewardUNIM;
  const formattedSFUnim = unimSF?.toLocaleString("en");
  const disabledSF = unimSF?.isZero() || darkMarks?.isZero();

  // SC Supremacy
  const unimSC = data?.dropper?.unimTotal;
  const formattedSCUnim = unimSC?.toString()?.toLocaleString("en");
  const cu = data?.dropper?.cuTotal;
  const formattedCU = cu?.toString()?.toLocaleString("en");
  const disabledSC = unimSC?.isZero() || cu?.isZero();
  const shadowforgeTokens = useMemo(() => {
    return isWalletConnected && formattedDarkMark && formattedSFUnim
      ? [
          { token: ShadowcornTokenTypes.dark, amount: formattedDarkMark },
          { token: StakingTokenTypes.unim, amount: formattedSFUnim },
        ]
      : defaultSFRewards;
  }, [isWalletConnected, formattedDarkMark, formattedSFUnim]);

  const supremacyTokens = useMemo(() => {
    return isWalletConnected && formattedCU && formattedSCUnim
      ? [
          { token: StakingTokenTypes.cu, amount: formattedCU },
          { token: StakingTokenTypes.unim, amount: formattedSCUnim },
        ]
      : defaultSCSLPRewards;
  }, [isWalletConnected, formattedCU, formattedSCUnim]);

  // get time to next day at 04:45 in UTC
  const nextDayInUTC = Date.UTC(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate() + 1,
    4,
    45,
    0,
    0
  );

  const handleClaimSF = async () => {
    claimMutationSF.mutate(null, {
      onSuccess: () => {},
      onError: () => {
        setGenericErrorModal({
          show: true,
        });
      },
    });
  };

  const handleClaimSC = async () => {
    claimMutationSC.mutate(null, {
      onSuccess: () => {},
      onError: () => {
        setGenericErrorModal({
          show: true,
        });
      },
    });
  };

  return (
    <Container>
      <Row>
        <TokenCard
          title="Shadowforge"
          subtitle="Craft Minions to earn rewards daily! Rewards expire after 7 days!"
          walletNotConnectedButton={
            <Button variant="secondary" size="lg" as={Link} to="/">
              Buy a Shadowcorn
            </Button>
          }
          secondaryButton={
            <Button
              variant={wrongChain ? "primary" : "secondary"}
              size="lg"
              onClick={() =>
                wrongChain ? switchNetwork(chain) : handleClaimSF()
              }
              isLoading={claimMutationSF.isPending}
              disabled={
                wrongChain ? false : claimMutationSF.isPending || disabledSF
              }>
              {wrongChain ? "Switch Network" : "Claim"}
            </Button>
          }
          primaryButton={
            <Button
              variant="primary"
              size="lg"
              as={Link}
              to={CRYPTO.SHADOWCORNS}
              target="_blank">
              Go to shadowforge
            </Button>
          }
          tokens={shadowforgeTokens}
          tooltip={{
            desc: "Earn rewards daily by crafting minions! The higher the tier of minion you craft, the higher the share of rewards you will recieve.",
          }}
          distributionDate={nextDayInUTC}
          isLoading={loadingSF}
        />
        <TokenCard
          title="Shadowcorn supremacy"
          subtitle="Hold a Shadowcorn to earn rewards daily! Rewards expire after 7 days!"
          walletNotConnectedButton={
            <Button variant="secondary" size="lg" as={Link} to="/">
              Buy a Shadowcorn
            </Button>
          }
          secondaryButton={
            <Button
              variant={wrongChain ? "primary" : "secondary"}
              size="lg"
              onClick={() =>
                wrongChain ? switchNetwork(chain) : handleClaimSC()
              }
              isLoading={claimMutationSC.isPending}
              disabled={
                wrongChain ? false : claimMutationSC.isPending || disabledSC
              }>
              {wrongChain ? "Switch Network" : "Claim"}
            </Button>
          }
          primaryButton={
            <Button
              variant="primary"
              size="lg"
              as={Link}
              to={CRYPTO.SHADOWCORNS}
              target="_blank">
              Visit sc earnings
            </Button>
          }
          tokens={supremacyTokens}
          tooltip={{
            desc: "Amass a Shadowcorn and Minion Army to increase your Shadowcorn Earning rewards! The more Shadowcorns and Minions you hold, the higher the share of rewards you will receive. ",
          }}
          distributionTag="Rewards distributed daily"
          isLoading={loadingSupremecy}
        />
      </Row>
    </Container>
  );
}
