import styled from "styled-components";
import React from "react";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { NavMenuSectionLink } from "./NavMenuSectionLink";
import { NavMenuSocialsFooter } from "./NavMenuSocialsFooter";
import { COMMUNITY } from "../../consts/externalLinks";

const LINKS = [
  {
    title: "Governance",
    subtitle: "Help shape the future of Crypto Unicorns!",
    path: COMMUNITY.GOVERNANCE,
  },
  {
    title: "Forums",
    subtitle: "Take part in our governance process via the Forum",
    path: COMMUNITY.FORMUS,
  },
  {
    title: "Join the Community",
    subtitle: "Join our active and welcoming community!",
    path: COMMUNITY.DISCORD,
  },
  {
    title: "News",
    subtitle: "Latest news from Crypto Unicorns",
    path: COMMUNITY.DISCORD,
  },
];

const COMMUNITY_TOOLS_SECTION = {
  title: "Community Tools",
  links: [
    {
      title: "UniWu",
      subtitle: "A community-managed tool for streamlined game analysis",
      path: COMMUNITY.UNIWU,
    },
  ],
};

const Wrap = styled.nav`
  padding: 32px 26px;
`;

const SectionWrap = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 15px;
`;

// const SectionHeading = styled.h3`
//   font-family: ${({ theme }) => theme.fonts.display};
//   font-size: 22px;
//   color: ${({ theme }) => theme.palette.darkBlue300};
//   padding: 10px;
// `;

export function NavCommunityMenu() {
  return (
    <>
      <Wrap>
        <Row>
          {LINKS.map((link, index) => (
            <Col key={index} xs={12} sm={6} lg={4}>
              <NavMenuSectionLink
                path={link.path}
                title={link.title}
                subtitle={link.subtitle}
                target="_blank"
              />
            </Col>
          ))}
          <SectionWrap>
            {/* <SectionHeading>{COMMUNITY_TOOLS_SECTION.title}</SectionHeading> */}
            {COMMUNITY_TOOLS_SECTION.links.map((link, linkIndex) => (
              <NavMenuSectionLink
                key={linkIndex}
                path={link.path}
                title={link.title}
                subtitle={link.subtitle}
              />
            ))}
          </SectionWrap>
        </Row>
      </Wrap>
      <NavMenuSocialsFooter />
    </>
  );
}
