import styled from "styled-components";
import rank_1 from "../../../img/leaderboard/rank-1.png";
import rank_2 from "../../../img/leaderboard/rank-2.png";
import rank_3 from "../../../img/leaderboard/rank-3.png";
import { Typography } from "../../base/Typography";
import { device } from "../../../theme/mediaQuery";

const ranks = [rank_1, rank_2, rank_3];

const RankWrap = styled.div`
  width: 25px;
  text-align: center;

  & > img {
    width: 100%;
  }

  @media (${device.lg}) {
    width: 40px;
  }
`;

export function Rank({ rank, size = "lg" }) {
  const textSize = size === "lg" ? "displaySm" : "displayXs";
  // check if rank is in range
  if (rank > ranks.length || rank < 1) {
    return (
      <RankWrap>
        <Typography
          color="inherit"
          family="display"
          variant="textLg"
          lg={{ variant: textSize }}
        >
          {rank}
        </Typography>
      </RankWrap>
    );
  }

  const image = ranks[rank - 1];
  return (
    <RankWrap>
      <img src={image} alt="rank" />
    </RankWrap>
  );
}
