import styled from "styled-components";
import { Box } from "../base/Box";
import candy_unicorn from "../../img/unicorns/candy.png";
import moon_unicorn from "../../img/unicorns/moon.png";
import flower_unicorn from "../../img/unicorns/flower.png";
import crystal_unicorn from "../../img/unicorns/crystal.png";
import heart_unicorn from "../../img/unicorns/heart.png";
import star_unicorn from "../../img/unicorns/star.png";
import rainbow_unicorn from "../../img/unicorns/rainbow.png";
import omnom_unicorn from "../../img/unicorns/omnom.png";
import cloud_unicorn from "../../img/unicorns/cloud.png";
import { Typography } from "../base/Typography";
import { ClassIcon } from "./ClassIcon";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { device } from "../../theme/mediaQuery";
import { BUY } from "../../consts/externalLinks";

export const unicornsMap = {
  candy: {
    image: candy_unicorn,
    background: "radialMint",
    footerBackground: "linear-gradient(180deg, #02394D 0%, #053D52 100%)",
  },
  moon: {
    image: moon_unicorn,
    background: "radialBlue",
    footerBackground: "linear-gradient(180deg, #001C44 0%, #072D5D 100%)",
  },
  flower: {
    image: flower_unicorn,
    background: "radialGreen",
    footerBackground: "linear-gradient(180deg, #064D1B 0%, #0F521C 100%)",
  },
  crystal: {
    image: crystal_unicorn,
    background: "radialViolet",
    footerBackground: "linear-gradient(180deg, #431493 0%, #4F159D 100%)",
  },
  heart: {
    image: heart_unicorn,
    background: "radialPink",
    footerBackground: "linear-gradient(180deg, #7A003B 0%, #8B004B 100%)",
  },
  star: {
    image: star_unicorn,
    background: "radialYellow",
    footerBackground: "linear-gradient(180deg, #BD6D01 0%, #DA9B02 100%)",
  },
  rainbow: {
    image: rainbow_unicorn,
    background: "radialRed",
    footerBackground: "linear-gradient(180deg, #B93119 0%, #760E0D 100%)",
  },
  omnom: {
    image: omnom_unicorn,
    background: "radialOrange",
    footerBackground: "linear-gradient(180deg, #D06117 0%, #83260A 100%)",
  },
  cloud: {
    image: cloud_unicorn,
    background: "radialGray",
    footerBackground: "linear-gradient(180deg, #567A93 0%, #1B333D 100%)",
  },
};

const CharacterCardWrap = styled(Box)`
  overflow: visible;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CharacterCardBody = styled.div`
  padding: 20px 25px;
  margin: auto 0;

  @media (${device.lg}) {
    padding: 40px 35px;
    margin: initial;
  }
`;

const CharacterCardImage = styled.img`
  height: 100%;
`;

const CharacterCardImageWrap = styled.div`
  position: relative;
  aspect-ratio: 1/1;
  width: 70%;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  @media (${device.md}) {
    width: 100%;
  }
`;

const CharacterCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  height: 100%;
`;

const NameWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  justify-content: center;

  @media (${device.md}) {
    justify-content: flex-start;
  }
`;

const CharacterCardFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  padding: 23px 32px;
  position: relative;
  z-index: 1;

  @media (${device.xl}) {
    flex-direction: row;
  }
`;

const ClassIconWrap = styled(ClassIcon)`
  width: 33px;
  height: 33px;

  @media (${device.xl}) {
    width: 43px;
    height: 43px;
  }
`;

const CtaLinkWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: center;

  @media (${device.xl}) {
    margin-left: auto;
  }
`;

const CharacterCardFooterWrap = styled.div`
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-image: ${({ $background }) => $background};
  margin-top: auto;
`;

const FooterLink = styled.a`
  height: 20px;
  text-decoration: none;

  color: ${({ theme }) => theme.palette.white};
  &:hover{
    color: ${({ theme }) => theme.palette.slate100};
  }
`;

// const FooterLinkImage = styled.img`
//   height: 100%;
//   vertical-align: unset;
// `;

export function CharacterCard({ unicornClass, description }) {
  const img = unicornsMap[unicornClass]?.image;
  const background = unicornsMap[unicornClass]?.background;
  const footerBackground = unicornsMap[unicornClass]?.footerBackground;
  const urlFilter =
    unicornClass.charAt(0).toUpperCase() + unicornClass.slice(1);
  return (
    <CharacterCardWrap
      variant="gradient"
      pattern="labyrinth"
      gradient={background}
      rounded="md"
      patternSize="50%"
    >
      <CharacterCardBody>
        <Row>
          <Col xs={12} md={5} className="mb-3 mb-lg-0">
            <CharacterCardImageWrap>
              <CharacterCardImage src={img} />
            </CharacterCardImageWrap>
          </Col>

          <Col xs={12} md={7}>
            <CharacterCardDetails>
              <NameWrap>
                <ClassIconWrap name={unicornClass} />
                <Typography
                  variant="displaySm"
                  tag={"p"}
                  family={"display"}
                  lg={{
                    variant: "displayMd",
                  }}
                >
                  {unicornClass}
                </Typography>
              </NameWrap>
              <Typography
                variant="textSm"
                weight={600}
                tag={"p"}
                lg={{
                  variant: "textMd",
                }}
              >
                {description}
              </Typography>
            </CharacterCardDetails>
          </Col>
        </Row>
      </CharacterCardBody>
      <CharacterCardFooterWrap $background={footerBackground}>
        <CharacterCardFooter>
          <Typography
            variant="textXs"
            tag={"p"}
            family={"display"}
            lg={{
              variant: "textSm",
            }}
          >
            ready to raise a {unicornClass} unicorn?
          </Typography>
          <CtaLinkWrap>
            <FooterLink
              target="_blank"
              href={`${BUY.MARKETPLACE.UNICORN}?search%5BstringTraits%5D%5B0%5D%5Bname%5D=Class&search%5BstringTraits%5D%5B0%5D%5Bvalues%5D%5B0%5D=${urlFilter}`}
            >
              <Typography
                variant="textXs"
                tag={"p"}
                family={"display"}
                className="ms-auto"
                lg={{
                  variant: "textSm",
                }}
                color="inherit"
              >
                get one from our Marketplace
              </Typography>
            </FooterLink>
          </CtaLinkWrap>
        </CharacterCardFooter>
      </CharacterCardFooterWrap>
    </CharacterCardWrap>
  );
}
