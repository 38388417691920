import styled from "styled-components";
import { Portal } from "../common/Portal";
import { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

const MIN_OFFSET_TO_RIGHT = 200;

const TooltipOverlay = styled.div`
  position: absolute;
  --transform-x-offset-begin: ${({ $alternate }) =>
    $alternate ? "-85%" : "-10px"};
  --transform-x-offset-end: ${({ $alternate }) => ($alternate ? "-100%" : "0")};
  transform: translateX(var(--transform-x-offset-end));
  z-index: 9999;

  &.fade-side-enter {
    opacity: 0;
    transform: translateX(var(--transform-x-offset-begin));
  }

  &.fade-side-enter-active {
    opacity: 1;
    transform: translateX(var(--transform-x-offset-end));
    transition: opacity 200ms, transform 200ms;
  }

  &.fade-side-exit {
    opacity: 1;
    transform: translateX(var(--transform-x-offset-end));
  }

  &.fade-side-exit-active {
    opacity: 0;
    transform: translateX(var(--transform-x-offset-begin));
    transition: opacity 200ms, transform 200ms;
  }
`;

const TooltipContent = styled.div`
  transform: ${({ $isTop }) => ($isTop ? "translate(-50%, -105%)" : null)};
`;

const TooltipBody = styled.div`
  position: relative;
  background: ${({ theme, $background }) =>
    $background ? $background : theme.palette.white};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.55);
  border-radius: 16px;
  padding: ${({ $small }) => ($small ? "12px" : "24px")};
  color: ${({ theme, $color }) => ($color ? $color : theme.palette.black)};
  font-family: ${({ theme }) => theme.fonts.display};
  border: 1px solid transparent;
  background-clip: padding-box;
  z-index: 2;
`;

const TooltipBorder = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: linear-gradient(
    165.49deg,
    #cdb2ef -24.35%,
    rgba(205, 178, 239, 0) 45.74%
  );
  border-radius: 16px;
  z-index: 1;
`;

const TooltipWrap = styled.div`
  cursor: pointer;
  width: fit-content;
`;

export function TooltipCard({
  children,
  color,
  background,
  overlay,
  position = "auto",
  bodyStyle,
  isSmall = false,
  ...rest
}) {
  const overlayRef = useRef(null);
  const parentRef = useRef(null);

  const [coords, setCoords] = useState({ left: 0, top: 0 });
  const [alternateSide, setAlternateSide] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const isPositionTop = position === "top";

  const activateTooltip = (left, top, alternate = false) => {
    setCoords({
      left,
      top,
    });
    setAlternateSide(alternate);
    setShowTooltip(true);
  };

  const mouseEnter = () => {
    // on mouse enter, get the coordinates of the element
    const rect = parentRef.current.getBoundingClientRect();

    // if screen width is less than 768px, show the tooltip on top
    if (window.innerWidth < 768) {
      const left = rect.left + rect.width / 4;
      const top = rect.top + window.scrollY + rect.height / 2;

      return activateTooltip(left, top, false);
    }

    // check if there is enough space to the right of the element
    // if not, show the tooltip on the left
    if (rect.left + rect.width + MIN_OFFSET_TO_RIGHT > window.innerWidth) {
      const left = rect.left;
      const top = rect.top + window.scrollY;

      return activateTooltip(left, top, true);
    } else {
      const left = rect.left + rect.width;
      const top = rect.top + window.scrollY;

      return activateTooltip(left, top, false);
    }
  };

  const mouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <TooltipWrap
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      ref={parentRef}
      {...rest}
    >
      {children}
      <Portal>
        <CSSTransition
          timeout={300}
          classNames="fade-side"
          in={showTooltip}
          unmountOnExit
        >
          <TooltipOverlay
            ref={overlayRef}
            $alternate={alternateSide}
            style={{ left: coords.left, top: coords.top }}
          >
            <TooltipContent $isTop={isPositionTop}>
              <TooltipBorder />
              <TooltipBody
                $background={background}
                $color={color}
                $small={isSmall}
              >
                {overlay}
              </TooltipBody>
            </TooltipContent>
          </TooltipOverlay>
        </CSSTransition>
      </Portal>
    </TooltipWrap>
  );
}
