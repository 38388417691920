import { useMutation, useQuery } from "@tanstack/react-query";
import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import { useAtomValue } from "jotai";
import {
  currentWalletAddressAtom,
  signerAtom,
  walletConnectedAtom,
} from "../../store";
import { useActiveWalletChain } from "thirdweb/react";

export function useWeb3Query({ queryKey, queryFn, enabled = true, ...rest }) {
  //const [{ connectedChain }] = useSetChain();
  const chain = useActiveWalletChain();
  // we need to pass chainId to the queryKey to invalidate the query when chain changes
  const chainId = chain?.id;
  const wallet = useAtomValue(signerAtom);
  //const url = wallet?.provider?.connection?.url;
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const walletConnected = useAtomValue(walletConnectedAtom);
  const queryEnabled = walletConnected && !!wallet && enabled;
  // console.log("signer atom", wallet);
  return useQuery({
    queryKey: [...queryKey, walletAddress, chainId],
    queryFn: async () => {
      if (!walletConnected || !wallet) return;
      const result = await queryFn({
        wallet,
        address: walletAddress,
      });

      return result;
    },
    enabled: queryEnabled,
    ...rest,
  });
}

export function useWeb3QueryWrongChain({
  queryKey,
  queryFn,
  enabled = true,
  ...rest
}) {
  //const [{ connectedChain }] = useSetChain();
  //const chain = useActiveWalletChain();
  // we need to pass chainId to the queryKey to invalidate the query when chain changes
  // const chainId = chain?.id;
  const wallet = useAtomValue(signerAtom);
  //  const url = wallet?.provider?.connection?.url;
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const walletConnected = useAtomValue(walletConnectedAtom);
  const queryEnabled = walletConnected && !!wallet && enabled;

  return useQuery({
    queryKey: [...queryKey, walletAddress],
    queryFn: async () => {
      if (!walletConnected || !wallet) return;

      const result = await queryFn({
        wallet,
        address: walletAddress,
      });

      return result;
    },
    enabled: queryEnabled,
    ...rest,
  });
}

export function useWeb3QueryWithoutWallet({
  queryKey,
  queryFn,
  enabled = true,
  ...rest
}) {
  const queryEnabled = enabled;

  return useQuery({
    queryKey,
    queryFn: async () => {
      const result = await queryFn();

      return result;
    },
    enabled: queryEnabled,
    ...rest,
  });
}

//
export function useWeb3Mutation({
  mutationFn,
  // onSuccess,
  // invalidateKeys = [], // an array of query keys to invalidate on success
  ...rest
}) {
  const wallet = useAtomValue(signerAtom);
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const walletConnected = useAtomValue(walletConnectedAtom);
  const enabled = walletConnected && !!wallet;
  return useMutation({
    mutationFn: async (payload) => {
      if (!walletConnected || !wallet) return;

      const result = await mutationFn(
        { wallet, address: walletAddress },
        payload
      );

      return result;
    },
    enabled,
    // onSuccess: data => {
    //   // we need to pass wallet and walletAddress to onSuccess
    //   onSuccess && onSuccess(data, {
    //     wallet,
    //     walletAddress,
    //   });
    //
    //   // // if invalidateKeys is not empty, invalidate queries
    //   // if (invalidateKeys.length > 0) {
    //   //   invalidateKeys.forEach(key => {
    //   //     invalidateWeb3Query(key, walletAddress);
    //   //   });
    //   // }
    // },
    onError: (error) => {
      console.error("There was an error with the mutation: ", error);
    },
    ...rest,
  });
}

//
// export function useWeb3InfiniteQuery({ queryKey, queryFn, enabled = true, ...rest }) {
//   const [{ wallet }] = useConnectWallet();
//   const walletAddress = useAtomValue(currentWalletAddressAtom);
//   const walletConnected = useAtomValue(walletConnectedAtom);
//   const queryEnabled = walletConnected && !!wallet && enabled;
//
//   return useInfiniteQuery({
//     queryKey: [...queryKey, walletAddress],
//     queryFn: async ({ pageParam }) => {
//       if (!walletConnected || !wallet) return;
//
//       const result = await queryFn({
//         wallet, walletAddress,
//       }, pageParam);
//
//       return result;
//     },
//     enabled: queryEnabled,
//     ...rest,
//   });
// }
//
// export function useWeb3Queries(queries = []) {
//   const [{ wallet }] = useConnectWallet();
//   const walletAddress = useAtomValue(currentWalletAddressAtom);
//   const walletConnected = useAtomValue(walletConnectedAtom);
//   const queryEnabled = walletConnected && !!wallet;
//
//   return useQueries({
//     queries: queries.map(query => {
//       const { queryFn, queryKey, enabled, ...rest } = query;
//
//       return {
//         queryKey: [...queryKey, walletAddress],
//         queryFn: async () => {
//           if (!queryEnabled) return;
//
//           const result = await queryFn({
//             wallet,
//             walletAddress,
//           });
//
//           return result;
//         },
//         enabled: enabled && queryEnabled,
//         ...rest,
//       };
//     }),
//   });
// }
//
// export function useGetWeb3QueryKey(queryKey) {
//   const walletAddress = useAtomValue(currentWalletAddressAtom);
//
//   return [...queryKey, walletAddress];
// }
