import styled, { keyframes } from "styled-components";
import { Tooltip } from "./Tooltip";

const ProgressBarWrapper = styled.div`
  background-color: ${({ theme, $background }) =>
    $background ? $background : theme.palette.white};
  // background-color: linear-gradient(180deg, blue, red);
  // background: linear-gradient(#ffffff, #f0f0f0);
  border-radius: 50px;
  width: 100%;
  height: 40px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
`;

const moveAnimation = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
`;

const ProgressBarInner = styled.div`
  background: ${({ $background, theme }) =>
    theme.gradients[$background]
      ? theme.gradients[$background]
      : $background
      ? $background
      : theme.palette.darkBlue300};
  width: ${({ $width }) => $width}%;
  height: 100%;
  border-radius: 50px;
  position: relative;
  // box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1),
  //   0 1px 4px -1px rgba(0, 0, 0, 0.06), inset 0 -4px 7px -2px rgba(0, 0, 0, 0.1);
  box-shadow: ${({ $boxShadow }) =>
    $boxShadow
      ? $boxShadow
      : "0 2px 6px -1px rgba(0, 0, 0, 0.1), 0 1px 4px -1px rgba(0, 0, 0, 0.06), inset 0 -15px 20px -2px rgba(0, 0, 0, 0.2)"};

  & > span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: ${({ $animated }) =>
      $animated
        ? "linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)"
        : "unset"};

    z-index: 1;
    background-size: 50px 50px;
    animation: ${moveAnimation} 4s linear infinite;
    animation: ${({ $animated }) => $animated && "unset"};
    overflow: hidden;
  }
`;

const TooltipWrap = styled.div`
  max-width: 382px;
  color: ${({ $color }) => $color && $color};
  font-weight: 700;
`;
export function ProgressBar({
  now,
  color,
  background,
  animated = true,
  boxShadow,
  tooltip = null,
  tooltipColor,
}) {
  let width = 0;
  // check if now is a number
  if (!isNaN(now)) {
    width = now;
  }

  if (tooltip) {
    return (
      <Tooltip
        overlay={<TooltipWrap $color={tooltipColor}>{tooltip}</TooltipWrap>}
        border="md"
        large
        id="whatishapp"
        style={{ width: "100%", zIndex: "0" }}
      >
        <ProgressBarWrapper $background={background}>
          <ProgressBarInner
            $width={width}
            $background={color}
            $animated={animated}
            $boxShadow={boxShadow}
          >
            <span></span>
          </ProgressBarInner>
        </ProgressBarWrapper>
      </Tooltip>
    );
  }
  return (
    <ProgressBarWrapper $background={background}>
      <ProgressBarInner
        $width={width}
        $background={color}
        $animated={animated}
        $boxShadow={boxShadow}
      >
        <span></span>
      </ProgressBarInner>
    </ProgressBarWrapper>
  );
}
