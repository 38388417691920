import React, { useRef } from "react";
import { CharacterCard } from "../../common/CharacterCard";
import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import { FreeMode } from "swiper";
import { device } from "../../../theme/mediaQuery";
import { Icon } from "../../base/Icon";

const UNICORNS = [
  {
    name: "crystal",
    description:
      "While all Unicorns are carefree and cheerful, Crystals have a more calm, placid demeanor that belies their great endurance and steadfastness - they are rock-solid, after all!",
  },
  {
    name: "heart",
    description:
      "It's no surprise that Heart Unicorns are the most loving of all, and whenever someone's in trouble, you can bet a Heart is on the way to help! Known as tough and clever, these Unicorns are a great addition to any stable.",
  },
  {
    name: "star",
    description:
      "Stars are… well, they're out there! Flamboyant and shrewd, Star 'Corns love to take center stage and bathe in the limelight. Not only do these Unicorns enjoy being the group rockstar, they have the quick wit and drive to make it happen! Many a 'Corn has been caught off-guard by the charming twinkle of the Stars!",
  },
  {
    name: "rainbow",
    description:
      "If there is one thing that the Unicorn world can depend on, it is the unflappable party spirit of the Rainbow - these 'Corns can always be found having a great time, even in the direst of circumstances. Known for their quick thinking and big hearts, it is not uncommon to find Rainbow Unicorns foiling the plans of even the most clever Star 'Corns!",
  },
  {
    name: "omnom",
    description:
      "If one had to sum up OmNom unicorns in one word, it would probably be - hearty. Known for being big, loud, and boisterous, one couldn't imagine a good party without an OmNom 'Corn adding to the fun - and it's better to keep them on your side, because you don't want them for an enemy! For all the things that can be said about these Unicorns, they cannot be called sweet!",
  },
  {
    name: "cloud",
    description:
      "Laughing one moment and aggressive the next, Clouds have a reputation as mercurial Unicorns that are some of the best friends to have, but when they get worked up into a powerful storm, the worst enemies! This bloodline is known for occasional rays of sunlight that beam through with bright, intelligent minds.",
  },
  {
    name: "candy",
    description:
      "These Unicorns are sweet, clever, and usually surprisingly tough! Candies are known for sticking up for their friends, and therefore are usually surrounded by groups of admirers. Once in a long while, though, this bloodline produces Unicorns that are much more savory than sweet.",
  },
  {
    name: "moon",
    description:
      "Playful and capable, these Unicorns are quintessential space explorers, and their stunning appearance reflects that! Their bloodline is known mainly for its speed, but rare, star-struck eggs are sometimes hatched with a strong magic potency.",
  },
  {
    name: "flower",
    description:
      "Fast as a leaf on the wind, Flowers are known for blazing quickness and keen minds. Blooming with persuasiveness, these Unicorns are typically found in positions of leadership, able to sway those around them with flowery rhetoric.",
  },
];

const SwiperWrap = styled(Swiper)`
  overflow: visible;
`;

const SwiperSlideWrap = styled(SwiperSlide)`
  padding: 10px 0;
  height: auto;
`;

const SwiperArrow = styled(Icon)`
  width: 35px;
  height: 35px;
  z-index: 2;
  opacity: 0.;
  display: none;


  @media (${device.sm}) {
    display: inline;


    & svg {
      filter: invert(25%) sepia(56%) saturate(574%) hue-rotate(164deg)
        brightness(100%) contrast(94%);
    }

    &:hover {
      cursor: pointer;
      opacity: 0.6;
      transform: scale(1.05);
      transition: all 0.2s ease-in-out;
    }
  }
`;

const SwiperButtonsWrap = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  display: flex;
  top: 50%;
  width: 100%;
  opacity: 0.9;
  z-index: 1;

  @media (${device.sm}) {
    position: relative;
    justify-content: center;
    column-gap: 100px;
    margin-top: 12px;
    }
`;

export function HomeCharacterGrid() {
  const swiperRef = useRef();

  return (
    <SwiperWrap
      spaceBetween={16}
      slidesPerView={1.2}
      breakpoints={{
        1200: {
          slidesPerView: 1.7,
        },
      }}
      modules={[FreeMode]}
      centeredSlides={true}
      grabCursor={true}
      loop={true}
      onBeforeInit={(swiper) => {
        swiperRef.current = swiper;
      }}
    >
      {UNICORNS.map((unicorn) => (
        <SwiperSlideWrap key={unicorn.name}>
          <CharacterCard
            unicornClass={unicorn.name}
            description={unicorn.description}
          />
        </SwiperSlideWrap>
      ))}
      <SwiperButtonsWrap>
        <SwiperArrow
          name="arrow-left"
          onClick={() => swiperRef.current?.slidePrev()}
        />
        <SwiperArrow
          name="arrow-right"
          onClick={() => swiperRef.current?.slideNext()}
        />
      </SwiperButtonsWrap>
    </SwiperWrap>
  );
}
