import React, { useState } from "react";
import styled from "styled-components";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useConnectWallet } from "@web3-onboard/react";
import { Typography } from "../../base/Typography";
import { Button } from "../../base/Button";
import { device } from "../../../theme/mediaQuery";
import { EVENT_KEYS } from "../../../consts/googleEvents";
import { googleAnalyticsEvent } from "../../../helpers/googleAnalytics";
import { currentWalletAddressAtom, signerAtom } from "../../../store";
import { StakingCallout } from "./StakingCallout";
import Input from "./Input";
import { TOKEN_NAMES, TOKENS } from "./TokenSelect";
import { InfoModal } from "./InfoModal";
import { Icon } from "../../base/Icon";
import { ErrorMessage } from "./StakeModal";
import { StatCard } from "./StatCard";
import Spinner from "../../base/Spinner";
import {
  useAddLiquidity,
  useGetAddLiquidityData,
  useGetReserves,
  useGetVaultOverview,
} from "../../../query/vault";
import {
  liquidityModalOpenedAtom,
  liquiditySuccessModalAtom,
} from "../../../store/staking";
import { calculateLpPairAmount, calculateShareOfPool } from "../helpers";
import Web3 from "web3";
import { BUY } from "../../../consts/externalLinks";
import { getCULPContract } from "../contract";
import { NetworkConfiguration } from "../../Network";
import { POLYGON_NETWORK } from "../../ConnectWallet/WalletHelper";
import { ethers } from "ethers";
import { useConnectModal } from "thirdweb/react";
import { connectWallet } from "../../ConnectWallet/connectWallet";

const InfoModalWrap = styled(InfoModal)`
  flex-direction: column;
  gap: 24px;
  @media (${device.md}) {
    gap: 40px;
  }
`;
const InputFieldWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CUFieldWrap = styled.div`
  position: relative;
`;

const PlusIcon = styled(Icon)`
  width: 36px;
  height: auto;
  position: absolute;
  transform: translateY(-50%);
  left: 50%;
  bottom: -30px;
  transform: translateX(-50%);
  z-index: 1;
  user-select: none;
  @media (${device.md}) {
    width: 44px;
  }
`;

const ButtonWrap = styled(Button)`
  align-self: center;
`;

const STEPS = {
  ONE: 1,
  TWO: 2,
};

const formatNumberInput = (value) => {
  // Remove all non-digits and replace multiple decimals with a single decimal
  let formatted = value.replace(/[^0-9.]+/g, "").replace(/(\..*)\./g, "$1");

  // Split at the decimal point
  let parts = formatted.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas
  if (parts[1]) {
    parts[1] = parts[1].slice(0, 8) || ""; // Ensure there is a decimal point
  }
  let newString = parts.join(".");
  return newString;
};

export function LiquidityModal() {
  const wallet = useAtomValue(signerAtom);
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const [showModal, setShowModal] = useAtom(liquidityModalOpenedAtom);
  const { data = {} } = useGetVaultOverview();
  const addLiquidityMutation = useAddLiquidity();
  const setSuccessModal = useSetAtom(liquiditySuccessModalAtom);
  const [cuQuantity, setCuQuantity] = useState("");
  const [wethQuantity, setWethQuantity] = useState("");
  const [step, setStep] = useState(STEPS.ONE);
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];

  //const web3 = new Web3(wallet?.provider);

  function convertToWei(input) {
    return ethers.utils.parseUnits(input, "ether");
  }

  const handleModalClose = () => {
    setStep(STEPS.ONE);
    setCuQuantity("");
    setWethQuantity("");
    setShowModal(false);
  };

  const handleConfirm = (payload) => {
    const { sharePool } = payload;
    const cuWei = convertToWei(cuQuantity);
    const wethWei = convertToWei(wethQuantity);
    const prevCulpBalance = data.CULP?.CULPBalance;

    setStep(STEPS.TWO);
    addLiquidityMutation.mutate(
      {
        cu: cuWei,
        weth: wethWei,
      },
      {
        onSuccess: async (result) => {
          const { transactionHash } = result;

          let culpTokenFormated;

          try {
            const culpContract = getCULPContract(wallet);
            // Fetch current balance
            const currentBalance = await culpContract.balanceOf(walletAddress);
            // Find difference for CULP received
            let culpToken = currentBalance - prevCulpBalance;
            culpTokenFormated = Web3.utils.fromWei(
              culpToken.toString(),
              "ether"
            );
          } catch (error) {
            console.error("Error fetching CULP token balance:", error);
          }
          setSuccessModal({
            show: true,
            transaction: {
              hash: transactionHash,
              share: sharePool + "%",
              culp: culpTokenFormated,
            },
          });

          handleModalClose();
        },
        onError: () => {
          handleModalClose();
        },
      }
    );
  };

  return (
    <InfoModalWrap
      toggleModal={handleModalClose}
      showModal={showModal}
      headerTitle="Add liquidity">
      {step === 1 && (
        <Step1
          cuQuantity={cuQuantity}
          setCuQuantity={setCuQuantity}
          wethQuantity={wethQuantity}
          setWethQuantity={setWethQuantity}
          setStep={setStep}
          onConfirm={handleConfirm}
          toggleModal={handleModalClose}
          wallet={wallet}
        />
      )}
      {step === 2 && (
        <Step2 cuQuantity={cuQuantity} wethQuantity={wethQuantity} />
      )}
    </InfoModalWrap>
  );
}

const Step1 = ({
  cuQuantity,
  setCuQuantity,
  wethQuantity,
  setWethQuantity,
  toggleModal,
  onConfirm,
  wallet,
}) => {
  //data._reserve0 = grail, data._reserve1 = eth
  //const [{ wallet }] = useConnectWallet();

  const { data = [] } = useGetReserves();
  const [error, setError] = useState("");
  // const [fetchError, setFetchError] = useState("");

  // const [, connect] = useConnectWallet();
  const { connect } = useConnectModal();
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const { data: balances = [] } = useGetAddLiquidityData();
  let CUBalance = balances.cuBalanceFromWei ? balances.cuBalanceFromWei : 0;
  let ETHBalance = balances.ethBalanceFromWei ? balances.ethBalanceFromWei : 0;

  const web3 = new Web3(wallet?.provider);

  function convertToWei(input) {
    return input ? ethers.utils.parseUnits(input, "ether") : 0;
  }

  const sharePool =
    data._reserve1 && cuQuantity > 0
      ? calculateShareOfPool(cuQuantity, data._reserve1)
      : 0;
  const oneWei = 1 * 10 ** 18;
  const poolData = [
    {
      label: "ETH per CU",
      value: data._reserve0
        ? Number(
            calculateLpPairAmount(0, oneWei, data._reserve0, data._reserve1)
          ).toLocaleString("en-US", { maximumFractionDigits: 5 })
        : 0,
      key: "weth",
    },
    {
      label: "CU per ETH",
      value: data._reserve0
        ? Number(
            calculateLpPairAmount(oneWei, 0, data._reserve0, data._reserve1)
          ).toLocaleString("en-US", { maximumFractionDigits: 5 })
        : 0,
      key: "cu",
    },
    {
      label: "Share of Pool",
      value: data._reserve0 && cuQuantity > 0 ? `${sharePool} %` : "0 %",
      key: "pool",
    },
  ];

  const disable =
    (walletAddress && cuQuantity === "0") ||
    (walletAddress && wethQuantity === "0") ||
    cuQuantity === "" ||
    wethQuantity === "" ||
    error;

  const handleConnect = () => {
    toggleModal();
    connectWallet(connect).then(() => {
      googleAnalyticsEvent(EVENT_KEYS.CONNECT_CLICKED);
    });
  };

  const handleWETHChange = async (e) => {
    if (!e) {
      setCuQuantity("");
      handleAmountError(0, 0);
    }
    const wethAmount = e.replace(/[ ,]+/g, "");
    setWethQuantity(wethAmount);
    const cuAmount = calculateLpPairAmount(
      convertToWei(wethAmount),
      0,
      data._reserve0,
      data._reserve1
    )?.toString();
    setCuQuantity(cuAmount);
    handleAmountError(cuAmount, wethAmount);
  };

  const handleCUChange = async (e) => {
    if (!e) {
      setWethQuantity("");
      handleAmountError(0, 0);
    }
    const cuAmount = e.replace(/[ ,]+/g, "");
    setCuQuantity(cuAmount);
    const wethAmount = calculateLpPairAmount(
      0,
      convertToWei(cuAmount),
      data._reserve0,
      data._reserve1
    )?.toString();
    setWethQuantity(wethAmount);
    handleAmountError(cuAmount, wethAmount);
  };

  const handleAmountError = (cuAmount, wethAmount) => {
    if (
      Number(cuAmount) > Number(CUBalance) ||
      Number(wethAmount) > Number(ETHBalance)
    ) {
      setError("Insufficient Balance");
    } else {
      setError(null);
    }
  };

  const handleConfirm = () => {
    onConfirm({
      sharePool,
    });
  };

  return (
    <>
      <InputFieldWrap>
        <CUFieldWrap>
          <InputField
            token={TOKEN_NAMES.CU}
            value={formatNumberInput(cuQuantity)}
            onValueChange={(e) => handleCUChange(e)}
            balance={CUBalance}
          />
          <PlusIcon name="round-plus" $error={error} />
        </CUFieldWrap>
        <InputField
          token={TOKEN_NAMES.WETH}
          value={formatNumberInput(wethQuantity)}
          onValueChange={(e) => handleWETHChange(e)}
          balance={ETHBalance}
        />
        {error && (
          <ErrorMessage
            variant="textXs"
            md={{ variant: "textSm" }}
            weight={600}
            $error={error}>
            {error}&nbsp;
          </ErrorMessage>
        )}
      </InputFieldWrap>

      <PoolShare data={poolData} />
      {!walletAddress && (
        <ButtonWrap size="lg" onClick={handleConnect}>
          connect wallet
        </ButtonWrap>
      )}
      {walletAddress && (
        <ButtonWrap
          size="lg"
          variant="secondary"
          onClick={handleConfirm}
          disabled={disable}>
          Approve and add liquidity
        </ButtonWrap>
      )}
      <StakingCallout
        buttonText="Purchase more"
        title="Running low on CU or ETH tokens? Click Purchase More to obtain more through Camelot."
        href={BUY.CU}
      />
    </>
  );
};

const Step2Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
`;

const SpinnerWrap = styled.div`
  padding: 8px 2px;
  text-align: center;

  & svg {
    filter: none;
    vertical-align: top;
    width: 50px;
    height: 50px;
    @media (${device.md}) {
      width: 100px;
      height: 100px;
    }
  }
`;

const Step2 = ({ wethQuantity, cuQuantity }) => {
  const formattedWeth = formatNumberInput(wethQuantity);
  const formattedCu = formatNumberInput(cuQuantity);
  return (
    <>
      <SpinnerWrap>
        <Spinner
          gradientColor1="rgba(231, 182, 16, 0.60)"
          gradientColor2="rgba(215, 127, 1, 0.60)"
        />
      </SpinnerWrap>
      <Step2Wrap>
        <Typography
          as="p"
          variant="displaySm"
          md={{ variant: "displayLg" }}
          color="#4730A6"
          family="display">
          Waiting for confirmation
        </Typography>
        <Typography
          as="p"
          variant="textXl"
          md={{ variant: "displayXs" }}
          color="#252525">
          Supplying {formattedWeth} ETH and {formattedCu} CU
        </Typography>
        <Typography variant="textLg" md={{ variant: "textXl" }} color="#252525">
          Confirm this transaction in your wallet.
        </Typography>
      </Step2Wrap>
    </>
  );
};

const InputField = ({ value, onValueChange, token, balance = 0 }) => {
  return (
    <Input
      value={value}
      onChange={onValueChange}
      append={
        <TokenSection
          tokenId={token}
          balance={balance}
          onChange={onValueChange}
        />
      }
    />
  );
};

const BadgeWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 130px;
  background: #f3f3ff;
  width: 160px;
  gap: 10px;
  @media (${device.md}) {
    width: 175px;
  }

  & > * {
    font-weight: 500;
  }

  &::after {
    display: none;
  }
`;

const TokenWrap = styled.img`
  width: 30px;
  height: 30px;
`;

const TokenBadge = ({ tokenId }) => {
  const token = TOKENS[tokenId];
  return (
    <BadgeWrap>
      <TokenWrap src={token.img} alt={`${token.name} logo`} />
      <Typography variant="textXl" color="black" className="me-auto">
        {token.name}
      </Typography>
    </BadgeWrap>
  );
};

const TokenSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  width: 195px;
`;
const TokenButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  justify-content: space-around;
  gap: 10px;

  & > span {
    text-align: left;
    flex-grow: 1;
  }
`;

const MaxButton = styled.button`
  background: #fff;
  color: #5338c2;
  border: 1px solid #5338c2;
  border-radius: 50px;
  font-size: 14px;
  padding: 4px 10px;
  margin-left: auto;
  font-family: ${({ theme }) => theme.fonts.display};

  &:not(:disabled):hover {
    color: #fff;
    background-color: #5338c2;
  }

  &:active {
    filter: brightness(1.1);
  }
`;

const TokenSection = ({ tokenId, balance, onChange }) => {
  const handleMaxValue = () => {
    onChange(balance.toString());
  };
  return (
    <TokenSectionWrap>
      <TokenBadge tokenId={tokenId} />
      <TokenButtonWrap>
        <Typography
          tag="span"
          color="black"
          variant="textMd"
          md={{ variant: "textLg" }}>
          Balance: {balance}
        </Typography>
        <MaxButton
          onClick={handleMaxValue}
          disabled={parseFloat(balance) === 0}>
          Max
        </MaxButton>
      </TokenButtonWrap>
    </TokenSectionWrap>
  );
};

const PoolWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 20px;
`;
const CardContainer = styled.div`
  display: flex;
  gap: 16px;
  align-self: stretch;
  flex-direction: column;
  @media (${device.sm}) {
    flex-direction: row;
  }
`;

const PoolShare = ({ data }) => (
  <PoolWrap>
    <Typography
      as="h2"
      variant="textXl"
      md={{ variant: "displayXs" }}
      color="black"
      className="text-center">
      Initial prices and pool share
    </Typography>
    <CardContainer>
      {data.map((item, index) => (
        <StatCard
          key={`${index}-init-price-share`}
          value={item.value}
          label={item.label}
        />
      ))}
    </CardContainer>
  </PoolWrap>
);
