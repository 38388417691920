import styled from "styled-components";
import { device } from "../../theme/mediaQuery";
import { AppButton } from "../rainbowRumble/GameBanner";
import { GameApkButton } from "./GameApkButton";

const GameButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  padding: 40px;
  gap: 16px;
  width: 100%;
  max-width: 100%;
  @media (${device.lg}) {
    flex-direction: row;
  }

  // children
  & > :first-child {
    @media (${device.lg}) {
      width: 70%;
    }
  }
`;

const MobileLinksWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;

  @media (${device.sm}) {
    flex-direction: row;
  }

  @media (${device.lg}) {
    flex-direction: column;
    width: 30%;
  }

  & a {
    max-width: unset;
  }
`;
export function GameButtons({ mobileLinks, backgroundColor = "#3B288A" }) {
  return (
    <GameButtonsWrap xs={12} lg={7} className="p-0">
      <GameApkButton
        small={false}
        link={mobileLinks.apk.link}
        qr={mobileLinks.apk.qr}
        event={mobileLinks.apk.event}
        background={backgroundColor}
      />

      <MobileLinksWrap>
        <AppButton
          platform="android"
          link={mobileLinks.android.link}
          event={mobileLinks.android.analytics}
          background={backgroundColor}
        />
        <AppButton
          platform="ios"
          link={mobileLinks.ios.link}
          event={mobileLinks.ios.analytics}
          background={backgroundColor}
        />
      </MobileLinksWrap>
    </GameButtonsWrap>
  );
}
