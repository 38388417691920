import { ToastContainer as RTToastContainer } from "react-toastify";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { BridgeCloseIcon } from "./bridge/BridgeCloseIcon";

export const StyledToastContainer = styled(RTToastContainer)`
  --toastify-toast-width: 370px;


  &.Toastify__toast-container--top-right {
    top: 115px;
  }

  @media only screen and (max-width: 480px) {
    &.Toastify__toast-container--top-right {
      left: 9px;
      right: 9px;
      width: calc(100% - 18px);
      s
    }
  }

  .Toastify__toast {
    background-color: ${({ theme }) => theme.palette.white};
    border: 1px solid #1F8E0B;
    overflow: visible;
  }

  .Toastify__toast-body {
    & > * {
      color: #1F8E0B;
      font-family: ${({ theme }) => theme.fonts.display};
    }
  }

  //.Toastify__progress-bar {
  //  background: radial-gradient(56.60% 88.24% at 50.00% 16.18%, #FFD977 1.04%, rgba(157, 107, 41, 0.00) 100%), linear-gradient(180deg, #C79847 0%, #9D6B29 100%);
  //}
`;

const CloseButtonWrap = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
`;

function CloseButton({ closeToast }) {
  return (
    <CloseButtonWrap>
      <BridgeCloseIcon onClick={closeToast} $size="20px" />
    </CloseButtonWrap>
  );
}

export function ToastContainer() {
  return (
    <StyledToastContainer
      closeButton={CloseButton}
      hideProgressBar
    />
  );
}