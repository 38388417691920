import { FilterTokens } from "../../hooks/FilterTokens";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { Container } from "../base/Container";
import React from "react";
import { InventoryToolbar } from "./InventoryToolbar";
import { Select } from "../common/Select";
import styled from "styled-components";
import { device } from "../../theme/mediaQuery";
import { Typography } from "../base/Typography";

const TokensGridRow = styled(Row)`
  padding: 0 16px;
  @media (${device.md}) {
    padding: 0 32px;
  }
`;

const TokenCol = styled(Col)`
  padding: 8px;
`;

const PlaceholderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px;
  min-height: 350px;
`;

export function InventoryTokens({
  data,
  filters,
  title,
  subtitle,
  renderCard,
  filterGetters
}) {
  const {
    data: _filtered,
    getFilterValue,
    handleFiltersChange
  } = FilterTokens({
    data,
    getters: filterGetters
  });

  const isPlaceholder = _filtered.length === 0;

  return (
    <div>
      <Container className="p-3" styled={{ position: "relative" }}>
        <InventoryToolbar
          title={title}
          subtitle={subtitle}
          filters={
            <Row className="justify-content-end">
              {filters.map((filter) => (
                <Col key={filter.id} lg={4} className="my-2 px-2">
                  <Select
                    label={filter.title}
                    options={filter.options}
                    value={getFilterValue(filter.id)}
                    onChange={(option) =>
                      handleFiltersChange({
                        name: filter.id,
                        value: option.value
                      })
                    }
                  />
                </Col>
              ))}
            </Row>
          }
        />

        {isPlaceholder && (
          <PlaceholderWrap>
            <Typography
              color="darkBlue300"
              family="display"
              variant="textXl"
            >
              No items found for the selected filters
            </Typography>
          </PlaceholderWrap>
        )}

        <TokensGridRow>
          {_filtered.map((item, index) => (
            <TokenCol md={6} lg={4} xl={3} key={index}>
              {renderCard(item)}
            </TokenCol>
          ))}
        </TokensGridRow>
      </Container>
    </div>
  );
}
