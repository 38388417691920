import React, { useEffect, useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import { Typography } from "../../base/Typography";
import { Button } from "../../base/Button";
import { StakingCallout } from "./StakingCallout";
import { InfoCard } from "./InfoCard";
import { InfoModal } from "./InfoModal";
import { AssetSelector, TOKEN_NAMES, TOKENS } from "./AssetSelector";
import { months } from "../constants";
import {
  useGetAPRArray,
  useGetBadgeBoostFactor,
  useGetCUandCULPBalances,
  useStakeToken,
} from "../../../query/vault";
import {
  liquidityModalOpenedAtom,
  stakeModalOpenedAtom,
  stakeSuccessModalDataAtom,
  stakeSuccessModalOpenedAtom,
} from "../../../store/staking";
import { formatStringWithFixedFraction } from "../../Helpers";
import { BUY } from "../../../consts/externalLinks";

const InfoModalWrap = styled(InfoModal)`
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    margin: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e6e6fa;
    border-radius: 4px;
    padding: 20px 0;
  }

  @media (${device.md}) {
    gap: 40px;
  }
`;

const CU_CALLOUT_TEXT =
  "Running low on CU tokens? Click Purchase More to obtain more through Camelot.";
const CULP_CALLOUT_TEXT =
  "Running low on CULP tokens? Click Add Liquidity to obtain more.";

export function StakeModal({ initialTab = TOKEN_NAMES.CU }) {
  const [open, setOpen] = useAtom(stakeModalOpenedAtom);
  const setShowLiquidityModal = useSetAtom(liquidityModalOpenedAtom);
  const setSuccessModalOpen = useSetAtom(stakeSuccessModalOpenedAtom);
  const setSuccessModalData = useSetAtom(stakeSuccessModalDataAtom);
  const stakeQuery = useStakeToken();
  const [token, setToken] = useState(TOKENS[TOKEN_NAMES.CU]);

  const isCULPSelected = token.id === TOKENS[TOKEN_NAMES.CULP].id;

  const calloutText =
    token.id === TOKENS[TOKEN_NAMES.CU].id
      ? CU_CALLOUT_TEXT
      : CULP_CALLOUT_TEXT;
  const buttonText =
    token.id === TOKENS[TOKEN_NAMES.CU].id ? "Purchase more" : "Add Liquidity";

  //watch and refresh initialTab
  useEffect(() => {
    setToken(TOKENS[initialTab]);
  }, [initialTab, open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (payload) => {
    stakeQuery.mutate(payload, {
      onSuccess: (result) => {
        const transactionHash = result?.transactionHash;
        const durationLabel =
          months.find((month) => month.value === payload.duration)?.label ||
          payload.duration;

        // clear mutation state
        stakeQuery.reset();

        setSuccessModalData({
          hash: transactionHash,
          token: payload.token,
          quantity: payload.amount,
          duration: durationLabel,
          apr: payload.apr + "%",
        });

        // open success modal
        setSuccessModalOpen(true);

        // close stake modal
        handleClose();
      },
    });
  };

  const handleCULPCalloutClick = () => {
    setShowLiquidityModal(true);
    setOpen(false);
  };

  return (
    <InfoModalWrap
      toggleModal={handleClose}
      showModal={open}
      headerTitle="Stake">
      <StakingForm
        token={token}
        setToken={setToken}
        onSubmit={handleSubmit}
        isLoading={stakeQuery.isPending}
      />
      <Typography
        as="p"
        variant="textSm"
        md={{ variant: "textMd" }}
        color="#5338C2">
        <strong>Notice:</strong> Locking your {token.name} for the allotted time
        will freeze your {token.name} for that whole duration, this indicates no
        use of {token.name} during that time frame
      </Typography>
      {isCULPSelected && (
        <StakingCallout
          title={calloutText}
          buttonText={buttonText}
          onClick={handleCULPCalloutClick}
        />
      )}
      {!isCULPSelected && (
        <StakingCallout
          title={calloutText}
          buttonText={buttonText}
          href={BUY.CU}
        />
      )}
    </InfoModalWrap>
  );
}

const StakingFormWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 12px;
  @media (${device.md}) {
    gap: 16px;
  }
`;

const StakingField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (${device.md}) {
    gap: 8px;
  }
`;

const StakingForm = ({ token, setToken, onSubmit, isLoading }) => {
  const { CU, CULP } = useGetCUandCULPBalances();

  // hooks to get APR and badge boost factor data
  const { data: aprArray } = useGetAPRArray(token?.slug);
  const { data: badgeBoostFactor } = useGetBadgeBoostFactor(token?.slug);

  const [tokenQuantity, setTokenQuantity] = useState("");
  const [amountError, setAmountError] = useState(null);
  const [duration, setDuration] = useState(months[11]);

  // get the current APR and badge boost factor for the selected duration
  const currentAPRItem = aprArray?.find(
    (apr) => apr.monthName === duration.label
  );
  const estimatedAPR = currentAPRItem?.apr || 0;
  const timeBoostFactor = currentAPRItem?.timeBoost || 0;
  const cumulativeConvert = badgeBoostFactor + timeBoostFactor;

  const cardData = [
    {
      label: "Estimated APR",
      value: formatStringWithFixedFraction(estimatedAPR) + " %",
      key: "apr",
    },
    {
      label: "Cumulative Convert",
      value: formatStringWithFixedFraction(cumulativeConvert, null, 4),
      key: "cumulative",
      buttonSpace: true,
    },
    { label: "Timelock Convert", value: timeBoostFactor, key: "time" },
    {
      label: "Badge Convert",
      value: formatStringWithFixedFraction(badgeBoostFactor),
      button: true,
      key: "badge",
    },
  ];

  const tokenBalance = token.id === TOKENS[TOKEN_NAMES.CU].id ? CU : CULP;

  const disable =
    parseFloat(tokenQuantity) > tokenBalance ||
    tokenQuantity === "" ||
    parseFloat(tokenQuantity) === 0 ||
    amountError ||
    isLoading;

  const checkBalance = (value) => {
    const valueToCheck = token.id === TOKENS[TOKEN_NAMES.CU].id ? CU : CULP;

    if (parseFloat(value.replace(/,/g, "")) > valueToCheck) {
      setAmountError("Insufficient Balance");
    } else {
      setAmountError(null);
    }
  };

  const handleTokenTypeChange = (value) => {
    //reset values when switching tokens
    setTokenQuantity("");
    setAmountError(null);
    setDuration(months[11]);
    setToken(value);
  };

  const handleQuantityChange = (value) => {
    setTokenQuantity(value);
    checkBalance(value);
  };

  const handleSubmit = () => {
    const formatToken = parseFloat(tokenQuantity.replace(/,/g, ""));
    const payload = {
      token: token.slug,
      amount: formatToken,
      duration: duration.value,
      apr: estimatedAPR,
    };

    onSubmit(payload);
  };

  return (
    <>
      <StakingFormWrap>
        <StakingField>
          <Typography
            as="p"
            family="body"
            variant="textSm"
            md={{ variant: "textMd" }}
            color="black">
            Asset
          </Typography>
          <AssetSelector
            options={Object.values(TOKENS)}
            selectedToken={token}
            setSelectedToken={handleTokenTypeChange}
          />
        </StakingField>
        <StakingField>
          <Typography
            as="p"
            family="body"
            variant="textSm"
            md={{ variant: "textMd" }}
            color="black">
            Amount
          </Typography>
          <TokenField
            token={token.name}
            value={tokenQuantity}
            onChange={handleQuantityChange}
            available={token.id === TOKENS[TOKEN_NAMES.CU].id ? CU : CULP}
            showMaxButton
            error={amountError}
          />
        </StakingField>
        <StakingField>
          <Typography
            as="p"
            family="body"
            variant="textSm"
            md={{ variant: "textMd" }}
            color="#252525">
            Duration
          </Typography>
          <Typography
            as="p"
            family="body"
            variant="textXs"
            md={{ variant: "textSm" }}
            color="#252525">
            The longer duration you choose, the higher sAsset Convert you will
            receive!
          </Typography>
          <DurationSelector
            selectedMonth={duration}
            setSelectedMonth={setDuration}
          />
        </StakingField>
        <InfoCard data={cardData} />
      </StakingFormWrap>
      <Button
        size="lg"
        variant="secondary"
        onClick={handleSubmit}
        disabled={disable}
        isLoading={isLoading}>
        Stake
      </Button>
    </>
  );
};

const DurationWrapper = styled.div`
  background: #f3f3ff;
  padding: 12px;
  border-radius: 90px;
  @media (${device.md}) {
    padding: 15px;
  }
`;
const Duration = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background: #e0e0fc;
  border-radius: 100px;
  @media (${device.md}) {
    padding: 12px;
  }
`;

const MonthLabel = styled.label`
  margin: auto;
`;

const Month = styled.input`
  display: none;
`;

const CustomMonth = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.white};

  ${Month}:checked + & {
    border: 2.5px solid #9441e7;
    filter: drop-shadow(0px 0px 14px rgba(148, 65, 231, 0.3));
  }

  &:hover {
    border: 5px solid rgba(148, 65, 231, 0.1);
    cursor: pointer;
  }

  @media (${device.sm}) {
    width: 15px;
    height: 15px;
  }
  @media (${device.md}) {
    width: 20px;
    height: 20px;
    ${Month}:checked + & {
      border: 5px solid #9441e7;
    }
  }
`;

const DurationSelector = ({ selectedMonth, setSelectedMonth }) => {
  // const months = Array.from({ length: 12 }, (_, i) => i + 1);
  // const monthString = pluralize("month", selectedMonth, true);

  return (
    <>
      <DurationWrapper>
        <Duration>
          {months.map((month, index) => (
            <MonthLabel key={month.value}>
              <Month
                type="radio"
                name="duration"
                value={month.value}
                checked={selectedMonth === month}
                onChange={() => setSelectedMonth(month)}
              />
              <CustomMonth
              // left={(month -1) * (100 / 11)}
              />
            </MonthLabel>
          ))}
        </Duration>
      </DurationWrapper>
      <Typography
        tag="span"
        color="#5338C2"
        variant="text2xs"
        md={{ variant: "textSm" }}
        className="ms-auto">
        {selectedMonth.label}
      </Typography>
    </>
  );
};

const TokenFieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 8px 12px;
  overflow: hidden;
  background: #f3f3ff;
  border-radius: 8px;
  @media (${device.md}) {
    gap: 12px;
    padding: 12px 16px;
  }
`;

const Input = styled.input`
  background: transparent;
  border: none;
  color: #8221e3;
  width: 100%;
  height: 100%;

  font-size: 21px;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.display};

  border-radius: 0;

  @media (${device.md}) {
    font-size: 24px;
  }

  &::placeholder {
    color: #8221e3;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
`;

const MaxButton = styled.button`
  background-color: transparent;
  color: #8221e3;
  border: none;
  font-size: 14px;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.display};

  &:hover {
    color: #c9abff;
  }

  &:active {
    color: #c9abff;
    filter: brightness(1.1);
  }
`;

export const ErrorMessage = styled(Typography)`
  color: #fe7a75 !important;
  margin-top: 4px;
  opacity: ${({ $error }) => ($error ? 1 : 0)};
  max-height: ${({ $error }) => ($error ? "40px" : "0")};
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease, margin 0.5s ease;
`;

const TokenField = ({
  token,
  value = "",
  onChange,
  showMaxButton = false,
  available = 0,
  disabled,
  error,
}) => {
  const formattedAvailable = available.toLocaleString();

  function limitDecimals(number) {
    const strNumber = number.toString();
    const decimalIndex = strNumber.indexOf(".");
    if (decimalIndex !== -1) {
      return parseFloat(strNumber.slice(0, decimalIndex + 6));
    } else {
      return parseFloat(strNumber);
    }
  }

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleMaxValue = () => {
    if (token === "CULP") {
      onChange(limitDecimals(available).toString());
    } else {
      onChange(available.toString());
    }
  };

  const formatNumberInput = (value) => {
    // Remove all non-digits and replace multiple decimals with a single decimal
    let formatted = value.replace(/[^0-9.]+/g, "").replace(/(\..*)\./g, "$1");

    // Split at the decimal point
    let parts = formatted.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas
    return parts.join(".");
  };

  return (
    <TokenFieldWrap>
      <InputWrap>
        <Input
          type="text"
          placeholder="0"
          value={formatNumberInput(value)}
          onChange={handleChange}
          disabled={disabled}
        />
        {showMaxButton && <MaxButton onClick={handleMaxValue}>MAX</MaxButton>}
      </InputWrap>
      <ErrorMessage
        variant="text2xs"
        md={{ variant: "textXs" }}
        $error={error}
        weight={600}>
        {error}&nbsp;
      </ErrorMessage>
      {showMaxButton && (
        <Typography
          tag="span"
          color="#5338C2"
          variant="text2xs"
          md={{ variant: "textSm" }}
          className="ms-auto">
          Available Balance: {formattedAvailable} {token}
        </Typography>
      )}
    </TokenFieldWrap>
  );
};
