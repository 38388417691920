import { useState } from "react";
import styled from "styled-components";
import { TokenCard } from "./TokenCard";
import { getAttribute } from "../Helpers";
import { InventoryTokens } from "./InventoryTokens";
import Spinner from "../base/Spinner";
import { Typography } from "../base/Typography";
import { Button } from "../base/Button";
import { ItemsModal } from "./ItemsModal";
import { BUY } from "../../consts/externalLinks";
import { useGetInventoryItems } from "../../query/inventory";
import { LOOTBOX_ITEM_POOL_IDS } from "../../consts/tokenPools";

const PlaceholderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px;
  min-height: 528px;
  padding: 0 10px;
`;

const filters = [
  {
    title: "Rarity",
    options: [
      { value: null, title: "All", icon: "all" },
      { value: "Mythic", title: "Mythic", icon: "mythic" },
      { value: "Rare", title: "Rare", icon: "rare" },
      { value: "Common", title: "Common", icon: "common" }
    ],
    id: "rarity",
    getter: (item) => getAttribute("Rarity", item)
  }
];

const filterGetters = {
  rarity: (item) => getAttribute("Rarity", item)
};

export function InventoryItems() {
  const { data = [], isLoading } = useGetInventoryItems();
  const [showModal, setShowModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);

  const items = data?.filter((item) => {
    return !LOOTBOX_ITEM_POOL_IDS.includes(item.poolId);
  });

  const handleSelectItems = (item) => {
    setSelectedToken(item);
    setShowModal(true);
  };

  let _toolbarTitle = `${items?.length} item types owned`;

  if (isLoading) {
    return (
      <PlaceholderWrap>
        <Spinner color="black" />
      </PlaceholderWrap>
    );
  }

  return (
    <>
      {/* {balance > 0 && <TokenCard item={data[0]} />} */}
      {items && items.length > 0 && (
        <>
          <InventoryTokens
            title={_toolbarTitle}
            data={items}
            filters={filters}
            filterGetters={filterGetters}
            renderCard={(item) => (
              <TokenCard
                onClick={() => {
                  handleSelectItems(item);
                }}
                image={item.image}
                name={item.name}
                showClass={false}
                count={item.count}
                setShowModal={setShowModal}
                rightAttribute={{
                  value: "Default"
                }}
              />
            )}
          />

          <ItemsModal
            showModal={showModal}
            setShowModal={setShowModal}
            item={selectedToken}
          />
        </>
      )}
      {items && items.length === 0 && !isLoading && (
        <PlaceholderWrap>
          <Typography tag={"h3"} variant={"textLg"} color="gray300">
            🎁 Missing magical items? 🌟
          </Typography>
          <Typography tag={"h3"} variant={"textMd"} color="gray300">
            Elevate your Crypto Unicorns experience! Find lootboxes, tickets,
            potions and nft in-game items to boost your game play experience.
          </Typography>
          <a
            href={BUY.MARKETPLACE.UNICORN_ITEMS}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button block={true} prependIcon="cart">
              Visit Marketplace
            </Button>
          </a>
        </PlaceholderWrap>
      )}
    </>
  );
}
