import { Icon } from "../base/Icon";
import React, { useState } from "react";
import styled from "styled-components";
import { BSDropdown, BSDropdownItem, BSDropdownMenu, BSDropdownToggle } from "../base/BSDropdown";

const DropdownSizeStyles = {
  height: {
    sm: "40px",
    md: "56px",
  },
  fontSize: {
    sm: "14px",
    md: "20px",
  },
  minWidth: {
    sm: "260px",
    md: "260px",
  },
  borderRadius: {
    sm: "8px",
    md: "16px",
  },
};

const DropdownWrap = styled(BSDropdown)`
  cursor: pointer;
`;

const ToggleWrap = styled(BSDropdownToggle)`
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background: #3C1568;
  border-radius: ${({ $size }) => DropdownSizeStyles.borderRadius[$size]};
  border-bottom-right-radius: ${({ $active, $size }) => $active ? "0" : DropdownSizeStyles.borderRadius[$size]};
  border-bottom-left-radius: ${({ $active, $size }) => $active ? "0" : DropdownSizeStyles.borderRadius[$size]};
  height: ${({ $size }) => DropdownSizeStyles.height[$size]};
  padding: 0 16px;
  min-width: ${({ $size }) => DropdownSizeStyles.minWidth[$size]};
  font-size: ${({ $size }) => DropdownSizeStyles.fontSize[$size]};
  border-bottom: 2px solid ${({ $active }) => $active ? "#5338C2" : "transparent"};
  user-select: none;

  &:after {
    content: none;
  }

  &:hover {
    filter: brightness(1.1);
  }

  & path {
    fill: #F7C15D;
  }

`;

const DropDownMenuWrap = styled(BSDropdownMenu)`
  z-index: 1000;
  background: #3C1568;
  width: 100%;
  top: -3px !important;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: ${({ $size }) => DropdownSizeStyles.borderRadius[$size]};
  border-bottom-left-radius: ${({ $size }) => DropdownSizeStyles.borderRadius[$size]};
  user-select: none;
`;

const DropDownItemWrap = styled(BSDropdownItem)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 14px;
  color: ${({ $selected, theme }) => $selected ? theme.palette.white : "#82699F"};
  background: transparent;
  transition: color 0.3s;

  &:hover {
    background: transparent;
    color: ${({ theme }) => theme.palette.white};
  }

  & svg {
    color: #F7C15D;
  }
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const ToggleWrapIcon = styled(Icon)`
  margin-left: auto;
`;

const AppendIcon = styled(Icon)`
  margin-left: auto;
`;

export function StakingDropdown({
  options = [],
  onChange,
  value,
  size = "md",
}) {
  const [active, setActive] = useState(false);

  return (
    <DropdownWrap
      onToggle={setActive}
    >
      <ToggleWrap $size={size} $active={active}>
        {value?.icon && (
          <IconWrap>
            <img src={value.icon} alt={value.title} />
          </IconWrap>
        )}
        {value?.title || "All"}
        <ToggleWrapIcon name="arrow-down" color="#F7C15D" />
      </ToggleWrap>

      <DropDownMenuWrap $size={size}>
        {options.map((option) => (
          <DropDownItemWrap
            key={`${option.id}-${option.title}`}
            onClick={() => onChange(option)}
            $selected={value?.value === option.value}
          >
            {option.icon && (
              <IconWrap>
                <img src={option.icon} alt={option.title} />
              </IconWrap>
            )}
            {option.title}
            {value?.value === option.value && (
              <AppendIcon name="check" size="8px" />
            )}
          </DropDownItemWrap>
        ))}
      </DropDownMenuWrap>
    </DropdownWrap>

  );
}
