import React from "react";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import { Typography } from "../../base/Typography";
import { StakingTokenTypes } from "../constants";

export const TOKEN_NAMES = {
  CU: 0,
  CULP: 1
};

export const TOKENS = {
  [TOKEN_NAMES.CU]: {
    id: TOKEN_NAMES.CU,
    name: "CU",
    value: "CU",
    slug: StakingTokenTypes.cu
  },
  [TOKEN_NAMES.CULP]: {
    id: TOKEN_NAMES.CULP,
    name: "CULP",
    value: "CULP",
    slug: StakingTokenTypes.culp
  }
};

const TokenSelector = styled.div`
  display: flex;
  align-items: center;
  background: #e6e6fa;
  border-radius: 8px;
  padding: 12px 24px;
  position: relative;
  @media (${device.md}) {
    padding: 15px 30px;
  }
`;

const Token = styled.input`
  display: none;
`;

const TokenLabel = styled.label`
  display: block;
  flex: 1;
  background-color: #e6e6fa;
  color: #8221e3;
  text-align: center;
  padding: 4px 12px;
  border-radius: 6px;
  cursor: pointer;

  @media (${device.md}) {
    padding: 8px 16px;
  }

  ${Token}:checked + & {
    background-color: #8221e3;
    color: ${({ theme }) => theme.palette.white};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  &:hover {
    filter: brightness(1.05);
  }
`;

export const AssetSelector = ({ options, selectedToken, setSelectedToken }) => {
  const handleChange = (e) => {
    const tokenKey = e.target.value;
    setSelectedToken(TOKENS[tokenKey]);
  };

  return (
    <TokenSelector>
      {options.map((option) => (
        <React.Fragment key={option.value}>
          <Token
            id={option.id.toString()}
            type="radio"
            name="asset"
            value={option.id.toString()}
            checked={selectedToken.id === option.id}
            onChange={handleChange}
          />
          <TokenLabel htmlFor={option.id.toString()}>
            <Typography
              as="span"
              variant="textXl"
              md={{ variant: "displayXs" }}
              family="display"
              color="inherit"
            >
              {option.name}
            </Typography>
          </TokenLabel>
        </React.Fragment>
      ))}
    </TokenSelector>
  );
};
