import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import { Footer } from "../layouts/BaseLayout/Footer";
import { Header } from "../components/header";
import { HomeHeroBackground } from "../components/home/hero/HomeHeroBackground";
import { HomeHeroCta } from "../components/home/hero/HomeHeroCta";
import { HomeHero } from "../components/home/hero";
import { HomeCharacters } from "../components/home/character";
import { HomeEvolution } from "../components/home/evolution";
import { HomeGamesSection } from "../components/home/games";
import { LeaderboardSection } from "../components/home/leaderboard";
import { useAtomValue, useSetAtom } from "jotai/index";
import { scrollAtom } from "../store/scroll";
import { StakingBanner } from "../components/staking/StakingBanner";
import { device } from "../theme/mediaQuery";
import { useGetLpAPR } from "../query/vault";

const PurpleGradientWrapper = styled.div`
  background: linear-gradient(
    180deg,
    #150079 0%,
    #5118a4 43.52%,
    #872cc6 64.87%,
    rgba(146, 205, 249, 0.59) 86.23%,
    #92cdf9 98.21%
  );
  overflow-x: hidden;
`;

const StakingBannerWrap = styled.div`
  padding-bottom: 80px;
  margin-top: -30px;
  @media (${device.lg}) {
    padding-bottom: 40px;
  }
`;

export function Home() {
  const refGame = useRef(null);
  const { scroll, activePage } = useAtomValue(scrollAtom);
  const setScroll = useSetAtom(scrollAtom);
  const {
    data = {},
    // isLoading
  } = useGetLpAPR();

  const scrollToGame = () => {
    refGame.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (scroll) {
      setScroll({ scroll: false, activePage: undefined });
      scrollToGame();
    }
  }, [scroll, activePage, setScroll]);

  return (
    <>
      <PurpleGradientWrapper>
        <HomeHeroBackground>
          <Header scrollToGame={scrollToGame} />
          <StakingBannerWrap>
            <StakingBanner apr={data} />
          </StakingBannerWrap>
          <HomeHero />
          <HomeHeroCta />
        </HomeHeroBackground>
        <HomeCharacters />
      </PurpleGradientWrapper>
      <HomeEvolution />
      <HomeGamesSection ref={refGame} />
      <LeaderboardSection />
      <Footer />
    </>
  );
}
