import styled from "styled-components";
import React from "react";
import { Typography } from "../base/Typography";
import { Container } from "../base/Container";
import { device } from "../../theme/mediaQuery";

import engage from "../../img/airdrop/be-engaged.png";
import learn from "../../img/airdrop/learn-season-1.png";
import { COMMUNITY, WHITE_PAPER } from "../../consts/externalLinks";
import { Button } from "../base/Button";
import { Link } from "react-router-dom";

const SectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0 36px;
  gap: 36px;
  @media (${device.md}) {
    padding: 36px 0 80px;
    gap: 80px;
  }
  @media (${device.lg}) {
    padding: 40px 0 100px;
    gap: 100px;
  }
  @media (${device.xl}) {
    gap: 120px;
    padding: 100px 0 120px;
  }
`;

export const MoreInfo = () => {
  return (
    <Container>
      <SectionWrap>
        <InfoSection
          title="Be Early. Be Engaged. Be Loyal."
          subtitle="Abide these rules to excel in Season 1!"
          img={engage}
          button="Join the community"
          link={COMMUNITY.DISCORD}
          target="_blank"
          rel="noopener noreferrer"
        />
        <InfoSection
          title="learn more about play 2 airdrop!"
          // subtitle=""
          img={learn}
          button="Learn more"
          link={WHITE_PAPER.AIRDROP}
          target="_blank"
          rel="noopener noreferrer"
          reverse
        />
      </SectionWrap>
    </Container>
  );
};

const InfoSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  @media (${device.lg}) {
    flex-direction: ${({ $reverse }) => ($reverse ? "row-reverse" : "row")};
  }
`;

const ImgWrap = styled.img`
  width: 100%;
  @media (${device.lg}) {
    width: 48%;
  }
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  @media (${device.lg}) {
    width: 45%;
  }

  & a {
    width: 100%;
    @media (${device.sm}) {
      width: unset;
    }
  }
`;

export const InfoSection = ({
  title,
  subtitle,
  img,
  link,
  button,
  reverse = false,
  ...rest
}) => {
  return (
    <InfoSectionWrap $reverse={reverse}>
      <ImgWrap src={img} alt={title} />
      <TextWrap>
        <Typography
          as="h2"
          variant="displayMd"
          md={{
            variant: "displayLg",
          }}
          xl={{
            variant: "displayXl",
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            as="h3"
            family="body"
            variant="textLg"
            md={{
              variant: "textXl",
            }}
            xl={{
              variant: "displayXs",
            }}
          >
            {subtitle}
          </Typography>
        )}
        <Button
          as={Link}
          to={link}
          variant={reverse ? "primary" : "secondary"}
          className="pt-4"
          size="lg"
          {...rest}
        >
          {button}
        </Button>
      </TextWrap>
    </InfoSectionWrap>
  );
};
