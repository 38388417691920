import {
    FIRE_HUSK_POOL_ID,
    FIRE_MATERIAL_POOL_ID,
    FIRE_MINION_POOL_ID,
    FIRE_MINION_TIER2_POOL_ID,
    FIRE_MINION_TIER3_POOL_ID,
    GENERIC_MATERIAL_POOL_ID,
    HATCHERY_KEY_POOL_ID,
    NEBULA_HUSK_POOL_ID,
    NEBULA_MATERIAL_POOL_ID,
    NEBULA_MINION_POOL_ID,
    NEBULA_MINION_TIER2_POOL_ID,
    NEBULA_MINION_TIER3_POOL_ID,
    SLIME_HUSK_POOL_ID,
    SLIME_MATERIAL_POOL_ID,
    SLIME_MINION_POOL_ID,
    SLIME_MINION_TIER2_POOL_ID,
    SLIME_MINION_TIER3_POOL_ID,
    SOUL_HUSK_POOL_ID,
    SOUL_MATERIAL_POOL_ID,
    SOUL_MINION_POOL_ID,
    SOUL_MINION_TIER2_POOL_ID,
    SOUL_MINION_TIER3_POOL_ID,
    UNICORN_SOUL_POOL_ID,
    VOLT_HUSK_POOL_ID,
    VOLT_MATERIAL_POOL_ID,
    VOLT_MINION_POOL_ID,
    VOLT_MINION_TIER2_POOL_ID,
    VOLT_MINION_TIER3_POOL_ID
  } from "./tokenPools";
  
  export const TokenNamesMap = {
    [HATCHERY_KEY_POOL_ID]: "Shadow Forge Key",
    [FIRE_HUSK_POOL_ID]: "Fire Husk",
    [SOUL_HUSK_POOL_ID]: "Soul Husk",
    [NEBULA_HUSK_POOL_ID]: "Nebula Husk",
    [SLIME_HUSK_POOL_ID]: "Slime Husk",
    [VOLT_HUSK_POOL_ID]: "Volt Husk",
    [FIRE_MATERIAL_POOL_ID]: "Volatile Cinders",
    [SOUL_MATERIAL_POOL_ID]: "Shrouded Candle",
    [NEBULA_MATERIAL_POOL_ID]: "Bottled Black Hole",
    [VOLT_MATERIAL_POOL_ID]: "Hi-Voltage Conductivines",
    [SLIME_MATERIAL_POOL_ID]: "Putridust",
    [GENERIC_MATERIAL_POOL_ID]: "Lock of Unicorn Hair",
    [FIRE_MINION_POOL_ID]: "Pyrofiend",
    [SLIME_MINION_POOL_ID]: "Swamplix",
    [VOLT_MINION_POOL_ID]: "Shockwisp",
    [SOUL_MINION_POOL_ID]: "Sorciphant",
    [NEBULA_MINION_POOL_ID]: "Stargrub",
    [FIRE_MINION_TIER2_POOL_ID]: "Infernoclast",
    [SLIME_MINION_TIER2_POOL_ID]: "Muckmaw",
    [VOLT_MINION_TIER2_POOL_ID]: "Tazerite",
    [SOUL_MINION_TIER2_POOL_ID]: "Spylock",
    [NEBULA_MINION_TIER2_POOL_ID]: "Astralurk",
    [FIRE_MINION_TIER3_POOL_ID]: "Charonix",
    [SLIME_MINION_TIER3_POOL_ID]: "Gobzooka",
    [VOLT_MINION_TIER3_POOL_ID]: "Buzzbolt",
    [SOUL_MINION_TIER3_POOL_ID]: "Enigmancer",
    [NEBULA_MINION_TIER3_POOL_ID]: "Cosmoclaw",
    [UNICORN_SOUL_POOL_ID]: "Unicorn Soul"
  };