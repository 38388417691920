import { NotificationsModal } from "../../common/NotificationsModal";
import { useAtom } from "jotai/index";
import { rewardNotificationModalAtom } from "../../../store/staking";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import { StakingTokenImage } from "../StakingTokenImage";
import { FormattedAmount } from "../../common/FormattedAmount";
import { StakingViewTransactionButton } from "../StakingViewTransactionButton";
import { getTimeLeftInHumanReadable } from "../../../helpers/getTimeLeftInHumanReadable";

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ListItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 13px 10px;

  @media (${device.md}) {
    align-items: center;
    flex-direction: row;
  }
`;

const ListItemPrepend = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ListItemTextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItemTextTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.white};
`;

const ListItemTextCountdown = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
`;

const ListItemIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: start;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const ListItemAction = styled.div`
  & * {
    @media (${device.md}) {
      font-size: 16px;
    }
  }

  @media (${device.md}) {
    margin-left: auto;
  }
`;

export function StakingBadgesNotification({ variant = "primary" }) {
  const [modal, setModal] = useAtom(rewardNotificationModalAtom);

  const transactions = modal.transactions;
  const open = modal.show;

  // Close the modal and clear the transactions
  const handleClose = () => {
    setModal({
      show: false,
      transactions: [],
    });
  };

  const isClaimableAt = transactions.some(
    (transaction) => transaction.claimableAt
  );

  //if there is a claimableAt attribute in any of the transactions, show other title
  const title = !isClaimableAt
    ? "Reward claim success!"
    : "Initiate claim success!";

  return (
    <NotificationsModal
      open={open}
      onClose={handleClose}
      title={title}
      variant={variant}
    >
      <ListWrap>
        {transactions.map((transaction, index) => (
          <ListItem
            key={index}
            amount={transaction.amount}
            token={transaction.token}
            hash={transaction.hash}
            claimableAt={transaction.claimableAt}
          />
        ))}
      </ListWrap>
    </NotificationsModal>
  );
}

function ListItem({ amount, token, hash, claimableAt }) {
  return (
    <ListItemWrap>
      <ListItemPrepend>
        <ListItemIcon>
          <StakingTokenImage token={token} />
        </ListItemIcon>
        <ListItemTextWrap>
          <ListItemTextTitle>
            <FormattedAmount wei={amount} /> {token}
          </ListItemTextTitle>
          {claimableAt && (
            <ListItemTextCountdown>
              Claimable in{" "}
              {getTimeLeftInHumanReadable(claimableAt / 1000, false)}
            </ListItemTextCountdown>
          )}
        </ListItemTextWrap>
      </ListItemPrepend>
      <ListItemAction>
        <StakingViewTransactionButton
          block
          md={{
            block: false,
          }}
          hash={hash}
        >
          View Transaction
        </StakingViewTransactionButton>
      </ListItemAction>
    </ListItemWrap>
  );
}
