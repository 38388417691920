import {
  StakingCommonCard,
  StakingCommonCardHeader,
  StakingCommonCardTitle,
} from "../StakingCommonCard";
import styled from "styled-components";
import { Button } from "../../base/Button";
import {
  withdrawNotificationModelOpenedAtom,
  withdrawNotificationTransactionsAtom,
} from "../../../store/staking";
import { useAtom, useSetAtom } from "jotai";
import { device } from "../../../theme/mediaQuery";
import { formatStringWithFixedFraction } from "../../Helpers";
import { StakingTokenImage } from "../StakingTokenImage";
import Web3 from "web3";
import { getTimeLeftInHumanReadable } from "../../../helpers/getTimeLeftInHumanReadable";
import { useWithdrawToken } from "../../../query/vault";
import Spinner from "../../base/Spinner";
import { StakingPaginatedList } from "../StakingPaginatedList";
import { useRef } from "react";
import { ethers } from "ethers";
import { useActiveWalletChain } from "thirdweb/react";

const HeadingWrap = styled.div`
  display: none;
  align-items: center;
  height: 100%;
  padding: 0 40px;

  @media (${device.lg}) {
    display: flex;
  }
`;

const MobileHeadingWrap = styled(HeadingWrap)`
  display: flex;
  padding: 20px 20px;

  @media (${device.lg}) {
    display: none;
  }
`;

const HeadingTitle = styled(StakingCommonCardTitle)`
  font-size: 20px;
  flex: 1;
  display: flex;
  align-items: center;

  @media (${device.md}) {
    justify-content: center;
    text-align: center;
  }
`;

const BodyWrap = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 300px;
  background: #3c1568;
  margin-bottom: 12px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  @media (${device.lg}) {
    padding: 40px;
    min-height: 500px;
  }
`;

const Row = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (${device.lg}) {
    align-items: center;
    flex-direction: row;
    gap: 0;
  }
`;

const Cell = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};

  & button {
    padding: 0;
  }

  @media (${device.lg}) {
    font-size: 24px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const AssetCell = styled(Cell)`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
`;

const AssetImageWrap = styled.div`
  width: 30px;
  height: 30px;
  margin-left: auto;

  & img {
    width: 100%;
    height: 100%;
  }

  @media (${device.lg}) {
    width: 60px;
    height: 60px;
    margin-left: 40px;
  }

  @media (${device.xl}) {
    margin-left: 70px;
  }
`;

const CellTitle = styled.div`
  display: block;
  font-weight: 700;

  @media (${device.lg}) {
    display: none;
  }
`;

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 24px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const CardWrap = styled(StakingCommonCard)`
  background: transparent;
  padding-bottom: 10px;
`;

export function StakingHistoryTable({
  items = [],
  isLoading,
  switchNetwork,
  chain,
}) {
  const headingRef = useRef(null);
  // sort items by "end" timestamp
  const sortedItems = items.sort((a, b) => {
    const aEnd = new Date(parseInt(a.end)).getTime();
    const bEnd = new Date(parseInt(b.end)).getTime();

    return aEnd - bEnd;
  });

  const isEmpty = sortedItems.length === 0 && !isLoading;
  const showEmptyBody = isEmpty || isLoading;

  const onPageChange = () => {
    setTimeout(() => {
      headingRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const chainId = useActiveWalletChain();

  return (
    <CardWrap>
      <StakingCommonCardHeader ref={headingRef}>
        <MobileHeadingWrap>
          <HeadingTitle>History</HeadingTitle>
        </MobileHeadingWrap>
        <HeadingWrap>
          <HeadingTitle>Asset</HeadingTitle>
          <HeadingTitle>Quantity</HeadingTitle>
          <HeadingTitle>Status</HeadingTitle>
          <HeadingTitle>Action</HeadingTitle>
        </HeadingWrap>
      </StakingCommonCardHeader>
      {!showEmptyBody && (
        <StakingPaginatedList
          items={sortedItems}
          WrapComponent={BodyWrap}
          pageSize={4}
          onPageChange={onPageChange}
          renderRow={(item) => (
            <TableRow
              key={item.amount + item.start}
              data={item}
              switchNetwork={switchNetwork}
              chain={chain}
              chainId={chainId}
            />
          )}
        />
      )}
      {showEmptyBody && (
        <BodyWrap>
          {isEmpty && <Placeholder>No deposits found</Placeholder>}
          {isLoading && (
            <LoadingWrap>
              <Spinner />
            </LoadingWrap>
          )}
        </BodyWrap>
      )}
    </CardWrap>
  );
}

function TableRow({ data, switchNetwork, chain, chainId }) {
  const { token, end, amount, index } = data;
  const withdrawMutation = useWithdrawToken();

  // check if end timestamp is in the past
  const endTimestamp = parseInt(end);
  const now = new Date().getTime() / 1000;
  const isActive = endTimestamp > now;

  const status = isActive ? "Active" : "Expired";
  const setNotificationsModalOpened = useSetAtom(
    withdrawNotificationModelOpenedAtom
  );
  const [notifications, setNotifications] = useAtom(
    withdrawNotificationTransactionsAtom
  );
  const amountInEther = ethers.utils.formatEther(amount);

  const timeInHumanReadable = getTimeLeftInHumanReadable(endTimestamp);

  const wrongChain = chainId?.id !== chain?.id;
  const disabled = withdrawMutation.isPending;
  const handleWithdraw = () => {
    const payload = {
      index: index,
      token: token,
    };
    withdrawMutation.mutate(payload, {
      // on success, add a notification to the list and open the modal
      onSuccess: (result) => {
        const transactionHash = result?.transactionHash;
        const notification = {
          amount: amount,
          hash: transactionHash,
          token: token,
        };
        setNotifications([...notifications, notification]);
        setNotificationsModalOpened(true);
      },
    });
  };

  return (
    <Row>
      <AssetCell>
        <CellTitle>Asset:</CellTitle>
        <AssetImageWrap>
          <StakingTokenImage token={token} />
        </AssetImageWrap>
        {token}
      </AssetCell>
      <Cell>
        <CellTitle>Quantity:</CellTitle>
        {formatStringWithFixedFraction(amountInEther)}
      </Cell>
      <Cell>
        <CellTitle>Status:</CellTitle>
        {status}
      </Cell>
      <Cell>
        {!isActive && (
          <Button
            size="lg"
            block
            onClick={() =>
              wrongChain ? switchNetwork(chain) : handleWithdraw()
            }
            variant="secondary"
            disabled={wrongChain ? (disabled ? disabled : null) : disabled}
            isLoading={withdrawMutation.isPending}>
            {wrongChain
              ? disabled
                ? "Withdraw"
                : "Switch Network"
              : "Withdraw"}
          </Button>
        )}
        {isActive && (
          <Button size="lg" disabled block variant="secondary">
            {timeInHumanReadable}
          </Button>
        )}
      </Cell>
    </Row>
  );
}
