import { NetworkConfiguration } from "../Network";

import RBW_ABI from "../../CU_ERC20_ABI.json";
import BRIDGE_ABI from "../../CU_Bridge_ABI.json";
import { POLYGON_NETWORK } from "../ConnectWallet/WalletHelper";

import { ethers } from "ethers";

export const rbwParams =
  "0x000100000000000000000000000000000000000000000000000000000000001e8480";
export const zroPayment = "0x0000000000000000000000000000000000000000";

export function getRBWContract(wallet) {
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];

  const contract = new ethers.Contract(
    netconfig.RBWContractAddress,
    RBW_ABI,
    wallet
  );
  return {
    async approve(address, amount) {
      return contract.approve(address, amount);
    },
    async allowance(address, spender) {
      let allowance = await contract.allowance(address, spender);
      let allowanceWei = ethers.utils.formatEther(allowance);
      return allowanceWei;
    },
  };
}
export function getUNIMContract(wallet) {
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];

  const contract = new ethers.Contract(
    netconfig.UNIMContractAddress,
    RBW_ABI,
    wallet
  );

  return {
    approve(address, amount) {
      return contract.approve(address, amount);
    },
    allowance(address, spender) {
      return contract.allowance(address, spender);
    },
  };
}

export function getCUContract(wallet) {
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];

  const provider = new ethers.providers.JsonRpcProvider(netconfig.ArbitrumRPC);

  const contract = new ethers.Contract(
    netconfig.RBWArbitrum,
    RBW_ABI,
    provider
  );

  return {
    balanceOf(address) {
      return contract.balanceOf(address);
    },
  };
}

export function getBridgeContract(
  wallet,
  chainIdKey,
  contractAddress,
  walletAddress
) {
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const address = netconfig[contractAddress];
  const chainId = netconfig[chainIdKey];

  const contract = new ethers.Contract(address, BRIDGE_ABI, wallet);
  return {
    estimateSendFee(amount) {
      const amountWei = ethers.utils.parseEther(amount);

      const params = ethers.utils.solidityPack(
        ["uint16", "uint256"],
        [1, netconfig.bridgeGas]
      );

      const address = ethers.utils.hexZeroPad(walletAddress, 32);

      return contract.estimateSendFee(
        chainId,
        address,
        amountWei,
        false,
        params
      );
    },
    async sendFrom(chainId, toAddress, amount, maticAmount, zeroAddr) {
      const toAddressBytes32 = ethers.utils.hexZeroPad(toAddress, 32);

      const params = ethers.utils.solidityPack(
        ["uint16", "uint256"],
        [1, netconfig.bridgeGas]
      );
      const gasEstimate = await contract.estimateGas.sendFrom(
        walletAddress,
        chainId,
        toAddressBytes32,
        amount,
        [walletAddress, zeroAddr, params],
        {
          value: maticAmount.nativeFee,
        }
      );
      let gasLimit = gasEstimate
        .mul(ethers.BigNumber.from(15))
        .div(ethers.BigNumber.from(10));

      const tx = await contract.sendFrom(
        walletAddress,
        chainId,
        toAddressBytes32,
        amount,
        [walletAddress, zeroAddr, params],
        {
          value: maticAmount.nativeFee,
          gasLimit: 1000000,
        }
      );
      return tx;
      // console.log("tx", tx);

      // await tx.wait();

      // if (tx.status !== 1) {
      //   throw new Error("Transaction failed");
      // }
    },
  };
}
