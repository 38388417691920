import { Container } from "../../base/Container";
import styled from "styled-components";
import React from "react";
import { Typography } from "../../base/Typography";
import { HomeCharacterGrid } from "./HomeCharacterGrid";
import { device } from "../../../theme/mediaQuery";

const SectionWrap = styled.section`
  padding: 30px 0;
  overflow: hidden;

  @media (${device.lg}) {
    padding: 120px 0;
  }
`;
const HeadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  text-align: center;
  padding: 50px 0 40px;
  @media (${device.lg}) {
    padding: 100px 0 40px;
  }
`;

export function HomeCharacters() {
  return (
    <SectionWrap>
      <Container style={{ overflow: "visible" }}>
        <HeadingWrap>
          <Typography
            tag={"h2"}
            variant={"displayLg"}
            lg={{ variant: "display2xl" }}
          >
            Meet the Main Characters
          </Typography>
          <Typography
            tag={"p"}
            variant={"displayXs"}
            family={"display"}
            lg={{ variant: "displaySm" }}
          >
            Discover, Collect, and Raise these ‘Corns!
          </Typography>
          <Typography
            tag={"p"}
            variant={"textMd"}
            weight={600}
            lg={{ variant: "textXl" }}
          >
            Each unicorn is unique and brimming with personality! Due to the
            vastly different worlds they hail from, they have evolved into
            distinct classes, boasting unique names, body parts, and strengths.
            Every unicorn holds value, and some are true stars!
          </Typography>
        </HeadingWrap>
        <HomeCharacterGrid />
      </Container>
    </SectionWrap>
  );
}
