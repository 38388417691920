import { useEffect } from "react";
import Web3 from "web3";
import { saveAddress } from "@sharemint/sdk";
import { googleAnalyticsEventWallet } from "../../helpers/googleAnalytics";
import { useSetChain } from "@web3-onboard/react";

export const POLYGON_NETWORK = process.env.REACT_APP_POLYGON_NETWORK;
export const CONNECT_YOUR_WALLET = "Connect your wallet";

const switchNetwork = async (wallet, chainId, connectedChain) => {
  let web3 = new Web3(wallet.provider);

  if (connectedChain !== chainId) {
    try {
      await web3.currentProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(chainId) }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        alert("add this chain id");
      }
    }
  }
};

export const WalletHook = (
  wallet,
  netconfig,
  // connectedChains,
  setWalletStatus,
  setCurrentWalletAddress
) => {
  const [{ connectedChain }, setChain] = useSetChain();
  //let web3 = new Web3(wallet.provider);

  async function switchChain(chain) {
    await setChain({ chainId: chain });
  }
  useEffect(() => {
    if (!wallet || !connectedChain || !window) return;
    if (window.location.href.includes("staking")) {
      switchChain(netconfig.networkIdArbitrum);
    } else {
      switchChain(Web3.utils.toHex(netconfig.networkId));
    }
  }, [wallet, connectedChain, netconfig.networkId, window.location.href]);

  useEffect(() => {
    if (!wallet?.accounts[0]?.address) {
      //setWalletStatus(CONNECT_YOUR_WALLET);
      setWalletStatus(false);
    } else {
      // updateWalletStatus(wallet, setWalletStatus, CONNECT_YOUR_WALLET);
      setWalletStatus(true);
      setCurrentWalletAddress(wallet?.accounts[0]?.address);
      googleAnalyticsEventWallet(wallet?.accounts[0]?.address, wallet?.label);

      saveAddress({
        slug: "crypto-unicorns",
        address: wallet?.accounts[0]?.address,
      });
    }
  }, [wallet, setWalletStatus, setCurrentWalletAddress]);
};
