import React from "react";
import styled from "styled-components";
import { Typography } from "../base/Typography";
import { Container } from "../base/Container";
import { device } from "../../theme/mediaQuery";
import { SectionWrap as Section } from "./Hero";

import toy1 from "../../img/merch/unicorn-egg.png";
import toy2 from "../../img/merch/shadowcorn-collectable.png";
import toy3 from "../../img/merch/unicorn-toy.png";
import toy4 from "../../img/merch/shadowcorn-toy.png";
import toy5 from "../../img/merch/shirt.png";

const SectionWrap = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media (${device.xl}) {
    gap: 60px;
  }
`;

const ToysWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 20px;
  align-items: center;
  justify-content: center;
  @media (${device.md}) {
    gap: 8px;
    flex-wrap: nowrap;
  }
`;

const ToyImage = styled.img`
  max-width: 30%;
  @media (${device.sm}) {
    max-width: 150px;
  }
  @media (${device.lg}) {
    max-width: 180px;
  }
  @media (${device.xl}) {
    max-width: 200px;
  }
  @media (${device.xxl}) {
    max-width: 250px;
  }
`;

const TOYS = [toy1, toy2, toy3, toy4, toy5];

export function Merch() {
  return (
    <Container>
      <SectionWrap>
        <Typography
          tag={"h2"}
          variant={"displaySm"}
          lg={{ variant: "displayLg" }}
          xxl={{ variant: "displayXl" }}
          color="white"
          textshadow="hard"
          style={{ lineHeight: "100%", textAlign: "center" }}
        >
          Toys, Apparel, Lifestyle Products and more!
        </Typography>

        <ToysWrap>
          {TOYS.map((toy, index) => (
            <ToyImage key={index} src={toy} alt={`toy ${index + 1}`} />
          ))}
        </ToysWrap>
      </SectionWrap>
    </Container>
  );
}
