import { useSetChain } from "@web3-onboard/react";
import { Network } from "../Network";
import Web3 from "web3";

export const NetworkName = {
  POLYGON: "POLYGON",
  ARBITRUM: "ARBITRUM",
};

function getCurrentChainId(networkName) {
  switch ( networkName ) {
    case NetworkName.POLYGON:
      return Network.networkId;
    case NetworkName.ARBITRUM:
      return Network.networkIdArbitrum;
    default:
      return Network.networkId;
  }
}

export function ShowOnNetwork({ network, children }) {
  const [{ connectedChain }] = useSetChain();
  const chainId = connectedChain?.id;
  const currentChainId = getCurrentChainId(network);
  const currentChainIdInHex = Web3.utils.numberToHex(currentChainId);

  if (chainId === currentChainIdInHex) {
    return children;
  }

  return null;
}