import React from "react";
import styled from "styled-components";
import { Typography } from "../base/Typography";
import { Container } from "../base/Container";
import { device } from "../../theme/mediaQuery";
import { SectionWrap as Section } from "./Hero";
import { Carousel } from "./Carousel";
import { CHARACTER } from "./CharacterCard";

const SectionWrap = styled(Section)``;

const HeadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  text-align: center;
  margin-bottom: 32px;
  @media (${device.xl}) {
    margin-bottom: 51px;
  }
`;


// doubled each one bc the library's loop feature only 
// works if the #items >= 2* largest slide view
const SHADOWCORNS = [
  {
    name: "fire",
    description: "Shadowcorn",
  },
  {
    name: "soul",
    description: "Shadowcorn",
  },
  {
    name: "slime",
    description: "Shadowcorn",
  },
  {
    name: "nebula",
    description: "Shadowcorn",
  },
  {
    name: "volt",
    description: "Shadowcorn",
  },
  {
    name: "fire",
    description: "Shadowcorn",
  },
  {
    name: "soul",
    description: "Shadowcorn",
  },
  {
    name: "slime",
    description: "Shadowcorn",
  },
  {
    name: "nebula",
    description: "Shadowcorn",
  },
  {
    name: "volt",
    description: "Shadowcorn",
  },
];

export function ShadowcornCharacters() {
  return (
    <Container>
      <SectionWrap>
        <HeadingWrap>
          <Typography
            tag={"h2"}
            variant={"displaySm"}
            lg={{ variant: "displayLg" }}
            xxl={{ variant: "displayXl" }}
            textshadow="hard"
          >
            Battling the evil shadowcorns
          </Typography>
        </HeadingWrap>
        <Carousel
          characters={SHADOWCORNS}
          characterType={CHARACTER.SHADOWCORN}
        />
      </SectionWrap>
    </Container>
  );
}
