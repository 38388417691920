import React from "react";
import styled from "styled-components";
import { device } from "../../theme/mediaQuery";
import { Typography } from "../base/Typography";

const TokenFieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 12px 16px;

  overflow: hidden;
  background: #3e0f94;
  border-radius: 8px;
`;
const Input = styled.input`
  background: transparent;
  border: none;
  color: white;
  width: 100%;
  height: 100%;

  font-size: 21px;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.display};

  border-radius: 0;

  @media (${device.md}) {
    font-size: 24px;
  }

  &::placeholder {
    color: white;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
`;

const MaxButton = styled.button`
  background-color: transparent;
  color: #ffc600;
  border: none;
  font-size: 14px;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.display};

  &:hover {
    color: #c9abff;
  }

  &:active {
    color: #c9abff;
    filter: brightness(1.1);
  }
`;

const Token = styled.img`
  width: 28px;
  height: 28px;
`;

const InfoTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 4px;

  & span:first-of-type {
    max-width: 157px;
    @media (${device.sm}) {
      max-width: unset;
    }
  }

  & span:last-of-type {
    text-align: right;
  }
`;

const ErrorMessage = styled(Typography)`
  color: #fe7a75;
  margin-top: 4px;
  opacity: ${({ $error }) => ($error ? 1 : 0)};
  max-height: ${({ $error }) => ($error ? "40px" : "0")};
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease, margin 0.5s ease;
`;

export const TokenField = ({
  title,
  value = "",
  onChange,
  showMaxButton = false,
  available = 0,
  placeholder = "0",
  disabled,
  error,
  handleSetMax,
  token,
}) => {
  const tokenName = token.name;
  const img = token.img;
  const ratio = token?.ratio;
  const showRatio = ratio === 1 || !ratio ? false : true;
  const formattedAvailable = available.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  });
  let handleChange = (e) => {
    let inputValue = e.target.value;

    // Remove all non-digits and replace multiple decimals with a single decimal
    let formatted = inputValue
      .replace(/[^0-9.]+/g, "")
      .replace(/(\..*)\./g, "$1");

    handleSetMax(false);
    onChange(formatted);
  };

  const handleMaxValue = () => {
    if (disabled) return;
    //get the hightest value from available that is divisible by 10
    // let max = Math.floor(available / 10) * 10;
    onChange(available.toString());
    handleSetMax(true);
  };
  return (
    <TokenFieldWrap>
      <Typography tag="span" color="#C9ABFF">
        {title}:
      </Typography>
      <InputWrap>
        <Token src={img} />
        <Input
          type="text"
          placeholder={placeholder}
          value={formatNumberInput(value)} // Applying formatting for display
          onChange={handleChange}
          disabled={disabled}
        />
        {showMaxButton && <MaxButton onClick={handleMaxValue}>MAX</MaxButton>}
      </InputWrap>
      <ErrorMessage
        variant="text2xs"
        md={{ variant: "textXs" }}
        $error={error}
        weight={600}
      >
        {error}&nbsp;
      </ErrorMessage>
      {showMaxButton && (
        <InfoTextWrap>
          {showRatio && (
            <Typography
              tag="span"
              color="#C9ABFF"
              variant="text2xs"
              md={{ variant: "textXs" }}
            >
              {tokenName} converts to CU at a {ratio}:1 ratio{" "}
              <Typography
                tag="span"
                color="#ffffff"
                variant="text2xs"
                md={{ variant: "textXs" }}
                weight="bold"
              >
                ({ratio} {tokenName} = 1 CU)
              </Typography>
            </Typography>
          )}
          <Typography
            tag="span"
            color="#C9ABFF"
            variant="text2xs"
            md={{ variant: "textSm" }}
            className="ms-auto"
          >
            Available Balance: {formattedAvailable} {tokenName}
          </Typography>
        </InfoTextWrap>
      )}
    </TokenFieldWrap>
  );
};

export const formatNumberInput = (value) => {
  // Remove all non-digits and replace multiple decimals with a single decimal
  let formatted = value.replace(/[^0-9.]+/g, "").replace(/(\..*)\./g, "$1");

  // Split at the decimal point
  let parts = formatted.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas
  if (parts[1]) {
    parts[1] = parts[1].slice(0, 8) || ""; // Ensure there is a decimal point
  }
  let newString = parts.join(".");
  return newString;
};
