import React from "react";
import styled from "styled-components";
// import confetti from "canvas-confetti";
import { device } from "../../../theme/mediaQuery";
import confettiLeft from "../../../img/staking/confetti-left.png";
import confettiRight from "../../../img/staking/confetti-right.png";
import { AlertModal } from "./AlertModal";
import { Portal } from "../../common/Portal";

const ConfettiImg = styled.img`
  z-index: 12;
  position: absolute;
  top: -82px;
  bottom: 0;
  width: auto;
  object-fit: cover;
  display: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  @media (${device.md}) {
    display: block;
  }
  @media (${device.lg}) {
    top: -88px;
  }
`;

const ConfettiLeft = styled(ConfettiImg)`
  left: 0;
  transform: translateX(-53%);
  @media (${device.lg}) {
    transform: translateX(-48%);
  }
`;

const ConfettiRight = styled(ConfettiImg)`
  right: 0;
  transform: translateX(45%);
  @media (${device.lg}) {
    transform: translateX(46%);
  }
`;

export function SuccessModal({
  toggleModal,
  showModal,
  title,
  subtitle,
  message,
  children,
}) {
  // We will implement this if art is not ready!
  // const startConfetti = () => {
  //   let myConfetti = confetti.create(
  //     document.getElementById("canvasConfetti"),
  //     {
  //       resize: true,
  //       useWorker: true,
  //     }
  //   );

  //   let duration = 4 * 1000;
  //   let animationEnd = Date.now() + duration;

  //   const confettiColors = [
  //     "#FFDC73",
  //     "#A67C00",
  //     "#FFBF00",
  //     "#BF9830",
  //     "#FFCF40",
  //   ];

  //   let defaults = {
  //     startVelocity: 30,
  //     spread: 360,
  //     ticks: 60,
  //     zIndex: 1054,
  //     colors: confettiColors,
  //   };

  //   function randomInRange(min, max) {
  //     return Math.random() * (max - min) + min;
  //   }

  //   let interval = setInterval(function () {
  //     let timeLeft = animationEnd - Date.now();

  //     if (timeLeft <= 0) {
  //       return clearInterval(interval);
  //     }

  //     let particleCount = 65 * (timeLeft / duration);

  //     // Let's make things less chaotic for mobile users
  //     if (window.innerWidth <= 768) {
  //       particleCount = 15 * (timeLeft / duration);
  //     }

  //     let leftX = randomInRange(0.1, 0.3);
  //     let leftY = Math.random() - 0.2; // Since particles fall down, start a bit higher than random
  //     for (let i = 0; i < 5; i++) {
  //       myConfetti(
  //         Object.assign({}, defaults, {
  //           particleCount,
  //           origin: { x: leftX, y: leftY },
  //           decay: randomInRange(0.7, 0.9),
  //           scalar: randomInRange(0.7, 1.2),
  //           startVelocity: randomInRange(30, 35),
  //         })
  //       );
  //     }

  //     let rightX = randomInRange(0.7, 0.9);
  //     let rightY = Math.random() - 0.2;
  //     for (let i = 0; i < 5; i++) {
  //       myConfetti(
  //         Object.assign({}, defaults, {
  //           particleCount,
  //           origin: { x: rightX, y: rightY },
  //           decay: randomInRange(0.7, 0.9),
  //           scalar: randomInRange(0.7, 1.2),
  //           startVelocity: randomInRange(30, 35),
  //         })
  //       );
  //     }
  //   }, 250);
  // };

  return (
    <Portal>
      <AlertModal
        toggleModal={toggleModal}
        showModal={showModal}
        title={title}
        subtitle={subtitle}
        message={message}
      >
        <ConfettiLeft src={confettiLeft} alt="Confetti Left" />
        <ConfettiRight src={confettiRight} alt="Confetti Right" />
        {children}
      </AlertModal>
    </Portal>
  );
}
