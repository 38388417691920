import { atom } from "jotai/index";

const basePendingTransactionsAtom = atom(
  JSON.parse(localStorage.getItem("pendingTransactions")) || {
    showModal: false,
    transactions: [],
  }
);

export const persistedPendingTransactionsAtom = atom(
  (get) => get(basePendingTransactionsAtom),
  (get, set, newValue) => {
    const nextValue =
      typeof newValue === "function"
        ? newValue(get(basePendingTransactionsAtom))
        : newValue;
    set(basePendingTransactionsAtom, nextValue);
    localStorage.setItem("pendingTransactions", JSON.stringify(nextValue));
  }
);
