import styled from "styled-components";
import { Row } from "./Row";
import { Col } from "./Col";
import { device } from "../../theme/mediaQuery";

const NavBarWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding: 12px;
  align-items: center;
  position: relative;
  background: ${({ $background }) =>
    $background
      ? $background
      : "linear-gradient(180deg, #5252f2 0%, #3131b0 100%)"};
  box-shadow: ${({ $background }) =>
    $background
      ? ""
      : "0px 5px 9px 2px #4257DC inset, 0px -6px 0px 0px rgba(255, 255, 255, 0.04) inset"};
  row-gap: 8px;

  @media (${device.sm}) {
    row-gap: 8px;
    padding: 16px 0px;
  }
  @media (${device.md}) {
    padding: 16px 24px;
  }
  @media (${device.lg}) {
    padding: 16px 28px;
  }
  @media (${device.xl}) {
    padding: 16px 34px;
  }
`;

const NavButton = styled.button`
  color: white;
  background: transparent;
  border: none;
  margin: 0px 16px;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 700;
  color: ${({ theme }) => theme.palette.white};
  z-index: 9 !important;
  cursor: pointer;
  width: 100%;

  &:hover {
    color: ${({ theme, $button }) => !$button && theme.palette.slate100};
    background-color: ${({ $button }) => $button && "rgba(108, 188, 234, 0.2)"};
  }

  &.active {
    color: ${({ theme, $button }) => !$button && theme.palette.yellow200};
    background-color: ${({ $button }) => $button && "rgba(108, 188, 234, 0.2)"};
  }

  @media (${device.sm}) {
    width: fit-content;
  }
`;

const PatternBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${({ theme }) => theme.pattern.sprinkles});
  background-size: 50%;
  opacity: 0.05;
  z-index: 1;
`;

export function NavBar({
  type,
  setType,
  TABS,
  background,
  button = false,
  pattern = true,
  ...rest
}) {
  return (
    <Row className="pt-4" {...rest}>
      <Col>
        <NavBarWrap $background={background}>
          {pattern && <PatternBackground />}
          {TABS.map((tab, i) => (
            <NavButton
              className={tab.type === type ? "active" : ""}
              key={`${tab.type}-${i}`}
              onClick={() => setType(tab.type)}
              $button={button}
            >
              {tab.title}
            </NavButton>
          ))}
        </NavBarWrap>
      </Col>
    </Row>
  );
}
