import { StakingTokenTypes } from "./constants";
import { DexScreenerTokenPairs } from "../Network";

const API_BASE_URL = "https://api.dexscreener.io/latest/dex";
const API_PAIRS_URL = `${API_BASE_URL}/pairs`;

// this is a map of token pair ids for the DEX screener API. It points to the token pair identifier for each token type with dexId equal to uniswap
const TokenPairIdsMap = {
  [StakingTokenTypes.cu]: DexScreenerTokenPairs.CU_WETH,
  [StakingTokenTypes.weth]: DexScreenerTokenPairs.WETH_USDT,
  [StakingTokenTypes.arb]: DexScreenerTokenPairs.ARB_WETH,
  [StakingTokenTypes.grail]: DexScreenerTokenPairs.GRAIL_WETH,
  [StakingTokenTypes.xai]: DexScreenerTokenPairs.XAI_WETH,
};

// fetch the token prices on the arbitrum network and uniswap dex
async function getArbitrumTokenPrices() {
  const tokens = [
    StakingTokenTypes.arb,
    StakingTokenTypes.grail,
    StakingTokenTypes.xai,
    StakingTokenTypes.weth,
    StakingTokenTypes.cu,
  ];
// map token to id
  const ids = tokens.map(token => TokenPairIdsMap[token]).join(",");

  const result = await fetch(
    `${API_PAIRS_URL}/arbitrum/${ids}`,
  ).then((res) => res.json());

  return result?.pairs || [];
}

export async function getTokenPricesInUSD() {
  const tokens = [
    StakingTokenTypes.cu,
    StakingTokenTypes.weth,
    StakingTokenTypes.arb,
    StakingTokenTypes.grail,
    StakingTokenTypes.xai,
    StakingTokenTypes.rbw,
  ];

  // get the token prices on the arbitrum
  const pairs = await getArbitrumTokenPrices();

  // return an object with token as key and price as value
  return tokens.reduce((acc, token) => {
    const pair = pairs.find((pair) => pair.pairAddress === TokenPairIdsMap[token]);
    acc[token] = Number(pair?.priceUsd);
    return acc;
  }, {});

}