import { atom } from "jotai";

export const withdrawNotificationModelOpenedAtom = atom(false);
export const withdrawNotificationTransactionsAtom = atom([]);

export const badgeNotificationModelOpenedAtom = atom(false);
export const badgeNotificationTransactionsAtom = atom([
  {
    name: "Badge Name",
    id: "1245465433",
    address: "0x39858b1A4e48CfFB1019F0A15ff54899213B3f8b",
    hash: "0x320958340958",
  },
]);
export const badgeModalAtom = atom({
  show: false,
  badge: null,
  token: null,
});
export const badgeSuccessModalAtom = atom({
  show: false,
  badge: null,
  hash: null,
  token: null,
  recipientAddress: null,
});

export const rewardNotificationModalAtom = atom({
  show: false,
  transactions: [],
});

export const rewardErrorModalAtom = atom({
  show: false,
});

export const genericErrorModalAtom = atom({
  show: false,
});

export const stakeModalOpenedAtom = atom(false);
export const stakeSuccessModalOpenedAtom = atom(false);
export const stakeSuccessModalDataAtom = atom(null);

export const liquidityModalOpenedAtom = atom(false);
export const liquiditySuccessModalAtom = atom({
  show: false,
  transaction: {
    hash: null,
    share: null,
    culp: null,
  },
});

export const rewardDetailsModalAtom = atom({
  show: false,
  token: null,
});
