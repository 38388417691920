import React from "react";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import { CRYPTO } from "../../../consts/externalLinks";
import { Typography } from "../../base/Typography";
import { Button } from "../../base/Button";

const StakingWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  padding: 30px 20px;
  gap: 20px;

  @media (${device.lg}) {
    flex-direction: row;
  }

  @media (${device.xl}) {
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    padding: 30px 40px;
  }

  & p {
    text-align: center;
    @media (${device.lg}) {
      text-align: left;
    }
  }

  & a div > div {
    white-space: nowrap;
    font-size: 12px;
  }
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export function StakingSiteCard({ ...rest }) {
  return (
    <StakingWrap {...rest}>
      <TextWrap>
        <Typography
          tag={"p"}
          variant={"textXl"}
          family={"display"}
          md={{ variant: "displayXs" }}
          xl={{ variant: "displaySm" }}
        >
          Have Unclaimed Rewards on the Old Staking Site?
        </Typography>
        <Typography
          tag={"p"}
          variant={"textXs"}
          md={{ variant: "textSm" }}
          xl={{ variant: "textMd" }}
          color="white"
        >
          Visit the
          site now and claim them on Polygon!
        </Typography>
      </TextWrap>

      <Button as="a" size="sm" href={CRYPTO.VAULT} target="_blank">
        Visit old staking site
      </Button>
    </StakingWrap>
  );
}
