import styled from "styled-components";
import { Box } from "../base/Box";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { Icon } from "../base/Icon";
import { Typography } from "../base/Typography";
import { Link } from "react-router-dom";
import { useSetAtom } from "jotai/index";
import { scrollAtom } from "../../store/scroll";

const CardWrap = styled(Link)`
  text-decoration: none;
`;

const CardBox = styled(Box)`
  padding: 12px;
  color: ${({ theme }) => theme.palette.white};
  transition: all 0.24s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

const IconWrap = styled(Box)`
  padding: 15px;
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardLink = styled.div`
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 14px;
  text-decoration: none;
  padding-top: 12px;
`;

export function NavMenuCard({
  background,
  iconBackground,
  icon,
  title,
  description,
  link,
}) {
  const setScroll = useSetAtom(scrollAtom);

  return (
    <CardWrap
      to={link.path}
      target={link.path !== "/" ? "_blank" : null}
      onClick={() => {
        if (link.path === "/") {
          setScroll({ scroll: true });
        }
      }}
    >
      <CardBox
        gradient={background}
        variant="gradient"
        pattern="labyrinth"
        rounded="md"
      >
        <Row>
          <Col xs={3} md={2} xl={3} className="pe-0 d-flex align-items-center">
            <IconWrap color={iconBackground} rounded="sm">
              <Icon name={icon} size="100%" />
            </IconWrap>
          </Col>
          <Col xs={9} md={10} xl={9}>
            <div className="py-2">
              <Typography variant="textLg" tag="h3">
                {title}
              </Typography>
              <Typography variant="textXs" tag="p" weight={600}>
                {description}
              </Typography>
              <CardLink to={link.path}>{link.title}</CardLink>
            </div>
          </Col>
        </Row>
      </CardBox>
    </CardWrap>
  );
}
