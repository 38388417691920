import React, { useState } from "react";
import Countdown from "react-countdown";
import styled from "styled-components";
import pluralize from "pluralize";

import { Col } from "../base/Col";
import { Row } from "../base/Row";
import Spinner from "../base/Spinner";
import { Typography } from "../base/Typography";
import { Box } from "../base/Box";

import { device } from "../../theme/mediaQuery";

import { PastMilestones } from "./components/PastMilestones";
import LeaderboardProgressBar from "./components/LeaderboardProgressBar";
import { Tabs } from "../base/Tabs";
import { Icon } from "../base/Icon";
import { TooltipCard } from "../base/TooltipCard";

const SummaryContainer = styled.div`
  position: relative;
`;

const SummaryWrap = styled.div`
  border-radius: 16px;
  overflow: hidden;
`;

const HeaderWrap = styled(Box)`
  padding: 10px 25px;
  min-height: 100px;
  display: flex;
  color: ${({ theme }) => theme.palette.white};
  justify-content: center;
  flex-direction: column;

  cursor: ${({ $isCollapsible }) => ($isCollapsible ? "pointer" : "default")};

  @media (${device.sm}) {
    flex-direction: ${({ $isCollapsible }) =>
      $isCollapsible ? "row" : "column"};
    align-items: ${({ $isCollapsible }) => ($isCollapsible ? "center" : "")};
  }

  @media (${device.lg}) {
    padding: 0 45px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &:hover {
    filter: ${({ $isCollapsible }) =>
      $isCollapsible ? "brightness(1.01)" : ""};
  }

  & h2 {
    flex-grow: 1;
    display: flex;
    justify-content: ${({ $center }) => $center && "center"};
    align-content: center;
    align-items: center;
  }
`;

const BodyWrap = styled.div`
  background-image: url(${({ $image }) => $image});
  background-size: cover;
  padding: 10px 12px;

  @media (${device.lg}) {
    padding: 40px 32px;
  }
`;

const EventBodyWrap = styled(Box)`
  padding: 10px 12px;

  @media (${device.lg}) {
    padding: 40px 32px;
  }
  border-radius: 0px 0px 20px 20px;
  border-right: ${({ borderColor }) =>
    borderColor && `8px solid ${borderColor}`};
  border-bottom: ${({ borderColor }) =>
    borderColor && `8px solid ${borderColor}`};
  border-left: ${({ borderColor }) =>
    borderColor && `8px solid ${borderColor}`};
`;

const BodyContentWrap = styled.div`
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: ${({ $isCollapsed }) =>
    $isCollapsed
      ? "0"
      : "5000px"}; // incredibly large number to allow transition
  opacity: ${({ $isCollapsed }) => ($isCollapsed ? "0" : "1")};

  .timer {
    text-align: center;

    h3 {
      margin-top: -8px;

      span {
        font-family: "Insanibc" !important;
      }
    }
  }

  .reward-wrapper {
    display: flex;
    justify-content: flex-end;

    &.center {
      justify-content: center;
    }

    .reward-group {
      position: relative;
      margin: 0 7px;

      img {
        width: 45px;
        height: 45px;
        border-radius: 5px;
      }

      img:nth-of-type(1) {
        position: relative;
        z-index: 10;
      }

      img:nth-of-type(2) {
        position: absolute;
        left: 8px;
        top: -6px;
        transform: scale(0.9);
        z-index: 9;
      }

      img:nth-of-type(3) {
        position: absolute;
        left: 14px;
        top: -12px;
        transform: scale(0.8);
        z-index: 8;
      }

      img:nth-of-type(1n + 4) {
        display: none;
      }
    }
  }
`;

const PlaceholderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px;
  min-height: 300px;
`;

const SubHeadingWrap = styled(Typography)`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-left: auto;

  @media (${device.lg}) {
    padding-left: 10px;
  }
`;

const InfoWrap = styled(Icon)`
  cursor: pointer;
  width: 12px;
  @media (${device.sm}) {
    width: 16px;
  }
  @media (${device.lg}) {
    width: 26px;
  }

  &:hover {
    filter: brightness(0.95);
  }
`;

const ArrowIcon = styled(Icon)`
  margin-left: auto;
  @media (${device.lg}) {
    margin-left: unset;
  }

  &:hover {
    filter: opacity(0.85);
  }
`;

export function LeaderboardSummary({
  eventOver,
  leaderboard,
  headerTitle,
  subHeader = null,
  eventEndAt,
  headerPattern = "labyrinth",
  patternSize = "50%",
  headerPatternSize,
  patternOpacity,
  info = false,
  handleInfo = () => null,
  renderBody = () => null,
  headerBackgroundColor,
  badge = null,
  share = null,
  centerHeader = false,
  condensedTimestamp = false,
  collapsible = false,
  collapsed = false,
  titleSmall = false,
  ...rest
}) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const titleWidth = headerTitle.length;
  const title =
    titleWidth < 20 || !titleSmall ? (
      <Typography
        tag="h2"
        variant="displayXs"
        color="white"
        lg={{
          variant: "displayMd",
        }}
        textshadow="soft"
        className={`${centerHeader && "text-center"}`}
      >
        {headerTitle}
      </Typography>
    ) : (
      <Typography
        tag="h2"
        variant="displayXs"
        color="white"
        xl={{
          variant: "displayMd",
        }}
        textshadow="soft"
        className={`${centerHeader && "text-center"}`}
      >
        {headerTitle}
      </Typography>
    );
  const $headerPatternSize = headerPatternSize
    ? headerPatternSize
    : patternSize;

  const toggleCollapse = () => {
    if (collapsible) {
      setIsCollapsed(!isCollapsed);
    }
  };

  return (
    <SummaryContainer>
      {badge && badge}
      <SummaryWrap>
        <HeaderWrap
          gradient={headerBackgroundColor}
          variant="gradient"
          pattern={headerPattern}
          patternSize={$headerPatternSize}
          patternOpacity={patternOpacity}
          $center={centerHeader}
          $isCollapsible={collapsible}
          onClick={toggleCollapse}
        >
          {title}

          {/* Collapsible */}
          {collapsible && (
            <ArrowIcon
              name={isCollapsed ? "arrow-down" : "arrow-up"}
              color="white"
              size="28px"
            />
          )}

          {/* Timer */}
          {eventEndAt && (
            <>
              <SubHeadingWrap
                tag="div"
                variant="textXs"
                color="white"
                family="display"
                sm={{
                  variant: "textSm",
                }}
                lg={{
                  variant: "textMd",
                }}
                xl={{
                  variant: "textLg",
                }}
                textshadow="soft"
              >
                <Countdown
                  overtime={false}
                  date={eventEndAt * 1000}
                  renderer={(props) =>
                    countdownRenderer({
                      ...props,
                      condensed: condensedTimestamp,
                    })
                  }
                  zeroPadTime={2}
                />
                {info && <InfoWrap name="info-square" onClick={handleInfo} />}
              </SubHeadingWrap>
            </>
          )}

          {/* Subheader */}
          {subHeader && !eventEndAt && (
            <SubHeadingWrap
              tag="div"
              variant="textXs"
              color="white"
              family="display"
              sm={{
                variant: "textSm",
              }}
              lg={{
                variant: "textMd",
              }}
              xl={{
                variant: "textLg",
              }}
              textshadow="soft"
            >
              {subHeader}
            </SubHeadingWrap>
          )}
          {/* Share button */}
          {share && share}
        </HeaderWrap>

        {!eventOver && (
          <BodyContentWrap $isCollapsed={isCollapsed}>
            <SummaryBody
              leaderboard={leaderboard}
              patternSize={patternSize}
              patternOpacity={patternOpacity}
              {...rest}
            >
              {renderBody(leaderboard)}
            </SummaryBody>
          </BodyContentWrap>
        )}
      </SummaryWrap>
    </SummaryContainer>
  );
}

const TabsWrap = styled(Tabs)`
  border-radius: 0px;
  background: ${({ $background }) => $background || "#0c1439"};
  color: #fff;
  box-shadow: ${({ $boxShadow }) =>
    $boxShadow || "0px 7px 7px 0px #fefdfe40 inset"};
  column-gap: 22px;

  @media (${device.md}) {
    column-gap: 40px;
  }
`;

const SummaryBodyTabWrap = styled(SummaryBody)`
  padding: 0;
`;

export function LeaderboardSummaryTabs({
  eventOver,
  leaderboard,
  headerTitle,
  eventEndAt,
  headerPattern = "labyrinth",
  patternOpacity,
  patternSize = "50%",
  renderBody = () => null,
  headerBackgroundColor,
  headerColor,
  headerAccent = "#7C86B5",
  tabs,
  tab,
  setTab,
  hideSpinner = false,
  condensedTimestamp = false,
  spinnerColor = "darkBlue300",
  boxShadow,
  ...rest
}) {
  return (
    <SummaryWrap>
      <HeaderWrap
        gradient={headerBackgroundColor}
        variant="gradient"
        pattern={headerPattern}
        patternSize={patternSize}
        patternOpacity={patternOpacity}
      >
        <Typography
          tag="h2"
          variant="displayXs"
          color="white"
          lg={{
            variant: "displayMd",
          }}
          textshadow="soft"
        >
          {headerTitle}
        </Typography>
        {/* Timer */}
        {eventEndAt && (
          <>
            <SubHeadingWrap
              tag="div"
              variant="textXs"
              color="white"
              family="display"
              sm={{
                variant: "textSm",
              }}
              lg={{
                variant: "textMd",
              }}
              xl={{
                variant: "textLg",
              }}
              textshadow="soft"
            >
              <Countdown
                overtime={false}
                date={eventEndAt * 1000}
                renderer={(props) =>
                  countdownRenderer({
                    ...props,
                    condensed: condensedTimestamp,
                  })
                }
                zeroPadTime={2}
              />
            </SubHeadingWrap>
          </>
        )}
      </HeaderWrap>
      <TabsWrap
        tabs={tabs}
        active={tab}
        onChange={setTab}
        color="white"
        activeTextColor={headerAccent}
        $background={headerColor}
        floating={false}
        $boxShadow={boxShadow}
      />
      <BodyContentWrap>
        <SummaryBodyTabWrap
          leaderboard={leaderboard}
          {...rest}
          hideSpinner={hideSpinner}
          spinnerColor={spinnerColor}
        >
          {renderBody(leaderboard)}
        </SummaryBodyTabWrap>
      </BodyContentWrap>
    </SummaryWrap>
  );
}

function SummaryBody({
  leaderboard,
  children,
  backgroundImage,
  spinnerColor,
  hideSpinner = false,
  ...rest
}) {
  let bodyRenderer = children;

  if (!leaderboard && !hideSpinner) {
    bodyRenderer = <SummaryLoading spinnerColor={spinnerColor} />;
  }

  if (backgroundImage) {
    return (
      <BodyWrap $image={backgroundImage} {...rest}>
        {bodyRenderer}
      </BodyWrap>
    );
  }

  return (
    <EventBodyWrap className="p-0" {...rest}>
      {bodyRenderer}
    </EventBodyWrap>
  );
}

function SummaryLoading({ spinnerColor }) {
  const color = spinnerColor ? spinnerColor : "#253B6E";
  return (
    <PlaceholderWrap>
      <Spinner color={color} />
    </PlaceholderWrap>
  );
}

const DetailSectionWrap = styled.div`
  position: relative;
  border-radius: 16px;
  background: ${({ theme, $background }) =>
    $background ? $background : theme.palette.white};
  min-height: ${({ $large }) => ($large ? "170px" : "100px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
  height: 100%;
`;

const TitleRow = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  width: 100%;

  @media (${device.sm}) {
    flex-direction: row;
  }
`;

const TooltipCardWrap = styled(TooltipCard)`
  width: auto;
  position: absolute;
  top: 5px;
  right: 10px;

  @media (${device.lg}) {
    top: 12px;
    right: 16px;
  }
`;

const IconWrap = styled(Icon)`
  width: 12px;

  @media (${device.lg}) {
    width: 20px;
  }
`;

export function SummaryDetailSection({
  title,
  title2,
  title3,
  topTitle,
  bottomTitle,
  large,
  variant1,
  variant2,
  img,
  img2,
  img3,
  accent,
  background,
  color,
  tooltip,
  ...rest
}) {
  const textColor = color ? color : "darkBlue300";
  return (
    <DetailSectionWrap $large={large} $background={background} {...rest}>
      {tooltip && (
        <TooltipCardWrap
          background="#2e1e39"
          color="#AE8DC3"
          position="top"
          overlay={<SummaryTooltip title={topTitle} description={tooltip} />}
        >
          <IconWrap name="info" />
        </TooltipCardWrap>
      )}
      {topTitle && (
        <Typography
          variant="textSm"
          lg={{
            variant: variant1,
          }}
          color={accent ? accent : "slate300"}
          family="display"
        >
          {topTitle}
        </Typography>
      )}

      <TitleRow>
        <Col className="px-0">
          <Typography
            variant="textXl"
            lg={{
              variant: variant2,
            }}
            color={textColor}
            family="display"
          >
            {title}
            {img && (
              <img
                src={img}
                alt="icon"
                className="px-0"
                style={{ width: "100%", maxWidth: "30px" }}
              />
            )}
          </Typography>
        </Col>
        {title2 !== undefined && (
          <Col className="px-0">
            <Typography
              variant="textXl"
              lg={{
                variant: variant2,
              }}
              color={textColor}
              family="display"
            >
              {title2}
              {img2 && (
                <img
                  src={img2}
                  alt="icon"
                  className="px-0"
                  style={{ width: "100%", maxWidth: "30px" }}
                />
              )}
            </Typography>
          </Col>
        )}
        {title3 !== undefined && (
          <Col className="px-0">
            <Typography
              variant="textXl"
              lg={{
                variant: variant2,
              }}
              color={textColor}
              family="display"
            >
              {title3}
              {img3 && (
                <img
                  src={img3}
                  alt="icon"
                  className="px-0"
                  style={{ width: "100%", maxWidth: "30px" }}
                />
              )}
            </Typography>
          </Col>
        )}
      </TitleRow>
      {bottomTitle && (
        <Typography
          variant="textSm"
          lg={{
            variant: variant1,
          }}
          color={accent ? accent : "slate300"}
          family="display"
        >
          {bottomTitle}
        </Typography>
      )}
    </DetailSectionWrap>
  );
}

const TooltipWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
  max-width: 215px;

  & > h4 {
    line-height: 1;
  }
`;

function SummaryTooltip({ title, description }) {
  return (
    <TooltipWrap>
      <Typography variant="textXl" color="white" tag="h4">
        {title}
      </Typography>
      <Typography variant="textSm" color="purple200" weight={600} tag="p">
        {description}
      </Typography>
    </TooltipWrap>
  );
}

export const CondensedWrap = styled.div`
  /* min-width: 280px; */
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .timer-unit {
    display: inline-block;
    width: 26px;
    text-align: center;
    font-size: inherit;
    font-family: inherit;
  }
`;

// Create the component for the countdown timers
export const countdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
  condensed,
}) => {
  if (condensed) {
    const timer = (
      <CondensedWrap>
        <Typography
          tag="span"
          variant="text2xs"
          color="white"
          weight={700}
          family="body"
          className="pe-1"
          sm={{
            variant: "textXs",
          }}
          lg={{
            variant: "textSm",
          }}
          xl={{
            variant: "textMd",
          }}
          textshadow="soft"
        >
          REFRESHES IN
        </Typography>
        <Typography
          tag="span"
          variant="textMd"
          color="white"
          family="display"
          sm={{
            variant: "textLg",
          }}
          lg={{
            variant: "textXl",
          }}
          xl={{
            variant: "displayXs",
          }}
          textshadow="soft"
        >
          {days > 0 && (
            <>
              <span className="timer-unit">{days}</span>D
            </>
          )}{" "}
          <span className="timer-unit">{hours}</span>H{" "}
          <span className="timer-unit">{minutes}</span>M{" "}
          <span className="timer-unit">{seconds}</span>S
        </Typography>
      </CondensedWrap>
    );

    return <>{completed ? "This event has finished!" : timer}</>;
  }

  return (
    <>
      {completed
        ? "This event has finished!"
        : `${pluralize("day", days, true)}, 
               ${pluralize("hours", hours, true)}, 
               ${pluralize("minutes", minutes, true)} remaining`}
    </>
  );
};

export const BodyRow = styled(Row)`
  padding: 10px 0;
  padding-bottom: ${({ $isLastSection }) =>
    $isLastSection ? "20px" : " 10px"};
  margin: 0 auto;
  align-items: center;
  background-color: ${({ $color }) => $color || "transparent"};

  @media (${device.lg}) {
    align-items: flex-start;
    padding: 20px 32px;
    padding-bottom: ${({ $isLastSection }) =>
      $isLastSection ? "40px" : " 20px"};
  }

  &:first-of-type {
    padding-top: 20px;
    @media (${device.lg}) {
      padding-top: 40px;
    }
  }
`;

function LeaderboardSummaryTitle({
  title,
  milestoneTimestamp,
  countdownRenderer,
  className,
  color = "white",
  ...rest
}) {
  return (
    <Col
      xs={12}
      lg={6}
      className={`d-flex justify-content-center flex-column ${className}`}
      {...rest}
    >
      <Typography
        tag="h3"
        variant="textXl"
        color={color}
        textshadow="mediumAlt"
        lg={{
          variant: "displaySm",
        }}
      >
        {title}
      </Typography>
      <Typography
        tag="p"
        variant="textMd"
        color={color}
        weight={500}
        lg={{
          variant: "textXl",
        }}
      >
        <Countdown
          overtime={false}
          date={milestoneTimestamp * 1000}
          renderer={countdownRenderer}
          zeroPadTime={2}
        />
      </Typography>
    </Col>
  );
}

const LeaderboardTitleImage = styled.img`
  width: 85px;
  height: 85px;
  /* padding: 0; */
  @media (${device.lg}) {
    width: 102px;
    height: 102px;
  }
`;

const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  gap: 12px;
  @media (${device.lg}) {
    gap: 24px;
  }

  & h3 {
    white-space: break-spaces;
  }
`;

// const TitleWrap = styled.div`
//   display: flex;
//   width: fit-content;
//   padding: 0;
// `;

export function LeaderboardSummaryTitleImg({
  title,
  milestoneTimestamp,
  countdownRenderer,
  className,
  color = "white",
  img,
  ...rest
}) {
  return (
    <Col
      xs={12}
      lg={6}
      className={`d-flex justify-content-center flex-column ${className}`}
      {...rest}
    >
      <RowWrap>
        <LeaderboardTitleImage src={img} alt={title} />
        <Typography
          tag="h3"
          variant="textXl"
          color={color}
          textshadow="mediumAlt"
          lg={{
            variant: "displaySm",
          }}
        >
          {title}
        </Typography>
      </RowWrap>
    </Col>
  );
}

// Title section with countdown and points highlights
export function LeaderboardSummaryTitleSection({
  title,
  milestoneTimestamp,
  countdownRenderer,
  sectionBackground,
  pointsHighlight,
  order,
  titleColor,
  className,
  img = null,
}) {
  const titlePadding = pointsHighlight ? "pb-4 pb-lg-0" : "pb-0";
  const titleStyling = order
    ? `text-lg-center order-lg-${order[0]} ${titlePadding}`
    : titlePadding;
  const pointsHighlightSize = {
    xs: pointsHighlight?.length > 1 ? 6 : 12,
    lg: 3,
  };
  return (
    <BodyRow
      className={`d-flex align-items-center justify-content-between ${className}`}
      $color={sectionBackground}
    >
      {!img && (
        <LeaderboardSummaryTitle
          title={title}
          milestoneTimestamp={milestoneTimestamp}
          countdownRenderer={countdownRenderer}
          color={titleColor}
          className={titleStyling}
        />
      )}
      {img && (
        <LeaderboardSummaryTitleImg
          title={title}
          milestoneTimestamp={milestoneTimestamp}
          countdownRenderer={countdownRenderer}
          color={titleColor}
          className={titleStyling}
          img={img}
        />
      )}
      {pointsHighlight &&
        pointsHighlight.map((item, i) => (
          <Col
            xs={pointsHighlightSize.xs}
            lg={pointsHighlightSize.lg}
            key={`${item.bottomTitle.toString().replace(/\s+/g, "-")}-${i}`}
            className={
              order
                ? `order-lg-${order[i + 1]} align-self-stretch`
                : "align-self-stretch"
            }
          >
            <SummaryDetailSection
              variant1={"textXl"}
              variant2={"textXl"}
              {...item}
            />
          </Col>
        ))}
    </BodyRow>
  );
}

// Past milestones section
export function LeaderboardPastMilestonesSection({
  pastMilestones,
  self,
  sectionBackground,
  lastSection,
  milestones,
  hintColor,
  background,
  accent1,
  accent2,
  className,
  ...rest
}) {
  return (
    <>
      {self && (
        <BodyRow
          className={`d-flex justify-content-center ${className}`}
          $color={sectionBackground}
          $isLastSection={lastSection}
          {...rest}
        >
          <PastMilestones
            milestones={pastMilestones}
            hintColor={hintColor}
            background={background}
            accent1={accent1}
            accent2={accent2}
          />
        </BodyRow>
      )}
    </>
  );
}

// Summary detail section containing cards that highlight points outside of those in the title
export function LeaderboardSummaryDetailsSection({
  summaryDetails,
  sectionBackground,
  lastSection,
  className,
  large = true,
  ...rest
}) {
  const colSize = large ? 6 : 3;
  return (
    <BodyRow
      className={`justify-content-around align-items-stretch ${className}`}
      $color={sectionBackground}
      $isLastSection={lastSection}
      {...rest}
    >
      {summaryDetails.map((item, i) => (
        <Col
          xs={12}
          md={colSize}
          className="mb-4"
          key={`${item?.title.toString().replace(/\s+/g, "-")}-${i}`}
          style={{ flexGrow: large ? "" : 1 }}
        >
          <SummaryDetailSection
            large={large}
            variant1={large ? "textXl" : "textLg"}
            variant2={large ? "displaySm" : "displayXs"}
            {...item}
          />
        </Col>
      ))}
    </BodyRow>
  );
}

// Progress bar section
export function LeaderboardProgressBarSection({
  milestones,
  progress,
  sectionBackground,
  progressBarColor,
  lastSection,
  date = false,
  ...rest
}) {
  return (
    <BodyRow $color={sectionBackground} $isLastSection={lastSection} {...rest}>
      <Col className="my-5 my-lg-0 py-2">
        <LeaderboardProgressBar
          milestones={milestones}
          progressBarColor={progressBarColor}
          progress={progress}
          date={date}
        />
      </Col>
    </BodyRow>
  );
}
