import { Icon } from "../../base/Icon";
import styled from "styled-components";
import { useSetAtom } from "jotai/index";
import { rewardDetailsModalAtom } from "../../../store/staking";

const EstimatedValueWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px 8px;
  padding-top: 8px;
  padding-bottom: 16px;
`;

const RewardInfoIcon = styled(Icon)`
  cursor: pointer;
`;

const EstimatedValueText = styled.div`
  line-height: 1;
  position: relative;
`;

const EstimatedValueHint = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.body};
  text-align: center;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  white-space: nowrap;
`;

export function StakingOverviewEstimatedReward({
  min,
  max,
  token,
}) {
  const setShowRewardModal = useSetAtom(rewardDetailsModalAtom);

  const handleOpenRewardModal = () => {
    setShowRewardModal({
      show: true,
      token: token,
    });
  };

  const error = !min || !max;

  return (
    <EstimatedValueWrap>
      {!error && (<>
          <div>
            <EstimatedValueText>
              {min}
              <EstimatedValueHint>1 mo</EstimatedValueHint>
            </EstimatedValueText>

          </div>
          <Icon name="full-arrow-right" color="white" />
          <div>
            <EstimatedValueText>
              {max} &nbsp;%
              <EstimatedValueHint>12 mo</EstimatedValueHint>
            </EstimatedValueText>
          </div>
        </>
      )}
      {error && (<EstimatedValueText> - </EstimatedValueText>)}

      <RewardInfoIcon name="info" color="#A583CC" onClick={handleOpenRewardModal} />
    </EstimatedValueWrap>
  );
}