import { iconsMap as classIcons } from "./ClassIcon";
import allIcon from "../../img/all.png";
import eggUnicorn from "../../img/lifecycle/egg.png";
import babyUnicorn from "../../img/lifecycle/baby.png";
import adultUnicorn from "../../img/lifecycle/adult.png";
import rarity1 from "../../img/rarity/rarity1.png";
import rarity2 from "../../img/rarity/rarity2.png";
import rarity3 from "../../img/rarity/rarity3.png";
import rarity4 from "../../img/rarity/rarity4.png";
import rarity5 from "../../img/rarity/rarity5.png";
import rarity6 from "../../img/rarity/rarity6.png";
import common from "../../img/rarity/common.png";
import rare from "../../img/rarity/rare.png";
import mythic from "../../img/rarity/mythic.png";
import rarityNeutralCommon from "../../img/rarity/rarityNeutralCommon.png";
import rarityNeutralRare from "../../img/rarity/rarityNeutralRare.png";
import rarityNeutralMythic from "../../img/rarity/rarityNeutralMythic.png";
import commonShadowcorn from "../../img/rarity/commonShadowcorn.png";
import rareShadowcorn from "../../img/rarity/rareShadowcorn.png";
import mythicShadowcorn from "../../img/rarity/mythicShadowcorn.png";
import accuracy from "../../img/stats/accuracy.png";
import attackSpeed from "../../img/stats/attack-speed.png";
import attack from "../../img/stats/attack.png";
import defense from "../../img/stats/defense.png";
import endurance from "../../img/stats/endurance.png";
import intelligence from "../../img/stats/intelligence.png";
import magic from "../../img/stats/magic.png";
import movement from "../../img/stats/movement.png";
import power from "../../img/stats/power.png";
import resistance from "../../img/stats/resistance.png";
import speed from "../../img/stats/speed.png";
import vitality from "../../img/stats/vitality.png";

export const iconsMap = {
  all: allIcon,
  egg: eggUnicorn,
  baby: babyUnicorn,
  adult: adultUnicorn,
  rarity1: rarity1,
  rarity2: rarity2,
  rarity3: rarity3,
  rarity4: rarity4,
  rarity5: rarity5,
  rarity6: rarity6,
  common: common,
  rare: rare,
  mythic: mythic,
  commonShadowcorn: commonShadowcorn,
  rareShadowcorn: rareShadowcorn,
  mythicShadowcorn: mythicShadowcorn,
  accuracy: accuracy,
  attackSpeed: attackSpeed,
  attack: attack,
  defense: defense,
  endurance: endurance,
  intelligence: intelligence,
  magic: magic,
  movement: movement,
  power: power,
  resistance: resistance,
  speed: speed,
  vitality: vitality,
  rarityNeutralCommon: rarityNeutralCommon,
  rarityNeutralRare: rarityNeutralRare,
  rarityNeutralMythic: rarityNeutralMythic,
  ...classIcons,
};

export function FilterIcon({ name, ...rest }) {
  let icon = iconsMap[name];

  if (!icon) {
    console.warn(`Icon ${name} not found`);
    return <></>;
  }

  return <img src={icon} {...rest} alt={name} />;
}