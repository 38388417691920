import { useState } from "react";
import styled from "styled-components";
import { TokenCard } from "./TokenCard";
import { getAttribute } from "../Helpers";
import { InventoryTokens } from "./InventoryTokens";
import { UnicornModal } from "./UnicornModal";
import Spinner from "../base/Spinner";
import { Typography } from "../base/Typography";
import { Button } from "../base/Button";
import { BUY } from "../../consts/externalLinks";
import { useGetInventoryUnicorns } from "../../query/inventory";

const PlaceholderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px;
  min-height: 528px;
  padding: 0 10px;
`;

const SpinnerWrap = styled.div`
  & svg {
    margin: 0;
  }
`;

const filters = [
  {
    title: "LIFECYCLE",
    options: [
      { value: null, title: "All", icon: "all" },
      { value: "Egg", title: "Egg", icon: "egg" },
      { value: "Baby", title: "Baby", icon: "baby" },
      { value: "Adult", title: "Adult", icon: "adult" }
    ],
    id: "lifecycle",
    getter: (item) => getAttribute("LifeCycle", item)
  },
  {
    title: "Class",
    options: [
      { value: null, title: "All", icon: "all" },
      { value: "Candy", title: "Candy", icon: "candy" },
      { value: "Cloud", title: "Cloud", icon: "cloud" },
      { value: "Crystal", title: "Crystal", icon: "crystal" },
      { value: "Flower", title: "Flower", icon: "flower" },
      { value: "Heart", title: "Heart", icon: "heart" },
      { value: "Moon", title: "Moon", icon: "moon" },
      { value: "Omnom", title: "Omnom", icon: "omnom" },
      { value: "Rainbow", title: "Rainbow", icon: "rainbow" },
      { value: "Star", title: "Star", icon: "star" }
    ],
    id: "class",
    getter: (item) => getAttribute("Class", item)
  },
  {
    title: "Mythic",
    options: [
      { value: null, title: "All", icon: "all" },
      { value: "Single Mythic", title: "Single Mythic", icon: "rarity1" },
      { value: "Double Mythic", title: "Double Mythic", icon: "rarity2" },
      { value: "Triple Mythic", title: "Triple Mythic", icon: "rarity3" },
      { value: "Quad Mythic", title: "Quad Mythic", icon: "rarity4" },
      { value: "Epic", title: "Epic", icon: "rarity5" },
      { value: "Legendary", title: "Legendary", icon: "rarity6" }
    ],
    id: "mythic"
  }
];

const filterGetters = {
  mythic: (item) => getAttribute("Mythic", item),
  class: (item) => getAttribute("Class", item),
  lifecycle: (item) => getAttribute("LifeCycle", item)
};

export function InventoryUnicorns() {
  const { data = [], isLoading } = useGetInventoryUnicorns();
  // const { balance, data, loading, loadCount } = useAtomValue(unicornsAtom);
  const [showModal, setShowModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const balance = data?.length || 0;
  const handleSelectUnicorn = (item) => {
    setSelectedToken(item);
    setShowModal(true);
  };

  let _toolbarTitle = `${balance} unicorns owned`;

  if (isLoading) {
    return (
      <PlaceholderWrap>
        <Typography
          tag={"h3"}
          variant={"textLg"}
          color="black"
          className="mb-2"
        >
          Loading unicorns
        </Typography>
        <SpinnerWrap>
          <Spinner color="black" />
        </SpinnerWrap>
      </PlaceholderWrap>
    );
  }

  return (
    <>
      {balance > 0 && (
        <>
          <InventoryTokens
            title={_toolbarTitle}
            data={data}
            filters={filters}
            filterGetters={filterGetters}
            renderCard={(item) => (
              <TokenCard
                onClick={() => {
                  handleSelectUnicorn(item);
                }}
                image={item.image}
                name={item.name}
                setShowModal={setShowModal}
                rightAttribute={{
                  title: "Class",
                  value: getAttribute("Class", item)
                }}
                leftAttribute={{
                  title: "LifeCycle",
                  value: getAttribute("LifeCycle", item)
                }}
              />
            )}
          />
          <UnicornModal
            showModal={showModal}
            setShowModal={setShowModal}
            unicorn={selectedToken}
          />
        </>
      )}
      {balance === 0 && (
        <PlaceholderWrap>
          <Typography tag={"h3"} variant={"textLg"} color="gray300">
            🦄 Oh, no unicorns yet? 🌈
          </Typography>
          <Typography tag={"h3"} variant={"textMd"} color="gray300">
            Begin your magical journey by adopting enchanting unicorns from
            Marketplace and let the adventure unfold! ✨🎉
          </Typography>
          <a href={BUY.MARKETPLACE.UNICORN_LISTINGS} target="_blank" rel="noopener noreferrer">
            <Button block={true} prependIcon="cart">
              Visit Marketplace
            </Button>
          </a>
        </PlaceholderWrap>
      )}
    </>
  );
}
