import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import ThemeProvider, { ThemeGlobalStyles } from "./theme";
// import bootstrap for grid
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import ScrollToTop from "./helpers/ScrollToTop";

// Firebase:
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { isTestnet } from "./components/Network";
import { ToastContainer } from "./components/ToastContainer";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./query";
import { WalletProvider } from "./components/ConnectWallet/WalletProvider";
import { ThirdwebProvider } from "thirdweb/react";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigStaging = {
  apiKey: "AIzaSyDuWBzDy105TjPBuJaWL3ntXE668yVfP_k",
  authDomain: "tribes-poc-c1ada.firebaseapp.com",
  projectId: "tribes-poc-c1ada",
  storageBucket: "tribes-poc-c1ada.appspot.com",
  messagingSenderId: "434770480049",
  appId: "1:434770480049:web:7ec18153a59539ff0e0b90",
  measurementId: "G-P8QKN1X5NB",
};

const firebaseConfigProd = {
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: "1015056971063",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "G-N74B7YW7CZ",
};

const firebaseConfig = isTestnet ? firebaseConfigStaging : firebaseConfigProd;

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const firebaseFunction = getFunctions(firebaseApp);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <ThemeProvider>
        <ThirdwebProvider>
          <QueryClientProvider client={queryClient}>
            <WalletProvider />
            <ThemeGlobalStyles />
            <App />
            <div id="portal"></div>
            <ToastContainer />
            {/* </WalletProvider> */}
          </QueryClientProvider>
        </ThirdwebProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
