import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Title = styled.h2`
  font-size: 32px;
  font-family: ${({ theme }) => theme.fonts.display};
  color: ${({ theme }) => theme.palette.white};
  text-shadow: 1px 4px 0px rgba(0, 0, 0, 0.3);
`;

const HeadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (${device.lg}) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const ActionsWrap = styled.div``;


export function StakingRewardsSection({
  title,
  actions,
  children,
}) {
  return (
    <Wrap>
      <HeadingWrap>
        <Title>
          {title}
        </Title>
        <ActionsWrap>
          {actions}
        </ActionsWrap>
      </HeadingWrap>
      {children}
    </Wrap>

  );
}