import styled from "styled-components";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { NavMenuCard } from "./NavMenuCard";

const Wrap = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.slate100};
`;

export function NavMenuCardFooter({ cards, scrollToGame }) {
  return (
    <Wrap>
      <Row>
        {cards.map((card, index) => (
          <Col
            key={card.title}
            xs={12}
            xl={6}
            xxl={4}
            className="mb-3 mb-xxl-0"
          >
            <NavMenuCard
              background={card.colors.background}
              iconBackground={card.colors.iconBackground}
              icon={card.icon}
              title={card.title}
              description={card.description}
              link={card.link}
            />
          </Col>
        ))}
      </Row>
    </Wrap>
  );
}
