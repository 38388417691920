import { Link } from "react-router-dom";
import styled from "styled-components";

const LinkWrap = styled(Link)`
  display: block;
  text-decoration: none;
  border-radius: 8px;
  padding: 10px;
  transition: background 0.2s ease-in-out;
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  filter: ${({ $disabled }) =>
    $disabled ? "grayscale(0.5)" : "theme.palette.blue200"};
  &:hover {
    background: ${({ theme, $disabled }) =>
      $disabled ? "none" : theme.palette.slate50};
  }
`;

const TitleWrap = styled.div`
  font-family: ${({ theme }) => theme.fonts.display};
  color: ${({ theme }) => theme.palette.darkBlue300};
  font-size: 18px;
  margin-bottom: 0px;
`;

const SubtitleWrap = styled.div`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.gray500};
  font-size: 12px;
`;

export function NavMenuSectionLink({
  path,
  title,
  subtitle,
  disabled = false,
  ...rest
}) {
  //console.log("path: ", path.includes("https"));
  return (
    <LinkWrap
      to={path}
      {...rest}
      target={path && path.includes("https") ? "_blank" : null}
      $disabled={disabled ? true : false}
    >
      <TitleWrap>{title}</TitleWrap>
      {subtitle && <SubtitleWrap>{subtitle}</SubtitleWrap>}
    </LinkWrap>
  );
}
