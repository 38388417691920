import styled from "styled-components";
import { Icon } from "../../base/Icon";
import { device } from "../../../theme/mediaQuery";

const PaginationWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 40px 0;

  flex-wrap: wrap;

  @media (${device.lg}) {
    flex-wrap: nowrap;
    justify-content: center;

    gap: 40px;
    padding: 80px 0;
  }

  @media (${device.xl}) {
    gap: 120px;
  }
`;

const PaginationButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  width: 45%;

  @media (${device.xl}) {
    width: auto;
  }
`;

const PaginationButtonTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 16px;
  color: ${({ theme, $active }) =>
    $active ? theme.palette.violet100 : theme.palette.white};
  transition: color 0.3s ease-in-out;
  white-space: nowrap;

  @media (${device.lg}) {
    font-size: 20px;
  }
`;

const PaginationButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-image: ${({ theme }) => theme.gradients.linearIndigo};
  background-color: ${({ theme }) => theme.palette.indigo500};
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 10px solid;
  border-color: ${({ theme, $active }) =>
    $active ? "transparent" : theme.palette.indigo400};
  color: ${({ theme }) => theme.palette.white};
  position: relative;
  background-clip: padding-box;
  transition: all 0.1s ease-in-out;

  & > * {
    position: relative;
    z-index: 10;
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transition: all 0.2s ease-in-out;
  }

  &:after {
    // border gradient
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background-image: linear-gradient(
      180deg,
      rgba(185, 132, 251, 0.2) 0%,
      rgba(117, 51, 247, 0.2) 100%
    );
    margin: -10px;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  &:before {
    // background gradient
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background: ${({ theme }) => theme.gradients.linearVioletLight};
    transform: ${({ $active }) => ($active ? "scale(1)" : "scale(0)")};
    transition: all 0.2s ease;
    z-index: 1;
  }
`;

export function GamePagination({ games, active, onChange }) {
  return (
    <PaginationWrap>
      {games.map((game, index) => (
        <PaginationButton key={game.title} onClick={() => onChange(index)}>
          <PaginationButtonTitle $active={active === index}>
            {game.title}
          </PaginationButtonTitle>
          <PaginationButtonIcon $active={active === index}>
            <Icon name={game.icon} size="55%" />
          </PaginationButtonIcon>
        </PaginationButton>
      ))}
    </PaginationWrap>
  );
}
