import React from "react";
import styled, { keyframes } from "styled-components";
import { Typography } from "../../base/Typography";
import { Box } from "../../base/Box";
import { device } from "../../../theme/mediaQuery";
import { Modal } from "../../leaderboard/components/Modal";
import { Portal } from "../../common/Portal";

const modalAnimation = keyframes`
  from {
    transform: translateY(-5%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ModalWrap = styled(Modal)`
  max-width: 749px;
  min-height: 300px;
  padding: 0;
  border-radius: 40px;
  display: flex;
  flex-direction: column;

  animation: ${modalAnimation} 0.3s ease-in-out;
`;

const Header = styled(Box)`
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px 7px 7px 0px rgba(254, 253, 254, 0.25) inset,
  0px 0px 13px 0px rgba(255, 255, 255, 0.25) inset;
  padding: 18px 24px;
  height: 62px;
  @media (${device.md}) {
    height: 80px;
    padding: 24px 40px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.white};
  padding: 16px;
  border-radius: ${({ $isLastSection }) =>
          !$isLastSection && "0px 0px 16px 16px"};
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  @media (${device.md}) {
    padding: 40px;
  }
`;

const Footer = styled.div`
  background-color: #40006b;
  margin-top: auto;
  border-radius: 0px 0px 16px 16px;
  padding: 8px 24px;
  @media (${device.md}) {
    padding: 8px 40px;
  }
`;

export function InfoModal({
  toggleModal,
  showModal,
  headerTitle,
  footer,
  children,
  ...rest
}) {
  return (
    <Portal>
      <ModalWrap
        toggleModal={toggleModal}
        showModal={showModal}
        opacity="md"
        fullscreen
      >
        <Header
          gradient="linear-gradient(0deg, #6E28AA, #6E28AA);"
          pattern="labyrinth"
          patternSize="60%"
        >
          <Typography as="h1" variant="textXl" md={{ variant: "displayXs" }}>
            {headerTitle}
          </Typography>
        </Header>
        <Body $isLastSection={footer} {...rest}>{children}</Body>
        {footer && <Footer>{footer}</Footer>}
      </ModalWrap>
    </Portal>
  );
}
