import Web3 from "web3";
import Vault_ABI from "../../CU_Vault_ABI.json";
import Reward_ABI from "../../CU_Vault_Reward_ABI.json";
import CU_ABI from "../../CU_ERC20_ABI.json";
import View_ABI from "../../CU_VAULT_VIEW_ABI.json";
import BadgeManager_ABI from "../../CU_VAULT_BADGE_MANAGER_ABI.json";
import Router_ABI from "../../Camelot_Router_ABI.json";
import LP_ABI from "../../CU_LP_ABI.json";
import { Network } from "../Network";
import { getGasPrice } from "../Helpers";
import { approveTokens } from "./helpers";
import { ethers } from "ethers";

const METAMASK_REJECT_ERROR_CODE = 4001;

export function getStakingContract(wallet, address, userAddress, rpc = null) {
  let walletAddress = userAddress;
  if (wallet) {
    // let walletAddress = userAddress;
    //let walletAddress = "0x353cbBA89369aF3D78B8e397982Ca85dc9Ae5fCE";

    const vaultContract = new ethers.Contract(address, Vault_ABI, wallet);

    const send = async (method, params) => {
      const estimateGasLimit = await vaultContract.estimateGas[method](
        ...params
      );
      const tx = await vaultContract[method](...params, {
        gasLimit: estimateGasLimit,
      });

      const receipt = await tx.wait();

      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      } else {
        return receipt;
      }
    };

    return {
      balanceOf() {
        //returns the sRBW balance
        return vaultContract.balanceOf(walletAddress);
      },
      withdrawableRewardsOf(address) {
        //I think this should be the deposit ID for claiming rewards
        return vaultContract.withdrawableRewardsOf(address, walletAddress);
      },
      withdrawRewardsOf() {
        return vaultContract.withdrawRewardsOf(address, walletAddress);
      },
      getDepostisOF() {
        return vaultContract.getDepositsOf(walletAddress);
      },
      async claimRewards(rewardAddress) {
        return send("claimRewards", [rewardAddress, walletAddress]);
      },

      async depostit(amount, duration) {
        const gasLimit = 7200000;
        return new Promise(async (resolve, reject) => {
          if (address === Network.Arbitrum.CUStakingContractAddress) {
            let CUContract = getCUContract(wallet);
            try {
              await approveTokens(
                [CUContract],
                [address],
                [amount],
                walletAddress
              );

              let intervalId = setInterval(async () => {
                let approvalAmount = await CUContract.allowance(
                  address,
                  walletAddress
                );
                if (parseFloat(approvalAmount) >= parseFloat(amount)) {
                  clearInterval(intervalId); // Stop the interval once condition is met
                  try {
                    const result = await send(
                      "deposit",
                      [amount, duration, walletAddress],
                      gasLimit
                    );
                    resolve(result); // Resolve the promise once deposit is successful
                  } catch (error) {
                    reject(error); // Reject the promise if deposit fails
                  }
                }
              }, 2000); // Check every 2 seconds
            } catch (error) {
              reject(error); // Reject the promise if approval fails
            }
          } else if (address === Network.Arbitrum.CULPStakingContractAddress) {
            let CULPContract = getCULPContract(wallet);
            try {
              await approveTokens(
                [CULPContract],
                [address],
                [amount],
                walletAddress
              );
              let intervalId = setInterval(async () => {
                let approvalAmount = await CULPContract.allowance(
                  address,
                  walletAddress
                );
                if (parseFloat(approvalAmount) >= parseFloat(amount)) {
                  clearInterval(intervalId); // Stop the interval once condition is met
                  try {
                    const result = await send(
                      "deposit",
                      [amount, duration, walletAddress],
                      gasLimit
                    );
                    resolve(result); // Resolve the promise once deposit is successful
                  } catch (error) {
                    reject(error); // Reject the promise if deposit fails
                  }
                }
              }, 2000); // Check every 2 seconds
            } catch (error) {
              reject(error); // Reject the promise if approval fails
            }
          } else {
            reject("Unknown contract address"); // Reject if address is not recognized
          }
        });
      },
      async claimAll() {
        return send("claimAll", [walletAddress]);
      },
      async withdraw(depositIndex) {
        return send("withdraw", [depositIndex, walletAddress]);
      },
      totalSupply() {
        return vaultContract.totalSupply();
      },
      getMultiplier(duration) {
        return vaultContract.getMultiplier(duration);
      },
    };
  }
  if (rpc) {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const vaultContract = new ethers.Contract(address, Vault_ABI, provider);
    return {
      balanceOf(userAddress) {
        //returns the sRBW balance
        return vaultContract.balanceOf(userAddress);
      },
      withdrawableRewardsOf(address, userAddress) {
        //I think this should be the deposit ID for claiming rewards
        return vaultContract.withdrawableRewardsOf(address, userAddress);
      },
      withdrawRewardsOf(address, userAddress) {
        return vaultContract.withdrawRewardsOf(address, userAddress);
      },
      getDepostisOF(userAddress) {
        return vaultContract.getDepositsOf(userAddress);
      },
      totalSupply() {
        return vaultContract.totalSupply();
      },
      getMultiplier(duration) {
        return vaultContract.getMultiplier(duration);
      },
    };
  }
}

export function getRewardContract(wallet, address, userAddress) {
  let walletAddress = userAddress;
  //let walletAddress = "0x353cbBA89369aF3D78B8e397982Ca85dc9Ae5fCE";
  const rewardContract = new ethers.Contract(address, Reward_ABI, wallet);

  const send = async (method, params) => {
    const estimateGasLimit = await rewardContract.estimateGas[method](
      ...params
    );
    const tx = await rewardContract[method](...params, {
      gasLimit: estimateGasLimit,
    });
    const receipt = await tx.wait();

    if (receipt.status === 0) {
      throw new Error("Transaction failed");
    }
  };

  return {
    getDepositsOf() {
      //locked rewards
      return rewardContract.getDepositsOf(walletAddress);
    },
    getTotalDeposit() {
      //total staked RBW
      return rewardContract.getTotalDeposit(walletAddress);
    },
    //In the viewData from the vault overview query. This is the index of the deposit
    async withdraw(rewardIndex) {
      return send("withdraw", [rewardIndex, walletAddress]);
    },
    totalSupply() {
      return rewardContract.totalSupply();
    },
  };
}

export function getCUContract(wallet, rpc = null) {
  if (wallet) {
    const cuContract = new ethers.Contract(
      Network.Arbitrum.CUContractAddress,
      CU_ABI,
      wallet
    );

    const send = async (method, params) => {
      const estimateGasLimit = await cuContract.estimateGas[method](...params);
      const tx = await cuContract[method](...params, {
        gasLimit: estimateGasLimit,
      });
      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
    };

    return {
      balanceOf(address) {
        //Check against 0x4942AfFDeCF26e3BbE43847F63660ab7bfA18136 for the total staked RBW
        return cuContract.balanceOf(address);
      },
      allowance(contractAddress, address) {
        return cuContract.allowance(address, contractAddress);
      },
      approve(contractAddress, amount) {
        return send("approve", [contractAddress, amount]);
      },
    };
  } else if (rpc) {
    const provider = new ethers.providers.JsonRpcProvider(rpc);

    const cuContract = new ethers.Contract(
      Network.Arbitrum.CUContractAddress,
      CU_ABI,
      provider
    );

    return {
      balanceOf(address) {
        //Check against 0x4942AfFDeCF26e3BbE43847F63660ab7bfA18136 for the total staked RBW
        return cuContract.balanceOf(address);
      },
      allowance(address, contractAddress) {
        return cuContract.allowance(address, contractAddress);
      },
    };
  }
}

export function getCULPContract(wallet, rpc = null) {
  if (wallet) {
    const culpContract = new ethers.Contract(
      Network.Arbitrum.CULPContractAddress,
      LP_ABI,
      wallet
    );

    const send = async (method, params) => {
      const estimateGasLimit = await culpContract.estimateGas[method](
        ...params
      );
      const tx = await culpContract[method](...params, {
        gasLimit: estimateGasLimit,
      });
      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
    };

    return {
      balanceOf(address) {
        //Check against 0x4843bc8C9537A9AAd44b9E3398D3F3614034BfB9 for the total staked RBWLP
        return culpContract.balanceOf(address);
      },
      allowance(contractAddress, address) {
        return culpContract.allowance(address, contractAddress);
      },
      approve(contractAddress, amount) {
        return send("approve", [contractAddress, amount]);
      },
      getReserves() {
        return culpContract.getReserves();
      },
      getAmountOut(amountIn, tokenAddress) {
        return culpContract.getAmountOut(amountIn, tokenAddress);
      },

      totalSupply() {
        return culpContract.totalSupply();
      },
    };
  }
  if (rpc) {
    const provider = new ethers.providers.JsonRpcProvider(rpc);

    const culpContract = new ethers.Contract(
      Network.Arbitrum.CULPContractAddress,
      LP_ABI,
      provider
    );

    return {
      balanceOf(address) {
        //Check against 0x4843bc8C9537A9AAd44b9E3398D3F3614034BfB9 for the total staked RBWLP
        return culpContract.balanceOf(address);
      },
      getReserves() {
        return culpContract.getReserves();
      },
      getAmountOut(amountIn, tokenAddress) {
        return culpContract.getAmountOut(amountIn, tokenAddress);
      },
      totalSupply() {
        return culpContract.totalSupply();
      },
    };
  }
}

export function getEthContract(wallet) {
  const ethContract = new ethers.Contract(
    Network.Arbitrum.ETHContractAddress,
    CU_ABI,
    wallet
  );

  const send = async (method, params) => {
    const estimateGasLimit = await ethContract.estimateGas[method](...params);
    const tx = await ethContract[method](...params, {
      gasLimit: estimateGasLimit,
    });
    const receipt = await tx.wait();
    if (receipt.status === 0) {
      throw new Error("Transaction failed");
    }
  };

  return {
    balanceOf(address) {
      const balance = wallet.provider.getBalance(address);
      return balance;
      //return ethContract.methods.balanceOf(wallet.accounts[0].address).call();
    },
    allowance(contractAddress, address) {
      return ethContract.allowance(address, contractAddress);
    },
    approve(contractAddress, amount) {
      return send("approve", [contractAddress, amount]);
    },
  };
}

export function getViewContract(wallet, address) {
  // let address = "0x353cbBA89369aF3D78B8e397982Ca85dc9Ae5fCE";
  const viewContract = new ethers.Contract(
    Network.Arbitrum.viewContractAddress,
    View_ABI,
    wallet
  );

  return {
    fetchData() {
      return viewContract.fetchData(address);
    },
  };
}

export function getBadgeManagerContract(
  wallet,
  contractAddress,
  walletAddress
) {
  const badgeManagerContract = new ethers.Contract(
    contractAddress,
    BadgeManager_ABI,
    wallet
  );

  const send = async (method, params) => {
    const estimateGasLimit = await badgeManagerContract.estimateGas[method](
      ...params
    );
    const tx = await badgeManagerContract[method](...params, {
      gasLimit: estimateGasLimit,
    });
    const receipt = await tx.wait();
    if (receipt.status === 0) {
      throw new Error("Transaction failed");
    }
  };

  return {
    getDelegateByBadges(ownerAddressArray, contractAddressArray, tokenIdArray) {
      return badgeManagerContract.getDelegateByBadges(
        ownerAddressArray,
        contractAddressArray,
        tokenIdArray
      );
    },
    getDelegationsOfDelegate() {
      return badgeManagerContract.getDelegationsOfDelegate(walletAddress);
    },
    delegateBadgeTo(tokenId, address) {
      return send("delegateBadgeTo", [
        Network.Arbitrum.terminusContractAddress,
        tokenId,
        address,
      ]);
    },
  };
}

export function getRouterContract(wallet) {
  const routerContract = new ethers.Contract(
    Network.Arbitrum.CamelotRouterAddress,
    Router_ABI,
    wallet
  );

  const send = async (method, params, value) => {
    const estimateGasLimit = await routerContract.estimateGas[method](
      ...params,
      {
        value: value,
      }
    );
    const tx = await routerContract[method](...params, {
      value: value,
      gasLimit: estimateGasLimit,
    });
    const receipt = await tx.wait();
    if (receipt.status === 0) {
      throw new Error("Transaction failed");
    } else {
      return receipt;
    }
  };

  return {
    async addLiquidity(
      CUAmountInput,
      WETHAmountInput,
      address,
      slippage = 0.005,
      endTime
    ) {
      const contractArr = [getCUContract(wallet)];
      const addressArr = [
        Network.Arbitrum.CamelotRouterAddress,
        //  Network.ETHContractAddress,
      ];
      const amountArr = [CUAmountInput];
      await approveTokens(contractArr, addressArr, amountArr, address);
      const CUTokenAddress = "";
      const WETHTokenAddress = "";
      const WETHAmountInputBN = ethers.BigNumber.from(WETHAmountInput);
      const CUAmountInputBN = ethers.BigNumber.from(CUAmountInput);
      const slippageBN = ethers.BigNumber.from(Math.floor(slippage * 1000));
      const thousandBN = ethers.BigNumber.from(1000);
      const slippageAmountEth =
        WETHAmountInputBN.mul(slippageBN).div(thousandBN);
      const WETHAmountMin = WETHAmountInputBN.sub(slippageAmountEth);

      const slippageAmountCU = CUAmountInputBN.mul(slippageBN).div(thousandBN);
      const CUAmountMin = CUAmountInputBN.sub(slippageAmountCU);
      // const slippageAmountEth = web3.utils
      //   .toBN(WETHAmountInput)
      //   .mul(web3.utils.toBN(slippage * 1000))
      //   .div(web3.utils.toBN(1000));

      // const WETHAmountMin = web3.utils
      //   .toBN(WETHAmountInput)
      //   .sub(slippageAmountEth);

      // const slippageAmountCU = web3.utils
      //   .toBN(CUAmountInput)
      //   .mul(web3.utils.toBN(slippage * 1000))
      //   .div(web3.utils.toBN(1000));

      // const CUAmountMin = web3.utils.toBN(CUAmountInput).sub(slippageAmountCU);

      const deadline = endTime
        ? endTime
        : Math.floor(Date.now() / 1000 + 60 * 20); //20 minutes

      return send(
        "addLiquidityETH",
        [
          Network.Arbitrum.CUContractAddress,
          CUAmountInput,
          CUAmountMin.toString(),
          WETHAmountMin.toString(),
          address,
          deadline,
        ],
        WETHAmountInput
      );
    },
  };
}
