import { TokenAttribute, TokenModel } from "./token";
import { AssetModel, AssetTokenType } from "./asset";
import { CurrencyToken } from "./currency-token";

export const RitualConstraintType = {
  NONE: 0,
  HATCHERY_LEVEL: 1,
  SHADOWCORN_RARITY: 2,
  SHADOWCORN_CLASS: 3,
  SHADOWCORN_BALANCE: 4,
  SHADOWCORN_MIGHT: 5,
  SHADOWCORN_WICKEDNESS: 6,
  SHADOWCORN_TENACITY: 7,
  SHADOWCORN_CUNNING: 8,
  SHADOWCORN_ARCANA: 9,
  BALANCE_UNICORN: 10,
  BALANCE_SHADOWCORN: 11,
};

export const RitualConstraintOperator = {
  NONE: 0,
  LESS_THAN: 1,
  LESS_THAN_OR_EQUAL: 2,
  EQUAL: 3,
  GREATER_THAN_OR_EQUAL: 4,
  GREATER_THAN: 5,
  NOT_EQUAL: 6,
};

export const RitualContraintShadowcornClass = {
  1: "Fire",
  2: "Slime",
  3: "Volt",
  4: "Soul",
  5: "Nebula",
};

export const RitualContraintShadowcornRarity = {
  1: "Common",
  2: "Rare",
  3: "Mythic",
};

export const RitualAttribute = {
  rarity: "Rarity",
  costs: "Costs",
  products: "Products",
  constraints: "Constraints",
  charges: "Charges",
  class: "Class",
  tier: "Tier",
  tokenType: "Token Type",
};

export const RitualRarity = {
  common: "Common",
  rare: "Rare",
  mythic: "Mythic",
};

export const RitualRarityIdMap = {
  1: RitualRarity.common,
  2: RitualRarity.rare,
  3: RitualRarity.mythic,
};

export class RitualConstraint {
  constructor(constraintType, operator, value) {
    this.constraintType = parseInt(constraintType);
    this.operator = parseInt(operator);
    this.value = value;
  }

  get title() {
    const value = this.value;
    const operatorSymbol = this.operatorSymbol;
    switch (this.constraintType) {
      case RitualConstraintType.HATCHERY_LEVEL:
        return `Shadow Forge Level ${operatorSymbol} ${value}`;
      case RitualConstraintType.SHADOWCORN_RARITY:
        const rarity = RitualContraintShadowcornRarity[value];
        return `Shadowcorn Rarity ${this.operatorSymbol} ${rarity}`;
      case RitualConstraintType.SHADOWCORN_CLASS:
        const shadowcornClass = RitualContraintShadowcornClass[value];
        return `${shadowcornClass} Shadowcorn`;
      case RitualConstraintType.SHADOWCORN_BALANCE:
        return `Shadowcorns ${operatorSymbol} ${value}`;
      case RitualConstraintType.SHADOWCORN_MIGHT:
        return `Might ${operatorSymbol} ${value}`;
      case RitualConstraintType.SHADOWCORN_WICKEDNESS:
        return `Wickedness ${operatorSymbol} ${value}`;
      case RitualConstraintType.SHADOWCORN_TENACITY:
        return `Tenacity ${operatorSymbol} ${value}`;
      case RitualConstraintType.SHADOWCORN_CUNNING:
        return `Cunning ${operatorSymbol} ${value}`;
      case RitualConstraintType.SHADOWCORN_ARCANA:
        return `Arcana ${operatorSymbol} ${value}`;
      case RitualConstraintType.BALANCE_UNICORN:
        return `Unicorns ${operatorSymbol} ${value}`;
      case RitualConstraintType.BALANCE_SHADOWCORN:
        return `Shadowcorns ${operatorSymbol} ${value}`;
      default:
        return "Unknown";
    }
  }

  get operatorSymbol() {
    switch (this.operator) {
      case RitualConstraintOperator.LESS_THAN:
        return "<";
      case RitualConstraintOperator.LESS_THAN_OR_EQUAL:
        return "<=";
      case RitualConstraintOperator.EQUAL:
        return "";
      case RitualConstraintOperator.GREATER_THAN_OR_EQUAL:
        return ">=";
      case RitualConstraintOperator.GREATER_THAN:
        return ">";
      case RitualConstraintOperator.NOT_EQUAL:
        return "not";
      default:
        return "Unknown";
    }
  }

  get iconName() {
    const value = this.value;
    const type = this.constraintType;

    switch (type) {
      case RitualConstraintType.HATCHERY_LEVEL:
        return "hatchery_level";
      case RitualConstraintType.SHADOWCORN_RARITY:
        const rarity = RitualContraintShadowcornRarity[value];
        return `sc_${rarity?.toLowerCase()}`;
      case RitualConstraintType.SHADOWCORN_CLASS:
        return "sc_balance";
      case RitualConstraintType.SHADOWCORN_BALANCE:
        return "sc_balance";
      case RitualConstraintType.SHADOWCORN_MIGHT:
        return "might";
      case RitualConstraintType.SHADOWCORN_WICKEDNESS:
        return "wickedness";
      case RitualConstraintType.SHADOWCORN_TENACITY:
        return "tenacity";
      case RitualConstraintType.SHADOWCORN_CUNNING:
        return "cunning";
      case RitualConstraintType.SHADOWCORN_ARCANA:
        return "arcana";
      case RitualConstraintType.BALANCE_UNICORN:
        return "unicorn_materials";
      case RitualConstraintType.BALANCE_SHADOWCORN:
        return "sc_balance";
      default:
        return "Unknown";
    }
  }
}

export class RitualCosts extends AssetModel {
  constructor(amount, asset, assetType, poolId) {
    super(asset, assetType, poolId);
    this.amount = amount;
  }

  get parsedAmount() {
    if (this.tokenType === AssetTokenType.darkMark) {
      const currencyToken = new CurrencyToken("darkMark", this.amount);
      return currencyToken.parsed;
    } else if (this.tokenType === AssetTokenType.unim) {
      const currencyToken = new CurrencyToken("unim", this.amount);
      return currencyToken.parsed;
    }

    return parseInt(this.amount);
  }
}

export class RitualProduct extends AssetModel {
  constructor(amount, asset, assetType, poolId) {
    super(asset, assetType, poolId);
    this.amount = amount;
  }

  get parsedAmount() {
    return parseInt(this.amount);
  }
}

export class RitualModel extends TokenModel {
  get rarity() {
    return this.getAttributeValue(RitualAttribute.rarity);
  }

  get costs() {
    const costsAttribute = this.getAttributeValue(RitualAttribute.costs);

    return costsAttribute.map(
      (cost) =>
        new RitualCosts(cost.amount, cost.asset, cost.assetType, cost.poolId)
    );
  }

  get product() {
    const productsAttribute = this.getAttributeValue(RitualAttribute.products);
    const firstProduct = productsAttribute[0] || {};

    return new RitualProduct(
      firstProduct.amount,
      firstProduct.asset,
      firstProduct.assetType,
      firstProduct.poolId
    );
  }

  get constraints() {
    const constraintsAttribute = this.getAttributeValue(
      RitualAttribute.constraints
    );

    const constraints = constraintsAttribute.map(
      (constraint) =>
        new RitualConstraint(
          constraint.constraintType,
          constraint.operator,
          constraint.value
        )
    );

    const filtered = constraints.filter((constraint) => {
      return constraint.constraintType !== RitualConstraintType.NONE;
    });

    return filtered;
  }

  get charges() {
    return this.getAttributeValue(RitualAttribute.charges);
  }

  get chargesAmount() {
    const chargeAttribute = this.getAttributeValue(RitualAttribute.charges);

    // if charge is infinity or unlimited, return null
    if (
      chargeAttribute === "infinity" ||
      chargeAttribute === "Unlimited" ||
      chargeAttribute > Number.MAX_SAFE_INTEGER
    ) {
      return null;
    }

    return parseInt(chargeAttribute);
  }

  get tier() {
    return this.product.tier || 1;
  }

  get tierName() {
    switch (this.tier) {
      case 1:
        return "One";
      case 2:
        return "Two";
      case 3:
        return "Three";
      default:
        return this.tier;
    }
  }

  get hasConstraints() {
    return this.constraints.length > 0;
  }

  static fromJSON(json) {
    const token = super.fromJSON(json);
    return new RitualModel(
      token.name,
      token.image,
      token.attributes,
      token.rawJson,
      token.tokenId
    );
  }

  static fromBlockchainObject(token) {
    const name = token.name;
    const image = token.image;
    // we need to manually parse the attributes because they are not in the correct format

    // retrieve the class of the product
    const asset =
      token.products.length > 0
        ? new AssetModel(
            token.products[0].asset,
            token.products[0].assetType,
            token.products[0].poolId
          )
        : null;

    const attributes = [
      new TokenAttribute(RitualAttribute.charges, token.charges, null, null),
      new TokenAttribute(RitualAttribute.costs, token.costs, null, null),
      new TokenAttribute(RitualAttribute.products, token.products, null, null),
      new TokenAttribute(
        RitualAttribute.constraints,
        token.constraints,
        null,
        null
      ),
      new TokenAttribute(
        RitualAttribute.rarity,
        RitualRarityIdMap[token.rarity],
        null,
        null
      ),
      new TokenAttribute(RitualAttribute.class, asset.class, null, null),
      new TokenAttribute(RitualAttribute.tier, asset.tier, null, null),
      new TokenAttribute(
        RitualAttribute.tokenType,
        asset.ritualType,
        null,
        null
      ),
    ];
    const rawJson = token;
    const tokenId = token.tokenId;

    return new RitualModel(name, image, attributes, rawJson, tokenId);
  }
}
