import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import emailSpellChecker from "@zootools/email-spell-checker";

import { Typography } from "../components/base/Typography";
import { Container } from "../components/base/Container";
import { SectionCard } from "../components/common/SectionCard";
import { Row } from "../components/base/Row";
import { Col } from "../components/base/Col";
import { HomeVideoCarouselItem } from "../components/home/hero/HomeVideoCarouselItem";
import { Button } from "../components/base/Button";
import { Card } from "../components/base/Card";

import { Footer } from "../layouts/BaseLayout/Footer";
import { device } from "../theme/mediaQuery";
import useDocumentTitle from "../helpers/useDocumentTitle";

import logo from "../img/logo.png";
import unicorns_left from "../img/billboard/unicorns-left.png";
import unicorns_right from "../img/billboard/unicorns-right.png";
import background from "../img/billboard/torn-background.png";
import jousting from "../img/billboard/jousting.png";
import land from "../img/billboard/land.png";
import shadowcorn from "../img/billboard/shadowcorn.png";
import tribe from "../img/billboard/tribe.png";
import billboard_top from "../img/home/carousel/jousting-top.png";
import billboard_bottom from "../img/home/carousel/jousting-bottom.png";
import billboard_foreground from "../img/home/carousel/jousting-foreground.png";
import billboard_video from "../img/home/carousel/rewards.mp4";
import { Modal } from "../components/base/Modal";
import { googleAnalyticsEvent } from "../helpers/googleAnalytics";
import { BILLBOARD_SF, EVENT_KEYS } from "../consts/googleEvents";

const NavWrap = styled.nav`
  background: url(${background});
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 154px;

  @media (${device.sm}) {
    min-height: 250px;
  }

  @media (${device.lg}) {
    min-height: 412px;
  }
`;

const NavbarBrandWrap = styled(Link)`
  display: block;
  padding: 21px 0 0 14px;
  width: 92px;

  @media (${device.sm}) {
    padding: 30px 0 0 26px;
    width: 140px;
  }
  @media (${device.lg}) {
    padding: 43px 0 0 82px;
    width: 185px;
  }
`;

const Logo = styled.img`
  width: inherit;
`;

const HeroWrap = styled(Row)`
  text-align: center;
  margin-top: -10px;

  @media (min-width: 396px) {
    margin-top: -30px;
  }

  @media (${device.sm}) {
    margin-top: -85px;
  }
  @media (${device.lg}) {
    margin-top: -130px;
  }
  @media (${device.xl}) {
    margin-top: -150px;
  }
`;

const HeroConfirmationWrap = styled(HeroWrap)`
  margin-bottom: 24px;
  @media (${device.sm}) {
    margin-top: -55px;
  }
  @media (${device.lg}) {
    margin-top: -95px;
    margin-bottom: 32px;
  }
`;

const VideoWrap = styled(Row)`
  margin: 0 auto;
  width: 100vh;
  max-width: 181px;
  margin-top: 10px;
  margin-bottom: 54px;

  @media (${device.md}) {
    max-width: 267px;
    margin-top: 20px;
  }
  @media (${device.lg}) {
    max-width: 334px;
    margin-top: 30px;
  }
  @media (${device.xl}) {
    max-width: 400px;
    margin-top: 66px;
    margin-bottom: 80px;
  }
`;

const VideoConfirmationWrap = styled(VideoWrap)`
  margin-bottom: 32px;
  @media (${device.xl}) {
    margin-bottom: 60px;
  }
`;

const CardsWrap = styled(Row)`
  margin: 32px 0;
  gap: 16px;

  @media (${device.md}) {
    gap: 32px;
  }
  @media (${device.xl}) {
    gap: 48px 66px;
  }
`;

const Input = styled.input`
  background: ${({ theme }) => theme.palette.black};
  border: none;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  max-width: 400px;

  font-size: 20px;
  font-weight: 700;
  line-height: 19px;

  padding: 16px;
  color: ${({ theme }) => theme.palette.white};

  &:focus {
    box-shadow: none;
    outline: none;
  }
`;

const ErrorWrap = styled(Typography)`
  min-height: 24px;
  align-self: flex-start;
  padding-left: 10px;
}
`;

const EmailWrap = styled.span`
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
`;
const CTAWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 12px;
  margin-bottom: 64px;

  @media (${device.md}) {
    margin-bottom: 80px;
  }
`;

const ITEM = {
  title: "Enter to win",
  activePage: 1,
  topImage: billboard_top,
  bottomImage: billboard_bottom,
  video: billboard_video,
  foregroundImage: billboard_foreground,
};

const CARDS = [
  {
    title: "Harvest and Craft",
    subtitle: "Level up your buildings and prepare for the impending darkness",
    img: land,
    color: "redishOrange300",
  },
  {
    title: "Unicorn Jousting",
    subtitle:
      "Showcase the might of your Unicorns in thrilling PvP competitions and engaging mini-games.",
    img: jousting,
    color: "mintGreen300",
    reverse: true,
  },
  {
    title: "Shadowcorn",
    subtitle:
      "Embrace the chaos and unleash the power of the Shadowcorns upon an unsuspecting world.",
    img: shadowcorn,
    color: "#5118A4",
  },
  {
    title: "Tribes (coming soon)",
    subtitle:
      "Create or join a tribe, collaborate with teammates, and experience collective triumphs in our magical unicorn lands.",
    img: tribe,
    color: "carnationPink200",
    reverse: true,
  },
];

export function Billboard() {
  useDocumentTitle("Billboard SF");
  const [currentVideoRef, setCurrentVideoRef] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [emailSuggestion, setEmailSuggestion] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const inputRef = useRef(null);

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,13}$/;
    return emailPattern.test(email);
  };

  const handleEnterNow = () => {
    const isValid = validateEmail(email);
    if (isValid) {
      submitEmail();
    } else {
      setError("Please enter a valid email!");
    }
  };

  const submitEmail = () => {
    googleAnalyticsEvent(EVENT_KEYS.BILLBOARD_SF, BILLBOARD_SF.MODAL_OPENED);

    window._scq.push([
      "identify",
      {
        email: email,
        tags: ["Billboard SF", "Billboard SF 2023"],
        success: function () {
          googleAnalyticsEvent(
            EVENT_KEYS.BILLBOARD_SF,
            BILLBOARD_SF.EMAIL_SUBMITTED
          );
          setEmail("");
          setShowModal(false);
          window.scrollTo(0, 0);
          setConfirmation(true);
        },
        failure: function () {
          setError("Please try again later.");
          googleAnalyticsEvent(
            EVENT_KEYS.BILLBOARD_SF,
            BILLBOARD_SF.EMAIL_FAILED
          );
        },
      },
    ]);
  };

  const onChangeEmail = (e) => {
    const suggestedEmail = emailSpellChecker.run({
      email: e.target.value,
      domains: [...emailSpellChecker.POPULAR_DOMAINS, "laguna.games"],
    });

    setEmailSuggestion("");
    setError("");
    setEmail(e.target.value);

    if (suggestedEmail) {
      setEmailSuggestion(suggestedEmail.full);
    }
  };

  const pauseVideo = () => {
    // if user opens the modal, pause current video
    if (currentVideoRef !== null) {
      currentVideoRef.current?.pause();
    }
  };

  const openModal = () => {
    pauseVideo();
    setShowModal(true);
  };

  const closeModal = () => {
    setError("");
    setEmail("");
    setEmailSuggestion("");
    setShowModal(false);
  };
  const handleEmailSuggestion = () => {
    setEmail(emailSuggestion);
    setEmailSuggestion("");
  };

  useEffect(() => {
    if (showModal) {
      // Focus on the input element
      inputRef.current.focus();
    }
  }, [showModal]);

  const renderConfirmation = (
    <>
      <HeroConfirmationWrap>
        <Typography
          tag="h1"
          variant="displaySm"
          md={{ variant: "displayLg" }}
          lg={{ variant: "displayXl" }}
          color="black"
          className="text-center mt-4 mt-lg-5 mt-xl-5 px-4"
        >
          Thank you for submitting
        </Typography>
        <Typography
          tag="h1"
          variant="textXl"
          md={{ variant: "displaySm" }}
          lg={{ variant: "displayMd" }}
          xl={{ variant: "displayLg" }}
          color="black"
          family="minimal"
          className=" px-4"
        >
          Winners will be picked and announced every week
        </Typography>
      </HeroConfirmationWrap>

      <VideoConfirmationWrap>
        <HomeVideoCarouselItem
          onPlayVideo={setCurrentVideoRef}
          portrait
          {...ITEM}
        />
      </VideoConfirmationWrap>
      <CTAWrap>
        <Typography
          tag="h3"
          variant="displayMd"
          md={{ variant: "displayLg" }}
          lg={{ variant: "displayXl" }}
          xl={{ variant: "display2xl" }}
          color="black"
          className="text-center"
          family="minimal"
        >
          Learn more about Crypto Unicorns
        </Typography>
        <Button
          variant="secondary"
          block={true}
          as={Link}
          sm={{
            block: false,
          }}
          target="_self"
          rel="noopener"
          to="/"
          className="mt-3 mt-lg-4"
        >
          Visit the website
        </Button>
      </CTAWrap>
    </>
  );

  const suggestion = emailSuggestion ? (
    <>
      Did you mean:{" "}
      <EmailWrap onClick={handleEmailSuggestion}>{emailSuggestion}</EmailWrap>
    </>
  ) : null;

  const renderBillboardBody = (
    <>
      <HeroWrap>
        <Typography
          tag="h1"
          variant="textXl"
          md={{ variant: "displaySm" }}
          lg={{ variant: "displayMd" }}
          xl={{ variant: "displayLg" }}
          color="black"
          family="minimal"
        >
          Step into the exciting multiverse of
          <Typography
            tag="span"
            variant="displayLg"
            md={{ variant: "displayXl" }}
            lg={{ variant: "display2xl" }}
            xl={{ variant: "display3xl" }}
            color="black"
            family="minimal"
            className="d-block"
          >
            Crypto Unicorns
          </Typography>
        </Typography>
      </HeroWrap>
      <VideoWrap>
        <HomeVideoCarouselItem
          onPlayVideo={setCurrentVideoRef}
          portrait
          {...ITEM}
        />
      </VideoWrap>
      <WinBundleCard handleClick={openModal} />
      <CardsWrap>
        {CARDS.map((item, key) => (
          <Col
            md={12}
            lg={4}
            className="p-0 flex-grow-1"
            key={`${item.title}-${key}`}
          >
            <Card {...item} />
          </Col>
        ))}
      </CardsWrap>
      <WinBundleCard handleClick={openModal} className="d-block d-sm-none" />
      <Modal
        showModal={showModal}
        setShowModal={closeModal}
        title="Enter your email!"
        size="md"
        variant="secondary"
        patternSize="md"
      >
        <Input
          type="text"
          placeholder=""
          value={email}
          onChange={onChangeEmail}
          ref={inputRef}
        />
        <ErrorWrap>{error ? error : suggestion}</ErrorWrap>
        <Button
          variant="secondary"
          onClick={handleEnterNow}
          disabled={email === ""}
        >
          Enter now
        </Button>
      </Modal>
    </>
  );

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <NavWrap>
          <NavbarBrandWrap to="/">
            <Logo className="logo" src={logo} alt="Crypto Unicorns" />
          </NavbarBrandWrap>
        </NavWrap>
        <Container>
          {confirmation ? renderConfirmation : renderBillboardBody}
        </Container>
      </div>
      <Footer />
    </>
  );
}

const ImgLeft = styled.img`
  position: absolute;
  top: 52px;
  height: 100%;
  left: -170px;

  @media (min-width: 430px) {
    left: -110px;
  }
  @media (min-width: 490px) {
    left: -90px;
  }
  @media (${device.sm}) {
    left: -100px;
  }
  @media (${device.md}) {
    top: -10%;
    height: 120%;
    left: -250px;
  }
  @media (${device.lg}) {
    left: -165px;
  }
  @media (${device.xxl}) {
    left: -85px;
  }
`;

const ImgRight = styled(ImgLeft)`
  left: unset;
  right: -170px;

  @media (min-width: 430px) {
    right: -110px;
  }

  @media (min-width: 490px) {
    right: -90px;
  }
  @media (${device.sm}) {
    right: -100px;
  }
  @media (${device.md}) {
    right: -250px;
  }
  @media (${device.lg}) {
    right: -165px;
  }
  @media (${device.xxl}) {
    right: -85px;
  }
`;

const SectionContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 32px;
  margin: 52px 0;
  position: relative;
  @media (${device.md}) {
    gap: 70x;
    margin: 140px 0;
  }
`;

const WinBundleCard = ({ handleClick, ...rest }) => {
  return (
    <SectionCard
      title="Enter to win a free..."
      style={{ position: "relative" }}
      {...rest}
    >
      <SectionContentWrap>
        <ImgLeft src={unicorns_left} alt="" />
        <Typography
          tag="p"
          variant="displayXs"
          md={{ variant: "displayLg" }}
          xl={{ variant: "displayXl" }}
          family="display"
        >
          Digital collectible
          <Typography
            tag="span"
            variant="displayXs"
            md={{ variant: "displayLg" }}
            xl={{ variant: "displayXl" }}
            family="display"
            className="d-block"
          >
            Unicorn bundle
          </Typography>
        </Typography>
        <Button variant="secondary" onClick={handleClick}>
          Enter now
        </Button>
        <ImgRight src={unicorns_right} alt="" />
      </SectionContentWrap>
    </SectionCard>
  );
};
