import styled from "styled-components";
import { TrapezoidBox } from "./TrapezoidBox";
import { device } from "../../theme/mediaQuery";
import { BoxPatternSize } from "../base/Box";

const SectionCardWrap = styled.div`
  border-radius: ${({ $border, $borderColor }) =>
    $borderColor === "none" ? "16px" : $border ? "64px" : "22px"};
  border: 16px solid
    ${({ $borderColor, theme }) =>
      theme.palette[$borderColor] ? theme.palette[$borderColor] : $borderColor};
  background: ${({ $color }) => $color};
  transition: background 0.3s ease, border 0.3s ease;

  box-shadow: ${({ $shadow }) => ($shadow ? "0px 8px 27px 6px #00000033" : "")};
  position: relative;
  z-index: 1;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${({ theme }) => theme.pattern.sprinkles});
    background-size: ${({ $patternSize }) => $patternSize};
    opacity: 0.03;
    z-index: 1;
  }
`;

const SectionCardBody = styled.div`
  padding: 0 15px;
  position: relative;
  z-index: 50;

  @media (${device.lg}) {
    padding: 0 30px;
  }
`;

const SectionTitleWrap = styled(TrapezoidBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ $border }) => ($border ? "-16px" : "")}; // to overlap border
  z-index: 2;
  margin-bottom: 35px;
  filter: ${({ $shadow }) =>
    $shadow ? "drop-shadow(0px 12px 8px rgba(64, 86, 205, 0.30));" : ""};

  transition: background 0.6s ease;

  @media (${device.xl}) {
    padding: ${({ $border }) => ($border ? "25px 0" : "15px 0")};
    width: 65%;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  text-align: center;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

  @media (${device.md}) {
    font-size: 25px;
  }
  @media (${device.lg}) {
    font-size: ${({ $border }) => ($border ? "45px" : "26px")};
  }
  @media (${device.xl}) {
    font-size: ${({ $border }) => ($border ? "45px" : "32px")};
  }
`;

const BackgroundTrapezoidWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  filter: ${({ $shadow }) =>
    $shadow ? "drop-shadow(0px 12px 8px rgba(64, 86, 205, 0.30))" : ""};
`;

const BackgroundTrapezoid = styled(TrapezoidBox)`
  height: 30px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  transition: background 0.6s ease;

  @media (${device.xl}) {
    width: 85%;
  }
`;

const ImgWrap = styled.img`
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  max-width: 121px;
  top: -75px;
`;

export function SectionCard({
  title,
  children,
  border = true,
  patternSize,
  titleBackground = "linear-gradient(180deg, #4CADF1 0%, #4C73F4 100%)",
  background = "linear-gradient(180deg, #5252f2 0%, #3131b0 100%)",
  trapezoidColor = "linear-gradient(180deg, #1784DB 0%, #2953DB 100%)",
  borderColor = "blue200",
  shadow = false,
  img,
  ...rest
}) {
  const $patternSize = BoxPatternSize[patternSize] || patternSize;
  return (
    <SectionCardWrap
      $color={background}
      $borderColor={borderColor}
      $patternSize={$patternSize}
      $border={border}
      $shadow={shadow}
      {...rest}
    >
      {img && <ImgWrap src={img} />}
      <SectionTitleWrap
        $shadow={shadow}
        $border={border}
        $color={titleBackground}
        className="title-wrap"
      >
        <SectionTitle $border={border}>{title}</SectionTitle>
      </SectionTitleWrap>
      {border && (
        <BackgroundTrapezoidWrap $shadow={shadow}>
          <BackgroundTrapezoid $color={trapezoidColor} />
        </BackgroundTrapezoidWrap>
      )}
      <SectionCardBody>{children}</SectionCardBody>
    </SectionCardWrap>
  );
}
