import styled, { useTheme } from "styled-components";
import React from "react";

export const BoxVariant = {
  gradient: "gradient",
  flat: "flat",
};

export const BoxRound = {
  none: "none",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  full: "full",
};

function pickBoxRound({ $round }) {
  switch ($round) {
    case BoxRound.md:
      return "16px";
    case BoxRound.sm:
      return "8px";
    case BoxRound.lg:
      return "32px";
    case BoxRound.xl:
      return "64px";
    case BoxRound.full:
      return "9999px";
    case BoxRound.none:
      return "0px";
    default:
      return "0px";
  }
}

export const BoxPatternSize = {
  xs: "25%",
  sm: "50%",
  md: "100%",
  lg: "150%",
};

const BaseBox = styled.div`
  position: relative;
  z-index: 1;
  ${({ $color }) => $color && `background: ${$color};`}
  ${({ $gradient }) => $gradient && `background: ${$gradient};`}

  box-shadow: ${({ $isGradient, $boxShadow }) =>
    $isGradient && $boxShadow && "0px 7px 7px 0px #FEFDFE40 inset"};
  border-radius: ${pickBoxRound};
`;

export const PatternBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${({ theme, pattern }) => theme.pattern[pattern]});
  ${({ $patternSize }) => $patternSize && `background-size: ${$patternSize};`}
  opacity: ${({ $opacity }) => $opacity || 0.05};
  z-index: -1;
  border-radius: ${pickBoxRound};
  ${({ $isPatternInverted }) => $isPatternInverted && `filter: invert(100%);`}
  &:after {
    //pattern gradient
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    ${({ $patternGradient }) =>
      $patternGradient && `background: ${$patternGradient};`}
    z-index: -1;
  }
`;

export function Box({
  variant,
  color,
  pattern,
  rounded,
  children,
  gradient,
  patternSize = "lg",
  tag,
  patternOpacity,
  patternGradient,
  patternTag,
  patternInvert = false,
  boxShadow = true,
  ...rest
}) {
  const theme = useTheme();
  const $color = theme.palette[color] || color;
  const $gradient = theme.gradients[gradient] || gradient;
  const $patternSize = BoxPatternSize[patternSize] || patternSize;
  const componentTag = tag || "div";
  const patternComponentTag = patternTag || "div";

  return (
    <BaseBox
      $round={rounded}
      $color={$color}
      $isGradient={variant === BoxVariant.gradient}
      $gradient={$gradient}
      $boxShadow={boxShadow}
      as={componentTag}
      {...rest}
    >
      {children}
      {pattern && (
        <PatternBackground
          $round={rounded}
          pattern={pattern}
          $patternSize={$patternSize}
          $opacity={patternOpacity}
          $patternGradient={patternGradient}
          $isPatternInverted={patternInvert}
          as={patternComponentTag}
          className="pattern"
        />
      )}
    </BaseBox>
  );
}
