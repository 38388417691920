import { useEffect, useState } from "react";
import styled from "styled-components";
import { Pagination } from "../base/Pagination";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export function PaginatedList({ items, renderPage, onPageChange }) {
  const [page, setPage] = useState(1);
  const itemsPerPage = 12;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = items.slice(startIndex, endIndex);
  const totalPages =
    Math.ceil(items.length / itemsPerPage) === 0
      ? 1
      : Math.ceil(items.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  // watch data length and reset page if necessary
  useEffect(() => {
    setPage(1);
    onPageChange(1);
  }, [items.length, onPageChange]);

  return (
    <Wrap>
      {renderPage(itemsToDisplay)}
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </Wrap>
  );
}
