import { useWeb3Query } from "./utils/useWeb3Query";
import { NetworkConfiguration } from "../components/Network";
import { convertNum } from "../components/Helpers";
import { getERC20Contract } from "../contracts";
import { getCUContract } from "../components/bridge/contracts";
import { queryClient } from "./index";
import { ethers } from "ethers";

export const GET_WALLET_TOKENS_QUERY_KEY = "GET_WALLET_TOKENS_QUERY_KEY";

export const useGetWalletTokens = () => {
  return useWeb3Query({
    queryKey: [GET_WALLET_TOKENS_QUERY_KEY],
    queryFn: async ({ wallet, address }) => {
      const netconfig =
        NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];
      const polygonRPC = netconfig.PolygonRPC;
      //Remove this if statement when we are done testing
      // if (netconfig.networkId === 80002) {
      //   let unimAddr = netconfig.UNIMContractAddress;
      //   let maticAddr = netconfig.MATICContractAddress;
      //   const UNIMContract = getERC20Contract(wallet, unimAddr, polygonRPC);
      //   const MaticContract = getERC20Contract(wallet, maticAddr, polygonRPC);
      //   const _UNIMBalance = await UNIMContract.balanceOf(address);
      //   const _MaticBalance = await MaticContract.balanceOf(address);
      //   console.log("UNIMBalance", _UNIMBalance);
      //   console.log("MaticBalance", _MaticBalance);
      //   return {
      //     UNIM: parseFloat(convertNum(_UNIMBalance / Math.pow(10, 18))),
      //     MATIC: parseFloat((_MaticBalance / Math.pow(10, 18)).toFixed(5)),
      //     UNIMWEI: _UNIMBalance,
      //   };
      // }
      let unimAddr = netconfig.UNIMContractAddress;
      let unimXai = netconfig.Xai.unimAddress;

      let rbwAddr = netconfig.RBWContractAddress;
      // let wethAddr = netconfig.WETHContractAddress;
      let maticAddr = netconfig.MATICContractAddress;
      let xaiAddr = netconfig.Arbitrum.XAIContractAddress;
      const UNIMContract = getERC20Contract(null, unimAddr, polygonRPC);
      const RBWContract = getERC20Contract(null, rbwAddr, polygonRPC);
      // const WETHContract = getERC20Contract(null, wethAddr, polygonRPC);
      const MaticContract = getERC20Contract(null, maticAddr, polygonRPC);
      const XaiContract = getERC20Contract(
        null,
        xaiAddr,
        netconfig.ArbitrumRPC
      );
      const unimXaiContract = getERC20Contract(
        null,
        unimXai,
        "https://xai-chain.net/rpc"
      ); //netconfig.XaiRPC
      const CUContract = await getCUContract();

      const provider = new ethers.providers.JsonRpcProvider(
        netconfig.ArbitrumRPC
      );
      const ethArbBalance = await provider.getBalance(address);
      const _UNIMBalance = await UNIMContract.balanceOf(address);

      const _RBWBalance = await RBWContract.balanceOf(address);
      // const _WETHBalance = await WETHContract.balanceOf(address);
      const _MaticBalance = await MaticContract.balanceOf(address);
      const _CUBalance = await CUContract.balanceOf(address);
      const _XaiBalance = await XaiContract.balanceOf(address);

      const _unimXaiBalance = await unimXaiContract.balanceOf(address);
      return {
        UNIM: ethers.utils.formatEther(_UNIMBalance).toString(),
        RBW: Number(ethers.utils.formatEther(_RBWBalance)).toFixed(2),
        ETH: Number(ethers.utils.formatEther(ethArbBalance)).toFixed(2),
        MATIC: Number(ethers.utils.formatEther(_MaticBalance)).toFixed(5),
        CU: Number(ethers.utils.formatEther(_CUBalance)).toFixed(2),
        XAI: Number(ethers.utils.formatEther(_XaiBalance)).toFixed(2),
        UNIMXAI: Number(ethers.utils.formatEther(_unimXaiBalance)).toFixed(2),
        RBWWEI: _RBWBalance,
        UNIMWEI: _UNIMBalance,
      };
    },
  });
};

export const invalidateGetWalletTokens = (walletAddress) => {
  return queryClient.invalidateQueries(
    GET_WALLET_TOKENS_QUERY_KEY,
    walletAddress
  );
};
