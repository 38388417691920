import styled from "styled-components";
import { TrapezoidBox } from "./TrapezoidBox";
import { device } from "../../theme/mediaQuery";

const SectionCardWrap = styled.div`
  border-radius: ${({ $borderColor }) =>
    $borderColor === "none" ? "16px" : "20px"};
  border: 8px solid
    ${({ $borderColor, theme }) =>
      theme.palette[$borderColor] ? theme.palette[$borderColor] : $borderColor};
  background: ${({ $color }) => $color};
  backdrop-filter: blur(22px);
  transition: background 0.3s ease, border 0.3s ease;

  box-shadow: ${({ $shadow }) => ($shadow ? "0px 8px 27px 6px #00000033" : "")};
  position: relative;
  z-index: 1;
`;

const SectionCardBody = styled.div`
  padding: 0 15px;
  position: relative;
  z-index: 50;

  @media (${device.lg}) {
    padding: 0 30px;
  }
`;

const SectionTitleWrap = styled(TrapezoidBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ $border }) => ($border ? "-16px" : "")}; // to overlap border
  z-index: 2;
  margin-bottom: 35px;
  filter: ${({ $shadow }) =>
    $shadow ? "drop-shadow(0px 12px 8px rgba(64, 86, 205, 0.30));" : ""};

  transition: background 0.6s ease;

  @media (${device.md}) {
    width: 55%;
  }

  @media (${device.xl}) {
    padding: ${({ $border }) => ($border ? "16px 0" : "15px 0")};
    width: 35%;
  }

  // shift up
  ::before,
  ::after {
    top: 8px;
    @media (${device.lg}) {
      top: 0;
    }
    @media (${device.xl}) {
      top: -10px;
    }
  }
`;

const SectionTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  text-align: center;
  text-shadow: ${({ $titleShadow }) =>
    $titleShadow ? $titleShadow : "0px 2px 2px rgba(0, 0, 0, 0.15)"};
  margin-top: 8px;

  @media (${device.md}) {
    font-size: 25px;
  }
  @media (${device.lg}) {
    margin-top: 0;
    font-size: 26px;
  }
  @media (${device.xl}) {
    margin-bottom: 10px;
    font-size: 32px;
  }
`;

const BackgroundTrapezoidWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  filter: ${({ $shadow }) =>
    $shadow ? "drop-shadow(0px 12px 8px rgba(64, 86, 205, 0.30))" : ""};

  opacity: 0;
  @media (${device.lg}) {
    opacity: 1;
  }
`;

const BackgroundTrapezoid = styled(TrapezoidBox)`
  height: 30px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  transition: background 0.6s ease;

  @media (${device.md}) {
    width: 70%;
  }

  @media (${device.xl}) {
    width: 55%;
  }

  // shift down
  ::before,
  ::after {
    top: -1px;
  }
`;

const ImgWrap = styled.img`
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  max-width: 121px;
  top: -75px;
`;

export function SectionCardAlt({
  title,
  children,
  border = true,
  titleBackground = "linear-gradient(180deg, #4CADF1 0%, #4C73F4 100%)",
  background = "linear-gradient(180deg, #5252f2 0%, #3131b0 100%)",
  trapezoidColor = "linear-gradient(180deg, #1784DB 0%, #2953DB 100%)",
  borderColor = "blue200",
  shadow = false,
  titleShadow = false,
  img,
  renderBanner = null,
  ...rest
}) {
  return (
    <SectionCardWrap
      $color={background}
      $borderColor={borderColor}
      $border={border}
      $shadow={shadow}
      {...rest}
    >
      {renderBanner && renderBanner()}
      {img && <ImgWrap src={img} />}
      <SectionTitleWrap
        $shadow={shadow}
        $border={border}
        $color={titleBackground}
        className="title-wrap"
      >
        <SectionTitle $border={border} $titleShadow={titleShadow}>
          {title}
        </SectionTitle>
      </SectionTitleWrap>
      {border && (
        <BackgroundTrapezoidWrap $shadow={shadow}>
          <BackgroundTrapezoid $color={trapezoidColor} />
        </BackgroundTrapezoidWrap>
      )}
      <SectionCardBody>{children}</SectionCardBody>
    </SectionCardWrap>
  );
}
