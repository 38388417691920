import React from "react";
import styled from "styled-components";
import { Container } from "../base/Container";
import { SectionWrap as Section } from "./Hero";
import { Typography } from "../base/Typography";
import { device } from "../../theme/mediaQuery";
import Spinner from "../base/Spinner";
import { FORM } from "../../consts/externalLinks";

const SectionWrap = styled(Section)`
  padding: 36px 0 55px;
  @media (${device.md}) {
    padding: 40px 0 80px;
  }

  @media (${device.xl}) {
    padding: 80px 0 150px;
  }
`;

const HeadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  text-align: center;
  margin-bottom: 32px;
  @media (${device.xl}) {
    margin-bottom: 60px;
  }
`;

const LicenseeWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-items: stretch;
  gap: 24px 32px;
  @media (${device.lg}) {
    flex-direction: row;
  }
`;

const LicenseeSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Form = styled.iframe`
  background: #270e60;
  padding: 6px 0;
  border-radius: 20px;
  width: 100%;
  height: 2685px;
  @media (min-width: 350px) {
    height: 2555px;
  }
  @media (min-width: 380px) {
    height: 2455px;
  }
  @media (min-width: 400px) {
    height: 2435px;
  }
  @media (min-width: 450px) {
    height: 2400px;
  }
  @media (${device.sm}) {
    height: 2385px;
  }
  @media (${device.md}) {
    height: 2285px;
  }
  @media (${device.xl}) {
    height: 2285px;
  }
`;

const SpinnerWrap = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Licensee = () => {
  return (
    <Container>
      <SectionWrap>
        <HeadingWrap>
          <Typography
            tag={"h2"}
            variant={"displaySm"}
            lg={{ variant: "displayLg" }}
            xxl={{ variant: "displayXl" }}
            textshadow="hard"
          >
            Contact Us
          </Typography>
          <Typography
            tag={"p"}
            variant={"textMd"}
            weight={600}
            lg={{ variant: "textXl" }}
          >
            For more information on partnering your business with Laguna Games,
            please complete the form below.
          </Typography>
        </HeadingWrap>
        <LicenseeWrap>
          <LicenseeSection>
            <Form src={FORM.LICENSING}>
              <SpinnerWrap>
                <Spinner color="white" />
              </SpinnerWrap>
            </Form>
          </LicenseeSection>
        </LicenseeWrap>
      </SectionWrap>
    </Container>
  );
};

export default Licensee;

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ $isLarge }) => ($isLarge ? "16px" : "4px")};
  & > * {
    line-height: 100%;
  }
`;

export const Title = ({ title, subtitle, size = "lg" }) => {
  const isLarge = size === "lg";
  const titleSize = {
    sm: isLarge ? "textXl" : "textMd",
    lg: isLarge ? "displayXs" : "textLg",
    xl: isLarge ? "displaySm" : "textXl",
  };
  const subtitleSize = {
    sm: isLarge ? "textLg" : "textMd",
    lg: isLarge ? "textXl" : "textLg",
  };
  return (
    <TitleWrap $isLarge={isLarge}>
      <Typography
        tag={"h3"}
        variant={titleSize.sm}
        lg={{ variant: titleSize.lg }}
        xl={{ variant: titleSize.xl }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          tag={"p"}
          weight={600}
          variant={subtitleSize.sm}
          lg={{ variant: subtitleSize.lg }}
        >
          {subtitle}
        </Typography>
      )}
    </TitleWrap>
  );
};
