import React from "react";
import styled from "styled-components";
import { useAtom } from "jotai";
import { trimmedCurrentWalletAddress } from "../../store";
import { Typography } from "../base/Typography";
import { device } from "../../theme/mediaQuery";

const HeadingWrap = styled.div`
  @media (${device.xl}) {
    padding-top: 7px;
  }
`;

// const HorizontalLine = styled.div`
//   position: absolute;
//   left: 0;
//   right: 0;
//   background: linear-gradient(180deg, #e7b610 0%, #d77f01 100%);
//   height: 8px;
//   margin-top: 20px;

//   @media (${device.md}) {
//     margin-top: 24px;
//   }

//   @media (${device.lg}) {
//     height: 12px;
//     margin-top: 36px;
//   }

//   @media (${device.xl}) {
//     margin-top: 40px;
//   }

//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: url(${({ theme }) => theme.pattern.sprinkles}) 0% 0% / 70%
//       repeat;
//     opacity: 0.08;
//   }
// `;

export const Heading = () => {
  const walletAddress = useAtom(trimmedCurrentWalletAddress);
  const title =
    walletAddress && walletAddress[0] ? `${walletAddress[0]}'s ` : "";

  return (
    <>
      <HeadingWrap>
        <Typography
          tag={"p"}
          family={"display"}
          variant="displayXs"
          lg={{
            variant: "displaySm",
          }}
          xl={{
            variant: "displayMd",
          }}
          className="text-center"
        >
          {title}Rewards
        </Typography>
        {/* <HorizontalLine /> */}
      </HeadingWrap>
    </>
  );
};
