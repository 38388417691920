import { useRef, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { device } from "../../theme/mediaQuery";
import { StakingPaginatedList } from "../staking/StakingPaginatedList";
import { StakingRewardsClaimEscrowedButton } from "../staking/rewards/StakingRewardsEscrowedButton";
import { FormattedAmount } from "../common/FormattedAmount";
import {
  StakingRewardTokenAvatar,
  StakingRewardsBox,
} from "../staking/rewards/StakingRewardsCard";
import { useGetTokenUSDPrice } from "../../query/vault";
import { formatStringWithFixedFraction } from "../Helpers";
import { ethers } from "ethers";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 865px;
  @media (${device.sm}) {
    height: 730px;
  }
  @media (${device.xl}) {
    height: 565px;
  }
`;

const TableHeadingWrap = styled.div`
  height: 90px;
  display: none;
  align-items: flex-end;
  padding: 0 20px 12px;
  gap: 20px;
  background: #f3f3ff;
  white-space: nowrap;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  @media (${device.xl}) {
    padding: 0 50px 12px;
    text-align: center;
    display: flex;
    gap: 35px;
  }
`;

const TableHeadingMobileWrap = styled(TableHeadingWrap)`
  display: flex;

  @media (${device.xl}) {
    display: none;
  }
`;

export const TableHeading = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.display};
  color: #3334b9;
  flex: ${({ $flex }) => $flex || 1};
  text-align: ${({ $textAlign }) => $textAlign || "center"};
  @media (${device.xl}) {
    font-size: 20px;
  }
`;

const BodyWrap = styled.div`
  padding: 20px 8px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #eee8f4;
  margin-bottom: 25px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 100%;

  @media (${device.lg}) {
    padding: 20px 20px 0;
  }
`;

export const TableRowWrap = styled(StakingRewardsBox)`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  border-radius: 16px;
  gap: 20px;

  @media (${device.xl}) {
    align-items: center;
    flex-direction: row;
    min-height: 92px;
    padding: 0 30px;
    gap: 35px;
  }
`;

export const ButtonWrap = styled(StakingRewardsClaimEscrowedButton)`
  margin-top: auto;
  max-width: 352px;
  & * {
    min-height: 50px;
    font-size: 22px;
    @media (${device.xl}) {
      font-size: 25px;
    }
  }
`;

const appearKeyframes = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

export const TableCellWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  flex: 1;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
  line-height: 1;
  flex: ${({ $flex }) => $flex || 1};
  text-align: ${({ $textAlign }) => $textAlign || "center"};
  animation: ${appearKeyframes} 0.5s ease;

  @media (${device.sm}) {
    & button * {
      font-size: 14px;
    }
  }

  @media (${device.md}) {
    & button * {
      font-size: 25px;
    }

    & button {
      margin: 0;
    }
  }

  @media (${device.xxl}) {
    align-items: initial;
    & button * {
      white-space: nowrap;
    }
  }
`;

const TableCellMobileTitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
  text-shadow: 1px 4px 0px #0000004d;

  @media (${device.xl}) {
    display: none;
  }
`;

export function StakingRewardsTable({ items }) {
  const headingRef = useRef(null);
  const [pageSize, setPageSize] = useState(window.innerWidth < 1200 ? 2 : 4);

  const handleResize = () => {
    setPageSize(window.innerWidth < 1200 ? 2 : 4);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToHeading = () => {
    // we need to scroll after the state is updated
    // so we use setTimeout with 0 delay
    setTimeout(() => {
      headingRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return (
    <Wrap>
      <TableHeadingWrap ref={headingRef}>
        <TableHeading $flex={1.5}>Reward Type</TableHeading>
        <TableHeading>Amount Claimable</TableHeading>
        <TableHeading>Dollar value</TableHeading>
        <TableHeading $flex={2}>Action</TableHeading>
      </TableHeadingWrap>
      <TableHeadingMobileWrap>
        <TableHeading>escrowed rewards</TableHeading>
      </TableHeadingMobileWrap>
      <StakingPaginatedList
        WrapComponent={BodyWrap}
        onPageChange={scrollToHeading}
        items={items}
        pageSize={pageSize}
        renderRow={(reward, index) => (
          <TableRow
            key={index + reward.token + reward.amount + reward.end + "-row"}
            reward={reward}
          />
        )}
      />
    </Wrap>
  );
}

function TableCell({ token, children, title, ...rest }) {
  return (
    <TableCellWrap {...rest}>
      {title && <TableCellMobileTitle>{title}</TableCellMobileTitle>}
      {children}
    </TableCellWrap>
  );
}

function TableRow({ reward, ...rest }) {
  const { token, amount } = reward;
  const usdPriceQuery = useGetTokenUSDPrice(token);
  const usdPrice = usdPriceQuery.data;

  const amountInEth = ethers.utils.formatEther(amount);
  const amountInUsd = amountInEth * usdPrice;
  return (
    <TableRowWrap token={token} {...rest}>
      <TableCell $flex={1.5}>
        <StakingRewardTokenAvatar token={token} />
      </TableCell>
      <TableCell title="Amount">
        <FormattedAmount wei={amount} maxFractionDigits={4} />
      </TableCell>
      <TableCell title="Dollar value">
        {usdPrice
          ? `$${formatStringWithFixedFraction(amountInUsd, null, 4)}`
          : "-"}
      </TableCell>
      <TableCell $flex={2}>
        <ButtonWrap reward={reward} />
      </TableCell>
    </TableRowWrap>
  );
}
