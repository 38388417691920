import { useState, useEffect } from "react";
import { useConnectModal } from "thirdweb/react";
import styled from "styled-components";
import { useAtomValue } from "jotai";
import { Container } from "../components/base/Container";
import { Box } from "../components/base/Box";
import { Typography } from "../components/base/Typography";
import { SectionCard } from "../components/common/SectionCard";
import { device } from "../theme/mediaQuery";
import { Tabs } from "../components/base/Tabs";
import useDocumentTitle from "../helpers/useDocumentTitle";
import { currentWalletAddressAtom } from "../store";
import { Button } from "../components/base/Button";
import { EVENT_KEYS } from "../consts/googleEvents";
import { googleAnalyticsEvent } from "../helpers/googleAnalytics";
import { LeaderboardUnicornPartyTable } from "../components/leaderboard/LeaderboardUnicornParty.js";
import { LeaderboardStakingTable } from "../components/leaderboard/LeaderboardStaking.js";
import { LeaderboardAirdropTable } from "../components/leaderboard/LeaderboardAirdrop.js";
import { LeaderboardUnicornBattleTable } from "../components/leaderboard/LeaderboardUnicornBattle.js";
import { useLocation } from "react-router-dom";
import { connectWallet } from "../components/ConnectWallet/connectWallet.js";
// import { LeaderboardRainbowRumbleV2Table } from "../components/leaderboard/LeaderboardRainbowRumbleV2.js";

const SectionContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 60px;
  min-height: 700px;

  & .container {
    padding: ${({ $noPadding }) => $noPadding && "0"};
  }
`;

const TabsWrap = styled(Tabs)`
  flex-direction: column;
  align-items: center;
  padding: 12px 21px 0;

  @media (${device.md}) {
    padding: 0 30px;
  }
  @media (${device.lg}) {
    padding: 0 50px;
  }
  @media (${device.xl}) {
    flex-direction: row;
    align-items: unset;
  }

  & > li {
    white-space: nowrap;
  }
`;

const TABS = [
  { title: "Play to Airdrop", key: "airdrop", hideConnectWallet: true },
  // { title: "Rainbow Rumble", key: "rainbow-rumble" },
  { title: "Prepare for war", key: "prepare-for-war" },
  { title: "Unicorn Bingo", key: "bingo", hideConnectWallet: true },
  { title: "Staking", key: "staking" },
];

const shadowForgeTheme = {
  card: {
    titleBackground: "linear-gradient(180deg, #C262EB 0%, #4B3165 100%)",
    background:
      "linear-gradient(0deg, rgba(18, 6, 17, 0.9) 0%, rgba(18, 6, 17, 0.68) 100%), rgba(255, 255, 255, 0.44)",
    trapezoidColor: "linear-gradient(180deg, #714189 0%, #41255C 100%)",
    borderColor: "#9C53BB",
  },
  tabs: {
    color: "#E1B8FF",
    activeTextColor: "white",
    background: "#131222",
  },
  connectWallet: {
    gradient: "linear-gradient(180deg, #C262EB 0%, #4B3165 100%)",
    variant: "shadowcorn",
  },
};

const theme = {
  "shadow-forge": {
    ...shadowForgeTheme,
  },
  "twilight-tactics": {
    ...shadowForgeTheme,
  },
  "tug-of-war": {
    ...shadowForgeTheme,
  },
  staking: {
    card: {
      titleBackground: "linear-gradient(180deg, #E276E9 0%, #E08CDB 100%)",
      background: "linear-gradient(180deg, #6834AA 0%, #E28EDC 100%)",
      trapezoidColor: "linear-gradient(180deg, #E297DF 0%, #AD67C6 100%)",
      borderColor: "#E276E9",
    },
    tabs: {
      activeTextColor: "#E276E9",
    },
    connectWallet: {
      gradient: "rgba(255, 255, 255, 0.10)",
    },
  },
  airdrop: {
    card: {
      titleBackground: "linear-gradient(180deg, #6EB0ED 0%, #614CD7 100%)",
      background: "linear-gradient(180deg, #4860C7 0%, #634FD4 100%)",
      trapezoidColor: "linear-gradient(180deg, #6EB0ED 0%, #614CD7 100%)",
      borderColor: "#6377CF",
    },
    tabs: {
      activeTextColor: "#2F4398",
    },
    connectWallet: {
      gradient: "rgba(255, 255, 255, 0.10)",
    },
  },
  "prepare-for-war": {
    card: {
      titleBackground: "linear-gradient(180deg, #B90000 0%, #980000 100%)",
      background: "#F06957",
      trapezoidColor: "linear-gradient(180deg, #F64129 0%, #DE1E07 100%)",
      borderColor: "#F55300",
    },
    tabs: {
      activeTextColor: "#AB112B",
    },
    connectWallet: {
      gradient: "linear-gradient(180deg, #F64129 0%, #DE1E07 100%)",
    },
  },
  "rainbow-rumble": {
    card: {
      titleBackground: "#7931B1",
      background: "linear-gradient(180deg, #2B015B 0%, #3C1568 100%)",
      trapezoidColor: "linear-gradient(180deg, #7931B1 10%, #661574 100%)",
      borderColor: "#7931B1",
    },
    tabs: {
      activeTextColor: "#661574",
    },
    connectWallet: {
      gradient: "linear-gradient(180deg, #2B015B 0%, #3C1568 100%)",
    },
  },
};

export function LeaderboardPage() {
  useDocumentTitle("Leaderboards");
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const location = useLocation();
  const [tabs] = useState(TABS);
  const [tab, setTab] = useState(TABS[0].key);

  const cardTheme = theme[tab] ? theme[tab].card : {};
  const tabTheme = theme[tab] ? theme[tab].tabs : {};
  const connectWalletTheme = theme[tab] ? theme[tab].connectWallet : {};

  const searchParams = new URLSearchParams(location.search);
  const eventParam = searchParams.get("event");

  const currentTab = TABS.find((t) => t.key === tab);

  useEffect(() => {
    const tabKeys = TABS.map((tabObject) => tabObject.key);
    const tabExists = tabKeys.includes(eventParam);

    if (tabExists) {
      setTab(eventParam);
    }
  }, [eventParam]);

  return (
    <Container className="mb-5">
      <SectionCard title="Leaderboards" {...cardTheme}>
        <SectionContentWrap $noPadding={tab === "airdrop"}>
          <TabsWrap tabs={tabs} active={tab} onChange={setTab} {...tabTheme} />
          {!walletAddress && !currentTab?.hideConnectWallet && (
            <ConnectWallet {...connectWalletTheme} />
          )}
          {tab === "prepare-for-war" && (
            <LeaderboardUnicornBattleTable includeSummary={true} />
          )}
          {/* {tab === "rainbow-rumble" && (
            <LeaderboardRainbowRumbleV2Table includeSummary={true} />
          )} */}
          {tab === "airdrop" && (
            <LeaderboardAirdropTable includeSummary={true} />
          )}
          {tab === "staking" && (
            <LeaderboardStakingTable includeSummary={true} />
          )}
          {tab === "bingo" && (
            <LeaderboardUnicornPartyTable includeSummary={true} />
          )}
        </SectionContentWrap>
      </SectionCard>
    </Container>
  );
}

const ConnectWalletWrap = styled(Box)`
  border-radius: 16px !important;
  padding: 12px;
  @media (${device.md}) {
    padding: 32px 24px;
  }
`;

export const ConnectWallet = ({
  gradient = "linearBluePrimary",
  variant = "primary",
}) => {
  const { connect } = useConnectModal();

  const connectUser = () => {
    connectWallet(connect);
    googleAnalyticsEvent(EVENT_KEYS.CONNECT_CLICKED);
  };

  return (
    <ConnectWalletWrap
      variant="gradient"
      rounded="sm"
      gradient={gradient}
      className="text-center"
    >
      <Typography
        color="white"
        tag="p"
        weight={600}
        family="body"
        variant="textMd"
        className="mb-3"
        md={{ variant: "textXl" }}
      >
        Want to see where you are on the Leaderboards compared to other players?
      </Typography>

      <Button variant={variant} onClick={connectUser}>
        Connect Wallet
      </Button>
    </ConnectWalletWrap>
  );
};
