import React from "react";
import styled from "styled-components";
import { Typography } from "../base/Typography";
import { Container } from "../base/Container";
import { device } from "../../theme/mediaQuery";
import { SectionWrap as Section } from "./Hero";
import { Carousel } from "./Carousel";

const SectionWrap = styled(Section)``;

const HeadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  text-align: center;
  margin-bottom: 32px;
  @media (${device.xl}) {
    margin-bottom: 51px;
  }
`;

const UNICORNS = [
  {
    name: "crystal",
    description: "Unicorn",
  },
  {
    name: "heart",
    description: "Unicorn",
  },
  {
    name: "star",
    description: "Unicorn",
  },
  {
    name: "rainbow",
    description: "Unicorn",
  },
  {
    name: "omnom",
    description: "Unicorn",
  },
  {
    name: "cloud",
    description: "Unicorn",
  },
  {
    name: "candy",
    description: "Unicorn",
  },
  {
    name: "moon",
    description: "Unicorn",
  },
  {
    name: "flower",
    description: "Unicorn",
  },
];

export function UnicornCharacters() {
  return (
    <Container>
      <SectionWrap>
        <HeadingWrap>
          <Typography
            tag={"h2"}
            variant={"displaySm"}
            lg={{ variant: "displayLg" }}
            xxl={{ variant: "displayXl" }}
            textshadow="hard"
          >
            From egg to adulthood, discover 9 different Unicorn classes
          </Typography>
        </HeadingWrap>
        <Carousel characters={UNICORNS} />
      </SectionWrap>
    </Container>
  );
}
