import { useState, useEffect } from "react";
import { size } from "../theme/mediaQuery";

const isMobileScreen = () => window.innerWidth < size.sm;
export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(isMobileScreen());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileScreen());
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
}
