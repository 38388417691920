import React from "react";
import styled from "styled-components";
import { Box } from "../base/Box";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { Typography } from "../base/Typography";
import { getAttribute } from "../Helpers";
import { FilterIcon } from "../common/FilterIcon";
import { device } from "../../theme/mediaQuery";
import { Modal } from "../base/Modal";

const ImgWrap = styled.img`
  border-radius: 8px;
  object-fit: cover;
  max-width: 100%;
  max-height: 700px;

  @media (${device.xl}) {
    max-width: 35%;
    max-height: fit-content;
  }
`;

const StatsWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledStatHighlights = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 1rem;
  margin: 0.5rem 0;

  @media (${device.lg}) {
    flex-direction: row;
    justify-content: center;
    column-gap: 1.275rem;
  }
`;

const StyledStatBox = styled(Box)`
  width: 100%;
  @media (${device.lg}) {
    width: 49%;
  }
`;

const StatRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.darkBlue300};
  border-bottom: ${({ $isHeading }) => $isHeading && "1px solid #DCE7F2"};
  column-gap: 0.5rem;
  padding: 0.3rem;

  @media (${device.lg}) {
    padding: 1rem;
    column-gap: 1.25rem;
  }
`;

const StatIcon = styled(FilterIcon)`
  width: 24px;
  height: 24px;
`;

const StatsHighlightWrap = styled(Box)`
  width: 100%;

  @media (${device.lg}) {
    width: 50%;
  }
`;

export function UnicornModal({ showModal, setShowModal, unicorn }) {
  const unicornClass = unicorn && getAttribute("Class", unicorn).toLowerCase();
  const breedPoints = unicorn && getAttribute("Breeding Points", unicorn);
  const mythic = unicorn && getAttribute("Mythic", unicorn);

  const stats = unicorn && [
    [
      {
        icon: "power",
        label: "Power Score",
        score:
          getAttribute("Attack", unicorn) + getAttribute("Accuracy", unicorn),
      },
      {
        icon: "attack",
        label: "Attack",
        score: getAttribute("Attack", unicorn),
      },
      {
        icon: "accuracy",
        label: "Accuracy",
        score: getAttribute("Accuracy", unicorn),
      },
    ],
    [
      {
        icon: "endurance",
        label: "Endurance Score",
        score:
          getAttribute("Defense", unicorn) + getAttribute("Vitality", unicorn),
      },
      {
        icon: "defense",
        label: "Defense",
        score: getAttribute("Defense", unicorn),
      },
      {
        icon: "vitality",
        label: "Vitality",
        score: getAttribute("Vitality", unicorn),
      },
    ],
    [
      {
        icon: "speed",
        label: "Speed Score",
        score:
          getAttribute("Movement Speed", unicorn) +
          getAttribute("Attack Speed", unicorn),
      },
      {
        icon: "movement",
        label: "Movement Speed",
        score: getAttribute("Movement Speed", unicorn),
      },
      {
        icon: "attack",
        label: "Attack Speed",
        score: getAttribute("Attack Speed", unicorn),
      },
    ],
    [
      {
        icon: "intelligence",
        label: "Intelligence Score",
        score:
          getAttribute("Magic", unicorn) + getAttribute("Resistance", unicorn),
      },
      {
        icon: "magic",
        label: "Magic",
        score: getAttribute("Magic", unicorn),
      },
      {
        icon: "resistance",
        label: "Resistance",
        score: getAttribute("Resistance", unicorn),
      },
    ],
  ];
  const overallStat =
    stats && stats.reduce((sum, statGroup) => sum + statGroup[0].score, 0);

  return (
    <>
      {unicorn && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          title={unicorn?.name}
          subtitle={
            unicorn.token_id
              ? `${unicornClass} - Unicorn ID: ${unicorn.token_id}`
              : `${unicornClass}`
          }
          classIcon={unicornClass}
        >
          <>
            <ImgWrap src={unicorn.image} />
            <Box
              variant="flat"
              rounded="md"
              color="darkBlue"
              className="col ms-xl-4"
              pattern="sprinkles"
            >
              <StatsWrap>
                <Box
                  variant="gradient"
                  rounded="sm"
                  gradient="linearBlack"
                  pattern="sprinkles"
                  patternSize="sm"
                  className="py-3 px-4"
                >
                  <Typography tag={"h3"} variant={"textXl"} color="white">
                    Stats Summary
                  </Typography>
                </Box>
                <div
                  style={{
                    background: "#e4f3fb",
                    borderBottomRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                  }}
                  className="p-2 px-3"
                >
                  <Row>
                    <StyledStatHighlights>
                      <StatsHighlight
                        label="Overall stats"
                        value={overallStat}
                        gradient="linear-gradient(143.22deg, #F46652 7.24%, #FD6041 94.73%)"
                      />
                      {mythic ? (
                        <StatsHighlight
                          // label=""
                          value={mythic}
                          gradient="linear-gradient(143.22deg, #14DBBD 7.24%, #00B8A8 94.73%);)"
                        />
                      ) : null}
                      {breedPoints ? (
                        <StatsHighlight
                          label="Breed points"
                          value={`${breedPoints} of 8`}
                          gradient="linear-gradient(143.22deg, #FB60C7 7.24%, #FF2BB0 94.73%)"
                        />
                      ) : null}
                    </StyledStatHighlights>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-wrap justify-content-between">
                      {stats.map((stat, i) => (
                        <StatsCard
                          data={stat}
                          id={unicorn.token_id}
                          key={`stat-card-unicorn.token_i-${i}`}
                        />
                      ))}
                    </Col>
                  </Row>
                </div>
              </StatsWrap>
            </Box>
          </>
        </Modal>
      )}
    </>
  );
}

const StatsHighlight = ({ label, value, gradient }) => {
  return (
    <StatsHighlightWrap
      variant="gradient"
      rounded="sm"
      gradient={gradient}
      pattern="sprinkles"
      patternSize="lg"
      className={`p-3 col d-flex ${
        label ? "justify-content-between" : "justify-content-center"
      }`}
    >
      {label && (
        <Typography
          tag={"h4"}
          variant={"textLg"}
          color="white"
          className="d-inline"
        >
          {label}
        </Typography>
      )}
      <Typography
        tag={"h4"}
        variant={"textLg"}
        color="white"
        className="d-inline"
      >
        {value}
      </Typography>
    </StatsHighlightWrap>
  );
};

const StatsCard = ({ data, id }) => {
  return (
    <StyledStatBox
      variant="flat"
      rounded="sm"
      color="white"
      className="my-2 p-2"
    >
      {data.map((stat, i) => (
        <StatRow key={`${id}-${stat.label}`} $isHeading={i === 0}>
          <StatIcon name={stat.icon} />
          <Typography
            tag={"h3"}
            variant={"textLg"}
            color="darkBlue300"
            className="me-auto"
          >
            {stat.label}
          </Typography>
          <Typography tag={"h3"} variant={"textLg"} color="darkBlue300">
            {stat.score}
          </Typography>
        </StatRow>
      ))}
    </StyledStatBox>
  );
};
