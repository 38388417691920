import {
  FIRE_HUSK_POOL_ID,
  FIRE_LOOTBOX_POOL_ID,
  FIRE_MATERIAL_POOL_ID,
  FIRE_MINION_POOL_ID,
  FIRE_MINION_TIER2_POOL_ID,
  FIRE_MINION_TIER3_POOL_ID,
  GENERIC_MATERIAL_POOL_ID,
  HATCHERY_KEY_POOL_ID,
  NEBULA_HUSK_POOL_ID,
  NEBULA_LOOTBOX_POOL_ID,
  NEBULA_MATERIAL_POOL_ID,
  NEBULA_MINION_POOL_ID,
  NEBULA_MINION_TIER2_POOL_ID,
  NEBULA_MINION_TIER3_POOL_ID,
  RIFT_CRYSTAL_POOL_ID,
  SLIME_HUSK_POOL_ID,
  SLIME_LOOTBOX_POOL_ID,
  SLIME_MATERIAL_POOL_ID,
  SLIME_MINION_POOL_ID,
  SLIME_MINION_TIER2_POOL_ID,
  SLIME_MINION_TIER3_POOL_ID,
  SOUL_HUSK_POOL_ID,
  SOUL_LOOTBOX_POOL_ID,
  SOUL_MATERIAL_POOL_ID,
  SOUL_MINION_POOL_ID,
  SOUL_MINION_TIER2_POOL_ID,
  SOUL_MINION_TIER3_POOL_ID,
  UNICORN_SOUL_POOL_ID,
  VOLT_HUSK_POOL_ID,
  VOLT_LOOTBOX_POOL_ID,
  VOLT_MATERIAL_POOL_ID,
  VOLT_MINION_POOL_ID,
  VOLT_MINION_TIER2_POOL_ID,
  VOLT_MINION_TIER3_POOL_ID,
} from "../consts/tokenPools";
import { TokenNamesMap } from "../consts/tokenNames";
import { NetworkConfiguration } from "../components/Network";

export const AssetTokenType = {
  husk: "husk",
  genericMaterial: "genericMaterial",
  minion: "minion",
  currency: "currency",
  hatcheryKey: "hatcheryKey",
  darkMark: "darkMark",
  lootbox: "lootbox",
  material: "material",
  unicornSoul: "unicorn_soul",
  unim: "unim",
  riftCrystal: "rift_crystal",
};

export const AssetRitualType = {
  minion: "minion",
  ingredient: "ingredient",
};

const POOL_ID_MAP = {
  [FIRE_MINION_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Fire",
    tier: 1,
  },
  [SLIME_MINION_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Slime",
    tier: 1,
  },
  [VOLT_MINION_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Volt",
    tier: 1,
  },
  [SOUL_MINION_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Soul",
    tier: 1,
  },
  [NEBULA_MINION_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Nebula",
    tier: 1,
  },
  [FIRE_HUSK_POOL_ID]: { type: AssetTokenType.husk, class: "Fire" },
  [SLIME_HUSK_POOL_ID]: { type: AssetTokenType.husk, class: "Slime" },
  [VOLT_HUSK_POOL_ID]: { type: AssetTokenType.husk, class: "Volt" },
  [SOUL_HUSK_POOL_ID]: { type: AssetTokenType.husk, class: "Soul" },
  [NEBULA_HUSK_POOL_ID]: { type: AssetTokenType.husk, class: "Nebula" },
  [FIRE_MATERIAL_POOL_ID]: { type: AssetTokenType.material, class: "Fire" },
  [SLIME_MATERIAL_POOL_ID]: { type: AssetTokenType.material, class: "Slime" },
  [VOLT_MATERIAL_POOL_ID]: { type: AssetTokenType.material, class: "Volt" },
  [SOUL_MATERIAL_POOL_ID]: { type: AssetTokenType.material, class: "Soul" },
  [NEBULA_MATERIAL_POOL_ID]: { type: AssetTokenType.material, class: "Nebula" },
  [FIRE_MINION_TIER2_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Fire",
    tier: 2,
  },
  [SLIME_MINION_TIER2_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Slime",
    tier: 2,
  },
  [VOLT_MINION_TIER2_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Volt",
    tier: 2,
  },
  [SOUL_MINION_TIER2_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Soul",
    tier: 2,
  },
  [NEBULA_MINION_TIER2_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Nebula",
    tier: 2,
  },
  [FIRE_MINION_TIER3_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Fire",
    tier: 3,
  },
  [SLIME_MINION_TIER3_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Slime",
    tier: 3,
  },
  [VOLT_MINION_TIER3_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Volt",
    tier: 3,
  },
  [SOUL_MINION_TIER3_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Soul",
    tier: 3,
  },
  [NEBULA_MINION_TIER3_POOL_ID]: {
    type: AssetTokenType.minion,
    class: "Nebula",
    tier: 3,
  },
};

const LOOTBOX_POOL_ID_MAP = {
  [FIRE_LOOTBOX_POOL_ID]: { type: AssetTokenType.lootbox, class: "Fire" },
  [SLIME_LOOTBOX_POOL_ID]: { type: AssetTokenType.lootbox, class: "Slime" },
  [VOLT_LOOTBOX_POOL_ID]: { type: AssetTokenType.lootbox, class: "Volt" },
  [SOUL_LOOTBOX_POOL_ID]: { type: AssetTokenType.lootbox, class: "Soul" },
  [NEBULA_LOOTBOX_POOL_ID]: { type: AssetTokenType.lootbox, class: "Nebula" },
};

export const AssetMap = {
  UNIM: "UNIM",
  darkMark: "darkMark",
  rbw: "rbw",
  item: "item",
  unknown: "unknown",
  unicornItem: "unicornItem",
};

export const AssetAddress = {
  [NetworkConfiguration.unimAddress?.toLowerCase()]: AssetMap.UNIM,
  [NetworkConfiguration.darkMarksAddress?.toLowerCase()]: AssetMap.darkMark,
  [NetworkConfiguration.rbwcAddress?.toLowerCase()]: AssetMap.rbw,
  [NetworkConfiguration.itemsAddress?.toLowerCase()]: AssetMap.item,
  [NetworkConfiguration.unicornItemsAddress?.toLowerCase()]:
    AssetMap.unicornItem,
};

// asset model to handle costs, products and other related data from BC
export class AssetModel {
  constructor(assetAddress, assetType, poolId) {
    this.asset = AssetAddress[assetAddress?.toLowerCase()];
    this.assetType = assetType;
    this.poolId = poolId;
    this.assetAddress = assetAddress;
  }

  get class() {
    const poolId = this.poolId;
    const tokenType = this.tokenType;
    if (tokenType === AssetTokenType.lootbox) {
      if (poolId in LOOTBOX_POOL_ID_MAP) {
        const { class: poolClass } = LOOTBOX_POOL_ID_MAP[poolId];
        return poolClass;
      } else {
        return null;
      }
    }

    if (poolId in POOL_ID_MAP) {
      const { class: poolClass } = POOL_ID_MAP[poolId];
      return poolClass;
    } else {
      return null;
    }
  }

  get tier() {
    const poolId = this.poolId;
    const tokenType = this.tokenType;
    if (tokenType === AssetTokenType.minion) {
      if (poolId in POOL_ID_MAP) {
        const { tier: poolTier } = POOL_ID_MAP[poolId];
        return poolTier;
      } else {
        return null;
      }
    }

    return null;
  }

  get tokenType() {
    const poolId = this.poolId;

    if (this.asset === AssetMap.unicornItem) {
      return AssetTokenType.lootbox;
    }

    if (this.asset === AssetMap.darkMark) {
      return AssetTokenType.darkMark;
    }

    if (this.asset === AssetMap.UNIM) {
      return AssetTokenType.unim;
    }

    if (parseInt(poolId) === GENERIC_MATERIAL_POOL_ID) {
      return AssetTokenType.genericMaterial;
    }

    if (parseInt(poolId) === HATCHERY_KEY_POOL_ID) {
      return AssetTokenType.hatcheryKey;
    }

    if (parseInt(poolId) === UNICORN_SOUL_POOL_ID) {
      return AssetTokenType.unicornSoul;
    }

    if (parseInt(poolId) === RIFT_CRYSTAL_POOL_ID) {
      return AssetTokenType.riftCrystal;
    }

    if (poolId in POOL_ID_MAP) {
      const { type: poolType } = POOL_ID_MAP[poolId];
      return poolType;
    } else {
      return null;
    }
  }

  get ritualType() {
    const poolId = this.poolId;

    if (
      this.asset === AssetMap.unicornItem ||
      this.asset === AssetMap.darkMark ||
      this.asset === AssetMap.UNIM ||
      parseInt(poolId) === GENERIC_MATERIAL_POOL_ID ||
      parseInt(poolId) === HATCHERY_KEY_POOL_ID ||
      parseInt(poolId) === UNICORN_SOUL_POOL_ID ||
      parseInt(poolId) === RIFT_CRYSTAL_POOL_ID
    ) {
      return AssetRitualType.ingredient;
    }

    if (poolId in POOL_ID_MAP) {
      const { type: poolType } = POOL_ID_MAP[poolId];
      return poolType;
    } else {
      return null;
    }
  }

  get title() {
    const tokenType = this.tokenType;
    const tokenClass = this.class;
    if (tokenType === AssetTokenType.darkMark) {
      return "Dark Marks";
    }

    if (tokenType === AssetTokenType.unim) {
      return "UNIM";
    }

    if (tokenType === AssetTokenType.riftCrystal) {
      return "Rift Crystal";
    }

    const ingredientTypes = [
      AssetTokenType.husk,
      AssetTokenType.genericMaterial,
      AssetTokenType.minion,
      AssetTokenType.hatcheryKey,
      AssetTokenType.material,
      AssetTokenType.unicornSoul,
    ];

    if (ingredientTypes.includes(tokenType)) {
      return TokenNamesMap[this.poolId];
    }

    if (tokenType === AssetTokenType.lootbox) {
      return `${tokenClass} Lootbox`;
    }

    return tokenType;
  }

  get iconName() {
    const tokenType = this.tokenType;
    const tokenClass = this.class;
    const tier = this.tier;
    if (tokenType === AssetTokenType.darkMark) {
      return "darkMark";
    }

    if (tokenType === AssetTokenType.husk) {
      return `${tokenClass}_husk`;
    }

    if (tokenType === AssetTokenType.genericMaterial) {
      return `generic_material`;
    }

    if (tokenType === AssetTokenType.minion) {
      return `${tokenClass}_minion_t${tier}`;
    }

    if (tokenType === AssetTokenType.hatcheryKey) {
      return `hatchery_key`;
    }

    if (tokenType === AssetTokenType.lootbox) {
      return `${tokenClass}_lootbox`;
    }

    if (tokenType === AssetTokenType.material) {
      return `${tokenClass}_material`;
    }

    if (tokenType === AssetTokenType.unicornSoul) {
      return `unicorn_soul`;
    }

    if (tokenType === AssetTokenType.unim) {
      return `unim`;
    }

    if (tokenType === AssetTokenType.riftCrystal) {
      return `rift_crystal`;
    }

    return tokenType;
  }

  get iconType() {
    const tokenType = this.tokenType;

    const ingredientTypes = [
      AssetTokenType.husk,
      AssetTokenType.genericMaterial,
      AssetTokenType.minion,
      AssetTokenType.hatcheryKey,
      AssetTokenType.lootbox,
      AssetTokenType.material,
      AssetTokenType.unicornSoul,
      AssetTokenType.riftCrystal,
    ];

    if (
      tokenType === AssetTokenType.darkMark ||
      tokenType === AssetTokenType.unim
    ) {
      return "currency";
    }

    if (ingredientTypes.includes(tokenType)) {
      return "ingredient";
    }
    return null;
  }
}
