export class TokenAttribute {
  constructor(traitType, value, displayType, maxValue) {
    this.trait_type = traitType;
    this.value = value;
    this.display_type = displayType;
    this.max_value = maxValue;
  }
}

export class TokenModel {
  constructor(
    name,
    image,
    attributes,
    rawJson,
    tokenId
  ) {
    this.name = name;
    this.image = image;
    this.attributes = attributes;
    this.rawJson = rawJson;
    this.tokenId = tokenId;
  }

  get id() {
    const traitType = "Token ID";
    const attribute = this.getAttributeValue(traitType);
    return this.tokenId || attribute?.toString();
  }

  static fromJSON(json) {
    const tokenId = json["token_id"] || json["tokenId"] || json["poolId"];
    const attributes = json["attributes"]
      ? json["attributes"].map(
        item =>
          new TokenAttribute(
            item["trait_type"] || item["traitType"],
            item["value"],
            item["display_type"],
            item["max_value"]
          )
      )
      : [];

    return new TokenModel(
      json["name"],
      json["image"],
      attributes,
      json,
      tokenId
    );
  }

  getAttributeValue(name) {
    const attribute = this.attributes.find(
      item => item.traitType.toLowerCase() === name.toLowerCase()
    );

    return attribute?.value || null;
  }

  getAttributes(attributeNames) {
    const result = [];

    attributeNames.forEach(name => {
      result.push(this.getAttributeValue(name));
    });

    return result;
  }

  getAttribute(name) {
    return this.attributes.find(
      item => item.traitType.toLowerCase() === name.toLowerCase()
    );
  }
}
