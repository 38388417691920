import { Alchemy, Network } from "alchemy-sdk";
import { isTestnet, Network as NetworkConfig } from "../components/Network";

export class AlchemySdkError extends Error {
  constructor(message) {
    super(`${message}`);
  }
}

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_KEY,
  network: Network.MATIC_MAINNET,
};
const settingsArbitrum = {
  apiKey: process.env.REACT_APP_ALCHEMY_KEY_ARB,
  network: Network.ARB_MAINNET,
};

const instance = new Alchemy(settings);
const instanceArbitrum = new Alchemy(settingsArbitrum);

export const alchemy = {
  getUnicornIdsByWallet: async (address) => {
    try {
      let options = {
        contractAddresses: [NetworkConfig.CUContractAddress],
        omitMetadata: true,
      };
      let hasNextPage = true;
      let ids = [];

      while (hasNextPage) {
        const result = await instance.nft.getNftsForOwner(address, options);

        const tokens = result.ownedNfts;
        const tokenIds = tokens.map((token) => token.tokenId);
        ids = [...ids, ...tokenIds];
        // If there is a page key, set the page key to the next page key. Otherwise, exit the loop.
        if (!!result.pageKey) {
          options.pageKey = result.pageKey;
          hasNextPage = true;
        } else {
          hasNextPage = false;
        }
      }

      return ids;
    } catch (error) {
      throw new AlchemySdkError(error.message);
    }
  },
  getShadowcornsByWallet: async (address) => {
    try {
      const shadowcorns = [];

      const nftsIterable = instance.nft.getNftsForOwnerIterator(address, {
        contractAddresses: [NetworkConfig.ShadowcornContractAddress],
      });

      // Iterate over the NFTs and add them to the nfts array.
      for await (const nft of nftsIterable) {
        const rawMetadata = nft.raw?.metadata;
        if (rawMetadata.attributes.length > 0) {
          shadowcorns.push(rawMetadata);
        }
      }

      return shadowcorns;
    } catch (error) {
      throw new AlchemySdkError(error.message);
    }
  },
  getUnicornItemsByWallet: async (address) => {
    try {
      let options = {
        contractAddresses: [NetworkConfig.CUTerminusAddress],
      };
      const items = [];
      const result = await instance.nft.getNftsForOwnerIterator(
        address,
        options
      );

      for await (const nft of result) {
        const tokenId = parseInt(nft.tokenId);
        items.push({
          ...nft.raw?.metadata,
          count: nft.balance,
          poolId: tokenId,
        });
      }

      return items;
    } catch (error) {
      throw new AlchemySdkError(error.message);
    }
  },
  getUnicornItemsByWalletArb: async (address) => {
    try {
      let options = {
        contractAddresses: [NetworkConfig.Arbitrum.terminusContractAddress],
      };
      const items = [];
      const result = await instanceArbitrum.nft.getNftsForOwnerIterator(
        address,
        options
      );

      for await (const nft of result) {
        const tokenId = parseInt(nft.tokenId);
        items.push({
          ...nft.raw?.metadata,
          count: nft.balance,
          poolId: tokenId,
        });
      }

      return items;
    } catch (error) {
      throw new AlchemySdkError(error.message);
    }
  },
  getShadowcornItemsByWallet: async (address) => {
    try {
      let options = {
        contractAddresses: [NetworkConfig.ShadowcornItemsContractAddress],
      };
      const items = [];
      const result = instance.nft.getNftsForOwnerIterator(address, options);

      for await (const nft of result) {
        const tokenId = parseInt(nft.tokenId);
        items.push({
          ...nft.raw?.metadata,
          count: nft.balance,
          poolId: tokenId,
        });
      }

      return items;
    } catch (error) {
      throw new AlchemySdkError(error.message);
    }
  },
  getLandIdsByWallet: async (address) => {
    try {
      let options = {
        contractAddresses: [NetworkConfig.CULandAddress],
        omitMetadata: true,
      };
      let hasNextPage = true;
      let ids = [];

      while (hasNextPage) {
        const result = await instance.nft.getNftsForOwner(address, options);

        const tokens = result.ownedNfts;
        const tokenIds = tokens.map((token) => token.tokenId);
        ids = [...ids, ...tokenIds];
        // If there is a page key, set the page key to the next page key. Otherwise, exit the loop.
        if (!!result.pageKey) {
          options.pageKey = result.pageKey;
          hasNextPage = true;
        } else {
          hasNextPage = false;
        }
      }

      return ids;
    } catch (error) {
      throw new AlchemySdkError(error.message);
    }
  },
  getBadgesById: async (badgeIds) => {
    try {
      const options = (tokenId) => {
        return {
          contractAddress: NetworkConfig.Arbitrum.terminusContractAddress,
          tokenId: tokenId,
          tokenType: "ERC1155",
        };
      };
      const optionsArray = badgeIds.map(options);
      let result = await instanceArbitrum.nft.getNftMetadataBatch(optionsArray);
      let items = [];
      result.nfts.forEach((nft) => {
        items.push({
          ...nft.raw?.metadata,
          // count: nft.balance,
          poolId: nft.tokenId,
        });
      });
      return items;
    } catch (error) {
      throw new AlchemySdkError(error.message);
    }
  },
};
