import { createClient } from "@layerzerolabs/scan-client";
import { NetworkConfiguration } from "../Network";
import { POLYGON_NETWORK } from "../ConnectWallet/WalletHelper";

export async function pollMessagesForDelivery(txHash, wallet) {
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const client = createClient(
    netconfig.networkId === 137 ? "mainnet" : "testnet"
  );
  let polling = true;
  // let confirmations = 0;
  let initialBlock;
  let dstTxHash = null;

  while (polling) {
    const { messages } = await client.getMessagesBySrcTxHash(txHash);
    // const currentBlock = await wallet.getBlockNumber();
    // confirmations = currentBlock - initialBlock;

    // console.log(
    //   "messages",
    //   messages,
    //   "currentBlock",
    //   currentBlock,
    //   "confirmations",
    //   confirmations
    // );

    // looks like it returns an array but this might need to be changed
    for (const message of messages) {
      if (message.status === "DELIVERED") {
        initialBlock = message.srcBlockNumber;
        polling = false;
        dstTxHash = message.dstTxHash;
        break;
      }
    }

    if (polling) {
      await new Promise((resolve) => setTimeout(resolve, 4000));
    }
  }
  return dstTxHash;
}
