import { Container } from "../components/base/Container";
import { SectionCard } from "../components/common/SectionCard";
import styled from "styled-components";
import { device } from "../theme/mediaQuery";
import useDocumentTitle from "../helpers/useDocumentTitle";
import { CRYPTO } from "../consts/externalLinks";

const ContentWrap = styled.div`
  padding: 0 20px;
  margin: 0 auto;
  color: ${({ theme }) => theme.palette.white};
  padding-bottom: 50px;

  & a {
    color: ${({ theme }) => theme.palette.white};
  }

  p,
  li {
    font-size: 16px;
    padding: 10px 0;
    font-weight: 600;
  }

  & .c2 {
    font-size: 23px;
    font-weight: 700;
  }

  @media (${device.md}) {
    p,
    li {
      font-size: 21px;
    }

    & .c2 {
      font-size: 32px;
    }
  }
`;

export function TermsPage() {
  useDocumentTitle("Terms of Service");

  return (
    <Container className="pb-5">
      <SectionCard title="Terms of Service">
        <ContentWrap>
          <p className="c1">
            <span className="c0">Last updated: May 21, 2024 </span>
          </p>
          <p className="c1">
            <span>
              These Terms of Service constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity (“you")
              and The Crypto Unicorns DAO, a Nevada Unincorporated Nonprofit
              Association,(“The Crypto Unicorns UNA”, “the UNA”, “we", “us", or
              “our") concerning your access to and use of the{" "}
            </span>
            <span className="c3 c7">
              <a href={CRYPTO.UNICORNS}>www.cryptounicorns.fun</a>
            </span>
            <span>
              {" "}
              website and the Crypto Unicorns app as well as any other media
              form, media channel, mobile website or mobile application related,
              linked, or otherwise connected thereto (collectively, the “Site"
              and the “App"). Crypto Unicorns is a distributed application that
              is currently running on the Arbitrum Network, and/or other
              EVM-compatible blockchains (collectively, the “Blockchains”),
              using specially-developed smart contracts (each, a “Smart
              Contract”) to enable users to own, transfer, race, battle, and
              breed genetically unique digital Unicorns. It also enables users
              to own and transfer other digital assets like plots of land and
              items. These assets can then be visualized within the Site and the
              App. The Smart Contracts and the Site are collectively referred to
              in these Terms as the “App”. Using the App, users can view their
              assets and use the Smart Contracts to acquire, trade, battle, and
              breed unicorns with other App users.
            </span>
            {/* <span>.</span>
            <span className="c0">
              &nbsp;The Smart Contracts and the Site are collectively referred
              to in these Terms as the &ldquo;App&rdquo;. Using the App, users
              can view their assets and use the Smart Contracts to acquire,
              trade, battle, and breed unicorns with other App users.
            </span> */}
          </p>
          <p className="c1">
            <span className="c0">
              PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE APP OR THE
              SITE. THESE TERMS GOVERN YOUR USE OF THE APP, THE SITE, AND ANY
              PURCHASES YOU MAKE, UNLESS WE HAVE EXECUTED A SEPARATE WRITTEN
              AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY WILLING TO MAKE
              THE APP AND THE SITE AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE
              TERMS. BY USING THE APP AND/OR THE SITE, OR ANY PART OF EACH, YOU
              ARE CONFIRMING YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF
              THESE TERMS. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A
              COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE
              LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN
              WHICH CASE “YOU” WILL MEAN THAT ENTITY. IF YOU DO NOT HAVE SUCH
              AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE TERMS, THEN WE ARE
              UNWILLING TO MAKE THE APP OR THE SITE AVAILABLE TO YOU. IF YOU DO
              NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE APP OR THE
              SITE.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Supplemental terms and conditions or documents that may be posted
              on the Site, the App, and the Smart Contracts from time to time
              are hereby expressly incorporated herein by reference. We reserve
              the right, in our sole discretion, to make changes or
              modifications to these Terms of Service at any time and for any
              reason. We will alert you of any changes by updating the “Last
              Updated" date of these Terms of Service, and you waive any right
              to receive specific notice of each such change. It is your
              responsibility to periodically review these Terms of Service to
              stay informed of updates. You will be subject to and will be
              deemed to have been made aware of and to have accepted, the
              changes in any revised Terms of Service by your continued use of
              the Site, the App, and the Smart Contracts after the date such
              revised Terms of Service are posted.
            </span>
          </p>
          <p className="c1">
            <span className="c0">Key Definitions</span>
          </p>
          <p className="c1">
            <span className="c0">
              “CU” is the in-game currency token and is used for gaming
              purposes, such as purchasing resources or participating in the
              in-game economy.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              “UNIM” is an ERC20 Token used to breed and evolve Unicorns.
              “Ownership” means players are able to transact with their CU and
              UNIM tokens using their private keys.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              “Smart Contracts” means the computer code executed in response to
              transactions published on the Blockchains that generates a
              consistent and verifiable result.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Fees and Payment</span>
          </p>
          <p className="c1">
            <span className="c0">
              If you elect to purchase, trade, or interact with the tokens on
              the App, or with or from other users via the App, any financial
              transactions you engage in will be conducted solely through the
              Arbitrum network. We will have no insight into or control over
              these payments or transactions, nor do we have the ability to
              reverse any transactions. You agree we have no liability to you or
              to any third party for any claims or damages that may arise as a
              result of any transactions that you engage in via the App or any
              other transactions that you conduct via the Arbitrum or other
              blockchain networks.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              As between us, you will be solely responsible to pay any and all
              sales, use, value-added and other taxes, duties, and assessments
              (except taxes on our net income) now or hereafter claimed or
              imposed by any governmental authority (collectively, “Taxes”)
              associated with your use of the App (including, without
              limitation, any Taxes that may become payable as the result of
              your ownership, transfer, or acquisition of your CU token). Except
              for income taxes levied on the UNA, you: (i) will pay or reimburse
              us for all national, federal, state, local or other taxes and
              assessments of any jurisdiction, including value added taxes and
              taxes as required by international tax treaties, customs or other
              import or export taxes, and amounts levied in lieu thereof based
              on charges set, services performed or payments made hereunder, as
              are now or hereafter may be imposed under the authority of any
              national, state, local or any other taxing jurisdiction; and (ii)
              shall not be entitled to deduct the amount of any such taxes,
              duties or assessments from payments made to us pursuant to these
              Terms.
            </span>
          </p>
          <p className="c1">
            <span className="c3">Ownership; Restrictions</span>
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c1">
            <span className="c0">
              You acknowledge and agree that, unless otherwise stated, we (or,
              as applicable, our licensors) own all legal right, title and
              interest in and to all elements of the App, and all intellectual
              property rights therein. The visual interfaces, graphics
              (including, without limitation, all art and drawings associated
              with the), design, systems, methods, information, computer code,
              software, services, “look and feel”, organization, compilation of
              the content, code, data, and all other elements of the App
              (collectively, the “Crypto Unicorns Materials”) are owned by the
              UNA and/or its licensors, and are protected by copyright, trade
              dress, patent, and trademark laws, international conventions,
              other relevant intellectual property and proprietary rights, and
              applicable laws. All Crypto Unicorns Materials are the copyrighted
              property of the UNA or its licensors, and all trademarks, service
              marks, and trade names contained in the Crypto Unicorns Materials
              are proprietary to the UNA or its licensors. Except as expressly
              set forth herein, your use of the App does not grant you ownership
              of or any other rights with respect to any content, code, data, or
              other materials that you may access on or through the App. We
              reserve all rights in and to the Crypto Unicorns Materials not
              expressly granted to you in the Terms. For the sake of clarity,
              you understand and agree: (i) that your “purchase” of in-game
              assets, including CU tokens, whether via the App or otherwise,
              does not give you any rights or licenses in or to the Crypto
              Unicorns Materials (including, without limitation, our copyright
              in and to the art and drawings associated with the tokens) other
              than those expressly contained in these Terms or pursuant to the
              Crypto Unicorns UNA Charter.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              You may choose to submit comments, bug reports, ideas or other
              feedback about the App, including without limitation about how to
              improve the App (collectively, “Feedback”). By submitting any
              Feedback, you agree that we are free to use such Feedback at our
              discretion and without additional compensation to you, and to
              disclose such Feedback to third parties (whether on a
              non-confidential basis, or otherwise). You hereby grant us a
              perpetual, irrevocable, nonexclusive, worldwide license under all
              rights necessary for us to incorporate and use your Feedback for
              any purpose.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              You agree that you are responsible for your own conduct while
              accessing or using the App, and for any consequences thereof. You
              agree to use the App only for purposes that are legal, proper and
              in accordance with these Terms and any applicable laws or
              regulations. By way of example, and not as a limitation, you may
              not, and may not allow any third party to: (i) send, upload,
              distribute or disseminate any unlawful, defamatory, harassing,
              abusive, fraudulent, obscene, or otherwise objectionable content;
              (ii) distribute viruses, worms, defects, Trojan horses, corrupted
              files, hoaxes, or any other items of a destructive or deceptive
              nature; (iii) impersonate another person (via the use of an email
              address or otherwise); (iv) upload, post, transmit or otherwise
              make available through the App any content that infringes the
              intellectual proprietary rights of any party; (v) use the App to
              violate the legal rights (such as rights of privacy and publicity)
              of others; (vi) engage in, promote, or encourage illegal activity
              (including, without limitation, money laundering); (vii) interfere
              with other users’ enjoyment of the App; (viii) exploit the App for
              any unauthorized commercial purpose; (ix) modify, adapt,
              translate, or reverse engineer any portion of the App; (x) remove
              any copyright, trademark or other proprietary rights notices
              contained in or on the App or any part of it; (xi) reformat or
              frame any portion of the App; (xii) display any content on the App
              that contains any hate-related or violent content or contains any
              other material, products or services that violate or encourage
              conduct that would violate any criminal laws, any other applicable
              laws, or any third party rights; (xiii) use any robot, spider,
              site search/retrieval application, or other device to retrieve or
              index any portion of the App or the content posted on the App, or
              to collect information about its users for any unauthorized
              purpose; (xiv) create user accounts by automated means or under
              false or fraudulent pretenses; or (xv) access or use the App for
              the purpose of creating a product or service that is competitive
              with any of our products or services.
            </span>
          </p>
          <p className="c1">
            <span>
              Crypto Unicorns Tokens (CU) are an ERC20 Governance Token for the
              Unicorn metaverse. CU holders will be able to utilize these tokens
              in a variety of ways including Unicorn Evolution and Breeding, and
              staking the CU tokens to vote on the Crypto Unicorns DAO
              proposals.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Termination</span>
          </p>
          <p className="c1">
            <span className="c0">
              You may terminate these Terms at any time by canceling your
              account on the App and discontinuing your access to and use of the
              App. You will not receive any refunds if you cancel your account,
              or otherwise terminate these Terms. You agree that we, in our sole
              discretion and for any or no reason, may terminate these Terms and
              suspend and/or terminate your account(s) for the App. You agree
              that any suspension or termination of your access to the App may
              be without prior notice, and that we will not be liable to you or
              to any third party for any such suspension or termination. If we
              terminate these Terms or suspend or terminate your access to or
              use of the App due to your breach of these Terms or any suspected
              fraudulent, abusive, or illegal activity, then termination of
              these Terms will be in addition to any other remedies we may have
              at law or in equity. Upon any termination or expiration of these
              Terms, whether by you or us, you may no longer have access to
              information that you have posted on the App or that is related to
              your account, and you acknowledge that we will have no obligation
              to maintain any such information in our databases or to forward
              any such information to you or to any third party.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Disclaimers </span>
          </p>
          <p className="c1">
            <span className="c0">
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF
              THE APP IS AT YOUR SOLE RISK, AND THAT THE APP IS PROVIDED “AS IS”
              AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS
              OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
              APPLICABLE LAW, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS
              MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED
              WARRANTIES REGARDING THE APP AND ANY PART OF IT (INCLUDING,
              WITHOUT LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY EXTERNAL
              WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS,
              ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE
              FOREGOING, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT
              REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE
              APP WILL MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE
              APP WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR,
              (III) USAGE DATA PROVIDED THROUGH THE APP WILL BE ACCURATE, (III)
              THE APP OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR
              THROUGH THE APP ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS,
              OR (IV) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE APP WILL
              BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
              IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF
              THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION
              AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO
              LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT
              IS DUE TO OUR GROSS NEGLIGENCE.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU
              INCUR AS THE RESULT OF YOUR USE OF THE BLOCKCHAINS OR AN
              ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES,
              DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR, SUCH AS FORGOTTEN
              PASSWORDS OR INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER
              TRANSACTIONS; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED
              WALLET FILES; (D) UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD
              PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES,
              PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE APP,
              THE BLOCKCHAINS, OR AN ELECTRONIC WALLET.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              CU TOKENS AND OTHER IN-GAME ASSETS ARE INTANGIBLE DIGITAL ASSETS
              THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN
              THE BLOCKCHAINS. ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR
              THROUGH OUR CODE, AND ALL TRANSACTIONS ARE RECORDED ON THE
              DECENTRALIZED LEDGER WITHIN THE BLOCKCHAINS. FOR CLARITY, WE HAVE
              NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH RESPECT TO
              THE FUNCTIONALITY OF THE BLOCKCHAINS AND/OR THE EXECUTION OF SMART
              CONTRACTS AND/OR TRANSACTIONS.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              The UNA IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY
              OTHER FEATURES OF THE BLOCKCHAINS OR AN ELECTRONIC WALLET,
              INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR
              REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE
              BLOCKCHAIN SUPPORTING THE BLOCKCHAINS, INCLUDING FORKS, TECHNICAL
              NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Limitation of Liability</span>
          </p>
          <p className="c1">
            <span className="c0">
              YOU UNDERSTAND AND AGREE THAT WE, OUR SUBSIDIARIES, AFFILIATES,
              AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR
              ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
              DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY
              OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS
              (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR
              BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF
              SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN
              IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND
              ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR
              ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY
              PORTION OF THE APP, WHETHER IN CONTRACT, TORT, STRICT LIABILITY,
              OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF (A) THE
              AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 12 MONTH
              PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) $100.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE APP AVAILABLE TO
              YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY
              DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH
              REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE
              PARTIES AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US. WE
              WOULD NOT BE ABLE TO PROVIDE THE APP TO YOU WITHOUT THESE
              LIMITATIONS.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              YOU ACKNOWLEDGE AND AGREE THAT YOU DO NOT RESIDE IN REGION THAT
              EXPLICITLY BANS THE USE OF LOOT BOXES IN GAMES IN ACCORDANCE WITH
              GAMBLING LAWS.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
              INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND SOME JURISDICTIONS ALSO
              LIMIT DISCLAIMERS OR LIMITATIONS OF LIABILITY FOR PERSONAL INJURY
              FROM CONSUMER PRODUCTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO
              PERSONAL INJURY CLAIMS.
            </span>
          </p>
          <p className="c1">
            <span className="c3">Assumption of Risk</span>
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c1">
            <span className="c0">
              You accept and acknowledge each of the following:
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              The prices of blockchain assets are extremely volatile.
              Fluctuations in the price of other digital assets could materially
              and adversely affect the value of your CU token or other items,
              which may also be subject to significant price volatility. We
              cannot guarantee that any purchasers of CU tokens or other items
              will not lose money. You are solely responsible for determining
              what, if any, taxes apply to your CU-related transactions. The UNA
              is not responsible for determining the taxes that apply to your
              transactions on the App, the Site, or any blockchain. The App does
              not store, send, or receive CU tokens. This is because CU tokens
              exist only by virtue of the ownership record maintained on the
              App’s supporting blockchain. Any transfer of CU token occurs
              within the supporting blockchain, and not on the App. There are
              risks associated with using an Internet-based currency, including,
              but not limited to, the risk of hardware, software and Internet
              connections, the risk of malicious software introduction, and the
              risk that third parties may obtain unauthorized access to
              information stored within your wallet. You accept and acknowledge
              that The UNA will not be responsible for any communication
              failures, disruptions, errors, distortions or delays you may
              experience when using the Blockchains, however caused. A lack of
              use or public interest in the creation and development of
              distributed ecosystems could negatively impact the development of
              the Crypto Unicorns ecosystem, and therefore the potential utility
              or value of CU tokens. The regulatory regime governing blockchain
              technologies, cryptocurrencies, and tokens is uncertain, and new
              regulations or policies may materially adversely affect the
              development of the Crypto Unicorns ecosystem, and therefore the
              potential utility or value of CU tokens. Upgrades to the
              Blockchains, a hard fork in the Blockchains, or a change in how
              transactions are confirmed on the Blockchains may have unintended,
              adverse effects on all applications using the Blockchains,
              including the Crypto Unicorns ecosystem.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Indemnification </span>
          </p>
          <p className="c1">
            <span className="c0">
              You agree to hold harmless and indemnify the UNA and its
              subsidiaries, affiliates, officers, agents, employees,
              advertisers, licensors, suppliers or partners from and against any
              claim, liability, loss, damage (actual and consequential) of any
              kind or nature, suit, judgment, litigation cost, and attorneys’
              fees arising out of or in any way related to (i) your breach of
              these Terms, (ii) your misuse of the App, (iii) your violation of
              applicable laws, rules or regulations in connection with your
              access to or use of the App, or (iv) your breach of our Privacy
              Policy. You agree that the UNA will have control of the defense or
              settlement of any such claims.
            </span>
          </p>
          <p className="c1">
            <span className="c2">External Sites </span>
          </p>
          <p className="c1">
            <span className="c0">
              The App may include hyperlinks to other web sites or resources
              (collectively, “External Sites”), which are provided solely as a
              convenience to our users. We have no control over any External
              Sites. You acknowledge and agree that we are not responsible for
              the availability of any External Sites, and that we do not endorse
              any advertising, products or other materials on or made available
              from any External Sites. Furthermore, you acknowledge and agree
              that we are not liable for any loss or damage which may be
              incurred as a result of the availability or unavailability of the
              External Sites, or as a result of any reliance placed by you upon
              the completeness, accuracy or existence of any advertising,
              products or other materials on, or made available from, any
              External Sites.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Changes to the App </span>
          </p>
          <p className="c1">
            <span className="c0">
              We may revise and update these Terms of Service from time to time
              in our sole discretion. When we make changes, we will make the
              updated Terms available on the App and update the “Last Updated”
              date at the beginning of these Terms accordingly, which apply to
              all access to and use of the App and Website thereafter. Please
              check these Terms periodically for changes. Any changes to the
              Terms will apply on the date that they are made, and your
              continued access to or use of the App after the Terms have been
              updated will constitute your binding acceptance of the updates. If
              you do not agree to any revised Terms, you may not access or use
              the App.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Children </span>
          </p>
          <p className="c1">
            <span className="c0">
              You affirm that you are over the age of 13, as the App is not
              intended for children under 13.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              IF YOU ARE 13 OR OLDER BUT UNDER THE AGE OF 18, OR THE LEGAL AGE
              OF MAJORITY WHERE YOU RESIDE IF THAT JURISDICTION HAS AN OLDER AGE
              OF MAJORITY, THEN YOU AGREE TO REVIEW THESE TERMS WITH YOUR PARENT
              OR GUARDIAN TO MAKE SURE THAT BOTH YOU AND YOUR PARENT OR GUARDIAN
              UNDERSTAND AND AGREE TO THESE TERMS. YOU AGREE TO HAVE YOUR PARENT
              OR GUARDIAN REVIEW AND ACCEPT THESE TERMS ON YOUR BEHALF. IF YOU
              ARE A PARENT OR GUARDIAN AGREEING TO THE TERMS FOR THE BENEFIT OF
              A CHILD OVER 13, THEN YOU AGREE TO AND ACCEPT FULL RESPONSIBILITY
              FOR THAT CHILD’S USE OF THE APP, INCLUDING ALL FINANCIAL CHARGES
              AND LEGAL LIABILITY THAT HE OR SHE MAY INCUR.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Privacy Policy </span>
          </p>
          <p className="c1">
            <span className="c0">
              Our <a href="/privacy-policy">Privacy Policy</a> describes the
              ways we collect, use, store and disclose your personal
              information, and is hereby incorporated by this reference into
              these Terms. You agree to the collection, use, storage, and
              disclosure of your data in accordance with our Privacy Policy.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Dispute Resolution; Arbitration </span>
          </p>
          <p className="c1">
            <span className="c0">
              PLEASE READ THIS SECTION CAREFULLY. IT MAY SIGNIFICANTLY AFFECT
              YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN
              COURT.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              EXCEPT FOR DISPUTES THAT QUALIFY FOR SMALL CLAIMS COURT, ALL
              DISPUTES ARISING OUT OF OR RELATED TO THESE TERMS OF SERVICE OR
              ANY ASPECT OF THE RELATIONSHIP BETWEEN YOU AND US, WHETHER BASED
              IN CONTRACT, TORT, STATUTE, FRAUD, MISREPRESENTATION, OR ANY OTHER
              LEGAL THEORY, WILL BE RESOLVED THROUGH FINAL AND BINDING
              ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY A
              JUDGE OR JURY, AND YOU AGREE THAT THE UNA AND YOU ARE EACH WAIVING
              THE RIGHT TO SUE IN COURT AND TO HAVE A TRIAL BY A JURY. YOU AGREE
              THAT ANY ARBITRATION WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS
              ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND YOU ARE
              AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ACTION.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              The laws of the State of Delaware shall govern these Terms of
              Services.
            </span>
          </p>
          <p className="c1">
            <span>
              The arbitration shall be commenced and conducted under the
              Commercial Arbitration Rules of the American Arbitration
              Association (“AAA") and, where appropriate, the AAA's
              Supplementary Procedures for Consumer-Related Disputes (“AAA
              Consumer Rules"), both of which are available at the AAA website
              www.adr.org. Your arbitration fees and your share of arbitration
              compensation shall be governed by the AAA Consumer Rules and,
              where appropriate, limited by the AAA Consumer Rules. If such
              costs are determined by the arbitrator to be excessive, we will
              pay all the arbitration fees and expenses. Except where otherwise
              required by the applicable AA rules or applicable law, the
              arbitration can take place in the State of New York. Except as
              otherwise provided herein, the Parties may litigate in court to
              compel arbitration, stay proceedings pending arbitration, or to
              confirm, modify, vacate, or enter judgement on the award entered
              by the arbitrator.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              The arbitrator may award any relief that a court of competent
              jurisdiction could award, including attorneys’ fees when
              authorized by law. The arbitral decision may be enforced in any
              court of competent jurisdiction.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              The arbitrator will conduct hearings, if any, by teleconference or
              videoconference, rather than by personal appearances, unless the
              arbitrator determines upon request by you or by us that an
              in-person hearing is appropriate. Any in-person appearances will
              be held at a location which is reasonably convenient to both
              parties with due consideration of their ability to travel and
              other pertinent circumstances. The arbitrator’s decision will
              follow the terms of these Terms of Service and will be final and
              binding. The arbitrator will have authority to award temporary,
              interim, or permanent injunctive relief or relief providing for
              specific performance of these Terms of Service, but only to the
              extent necessary to provide relief warranted by the individual
              claim before the arbitrator. The award rendered by the arbitrator
              may be confirmed and enforced in any court having jurisdiction
              thereof. Notwithstanding any of the foregoing, nothing in these
              Terms of Service will preclude you from bringing issues to the
              attention of federal, state, or local agencies and, if the law
              allows, they can seek relief against us for you.
            </span>
          </p>
          <p className="c1">
            <span>
              If for any reason, a Dispute proceeds in court rather than
              arbitration, the Dispute shall be commenced or prosecuted in the
              state and federal courts located in the State of New York, and the
              Parties hereby consent to and waive all defenses of lack of
              personal jurisdiction, and forum non-conveniens with respect to
              venue and jurisdiction in such state and federal courts.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Waiver and Severability </span>
          </p>
          <p className="c1">
            <span className="c0">
              No waiver by the Company of any term or condition set out in these
              Terms of Service shall be deemed a further or continuing waiver of
              such term or condition or a waiver of any other term or condition,
              and any failure of the Company to assert a right or provision
              under these Terms of Service shall not constitute a waiver of such
              right or provision. If any provision of these Terms of Service is
              held by a court or other tribunal of competent jurisdiction to be
              invalid, illegal, or unenforceable for any reason, such provision
              shall be eliminated or limited to the minimum extent such that the
              remaining provisions of the Terms of Service will continue in full
              force and effect.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Content Standards</span>
          </p>
          <p className="c1">
            <span className="c0">
              These content standards apply to any and all contributions and use
              by you to the App or Site. Your use and contributions must in
              their entirety comply with all applicable federal, state, local,
              and international laws and regulations. Without limiting the
              foregoing, You must not:
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Provide any material that is defamatory, obscene, indecent,
              abusive, offensive, harassing, violent, hateful, inflammatory, or
              otherwise objectionable. Promote sexually explicit or pornographic
              material, violence, or discrimination based on race, sex,
              religion, nationality, disability, sexual orientation, or age.
              Infringe any patent, trademark, trade secret, copyright, or other
              intellectual property or other rights of any other person. Violate
              the legal rights (including the rights of publicity and privacy)
              of others or contain any material that could give rise to any
              civil or criminal liability under applicable laws or regulations
              or that otherwise may be in conflict with these Terms of Service
              and our Privacy Policy. Be likely to deceive any person. Promote
              any illegal activity, or advocate, promote, or assist any unlawful
              act. Impersonate any person, or misrepresent your identity or
              affiliation with any person or organization. Involve commercial
              activities or sales, such as contests, sweepstakes, and other
              sales promotions, barter, or advertising. Give the impression that
              they emanate from or are endorsed by us or any other person or
              entity, if this is not the case. Defamation You agree you will not
              disparage or criticize the Company or its Affiliates, or their
              respective businesses, management, directors, business practices,
              or equity holders (the “Company Entities”) and that you will not
              otherwise do or say anything that could disrupt the good morale,
              or otherwise harm the interests or reputations, of the Company
              Entities and we agree we will not publicly disparage or criticize
              you.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Refunds </span>
          </p>
          <p className="c1">
            <span className="c0">
              Due to the irrevocable nature of the Blockchains, and our lack of
              control over user assets, we are unable to offer refunds on any
              purchases.
            </span>
          </p>
          <p className="c1">
            <span className="c2">General </span>
          </p>
          <p className="c1">
            <span className="c0">
              These Terms constitute the entire legal agreement between you and
              the UNA, govern your access to and use of the App, and completely
              replace any prior or contemporaneous agreements between the
              parties related to your access to or use of the App, whether oral
              or written. There are no third-party beneficiaries to these Terms.
              The parties are independent contractors, and nothing in these
              Terms create any agency, partnership, or joint venture. The
              language in these Terms will be interpreted as to its fair
              meaning, and not strictly for or against any party. You may not
              assign any or your rights or obligations under these Terms,
              whether by operation of law or otherwise, without our prior
              written consent. We may assign our rights and obligations under
              these Terms in our sole discretion to an affiliate, or in
              connection with an acquisition, sale or merger. Should any part of
              these Terms be held invalid or unenforceable, that portion shall
              be construed consistent with applicable law and the remaining
              portions will remain in full force and effect. Our failure to
              enforce any provision of these Terms will not be deemed a waiver
              of such provision, nor of the right to enforce such provision.
              These Terms will be governed by and construed in accordance with
              the laws of the State of Delaware. We will not be liable for any
              failure or delayed performance of our obligations that result from
              any condition beyond our reasonable control, including, but not
              limited to, governmental action, acts of terrorism, earthquake,
              fire, flood, acts of God, labor conditions, power failures,
              Internet disturbances, or acts or omissions of third parties. You
              agree that we may provide you with notices (including, without
              limitation those regarding changes to these Terms) by email,
              regular mail, or postings on the App. By providing us with your
              email address, you consent to our using the email address to send
              you any notices required by law in lieu of communication by postal
              mail.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Giveaway Sweepstakes Official Rules</span>
          </p>
          <p className="c1">
            <span className="c0">
              You acknowledge that we may conduct giveaways from time to time
              (the “Sweepstakes”), which users can participate with no purchase
              necessary to enter or win during a specific timeline (the
              “Promotion Period”) which will be communicated in the Sweepstakes
              announcement. Making a purchase or payment of any kind will not
              increase your chances of winning.
            </span>
          </p>
          <p>
            <span className="c0">
              By participating in the Sweepstakes, you unconditionally accept
              and agree to comply with and abide by these terms and the
              decisions of the Company, which shall be final and binding in all
              respects. We are responsible for the collection, submission or
              processing of entries and the overall administration of the
              Sweepstakes. You should look solely to us with any questions,
              comments or problems related to the Sweepstakes. We may be reached
              by email at{" "}
              <a href="mailto: legal@cryptounicorns.fun">
                legal@cryptounicorns.fun
              </a>{" "}
              during the Promotion Period.
            </span>
          </p>
          <p>
            <span className="c0">
              The Sweepstakes are open to anyone who is 18 years of age or
              older. Company, and their respective parents, subsidiaries,
              affiliates, distributors, retailers, sales representatives,
              advertising and promotion agencies and each of their respective
              officers, directors and employees (the “Promotion Entities”), are
              ineligible to enter the Sweepstakes or win the Prize. Household
              Members and Immediate Family Members of such individuals are also
              not eligible to enter or win. “Household Members” shall mean those
              people who share the same residence at least three months a year.
              “Immediate Family Members” shall mean parents, step-parents, legal
              guardians, children, step-children, siblings, step-siblings, or
              spouses. This Sweepstakes is subject to all applicable federal,
              state and local laws and regulations and is void where prohibited
              or restricted by law.
            </span>
          </p>
          <p>
            <span>
              One winner will receive a free Unicorn NFT (the “Prize”). The
              Prize is non-transferable, non-exchangeable, and cannot be
              substituted for cash or any other prize. Only one Prize per person
              will be awarded. The Company reserves the right to substitute the
              prize with another of equal or greater value if necessary (the
              “ARV”), as determined in Company’s reasonable, good faith
              judgment. This determination is final and binding and cannot be
              appealed.
            </span>
          </p>
          <p>
            <span>
              You may enter the Sweepstakes during the Promotion Period by
              visiting the entry form via one of our ad campaigns. By entering
              the Sweepstakes, you agree to subscribe to marketing
              communications from the Company and/or the Company’s affiliates.
              Additionally, as part of the Sweepstakes entry process, detailed
              instructions will be provided during the Sweepstakes announcement
              on how to earn additional entries by providing certain information
              or posting on social media. Note that you must comply with the
              terms and policies of the respective social media platforms, and
              we shall not be liable for any actions or consequences arising
              from your non-compliance with such terms and policies.
            </span>
          </p>
          <p>
            <span>
              The Sweepstakes guidelines will also specify how to connect your
              wallet address (the “Wallet”), as the Prize will be delivered to
              the Winner’s designated Wallet. Automated or robotic Entries
              submitted by individuals or organizations will be disqualified.
              Internet entry must be made by you. Any attempt by you to obtain
              more than the stated number of Entries by using multiple/different
              email addresses, identities, registrations, logins or any other
              methods, including, but not limited to, commercial
              contest/sweepstakes subscription notification and/or entering
              services, will void your Entries and you may be disqualified.
              Final eligibility for the award of the Prize is subject to
              eligibility verification as set forth below. All Entries must be
              posted by the end of the Promotion Period in order to participate.
              Company's database clock will be the official timekeeper for the
              Sweepstakes.
            </span>
          </p>
          <p>
            <span>
              The winner of the Sweepstakes (the “Winner”) will be selected in a
              random drawing from among all eligible Entries received throughout
              the Promotion Period. The random drawing will be conducted about 5
              days after the Promotion Period by Company or its designated
              representatives, whose decisions are final. Odds of winning will
              vary depending on the number of eligible Entries received. Winner
              will be notified by email at the email address provided in the
              Entry Information approximately 5 days after the random drawing,
              and Winner must respond within 7 days to claim the prize. If
              Winner does not respond within the specified time, an alternate
              winner may be selected. If the Winner responds within the
              specified time to claim the Prize, the Prize will be delivered to
              the Winner’s Wallet.
            </span>
          </p>
          <p>
            <span>
              By entering the Sweepstakes, you grant us permission to share your
              email address and any other personally identifiable information
              with the other Sweepstakes Entities for the purpose of
              administration and prize fulfillment, including use in a publicly
              available Winners list. Any personal information provided by you,
              including your wallet address, will be used solely for the purpose
              of administering the Sweepstakes and will be subject to our{" "}
              <a href="/privacy-policy">Privacy Policy</a>. You additionally
              grant us and our affiliates consent to send you marketing
              communications about the Company’s and the Company’s affiliates’
              products and services.
            </span>
          </p>
          <p>
            <span>
              We assume no responsibility or liability for (a) any incorrect or
              inaccurate entry information, or for any faulty or failed
              electronic data transmissions; (b) any unauthorized access to, or
              theft, destruction or alteration of entries at any point in the
              operation of this Sweepstakes; (c) any technical malfunction,
              failure, error, omission, interruption, deletion, defect, delay in
              operation or communications line failure, regardless of cause,
              with regard to any equipment, systems, networks, lines,
              satellites, servers, camera, computers or providers utilized in
              any aspect of the operation of the Sweepstakes; (d)
              inaccessibility or unavailability of any network or wireless
              service, the Internet or website or any combination thereof; (e)
              suspended or discontinued Internet, wireless or landline phone
              service; or (f) any injury or damage to participant's or to any
              other person’s computer or mobile device which may be related to
              or resulting from any attempt to participate in the Sweepstakes or
              download of any materials in the Sweepstakes. By participating in
              the Sweepstakes, you acknowledge that the odds of winning depend
              on the total number of eligible entries received; thus, the
              specific odds of winning were not disclosed. You agree to release,
              discharge, and hold harmless the Company, its affiliates,
              partners, officers, directors, employees, and agents from any
              claims, losses, damages, or injuries arising out of or relating to
              your participation in the Sweepstakes or the acceptance, use,
              misuse, or possession of any prize won. The Company shall not be
              liable for any indirect, incidental, consequential, special, or
              punitive damages, including but not limited to loss of profits,
              arising out of or in connection with the Sweepstakes or the prizes
              awarded. You acknowledge that the Prize is provided on an "as-is"
              basis and that the Company does not provide any warranties or
              guarantees regarding the Prize.
            </span>
          </p>
          <p>
            <span>
              If, for any reason, the Sweepstakes is not capable of running as
              planned for reasons which may include without limitation,
              infection by computer virus, tampering, unauthorized intervention,
              fraud, technical failures, or any other causes which may corrupt
              or affect the administration, security, fairness, integrity or
              proper conduct of this Sweepstakes, we reserve the right at our
              sole discretion to cancel, terminate, modify or suspend the
              Sweepstakes in whole or in part. In such event, we shall
              immediately suspend all drawings and prize awards, and we reserve
              the right to award any remaining prizes (up to the total ARV as
              set forth in these Terms) in a manner deemed fair and equitable by
              us. The Company shall not have any further liability to any
              participant in connection with the Sweepstakes.
            </span>
          </p>
          <p className="c1">
            <span className="c2">Skill-Based Games</span>
          </p>
          <p>
            <span>
              Unicorn Party (the “Game”) is open to individuals who are 18 years
              of age or older. You must have a valid account on the App and
              possess the required CU tokens to purchase entry tickets. Company
              and their respective Promotion Entities are ineligible to
              participate. Household Members and Immediate Family Members of
              such individuals are also not eligible to participate.
            </span>
          </p>
          <p>
            <span>
              To participate, you must acquire entry tickets by purchasing them
              with the designated CU tokens. The number of entry tickets
              required for participation will be clearly communicated, and
              participants must have a sufficient token balance to cover the
              ticket cost.
            </span>
          </p>
          <p>
            <span>
              The Game will feature a series of skill based mini games based on
              popular .io titles. Participants will be required to demonstrate
              their skills and compete against other players to achieve the
              highest scores or accomplish specific milestones within the game.
            </span>
          </p>
          <p>
            <span>
              Prizes will be awarded based on participants' performance within
              the Game. The criteria for winning and the specific prizes
              available will be clearly stated prior to participation. The
              winners will be determined via a battle royale style whereby the
              last player standing is the winner.
            </span>
          </p>
          <p>
            <span>
              Winners will be notified through the App or via email within a
              specified time period after the Game concludes. Participants must
              provide accurate and valid contact information to receive prize
              notifications, and must connect their wallet as prizes will be
              delivered to the Winner’s designated wallet. The distribution of
              prizes will be carried out according to the terms and conditions
              outlined in the Game rules.
            </span>
          </p>
          <p>
            <span>
              Any personal information provided by you, including your wallet
              address, will be used solely for the purpose of administering the
              Game and will be subject to our Privacy Policy . You additionally
              grant us and our affiliates consent to send you marketing
              communications about the Company’s and the Company’s affiliates’
              products and services.
            </span>
          </p>
          <p>
            <span>
              The Game will be conducted with fairness in mind. Any attempt to
              manipulate or cheat within the Game will result in immediate
              disqualification. The Company reserves the right to employ
              anti-cheating measures, such as monitoring gameplay or employing
              algorithms to detect unfair practices.
            </span>
          </p>
          <p>
            <span>
              We assume no responsibility or liability for (a) any incorrect or
              inaccurate entry information, or for any faulty or failed
              electronic data transmissions; (b) any unauthorized access to, or
              theft, destruction or alteration of entries at any point in the
              operation of the Game; (c) any technical malfunction, failure,
              error, omission, interruption, deletion, defect, delay in
              operation or communications line failure, regardless of cause,
              with regard to any equipment, systems, networks, lines,
              satellites, servers, camera, computers or providers utilized in
              any aspect of the operation of the Game; (d) inaccessibility or
              unavailability of any network or wireless service, the Internet or
              website or any combination thereof; (e) suspended or discontinued
              Internet, wireless or landline phone service; or (f) any injury or
              damage to your or to any other person’s computer or mobile device
              which may be related to or resulting from any attempt to
              participate in the Game or download of any materials in the Game.
              You agree to release, discharge, and hold harmless the Company,
              its affiliates, partners, officers, directors, employees, and
              agents from any claims, losses, damages, or injuries arising out
              of or relating to your participation in the Game or the
              acceptance, use, misuse, or possession of any prize won. The
              Company shall not be liable for any indirect, incidental,
              consequential, special, or punitive damages, including but not
              limited to loss of profits, arising out of or in connection with
              the Game or the prizes awarded. You acknowledge that the prizes
              are provided on an "as-is" basis and that the Company does not
              provide any warranties or guarantees regarding the prizes.
            </span>
          </p>
          <p>
            <span>
              Any disputes or discrepancies regarding gameplay, scores, or
              eligibility will be resolved at the company's discretion. The
              Company's decisions regarding the Game, including prize awards,
              are final and binding. The Game will adhere to all applicable laws
              and regulations. Participants are responsible for ensuring their
              participation is lawful in their respective jurisdictions. The
              Company reserves the right to cancel, modify, suspend, or
              terminate the Game in whole or in part if required by law or for
              any other valid reason, which may include without limitation,
              infection by computer virus, tampering, unauthorized intervention,
              fraud, technical failures, or any other causes which may corrupt
              or affect the administration, security, fairness, integrity or
              proper conduct of the Game.
            </span>
          </p>
        </ContentWrap>
      </SectionCard>
    </Container>
  );
}
