import { useState } from "react";
import styled from "styled-components";
import { TokenCard } from "./TokenCard";
import { landType, rarity } from "../Helpers";
import { InventoryTokens } from "./InventoryTokens";
import { Modal } from "../base/Modal";
import { Typography } from "../base/Typography";
import { Button } from "../base/Button";
import Spinner from "../base/Spinner";
import { BUY } from "../../consts/externalLinks";
import { useGetInventoryLands } from "../../query/inventory";

const ImgWrap = styled.img`
  width: 100%;
  border-radius: 8px;
`;

const PlaceholderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 20px;
  min-height: 528px;
  padding: 0 10px;
`;

const SpinnerWrap = styled.div`
  & svg {
    margin: 0;
  }
`;

const filters = [
  {
    title: "Class",
    options: [
      { value: null, title: "All", icon: "all" },
      // { value: "0", title: "Unset" , },
      { value: "1", title: "Mythic", icon: "mythic" },
      { value: "2", title: "Light", icon: "light" },
      { value: "3", title: "Wonder", icon: "wonder" },
      { value: "4", title: "Mystery", icon: "mystery" },
      { value: "5", title: "Heart", icon: "heart" },
      { value: "6", title: "Cloud", icon: "cloud" },
      { value: "7", title: "Flower", icon: "flower" },
      { value: "8", title: "Candy", icon: "candy" },
      { value: "9", title: "Crystal", icon: "crystal" },
      { value: "10", title: "Moon", icon: "moon" },
      { value: "11", title: "Rainbow", icon: "rainbow" },
      { value: "12", title: "OmNom", icon: "omnom" },
      { value: "13", title: "Star", icon: "star" }
    ],
    id: "class"
  },
  {
    title: "Rarity",
    options: [
      { value: null, title: "All", icon: "all" },
      // { value: "0", title: "Unset" },
      { value: "1", title: "Mythic", icon: "mythic" },
      { value: "2", title: "Rare", icon: "rare" },
      { value: "3", title: "Common", icon: "common" }
    ],
    id: "rarity"
  }
];

const filterGetters = {
  class: (item) => item.landType,
  rarity: (item) => item.rarity
};

export function InventoryLands() {
  // const { balance, data, loading, loadCount } = useAtomValue(landsAtom);
  const { data = [], isLoading } = useGetInventoryLands();
  const [showModal, setShowModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const balance = data?.length || 0;
  const handleSelectLand = (item) => {
    setSelectedToken(item);
    setShowModal(true);
  };

  let _toolbarTitle = `${balance} lands owned`;

  if (isLoading) {
    return (
      <PlaceholderWrap>
        <Typography
          tag={"h3"}
          variant={"textLg"}
          color="black"
          className="mb-2"
        >
          Loading lands
        </Typography>
        <SpinnerWrap>
          <Spinner color="black" />
        </SpinnerWrap>
      </PlaceholderWrap>
    );
  }

  return (
    <>
      {/* {balance > 0 && <TokenCard item={data[0]} />} */}
      {balance > 0 && (
        <InventoryTokens
          title={_toolbarTitle}
          data={data}
          filters={filters}
          filterGetters={filterGetters}
          renderCard={(item) => (
            <TokenCard
              onClick={() => handleSelectLand(item)}
              image={item.image}
              name={item.name}
              showClass={false}
              rightAttribute={{
                title: "Class",
                value: landType[item.landType]
              }}
              leftAttribute={{
                title: "Rarity",
                value: rarity[item.rarity]
              }}
            />
          )}
        />
      )}
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title={selectedToken?.name}
        size="md"
        subtitle={
          selectedToken?.landType
            ? `${landType[selectedToken?.landType]} - ID: ${
              selectedToken?.tokenID
            }`
            : `ID: ${selectedToken?.tokenID}`
        }
        classIcon={
          selectedToken?.landType &&
          landType[selectedToken?.landType].toLowerCase()
        }
      >
        <ImgWrap src={selectedToken?.image} />
      </Modal>
      {balance === 0 && (
        <PlaceholderWrap>
          <Typography tag={"h3"} variant={"textLg"} color="gray300">
            🦄 Oh, no lands yet? 🌈
          </Typography>
          <Typography tag={"h3"} variant={"textMd"} color="gray300">
            Create your unicorn haven by claiming mystical lands from Marketplace or
            mint your own from our land vending machine.
          </Typography>
          <a
            href={BUY.MARKETPLACE.UNICORN_LAND}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button block={true} prependIcon="cart">
              Visit Marketplace
            </Button>
          </a>
        </PlaceholderWrap>
      )}
    </>
  );
}
