import styled from "styled-components";

export const TrapezoidBox = styled.div`
  position: relative;
  z-index: 1;
  ::before,
  ::after {
    // background shape using skew to make trapezoid
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: ${({ $color }) => $color};

    transform-origin: bottom;
    z-index: -1;
  }
  ::before {
    //right corner
    border-radius: 15px 5px 15px 15px;
    border-right: 0;
    left: 0;
    transform: skew(-30deg);
  }
  ::after {
    //left corner
    border-radius: 5px 15px 15px 15px;
    border-left: 0;
    right: 0;
    transform: skew(30deg);
  }
`;
