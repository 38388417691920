import React, { useRef } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { FreeMode, Pagination } from "swiper";
import { device } from "../../theme/mediaQuery";
import { RewardCard } from "./RewardCard";
import hexButton from "../../img/player/hex-button.svg";

const RewardCarouselWrap = styled.div`
  position: relative;

  height: 380px;
  @media (${device.xl}) {
    height: unset;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #aea7ca;

    &.swiper-button-disabled {
      opacity: 0.5;
    }

    &::after {
      font-size: 20px;
    }
  }

  .swiper-button-next {
    right: 10px;
  }

  .swiper-button-prev {
    left: 10px;
  }

  .swiper-pagination-bullet {
    background: #aea7ca;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: #4730a6;
  }

  .swiper-button-next,
  .swiper-button-prev,
  .swiper-pagination {
    display: block;
    @media (${device.xl}) {
      display: none;
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: 0;
  }
`;

const SwiperWrap = styled(Swiper)`
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const SwiperSlideWrap = styled(SwiperSlide)`
  height: auto;
  transition: filter 0.4s;

  @media (${device.xl}) {
    filter: blur(2px);
  }

  &.swiper-slide-active {
    filter: blur(0);

    @media (${device.xl}) {
      & > div {
        margin: 0 auto;
      }
    }
  }

  &.swiper-slide-prev,
  &.swiper-slide-next {
    @media (${device.xl}) {
      filter: blur(0);
    }
  }

  &.swiper-slide-prev {
    @media (${device.xl}) {
      & > div {
        margin-right: auto;
      }
    }
  }
  &.swiper-slide-next {
    @media (${device.xl}) {
      & > div {
        margin-left: auto;
      }
    }
  }
`;

const SwiperButtonsWrap = styled.div`
  display: flex;
  justify-content: space-around;
  z-index: 1;
  gap: 32px;
  max-width: 400px;
  margin: 24px auto 0;
  display: none;

  @media (${device.xl}) {
    display: flex;
    margin: 0;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 104%;
    max-width: unset;
  }

  & svg {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(281deg)
      brightness(101%) contrast(104%);
  }
`;

const HexButton = styled.button`
  background: url(${hexButton}) no-repeat center center;
  background-size: contain;
  border: none;
  width: 53px;
  height: 53px;
  cursor: pointer;
  filter: drop-shadow(0px 12px 32px rgba(183, 208, 225, 0.5));
  transition: filter 0.3s;
  &:hover {
    filter: drop-shadow(0px 12px 32px rgba(183, 208, 225, 0.6)) brightness(1.1);
  }
  &.prev {
    transform: scaleX(-1);
  }
`;

// const rewards = Array.from({ length: 28 }, () => ({
//   name: `10 nebula shadowcorn lootboxes`,
//   img: reward,
//   id: 1234,
// }));

export function RewardCarousel({ network, data, ...rest }) {
  const swiperRef = useRef();
  // const rewards = data?.dropper?.dropper || [];
  const rewards = network ? data?.eventsArr : data?.dropper?.dropper;
  return (
    <RewardCarouselWrap {...rest}>
      <SwiperWrap
        spaceBetween={16}
        slidesPerView={1.1}
        breakpoints={{
          400: {
            slidesPerView: 1.5,
            spaceBetween: 16,
          },
          576: {
            slidesPerView: 1.75,
            spaceBetween: 16,
          },
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        slideVisibleClass="swiper-slide-visible"
        modules={[FreeMode, Pagination]}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        centeredSlides={true}
        grabCursor={true}
        loop={true}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {rewards.map((reward, i) => (
          <SwiperSlideWrap key={reward.name + "-reward-" + i}>
            <RewardCard
              name={reward.name}
              // description={reward.description}
              img={reward.image}
              amount={reward}
            />
          </SwiperSlideWrap>
        ))}
      </SwiperWrap>
      <SwiperButtonsWrap>
        <HexButton
          className="prev"
          onClick={() => swiperRef.current?.slidePrev()}
        />

        <HexButton
          className="next"
          onClick={() => swiperRef.current?.slideNext()}
        />
      </SwiperButtonsWrap>
    </RewardCarouselWrap>
  );
}
