import React, { useState } from "react";
import styled from "styled-components";
import { device } from "../../theme/mediaQuery";
import { Typography } from "../base/Typography";
import { DropDown, DropDownItem, DropDownMenu } from "../base/Dropdown";
import { Icon } from "../base/Icon";
import { TOKEN } from "../../views/Bridge";

const TokenFieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const InputWrap = styled(DropDown)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 12px 16px;
  background: #3e0f94;
  border-radius: ${({ $show }) => ($show ? "0 0 8px 8px" : "8px")};
  transition: border-radius 0.3s ease-in-out;

  @media (${device.lg}) {
    border-radius: ${({ $show }) => ($show ? "8px 8px 0 0" : "8px")};
  }

  &:hover {
    background: rgba(62, 15, 148, 0.9);
  }
`;

const Token = styled.img`
  width: 28px;
  height: 28px;
`;

const ToggleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

const IconWrap = styled(Icon)`
  & svg {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(170deg)
      brightness(104%) contrast(103%);
  }
`;

const DropDownMenuWrap = styled(DropDownMenu)`
  background: #3e0f94 !important;
  border-top: 0;
  border-radius: 8px 8px 0 0;
  padding: 0;
  width: 100%;
  gap: 0;
  @media (${device.lg}) {
    border-radius: 0 0 8px 8px;
  }
`;

const DropDownItemWrap = styled(DropDownItem)`
  color: #ffffff !important;
  border-top: 1px solid #5338c2;

  padding: 8px 16px;
  gap: 12px;

  &:hover {
    background: #48228c !important;
    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }
  }
`;

export const DropDownField = ({ title, onSelect, token }) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => {
    setShow((prevState) => !prevState);
  };
  const handleSelect = (tokenId) => {
    setShow(false);
    onSelect(tokenId);
  };

  return (
    <TokenFieldWrap>
      <Typography tag="span" color="#C9ABFF">
        {title}:
      </Typography>
      <InputWrap $show={show}>
        <Token src={token.img} />
        <ToggleWrap onClick={handleToggle}>
          <Typography
            family="display"
            variant="textLg"
            lg={{
              variant: "textXl",
            }}
            xl={{
              variant: "displayXs",
            }}
          >
            {token.name}
          </Typography>
          <IconWrap name="arrow-down" />
        </ToggleWrap>
        <DropDownMenuWrap show={show} onClose={() => setShow(false)}>
          {Object.values(TOKEN).map((token) => (
            <DropDownItemWrap
              key={`token-${token.id}`}
              onClick={() => handleSelect(token.id)}
            >
              <Token src={token.img} />
              <Typography
                family="display"
                variant="textLg"
                lg={{
                  variant: "textXl",
                }}
                xl={{
                  variant: "displayXs",
                }}
              >
                {token.name}
              </Typography>
            </DropDownItemWrap>
          ))}
        </DropDownMenuWrap>
      </InputWrap>
    </TokenFieldWrap>
  );
};
