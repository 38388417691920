import React, { useMemo } from "react";
import styled from "styled-components";
import { device } from "../../theme/mediaQuery";
import { PlayerCard } from "./PlayerCard";
import { Button } from "../base/Button";
import { Box } from "../base/Box";
import { Typography } from "../base/Typography";
import { Link } from "react-router-dom";
import { Icon } from "../base/Icon";
import balloon from "../../img/player/balloon.png";
import daily from "../../img/player/daily.png";
import jousting from "../../img/player/jousting.png";
import questing from "../../img/player/questing.png";
import shadowcorn from "../../img/player/shadowcorn.png";
import xai from "../../img/currencies/xai-white.png";
import { useAtomValue, useSetAtom } from "jotai";
import { walletConnectedAtom } from "../../store";
import { WHITE_PAPER } from "../../consts/externalLinks";
import { useClaimRewardsArb, useGetClaimsArb } from "../../query/claim";
import { useActiveWalletChain } from "thirdweb/react";
import { defineChain } from "thirdweb";
import { genericErrorModalAtom } from "../../store/staking";

const PlayerCardWrap = styled(PlayerCard)`
  gap: 40px;
`;

const P2AWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

function formatValue(value) {
  const num = Number(value);
  console.log("num: ", num);

  if (isNaN(num)) {
    return "0";
  }

  if (num === 0) {
    return "0";
  }

  if (num < 0.01) {
    return "<0.01";
  }

  return num.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
}

export const P2A = ({ switchNetwork, chain }) => {
  const walletConnected = useAtomValue(walletConnectedAtom);
  const setGenericErrorModal = useSetAtom(genericErrorModalAtom);
  const chainId = useActiveWalletChain();
  const { data, isLoading } = useGetClaimsArb();
  const disable = data?.p2aArr?.length === 0;
  const claimMutation = useClaimRewardsArb(data?.batchInputP2a);
  const wrongChain = chainId?.id !== chain?.id;

  const rows = useMemo(
    () => [
      {
        title: "Jousting",
        subtitle:
          "Enter the Arena and prove your might! Climb the ranks each weekend to earn more rewards.",
        points: formatValue(data?.joustingTotal),
        link: WHITE_PAPER.AIRDROP,
        img: jousting,
      },
      {
        title: "Questing",
        subtitle:
          "Send unicorns on adventures deep into the dark forest for bigger rewards from tougher quests.",
        points: formatValue(data?.questingTotal),
        link: WHITE_PAPER.AIRDROP,
        img: questing,
      },

      {
        title: "Daily Login and Crafting",
        subtitle:
          "Log in daily and complete a craft to earn your share of rewards!",
        points: formatValue(data?.dailyTotal),
        link: WHITE_PAPER.AIRDROP,
        img: daily,
      },
      {
        title: "Shadowcorn Supremacy",
        subtitle:
          "Build a powerful army of Shadowcorns and Minions; the higher your Supremacy Score, the more rewards you receive.",
        points: formatValue(data?.shadowcornTotal),
        link: WHITE_PAPER.AIRDROP,
        img: shadowcorn,
      },
    ],
    [data]
  );

  const handleClaim = async () => {
    claimMutation.mutate(null, {
      onSuccess: (result) => {
        console.log("result", result);
      },
      onError: () => {
        setGenericErrorModal({
          show: true,
        });
      },
    });
  };

  return (
    <>
      <PlayerCardWrap
        title="P2A Daily Rewards"
        isLoading={isLoading}
        tag="limited time rewards!"
        button={
          walletConnected ? (
            <Button
              disabled={wrongChain ? false : disable}
              onClick={() =>
                wrongChain ? switchNetwork(chain) : handleClaim()
              }
              variant={wrongChain ? "primary" : "secondary"}
              className="p-0">
              {wrongChain ? "Switch Network" : "Claim"}
            </Button>
          ) : null
        }>
        <P2AWrap>
          {rows.map((row, i) => (
            <P2ARow key={`p2a-${i}`} {...row} />
          ))}
        </P2AWrap>
        <EarnMore
          subtitle="Earn more by participating on the Play 2 Airdrop leaderboard!"
          buttonText="Visit Play2Airdrop"
        />
      </PlayerCardWrap>
    </>
  );
};

const RowWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;

  @media (${device.sm}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const P2ARowContent = styled.div`
  display: flex;
  align-items: flex-start;
  @media (${device.md}) {
    align-items: center;
  }
  gap: 12px;
`;

const P2AImg = styled.img`
  width: 38px;
  height: 38px;
  @media (${device.md}) {
    width: 50px;
    height: 50px;
  }
  @media (${device.lg}) {
    width: 64px;
    height: 64px;
  }
`;

const RowTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LinkWrap = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.display};
  font-weight: 400;
  color: #1f85e6;
  transition: color 0.1s ease-in-out;
  padding-top: 4px;

  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    color: ${({ theme }) => theme.palette.blue300};
  }

  & svg {
    fill: ${({ theme }) => theme.palette.blue300};
  }
`;

const PointsTag = styled.div`
  display: flex;
  padding: 7px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fefefe;
  box-shadow: 0px 4px 4px 0px rgba(66, 66, 209, 0.1);
  margin-top: 4px;
  @media (${device.md}) {
    margin-top: 0;
  }
`;

const XaiIcon = styled.img`
  width: 18px;
  height: 18px;
  @media (${device.lg}) {
    width: 30px;
    height: 30px;
  }
`;

const PointsValue = styled(Typography)``;

const P2ARow = ({ title, subtitle, points, img, link }) => {
  return (
    <RowWrap>
      <P2ARowContent>
        <P2AImg src={img} alt={title} />
        <RowTextWrap>
          <Typography
            tag="h3"
            variant="textLg"
            md={{
              variant: "textXl",
            }}
            color="#3334B9">
            {title}
          </Typography>
          <Typography
            tag="p"
            variant="textXs"
            md={{
              variant: "textSm",
            }}
            color="#343434">
            {subtitle}
          </Typography>
          <LinkWrap to={link} target="_blank" rel="noopener noreferrer">
            Learn More <Icon name="bold-arrow-right" />
          </LinkWrap>
          <PointsTag className="d-flex d-md-none">
            <XaiIcon src={xai} alt="Xai" />
            <PointsValue
              tag="span"
              variant="textSm"
              md={{
                variant: "textMd",
              }}
              weight={700}
              color="#343434">
              {points}
            </PointsValue>
          </PointsTag>
        </RowTextWrap>
      </P2ARowContent>
      <PointsTag className="d-none d-md-flex">
        <XaiIcon src={xai} alt="Xai" />
        <PointsValue
          tag="span"
          variant="textSm"
          md={{
            variant: "textMd",
          }}
          weight={700}
          color="#343434">
          {points}
        </PointsValue>
      </PointsTag>
    </RowWrap>
  );
};

const EarnBoxWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 18px;
  gap: 12px 24px;
  @media (${device.sm}) {
    align-items: center;
  }
  @media (${device.md}) {
    align-items: center;
    flex-direction: row;
    padding: 20px 40px;
  }

  & a * {
    font-size: 18px;
    white-space: nowrap;
    @media (${device.lg}) {
      font-size: 21px;
    }
    @media (${device.xxl}) {
      font-size: 23px;
    }
  }
`;

const EarnImg = styled.img`
  position: absolute;
  height: 100px;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);

  @media (${device.md}) {
    height: 120px;
    height: 120px;
    left: -20px;

    top: 50%;
    transform: translateY(-50%);
  }
  @media (${device.xl}) {
    height: 135px;
    left: 15px;
  }
  @media (${device.xxl}) {
    left: 25px;
  }
`;

const ContentWrap = styled.div`
  flex-shrink: 1;
  display: flex;
  gap: 12px;
  align-items: center;
  flex-direction: column;

  @media (${device.sm}) {
    flex-direction: row;
  }
`;

const TextWrap = styled.div`
  text-align: center;
  padding-top: 65px;
  @media (${device.sm}) {
    text-align: unset;
  }
  @media (${device.md}) {
    padding-top: 0;
    padding-left: 60px;
  }
  @media (${device.xl}) {
    padding-left: 115px;
  }
`;

const EarnMore = ({ title, subtitle, buttonText }) => {
  return (
    <EarnBoxWrap
      variant="gradient"
      gradient="#3A9FFF"
      pattern="dots-reverse"
      patternSize="100%"
      patternOpacity="0.5"
      rounded="md">
      <EarnImg src={balloon} alt="" />
      <ContentWrap>
        <TextWrap>
          {title && (
            <Typography
              tag={"h3"}
              variant={"textXl"}
              md={{
                variant: "displaySm",
              }}
              textshadow="soft"
              className="d-flex flex-column justify-content-center">
              Title
            </Typography>
          )}
          {subtitle && (
            <Typography
              tag={"p"}
              variant={"textSm"}
              weight={600}
              md={{
                variant: "textMd",
              }}
              textshadow="soft">
              {subtitle}
            </Typography>
          )}
        </TextWrap>
      </ContentWrap>
      <Button as={Link} size="lg" to="/airdrop">
        {buttonText}
      </Button>
    </EarnBoxWrap>
  );
};
