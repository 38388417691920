import { StakingCommonCard, StakingCommonCardHeader, StakingCommonCardTitle } from "../StakingCommonCard";
import styled from "styled-components";
import { device } from "../../../theme/mediaQuery";
import Spinner from "../../base/Spinner";

const HeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 6px;
  padding: 10px 20px;
  height: 100%;
  text-align: center;

  @media (${device.lg}) {
    flex-direction: row;
    align-items: center;
    padding: 0 40px;
    gap: 10px;
    text-align: left;
  }
`;

const ActionsWrap = styled.div`
  & button {
    padding: 0;
  }
`;

const Tip = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};

  @media (${device.lg}) {
    font-size: 20px;
    margin-left: auto;
  }
`;

const BodyWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 16px;

  @media (${device.lg}) {
    padding: 20px;
  }

  @media (${device.xl}) {
    grid-template-columns: ${({ $fill }) => $fill ? "1fr" : "1fr 1fr"};
  }

  @media (${device.xl}) {
    padding: 40px;
    gap: 40px;
  }
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 400px;
`;

export function StakingRewardsSection({
  title,
  actions,
  tip,
  bodyFill,
  children,
  isLoading,
}) {
  return (
    <StakingCommonCard>
      <StakingCommonCardHeader>
        <HeadingWrap>
          <StakingCommonCardTitle>
            {title}
          </StakingCommonCardTitle>
          {tip && (
            <Tip>
              {tip}
            </Tip>
          )}
          <ActionsWrap>
            {actions}
          </ActionsWrap>
        </HeadingWrap>
      </StakingCommonCardHeader>
      {!isLoading && (
        <BodyWrap
          $fill={bodyFill}
        >
          {children}
        </BodyWrap>
      )}
      {isLoading && (
        <LoadingWrap>
          <Spinner />
        </LoadingWrap>
      )}
    </StakingCommonCard>
  );
}