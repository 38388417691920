import React from "react";
import styled from "styled-components";
import { Icon } from "../../base/Icon";
import { device } from "../../../theme/mediaQuery";
import { useSetChain } from "@web3-onboard/react";
import Web3 from "web3";
import { NetworkConfiguration } from "../../Network";
import { POLYGON_NETWORK } from "../../ConnectWallet/WalletHelper";
import { NetworkName, ShowOnNetwork } from "../../common/ShowOnNetwork";

const StakingWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #fca1e6 0%, #ca3c93 100%);
  padding: 12px;
  gap: 6px 20px;

  position: sticky;
  top: 0;
  z-index: 10;

  @media (${device.md}) {
    padding: 12px 24px;
    flex-direction: row;
  }

  @media (${device.lg}) {
    flex-direction: row;
    justify-content: center;
    gap: 30px;
  }

  & p {
    text-align: center;
    @media (${device.md}) {
      text-align: left;
    }
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.palette.white};
  font-size: 1rem;
  font-weight: bold;
`;

const ActionLink = styled.div`
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 4px;
  cursor: pointer;
  white-space: nowrap;

  & * {
    fill: ${({ theme }) => theme.palette.white};
  }

  &:hover {
    color: rgba(88, 40, 164, 0.7);

    & * {
      fill: rgba(88, 40, 164, 0.7);
    }
  }
`;

export function NetworkBanner() {
  const [, setChain] = useSetChain();
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const arbitrumId = Web3.utils.toHex(netconfig.networkIdArbitrum);

  const handleNetworkSwitch = async () => {
    try {
      await setChain({ chainId: arbitrumId });
    } catch (error) {
      console.warn(`Network switching error`);
    }
  };

  return (
    <ShowOnNetwork network={NetworkName.POLYGON}>
      <StakingWrap>
        <Description>
          This page required being on the Arbitrum network. To ensure proper
          functionality, please switch networks.
        </Description>
        <ActionLink onClick={handleNetworkSwitch}>
          Change Network <Icon color="" name="full-arrow-right" size="24px" />
          {/* TODO; Add Arbitrum if they don't have it */}
        </ActionLink>
      </StakingWrap>
    </ShowOnNetwork>
  );
}
