import styled from "styled-components";
import { NavMenuSectionLink } from "./NavMenuSectionLink";
import { COMMUNITY, WHITE_PAPER } from "../../consts/externalLinks";

const Wrap = styled.nav`
  padding: 32px 26px;
`;

export function NavHelpMenu() {
  return (
    <Wrap>
      <NavMenuSectionLink
        title="ZenDesk"
        subtitle="Game issues? Our Zendesk and support team have your back."
        path={COMMUNITY.ZENDESK}
        target="_blank"
      />
      {/* need the link */}
      <NavMenuSectionLink
        title="learn about wallets"
        subtitle="Get support from our Discord channel and community managers"
        path={WHITE_PAPER.STEP_1}
        target="_blank"
      />
    </Wrap>
  );
}
