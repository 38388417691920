import Web3 from "web3";
import CU_ABI from "../CU_ERC721_ABI.json";
import LAND_ABI from "../CU_Land_ABI.json";
import { Network } from "../components/Network";
import ERC20_ABI from "../CU_ERC20_ABI.json";
import { ethers } from "ethers";

export function getUnicornContract(wallet, address) {
  const web3 = new Web3(wallet.provider);

  const contract = new web3.eth.Contract(CU_ABI, Network.CUContractAddress);

  return {
    tokenURI(tokenId) {
      return contract.methods.tokenURI(tokenId).call();
    },
  };
}

export function getLandContract(wallet, address) {
  const web3 = new Web3(wallet.provider);

  const contract = new web3.eth.Contract(LAND_ABI, Network.CULandAddress);

  return {
    tokenURI(tokenId) {
      return contract.methods.tokenURI(tokenId).call();
    },
    getMetadata(tokenId) {
      return contract.methods.getLandMetaData(tokenId).call();
    },
    balanceOf(address) {
      return contract.methods.balanceOf(address).call();
    },
    tokenOfOwnerByIndex(address, index) {
      return contract.methods.tokenOfOwnerByIndex(address, index).call();
    },
  };
}

export function getERC20Contract(wallet, contractAddress, rpcUrl = null) {
  // if rpcUrl is provided, use it, otherwise use the wallet provider
  if (wallet) {
    const contract = new ethers.Contract(contractAddress, ERC20_ABI, wallet);

    return {
      balanceOf(address) {
        return contract.balanceOf(address);
      },
    };
  } else {
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
    const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider);

    return {
      balanceOf(address) {
        return contract.balanceOf(address);
      },
    };
  }
}
