import {
  createGlobalStyle,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components";
import { commonPalette, gradients, textshadows } from "./colors";
import { commonComponents } from "./components";
import { commonTypography } from "./typography";
import { commonFonts } from "./font";
import { commonPatterns } from "./pattern";
import InsanibcEOT from "../fonts/Insanibc.eot";
import InsanibcWOFF from "../fonts/Insanibc.woff";
import InsanibcTTF from "../fonts/Insanibc.ttf";
import InsanibcSVG from "../fonts/Insanibc.svg";

import LTRemarkOTF from "../fonts/LTRemark.otf";

function getTheme() {
  return {
    palette: {
      ...commonPalette,
    },
    components: {
      ...commonComponents,
    },
    typography: {
      ...commonTypography,
    },
    fonts: {
      ...commonFonts,
    },
    pattern: {
      ...commonPatterns,
    },
    gradients: {
      ...gradients,
    },
    textshadows: {
      ...textshadows,
    },
  };
}

export default function ThemeProvider({ children }) {
  const themeObject = getTheme();
  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

export const ThemeGlobalStyles = createGlobalStyle`
  
  @font-face {
    font-family: "Insanibc";
    src: url(${InsanibcEOT});
    src: local("☺"), url(${InsanibcWOFF}) format("woff"),
    url(${InsanibcTTF}) format("truetype"),
    url(${InsanibcSVG}) format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "LTRemark";
    src: url(${LTRemarkOTF}) format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  * {
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body, html {
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5 {
    font-family: "Insanibc", sans-serif;
    margin: 0;
  }

  p {
    margin: 0;
  }

  *, *:after, *:before {
    box-sizing: border-box;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

`;
