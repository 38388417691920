import { Button } from "../base/Button";

export function StakingViewTransactionButton({ hash, ...rest }) {
  // get transaction link from hash to polugon scan
  const transactionLink = `https://arbiscan.io/tx/${hash}`;
  return (
    <Button
      {...rest}
      as="a"
      href={transactionLink}
      target="_blank"
      rel="noopener noreferrer"
    />
  );
}