import styled, { keyframes } from "styled-components";
import { device } from "../../../theme/mediaQuery";
import { useRef } from "react";
import { StakingPaginatedList } from "../StakingPaginatedList";
import {
  StakingRewardsBox,
  StakingRewardTokenAvatar,
} from "./StakingRewardsCard";
import { FormattedAmount } from "../../common/FormattedAmount";
import { StakingRewardsClaimEscrowedButton } from "./StakingRewardsEscrowedButton";
import { useGetTokenUSDPrice } from "../../../query/vault";
import Web3 from "web3";
import { formatStringWithFixedFraction } from "../../Helpers";
import { ethers } from "ethers";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableHeadingWrap = styled.div`
  height: 64px;
  display: none;
  align-items: center;
  padding: 0 20px;
  gap: 20px;
  background: #7931b1;
  white-space: nowrap;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  @media (${device.xl}) {
    padding: 0 50px;
    text-align: center;
    display: flex;
    gap: 35px;
  }
`;

const TableHeadingMobileWrap = styled(TableHeadingWrap)`
  display: flex;

  @media (${device.xl}) {
    display: none;
  }
`;

export const TableHeading = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.display};
  color: ${({ theme }) => theme.palette.white};
  flex: ${({ $flex }) => $flex || 1};
  text-shadow: 1px 4px 0px #0000004d;
  text-align: ${({ $textAlign }) => $textAlign || "center"};
  @media (${device.xl}) {
    font-size: 20px;
  }
`;

const BodyWrap = styled.div`
  padding: 20px 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #17042c;
  margin-bottom: 20px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  @media (${device.lg}) {
    padding: 20px;
  }
`;

export const TableRowWrap = styled(StakingRewardsBox)`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  border-radius: 16px;
  gap: 20px;

  @media (${device.xl}) {
    align-items: center;
    flex-direction: row;
    min-height: 92px;
    padding: 0 30px;
    gap: 35px;
  }
`;

const appearKeyframes = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

export const TableCellWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  flex: 1;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
  line-height: 1;
  flex: ${({ $flex }) => $flex || 1};
  text-align: ${({ $textAlign }) => $textAlign || "center"};
  animation: ${appearKeyframes} 0.5s ease;

  @media (${device.xs}) {
    & button * {
      font-size: 14px;
    }
  }

  @media (${device.md}) {
    & button * {
      font-size: 25px;
    }

    & button {
      margin: 0;
    }
  }

  @media (${device.xxl}) {
    align-items: initial;
    & button * {
      white-space: nowrap;
    }
  }
`;

const TableCellMobileTitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.display};
  text-shadow: 1px 4px 0px #0000004d;

  @media (${device.xl}) {
    display: none;
  }
`;

const PrependRowWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const PrependRowTip = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white};
  font-size: 17px;
  border-bottom: 1px solid #5338c2;
  padding: 30px 0;
`;

export function StakingRewardsTable({ items, xaiReward = null }) {
  const headingRef = useRef(null);

  const scrollToHeading = () => {
    // we need to scroll after the state is updated
    // so we use setTimeout with 0 delay
    setTimeout(() => {
      headingRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return (
    <Wrap>
      <TableHeadingWrap ref={headingRef}>
        <TableHeading $flex={1.5}>Reward Type</TableHeading>
        <TableHeading $textAlign="right">Amount Claimable</TableHeading>
        <TableHeading $textAlign="right">Dollar value</TableHeading>
        <TableHeading $flex={2}>Action</TableHeading>
      </TableHeadingWrap>
      <TableHeadingMobileWrap>
        <TableHeading>escrowed rewards</TableHeading>
      </TableHeadingMobileWrap>
      <StakingPaginatedList
        WrapComponent={BodyWrap}
        onPageChange={scrollToHeading}
        items={items}
        pageSize={6}
        renderRow={(reward, index) => (
          <TableRow
            key={index + reward.token + reward.amount + reward.end}
            reward={reward}
          />
        )}
      />
    </Wrap>
  );
}

function TableCell({ token, children, title, ...rest }) {
  return (
    <TableCellWrap {...rest}>
      {title && <TableCellMobileTitle>{title}</TableCellMobileTitle>}
      {children}
    </TableCellWrap>
  );
}

function TableRow({ reward, ...rest }) {
  const { token, amount } = reward;
  const usdPriceQuery = useGetTokenUSDPrice(token);
  const usdPrice = usdPriceQuery.data;

  const amountInEth = ethers.utils.formatEther(amount);
  const amountInUsd = amountInEth * usdPrice;

  return (
    <TableRowWrap token={token} {...rest}>
      <TableCell $flex={1.5}>
        <StakingRewardTokenAvatar token={token} />
      </TableCell>
      <TableCell $textAlign="right" title="Amount">
        <FormattedAmount wei={amount} maxFractionDigits={4} />
      </TableCell>
      <TableCell $textAlign="right" title="Dollar value">
        {usdPrice
          ? `$${formatStringWithFixedFraction(amountInUsd, null, 4)}`
          : "-"}
      </TableCell>
      <TableCell $flex={2}>
        <StakingRewardsClaimEscrowedButton reward={reward} />
      </TableCell>
    </TableRowWrap>
  );
}
