import {
  getBadgeManagerContract,
  getCUContract,
  getCULPContract,
  getRewardContract,
  getStakingContract,
  getViewContract,
} from "./contract";
import { Network, NetworkConfiguration } from "../Network";
import { POLYGON_NETWORK } from "../ConnectWallet/WalletHelper";
import { alchemy } from "../../services/alchemy";
import { activeBadgeList, months, rewardsArray } from "./constants";

function parseData(rawData, cuAddress, culpAddress) {
  const { pendingRewards, pools, escrowPool, totalWeight } = rawData;

  const parsedPools = {
    CULP: {},
    CU: {},
  };

  pools.forEach((pool) => {
    const {
      poolAddress,
      totalPoolShares,
      depositToken,
      accountPendingRewards,
      accountClaimedRewards,
      accountTotalDeposit,
      accountPoolShares,
      weight,
      deposits,
    } = pool;

    const parsedDeposits = deposits.map((deposit) => ({
      amount: deposit.amount,
      start: deposit.start,
      end: deposit.end,
      multiplier: deposit.multiplier,
    }));

    if (poolAddress.toLowerCase() === culpAddress.toLowerCase()) {
      parsedPools.CULP = {
        poolAddress,
        totalPoolShares,
        depositToken,
        accountPendingRewards,
        accountClaimedRewards,
        accountTotalDeposit,
        accountPoolShares,
        weight,
        deposits: parsedDeposits,
      };
    } else if (poolAddress.toLowerCase() === cuAddress.toLowerCase()) {
      parsedPools.CU = {
        poolAddress,
        totalPoolShares,
        depositToken,
        accountPendingRewards,
        accountClaimedRewards,
        accountTotalDeposit,
        accountPoolShares,
        weight,
        deposits: parsedDeposits,
      };
    }
  });

  const parsedEscrowPool = {
    poolAddress: escrowPool.poolAddress,
    totalPoolShares: escrowPool.totalPoolShares,
    depositToken: escrowPool.depositToken,
    accountPendingRewards: escrowPool.accountPendingRewards,
    accountClaimedRewards: escrowPool.accountClaimedRewards,
    accountTotalDeposit: escrowPool.accountTotalDeposit,
    accountPoolShares: escrowPool.accountPoolShares,
    weight: escrowPool.weight,
    deposits: escrowPool.deposits.map((deposit) => ({
      amount: deposit.amount,
      start: deposit.start,
      end: deposit.end,
      multiplier: deposit.multiplier,
    })),
  };

  return {
    viewData: {
      pendingRewards,
      pools: parsedPools,
      escrowPool: parsedEscrowPool,
      totalWeight,
    },
  };
}

export function convertAmountFromRawNumber(value, decimals = 18) {
  if (decimals === 8) {
    const result = parseFloat(value) / Math.pow(10, decimals);
    return result.toFixed(decimals);
  } else {
    const result = parseFloat(value) / Math.pow(10, decimals);
    return result.toFixed(decimals);
  }
}

// export async function getVaultData(wallet, userAddress) {
//   const netconfig = NetworkConfiguration[POLYGON_NETWORK];
//   const REWARD_PER_SECOND = convertAmountFromRawNumber(814027488411996000);
//   const SECONDS_PER_YEAR = 86400 * 30 * 12;
//   const MAX_LOCKED_BOOSTED = 4;
//   const MIN_LOCKED_BOOSTED = 1 + (3 * 1) / 12;

//   const cuStakingContract = getStakingContract(
//     wallet,
//     netconfig.Arbitrum.CUStakingContractAddress,
//     userAddress
//   );
//   const culpStakingContract = getStakingContract(
//     wallet,
//     netconfig.Arbitrum.CULPStakingContractAddress,
//     userAddress
//   );
//   const cuContract = getCUContract(wallet);
//   // const rewardContract = getRewardContract(wallet);
//   const viewContract = getViewContract(wallet, userAddress);
//   const culpContract = getCULPContract(wallet);
//   const view = await viewContract.fetchData();
//   const parsedData = parseData(
//     view,
//     netconfig.Arbitrum.CUStakingContractAddress,
//     netconfig.Arbitrum.CULPStakingContractAddress
//   );
//   //get rbw data
//   const sCUBalance = await cuStakingContract.balanceOf();
//   const totalStakedCU = await cuContract.balanceOf(
//     netconfig.Arbitrum.CUStakingContractAddress
//   );

//   const totalStakedCUParsed = (totalStakedCU / 10 ** 18).toFixed(2);

//   const shareOfOwnership =
//     (parsedData.viewData?.pools.CU.accountTotalDeposit / totalStakedCU) * 100;
//   const cuPriceHistory = await fetch(
//     "  https://coins.llama.fi/chart/arbitrum:0x89C49A3fA372920aC23ce757A029e6936c0b8e02?span=14&period=1d&searchWidth=600"
//   ).then((res) => res.json());

//   const cuStakingContractTotalSupply = await cuStakingContract.totalSupply();
//   const minAPR = (
//     ((REWARD_PER_SECOND * SECONDS_PER_YEAR * 0.84104289318 * 10) /
//       (cuStakingContractTotalSupply / 10 ** 18)) *
//     MIN_LOCKED_BOOSTED
//   ).toFixed(0);
//   const maxAPR = (
//     ((REWARD_PER_SECOND * SECONDS_PER_YEAR * 0.84104289318 * 10) /
//       (cuStakingContractTotalSupply / 10 ** 18)) *
//     MAX_LOCKED_BOOSTED
//   ).toFixed(0);
//   const baseAPR =
//     (REWARD_PER_SECOND * SECONDS_PER_YEAR * 0.84104289318 * 10) /
//     (cuStakingContractTotalSupply / 10 ** 18).toFixed(2);

//   let aprBoost = [];
//   for (let i = 0; i < months.length; i++) {
//     let boost =
//       (await cuStakingContract.getMultiplier(months[i].value)) / 10 ** 18;
//     let baseAPR =
//       (REWARD_PER_SECOND * SECONDS_PER_YEAR * 0.84104289318 * 10) /
//       (cuStakingContractTotalSupply / 10 ** 18);
//     let apr =
//       ((REWARD_PER_SECOND * SECONDS_PER_YEAR * 0.84104289318 * 10) /
//         (cuStakingContractTotalSupply / 10 ** 18)) *
//       boost;
//     aprBoost.push({
//       monthDuration: months[i].value,
//       monthName: months[i].label,
//       apr: apr.toFixed(0),
//       timeBoost: boost,
//       baseAPR: baseAPR.toFixed(2),
//     });
//   }

//   const cuBalance = await cuContract.balanceOf(userAddress);

//   //get lp data
//   const sCULPBalance = await culpStakingContract.balanceOf();
//   const totalStakedCULP = await culpContract.balanceOf(
//     netconfig.Arbitrum.CULPStakingContractAddress
//   );
//   const parsedTotalStakedCULP = (totalStakedCULP / 10 ** 18).toFixed(2);
//   const culpPrice = 100; //need to get this from uniswap
//   const totalValueStakedLP = parsedTotalStakedCULP * culpPrice;
//   const culpBalance = await culpContract.balanceOf(userAddress);
//   const userStakedValueLP =
//     (parsedData.viewData?.pools.CULP.accountTotalDeposit / 10 ** 18) *
//     culpPrice;

//   const minAPRLP = 23; // uses data from a different source than what we will be using
//   const maxAPRLP = 45; // uses data from a different source than what we will be using

//   //const stakedArr = await rbwStakingContract.getDepostisOF();
//   // // Returns an array of deposits
//   // // [0] rbw amount
//   // // [1] start
//   // // [2] end
//   // // [3] sRBW amount
//   //const totalStakedRBWUser = await rewardContract.getTotalDeposit();
//   // //rewardAddress is going to be the address of the reward ie. rbw
//   const withdrawableRewardsOf = await cuStakingContract.withdrawableRewardsOf(
//     netconfig.Arbitrum.CUContractAddress
//   );
//   // const withdrawableRewardsOfLP =
//   //   await rbwlpStakingContract.withdrawableRewardsOf(
//   //     "0x431cd3c9ac9fc73644bf68bf5691f4b83f9e104f"
//   //   );
//   const LPRewards = [];
//   for (let i = 0; i < rewardsArray.length; i++) {
//     const withdrawableRewardsOfLP =
//       await culpStakingContract.withdrawableRewardsOf(rewardsArray[i].address);
//     LPRewards.push({
//       amount: withdrawableRewardsOfLP,
//       name: rewardsArray[i].name,
//     });
//   }

//   const escrowedRewardArray = [];
//   for (let i = 0; i < rewardsArray.length; i++) {
//     const rewardContract = getRewardContract(
//       wallet,
//       rewardsArray[i].rewardContractAddress,
//       userAddress
//     );
//     const escrowedRewards = await rewardContract.getDepositsOf(userAddress);
//     // add name and index to the array
//     const updatedEscrowedRewards = escrowedRewards.map((reward, index) => ({
//       ...reward,
//       name: rewardsArray[i].name,
//       index: index,
//     }));
//     escrowedRewardArray.push(...updatedEscrowedRewards);
//   }

//   return {
//     ...parsedData,
//     CU: {
//       totalStakedCU: totalStakedCUParsed,
//       shareOfOwnership: shareOfOwnership,
//       cuPriceHistory: cuPriceHistory,
//       userStakedCU: parsedData.viewData?.pools.CU.accountTotalDeposit,
//       userSCU: sCUBalance,
//       minAPR: minAPR,
//       maxAPR: maxAPR,
//       baseAPR: baseAPR,
//       cuBalance: cuBalance,
//       aprArray: aprBoost,
//     },
//     CULP: {
//       userSRCULP: sCULPBalance,
//       userStakedCULP: parsedData.viewData?.pools.CULP.accountTotalDeposit,
//       totalStakedCULP: parsedTotalStakedCULP,
//       culpPrice: culpPrice,
//       totalValueStakedLP: totalValueStakedLP,
//       CULPBalance: culpBalance,
//       userStakedValue: userStakedValueLP,
//       minAPR: minAPRLP,
//       maxAPR: maxAPRLP,
//       // aprArray: aprBoost, //update this for arbitrum to use the LP contract
//     },
//     Rewards: {
//       withdrawableRewardsOf: [{ amount: withdrawableRewardsOf, name: "CU" }],
//       withdrawableRewardsOfLP: LPRewards,
//       escrowedRewards: escrowedRewardArray,
//     },
//   };
// }

export async function getVaultData(wallet, userAddress) {
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const REWARD_PER_SECOND = convertAmountFromRawNumber(814027488411996000);
  const SECONDS_PER_YEAR = 86400 * 30 * 12;
  const MAX_LOCKED_BOOSTED = 4;
  const MIN_LOCKED_BOOSTED = 1 + (3 * 1) / 12;

  const cuStakingContract = getStakingContract(
    wallet,
    netconfig.Arbitrum.CUStakingContractAddress,
    userAddress
  );
  const culpStakingContract = getStakingContract(
    wallet,
    netconfig.Arbitrum.CULPStakingContractAddress,
    userAddress
  );
  const cuContract = getCUContract(wallet);
  const viewContract = getViewContract(wallet, userAddress);
  const culpContract = getCULPContract(wallet);

  // Parallel fetches
  const [
    view,
    cuPriceHistory,
    sCUBalance,
    totalStakedCU,
    cuBalance,
    sCULPBalance,
    totalStakedCULP,
    culpBalance,
    withdrawableRewardsOf,
  ] = await Promise.all([
    viewContract.fetchData(),
    fetch(
      "https://coins.llama.fi/chart/arbitrum:0x89C49A3fA372920aC23ce757A029e6936c0b8e02?span=14&period=1d&searchWidth=600"
    ).then((res) => res.json()),
    cuStakingContract.balanceOf(),
    cuContract.balanceOf(netconfig.Arbitrum.CUStakingContractAddress),
    cuContract.balanceOf(userAddress),
    culpStakingContract.balanceOf(),
    culpContract.balanceOf(netconfig.Arbitrum.CULPStakingContractAddress),
    culpContract.balanceOf(userAddress),
    cuStakingContract.withdrawableRewardsOf(
      netconfig.Arbitrum.CUContractAddress
    ),
  ]);

  const parsedData = parseData(
    view,
    netconfig.Arbitrum.CUStakingContractAddress,
    netconfig.Arbitrum.CULPStakingContractAddress
  );

  const totalStakedCUParsed = (totalStakedCU / 10 ** 18).toFixed(2);
  const shareOfOwnership =
    (parsedData.viewData?.pools.CU.accountTotalDeposit / totalStakedCU) * 100;

  const cuStakingContractTotalSupply = await cuStakingContract.totalSupply();
  const baseAPR =
    (REWARD_PER_SECOND * SECONDS_PER_YEAR * 0.84104289318 * 10) /
    (cuStakingContractTotalSupply / 10 ** 18);
  const minAPR = (baseAPR * MIN_LOCKED_BOOSTED).toFixed(0);
  const maxAPR = (baseAPR * MAX_LOCKED_BOOSTED).toFixed(0);

  // Calculate APR Boost in parallel
  const aprBoostPromises = months.map(async (month) => {
    const boost =
      (await cuStakingContract.getMultiplier(month.value)) / 10 ** 18;
    const apr = (baseAPR * boost).toFixed(0);
    return {
      monthDuration: month.value,
      monthName: month.label,
      apr: apr,
      timeBoost: boost,
      baseAPR: baseAPR.toFixed(2),
    };
  });
  const aprBoost = await Promise.all(aprBoostPromises);

  const parsedTotalStakedCULP = (totalStakedCULP / 10 ** 18).toFixed(2);
  const culpPrice = 100; // need to get this from uniswap
  const totalValueStakedLP = parsedTotalStakedCULP * culpPrice;
  const userStakedValueLP =
    (parsedData.viewData?.pools.CULP.accountTotalDeposit / 10 ** 18) *
    culpPrice;

  const minAPRLP = 23; // uses data from a different source than what we will be using
  const maxAPRLP = 45; // uses data from a different source than what we will be using

  // Fetch LP rewards in parallel
  const LPRewardsPromises = rewardsArray.map(async (reward) => {
    const amount = await culpStakingContract.withdrawableRewardsOf(
      reward.address
    );
    return {
      amount: amount,
      name: reward.name,
    };
  });
  const LPRewards = await Promise.all(LPRewardsPromises);

  // Fetch escrowed rewards in parallel
  const escrowedRewardPromises = rewardsArray.map(async (reward) => {
    const rewardContract = getRewardContract(
      wallet,
      reward.rewardContractAddress,
      userAddress
    );
    const escrowedRewards = await rewardContract.getDepositsOf(userAddress);
    return escrowedRewards.map((escrowedReward, index) => ({
      ...escrowedReward,
      name: reward.name,
      index: index,
    }));
  });
  const escrowedRewardArray = (
    await Promise.all(escrowedRewardPromises)
  ).flat();

  return {
    ...parsedData,
    CU: {
      totalStakedCU: totalStakedCUParsed,
      shareOfOwnership: shareOfOwnership,
      cuPriceHistory: cuPriceHistory,
      userStakedCU: parsedData.viewData?.pools.CU.accountTotalDeposit,
      userSCU: sCUBalance,
      minAPR: minAPR,
      maxAPR: maxAPR,
      baseAPR: baseAPR.toFixed(2),
      cuBalance: cuBalance,
      aprArray: aprBoost,
    },
    CULP: {
      userSRCULP: sCULPBalance,
      userStakedCULP: parsedData.viewData?.pools.CULP.accountTotalDeposit,
      totalStakedCULP: parsedTotalStakedCULP,
      culpPrice: culpPrice,
      totalValueStakedLP: totalValueStakedLP,
      CULPBalance: culpBalance,
      userStakedValue: userStakedValueLP,
      minAPR: minAPRLP,
      maxAPR: maxAPRLP,
    },
    Rewards: {
      withdrawableRewardsOf: [{ amount: withdrawableRewardsOf, name: "CU" }],
      withdrawableRewardsOfLP: LPRewards,
      escrowedRewards: escrowedRewardArray,
    },
  };
}

export async function getVaultDataWithoutWallet() {
  const rpc = Network.ArbitrumRPC;
  const cuContract = getCUContract(null, rpc);
  const culpContract = getCULPContract(null, rpc);

  const totalStakedCU = await cuContract.balanceOf(
    Network.Arbitrum.CUStakingContractAddress
  );
  const totalStakedCUParsed = (totalStakedCU / 10 ** 18).toFixed(2);

  const totalStakedCULP = await culpContract.balanceOf(
    Network.Arbitrum.CULPStakingContractAddress
  );
  const parsedTotalStakedCULP = (totalStakedCULP / 10 ** 18).toFixed(2);

  return {
    CU: {
      totalStakedCU: totalStakedCUParsed,
    },
    CULP: {
      totalStakedCULP: parsedTotalStakedCULP,
    },
  };
}

// function filterUserTokens(userTokens, activeBadgeList) {
//   const validTokens = userTokens
//     .map((token) => {
//       const matchingToken = activeBadgeList[0].find(
//         (item) => item.tokenId === String(token.poolId)
//       );
//       if (matchingToken) {
//         return {
//           ...token,
//           boostFactor: matchingToken.boostFactor,
//         };
//       }
//       return null;
//     })
//     .filter(Boolean); // Remove null values

//   return validTokens;
// }
function filterUserTokens(userTokens, activeBadgeList) {
  const validTokens = userTokens
    .map((token) => {
      const matchingToken = activeBadgeList[0].find(
        (item) => item.tokenId === String(token.poolId)
      );

      if (matchingToken) {
        const updatedToken = {
          ...token,
          boostFactor: matchingToken.boostFactor,
        };
        if (
          parseInt(matchingToken.tokenId) >= 197 &&
          parseInt(matchingToken.tokenId) <= 215
        ) {
          const boostFactor = matchingToken.boostFactor;
          let rarity = "";

          if (boostFactor === 0.01) rarity = "Voting";
          else if (boostFactor === 0.05) rarity = "Common";
          else if (boostFactor === 0.075) rarity = "Uncommon";
          else if (boostFactor === 0.125) rarity = "Rare";
          else if (boostFactor === 0.2) rarity = "Mythic";

          updatedToken.attributes = [
            ...updatedToken.attributes,
            { trait_type: "Rarity", value: rarity },
          ];

          updatedToken.rarity = rarity;
        }

        return updatedToken;
      }

      return null;
    })
    .filter(Boolean); // Remove null values

  return validTokens;
}

function getTotalBoostFactor(array, address) {
  return array.reduce((totalBoost, item) => {
    if (item.delegated.toLowerCase() === address.toLowerCase()) {
      return totalBoost + parseFloat(item.boostFactor);
    }
    return totalBoost;
  }, 0);
}

export async function getVaultBadges(wallet, address) {
  // let address = wallet.accounts[0].address;
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  let badges = await alchemy.getUnicornItemsByWalletArb(address);
  //Check if a users badges are used in the staking pools
  const filteredBadges = filterUserTokens(badges, activeBadgeList);
  const cuBadgeContract = getBadgeManagerContract(
    wallet,
    netconfig.Arbitrum.badgeManagerCU,
    address
  );
  const culpBadgeContract = getBadgeManagerContract(
    wallet,
    netconfig.Arbitrum.badgeManagerCULP,
    address
  );

  //Returns a list of addresses that the user has delegated to
  const delegatedAddressArr = await cuBadgeContract.getDelegateByBadges(
    filteredBadges.map(() => address),
    filteredBadges.map(() => netconfig.Arbitrum.terminusContractAddress),
    filteredBadges.map((item) => item.poolId)
  );

  const delegatedAddressArrLP = await culpBadgeContract.getDelegateByBadges(
    filteredBadges.map(() => address),
    filteredBadges.map(() => netconfig.Arbitrum.terminusContractAddress),
    filteredBadges.map((item) => item.poolId)
  );
  //Add the delegated address to the badges
  const badgesLpWithDelegationAddress = delegatedAddressArrLP.map(
    (item, index) => {
      return {
        ...filteredBadges[index],
        delegated: item,
        delegatedFrom: address,
      };
    }
  );
  const badgesWithDelegationAddress = delegatedAddressArr.map((item, index) => {
    return {
      ...filteredBadges[index],
      delegated: item,
      delegatedFrom: address,
    };
  });
  // const allBadges = delegatedAddressArr.map((item, index) => {
  //   console.log("item", item);
  //   return {
  //     ...badgesLpWithDelegationAddress[index],
  //     delegatedLP: item,
  //     // delegatedFromLP: address,
  //   };
  // });

  const allBadges = badgesWithDelegationAddress.map((item, index) => {
    return {
      ...item,
      delegatedLP: badgesLpWithDelegationAddress[index].delegated,
    };
  });

  const delegatedBadgesCU = await cuBadgeContract.getDelegationsOfDelegate();
  const delegatedBadgesCULP =
    await culpBadgeContract.getDelegationsOfDelegate();

  let delegatedBadgeDataCU = [];
  let delegatedBadgeDataCULP = [];
  if (delegatedBadgesCU.length > 0) {
    let idArrCU = [];

    for (let i = 0; i < delegatedBadgesCU.length; i++) {
      if (delegatedBadgesCU[i].owner.toLowerCase() !== address.toLowerCase()) {
        idArrCU.push(Number(delegatedBadgesCU[i].badge.tokenId));
      }
    }
    if (idArrCU.length > 0) {
      let badgeDataCU = await alchemy.getBadgesById(idArrCU);
      let combinedDataCU = delegatedBadgesCU.map((item, index) => {
        return {
          ...badgeDataCU[index],
          delegated: address,
          delegatedFrom: item.owner,
        };
      });
      let addBoostFactor = filterUserTokens(combinedDataCU, activeBadgeList);
      delegatedBadgeDataCU.push(...addBoostFactor);
    }
  }
  if (delegatedBadgesCULP.length > 0) {
    let idArrCULP = [];

    for (let i = 0; i < delegatedBadgesCULP.length; i++) {
      if (
        delegatedBadgesCULP[i].owner.toLowerCase() !== address.toLowerCase()
      ) {
        idArrCULP.push(Number(delegatedBadgesCULP[i].badge.tokenId));
      }
    }
    if (idArrCULP.length > 0) {
      let badgeDataCULP = await alchemy.getBadgesById(idArrCULP);
      let combinedDataCULP = delegatedBadgesCULP.map((item, index) => {
        return {
          ...badgeDataCULP[index],
          delegated: address,
          delegatedFrom: item.owner,
        };
      });
      let addBoostFactorLP = filterUserTokens(
        combinedDataCULP,
        activeBadgeList
      );
      delegatedBadgeDataCULP.push(...addBoostFactorLP);
    }
  }
  let combinedCUBadges = [
    ...badgesWithDelegationAddress,
    ...delegatedBadgeDataCU,
  ];

  let combinedCULPBadges = [
    ...badgesLpWithDelegationAddress,
    ...delegatedBadgeDataCULP,
  ];

  //Calculate the total boost factor
  //Olny badges that are delegated to the users own address are counted
  const totalBoostFactor = getTotalBoostFactor(combinedCUBadges, address);
  const totalBoostFactorLP = getTotalBoostFactor(combinedCULPBadges, address);
  //Badges can be delegated to another wallet address or the users own address
  //Badges can be delegated to both CU and CULP badge manager contracts
  //If the delegated address is 0x00 it means the badge is not delegated for that contract
  return {
    badgesCU: combinedCUBadges,
    badgesCULP: combinedCULPBadges,
    allBadges: allBadges,
    totalBoostFactor: totalBoostFactor,
    totalBoostFactorLP: totalBoostFactorLP,
  };
}
