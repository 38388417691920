import { device } from "../mediaQuery";
import { css } from "styled-components";

//this function will help us to get a template to build a css based on responsive props
// i.e lg={} will return a template like this:
// @media (min-width: 992px) {
//   ...
// }


// Iterate through the sizes and create a media template
export const responsiveBreakpoints = function (props) {
  const result = {};
  // iterate through breakpoints
  for (const breakpointKey in device) {
    if (props[breakpointKey]) {
      result[breakpointKey] = props[`${breakpointKey}`];
    }
  }

  return Object.keys(result).map(screenLabel => {
    const breakpointProps = result[screenLabel];
    // return media query template
    return {
      query: (...args) => css`
        @media (${device[screenLabel]}) {
          ${css(...args)}
        }
      `,
      props: breakpointProps,
    };
  });

};

// Iterate through props and create a media template with callback
export const breakpointProps = ({ $breakpointProps }, queryCallback) => {
  // if no breakpoint props, return empty array
  if (!$breakpointProps) {
    return [];
  }

  return Object.keys($breakpointProps).map(key => {
    const { query, props } = $breakpointProps[key];

    return queryCallback(props, query);
  });
};