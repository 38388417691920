import styled from "styled-components";
import { colors } from "../colors";

export const commonComponents = {
  button: {
    primary: {
      default: {
        background:
          "radial-gradient(100% 100% at 49.72% 0%, #3A9FFF 0%, #1F85E6 75.34%)",
        textColor: colors.white,
        borderColor:
          "linear-gradient(180deg, #DCE7F2 0%, #4798E4 46.88%, #005FBA 98.96%)",
        textShadowColor: colors.darkBlue300,
      },
      hover: {
        background:
          "radial-gradient(253.77% 94.19% at 50.31% 51.16%, #91C9FF 0%, #3A9FFF 75.34%)",
      },
      disabled: {
        background:
          "radial-gradient(100% 100% at 49.72% 0%, #A0A0A0 0%, #757575 100%)",
        borderColor:
          "linear-gradient(178.03deg, #757575 1.66%, #A0A0A0 73.06%, #E4E4E4 124.21%)",
        textShadowColor: "#626262",
      },
      active: {
        background:
          "radial-gradient(91.67% 95.35% at 51.86% 95.35%, #3A9FFF 10.39%, #91C9FF 89%)",
        borderColor:
          "linear-gradient(180deg, #005FBA 0%, #4798E4 63.93%, #DCE7F2 100%)",
      },
      focus: {
        borderColor:
          "linear-gradient(180deg, #FFF3C0 0%, #FF9A01 37.47%, #B33E05 98.96%)",
      },
    },
    secondary: {
      default: {
        background:
          "radial-gradient(118.62% 118.6% at 50% -18.6%, #FFF3C0 0%, #FF9A01 75.34%)",
        textColor: colors.white,
        borderColor:
          "linear-gradient(180deg, #FFF3C0 0%, #FF9A01 37.47%, #B33E05 98.96%)",
        textShadowColor: colors.yellow300,
      },
      hover: {
        background:
          "radial-gradient(253.77% 94.19% at 50.31% 51.16%, #FFD77A 0%, #FF9A01 75.34%)",
      },
      disabled: {
        background:
          "radial-gradient(100% 100% at 49.72% 0%, #A0A0A0 0%, #757575 100%)",
        borderColor:
          "linear-gradient(178.03deg, #757575 1.66%, #A0A0A0 73.06%, #E4E4E4 124.21%)",
        textShadowColor: "#626262",
      },
      active: {
        background:
          "radial-gradient(101.95% 127.91% at 51.86% 100%, #FF9A01 10.39%, #FFF3C0 84.96%)",
        borderColor:
          "linear-gradient(180deg, #B33E05 0%, #FF9A01 63.93%, #FFF3C0 100%)",
      },
      focus: {
        borderColor:
          "linear-gradient(180deg, #FFF3C0 0%, #FF9A01 37.47%, #B33E05 98.96%)",
      },
    },
    destructive: {
      default: {
        background:
          "radial-gradient(118.62% 118.6% at 50% -18.6%, #F26650 0%, #E3442B 73.21%)",
        textColor: colors.white,
        borderColor:
          "linear-gradient(180deg, #FFB098 0.01%, #E3442B 37.47%, #DD191C 98.96%)",
        textShadowColor: "#DD191C",
      },
      hover: {
        background:
          " radial-gradient(118.62% 118.6% at 50% -18.6%, #FFB098 0%, #E3442B 73.21%)",
      },
      disabled: {
        background:
          "radial-gradient(100% 100% at 49.72% 0%, #A0A0A0 0%, #757575 100%)",
        borderColor:
          "linear-gradient(178.03deg, #757575 1.66%, #A0A0A0 73.06%, #E4E4E4 124.21%)",
        textShadowColor: "#626262",
      },
      active: {
        background:
          "radial-gradient(93.04% 93.02% at 50% 6.98%, #FFB098 0%, #E3442B 85.2%)",
        borderColor:
          "linear-gradient(180deg, #DD191C 0%, #E3442B 44.11%, #FFB098 100%)",
      },
      focus: {
        borderColor:
          "linear-gradient(180deg, #FFF3C0 0%, #FF9A01 37.47%, #B33E05 98.96%)",
      },
    },
    shadowcorn: {
      default: {
        background:
          "radial-gradient(100% 100% at 49.72% 0%, #C262EB 0%, #714189 75.34%)",
        textColor: colors.white,
        borderColor:
          "linear-gradient(180deg, #E1B8FF 0%, #714189 46.88%, #41255C 98.96%)",
        textShadowColor: colors.darkBlue300,
      },
      hover: {
        background:
          "radial-gradient(253.77% 94.19% at 50.31% 51.16%, #E1B8FF 0%, #C262EB 75.34%)",
      },
      disabled: {
        background:
          "radial-gradient(100% 100% at 49.72% 0%, #A0A0A0 0%, #757575 100%)",
        borderColor:
          "linear-gradient(178.03deg, #757575 1.66%, #A0A0A0 73.06%, #E4E4E4 124.21%)",
        textShadowColor: "#626262",
      },
      active: {
        background:
          "radial-gradient(91.67% 95.35% at 51.86% 95.35%, #C262EB 10.39%, #E1B8FF 89%)",
        borderColor:
          "linear-gradient(180deg, #714189 0%, #C262EB 63.93%, #E1B8FF 100%)",
      },
      focus: {
        borderColor:
          "linear-gradient(180deg, #E1B8FF 0%, #C262EB 37.47%, #131222 98.96%)",
      },
    },
  },
  card: {},
  input: {},
};

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size ?? "20px"};
  height: ${({ size }) => size ?? "20px"};
  margin-right: ${({ marginRight }) => marginRight ?? 0};
  margin-left: ${({ marginLeft }) => marginLeft ?? 0};

  & svg {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  & > * {
    fill: ${({ theme, color = "initial" }) => theme.palette[color] ?? color};
    stroke: ${({ theme, color = "initial" }) => theme.palette[color] ?? color};
    transition: fill 0.3s ease;
  }
`;
