import styled from "styled-components";
import { Box } from "../base/Box";
import { Typography } from "../base/Typography";
import { device } from "../../theme/mediaQuery";
import { ethers } from "ethers";
import pluralize from "pluralize";

const CardWrap = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;

  transform: translateY(0px);
  transition: all 0.24s ease;
  max-width: 365px;

  @media (${device.xl}) {
    /* width: 326px; */
  }
  @media (${device.xxl}) {
    width: ${({ $setWidth }) => ($setWidth ? "300px" : "unset")};
  }
`;

const HeaderImg = styled.img`
  object-fit: contain;
  // height: 256px;
  width: 100%;
`;

const TextBox = styled(Box)`
  user-select: none;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 21px;
  padding: 28px 20px 21px;
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 32px;
  & h3 {
    text-align: center;
    line-height: 100%;
  }
`;

const Separator = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.11);
  width: 100%;
`;

// const ButtonWrap = styled(Button)`
//   width: 100%;

//   & div {
//     font-size: 12px;
//     @media (${device.md}) {
//       font-size: 14px;
//     }
//     @media (${device.lg}) {
//       font-size: 16px;
//     }
//   }
// `;

export function RewardCard({ name, description, img, flex = false, amount }) {
  // const handleClaim = () => {};
  // console.log("amount", amount);
  function formatAmount(amount) {
    if (amount?.amount.length >= 18) {
      return ethers.utils.formatEther(amount?.amount);
    } else {
      return amount?.amount;
    }
  }
  const displayAmount = formatAmount(amount); //ethers.utils.formatEther(amount);
  return (
    <CardWrap $setWidth={!flex}>
      <Box
        variant="gradient"
        gradient="#F3F3FF"
        rounded="sm"
        pattern="none"
        patternSize="lg"
        patternOpacity="0.05"
      >
        <HeaderImg src={img} alt="" />
        {/* <Separator /> */}
        <TextBox
          variant="gradient"
          gradient="linear-gradient(180deg, #144C92 0%, #1E3C72 100%)"
          pattern="sprinkles"
          patternSize="lg"
        >
          <TextWrap>
            <Typography
              tag={"h3"}
              variant={"textSm"}
              xl={{ variant: "textMd" }}
              color="white"
            >
              {pluralize(name, displayAmount, true)}
            </Typography>
            {description && (
              <Typography
                tag={"h3"}
                variant={"textSm"}
                xl={{ variant: "textMd" }}
                color="white"
              >
                {description}
              </Typography>
            )}
          </TextWrap>
          {/* <Separator />
          <ButtonWrap variant="secondary" onClick={handleClaim}>
            Claim Reward
          </ButtonWrap> */}
        </TextBox>
      </Box>
    </CardWrap>
  );
}
