import React from "react";
import styled from "styled-components";
import { Button } from "../../base/Button";
import { SuccessModal } from "./SuccessModal";
import { device } from "../../../theme/mediaQuery";
import { Typography } from "../../base/Typography";
import { useAtom, useSetAtom } from "jotai";
import {
  liquiditySuccessModalAtom,
  stakeModalOpenedAtom,
} from "../../../store/staking";
import { StakingViewTransactionButton } from "../StakingViewTransactionButton";

const ButtonWrap = styled.div`
  z-index: 13;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  width: 100%;
  @media (${device.md}) {
    gap: 20px;
    flex-wrap: nowrap;
    padding: 0 20px;
  }

  & * {
    white-space: nowrap;
    // @media (${device.sm}) {
    //   width: unset;
    // }
  }

  & > button {
    padding: 0;
  }
`;

const StakingDetailsWrap = styled.div`
  background: #6a00b2;
  color: ${({ theme }) => theme.palette.white};
  backdrop-filter: blur(6px);
  border-radius: 12px;
  padding: 12px;
  margin: 16px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 8px;
  z-index: 13;

  @media (${device.sm}) {
    //width: 350px;
  }

  @media (${device.md}) {
    padding: 24px;
    margin: 24px 0;
    //width: 536px;
  }
  @media (${device.md}) {
    padding: 30px;
  }
`;

const StakingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: normal;
  gap: 8px;

  & h4 {
    font-weight: normal;
  }

  & p {
    font-weight: 700;
  }
`;

const details = [
  { title: "Your CULP Tokens", key: "culp" },
  { title: "Your Pool Share", key: "share" },
];

const StakingDetails = ({ transaction }) => {
  return (
    <StakingDetailsWrap>
      {details.map((item) => (
        <React.Fragment key={item.key}>
          {transaction?.[item.key] && (
            <StakingRow>
              <Typography
                as="p"
                variant="textLg"
                md={{ variant: "displayXs" }}
                family="body"
              >
                {transaction[item.key]}
              </Typography>
              <Typography
                as="h4"
                variant="textLg"
                md={{ variant: "displayXs" }}
                family="body"
                className="text-end"
              >
                {item.title}
              </Typography>
            </StakingRow>
          )}
        </React.Fragment>
      ))}
    </StakingDetailsWrap>
  );
};

export function LiquiditySuccessModal() {
  const [modal, setModal] = useAtom(liquiditySuccessModalAtom);
  const setStakeModalOpened = useSetAtom(stakeModalOpenedAtom);
  const handleClose = () => {
    setModal({
      show: false,
      transaction: {
        hash: null,
        share: null,
        culp: null,
      },
    });
  };

  const { show, transaction } = modal;

  const handleOpenStakeModal = () => {
    setStakeModalOpened(true);
    handleClose();
  };

  return (
    <SuccessModal
      toggleModal={handleClose}
      showModal={show}
      title="Success!"
      subtitle="You have successfully added liquidity to the CU-ETH pool."
      message="In exchange you have received the following CULP tokens which can be staked to earn rewards."
    >
      <StakingDetails transaction={transaction} />
      <ButtonWrap>
        <Button
          variant="secondary"
          size="lg"
          onClick={handleOpenStakeModal}
          block={true}
        >
          Stake culp
        </Button>
        <StakingViewTransactionButton
          variant="primary"
          size="lg"
          hash={transaction.hash}
          block={true}
        >
          View Transaction
        </StakingViewTransactionButton>
      </ButtonWrap>
    </SuccessModal>
  );
}
