import {
  useWeb3Mutation,
  useWeb3Query,
  useWeb3QueryWithoutWallet,
  useWeb3QueryWrongChain,
} from "./utils/useWeb3Query";
import {
  months,
  rewardsArray,
  StakingTokenTypes,
} from "../components/staking/constants";
import {
  convertAmountFromRawNumber,
  getVaultBadges,
  getVaultData,
  getVaultDataWithoutWallet,
} from "../components/staking/GetVaultData";
import {
  getBadgeManagerContract,
  getCUContract,
  getCULPContract,
  getRewardContract,
  getRouterContract,
  getStakingContract,
} from "../components/staking/contract";

import { Network } from "../components/Network";
import { queryClient } from "./index";
import Web3 from "web3";
import { useQuery } from "@tanstack/react-query";
import { getTokenPricesInUSD } from "../components/staking/GetTokenPrice";
import { ethers } from "ethers";
import { useActiveWalletChain } from "thirdweb/react";

export const GET_VAULT_OVERVIEW_QUERY_KEY = "GET_VAULT_OVERVIEW_QUERY_KEY";
export const MUTATE_VAULT_CLAIM_REWARDS = "MUTATE_VAULT_CLAIM_REWARDS";
export const MUTATE_CLAIM_REWARDS = "MUTATE_CLAIM_CLAIMABLE_REWARDS";
export const MUTATE_WITHDRAW_ESCROWED_REWARDS =
  "MUTATE_WITHDRAW_ESCROWED_REWARDS";
export const MUTATE_STAKE_TOKEN = "MUTATE_STAKE_TOKEN";
export const MUTATE_WITHDRAW_TOKEN = "MUTATE_WITHDRAW_TOKEN";
export const MUTATE_DELEGATE_BADGE = "MUTATE_DELEGATE_BADGE";
export const GET_VAULT_BADGES_QUERY_KEY = "GET_VAULT_BADGES_QUERY_KEY";
export const GET_VAULT_RESERVES_QUERY_KEY = "GET_VAULT_RESERVES_QUERY_KEY";
export const MUTATE_ADD_LIQUIDITY = "MUTATE_ADD_LIQUIDITY";
export const GET_ADD_LIQUIDITY_QUERY_KEY = "GET_ADD_LIQUIDITY_QUERY_KEY";
export const GET_TOKEN_USD_PRICE_QUERY_KEY = "GET_TOKEN_USD_PRICE_QUERY_KEY";
export const GET_LP_APR_QUERY_KEY = "GET_LP_APR_QUERY_KEY";
export const GET_VAULT_OVERVIEW_WITHOUT_WALLET_QUERY_KEY =
  "GET_VAULT_OVERVIEW_WITHOUT_WALLET_QUERY_KEY";
export const GET_VAULT_OVERVIEW_WRONG_NETWORK_QUERY_KEY =
  "GET_VAULT_OVERVIEW_WRONG_NETWORK_QUERY_KEY";

function getStakingContractByToken(wallet, token, userAddress) {
  const contractAddress =
    token === StakingTokenTypes.cu
      ? Network.Arbitrum.CUStakingContractAddress
      : Network.Arbitrum.CULPStakingContractAddress;
  return getStakingContract(wallet, contractAddress, userAddress);
}

function getRewardContractByToken(wallet, token, userAddress) {
  const reward = rewardsArray.find((reward) => reward.name === token);

  if (!reward) {
    throw new Error(`Invalid token: ${token}`);
  }

  const contractAddress = reward.rewardContractAddress;

  return getRewardContract(wallet, contractAddress, userAddress);
}

export const useGetVaultOverview = () => {
  const chainId = useActiveWalletChain();
  return useWeb3Query({
    queryKey: [GET_VAULT_OVERVIEW_QUERY_KEY],
    queryFn: async ({ wallet, address }) => {
      // if (chainId.id === Network.networkIdArbitrum) {
      //   const data = await getVaultData(wallet, address);
      //   return data;
      // } else {
      const provider = new ethers.providers.JsonRpcProvider(
        Network.ArbitrumRPC
      );
      const data = await getVaultData(provider, address);
      return data;
    },
    // },
  });
};

export const useGetVaultOverviewWrongNetwork = () => {
  return useWeb3QueryWrongChain({
    queryKey: [GET_VAULT_OVERVIEW_WRONG_NETWORK_QUERY_KEY],
    queryFn: async ({ address }) => {
      const provider = new ethers.providers.JsonRpcProvider(
        Network.ArbitrumRPC
      );
      const startTime = Date.now();
      const data = await getVaultData(provider, address);
      const endTime = Date.now();
      console.log("Vault data fetch time:", endTime - startTime, "ms");
      return data;
    },
  });
};

export const useGetVaultBadges = () => {
  return useWeb3Query({
    queryKey: [GET_VAULT_BADGES_QUERY_KEY],
    queryFn: async ({ wallet, address }) => {
      let badges = await getVaultBadges(wallet, address);
      return badges;
    },
  });
};

export const useDelegateBadge = (badge, token, address) => {
  return useWeb3Mutation({
    mutationKey: [MUTATE_DELEGATE_BADGE],
    mutationFn: async ({ wallet }) => {
      const contractAddress =
        token === StakingTokenTypes.cu
          ? Network.Arbitrum.badgeManagerCU
          : Network.Arbitrum.badgeManagerCULP;
      let contract = getBadgeManagerContract(wallet, contractAddress);
      return await contract.delegateBadgeTo(badge.poolId, address);
    },
    onSuccess: async () => {
      // invalidate the vault badge query to refetch the data
      queryClient.invalidateQueries({
        queryKey: [GET_VAULT_BADGES_QUERY_KEY],
      });
    },
  });
};

export const useGetReserves = () => {
  return useWeb3Query({
    queryKey: [GET_VAULT_RESERVES_QUERY_KEY],
    queryFn: async ({ wallet, address }) => {
      const contract = getCULPContract(wallet);
      const reserves = await contract.getReserves();
      return reserves;
    },
    staleTime: 10000, // 10 seconds
  });
};
/**
 * This hook is used to create a mutation for claiming rewards from the vault contract.
 *
 * @returns {Object} The mutation object returned from the `useWeb3Mutation` hook.
 * This object includes fields for the mutation's current status and methods to trigger the mutation.
 */
export const useClaimRewards = (token) => {
  return useWeb3Mutation({
    mutationKey: [MUTATE_CLAIM_REWARDS, token],
    mutationFn: async ({ wallet, address }) => {
      const stakingContract = getStakingContractByToken(wallet, token, address);

      return stakingContract.claimAll();
    },
    onSuccess: async () => {
      // invalidate the vault overview query to refetch the data
      queryClient.invalidateQueries({
        queryKey: [GET_VAULT_OVERVIEW_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [GET_VAULT_OVERVIEW_WRONG_NETWORK_QUERY_KEY],
      });
    },
  });
};

/**
 * This hook is used to create a mutation for claiming rewards from the vault contract.
 *
 * @returns {Object} The mutation object returned from the `useWeb3Mutation` hook.
 * This object includes fields for the mutation's current status and methods to trigger the mutation.
 */
export const useWithdrawEscrowedRewards = (id, token) => {
  return useWeb3Mutation({
    mutationKey: [MUTATE_WITHDRAW_ESCROWED_REWARDS, id, token],
    mutationFn: async ({ wallet, address }) => {
      const rewardContract = getRewardContractByToken(wallet, token, address);

      return rewardContract.withdraw(id);
    },
    onSuccess: async () => {
      // invalidate the vault overview query to refetch the data
      queryClient.invalidateQueries({
        queryKey: [GET_VAULT_OVERVIEW_QUERY_KEY],
      });
    },
  });
};

/**
 * This hook is used to create a mutation for staking a token in the staking contract.
 *
 * @returns {Object} The mutation object returned from the `useWeb3Mutation` hook.
 * This object includes fields for the mutation's current status and methods to trigger the mutation.
 */
export const useStakeToken = () => {
  return useWeb3Mutation({
    mutationKey: [MUTATE_STAKE_TOKEN],
    mutationFn: async ({ wallet, address }, payload) => {
      const { amount, duration, token } = payload;

      const stakingContract = getStakingContractByToken(wallet, token, address);
      const amountInWei = ethers.utils.parseEther(amount.toString());
      return stakingContract.depostit(`${amountInWei}`, duration);
    },
    onSuccess: async () => {
      // invalidate the vault overview query to refetch the data
      await queryClient.invalidateQueries({
        queryKey: [GET_VAULT_OVERVIEW_QUERY_KEY],
      });
    },
  });
};

/**
 * This hook is used to create a mutation for withdrawing a token from the staking contract.
 *
 * @returns {Object} The mutation object returned from the `useWeb3Mutation` hook.
 * This object includes fields for the mutation's current status and methods to trigger the mutation.
 */
export const useWithdrawToken = () => {
  return useWeb3Mutation({
    mutationKey: [MUTATE_WITHDRAW_TOKEN],
    mutationFn: async ({ wallet, address }, payload) => {
      const { index, token } = payload;
      const stakingContract = getStakingContractByToken(wallet, token, address);

      return stakingContract.withdraw(index);
    },
    onSuccess: async () => {
      // invalidate the vault overview query to refetch the data
      await queryClient.invalidateQueries({
        queryKey: [GET_VAULT_OVERVIEW_QUERY_KEY],
      });
    },
  });
};

export const useAddLiquidity = () => {
  return useWeb3Mutation({
    mutationKey: [MUTATE_ADD_LIQUIDITY],
    mutationFn: async ({ wallet, address }, payload) => {
      const contract = getRouterContract(wallet);
      return await contract.addLiquidity(payload.cu, payload.weth, address);
    },
    onSuccess: async () => {
      // invalidate the vault overview query to refetch the data
      await queryClient.invalidateQueries({
        queryKey: [GET_VAULT_OVERVIEW_QUERY_KEY],
      });
    },
  });
};

// hook helper to get history for both staking tokens
export function useGetHistory() {
  const { data = [], ...rest } = useGetVaultOverview();

  const CUDeposits = data?.viewData?.pools?.CU?.deposits || [];
  const CULPDeposits = []; //data?.viewData?.pools?.CULP?.deposits ||

  const historyList = [];

  // map deposits to historyList array with token type and index
  if (CUDeposits.length > 0) {
    const mappedDeposits = CUDeposits.map((deposit, index) => ({
      ...deposit,
      token: StakingTokenTypes.cu,
      index,
    }));
    historyList.push(...mappedDeposits);
  }

  if (CULPDeposits.length > 0) {
    const mappedDeposits = CULPDeposits.map((deposit, index) => ({
      ...deposit,
      token: StakingTokenTypes.culp,
      index,
    }));
    historyList.push(...mappedDeposits);
  }

  return {
    data: historyList,
    ...rest,
  };
}

// hook helper to get claimable rewards for both staking tokens
export function useGetClaimableRewards() {
  const { data, ...rest } = useGetVaultOverview();

  // get staked for both tokens
  const stakedCU = data?.CU?.userStakedCU || "0";
  const stakedCULP = data?.CULP?.userStakedCULP || "0";

  // get claimable rewards for both tokens
  const claimableCU = data?.Rewards?.withdrawableRewardsOf || [];
  const claimableCULP = data?.Rewards?.withdrawableRewardsOfLP || [];

  // get deposits for the corresponding tokens
  const depositsCU = data?.viewData?.pools?.CU?.deposits || [];
  const depositsCULP = data?.viewData?.pools?.CULP?.deposits || [];

  const zeroInBN = Web3.utils.toBN("0");

  const claimableRewards = [
    {
      claimable: claimableCU.map((claimable) => ({
        amount: claimable.amount,
        token: claimable.name,
        exists: Web3.utils.toBN(claimable.amount).gt(zeroInBN),
      })),
      isStaked: Web3.utils.toBN(stakedCU).gt(zeroInBN),
      token: StakingTokenTypes.cu,
      staked: stakedCU,
      deposits: depositsCU,
    },
    {
      claimable: claimableCULP.map((claimable) => ({
        amount: claimable.amount,
        token: claimable.name,
        exists: Web3.utils.toBN(claimable.amount).gt(zeroInBN),
      })),
      isStaked: Web3.utils.toBN(stakedCULP).gt(zeroInBN),
      token: StakingTokenTypes.culp,
      staked: stakedCULP,
      deposits: depositsCULP,
    },
  ];

  return {
    data: claimableRewards,
    ...rest,
  };
}

export function useGetClaimableRewardsWrongNetwork() {
  const { data, ...rest } = useGetVaultOverviewWrongNetwork();

  // get staked for both tokens
  const stakedCU = data?.CU?.userStakedCU || "0";
  const stakedCULP = data?.CULP?.userStakedCULP || "0";

  // get claimable rewards for both tokens
  const claimableCU = data?.Rewards?.withdrawableRewardsOf || [];
  const claimableCULP = data?.Rewards?.withdrawableRewardsOfLP || [];

  // get deposits for the corresponding tokens
  const depositsCU = data?.viewData?.pools?.CU?.deposits || [];
  const depositsCULP = data?.viewData?.pools?.CULP?.deposits || [];

  const zeroInBN = Web3.utils.toBN("0");

  const claimableRewards = [
    {
      claimable: claimableCU.map((claimable) => ({
        amount: claimable.amount,
        token: claimable.name,
        exists: Web3.utils.toBN(claimable.amount).gt(zeroInBN),
      })),
      isStaked: Web3.utils.toBN(stakedCU).gt(zeroInBN),
      token: StakingTokenTypes.cu,
      staked: stakedCU,
      deposits: depositsCU,
    },
    {
      claimable: claimableCULP.map((claimable) => ({
        amount: claimable.amount,
        token: claimable.name,
        exists: Web3.utils.toBN(claimable.amount).gt(zeroInBN),
      })),
      isStaked: Web3.utils.toBN(stakedCULP).gt(zeroInBN),
      token: StakingTokenTypes.culp,
      staked: stakedCULP,
      deposits: depositsCULP,
    },
  ];

  return {
    data: claimableRewards,
    ...rest,
  };
}

// hook helper to get escrowed rewards
export function useGetEscrowedRewards() {
  const { data, ...rest } = useGetVaultOverview();
  const escrowedRewards = data?.Rewards?.escrowedRewards || [];
  const deposits = data?.viewData?.escrowPool?.deposits || [];

  const mappedEscrowedRewards = escrowedRewards.map((reward) => ({
    ...reward,
    token: reward.name,
  }));

  return {
    rewards: mappedEscrowedRewards,
    deposits,
    ...rest,
  };
}

export function useGetEscrowedRewardsWrongNetwork() {
  const { data, ...rest } = useGetVaultOverviewWrongNetwork();
  const escrowedRewards = data?.Rewards?.escrowedRewards || [];
  const deposits = data?.viewData?.escrowPool?.deposits || [];

  const mappedEscrowedRewards = escrowedRewards.map((reward) => ({
    ...reward,
    token: reward.name,
  }));

  return {
    rewards: mappedEscrowedRewards,
    deposits,
    ...rest,
  };
}

export const useGetAddLiquidityData = () => {
  return useWeb3Query({
    queryKey: [GET_ADD_LIQUIDITY_QUERY_KEY],
    queryFn: async ({ wallet, address }) => {
      // let web3 = new Web3(wallet.provider);
      const cuContract = getCUContract(wallet);
      const cuBalance = await cuContract.balanceOf(address);
      const ethBalance = await wallet.provider.getBalance(address);
      const cuBalanceFromWei = ethers.utils.formatEther(cuBalance);
      const ethBalanceFromWei = ethers.utils.formatEther(ethBalance);
      return {
        cuBalance,
        cuBalanceFromWei,
        ethBalance,
        ethBalanceFromWei,
      };
    },
  });
};
export const useGetTokenUSDPrice = (token) => {
  return useQuery({
    queryKey: [GET_TOKEN_USD_PRICE_QUERY_KEY],
    queryFn: getTokenPricesInUSD,
    // set stale time to 5 minutes to prevent unnecessary refetching and api calls
    staleTime: 1000 * 60 * 5,
    // set cache time to 10 minutes
    cacheTime: 1000 * 60 * 10,
    select: (data) => {
      if (!data) {
        return null;
      }

      return data[token] || null;
    },
  });
};

// hook helper to get balances for both staking tokens
export const useGetCUandCULPBalances = () => {
  const { data = {}, ...rest } = useGetVaultOverview();

  const CU = data?.CU?.cuBalance || "0";
  const CULP = data?.CULP?.CULPBalance || "0";

  const CUInETH = ethers.utils.formatEther(CU);
  const CULPInETH = ethers.utils.formatEther(CULP);

  const parsedCU = parseFloat(CUInETH);
  const parsedCULP = parseFloat(CULPInETH);

  return {
    CU: parsedCU,
    CULP: parsedCULP,
    ...rest,
  };
};

// hook helper to get APR array based on the token
export const useGetAPRArray = (token) => {
  const { data = {}, ...rest } = useGetVaultOverview();

  // to get LP APR, we need to sum the APRs of all the rewards
  const lpApr = useGetLpAPR();
  const xaiLpArray = lpApr.data?.xaiAPR?.aprArray || [];
  const cuLpArray = lpApr.data?.cuAPR?.aprArray || [];
  const grailLpArray = lpApr.data?.grailAPR?.aprArray || [];
  const culpAPR = boostArr.map((boost, index) => {
    return {
      timeBoost: boost,
      apr:
        xaiLpArray[index]?.apr +
        cuLpArray[index]?.apr +
        grailLpArray[index]?.apr,
      monthName: months[index]?.label,
      monthDuration: months[index]?.value,
      baseAPR: xaiLpArray[index]?.apr + cuLpArray[index]?.apr,
    };
  });

  const cuAPR = data?.CU?.aprArray || [];

  if (token === StakingTokenTypes.cu) {
    return {
      data: cuAPR,
      ...rest,
    };
  } else if (token === StakingTokenTypes.culp) {
    return {
      data: culpAPR,
      ...rest,
    };
  } else {
    return {
      data: [],
      ...rest,
    };
  }
};

// hook helper to get badge boost factor based on the token
export const useGetBadgeBoostFactor = (token) => {
  const { data = {}, ...rest } = useGetVaultBadges();

  const cuBoostFactor = data?.totalBoostFactor || 0;
  const culpBoostFactor = data?.totalBoostFactorLP || 0;

  if (token === StakingTokenTypes.cu) {
    return {
      data: cuBoostFactor,
      ...rest,
    };
  } else if (token === StakingTokenTypes.culp) {
    return {
      data: culpBoostFactor,
      ...rest,
    };
  } else {
    return {
      data: 0,
      ...rest,
    };
  }
};

const boostArr = [1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4];

//!!!!!!update the weight and reward per sec for each reward
const rewards = [
  {
    name: "cu",
    rewardContractAddress: Network.Arbitrum.CURewardContract,
    rewardPerSec: 1117, /// 86400,
    weight: 1,
  },
  {
    name: "xai",
    rewardContractAddress: Network.Arbitrum.XAIRewardContract,
    rewardPerSec: 5750, // / 86400,
    weight: 1,
  },
  {
    name: "grail",
    rewardContractAddress: Network.Arbitrum.GRAILRewardContract,
    rewardPerSec: 0.13, // / 86400,
    weight: 1,
  },
  // {
  //   name: "arb",
  //   rewardContractAddress: Network.Arbitrum.ARBRewardContract,
  //   rewardPerSec: 0,
  //   weight: 1,
  // },
];
//hard code the reward per sec for each reward
//weight should be 1 for xai, grail, arb because they aren't split between cu and culp

// Rewards per Week
// 7,821 CU
// 40,250  XAI

export const useGetLpAPR = () => {
  const { data: cuPrice } = useGetTokenUSDPrice(StakingTokenTypes.cu);
  const { data: xaiPrice } = useGetTokenUSDPrice(StakingTokenTypes.xai);
  const { data: grailPrice } = useGetTokenUSDPrice(StakingTokenTypes.grail);
  const { data: arbPrice } = useGetTokenUSDPrice(StakingTokenTypes.arb);
  const { data: ethPrice } = useGetTokenUSDPrice(StakingTokenTypes.weth);

  return useWeb3QueryWithoutWallet({
    enabled:
      cuPrice !== undefined &&
      xaiPrice !== undefined &&
      grailPrice !== undefined &&
      arbPrice !== undefined &&
      ethPrice !== undefined,
    queryKey: [GET_LP_APR_QUERY_KEY],
    queryFn: async () => {
      const secondsInMonth = 86400 * 30;
      const SECONDS_PER_YEAR = 86400 * 30 * 12;

      const rpc = Network.ArbitrumRPC;

      // let web3 = new Web3(rpc);

      //lp apr's

      const lpContract = getCULPContract(null, rpc);
      const sCULPContract = getStakingContract(
        null,
        Network.Arbitrum.CULPStakingContractAddress,
        null,
        rpc
      );

      const sculpTotalSupply = await sCULPContract.totalSupply();
      const sculpTotalSupplyInEth = parseFloat(
        ethers.utils.formatEther(sculpTotalSupply)
      );

      const reserves = await lpContract.getReserves();
      const culpStaked = await lpContract.balanceOf(
        Network.Arbitrum.CULPStakingContractAddress
      );
      const culpTotalSupply = await lpContract.totalSupply();
      rewards[0].price = cuPrice;
      rewards[1].price = xaiPrice;
      rewards[2].price = grailPrice;

      const reservesInEth = parseFloat(
        ethers.utils.formatEther(reserves._reserve0)
      );
      const culptotalSupplyInEth = parseFloat(
        ethers.utils.formatEther(culpTotalSupply)
      );

      const lpValue = (reservesInEth * 2 * ethPrice) / culptotalSupplyInEth;

      const calculatAPR = (token, boost) => {
        if (culpStaked === "0") {
          return 0;
        }

        const newAPR =
          (((boost / (boost + sculpTotalSupplyInEth)) *
            token.rewardPerSec *
            token.price *
            360) /
            lpValue) *
          100;

        return newAPR;
      };

      const cuAPR = {
        name: rewards[0].name,
        baseApr: calculatAPR(rewards[0], 1),
        minApr: calculatAPR(rewards[0], boostArr[0]),
        maxApr: calculatAPR(rewards[0], boostArr[11]),
        aprArray: boostArr.map((boost, index) => {
          return {
            boost: boost,
            apr: calculatAPR(rewards[0], boost),
            label: months[index].label,
          };
        }),
      };

      const xaiAPR = {
        name: rewards[1].name,
        baseApr: calculatAPR(rewards[1], 1),
        minApr: calculatAPR(rewards[1], boostArr[0]),
        maxApr: calculatAPR(rewards[1], boostArr[11]),
        aprArray: boostArr.map((boost, index) => {
          return {
            boost: boost,
            apr: calculatAPR(rewards[1], boost),
            label: months[index].label,
          };
        }),
      };

      const grailAPR = {
        name: rewards[2].name,
        baseApr: calculatAPR(rewards[2], 1),
        minApr: calculatAPR(rewards[2], boostArr[0]),
        maxApr: calculatAPR(rewards[2], boostArr[11]),
        aprArray: boostArr.map((boost, index) => {
          return {
            boost: boost,
            apr: calculatAPR(rewards[2], boost),
            label: months[index].label,
          };
        }),
      };

      // const arbAPR = {
      //   name: rewards[3].name,
      //   baseApr: calculatAPR(rewards[3], 1),
      //   minApr: calculatAPR(rewards[3], boostArr[0]),
      //   maxApr: calculatAPR(rewards[3], boostArr[11]),
      //   aprArray: boostArr.map((boost, index) => {
      //     return {
      //       boost: boost,
      //       apr: calculatAPR(rewards[3], boost),
      //       label: months[index].label,
      //     };
      //   }),
      // };

      const totalMinApr = cuAPR.minApr + xaiAPR.minApr + grailAPR.minApr;
      const totalMaxApr = cuAPR.maxApr + xaiAPR.maxApr + grailAPR.maxApr;
      //add total apr
      //add apr array for each month

      //cu apr's

      const REWARD_PER_SECOND = convertAmountFromRawNumber(814027488411996000);
      //const SECONDS_PER_YEAR = 86400 * 30 * 12;
      const MAX_LOCKED_BOOSTED = 4;
      const MIN_LOCKED_BOOSTED = 1 + (3 * 1) / 12;

      const cuStakingContract = getStakingContract(
        null,
        Network.Arbitrum.CUStakingContractAddress,
        null,
        rpc
      );

      const cuStakingContractTotalSupply =
        await cuStakingContract.totalSupply();
      const minAPR = (
        ((REWARD_PER_SECOND * SECONDS_PER_YEAR * 0.84104289318 * 10) /
          (cuStakingContractTotalSupply / 10 ** 18)) *
        MIN_LOCKED_BOOSTED
      ).toFixed(0);
      const maxAPR = (
        ((REWARD_PER_SECOND * SECONDS_PER_YEAR * 0.84104289318 * 10) /
          (cuStakingContractTotalSupply / 10 ** 18)) *
        MAX_LOCKED_BOOSTED
      ).toFixed(0);
      const baseAPR =
        (REWARD_PER_SECOND * SECONDS_PER_YEAR * 0.84104289318 * 10) /
        (cuStakingContractTotalSupply / 10 ** 18).toFixed(2);

      let aprBoost = [];
      for (let i = 0; i < months.length; i++) {
        let boost =
          (await cuStakingContract.getMultiplier(months[i].value)) / 10 ** 18;
        let baseAPR =
          (REWARD_PER_SECOND * SECONDS_PER_YEAR * 0.84104289318 * 10) /
          (cuStakingContractTotalSupply / 10 ** 18);
        let apr =
          ((REWARD_PER_SECOND * SECONDS_PER_YEAR * 0.84104289318 * 10) /
            (cuStakingContractTotalSupply / 10 ** 18)) *
          boost;
        aprBoost.push({
          monthDuration: months[i].value,
          monthName: months[i].label,
          apr: apr.toFixed(0),
          timeBoost: boost,
          baseAPR: baseAPR.toFixed(2),
        });
      }

      return {
        CULP: {
          cuAPR,
          xaiAPR,
          grailAPR,
          totalMinApr,
          totalMaxApr,
          CULPValue: lpValue,
        },
        CU: {
          aprArray: aprBoost,
          minAPR,
          maxAPR,
          baseAPR,
        },
      };
    },
  });
};

export const useGetVaultOverviewWithoutWallet = () => {
  return useWeb3QueryWithoutWallet({
    queryKey: [GET_VAULT_OVERVIEW_WITHOUT_WALLET_QUERY_KEY],
    queryFn: async () => {
      const data = await getVaultDataWithoutWallet();
      return data;
    },
  });
};
