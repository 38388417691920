import React, { forwardRef } from "react";
import styled from "styled-components";
import { PlayerCard } from "./PlayerCard";
import { Button } from "../base/Button";
import { Typography } from "../base/Typography";
import { Link } from "react-router-dom";
import { useAtomValue, useSetAtom } from "jotai";
import { walletConnectedAtom } from "../../store";
import { RewardCarousel } from "./RewardCarousel";
import { device } from "../../theme/mediaQuery";
import {
  useClaimRewards,
  useClaimRewardsArb,
  useGetClaims,
  useGetClaimsArb,
  useGetSCClaims,
} from "../../query/claim";
import { RewardCards } from "./RewardCards";
import { useActiveWalletChain, useConnectModal } from "thirdweb/react";
import { genericErrorModalAtom } from "../../store/staking";
import { NetworkConfiguration } from "../Network";
import { POLYGON_NETWORK } from "../ConnectWallet/WalletHelper";
import { googleAnalyticsEvent } from "../../helpers/googleAnalytics";
import { EVENT_KEYS } from "../../consts/googleEvents";
import { connectWallet } from "../ConnectWallet/connectWallet";

const PlayerCardWrap = styled(PlayerCard)`
  gap: 40px;
  justify-content: center;

  padding: 16px 0;
  background: ${({ $isWhite, theme }) =>
    $isWhite ? theme.palette.white : "#F3F3FF"};

  @media (${device.xl}) {
    background: ${({ $isWhite, theme }) =>
      $isWhite ? theme.palette.white : "#F3F3FF"};
  }
`;

export const EventReward = forwardRef(({ switchNetwork, chain }, ref) => {
  const walletConnected = useAtomValue(walletConnectedAtom);
  const setGenericErrorModal = useSetAtom(genericErrorModalAtom);
  const chainId = useActiveWalletChain();
  const wrongChain = chainId?.id !== chain?.id;
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];

  // XAI rewards
  const { data: dataXai, isLoading: isLoadingDataXai } = useGetClaims();
  const { data: dataArb, isLoading: isLoadingDataArb } = useGetClaimsArb();
  // const { data: hatchery, isLoading: hatcheryLoading } = useGetSCClaims();
  const rewardsXai = dataXai?.dropper?.dropper;
  const claimMutationXai = useClaimRewards(dataXai?.dropper?.batchClaim);
  const claimMutationArb = useClaimRewardsArb(dataArb?.batchInputEvents);
  const isEmptyXai = rewardsXai === undefined || rewardsXai?.length === 0;
  const isEmptyArb =
    dataArb?.eventsArr === undefined || dataArb?.eventsArr?.length === 0;
  const isLoadingXai = isLoadingDataXai;
  const isLoadingArb = isLoadingDataArb;
  // switch based on chain
  const isArb = chain.id === netconfig.networkIdArbitrum;
  const data = isArb ? dataArb : dataXai;
  const claimMutation = isArb ? claimMutationArb : claimMutationXai;
  const isEmpty = isArb ? isEmptyArb : isEmptyXai;
  const isLoading = isArb ? isLoadingArb : isLoadingXai;

  const handleClaim = async () => {
    claimMutation.mutate(null, {
      onSuccess: () => {},
      onError: () => {
        setGenericErrorModal({
          show: true,
        });
      },
    });
  };

  return (
    <div ref={ref}>
      <PlayerCardWrap
        title="Event Reward"
        $isWhite={walletConnected}
        isLoading={isLoading}
        tooltip={{
          desc: "Earn rewards by participating in leaderboard events!",
        }}
        button={
          walletConnected ? (
            <Button
              disabled={wrongChain ? false : isEmpty}
              onClick={() =>
                wrongChain ? switchNetwork(chain) : handleClaim()
              }
              variant={wrongChain ? "primary" : "secondary"}
              className="p-0">
              {wrongChain ? "Switch Network" : "Claim"}
            </Button>
          ) : null
        }>
        {(!walletConnected || isEmpty) && <LeaderboardCTA />}
        {/* desktop */}
        {walletConnected && !isEmpty && (
          <RewardCards
            data={data}
            network={isArb}
            className="d-none d-md-grid"
          />
        )}
        {/* mobile */}
        {walletConnected && !isEmpty && (
          <RewardCarousel
            data={data}
            network={isArb}
            className="d-sm-block d-md-none"
          />
        )}
      </PlayerCardWrap>
    </div>
  );
});

const CTAWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
  padding: 10px;
`;

const LeaderboardCTA = () => {
  const walletConnected = useAtomValue(walletConnectedAtom);
  const { connect } = useConnectModal();

  const title = walletConnected
    ? "Participate in events to earn rewards"
    : "Connect your wallet to view your rewards";

  const connectUser = () => {
    connectWallet(connect);
    googleAnalyticsEvent(EVENT_KEYS.CONNECT_CLICKED);
  };

  return (
    <CTAWrap>
      <Typography
        tag="h3"
        variant="textXl"
        md={{
          variant: "displayXs",
        }}
        color="#3334B9"
        className="text-center">
        {title}
      </Typography>
      {walletConnected && (
        <Button as={Link} to="/leaderboard" variant="secondary" className="p-0">
          Go to leaderboard
        </Button>
      )}
      {!walletConnected && (
        <Button onClick={connectUser} variant="primary" className="p-0">
          Connect Wallet
        </Button>
      )}
    </CTAWrap>
  );
};
