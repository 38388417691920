import styled from "styled-components";
import React from "react";
import { Typography } from "../base/Typography";
import { device } from "../../theme/mediaQuery";
import { useIsLarge } from "../../helpers/useIsLarge";
import { Icon } from "../base/Icon";

export const VARIANTS = {
  PINK: 0,
  BLUE: 1,
  PURPLE: 2,
  EGGPLANT: 3,
  MAGENTA: 4,
  BRIGHT_PINK: 5,
};

const THEME = {
  [VARIANTS.BLUE]: {
    name: "blue",
    border: "#8abef5",
    background: "linear-gradient(180deg, #1375dd 0%, #0a3f77 100%)",
  },
  [VARIANTS.PINK]: {
    name: "pink",
    border: "#FF66BA",
    background: "linear-gradient(180deg, #FF008E 0%, #905 100%)",
  },
  [VARIANTS.PURPLE]: {
    name: "purple",
    border: "#9989DC",
    background: "linear-gradient(180deg, #5338C2 0%, #271B5C 100%)",
  },
  [VARIANTS.EGGPLANT]: {
    name: "eggplant",
    border: "#8A2C9A",
    background: "#661574",
  },
  [VARIANTS.MAGENTA]: {
    name: "eggplant",
    border: "#E161D9",
    background: "#AA25A2",
  },
  [VARIANTS.BRIGHT_PINK]: {
    name: "bright-pink",
    border: "#FF59C2",
    background: "#DB1A94",
  },
};

const TableWrap = styled.div`
  width: 100%;
  overflow-x: hidden;
  border-radius: 16px;
  background: ${({ $background }) => $background && $background};
  border-radius: 8px;
  border: ${({ $border }) => $border && `2px solid ${$border}`};
  padding: 12px 12px;
  @media (${device.md}) {
    padding: 16px 24px;
  }
  @media (${device.xl}) {
    padding: ${({ $hasIcon }) => ($hasIcon ? "24px 40px" : "16px 40px")};
    table {
      min-width: 768px;
    }
  }

  & h4 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    @media (${device.xl}) {
      /* min-width: 915px; */
    }
    & p {
      text-shadow: none;
    }
  }
`;

const IconWrap = styled(Icon)`
  width: 12px;
  height: auto;

  @media (${device.lg}) {
    width: 14px;
  }
`;

const StyledTable = styled.table`
  text-align: center;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: ${({ $hasIcon }) => ($hasIcon ? "0 16px" : "unset")};
  width: 100%;
  /* min-width: 915px; */
`;

const TableRow = styled.tr`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  @media (${device.lg}) {
    display: table-row;
    flex-direction: unset;
    align-items: unset;
    gap: unset;
    padding-bottom: unset;
    margin-bottom: unset;
    border-bottom: unset;
  }

  &:last-of-type {
    padding-bottom: unset;
    margin-bottom: unset;
    border-bottom: unset;
  }

  .quantity {
    display: none;
    @media (${device.lg}) {
      display: table-cell;
    }
  }
  .details,
  .quantity,
  .quantity-mobile {
    font-size: 14px;
    @media (${device.xl}) {
      font-size: 16px;
    }
  }
  .quantity-mobile {
    display: inline-block;
    margin-left: auto;
    padding-left: 12px;
    text-shadow: none;
    @media (${device.lg}) {
      display: none;
    }
  }
`;

const TableCell = styled.td`
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;

  @media (${device.lg}) {
    padding: 8px;
    font-size: 16px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
  }
  &:first-child {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }
`;

const TitleCell = styled(TableCell)`
  text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
  font-weight: 400;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.display};
  text-align: left;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (${device.sm}) {
    flex-wrap: nowrap;
  }

  @media (${device.lg}) {
    display: table-cell;
    flex-wrap: unset;
  }

  @media (${device.xl}) {
    font-size: ${({ $hasIcon, $smallHeading }) =>
      $hasIcon || $smallHeading ? "18px" : "24px"};
  }
`;

const RowIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
`;

const PointsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  padding-left: 10px;
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 14px;
  margin: 0 10px;
  border-radius: 50px;
  gap: 6px;
  height: 36px;

  max-width: 400px;
  margin: 0 auto;

  @media (${device.xl}) {
    font-size: 18px;
  }
  & span {
    word-break: keep-all;
    font-size: inherit;
    font-family: inherit;
  }
`;

export const Table = ({
  title,
  rows,
  hasIcon = false,
  locked = false,
  smallHeading = false,
  variant = VARIANTS.BLUE,
}) => {
  const isLarge = useIsLarge();
  const theme = THEME[variant];

  return (
    <TableWrap
      $hasIcon={hasIcon}
      $background={theme.background}
      $border={theme.border}
    >
      {title && (
        <Typography
          tag={"h4"}
          variant={"textSm"}
          md={{ variant: "textMd" }}
          xl={{ variant: "displayXs" }}
          textshadow="hardAlt"
        >
          {locked && <IconWrap name="lock" />}

          {title}
          {locked && (
            <Typography
              tag={"p"}
              variant={"text2xs"}
              color="#FBD470"
              xl={{ variant: "textXs" }}
            >
              {locked}
            </Typography>
          )}
        </Typography>
      )}
      <StyledTable $hasIcon={hasIcon}>
        <tbody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TitleCell
                $smallHeading={smallHeading}
                $hasIcon={hasIcon}
                colSpan={isLarge ? 2 : 1}
              >
                {hasIcon && <RowIcon src={row.iconSrc} alt={row.iconAlt} />}
                {row.label}
                <p className="quantity-mobile">{row.quantity}x</p>
              </TitleCell>
              <TableCell className="details">{row.detail}</TableCell>
              <TableCell className="quantity">{row.quantity}x</TableCell>
              <TableCell>
                <PointsBox>
                  {row.points ? row.points.toLocaleString() : 0}
                  <span> POINTS</span>
                </PointsBox>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </TableWrap>
  );
};
