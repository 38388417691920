import React from "react";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { Typography } from "../base/Typography";
import styled from "styled-components";
import { device } from "../../theme/mediaQuery";

const InventoryToolbarRow = styled(Row)`
  padding: 0 16px;
  @media (${device.md}) {
    padding: 0 32px;
  }
`;

export function InventoryToolbar({ title, subtitle, filters }) {
  return (
    <InventoryToolbarRow className="py-4 align-items-center">
      <Col lg={4}>
        <Typography tag={"h2"} variant={"displaySm"} color="darkBlue300">
          {title}
        </Typography>
        {subtitle && (
          <Typography
            tag={"p"}
            variant={"textSm"}
            color="darkBlue300"
            className={"mb-0"}
          >
            {subtitle}
          </Typography>
        )}
      </Col>
      <Col lg={8}>{filters}</Col>
    </InventoryToolbarRow>
  );
}
