import styled from "styled-components";
import { StakingRewardsClaimable } from "../../components/staking/rewards/StakingRewardsClaimable";
import { StakingRewardsEscrowed } from "../../components/staking/rewards/StakingRewardsEscrowed";
import { StakingBadgesNotification } from "../../components/staking/rewards/StakingRewardsNotifications";
import { StakeModal } from "../../components/staking/Modals/StakeModal";
import { StakingSuccessModal } from "../../components/staking/Modals/StakingSuccessModal";
import { ErrorModal } from "../../components/staking/Modals/ErrorModal";
import { useState } from "react";
import { TOKEN_NAMES } from "../../components/staking/Modals/AssetSelector";
import { NetworkConfiguration } from "../../components/Network";
import { POLYGON_NETWORK } from "../../components/ConnectWallet/WalletHelper";
import { defineChain } from "thirdweb";
import { useSwitchActiveWalletChain } from "thirdweb/react";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export function StakingRewards() {
  const [stakeInitialTab, setStakeInitialTab] = useState(TOKEN_NAMES.CU);
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const arbChain = defineChain({
    id: netconfig.networkIdArbitrum,
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
  });
  const switchChain = useSwitchActiveWalletChain();

  return (
    <Wrap>
      <StakingRewardsClaimable
        setStakeInitialTab={(token) => setStakeInitialTab(token)}
        switchNetwork={switchChain}
        chain={arbChain}
      />
      <StakingRewardsEscrowed
        setStakeInitialTab={(token) => setStakeInitialTab(token)}
      />
      <StakingBadgesNotification />
      <StakeModal initialTab={stakeInitialTab} />
      <StakingSuccessModal />
      <ErrorModal />
    </Wrap>
  );
}
