import React, { useState } from "react";
import styled from "styled-components";
import Spinner from "../base/Spinner";
import { Typography } from "../base/Typography";
import { Button } from "../base/Button";
import { Box } from "../base/Box";
import { device } from "../../theme/mediaQuery";
import { Modal } from "../base/Modal";
import { Icon } from "../base/Icon";
import { TransferDetails } from "./TransferDetails";
import { isTestnet, NetworkConfiguration } from "../Network";
import { POLYGON_NETWORK } from "../ConnectWallet/WalletHelper";
import {
  getBridgeContract,
  getRBWContract,
  getUNIMContract,
  zroPayment,
} from "./contracts";
import { useAtomValue, useSetAtom } from "jotai";
import { persistedPendingTransactionsAtom } from "../../store/pending";
import { BridgeCloseIcon } from "./BridgeCloseIcon";
import { CHAIN, CHAIN_NAMES } from "../../views/Bridge";
import { signerAtom } from "../../store";
import { useActiveAccount } from "thirdweb/react";
import { ethers } from "ethers";

const ModalWrap = styled.div`
  max-width: 519px;
  padding: 0;
  border-radius: 40px;
  gap: 20px;
  border: transparent;
  margin: 0 auto;
  position: relative;
  @media (${device.md}) {
    gap: 26px;
  }
`;

const BoxWrap = styled(Box)`
  border-radius: 12px;
  padding: 40px 20px;
  box-shadow: 0px 4px 7px 0px rgba(255, 255, 255, 0.34) inset;
  width: 100%;
  height: 100%;

  box-shadow: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  @media (${device.md}) {
    row-gap: 26px;

    border-radius: 20px;
    padding: 40px 24px;
  }

  @media (${device.lg}) {
    padding: 40px;
  }

  & * {
    text-align: center;
  }

  & a {
    color: white;
    font-weight: bolder;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.palette.carnationPink100};
    }
  }
`;

const Token = styled.img`
  width: 28px;
  height: 28px;
`;

const ChainsWrap = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  position: relative;
  @media (${device.md}) {
    gap: 20px;
  }
`;

const TransferDetailsWrap = styled(TransferDetails)`
  background-color: #672ca9;
  width: 100%;
`;

const SpinnerWrap = styled.div`
  padding: 8px 2px;
  height: 36px;

  & svg {
    filter: none;
    height: 20px;
    vertical-align: top;
  }
`;

const CloseButton = styled(BridgeCloseIcon)`
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }
`;

export function BridgeModal({
  transferQuantity = 0,
  estimatedTime,
  toggleModal,
  toAddress,
  showModal,
  sendFee,
  token,
  tokenId,
}) {
  // const [{ wallet }] = useConnectWallet();
  const wallet = useAtomValue(signerAtom);
  const setPendingTransactions = useSetAtom(persistedPendingTransactionsAtom);
  const account = useActiveAccount();
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isStepOne, setIsStepOne] = useState(true);
  const [transactionHash, setTransactionHash] = useState("");

  const chain = CHAIN[token.chainId];
  const totalTokenSent = transferQuantity / 10 ** 18;

  // Show pending transactions modal
  // const showTransactionModal = () => {
  //   handleToggleModal();
  //   setPendingTransactions((prev) => ({
  //     ...prev,
  //     showModal: true,
  //   }));
  // };

  // Add transaction to store
  const addTransaction = (newTransaction) => {
    setPendingTransactions((prev) => ({
      ...prev,
      transactions: [...prev.transactions, newTransaction],
    }));
  };

  // RBW => arbitrum
  async function bridgeFunds(wallet, amount, toAddress) {
    let sanitizedAmount = amount.slice(0, -1) + 0;
    const rbwContract = getRBWContract(wallet);
    const unimContract = getUNIMContract(wallet);
    const bridgeContract = getBridgeContract(
      wallet,
      tokenId === 0 ? "dstChainIdArbitrum" : "dstChainIdXai",
      tokenId === 0 ? "RBWBridgeContractAddress" : "UNIMBridgeContractAddress",
      account.address
    );
    if (tokenId === 0) {
      const rbwAllowance = await rbwContract.allowance(
        account.address,
        netconfig.RBWBridgeContractAddress
      );

      if (
        ethers.utils.parseEther(rbwAllowance).lt(ethers.BigNumber.from(amount))
      ) {
        await rbwContract.approve(netconfig.RBWBridgeContractAddress, amount);
      }
    }
    if (tokenId === 1) {
      const unimAllowance = await unimContract.allowance(
        account.address,
        netconfig.UNIMBridgeContractAddress
      );
      if (unimAllowance.lt(ethers.BigNumber.from(amount))) {
        const tx = await unimContract.approve(
          netconfig.UNIMBridgeContractAddress,
          amount
        );
        await tx.wait();
        if (tx.status === 0) {
          throw new Error("Transaction failed");
        }
      }
    }
    let tx = await bridgeContract.sendFrom(
      tokenId === 0 ? netconfig.dstChainIdArbitrum : netconfig.dstChainIdXai,
      toAddress,
      sanitizedAmount,
      sendFee,
      zroPayment
    );
    await tx.wait();
    if (tx.status === 0) {
      throw new Error("Transaction failed");
    } else {
      return tx;
    }
  }

  // Mock function - leaving it here to help test the UI because
  // async function mockBridgeFunds(wallet, amount, toAddress) {
  //   console.log("Mock bridging funds:", { wallet, amount, toAddress });

  //   // Simulate waiting for a couple of seconds
  //   await new Promise((resolve) => setTimeout(resolve, 2000));

  //   // Return a fixed transaction hash
  //   return {
  //     hash:
  //       "0x2dd54953509498f48e4ba863c52e27ce5e644a1f74510521551e48463f7a6ae2",
  //     timestamp: new Date().toISOString(),
  //     amount: amount,
  //   };
  // }

  const handleTransfer = async () => {
    setIsLoading(true);
    bridgeFunds(wallet, transferQuantity, toAddress)
      .then((data) => {
        const transaction = {
          txHash: data.hash,
          timestamp: new Date().toISOString(),
          amount: transferQuantity / 10 ** 18,
          tokenId: token.id,
        };
        addTransaction(transaction);
        setIsLoading(false);
        setIsStepOne(false);
        setTransactionHash(data.hash);
        //add has to store
      })
      .catch((e) => {
        setError(true);
        setIsLoading(false);
      });
  };

  const handleToggleModal = () => {
    setIsStepOne(true);
    setIsLoading(false);
    setError(false);
    setTransactionHash("");
    toggleModal();
  };
  return (
    <Modal
      variant="custom"
      showModal={showModal}
      setShowModal={handleToggleModal}>
      <ModalWrap>
        <CloseButton onClick={handleToggleModal} />
        <BoxWrap
          gradient="linear-gradient(180deg, #6F19B8 0%, #2D0D78 100%)"
          pattern="sprinkles"
          patternSize="md">
          <Typography
            tag="h1"
            variant="displayXs"
            lg={{
              variant: "displayXs",
            }}
          >
            Send {totalTokenSent.toLocaleString()} {token.name}{" "}
            <Token src={token.img} />
          </Typography>
          <ChainsWrap>
            <Chain
              chain={CHAIN[CHAIN_NAMES.POLYGON].name}
              img={CHAIN[CHAIN_NAMES.POLYGON].img}
            />
            <Icon name="full-arrow-right" color="#FFEB35" size="21px" />
            <Chain chain={chain.name} img={chain.img} />
          </ChainsWrap>

          <TransferDetailsWrap
            transferQuantity={transferQuantity}
            estimatedTime={estimatedTime}
            token={token}
          />
          {isStepOne ? (
            <StepOne
              transferQuantity={transferQuantity}
              onClick={handleTransfer}
              isLoading={isLoading}
              error={error}
              token={token}
              chain={chain.name}
            />
          ) : (
            <StepTwo
              txHash={transactionHash}
              token={token}
              chain={chain.name}
            />
          )}
        </BoxWrap>
      </ModalWrap>
    </Modal>
  );
}

const ChainWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  gap: 7px;
`;

const ChainImg = styled.img`
  width: 24px;
  height: 24px;
  @media (${device.md}) {
    width: 32px;
    height: 32px;
  }
`;

const Chain = ({ chain, img }) => {
  const chainString = chain.charAt(0).toUpperCase() + chain.slice(1);
  return (
    <ChainWrap>
      <ChainImg src={img} alt={chain} />
      <Typography
        tag="h3"
        family="display"
        variant="textLg"
        md={{ variant: "textXl" }}>
        {chainString}
      </Typography>
    </ChainWrap>
  );
};

const StepOne = ({
  transferQuantity,
  onClick,
  error,
  token,
  chain,
  isLoading,
}) => {
  const totalTokenSent = transferQuantity / 10 ** 18;
  const totalTokenReceived = totalTokenSent / token.ratio;

  return (
    <>
      <Typography
        tag={"p"}
        variant={"textSm"}
        md={{ variant: "textMd" }}
        color="white"
        className="py-1">
        Clicking Send is irreversible. Are you sure you would like to send{" "}
        <strong>
          {totalTokenSent.toLocaleString()} {token.name} Tokens
        </strong>{" "}
        to the <strong>{chain} chain</strong> to receive{" "}
        <strong>
          {totalTokenReceived.toLocaleString()} {token.convertedToken} Tokens
        </strong>
        ?
      </Typography>

      <Button size="sm" onClick={onClick}>
        {!error && !isLoading && "Confirm"}
        {error && "Try Again"}
        {isLoading && !error && (
          <SpinnerWrap>
            <Spinner color="white" />
          </SpinnerWrap>
        )}
      </Button>
    </>
  );
};

// const ProgressWrap = styled.div`
//   width: 100%;
// `;

// const ProgressBarContainer = styled.div`
//   background: #672ca9;
//   border-radius: 20px;
//   margin: 20px 0 4px;
//   height: 20px;
//   width: 100%;
// `;

// const ProgressBarFiller = styled.div`
//   background-color: #ffc600;
//   height: 100%;
//   border-radius: inherit;
//   width: ${({ $percentage }) => $percentage}%;
//   transition: width 0.5s ease-in-out;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: white;
// `;

// const ProgressBar = ({ total, completed }) => {
//   const percentage = (completed / total) * 100;

//   return (
//     <ProgressWrap>
//       <ProgressBarContainer>
//         <ProgressBarFiller $percentage={percentage}></ProgressBarFiller>
//       </ProgressBarContainer>
//       <Typography
//         tag={"p"}
//         variant={"text2xs"}
//         md={{ variant: "textXs" }}
//         color="white"
//         className="text-end"
//       >
//         {completed}/{total} Confirmations
//       </Typography>
//     </ProgressWrap>
//   );
// };

const StepTwo = ({ txHash, token, chain }) => {
  const scanLink = isTestnet
    ? `${token.scanLinks.TESTNET}/${txHash}`
    : `${token.scanLinks.MAINNET}/${txHash}`;

  const convertedToken = token.convertedToken.includes("CU")
    ? "$CU"
    : token.convertedToken;

  return (
    <>
      <Button size="sm" as="a" href={scanLink} target="_blank" rel="noreferrer">
        View transaction
      </Button>
      <Typography
        tag={"p"}
        variant={"textSm"}
        md={{ variant: "textMd" }}
        color="white"
        className="py-1">
        You will have to wait 20 minutes after all the confirmations have been
        received prior to receiving {convertedToken} on {chain}.
      </Typography>
    </>
  );
};
