import { ethers } from "ethers";
import Web3 from "web3";

//This is assuming that CU is A and wETH is B on both the input and the pair
// export function calculateLpPairAmount(
//   amountInA,
//   amountInB,
//   reserveA,
//   reserveB
// ) {
//   return amountInA
//     ? (amountInA * reserveB) / reserveA
//     : (amountInB * reserveA) / reserveB;
// }

// export function calculateLpPairAmount(
//   amountInA,
//   amountInB,
//   reserveA,
//   reserveB
// ) {
//   const web3 = new Web3();

//   // Convert the input amounts and reserves to BigInt
//   const amountInABigInt = web3.utils.toBN(amountInA.toString());
//   const amountInBBigInt = web3.utils.toBN(amountInB.toString());
//   const reserveABigInt = web3.utils.toBN(reserveA.toString());
//   const reserveBBigInt = web3.utils.toBN(reserveB.toString());

//   // Perform the calculations using BigInt
//   const numerator = amountInA
//     ? amountInABigInt.mul(reserveBBigInt)
//     : amountInBBigInt.mul(reserveABigInt);
//   const denominator = amountInA ? reserveABigInt : reserveBBigInt;

//   let resultBigInt = numerator.div(denominator);

//   // Convert the result from BigInt to string
//   const result = web3.utils.fromWei(resultBigInt.toString(), "ether"); // Convert result from wei to ether (or whatever unit you're using)

//   return result;
// }
export function calculateLpPairAmount(
  amountInA,
  amountInB,
  reserveA,
  reserveB
) {
  // Convert the input amounts and reserves to BigNumber
  const amountInABigInt = ethers.BigNumber.from(amountInA.toString());
  const amountInBBigInt = ethers.BigNumber.from(amountInB.toString());
  const reserveABigInt = ethers.BigNumber.from(reserveA.toString());
  const reserveBBigInt = ethers.BigNumber.from(reserveB.toString());

  // Perform the calculations using BigNumber
  const numerator = amountInA
    ? amountInABigInt.mul(reserveBBigInt)
    : amountInBBigInt.mul(reserveABigInt);
  const denominator = amountInA ? reserveABigInt : reserveBBigInt;

  const resultBigInt = numerator.div(denominator);

  // Convert the result from BigNumber to string
  const result = ethers.utils.formatUnits(resultBigInt, "ether"); // Convert result from wei to ether (or whatever unit you're using)

  return result;
}

//input decimal between 0 and 1
export function calculatePercentage(amount) {
  return amount / 100;
}

//approve multiple tokens for transfer
export async function approveTokens(
  contractArr,
  addressArr,
  amountArr,
  address
) {
  for (let i = 0; i < contractArr.length; i++) {
    let approved = await contractArr[i].allowance(addressArr[i], address);
    if (Number(approved) < Number(amountArr[i])) {
      return await contractArr[i].approve(addressArr[i], amountArr[i]);
    } else {
      return;
    }
  }
}

export function calculateShareOfPool(amountA, reserveA) {
  const amountAWei = ethers.utils.parseUnits(amountA.toString(), "ether");
  const amountABigNumber = ethers.BigNumber.from(amountAWei);
  const reserveABigNumber = ethers.BigNumber.from(reserveA);
  const newReserveA = reserveABigNumber.add(amountABigNumber);
  const shareOfPool = amountABigNumber
    .mul(ethers.BigNumber.from(100))
    .div(newReserveA);
  return shareOfPool.toString();
}
