import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography } from "../../../components/base/Typography";
import { device } from "../../../theme/mediaQuery";
import { useAtomValue } from "jotai";
import { currentWalletAddressAtom } from "../../../store";
import { Icon } from "../../base/Icon";

const theme = {
  background: "rgba(255, 255, 255, 0.20)",
  textColor: "#8221E3",
  placeholderColor: "#9441E7",
  borderColor: "#9441E7",
  errorColor: "#FA5E59",
  inputBackground: "#F0F0FC",
  iconColor: "#8221E3",
  activeTextColor: "#8221E3",
  activeIconColor: "#B880EF",
  activeBorderColor: "#8221E3",
  hoverBorderColor: "#B880EF",
  hoverBackgroundColor: "#B880EF",
  hoverTextColor: "white",
  errorBorderColor: "#FA5E59",
  labelTextColor: "black",
  button: "#8221E3",
  autofillBackground: "#ECE7F4",
  autofillTextColor: "#3E0F94",
};

const InputWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 12px 16px;

  overflow: hidden;
  border-radius: 8px;
`;
const Input = styled.input`
  background: transparent;
  border: none;
  color: ${({ $theme }) => $theme.textColor};
  width: 100%;
  height: 100%;

  font-size: 21px;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.display};

  border-radius: 0;

  @media (${device.md}) {
    font-size: 24px;
  }

  &::placeholder {
    color: ${({ $theme }) => $theme.textColor};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
`;

const RecipientInputWrap = styled(InputWrap)`
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: "12px";
  padding-bottom: "12px";
  max-height: "100px";
  overflow: hidden;
  background-color: ${({ $theme }) => $theme.inputBackground};
  transition: max-height 0.3s ease, background-color 0.3s ease,
    opacity 0.3s ease, visibility 0.3s ease, padding 0.3s ease, margin 0.3s ease;
  opacity: "1";
  visibility: "visible";
  border: ${({ $error, $theme }) =>
    $error ? `1px solid ${$theme.errorBorderColor}` : "1px solid transparent"};
`;

const Placeholder = styled(Typography)`
  width: 100%;
  color: ${({ $theme }) => $theme.placeholderColor};
  position: absolute;
  pointer-events: none;
  font-family: ${({ theme }) => theme.fonts.display};
  visibility: ${({ $show }) => ($show ? "visible" : "hidden")};
  margin-right: 22px;

  & span {
    font-size: 16px;
  }
`;

const RecipientInputField = styled(Input)`
  border: none;
  background: transparent;
  position: relative;
  color: ${({ $theme }) => $theme.textColor};

  &:focus {
    outline: ${({ $error }) => ($error ? "1px solid red" : "none")};
  }
`;

const TokenFieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: "20px";
`;

const AutofillWrap = styled.div`
  display: flex;
`;

const ErrorMessage = styled(Typography)`
  color: ${({ $theme }) => $theme.errorColor};
  margin-top: 4px;
  opacity: ${({ $error }) => ($error ? 1 : 0)};
  max-height: ${({ $error }) => ($error ? "40px" : "0")};
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease, margin 0.5s ease;
`;

const CustomRecipientButton = styled.button`
  background-color: ${({ $theme }) => $theme.autofillBackground};
  color: ${({ $theme }) => $theme.autofillTextColor};
  border-radius: 40px;
  font-size: 12px;
  display: flex;
  padding: 0 6px;
  align-items: center;
  gap: 10px;
  width: auto;
  height: 23px;

  border: 1px solid transparent;

  & div {
    color: ${({ $theme }) => $theme.autofillTextColor};
    width: 15px;
  }

  &:not(:disabled):hover {
    color: ${({ $theme }) => $theme.hoverTextColor};
    border: 1px solid ${({ $theme }) => $theme.autofillTextColor};
    background-color: ${({ $theme }) => $theme.autofillTextColor};
    & div {
      color: ${({ $theme }) => $theme.hoverTextColor};
    }
  }

  &:disabled {
    cursor: default;
  }
`;

export const RecipientInput = ({
  onChange,
  onError,
  placeholder,
  label = false,
  disable = false,
}) => {
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);

  const handleWalletAddress = () => {
    setShowPlaceholder(false);
    handleError(false);
    handleInput(walletAddress || ""); // guard against undefined
  };

  const handleError = (error) => {
    onError(error);
    setError(error);
  };

  const handleInput = (input) => {
    if (input !== "") {
      setShowPlaceholder(false);
      const isValidAddress = /^0x[a-fA-F0-9]{40}$/.test(input);
      if (!isValidAddress) {
        handleError(true);
      } else {
        handleError(false);
      }
    } else {
      setShowPlaceholder(true);
      handleError(false);
    }

    setInputValue(input);
    onChange(input);
  };

  useEffect(() => {
    if (disable) {
      setInputValue("");
    }
  }, [disable]);

  return (
    <div className="p-0">
      <TokenFieldWrap $label={label}>
        {label && (
          <Typography
            as="p"
            family="body"
            variant="textSm"
            md={{ variant: "textMd" }}
            color={theme.labelTextColor}
            className="pb-2"
          >
            {label}
          </Typography>
        )}
        <RecipientInputWrap $error={error} $theme={theme}>
          <RecipientInputField
            type="text"
            placeholder=""
            value={inputValue}
            onChange={(e) => handleInput(e.target.value)}
            $theme={theme}
          />
          <Placeholder
            $show={showPlaceholder}
            tag="p"
            variant="textSm"
            md={{ variant: "textMd" }}
            lg={{ variant: "textLg" }}
            style={{ fontWeight: "bold" }}
            $theme={theme}
          >
            {placeholder} <span>(e.g. 0x1234....)</span>
          </Placeholder>
        </RecipientInputWrap>
        <AutofillWrap className="py-2">
          <CustomRecipientButton
            onClick={handleWalletAddress}
            $theme={theme}
            disabled={disable}
          >
            <Icon name="wallet-dense" />
            Autofill Current Wallet Address
          </CustomRecipientButton>
        </AutofillWrap>
        <ErrorMessage
          variant="text2xs"
          md={{ variant: "textXs" }}
          weight={600}
          $error={error}
          $theme={theme}
        >
          Invalid Address
        </ErrorMessage>
      </TokenFieldWrap>
    </div>
  );
};
