import { createThirdwebClient, defineChain } from "thirdweb";
import { createWallet, inAppWallet, walletConnect } from "thirdweb/wallets";
import { NetworkConfiguration } from "../Network";
import { POLYGON_NETWORK } from "./WalletHelper";
import { arbitrum, polygon } from "thirdweb/chains";

export async function connectWallet(connect) {
  const network = NetworkConfiguration[POLYGON_NETWORK];
  const client = createThirdwebClient({
    clientId: network.clientID,
  });
  const xaiChain = defineChain({
    id: network.networkIdXai,
    nativeCurrency: {
      name: "Xai",
      symbol: "XAI",
      decimals: 18,
    },
  });

  const chains = [arbitrum, polygon, xaiChain];
  const wallets = [
    // inAppWallet(),
    createWallet("io.metamask"),
    createWallet("com.coinbase.wallet"),
    createWallet("io.rabby"),
    walletConnect(),
    inAppWallet({
      auth: {
        options: ["email", "google", "facebook", "apple"],
      },
    }),
  ];
  //const { connect, isConnecting } = useConnectModal();
  return await connect({ client, wallets, chains: chains });
}
