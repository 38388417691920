import { useEffect, useState } from "react";

// move this function to a helper file
function calculateTimeLeft(toDate) {
  const nowInMilliseconds = new Date().getTime();
  const toInMilliseconds = new Date(toDate).getTime();

  const difference = toInMilliseconds - nowInMilliseconds;
  let timeLeft = {};
  if (difference > 0) {
    timeLeft = {
      days: 0, // We should never go over 24 hours
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

export function CountdownTimer({ toDate, onEnd }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(toDate));

  // useEffect to update the timeLeft every second
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(toDate));
    }, 1000);
    return () => clearInterval(timer);
  }, [toDate]);

  // useEffect to call onEnd when the timeLeft is 0
  useEffect(() => {
    if (
      timeLeft.days === 0 &&
      timeLeft.hours === 0 &&
      timeLeft.minutes === 0 &&
      timeLeft.seconds === 0
    ) {
      onEnd();
    }
  }, [timeLeft, onEnd]);

  const days = timeLeft.days;
  const hoursWithDays = timeLeft.hours + days * 24;
  const hours = hoursWithDays < 10 ? `0${hoursWithDays}` : hoursWithDays;
  const minutes =
    timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes;
  const seconds =
    timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds;

  return (
    <>
      {hours}:{minutes}:{seconds}
    </>
  );
}
