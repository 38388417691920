import candyUnicorn from "../../img/class/candy.png";
import moonUnicorn from "../../img/class/moon.png";
import flowerUnicorn from "../../img/class/flower.png";
import crystalUnicorn from "../../img/class/crystal.png";
import heartUnicorn from "../../img/class/heart.png";
import starUnicorn from "../../img/class/star.png";
import rainbowUnicorn from "../../img/class/rainbow.png";
import omnomUnicorn from "../../img/class/omnom.png";
import cloudUnicorn from "../../img/class/cloud.png";
import lightLand from "../../img/class/light.png";
import wonderLand from "../../img/class/wonder.png";
import mysteryLand from "../../img/class/mystery.png";
import mythicLand from "../../img/class/mythic.png";
import fireShadowcorn from "../../img/class/fire.png";
import slimeShadowcorn from "../../img/class/slime.png";
import soulShadowcorn from "../../img/class/soul.png";
import voltShadowcorn from "../../img/class/volt.png";
import nebulaShadowcorn from "../../img/class/nebula.png";

export const iconsMap = {
  candy: candyUnicorn,
  moon: moonUnicorn,
  flower: flowerUnicorn,
  crystal: crystalUnicorn,
  heart: heartUnicorn,
  star: starUnicorn,
  rainbow: rainbowUnicorn,
  omnom: omnomUnicorn,
  cloud: cloudUnicorn,
  light: lightLand,
  wonder: wonderLand,
  mystery: mysteryLand,
  mythic: mythicLand,
  fire: fireShadowcorn,
  slime: slimeShadowcorn,
  soul: soulShadowcorn,
  volt: voltShadowcorn,
  nebula: nebulaShadowcorn,
};

export function ClassIcon({ name, ...rest }) {
  let icon = iconsMap[name];

  if (!icon) {
    console.warn(`Icon ${name} not found`);
    return <></>;
  }

  return <img src={icon} {...rest} alt={name} />;
}
