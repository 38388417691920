import React from "react";
import styled from "styled-components";
import { Typography } from "../base/Typography";
import { Container } from "../base/Container";
import { device } from "../../theme/mediaQuery";
import { Icon } from "../base/Icon";
import { SectionWrap as Section } from "./Hero";

const statisticsData = [
  { icon: "social", text: "165k", subtle: "Social Media Followers" },
  { icon: "time", text: "1 MM", subtle: "Hours of Game Time" },
  { icon: "community", text: "30 M", subtle: "Impressions" },
];

const SectionWrap = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin-bottom: 20px;

  @media (${device.sm}) {
    margin-bottom: 40px;
  }

  @media (${device.xl}) {
    margin-bottom: 0;
    gap: 60px;
  }
`;

const StatisticsWrap = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
  max-width: 800px;

  @media (${device.sm}) {
    flex-direction: row;
  }
  @media (${device.lg}) {
    gap: 20px;
    gap: 38px;
  }
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px 16px;
  width: 100%;
  @media (${device.sm}) {
    width: 150px;
  }
  @media (${device.lg}) {
    gap: 20px;
    width: 230px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  padding: 12px;

  background: linear-gradient(180deg, #b211f2 0%, #4557f7 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media (${device.lg}) {
    width: 104px;
    height: 104px;
    border-radius: 70px;
  }

  & > div {
    width: 49px;
    height: 49px;
    @media (${device.lg}) {
      width: 80px;
      height: 80px;
    }
  }
`;

export function StatsAtAGlance() {
  return (
    <Container>
      <SectionWrap>
        <Typography
          tag={"h2"}
          variant={"displaySm"}
          lg={{ variant: "displayLg" }}
          xxl={{ variant: "displayXl" }}
          color="white"
          textshadow="hard"
          className="text-center"
          style={{ lineHeight: "100%" }}
        >
          our statistics at a glance
        </Typography>
        <StatisticsWrap>
          {statisticsData.map((stat, index) => (
            <StatItem key={index}>
              <IconWrapper>
                <Icon name={stat.icon} />
              </IconWrapper>
              <Typography
                as="p"
                variant="textXl"
                md={{ variant: "displayXs" }}
                lg={{ variant: "displaySm" }}
                color="white"
                family="display"
              >
                {stat.text}
                <Typography
                  as="span"
                  variant="textMd"
                  md={{ variant: "textLg" }}
                  lg={{ variant: "textXl" }}
                  color="white"
                  style={{ display: "block" }}
                >
                  {stat.subtle}
                </Typography>
              </Typography>
            </StatItem>
          ))}
        </StatisticsWrap>
      </SectionWrap>
    </Container>
  );
}
