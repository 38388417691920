import styled from "styled-components";
import React from "react";
import { device } from "../../theme/mediaQuery";
import { Typography } from "../base/Typography";
import tier0 from "../../img/leaderboard/tier0_items.png";
import tier1 from "../../img/leaderboard/tier1_items.png";
import tier2 from "../../img/leaderboard/tier2_items.png";
import tier3 from "../../img/leaderboard/tier3_items.png";
import tier4 from "../../img/leaderboard/tier4_items.png";
import tier5 from "../../img/leaderboard/tier5_items.png";
import tier6 from "../../img/leaderboard/tier6_items.png";
import tier7 from "../../img/leaderboard/tier7_items.png";
import tier8 from "../../img/leaderboard/tier8_items.png";
import tier9 from "../../img/leaderboard/tier9_items.png";
import tier10 from "../../img/leaderboard/tier10_items.png";

import badge1 from "../../img/leaderboard/badge_tier1_P2A.png";
import badge2 from "../../img/leaderboard/badge_tier2_P2A.png";
import badge3 from "../../img/leaderboard/badge_tier3_P2A.png";
import progression from "../../img/leaderboard/progression_bundle.png";
import rare from "../../img/leaderboard/lootbox_rare_opened.png";
import common from "../../img/leaderboard/lootbox_common_opened.png";
import mythic from "../../img/leaderboard/lootbox_mythic_opened.png";
import xai from "../../img/currencies/xai-white.png";
import gem from "../../img/leaderboard/gem.png";

import unicorn from "../../img/airdrop/deep-fried-king.png";
import { ProgressBar } from "./ProgressBar";

const rewards = {
  badge1: {
    name: "P2A Tier I Badge",
    img: badge1,
  },
  badge2: {
    name: "P2A Tier II Badge",
    img: badge2,
  },
  badge3: {
    name: "P2A Tier III Badge",
    img: badge3,
  },
  xai: {
    name: "XAI Token",
    img: xai,
  },
  common: {
    name: "Common Lootbox",
    img: common,
  },
  rare: {
    name: "Rare Lootbox",
    img: rare,
  },
  mythic: {
    name: "Mythic Lootbox",
    img: mythic,
  },
  progression: {
    name: "Progression Bundle",
    img: progression,
  },
  gem: {
    name: "Gem Crafting Materials",
    img: gem,
  },
};

export const TIERS = [
  {
    name: "Tier 0",
    img: tier0,
    gradient: "tier0",
    progressIcon: unicorn,
    rewards: [
      {
        ...rewards.badge1,
        count: 0,
      },
    ],
  },
  {
    name: "Tier I",
    img: tier1,
    gradient: "tier1",
    progressIcon: unicorn,
    rewards: [
      {
        ...rewards.badge1,
        count: 0,
      },
      {
        ...rewards.common,
        count: 1,
      },
    ],
  },
  {
    name: "Tier II",
    img: tier2,
    gradient: "tier2",
    progressIcon: unicorn,
    rewards: [
      {
        ...rewards.badge1,
        count: 0,
      },
      {
        ...rewards.xai,
        count: 5,
      },
      {
        ...rewards.common,
        count: 2,
      },
      {
        ...rewards.progression,
        count: 1,
      },
    ],
  },
  {
    name: "Tier III",
    img: tier3,
    gradient: "tier3",
    progressIcon: unicorn,
    rewards: [
      {
        ...rewards.badge1,
        count: 0,
      },
      {
        ...rewards.xai,
        count: 25,
      },
      {
        ...rewards.common,
        count: 3,
      },
      {
        ...rewards.progression,
        count: 3,
      },
    ],
  },
  {
    name: "Tier IV",
    img: tier4,
    gradient: "tier4",
    progressIcon: unicorn,
    rewards: [
      {
        ...rewards.badge2,
        count: 0,
      },
      {
        ...rewards.xai,
        count: 100,
      },
      {
        ...rewards.rare,
        count: 3,
      },
      {
        ...rewards.progression,
        count: 7,
      },
    ],
  },
  {
    name: "Tier V",
    img: tier5,
    gradient: "tier5",
    progressIcon: unicorn,
    rewards: [
      {
        ...rewards.badge2,
        count: 0,
      },
      {
        ...rewards.xai,
        count: 250,
      },
      {
        ...rewards.rare,
        count: 5,
      },
      {
        ...rewards.progression,
        count: 10,
      },
    ],
  },
  {
    name: "Tier VI",
    img: tier6,
    gradient: "tier6",
    progressIcon: unicorn,
    rewards: [
      {
        ...rewards.badge2,
        count: 0,
      },
      {
        ...rewards.xai,
        count: 500,
      },
      {
        ...rewards.rare,
        count: 7,
      },
      {
        ...rewards.gem,
        count: 5,
      },
    ],
  },
  {
    name: "Tier VII",
    img: tier7,
    gradient: "tier7",
    progressIcon: unicorn,
    rewards: [
      {
        ...rewards.badge3,
        count: 0,
      },
      {
        ...rewards.xai,
        count: 1250,
      },
      {
        ...rewards.rare,
        count: 10,
      },
      {
        ...rewards.gem,
        count: 8,
      },
    ],
  },
  {
    name: "Tier VIII",
    img: tier8,
    gradient: "tier8",
    progressIcon: unicorn,
    rewards: [
      {
        ...rewards.badge3,
        count: 0,
      },
      {
        ...rewards.xai,
        count: 2500,
      },
      {
        ...rewards.mythic,
        count: 5,
      },
      {
        ...rewards.gem,
        count: 10,
      },
    ],
  },
  {
    name: "Tier IX",
    img: tier9,
    gradient: "tier9",
    progressIcon: unicorn,
    rewards: [
      {
        ...rewards.badge3,
        count: 0,
      },
      {
        ...rewards.xai,
        count: 5000,
      },
      {
        ...rewards.mythic,
        count: 7,
      },
      {
        ...rewards.gem,
        count: 15,
      },
    ],
  },
  {
    name: "Tier X",
    img: tier10,
    gradient: "tier10",
    progressIcon: unicorn,
    rewards: [
      {
        ...rewards.badge3,
        count: 0,
      },
      {
        ...rewards.xai,
        count: 10000,
      },
      {
        ...rewards.mythic,
        count: 10,
      },
      {
        ...rewards.gem,
        count: 20,
      },
    ],
  },
];

const TierWrap = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  @media (${device.lg}) {
    gap: 24px;
  }
`;

export const Tier = ({ tier, points, pointsRemaining }) => {
  const hasCompleted = tier === TIERS.length;
  const currentTier = TIERS[tier];
  const nextTier = hasCompleted ? null : TIERS[tier + 1];

  return (
    <TierWrap>
      <TierTitle icon={currentTier.img} title={currentTier.name} />
      <ProgressBar
        nextIcon={nextTier && nextTier.img}
        nextTier={nextTier && nextTier.name}
        progressIcon={currentTier.progressIcon}
        gradient={nextTier ? nextTier.gradient : currentTier.gradient}
        pointsRemaining={pointsRemaining}
        score={points}
      />
    </TierWrap>
  );
};

const TierTitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (${device.md}) {
    gap: 12px;
  }
`;

const TierIcon = styled.img`
  width: 24px;
  height: 24px;

  @media (${device.md}) {
    width: 32px;
    height: 32px;
  }
  @media (${device.lg}) {
    width: 40px;
    height: 40px;
  }
`;

export const TierTitle = ({ icon, title }) => {
  return (
    <TierTitleWrap>
      <TierIcon src={icon} alt={title} />
      <Typography
        tag={"p"}
        variant={"textXs"}
        md={{ variant: "textSm" }}
        xl={{ variant: "displaySm" }}
        family="display"
      >
        {title}
      </Typography>
    </TierTitleWrap>
  );
};
